export default {
  loading: false,
  error: false,
  allPlayerAdmin: [],
  pagination: {
    limit: 25,
    page: 0,
    total: 0
  },
  itemsByID: '',
  listCampaignByOrg: [],
  application_installed: [],
  total_records: 0
}
