<template>
  <div class="container-fluid">
    <div class="header-campaign d-flex justify-content-between">
      <!-- Search Unregistered Player -->
      <div :class="{ 'd-none': !(activeButton === 'players') }">
        <SfInputSearch
          v-model="dataSearch"
          :placeholder="$t('search')"
          v-debounce:500ms="searchInputChange"
          :height="'40px'"
          @onSearchInputChange="resetSearchPlayer"
        />
      </div>
      <!-- Search Connected -->
      <div :class="{ 'd-none': !(activeButton === 'connected') }">
        <SfInputSearch
          v-model="dataSearchConnected"
          :placeholder="$t('search')"
          v-debounce:500ms="connectedSearchInputChange"
          :height="'40px'"
          @onSearchInputChange="resetSearchConnected"
        />
      </div>
    </div>
    <div class="row players-tabs-wraper">
      <div class="col-6">
        <div class="sf-sub-menu">
          <div class="col-md-12 top-menu">
            <ul class="nav nav-tabs nav-tabs-custom">
              <li class="acount-menu-item">
                <a
                  @click="activeButton = 'players'"
                  :class="{ 'is-active': activeButton === 'players' }"
                  >Player</a
                >
              </li>
              <li class="acount-menu-item">
                <a
                  @click="activeButton = 'connected'"
                  :class="{ 'is-active': activeButton === 'connected' }"
                  >Player Connected</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div v-if="activeButton === 'players'" class="row mb-3">
          <div class="col-12 d-flex justify-content-end">
            <b-pagination
              class="sf-b-pagination"
              :total-rows="limitPage.value * paginationUnregisteredPlayers.total"
              :per-page="limitPage.value"
              @change="changePage"
              :limit="6"
              v-model="currentPage"
            />
            <SfOptionLimitPage @changeLimitPage="changeLimitPage" :limitPage.sync="limitPage" />
          </div>
        </div>
        <div v-if="activeButton === 'connected'" class="row mb-3">
          <div class="col-12 d-flex justify-content-end">
            <b-pagination
              class="sf-b-pagination"
              :total-rows="limitPageConnected.value * paginationConnectedPlayers.total"
              :per-page="limitPageConnected.value"
              @change="changePageConnected"
              :limit="6"
              v-model="currentPageOfConnected"
            >
            </b-pagination>
            <SfOptionLimitPage
              @changeLimitPage="changeLimitPageConnected"
              :limitPage.sync="limitPageConnected"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="sf-table">
      <!-- Table Player -->
      <b-table
        :class="{ 'dl-none': !(activeButton === 'players') }"
        show-empty
        striped
        hover
        :items="listUnregisteredPlayers"
        :fields="fields"
        head-variant="none"
        table-variant="none"
        thead-class="custom-b-table"
        :busy="isLoadingUnregisteredPlayers"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>{{ $t('loading') }}...</strong>
          </div>
        </template>
        <template v-slot:empty>
          <p class="d-flex justify-content-center">{{ $t('no-data-available-in-table') }}</p>
        </template>

        <template v-slot:head(image)="field">
          {{ field.label }}
        </template>
        <template v-slot:cell(image)="field">
          <img
            v-if="field.item.orientation && field.item.rotate"
            class="img-player"
            :class="field.item.orientation"
            :src="
              require('@/assets/images/' +
                field.item.orientation +
                '-' +
                field.item.rotate +
                '.svg')
            "
          />
        </template>

        <template v-slot:head(name)="field">
          <span class="custome-arrows">{{ $t('name') }}</span>
        </template>
        <template v-slot:cell(name)="field">
          <p>
            <router-link
              :to="`/players/${field.item.player_code}`"
              :class="{ 'text-color-grey-40': !field.item.activated }"
              >{{ field.item.name }}</router-link
            >
          </p>
        </template>

        <template v-slot:head(running_campaign)="field">
          <span class="custome-arrows">{{ $t('running-campaign') }}</span>
        </template>
        <template v-slot:cell(running_campaign)="field">
          <p>
            <router-link :to="`/campaigns/${field.item.campaign_code}/edit`"
              >{{ field.item.running_campaign }}
            </router-link>
          </p>
        </template>

        <template v-slot:head(action)="field">
          <span>{{ $t('actions') }}</span>
        </template>
        <template v-slot:cell(action)="field">
          <button @click="connectPlayer(field.item.id)" class="sf-primary" type="button">
            Connect
          </button>
        </template>
      </b-table>
      <!-- Table Player Connected -->
      <b-table
        :class="{ 'dl-none': !(activeButton === 'connected') }"
        show-empty
        striped
        hover
        :items="listConnectedPlayers"
        :fields="fieldsConnected"
        head-variant="none"
        table-variant="none"
        thead-class="custom-b-table"
        :busy="isLoadingConnectedPlayers"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>{{ $t('loading') }}...</strong>
          </div>
        </template>
        <template v-slot:empty="isEmptyConnectedPlayers">
          <p class="d-flex justify-content-center">{{ $t('no-data-available-in-table') }}</p>
        </template>

        <template v-slot:head(image)="field">
          {{ field.label }}
        </template>
        <template v-slot:cell(image)="field">
          <img
            v-if="field.item.orientation && field.item.rotate"
            class="img-player"
            :class="field.item.orientation"
            :src="
              require('@/assets/images/' +
                field.item.orientation +
                '-' +
                field.item.rotate +
                '.svg')
            "
          />
        </template>

        <template v-slot:head(name)="field">
          <span class="custome-arrows">{{ $t('name') }}</span>
        </template>
        <template v-slot:cell(name)="field">
          <p>
            <router-link
              :to="`/players/${field.item.player_code}`"
              :class="{ 'text-color-grey-40': !field.item.activated }"
              >{{ field.item.name }}</router-link
            >
          </p>
        </template>

        <template v-slot:head(running_campaign)="field">
          <span class="custome-arrows">{{ $t('running-campaign') }}</span>
        </template>
        <template v-slot:cell(running_campaign)="field">
          <p>
            <router-link :to="`/campaigns/${field.item.campaign_code}/edit`"
              >{{ field.item.running_campaign }}
            </router-link>
          </p>
        </template>

        <template v-slot:head(action)="field">
          <span>{{ $t('actions') }}</span>
        </template>
        <template v-slot:cell(action)="field">
          <button @click="reconnectPlayer(field.item.id)" class="sf-primary" type="button">
            Reconnect
          </button>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import SfInputSearch from '@/components/SfInputSearch.vue'
import { mapState, mapMutations } from 'vuex'
import { timeSince } from '@/helper/helper'
import playerService from '../services/player.service'
import SfOptionLimitPage from '@/components/SfOptionLimitPage.vue'

export default {
  name: 'PlayerPage',
  components: {
    SfInputSearch,
    SfOptionLimitPage
  },
  data() {
    return {
      fields: [
        {
          key: 'image',
          label: '',
          colType: 'image',
          thClass: 'icon-table'
        },
        {
          key: 'name',
          sortable: true,
          colType: 'name',
          label: 'Name'
        },
        {
          key: 'running_campaign',
          sortable: true,
          colType: 'running_campaign',
          label: 'Running Campaign'
        },
        {
          key: 'Action',
          colType: 'button',
          label: 'Actions'
        }
      ],
      fieldsConnected: [
        {
          key: 'image',
          label: '',
          colType: 'image',
          thClass: 'icon-table'
        },
        {
          key: 'name',
          sortable: true,
          colType: 'name',
          label: 'Name'
        },
        {
          key: 'running_campaign',
          sortable: true,
          colType: 'running_campaign',
          label: 'Running Campaign'
        },
        {
          key: 'Action',
          colType: 'button',
          label: 'Actions'
        }
      ],
      uniqkey: this.$route.query.uniqkey,
      dataSearch: '',
      dataSearchConnected: '',
      currentPage: 1,
      currentPageOfConnected: 1,
      limitPage: {
        name: `25 / ${this.$t('page')}`,
        value: '25'
      },
      limitPageConnected: {
        name: `25 / ${this.$t('page')}`,
        value: '25'
      },
      user: {},
      activeButton: 'players'
    }
  },
  methods: {
    timeSince,
    ...mapMutations('player', {
      createPlayerFromSocket: 'CREATE_PLAYER_FROM_SOCKET',
      deletePlayerFromSocket: 'DELETE_PLAYER_FROM_SOCKET',
      updatePlayerFromSocket: 'UPDATE_PLAYER_FROM_SOCKET',
      setLoadingUnregisteredPlayers: 'SET_LOADING_UNREGISTERED_PLAYERS',
      setLoadingConnectedPlayers: 'SET_LOADING_CONNECTED_PLAYERS'
    }),
    fetchData(activePage, limit, search) {
      this.$store.dispatch('player/getUnregisteredPlayers', { activePage, limit, search })
    },
    fetchDataConnectedPlayers(unqikey, activePage, limit, search) {
      this.$store.dispatch('player/getConnectedPlayers', { unqikey, activePage, limit, search })
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage, this.limitPage.value, this.dataSearch)
    },
    changePageConnected(page) {
      this.currentPageOfConnected = page
      this.fetchDataConnectedPlayers(
        this.uniqkey,
        this.currentPageOfConnected,
        this.limitPageConnected.value,
        this.dataSearchConnected
      )
    },
    resetSearchPlayer() {
      this.fetchData(this.currentPage, this.limitPage.value)
    },
    resetSearchConnected() {
      this.fetchDataConnectedPlayers(
        this.uniqkey,
        this.currentPageOfConnected,
        this.limitPageConnected.value
      )
    },
    changeLimitPage(data) {
      this.currentPage = 1
      this.limitPage = data
      this.fetchData(this.currentPage, this.limitPage.value, this.dataSearch)
    },
    changeLimitPageConnected(data) {
      this.currentPageOfConnected = 1
      this.limitPageConnected = data
      this.fetchDataConnectedPlayers(
        this.uniqkey,
        this.currentPageOfConnected,
        this.limitPageConnected.value,
        this.dataSearchConnected
      )
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, this.limitPage.value, value)
    },
    connectedSearchInputChange(value) {
      this.dataSearchConnected = value
      this.currentPageOfConnected = 1
      this.fetchDataConnectedPlayers(
        this.uniqkey,
        this.currentPageOfConnected,
        this.limitPageConnected.value,
        value
      )
    },
    getUser() {
      const user = this.$cookies.get('user')
      if (user) {
        this.user = user
      }
    },
    connectPlayer(id) {
      this.setLoadingUnregisteredPlayers(true)
      const data = {
        id: id,
        params: this.uniqkey
      }
      playerService
        .connectUnregisteredPlayer(data)
        .then(() => {
          this.$toast.success('Register player successfully')
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
        .finally(() => {
          this.setLoadingUnregisteredPlayers(false)
        })
    },
    reconnectPlayer(id) {
      this.setLoadingConnectedPlayers(true)
      playerService
        .reconnectPlayer(id)
        .then(() => {
          this.$toast.success('Reconnect player successfully')
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
        .finally(() => {
          this.setLoadingConnectedPlayers(false)
        })
    }
  },
  mounted() {
    this.getUser()
    this.$cable.subscribe({
      channel: 'PlayersChannel',
      organization: this.user.organization_id
    })
    this.fetchData(this.currentPage, this.limitPage.value)
    this.fetchDataConnectedPlayers(
      this.uniqkey,
      this.currentPageOfConnected,
      this.limitPageConnected.value
    )
  },
  beforeDestroy() {
    this.$cable.unsubscribe('PlayersChannel')
  },
  channels: {
    PlayersChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (data.action === 'create') {
          this.createPlayerFromSocket({ data: data.data })
        } else if (data.action === 'delete') {
          this.deletePlayerFromSocket({ data: data.data })
        } else if (data.action === 'update') {
          this.updatePlayerFromSocket({ data: data.data })
        }
      },
      disconnected() {}
    }
  },
  watch: {
    activeButton(value) {
      if (value === 'players') {
        this.$bus.$emit('send_active_button_to_header', this.dataSearch)
      } else if (value === 'groups') {
        this.$bus.$emit('send_active_button_to_header', this.dataSearchConnected)
      }
    }
  },
  computed: {
    ...mapState('player', {
      error: 'error',
      paginationUnregisteredPlayers: 'paginationUnregisteredPlayers',
      listUnregisteredPlayers: 'listUnregisteredPlayers',
      isLoadingUnregisteredPlayers: 'isLoadingUnregisteredPlayers',
      paginationConnectedPlayers: 'paginationConnectedPlayers',
      listConnectedPlayers: 'listConnectedPlayers',
      isLoadingConnectedPlayers: 'isLoadingConnectedPlayers'
    }),
    isEmpty() {
      return this.listUnregisteredPlayers.length < 1
    },
    isEmptyConnectedPlayers() {
      return this.listConnectedPlayers.length < 1
    }
  }
}
</script>
<style lang="scss" scoped>
@import './campaign/campaignPage.scss';
@import './campaign/editCampaign.scss';
</style>
