export default {
  getState(state) {
    return state
  },
  listTimezoneOptions: (state) => {
    return state.listTimezone.map((timezone) => {
      return {
        id: `${timezone.value}|${timezone.name}`,
        text: timezone.name
      }
    })
  }
}
