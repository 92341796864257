<template>
  <div>
    <div class="content">
      <div class="select-all container" v-if="selectAllButton">
        <div class="row">
          <div class="col-12 d-flex align-items-center">
            <button type="button" class="sf-btn-outline btn" @click="triggerCheckboxClick">
              <label class="select-all-container"
                ><input
                  type="checkbox"
                  v-if="selectAllButton && nodes.length > 0"
                  class="select-checkbox"
                  :checked="selectedItems.length === assetItems.length"
                  @change="toggleSelectedAllItem($event)"
                />Select All</label
              >
            </button>
            <button
              type="button"
              :disabled="loadingAssets"
              @click="onCloneBulk"
              v-if="selectedItems.length > 0"
              class="sf-btn-outline btn btn-del ml-2"
            >
              <img class="delete-item select-item" :src="cloneIcon" /> Move to LOOP
            </button>
          </div>
        </div>
      </div>
    </div>
    <template v-if="viewType === 'grid-view'">
      <div v-if="loading" :key="`loading-placeholders`">
        <transition-group name="item" tag="div" class="asset-container" ref="divcontainer">
          <template v-for="i in parseInt(this.limitPage.value)">
            <div class="element-item item" :key="`loading-${i}`" v-widthItem="itemWidth">
              <content-placeholders :centered="true">
                <div class="detail-section sf-asset-card">
                  <div class="img-name">
                    <div class="wrap-img">
                      <content-placeholders-img />
                    </div>
                    <div class="name asset-info">
                      <div class="wrap-p">
                        <content-placeholders-text :lines="2" />
                      </div>
                    </div>
                  </div>
                </div>
              </content-placeholders>
            </div>
          </template>
        </transition-group>
      </div>
      <div v-else :key="`content-item`">
        <draggable
          class=""
          draggable=".draggable-item"
          :list="nodes"
          :sort="false"
          :group="{ name: 'item', pull: 'clone', put: false }"
        >
          <transition-group name="item" tag="div" class="asset-container" ref="divcontainer">
            <template v-for="item in nodes">
              <div
                :key="item.id"
                :class="getClassWithType(item)"
                :ref="`item-${item.type}-${item.id}`"
                draggable="true"
                @dblclick="openFolder(item)"
                @dragstart="onDragstart(item.id, item.type, $event)"
                @dragend="onDragend(item.id, item.type)"
                v-widthItem="itemWidth"
                @mousedown="startLongPress(item)"
                @touchstart="startLongPress(item)"
                @mouseup="endLongPress(item)"
                @touchend="endLongPress(item)"
              >
                <input
                  type="checkbox"
                  v-if="selectAllButton && item.type !== typeFile.folder"
                  class="data-image selected-item"
                  :checked="selectedItems.includes(item)"
                  @change="toggleSelectedItem($event, item)"
                />
                <div class="data-drag hide">
                  <img class="data-image" :src="getIconWithType(item)" />
                  <p class="data-title">
                    <span :title="item.name">{{ item.name }}</span>
                  </p>
                </div>
                <div
                  class="detail-section sf-asset-card"
                  :class="selectAllButton && selectedItems.includes(item) ? 'selected' : ''"
                >
                  <div class="img-name">
                    <div
                      class="wrap-img"
                      :class="
                        item.type +
                        '-img none-button' +
                        (item.folder_type === 'organizations'
                          ? ' organization'
                          : item.shared_folder
                          ? ' shared-folder'
                          : '')
                      "
                      style=""
                    >
                      <div
                        v-if="isImageVideo(item)"
                        class="bg-gradient"
                        data-fancybox="gallery"
                        :href="item.origin_link"
                      >
                        <div class="background-opacity"></div>
                        <img class="data-image" :src="item.thumb_link" />
                      </div>
                      <div v-if="item.orientation === 'portrait'" class="portrait-note-icon"></div>
                    </div>
                    <div class="background-text"></div>
                    <div class="name asset-info">
                      <div class="wrap-p">
                        <p class="asset-name">
                          <span :title="item.name">{{ item.name }}</span>
                        </p>
                        <p class="size" v-if="isImageVideo(item)">{{ bytesToSize(item.size) }}</p>
                        <p v-if="item.type === typeFile.folder">{{ timeSince(item.updated_at) }}</p>
                      </div>
                      <div class="group-tags" />
                    </div>
                  </div>
                  <div class="date-action">
                    <div class="table-row">
                      <div class="wrap-size-date">
                        <div class="date-section">
                          <img
                            v-if="isImageVideo(item)"
                            :src="
                              require('@/assets/images/' +
                                item.type.replace('_', '-') +
                                '-mini-icon.svg')
                            "
                          />
                        </div>
                        <div class="size-section" v-if="item.type === typeFile.video">
                          <span class="duration">
                            {{ toDuration(item.duration) }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </transition-group>
        </draggable>
      </div>
    </template>
    <template v-else>
      <div v-if="loading" :key="`loading-placeholders`">
        <transition-group name="fade" mode="in-out" tag="div" class="table-container">
          <template v-for="i in parseInt(this.limitPage.value)">
            <div class="element-item item" :key="`loading-${i}`" v-widthItem="itemWidth">
              <content-placeholders :animated="true">
                <content-placeholders-heading :img="true" />
              </content-placeholders>
            </div>
          </template>
        </transition-group>
      </div>
      <div v-else :key="`content-item`">
        <draggable
          class="dragArea list-group"
          draggable=".draggable-item"
          :list="nodes"
          :sort="false"
          :group="{ name: 'item', pull: 'clone', put: false }"
        >
          <transition-group name="item" tag="div" class="table-container">
            <template v-for="item in nodes" class="">
              <div
                :key="item.id"
                :class="getClassWithType(item)"
                :ref="`item-${item.type}-${item.id}`"
                draggable="true"
                @dblclick="openFolder(item)"
                @dragstart="onDragstart(item.id, item.type, $event)"
                @dragend="onDragend(item.id, item.type)"
                v-widthItem="itemWidth"
                @mousedown="startLongPress(item)"
                @touchstart="startLongPress(item)"
                @mouseup="endLongPress(item)"
                @touchend="endLongPress(item)"
              >
                <input
                  type="checkbox"
                  v-if="selectAllButton && item.type !== typeFile.folder"
                  class="data-image selected-item"
                  :checked="selectedItems.includes(item)"
                  @change="toggleSelectedItem($event, item)"
                />
                <div class="data-drag hide">
                  <img class="data-image" :src="getIconWithType(item)" />
                  <p class="data-title">{{ item.name }}</p>
                </div>
                <div
                  class="detail-section sf-asset-card"
                  :class="selectAllButton && selectedItems.includes(item) ? 'selected' : ''"
                >
                  <div class="icon-media">
                    <img
                      v-if="item.type !== typeFile.folder"
                      :src="
                        require('@/assets/images/' +
                          item.type.replace('_', '-') +
                          '-listview-icon.svg')
                      "
                    />
                  </div>
                  <div class="background-img">
                    <div class="wrap-img" :class="item.type + '-img'">
                      <div
                        v-if="isImageVideo(item)"
                        class="bg-gradient"
                        data-fancybox="gallery"
                        :href="item.origin_link"
                      >
                        <img :src="item.thumb_link" />
                      </div>
                      <img v-else class="img-default icon-ig" :src="getIconWithType(item)" />
                    </div>
                  </div>
                  <div class="right-content-element asset-info">
                    <div class="top-content">
                      <div class="item item-left">
                        <p class="asset-name">
                          <span :title="item.name">{{ item.name }}</span>
                        </p>
                        <!-- <p class="orientation hidden">{{ item.orientation }}</p> -->
                        <div class="group-tags hidden"></div>
                      </div>
                      <div class="item item-right">
                        <div class="table-row">
                          <div class="size-section item-cell">
                            <p v-if="item.type === typeFile.video">
                              <img src="~@/assets/images/time-litsview.svg" />{{
                                toDuration(item.duration)
                              }}
                            </p>
                            <p v-if="isImageVideo(item) && item.type === typeFile.pdf" class="size">
                              <img src="~@/assets/images/size-litsview.svg" />{{
                                bytesToSize(item.size)
                              }}
                            </p>
                          </div>
                          <div class="date-section item-cell">
                            <p>
                              <img src="~@/assets/images/date-listview.svg" />{{
                                timeSince(item.updated_at)
                              }}
                            </p>
                            <p
                              v-if="
                                item.type !== typeFile.folder && item.orientation === 'portrait'
                              "
                            >
                              <img
                                class="portrait-icon"
                                src="~@/assets/images/portrait-icon-listview.svg"
                              />Portrait
                            </p>
                            <p
                              v-if="
                                item.type !== typeFile.folder && item.orientation === 'landscape'
                              "
                            >
                              <img
                                class="landscape-icon"
                                src="~@/assets/images/landscape-icon-listview.svg"
                              />Landscape
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row-name"></div>
                  </div>
                </div>
              </div>
            </template>
          </transition-group>
        </draggable>
      </div>
    </template>
    <div class="drag-item" ref="drag">
      <img class="data-image" src="#" />
      <p class="data-title">#</p>
    </div>
  </div>
</template>
<script>
import typeFile from '@/constant/typeFile'
import { timeSince, toDuration } from '@/helper/helper'
import draggable from 'vuedraggable'
import { bytesToSize } from '@/helper/mathHelper'
import instagramIcon from '@/assets/images/instagram.svg'
import folderIcon from '@/assets/images/folder.svg'
import webIcon from '@/assets/images/web-icon.svg'
import pdfIcon from '@/assets/images/pdf-icon.svg'
import youtubeIcon from '@/assets/images/youtube-icon.svg'
import googleSlideIcon from '@/assets/images/google-slide-icon.svg'
import googleSheetIcon from '@/assets/images/google-sheet-icon.svg'
import customAssetIcon from '@/assets/images/custom-asset-icon.svg'
import selectItemIcon from '@/assets/images/selected-icon.svg'
import cancelIcon from '@/assets/images/cancel-icon.svg'
import checkallIcon from '@/assets/images/icon-check.svg'
import cloneIcon from '@/assets/images/icon-clone-active.svg'
import { mapGetters } from 'vuex'

export default {
  props: ['viewType', 'nodes', 'loading', 'limitPage', 'selectAllButton'],
  data() {
    return {
      instagramIcon,
      folderIcon,
      webIcon,
      pdfIcon,
      selectItemIcon,
      checkallIcon,
      cancelIcon,
      cloneIcon,
      youtubeIcon,
      typeFile,
      googleSlideIcon,
      googleSheetIcon,
      customAssetIcon,
      itemWidth: 1,
      eventResize: null,
      selectedItems: [],
      longPressActive: false,
      singleClickTimeout: null,
      assetItems: []
    }
  },
  methods: {
    timeSince,
    toDuration,
    bytesToSize,
    onDragstart(id, type, event) {
      const eleCurrent = this.$refs[`item-${type}-${id}`][0]
      eleCurrent.style.opacity = 0
      const eleImage = eleCurrent.getElementsByClassName('data-image')[0]
      const eleTitle = eleCurrent.getElementsByClassName('data-title')[0]

      const eleDrag = this.$refs.drag
      const eleImageDrag = eleDrag.getElementsByClassName('data-image')[0]
      const eleTitleDrag = eleDrag.getElementsByClassName('data-title')[0]

      eleImageDrag.src = eleImage.src
      eleTitleDrag.innerHTML = eleTitle.innerHTML

      document.body.appendChild(eleDrag)
      event.dataTransfer.setDragImage(eleDrag, 0, 0)
    },
    onDragend(id, type) {
      const eleCurrent = this.$refs[`item-${type}-${id}`][0]
      eleCurrent.style.opacity = 1
      this.$emit('updateSlideFromSocket', { ...{ id: id } })
    },
    onDelete(id) {
      const node = this.nodes.find((item) => item.id === id)
      this.$emit('deleteItem', node)
    },
    onCloneBulk() {
      this.$emit('onCloneBulk', this.selectedItems)
    },
    startLongPress(item) {
      if (!this.longPressActive) {
        this.singleClickTimeout = setTimeout(() => {
          this.longPressActive = true
          this.selectedItems.push(item)
        }, 500)
      }
    },
    endLongPress() {
      clearTimeout(this.singleClickTimeout)

      if (this.longPressActive) {
        setTimeout(() => {
          this.longPressActive = false
        }, 100)
      } else {
        this.longPressActive = false
      }
    },
    toggleSelectedItem(event, item) {
      if (item.type !== typeFile.folder) {
        if (event.target.checked) {
          this.selectedItems.push(item)
        } else {
          this.selectedItems = this.selectedItems.filter((selectedItem) => selectedItem !== item)
        }
      }
    },
    toggleSelectedAllItem(event) {
      if (event.target.checked) {
        this.selectedItems = this.assetItems
      } else {
        this.selectedItems = []
      }
    },
    triggerCheckboxClick() {
      const checkbox = document.querySelector('.select-checkbox')[0]
      if (checkbox) {
        checkbox.click()
      }
    },
    onResize() {
      if (this.viewType === 'grid-view') {
        const widthCurrent = document.querySelectorAll('.asset-container')[0].clientWidth
        this.itemWidth = Math.max(1, (widthCurrent / 160) >> 0)
      } else {
        this.itemWidth = 1
      }
    },
    openFolder(item) {
      if (item.type === typeFile.folder) {
        this.$emit('openFolder', item)
      }
    },
    noneEvent(e) {
      e.preventDefault()
      e.stopPropagation()
    },
    getIconWithType(item) {
      let icon = ''
      if (this.isImageVideo(item)) {
        icon = item.thumb_link
      } else {
        switch (item.type) {
          case 'instagram':
            icon = this.instagramIcon
            break
          case 'folder':
            icon = this.folderIcon
            break
          case 'web':
            icon = this.webIcon
            break
          case 'pdf':
            icon = this.pdfIcon
            break
          case 'youtube':
            icon = this.youtubeIcon
            break
          case 'google_slide':
            icon = this.googleSlideIcon
            break
          case 'google_sheet':
            icon = this.googleSheetIcon
            break
          case 'custom_asset':
            icon = this.customAssetIcon
            break
          default:
            icon = ''
        }
      }
      return icon
    },
    isImageVideo(item) {
      return item.type === typeFile.image || item.type === typeFile.video
    },
    getClassWithType(item) {
      let className = 'element-item item ' + item.type
      if (!this.isImageVideo(item)) {
        className = className + ' none-img'
      }
      className = className + ' draggable-item'
      if (this.selectedItems.includes(item)) {
        return `${className} selected`
      }
      return className
    }
  },
  created() {
    this.eventResize = setInterval(this.onResize, 100)
  },
  watch: {
    nodes(values) {
      if (values) {
        this.assetItems = values.filter((item) => item.type !== typeFile.folder)
      }
    },
    viewType(value) {
      this.onResize()
    }
  },
  computed: {
    ...mapGetters('loopdetail', {
      loadingAssets: 'loadingAssets'
    })
  },
  components: {
    draggable
  },
  mounted() {
    this.onResize()
  },
  beforeDestroy() {
    clearInterval(this.eventResize)
  },
  directives: {
    widthItem: {
      bind(el, binding, vnode) {
        const item = binding.value
        el.style.width = `calc(100% / ${item})`
      },
      update(el, binding, vnode) {
        const item = binding.value
        el.style.width = `calc(100% / ${item})`
      }
    },
    resetWidth: {
      bind(el, binding, vnode) {
        el.style.width = 'calc(100% - 25px)'
      },
      update(el, binding, vnode) {
        el.style.width = 'calc(100% - 25px)'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.delete-item {
  width: 17px;
  height: 17px;
}
.content {
  padding-left: 5px;
  .select-all {
    position: relative;
    // padding: 0 29px;
    margin: 10px 0;
    .select-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      // margin: 0 5px;
      width: 100%;

      .select-item {
        width: 17px;
        height: 17px;
        margin: 0 5px;
        &.cancel-icon {
          width: 12px;
        }
        &.unselect-icon {
          width: 10px;
        }
      }
    }
  }
}
@import './style-grid-view.scss';
@import './style-list-view.scss';
.select-all-container {
  width: max-content;
  display: flex;
  cursor: pointer;
  .select-checkbox {
    width: 15px;
    height: 15px;
    margin: 0 6px 0 0;
  }
}
.item {
  &-enter-active {
    transition: all 0.3s ease-out;
  }

  &-leave-active {
    transition: all 0.3s ease-in;
    position: absolute;
    z-index: 1;
  }

  &-enter,
  &-leave-to {
    transform: scale(0.5);
    opacity: 0;
  }
  &-move {
    transition: transform 0.3s;
  }
}

.element-item draggable-item:hover {
  .detail-section {
    .img-name {
      .wrap-img {
        background-repeat: no-repeat !important;
        background-position: center center !important;
        width: 100% !important;
        height: 140px !important;
        margin: 0px auto !important;
        line-height: 170px !important;
        background-size: initial !important;
      }
    }
  }
}

.drag-item {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100px;
  height: 100px;
  z-index: -1;
  background-color: #fff;
  border-radius: 5px;
  .data-image {
    margin: 5px auto;
    display: block;
    height: 70px;
    border-radius: 5px;
  }
  .data-title {
    font-size: 13.5px;
    width: 100%;
    height: 20px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 3px;
  }
}
</style>
