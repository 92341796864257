<template>
  <div class="container-fluid">
    <div id="v-step-2" class="v-step-3 row row-custom row-custom-top row-overview">
      <div class="col-12 col-sm-6 col-md-3 col-custom">
        <div class="sf-card card-content">
          <div v-if="loading" class="card-body">
            <content-placeholders>
              <div class="media height-card">
                <div class="media-left">
                  <content-placeholders-img />
                </div>
                <div class="media-body">
                  <content-placeholders-text :lines="3" />
                </div>
              </div>
            </content-placeholders>
          </div>
          <div v-else class="card-body">
            <div class="media height-card">
              <div class="media-left">
                <router-link :to="'/library'">
                  <overviewFolder />
                </router-link>
              </div>
              <div class="media-body">
                <h4 class="media-heading">{{ overviewData.smart_assets_count }}</h4>
                <p class="content-first">{{ $t('library') }}</p>
                <p class="content-last">
                  {{ bytesToSize(overviewData.smart_assets_total_size || 0) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3 col-custom">
        <div class="sf-card card-loops">
          <div v-if="loading" class="card-body">
            <content-placeholders>
              <div class="media height-card">
                <div class="media-left">
                  <content-placeholders-img />
                </div>
                <div class="media-body">
                  <content-placeholders-text :lines="3" />
                </div>
              </div>
            </content-placeholders>
          </div>
          <div v-else class="card-body padding-card-loop">
            <div class="media height-card">
              <div class="media-left">
                <router-link :to="'/loop_assets'">
                  <overviewLoop />
                </router-link>
              </div>
              <div class="media-body">
                <h4 class="media-heading">{{ overviewData.loop_assets_count }}</h4>
                <p class="content-first">{{ $t('loops') }}</p>
                <p class="content-last hidden-xs">
                  {{ parseTime(overviewData.loop_assets_duration) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3 col-custom">
        <div class="sf-card card-campaigns">
          <div v-if="loading" class="card-body">
            <content-placeholders>
              <div class="media height-card">
                <div class="media-left">
                  <content-placeholders-img />
                </div>
                <div class="media-body">
                  <content-placeholders-text :lines="3" />
                </div>
              </div>
            </content-placeholders>
          </div>
          <div v-else class="card-body">
            <div class="media height-card">
              <div class="media-left">
                <router-link :to="'/campaigns'">
                  <overviewCampaign />
                </router-link>
              </div>
              <div class="media-body">
                <h4 class="media-heading">{{ overviewData.campaigns_count }}</h4>
                <p class="content-first">{{ $t('campaigns') }}</p>
                <p class="content-last">
                  {{ overviewData.active_campaigns_count }} {{ $t('active') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-3 col-custom">
        <div class="sf-card card-players">
          <div v-if="loading" class="card-body">
            <content-placeholders>
              <div class="media height-card">
                <div class="media-left">
                  <content-placeholders-img />
                </div>
                <div class="media-body">
                  <content-placeholders-text :lines="3" />
                </div>
              </div>
            </content-placeholders>
          </div>
          <div v-else class="card-body">
            <div class="media height-card">
              <div class="media-left">
                <router-link :to="'/players'">
                  <overviewPlayer />
                </router-link>
              </div>
              <div class="media-body">
                <h4 class="media-heading">{{ overviewData.players_count }}</h4>
                <p class="content-first">{{ $t('players') }}</p>
                <button
                  v-if="isAddNewPlayer"
                  @click="ShownModalAddNewPlayer"
                  class="sf-primary-add add-new-player-btn"
                  type="button"
                >
                  {{ $t('add-new-player') }}
                </button>
                <p v-else class="content-last">
                  {{ overviewData.active_players_count }} {{ $t('active') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-custom">
      <div class="col-12 col-sm-6 col-md-6 col-custom">
        <div class="row row-custom">
          <div class="col-12 col-custom">
            <div class="noti-type">{{ $t('recent-activities') }}</div>
            <div class="sf-card card-recent-active">
              <table v-if="loading" class="table">
                <tr v-for="i in 5" :key="i">
                  <td class="td-content">
                    <div class="wrap-img">
                      <i aria-hidden="true" class="fa fa-tags fa-lg"></i>
                    </div>
                  </td>
                  <td class="td-hours"><content-placeholders-text :lines="1" /></td>
                </tr>
              </table>
              <div v-else class="card-body p-0">
                <div class="sf-table">
                  <b-table
                    show-empty
                    striped
                    hover
                    :items="overviewData.logs"
                    :fields="fields"
                    head-variant="none"
                    table-variant="none"
                    thead-class="custom-b-table"
                  >
                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{ $t('loading') }}...</strong>
                      </div>
                    </template>
                    <template v-slot:empty>
                      <p class="d-flex justify-content-center">
                        {{ $t('no-data-available-in-table') }}
                      </p>
                    </template>

                    <template v-slot:head(action)="field">
                      <span>{{ field.label }}</span>
                    </template>
                    <template v-slot:cell(action)="field">
                      <p>
                        <i aria-hidden="true" class="fa fa-tags fa-lg"></i>
                        {{
                          `${field.item.user} ${$t('logs.' + field.item.action_type)} ${$t(
                            'logs.' + field.item.object_name
                          )} ${field.item.displayname} ${$t('in')}`
                        }}:
                        <router-link v-if="field.item.obj_name === 'Library'" to="/library">{{
                          $t(field.item.obj_name.toLowerCase())
                        }}</router-link>
                        <router-link v-else-if="field.item.obj_name === 'Players'" to="/players">{{
                          $t(field.item.obj_name.toLowerCase())
                        }}</router-link>
                        <router-link
                          v-else-if="field.item.obj_name === 'SmartAssetTemplates'"
                          to="/library"
                          >Library</router-link
                        >
                        <router-link
                          v-else-if="field.item.obj_name === 'Loops'"
                          to="/loop_assets"
                          >{{ $t(field.item.obj_name.toLowerCase()) }}</router-link
                        >
                        <router-link
                          v-else-if="field.item.obj_name === 'Campaigns'"
                          to="/campaigns"
                          >{{ $t(field.item.obj_name.toLowerCase()) }}</router-link
                        >
                      </p>
                    </template>

                    <template v-slot:head(time)="field">
                      <span>{{ field.label }}</span>
                    </template>
                    <template v-slot:cell(time)="field">
                      <p>{{ timeSince(field.item.act_time) }}</p>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-custom">
        <div class="noti-type">{{ $t('announcements') }}</div>
        <div class="sf-card card-anouncements">
          <!-- Default card contents
          -->
          <div v-if="loading" class="card-body padding-anouncements">
            <div v-for="i in 2" :key="i">
              <div class="alert alert-custom" role="alert">
                <content-placeholders-text :lines="1" />
              </div>
            </div>
          </div>
          <div v-else class="card-body padding-anouncements">
            <a
              v-for="(announcement, index) in overviewData.announcements"
              :key="index"
              :href="announcement.link"
            >
              <div class="alert alert-custom alert-info" role="alert">
                <div class="wrap-content-alert">
                  <div class="icon-alert">
                    <notificationBlue />
                    <p>{{ announcement.description }}</p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <add-new-player-modal ref="newPlayer"></add-new-player-modal>
  </div>
</template>

<script>
import overviewFolder from '@/assets/images/overview-folder.svg?inline'
import overviewLoop from '@/assets/images/overview-loop.svg?inline'
import overviewCampaign from '@/assets/images/overview-campaign.svg?inline'
import overviewPlayer from '@/assets/images/overview-player.svg?inline'
import notificationBlue from '@/assets/images/notification-blue.svg?inline'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { timeSince } from '@/helper/helper'
import AddNewPlayerModal from './player/AddNewPlayerModal.vue'

export default {
  name: 'home',
  components: {
    overviewFolder,
    overviewLoop,
    overviewCampaign,
    overviewPlayer,
    notificationBlue,
    AddNewPlayerModal
  },

  data() {
    return {
      envTest: process.env.VUE_APP_FOO,
      user: '',
      fields: [
        {
          key: 'action',
          colType: 'action',
          label: this.$t('action')
        },
        {
          key: 'time',
          colType: 'time',
          label: this.$t('time')
        }
      ]
    }
  },
  channels: {
    OverviewChannel: {
      connected() {},
      rejected() {},
      received(data) {
        const overviewData = this._.cloneDeep(this.overviewData)
        if (data.action === 'create') {
          overviewData[data.type + '_count'] += 1
        } else if (data.action === 'delete') {
          overviewData[data.type + '_count'] -= 1
        }
        overviewData.logs.unshift(data.data.user_log)
        overviewData.logs.pop()
        this.updateFromSocket(overviewData)
      },
      disconnected() {}
    }
  },
  methods: {
    ...mapMutations('overview', {
      updateFromSocket: 'UPDATE_FROM_SOCKET'
    }),
    fetchData() {
      this.$store.dispatch('overview/getOverview')
    },
    bytesToSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return '0 Byte'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
    },
    parseTime(d) {
      d = Number(d) / 1000
      const h = Math.floor(d / 3600)
      const m = Math.floor((d % 3600) / 60)
      const s = Math.floor((d % 3600) % 60)

      const hDisplay = h > 0 ? h + (h === 1 ? ' Hr, ' : ' Hrs, ') : ''
      const mDisplay = m > 0 ? m + (m === 1 ? ' Min, ' : ' Mins, ') : ''
      const sDisplay = s > 0 ? s + (s === 1 ? ' Sec' : ' Secs') : ''
      return hDisplay + mDisplay + sDisplay
    },
    timeSince,
    ShownModalAddNewPlayer() {
      this.$refs.newPlayer.isShownAddNewPlayerModal = true
      this.$refs.newPlayer.getProductFromChargeOver()
    }
  },
  computed: {
    ...mapState('overview', {
      overviewData: 'overviewData',
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('overview', ['isEmpty']),
    ...mapGetters('user', {
      currentUser: 'getCurrentUser'
    }),
    isAddNewPlayer() {
      return (
        this.currentUser.role &&
        this.currentUser.role.name === 'org-admin' &&
        this.overviewData.active_players_count === 0
      )
    }
  },
  mounted() {
    this.fetchData()
    this.$cable.subscribe({
      channel: 'OverviewChannel',
      organization: this.user.organization_id
    })
    this.$bus.$on('changeLanguage', (value) => {
      this.fetchData()
    })
  },
  created() {
    const userStorage = this.$cookies.get('user')
    if (userStorage) {
      const user = userStorage
      this.user = user || ''
    }
  },
  beforeDestroy() {
    this.$cable.unsubscribe('OverviewChannel')
    this.$bus.$off('changeLanguage')
  }
}
</script>

<style lang="scss" scoped>
$drop-shadow-base: 0px 4px 8px rgba(34, 49, 68, 0.04), 0px 0px 2px rgba(34, 49, 68, 0.06),
  0px 0px 1px rgba(34, 49, 68, 0.04);
.row-custom {
  margin: 0 -8px;
  font-family: 'Open Sans', sans-serif;

  &.row-custom-top {
    padding-top: 10px;
  }

  &.row-overview {
    .col-custom {
      .card-body {
        height: 150px;
        &.padding-card-loop {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  .col-custom {
    padding: 0 7px;

    .noti-type {
      padding: 5px 0;
      color: #105893;
      padding-left: 2px;
    }

    .sf-card {
      margin: 10px 0;
      border-radius: 0rem;
      border: none;
      background-color: #fff;

      .height-card {
        height: 115px;
      }

      &.card-content {
        h4,
        i {
          color: #ff7676;
        }

        box-shadow: $drop-shadow-base;
      }

      &.card-loops {
        h4,
        i {
          color: #3e6e9a;
        }
        box-shadow: $drop-shadow-base;
      }

      &.card-campaigns {
        h4,
        i {
          color: #94c862;
        }

        box-shadow: $drop-shadow-base;
      }

      &.card-players {
        h4,
        i {
          color: #dfad06;
        }

        box-shadow: $drop-shadow-base;
      }

      .media {
        .media-left {
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #f1eff2;
          width: 115px;
          height: 115px;
          padding-right: 10px;

          img {
            margin: 0px auto;
          }
        }

        .media-body {
          width: 170px;
          height: inherit;
          padding-left: 20px;
          vertical-align: middle;
          padding: 5px 0 5px 20px;
          text-align: left;
          line-height: normal;

          .media-heading {
            margin-bottom: 4px;
          }
          h4 {
            font-size: 36px;
          }

          .content-first {
            font-weight: 600;
            text-transform: uppercase;
            color: #4a4a4a;
            font-size: 14px;
            min-width: 85px;
            margin-bottom: 5px;
          }

          .content-last {
            color: #4a4a4a;
            font-size: 14px;
            margin-bottom: 0;
          }
        }
      }
    }

    .card-recent-active {
      margin: 10px 0;
      border-radius: 0;
      border: none;
      background-color: #fff;

      .table {
        margin-bottom: 0;
        tr {
          border-bottom: 1px solid #ddd;
        }
      }
      .card-heading {
        background-color: white;
        line-height: 36px;
        height: 54px;
        font-size: 14px;
        font-weight: 600;
        color: #267eb0;
        border-radius: 0px;
      }

      .td-content {
        border-bottom: 1px solid #ddd;
        border-top: none;
        padding: 15px;

        .wrap-img {
          display: table-cell;
          height: 20px;
          width: 20px;
          vertical-align: top;
          color: #80868b;

          img {
            margin: 3px 0;
          }
        }

        .wrap-p {
          display: table-cell;
          width: auto;
          padding-left: 5px;

          p {
            margin-bottom: 0;
          }
        }
      }

      .td-hours {
        min-width: 86px;
        padding: 15px 15px 15px 0;
        border-bottom: 1px solid #ddd;
        border-top: none;
      }
    }

    .card-status {
      margin: 10px 0;
      border-radius: 0;
      border: none;

      .card-heading {
        background-color: white;
        line-height: 36px;
        height: 54px;
        font-size: 14px;
        font-weight: 600;
        color: #267eb0;
        border-radius: 0px;
      }

      .list-group {
        .list-group-item {
          height: 51px;
          padding: 15px;

          .badge-custom {
            float: left;
            margin-right: 10px;

            &.badge-primary {
              background-color: #3598db;
            }

            &.badge-default {
              background-color: #80868b;
            }

            &.badge-infor {
              background-color: #ebba16;
            }
          }
        }
      }
    }

    .card-anouncements {
      border-radius: 0;
      margin: 10px 0;
      border: none;
      min-height: 170px;
      background-color: #fff;
      box-shadow: $drop-shadow-base;
      padding: 15px;

      .card-heading {
        background-color: white;
        line-height: 36px;
        height: 54px;
        font-size: 14px;
        font-weight: 600;
        color: #267eb0;
        border-radius: 0px;
      }

      .card-body {
        &.padding-anouncements {
          padding: 0;

          a {
            text-decoration: none;
          }
        }

        .alert-custom {
          border: none;
          border-radius: 0;

          &.alert-info {
            background-color: #f1f8fc;

            .wrap-img {
              i {
                color: #3598db;
              }
            }
          }

          &.alert-warning {
            background-color: #fefbf2;

            .wrap-img {
              i {
                color: #ebba16;
              }
            }
          }

          &.alert-danger {
            background-color: #fff5f5;

            .wrap-img {
              i {
                color: #ff7676;
              }
            }
          }

          &.alert-success {
            background-color: #f4f9ef;

            .wrap-img {
              i {
                color: #94c862;
              }
            }
          }

          .wrap-img {
            display: table-cell;
            width: 30px;
            overflow: hidden;
            //                        color: #80868b;
          }

          .wrap-content-alert {
            display: table-cell;
            vertical-align: top;

            .icon-alert {
              display: flex;

              p {
                margin-left: 10px;
                margin-bottom: 0;
              }
            }
            .title {
              float: left;
              font-weight: 600;
            }

            .hours {
              float: right;
            }

            .content-alert {
              margin-top: 10px;
              padding-left: 30px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1400px) {
    .add-new-player-btn {
      padding: 0px 8px 0px 16px;
      background-position: 4px center;
    }
  }
}
</style>
