import state from './loopDetailState'
import mutations from './loopDetailMutations'
import getters from './loopDetailGetters'
import actions from './loopDetailActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
