import BaseService from './base.service'

class CustomFontService extends BaseService {
  get entity() {
    return 'custom_fonts'
  }

  getCustomFont() {
    return new Promise((resolve, reject) => {
      return this.request({
        auth: true
      })
        .get(`${this.entity}`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  createCustomFont(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}`, data)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }
}

export default new CustomFontService()
