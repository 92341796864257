export default {
  // Loop page
  isEmpty: (state) => !state.items.length,
  items: (state) => customItems(state.items),
  treeFolder: (state) => state.treeFolder,
  breadcrumb: (state) => state.breadcrumb,
  pagination: (state) => state.pagination,
  pagePagination: (state) => state.pagination.page,
  totalPagination: (state) => state.pagination.total,
  limitPagination: (state) => state.pagination.limit
  // ===========================================================================
}

function customItems(items) {
  const itemOtherSubOrg = items.filter((x) => x.folder_type !== 'sub_organization')
  const folderSubOrganization = items.filter((x) => x.folder_type === 'sub_organization')
  return [...folderSubOrganization, ...itemOtherSubOrg]
}
