import state from './adminLanguageState'
import mutations from './adminLanguageMutations'
import getters from './adminLanguageGetters'
import actions from './adminLanguageActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
