export default {
  SET_OVERVIEW_DATA(state, overviewData) {
    state.overviewData = overviewData
  },
  UPDATE_FROM_SOCKET(state, data) {
    state.overviewData = data
  },
  SET_ERROR(state, data) {
    state.error = data
  },

  SET_LOADING(state, data) {
    state.loading = data
  }
}
