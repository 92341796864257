var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uikit uikit-field"},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"row mb-3"},[_vm._m(4),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"sf-select-group mb-3"},[_c('Select2',{staticClass:"sf-select2",attrs:{"options":[
              { id: '1', text: 'Option 1' },
              { id: '2', text: 'Option 2' },
              { id: '3', text: 'Option 3' }
            ]},model:{value:(_vm.selectValue),callback:function ($$v) {_vm.selectValue=$$v},expression:"selectValue"}}),_c('span',{staticClass:"lb-right caret"})],1)])]),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('div',{staticClass:"row mb-3"},[_vm._m(9),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"sf-search-wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchValue),expression:"searchValue"}],staticClass:"sf-input-search",attrs:{"type":"text","placeholder":"Search loop"},domProps:{"value":(_vm.searchValue)},on:{"input":function($event){if($event.target.composing)return;_vm.searchValue=$event.target.value}}}),_c('span',{staticClass:"sf-input-search-icon",style:({ backgroundImage: `url(${_vm.getSearchIcon})` }),on:{"click":_vm.onSearchClick}})])])]),_vm._m(10),_vm._m(11),_vm._m(12),_c('div',{staticClass:"row mb-3"},[_vm._m(13),_c('div',{staticClass:"col-3"},[_c('vue-tags-input',{class:'sf-tag',attrs:{"tags":_vm.tags},on:{"tags-changed":(newTags) => (_vm.tags = newTags)},model:{value:(_vm.tag),callback:function ($$v) {_vm.tag=$$v},expression:"tag"}})],1)]),_c('div',{staticClass:"row mb-3"},[_vm._m(14),_c('div',{staticClass:"col-5"},[_c('div',{staticClass:"sf-form-group py-3"},[_vm._m(15),_c('div',{staticClass:"sf-range-slider-group pl-2"},[_c('vue-slider',{staticClass:"sf-input-range-slider",model:{value:(_vm.rangeSlider),callback:function ($$v) {_vm.rangeSlider=$$v},expression:"rangeSlider"}})],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"material-name"},[_c('h2',[_vm._v("Input field")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("TEXT FIELD")])]),_c('div',{staticClass:"col-3"},[_c('input',{staticClass:"sf-input-text",attrs:{"type":"text","placeholder":"placeholder text"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("CHECKBOX")])]),_c('div',{staticClass:"col-6"},[_c('label',{staticClass:"sf-checkbox"},[_c('span',{staticClass:"check-title"},[_vm._v("Label")]),_c('input',{attrs:{"type":"checkbox","checked":"checked"}}),_c('span',{staticClass:"checkmark"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("RADIO")])]),_c('div',{staticClass:"col-6"},[_c('label',{staticClass:"sf-radio"},[_vm._v("One "),_c('input',{attrs:{"type":"radio","checked":"checked","name":"radio"}}),_c('span',{staticClass:"checkmark"})]),_c('label',{staticClass:"sf-radio"},[_vm._v("Two "),_c('input',{attrs:{"type":"radio","name":"radio"}}),_c('span',{staticClass:"checkmark"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("SELECT & SEARCH")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("LIST ITEM")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"list-item"},[_vm._v("List item")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("LIST ITEM ACTIVE")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"list-item active"},[_vm._v("List item")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("LIST ITEM WITH ICON")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"list-item-icon"},[_vm._v("List item")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("LIST ITEM WITH ICON ACTIVE")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"list-item-icon"},[_vm._v("List item")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("SEARCH")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("TEXT FIELD AND LABELS")])]),_c('div',{staticClass:"col-5"},[_c('div',{staticClass:"sf-form-group"},[_c('div',{staticClass:"sf-label-group"},[_c('label',{staticClass:"primary-label"},[_vm._v("Label")])]),_c('input',{staticClass:"sf-input-text",attrs:{"type":"text","placeholder":"placeholder text"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("TEXT FIELD AND LABELS")])]),_c('div',{staticClass:"col-5"},[_c('div',{staticClass:"sf-form-group multi-row"},[_c('div',{staticClass:"sf-label-group"},[_c('label',{staticClass:"primary-label"},[_vm._v("Primary Label")]),_c('label',{staticClass:"secondary-label"},[_vm._v("Secondary label ")])]),_c('input',{staticClass:"sf-input-text",attrs:{"type":"text","placeholder":"placeholder text"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("TEXT FIELD AND LABELS")])]),_c('div',{staticClass:"col-5"},[_c('div',{staticClass:"sf-form-group multi-row"},[_c('div',{staticClass:"sf-label-group"},[_c('label',{staticClass:"primary-label"},[_vm._v("Primary Label")]),_c('label',{staticClass:"secondary-label"},[_vm._v("Secondary label wrapping to next line ")])]),_c('input',{staticClass:"sf-input-text",attrs:{"type":"text","placeholder":"placeholder text"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("TEXT FIELD")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("INPUT RANGE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sf-label-group"},[_c('label',{staticClass:"primary-label"},[_vm._v("Label")])])
}]

export { render, staticRenderFns }