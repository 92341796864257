<template>
  <transition name="modal">
    <div class="sf-modal overlay component" v-if="show" @click="onClickOverlay" ref="overlay">
      <div
        v-bind:style="{
          width: width + 'px',
          height: height ? height + 'px' : 'auto'
        }"
        class="sf-modal-content"
      >
        <div class="sf-modal-header">
          <h3 class="title">{{ title }}</h3>
          <button class="close" @click="close" v-if="showCloseButton"></button>
        </div>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SfModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    },
    width: Number,
    title: String,
    height: Number,
    background: String
  },

  methods: {
    close() {
      this.$emit('onClose')
      this.$emit('update:show', false)
    },
    onClickOverlay($event) {
      if (this.closeOnOverlay && $event && $event.target === this.$refs.overlay) {
        this.close()
      }
    }
  },
  mounted() {
    window.addEventListener(
      'keydown',
      (event) => {
        if (event.keyCode === 27) {
          this.close()
          this.$emit('onClose')
        }
      },
      true
    )
  }
}
</script>

<style lang="scss" scoped></style>
