<template>
  <div>
    <div class="view-user">
      <div class="header-user">
        <h2>{{ $t('user-information') }}</h2>
      </div>
      <div class="cover-content">
        <div class="content-user d-flex">
          <p class="title-user">{{ $t('name') }}:</p>
          <p class="info-user">{{ infoUserByID.first_name }} {{ infoUserByID.last_name }}</p>
        </div>
        <div class="content-user d-flex">
          <p class="title-user">{{ $t('email') }}:</p>
          <p class="info-user">{{ infoUserByID.email }}</p>
        </div>
        <div class="content-user d-flex">
          <p class="title-user">{{ $t('organization') }}:</p>
          <p class="info-user">
            {{ infoUserByID.organization ? infoUserByID.organization.name : '' }}
          </p>
        </div>
        <div class="content-user d-flex">
          <p class="title-user">{{ $t('role') }}:</p>
          <p class="info-user">{{ infoUserByID.user_sub_type }}</p>
        </div>
        <button @click="backPageAccount" class="back-page">{{ $t('back') }}</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ViewUser',
  data() {
    return {
      currentUserID: '',
      userID: '',
      from: ''
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from
    })
  },
  methods: {
    backPageAccount() {
      this.$router.push(this.from.path)
    },
    getUserID() {
      const userStr = this.$cookies.get('user')
      if (userStr) {
        this.userID = userStr.id
      }
    }
  },
  mounted() {
    this.currentUserID = this.$route.params.userID
    this.$store.dispatch('user/getInfoUserByID', this.currentUserID)
    this.getUserID()
  },
  computed: {
    ...mapState('user', {
      infoUserByID: 'infoUserByID'
    })
  }
}
</script>
<style lang="scss" scoped>
.header-user {
  h2 {
    padding: 13px 0 0 30px;
    font-size: 30px;
    font-weight: 600;
    color: #00325b;
  }
}

.view-user {
  background: #ffffff;
}

.cover-content {
  margin-top: 20px;
  padding-bottom: 20px;
}

.content-user {
  padding: 13px 0 0 30px;
  .title-user {
    color: #212529;
    font: 15px 'Open Sans', sans-serif;
    font-weight: bolder;
  }
  .info-user {
    color: #212529;
    font: 15px 'Open Sans', sans-serif;
    padding-left: 5px;
  }
}
.back-page {
  background-color: #2c7fe1;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  width: 150px;
  margin: 0 10px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #2c7fe1;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  margin: 13px 0 0 30px;
}
</style>
