export default {
  loading: false,
  loadingPlayer: false,
  loadingCampaign: false,
  loadingOfUser: false,
  loadingRole: false,
  error: false,
  items: [],
  itemsByID: [],
  dataTablePlayer: [],
  dataTableCampaign: [],
  dataTableUser: [],
  permissionList: [],
  roleList: [],
  roleListByOrg: [],
  roleListByOrgNoPaging: [],
  roleListNoPaging: [],
  pagination: {
    limit: 10,
    page: 0,
    total: 0
  },
  pricingOptions: [{ id: '', text: 'Select Pricing' }],
  orgTypeOptions: [{ id: 0, text: 'Select Org Type' }],
  countryOptions: [{ id: '', text: 'Select a Country' }],
  parentOrg: [{ id: '', text: 'Select a Organization' }],
  provinceStateOptions: [{ id: '', text: 'Select a Province/State' }]
}
