<template>
  <div class="account-linked-com">
    <div class="overall">
      <p class="action-title">{{ $t('settings') }}</p>
      <p class="action-desc">{{ $t('check-instagram-connection-status-message') }}</p>
    </div>
    <div class="ig-account">
      <p class="ig-account-title mb-10">{{ $t('instagram-linked-account') }}:</p>
      <button v-if="instagramData" class="btn btn-ig-add btn-ig-add-success">
        @{{ instagramData.username }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    instagramData() {
      const igData = this.$store.getters['instagrameditor/getInstagramData']
      return igData
    }
  },
  methods: {}
}
</script>
<style scoped>
.overall-title {
  font-size: 16px;
  font-weight: 600;
  color: #2c7fe1;
}
.ig-account {
  margin-top: 15px;
}
</style>
