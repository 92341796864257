export default {
  folder: 'folder',
  video: 'video',
  image: 'image',
  pdf: 'pdf',
  instagram: 'instagram',
  website: 'web',
  youtube: 'youtube',
  loop: 'loop_asset',
  loops: 'loops',
  custom_asset: 'custom_asset',
  google_sheet: 'google_sheet',
  google_slide: 'google_slide',
  canva: 'canva'
}
