<template>
  <SfModal
    :title="$t('modal.title.edit-folder')"
    :width="768"
    :height="0"
    closeOnOverlay
    :show.sync="dataShow"
    @update:show="updateShow"
  >
    <div class="sf-modal-body">
      <div id="folder-asset-form-error-container"></div>
      <div class="container">
        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('name') }}</label>
              </div>
              <input
                class="sf-input-text"
                type="text"
                v-model="dataInput.name"
                name="folder"
                id="folder_name"
                :class="{ 'input-error-form': submitted && $v.dataInput.name.$invalid }"
                :placeholder="$t('placeholder.name')"
              />
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.name.required"
            >
              {{ $t('name-required') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sf-modal-footer mt-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="sf-modal-action">
              <button class="sf-secondary" type="button" @click="updateShow">
                {{ $t('cancel') }}
              </button>
              <button type="button" class="sf-primary" @click="onClickSave">
                {{ $t('save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SfModal>
</template>

<script>
import SfModal from '@/components/SfModal'
import { required, maxLength } from 'vuelidate/lib/validators'
export default {
  components: {
    SfModal
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dataShow: this.show,
      submitted: false,
      dataInput: {
        name: ''
      }
    }
  },

  watch: {
    show(value) {
      this.dataShow = value
      this.dataInput = { ...this.data }
    }
  },
  validations: {
    dataInput: {
      name: {
        required,
        maxLength: maxLength(250)
      }
    }
  },
  methods: {
    updateShow() {
      this.submitted = false
      this.$emit('update:show', false)
    },
    onClickSave() {
      this.submitted = true
      if (this.$v.dataInput.$invalid) {
        return
      }
      this.$emit('modal:save', { ...this.dataInput })
    }
  },
  computed: {},
  created() {},
  beforeDestroy() {
    this.dataInput = {
      name: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style-modal-smart-asset';
</style>
