export default {
  listProgramOptions: (state) => {
    const listProgramOptions = [
      {
        id: 0,
        text: 'Please Choose a Plan'
      }
    ]
    state.allProgramAdmin.map((program) => {
      return listProgramOptions.push({
        id: program.id,
        text: program.name
      })
    })
    return listProgramOptions
  },
  listProgramOptionsEdit: (state) => {
    const listProgramOptions = []
    state.allProgramAdmin.map((program) => {
      return listProgramOptions.push({
        id: program.id,
        text: program.name
      })
    })
    return listProgramOptions
  }
}
