import state from './adminTermState'
import mutations from './adminTermMutations'
import getters from './adminTermGetters'
import actions from './adminTermActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
