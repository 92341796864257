import BaseService from './base.service'

class AdminProgramService extends BaseService {
  get entity() {
    return 'admin/pay_programs'
  }

  getAllAdminProgram() {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/all_pay_program`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
}
export default new AdminProgramService()
