import forEach from 'lodash/forEach'
import isArray from 'lodash/isArray'
import $router from '../router'
import Vue from 'vue'
/**
 * Return message for HTTP status code
 * @param {number} status - HTTP status code
 * @returns {string} Message of network operation
 */
function _getStatusMessage(status) {
  let message = ''
  switch (status) {
    case 200:
      message = 'All done. Request successfully executed'
      break
    case 201:
      message = 'Data successfully created'
      break
    case 400:
      message = 'Bad Request'
      break
    case 401:
      message = 'Need auth'
      break
    case 404:
      message = 'Not found'
      break
    case 503:
      message = 'Service Unavailable'
      break
    default:
      message = 'Something wrong. Client default error message'
      break
  }
  return message
}

function _getResponseErrorMessage(error) {
  if (error.response && error.response.data) return error.response.data.description
  if (error.response && error.response.statusText) return error.response.statusText
  if (error.message) return error.message
  return "_getResponseErrorMessage can't handle error"
}

/**
 * Create instant, which represent response object
 * @param {Object} [data] - custom data
 * @param {Object} [response] - axios response object
 * @param {String} [message] - custom message to display
 */
export class ResponseWrapper {
  constructor(response, data = {}, message) {
    this.data = data
    this.success = response.data.success
    this.status = response.status
    this.statusMessage = _getStatusMessage(this.status)
    this.message = message || null
    this.refreshUser = response.data.last_update_user || null
  }
}

/**
 * Create instant, which represent error object
 * @param {Object} [error] - axios error object
 * @param {String} [message] - custom message to display
 */
export class ErrorWrapper extends Error {
  constructor(error, message) {
    super()
    this.name = 'ErrorWrapper'
    this.stack = new Error().stack
    this.success = error.response ? error.response.data.success : false
    this.status = error.response ? error.response.status : false
    this.statusMessage = _getStatusMessage(this.status)
    if (error.message === 'Network Error') {
      this.message = error.message
    } else {
      this.message = error.response.data.message || _getResponseErrorMessage(error)
    }
    if (error.response && error.response.data && error.response.data.data) {
      this.data = error.response.data.data
    }

    const statusCode = error.response && error.response.status
    if (statusCode === 401) {
      Vue.$cookies.remove('accessToken')
      Vue.$cookies.remove('refreshToken')
      Vue.$cookies.remove('tokenExpired')
      Vue.$cookies.remove('rememberMe')
      Vue.$cookies.remove('user')
      location.reload()
    }

    if (statusCode === 403) {
      $router.push('/term-user')
    }
  }
}

/**
 * Uses to clear request data before send it
 * Client shouldn't change entity id
 * @param data
 * @return {{}}
 */
export function clearData(data) {
  const result = {}
  forEach(data, (item, propName) => {
    if (isArray(item) && item.length) {
      result[propName] = item
    }
    if (!isArray(item) && item && propName !== 'id') {
      result[propName] = item
    }
  })
  return result
}
