import state from './adminOverviewState'
import mutations from './adminOverviewMutations'
import getters from './adminOverviewGetters'
import actions from './adminOverviewActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
