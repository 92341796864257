<template>
  <div class="container-fluid">
    <div class="header-campaign d-flex justify-content-between">
      <!-- Search Player -->
      <div :class="{ 'd-none': !(activeButton === 'players') }">
        <div class="d-flex align-items-center">
          <SfInputSearch
            v-model="dataSearch"
            :placeholder="$t('search')"
            v-debounce:500ms="searchInputChange"
            :height="'40px'"
            @onSearchInputChange="resetSearchPlayer"
          />
          <multiselect
            class="sf-multiselect ml-3"
            style="width: 150px"
            v-model="filterOrientation"
            :options="listOptionOrientation"
            :searchable="false"
            :allow-empty="false"
            deselectLabel=""
            selectLabel=""
            selectedLabel=""
            label="name"
            track-by="name"
            @select="changeOrientation"
          >
          </multiselect>
        </div>
      </div>

      <!-- Search Group -->
      <div :class="{ 'd-none': !(activeButton === 'groups') }">
        <SfInputSearch
          v-model="dataSearchGroup"
          :placeholder="$t('search')"
          v-debounce:500ms="groupSearchInputChange"
          :height="'40px'"
          @onSearchInputChange="resetSearchGroup"
        />
      </div>

      <!-- Search Player Children -->
      <div :class="{ 'd-none': !(activeButton === 'suborg') }">
        <SfInputSearch
          v-model="dataSearchChild"
          :placeholder="$t('search')"
          v-debounce:500ms="inputPlayerChildren"
          :height="'40px'"
          @onSearchInputChange="resetSearchPlayerChildren"
        />
      </div>

      <div>
        <button @click="ShownModalCreateGroup" class="sf-primary-add mr-2" type="button">
          {{ $t('add-new-group') }}
        </button>
        <button @click="ShownModalAddNewPlayer" class="sf-primary-add" type="button">
          {{ $t('add-new-player') }}
        </button>
      </div>
    </div>
    <div class="row players-tabs-wraper">
      <div class="col-6">
        <div class="sf-sub-menu">
          <div class="col-md-12 top-menu">
            <ul class="nav nav-tabs nav-tabs-custom">
              <li class="acount-menu-item">
                <a
                  @click="changeTab('players')"
                  :class="{ 'is-active': activeButton === 'players' }"
                  >{{ $t('players') }}</a
                >
              </li>
              <li class="acount-menu-item">
                <a
                  @click="changeTab('groups')"
                  :class="{ 'is-active': activeButton === 'groups' }"
                  >{{ $t('groups') }}</a
                >
              </li>
              <li
                v-if="
                  isParentOrg &&
                  this.currentUser.organization &&
                  this.currentUser.organization.children > 0
                "
                class="acount-menu-item"
              >
                <a
                  @click="changeTab('suborg')"
                  :class="{ 'is-active': activeButton === 'suborg' }"
                  >{{ $t('sub-organizations') }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-show="activeButton === 'suborg' || activeButton === 'players'" class="col-3">
        <div class="row pb-2 h-100 align-items-center">
          <a :href="downloadPlayerURL">{{ $t('download-csv') }}</a>
        </div>
      </div>
      <div class="col-3">
        <div v-if="activeButton === 'players'" class="row mb-3">
          <div v-if="pagination.total > 0" class="col-12 d-flex justify-content-end">
            <b-pagination
              class="sf-b-pagination"
              :total-rows="limitPage.value * pagination.total"
              :per-page="limitPage.value"
              @change="changePage"
              :limit="6"
              v-model="currentPage"
            />
            <SfOptionLimitPage @changeLimitPage="changeLimitPage" :limitPage.sync="limitPage" />
          </div>
        </div>
        <div v-if="activeButton === 'groups'" class="row mb-3">
          <div v-if="paginationGroup.total > 0" class="col-12 d-flex justify-content-end">
            <b-pagination
              class="sf-b-pagination"
              :total-rows="paginationGroup.limit * paginationGroup.total"
              :per-page="paginationGroup.limit"
              @change="changePageGroup"
              :limit="6"
              v-model="currentPageOfGroup"
            >
            </b-pagination>
            <SfOptionLimitPage
              @changeLimitPage="changeLimitPageGroup"
              :limitPage.sync="limitPageGroup"
            />
          </div>
        </div>
        <div v-if="activeButton === 'suborg'" class="row mb-3">
          <div v-if="dataPlayerChildren.total_pages > 0" class="col-12 d-flex justify-content-end">
            <b-pagination
              class="sf-b-pagination"
              :total-rows="paginationSubOrganization.limit * paginationSubOrganization.total"
              :per-page="paginationSubOrganization.limit"
              @change="changePagePlayerChild"
              :limit="6"
              v-model="currentPageOfSubOrganization"
            >
            </b-pagination>
            <SfOptionLimitPage
              @changeLimitPage="changeLimitPagePlayerChild"
              :limitPage.sync="limitPagePlayerChild"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="sf-table">
      <!-- <div class="list-button d-flex">
        <div @click="changePlayerTab" :class="{'activeButton': activeButton === 'players'}">PLAYERS</div>
        <div @click="changeGroupTab" :class="{'activeButton': activeButton === 'groups'}">GROUPS</div>
      </div> -->
      <!-- Table Player -->

      <b-table
        :class="{ 'dl-none': !(activeButton === 'players') }"
        show-empty
        striped
        hover
        :items="listPlayer"
        :fields="fields"
        head-variant="none"
        table-variant="none"
        thead-class="custom-b-table"
        :busy="loading"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>{{ $t('loading') }}...</strong>
          </div>
        </template>
        <template v-slot:empty>
          <p class="d-flex justify-content-center">{{ $t('no-data-available-in-table') }}</p>
        </template>

        <template v-slot:head(image)="field">
          {{ field.label }}
        </template>
        <template v-slot:cell(image)="field">
          <img
            v-if="field.item.orientation && field.item.rotate"
            class="img-player"
            :class="field.item.orientation"
            :src="
              require('@/assets/images/' +
                field.item.orientation +
                '-' +
                field.item.rotate +
                '.svg')
            "
          />
        </template>

        <template v-slot:head(name)="field">
          <span class="custome-arrows">{{ $t('name') }}</span>
        </template>
        <template v-slot:cell(name)="field">
          <p>
            <router-link
              :to="`/players/${field.item.player_code}`"
              :class="{ 'text-color-grey-40': !field.item.activated }"
              >{{ field.item.name }}</router-link
            >
          </p>
        </template>

        <template v-slot:head(running_campaign)="field">
          <span class="custome-arrows">{{ $t('running-campaign') }}</span>
        </template>
        <template v-slot:cell(running_campaign)="field">
          <p>
            <router-link :to="`/campaigns/${field.item.campaign_code}/edit`"
              >{{ field.item.running_campaign }}
            </router-link>
          </p>
        </template>

        <template v-slot:head(group_player)="field">
          <span class="custome-arrows">{{ $t('group') }}</span>
        </template>
        <template v-slot:cell(group_player)="field">
          <p v-if="field.item.group_player">
            {{ field.item.group_player.name }}
          </p>
        </template>

        <template v-slot:head(status)="field">
          <span class="custome-arrows">{{ $t('status') }}</span>
        </template>
        <template v-slot:cell(status)="field">
          <p v-if="field.item.status" class="online" :title="timeSince(field.item.last_connection)">
            Online
          </p>
          <p v-else-if="!field.item.status && field.item.last_connection && field.item.activated">
            {{ timeSince(field.item.last_connection) }}
          </p>
        </template>

        <template v-slot:head(action)="field">
          <span>{{ $t('actions') }}</span>
        </template>
        <template v-slot:cell(action)="field">
          <button
            @click="editPlayer(field.item.id)"
            class="button-edit-table"
            :title="$t('tooltip.edit')"
          ></button>
          <button
            v-if="!field.item.activated"
            @click="shownModalDeletePlayer(field.item.id)"
            class="button-delete-table"
            :title="$t('tooltip.delete')"
          ></button>
        </template>
      </b-table>

      <!-- Group Table -->

      <b-table
        :class="{ 'dl-none': !(activeButton === 'groups') }"
        show-empty
        striped
        hover
        :items="listPlayerGroup"
        :fields="fieldsGroup"
        head-variant="none"
        table-variant="none"
        thead-class="custom-b-table"
        :busy="loading"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>{{ $t('loading') }}...</strong>
          </div>
        </template>
        <template v-slot:empty>
          <p class="d-flex justify-content-center">{{ $t('no-data-available-in-table') }}</p>
        </template>
        <template v-slot:head(image)="field">
          {{ field.label }}
        </template>
        <template v-slot:cell(image)="field">
          <img
            v-if="field.item.orientation"
            class="img-player"
            :class="field.item.orientation"
            :src="
              require('@/assets/images/' +
                field.item.orientation +
                '-' +
                field.item.rotate +
                '.svg')
            "
          />
        </template>

        <template v-slot:head(name)="field">
          <span class="custome-arrows">{{ $t('name') }}</span>
        </template>
        <template v-slot:cell(name)="field">
          <p>{{ field.item.name }}</p>
        </template>

        <template v-slot:head(campaign)="field">
          <span class="custome-arrows">{{ $t('campaign') }}</span>
        </template>
        <template v-slot:cell(campaign)="field">
          <p>{{ field.item.campaign ? field.item.campaign.displayname : '' }}</p>
        </template>

        <template v-slot:head(players)="field">
          <span class="custome-arrows">{{ $t('players') }}</span>
        </template>
        <template v-slot:cell(players)="field">
          <p>{{ field.item.players ? field.item.players.length : 0 }}</p>
        </template>

        <template v-slot:head(action)="field">
          <span class="">{{ field.label }}</span>
        </template>
        <template v-slot:cell(action)="field">
          <button
            :title="$t('tooltip.edit')"
            @click="shownModalEditGroup(field.item.id)"
            class="button-edit-table"
          ></button>
          <button
            :title="$t('tooltip.delete')"
            @click="shownModalDelete(field.item.id)"
            class="button-delete-table"
          ></button>
        </template>
      </b-table>

      <div class="row mb-3" :class="{ 'd-none': !(activeButton === 'suborg') }">
        <div class="col-2">
          <div class="sf-select-group">
            <Select2
              class="sf-select2"
              v-model="subOrgSelected"
              :options="optionsSubOrg"
              @select="selectSubOrg($event)"
            />
            <span class="lb-right caret"></span>
          </div>
        </div>
      </div>

      <b-table
        :class="{ 'dl-none': !(activeButton === 'suborg') }"
        show-empty
        striped
        hover
        :items="dataPlayerChildren.items"
        :fields="fieldsPlayerChild"
        head-variant="none"
        table-variant="none"
        thead-class="custom-b-table"
        :busy="isLoadingDataPlayerChildren"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>{{ $t('loading') }}...</strong>
          </div>
        </template>
        <template v-slot:empty>
          <p class="d-flex justify-content-center">{{ $t('no-data-available-in-table') }}</p>
        </template>
        <template v-slot:head(activated)="field">
          {{ field.label }}
        </template>
        <template v-slot:cell(activated)="field">
          <div v-if="field.item.activated" class="player-active"></div>
          <div v-else class="player-inactive"></div>
        </template>

        <template v-slot:head(image)="field">
          {{ field.label }}
        </template>
        <template v-slot:cell(image)="field">
          <img
            v-if="field.item.orientation && field.item.rotate"
            class="img-player"
            :class="field.item.orientation"
            :src="
              require('@/assets/images/' +
                field.item.orientation +
                '-' +
                field.item.rotate +
                '.svg')
            "
          />
        </template>

        <template v-slot:head(name)="field">
          <span class="custome-arrows">{{ $t('name') }}</span>
        </template>
        <template v-slot:cell(name)="field">
          <p>
            <router-link :to="`/players/${field.item.player_code}`">{{
              field.item.name
            }}</router-link>
          </p>
        </template>

        <template v-slot:head(running_campaign)="field">
          <span class="custome-arrows">{{ $t('running-campaign') }}</span>
        </template>
        <template v-slot:cell(running_campaign)="field">
          <p>
            <router-link :to="`/campaigns/${field.item.campaign_code}/edit`"
              >{{ field.item.running_campaign }}
            </router-link>
          </p>
        </template>

        <template v-slot:head(group_player)="field">
          <span class="custome-arrows">{{ $t('group') }}</span>
        </template>
        <template v-slot:cell(group_player)="field">
          <p v-if="field.item.group_player">
            {{ field.item.group_player.name }}
          </p>
        </template>

        <template v-slot:head(status)="field">
          <span class="custome-arrows">{{ $t('status') }}</span>
        </template>
        <template v-slot:cell(status)="field">
          <p v-if="field.item.status" class="online" :title="timeSince(field.item.last_connection)">
            Online
          </p>
          <p v-else-if="!field.item.status && field.item.last_connection && field.item.activated">
            {{ timeSince(field.item.last_connection) }}
          </p>
        </template>

        <template v-slot:head(action)="field">
          <span>{{ field.label }}</span>
        </template>
        <template v-slot:cell(action)="field">
          <button
            @click="editPlayer(field.item.id)"
            class="button-edit-table"
            title="Edit"
          ></button>
          <button
            v-if="!field.item.activated"
            @click="shownModalDeletePlayer(field.item.id)"
            class="button-delete-table"
            title="Delete"
          ></button>
        </template>
      </b-table>
    </div>

    <SfModal
      :title="$t('modal.title.add-player')"
      :width="400"
      closeOnOverlay
      :show.sync="isShownModal"
    >
      <div class="form-group">
        <div class="form-body">
          <p>{{ $t('call-order-message') }}</p>
          <p>{{ $t('tell') }}: (844) 772-7336</p>
        </div>
      </div>
      <div class="sf-modal-footer">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" @click="isShownModal = false">
                  {{ $t('cancel') }}
                </button>
                <button class="sf-primary" @click="isShownModal = false">{{ $t('ok') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>
    <add-new-player-modal ref="newPlayer" @reloadPlayer="reloadPlayer"></add-new-player-modal>
    <SfModal
      :title="$t('modal.title.edit-player')"
      :width="786"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="container">
        <div class="row my-3">
          <div class="col-12">
            <ul class="sf-tabs">
              <li>
                <a
                  @click="changeTabEditModal('general')"
                  :class="{ active: activeTabModal === 'general' }"
                  >{{ $t('general') }}</a
                >
              </li>
              <li>
                <a
                  @click="changeTabEditModal('advanced')"
                  :class="{ active: activeTabModal === 'advanced' }"
                  >{{ $t('advanced') }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-if="activeTabModal === 'general'" class="container">
        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('name') }}</label>
              </div>
              <input
                v-model="namePlayer"
                class="sf-input-text"
                :placeholder="$t('placeholder.name')"
              />
            </div>
            <p v-if="!namePlayer && showError" class="error-text text-right">
              {{ $t('name-required') }}
            </p>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('description') }}</label>
              </div>
              <input
                v-model="descriptionPlayer"
                class="sf-input-text"
                :placeholder="$t('placeholder.description')"
              />
            </div>
            <p
              v-if="descriptionPlayer && descriptionPlayer.length > 500 && showError"
              class="error-text text-right"
            >
              {{ $t('long-text-validation') }}
            </p>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('location') }}</label>
              </div>
              <gmap-autocomplete
                class="sf-input-text"
                @place_changed="getAddressData"
                :placeholder="`${$t('placeholder.address')} ..`"
                :value="infoPlayerID.location"
              >
              </gmap-autocomplete>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('time-zone') }}</label>
              </div>
              <div class="sf-select-group">
                <Select2
                  class="sf-select2"
                  :value="infoPlayerID.time_zone_value"
                  @change="chooseTimezone"
                  :options="listTimezoneOptions"
                />
                <span class="lb-right caret"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('rotation') }}</label>
              </div>
              <div class="sf-select-group">
                <Select2
                  class="sf-select2"
                  :value="infoPlayerID.rotate"
                  :options="[
                    { id: 'default', text: $t('default') },
                    { id: 'flipped', text: $t('flipped') }
                  ]"
                  @change="changeRotate"
                  :settings="{ minimumResultsForSearch: -1 }"
                />
                <span class="lb-right caret"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('campaign') }}</label>
              </div>
              <div class="sf-select-group campaign-input">
                <Select2
                  class="sf-select2"
                  v-if="infoPlayerID.running_campaign"
                  @select="changeCampaign($event)"
                  :value="infoPlayerID.running_campaign.id"
                  :options="listCampaignOptionsForEditPlayer"
                ></Select2>
                <Select2
                  class="sf-select2"
                  v-else
                  :options="listCampaignOptionsForEditPlayer"
                  @select="changeCampaign($event)"
                ></Select2>
                <span class="lb-right caret"></span>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('tags') }}</label>
              </div>
              <vue-tags-input
                class="sf-tag"
                v-model="tag"
                :tags="infoPlayerID.tags"
                @tags-changed="(newTags) => setPlayerTags(newTags)"
                :placeholder="$t('placeholder.add-tags')"
              >
                <template slot="tag-center" slot-scope="{ tag }">
                  <span :title="tag.text || tag.name">{{ tag.text || tag.name }}</span>
                </template>
              </vue-tags-input>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="container">
        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('browser-cache') }}</label>
              </div>
              <div class="w-100">
                <toggle-button
                  :value="infoPlayerID.is_browser_cache"
                  :sync="true"
                  :width="44"
                  :height="22"
                  :margin="2"
                  @change="onChangeToggleCacheBrowser"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('kiosk-mode') }}</label>
              </div>
              <div class="w-100">
                <toggle-button
                  :value="infoPlayerID.is_kiosk_mode"
                  :sync="true"
                  :width="44"
                  :height="22"
                  :margin="2"
                  @change="onChangeToggleKioskMode"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('wifi-type') }}</label>
              </div>
              <div class="w-100">
                <toggle-button
                  :value="infoPlayerID.is_wifi_type"
                  :sync="true"
                  :width="44"
                  :height="22"
                  :margin="2"
                  @change="onChangeToggleWifiType"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalEdit = false">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="updatePlayer">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>
    <SfModal
      :title="$t('modal.title.add-group-player')"
      :width="786"
      closeOnOverlay
      :show.sync="isShownModalCreateGroup"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group sf-label-group-lg">
                  <label class="primary-label">{{ $t('player-group-name') }}</label>
                </div>
                <input
                  :placeholder="$t('placeholder.name')"
                  v-model="infoAddGroup.group_player.name"
                  class="sf-input-text"
                />
              </div>
              <p v-if="showError && !infoAddGroup.group_player.name" class="error-text text-right">
                {{ $t('group-name-required') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group sf-label-group-lg">
                  <label class="primary-label">{{ $t('orientation') }}</label>
                </div>
                <div class="term-info">
                  <div class="d-flex align-items-center">
                    <button
                      class="button-term"
                      @click="checkOrientation('landscape')"
                      :class="{ 'term-active': checkedOrientation === 'landscape' }"
                    ></button>
                    <p @click="checkOrientation('landscape')">
                      {{ $t('landscape') }} ({{ $t('default') }})
                    </p>
                  </div>
                  <div class="d-flex align-items-center" style="margin-left: 20px">
                    <button
                      class="button-term"
                      @click="checkOrientation('portrait')"
                      :class="{ 'term-active': checkedOrientation === 'portrait' }"
                    ></button>
                    <p @click="checkOrientation('portrait')">{{ $t('portrait') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group sf-label-group-lg">
                  <label class="primary-label">{{ $t('group-campaign') }}</label>
                </div>
                <div class="sf-select-group mb-3">
                  <Select2
                    class="sf-select2"
                    @select="selectCampaignForGroup($event)"
                    :value="0"
                    :options="listCampaignOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group sf-label-group-lg">
                  <label class="primary-label">{{ $t('add-player') }}</label>
                </div>
                <div class="sf-select-group mb-3">
                  <Select2
                    :options="listPlayersWithOrientationOptionsForAddGroup"
                    @select="selectPlayer($event)"
                    :value="0"
                    class="sf-select2"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
            </div>
            <div class="col-3">
              <button class="sf-primary" @click="AddPlayerToGroup">{{ $t('add-player') }}</button>
            </div>
          </div>

          <div class="row my-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group sf-label-group-lg">
                  <label class="primary-label">{{ $t('players-in-group') }}</label>
                </div>
                <div class="group-player w-100">
                  <p
                    class="name-player d-flex"
                    v-for="(name, index) in arrayPlayerChoosen.listNameChosen"
                    :key="index"
                  >
                    {{ name }}
                    <span @click="removeAndReturn(index)" class="d-flex align-items-center"
                      ><img src="@/assets/images/close-tag.png"
                    /></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sf-modal-footer">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalCreateGroup = false">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="createGroupPage">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>
    <SfDeleteModal
      :title="$t('modal.title.confirm-delete')"
      :width="400"
      closeOnOverlay
      :show.sync="isShownModalDelete"
    >
      <div class="content-delete">
        {{ $t('deleting-player-group-confirm-message') }}
      </div>

      <div class="sf-modal-footer">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalDelete = false">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="removeGroupPlayer">
                  {{ $t('confirm') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfDeleteModal>

    <SfModal
      :title="$t('modal.title.edit-group-player')"
      :width="786"
      closeOnOverlay
      :show.sync="isShownModalEditGroup"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group sf-label-group-lg">
                  <label class="primary-label">{{ $t('player-group-name') }}</label>
                </div>
                <input
                  v-model="groupDetailID.name"
                  :placeholder="$t('placeholder.name')"
                  class="sf-input-text"
                />
              </div>
              <p v-if="showError === true && !groupDetailID.name" class="error-text text-right">
                {{ $t('group-name-required') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group sf-label-group-lg">
                  <label class="primary-label">{{ $t('orientation') }}</label>
                </div>

                <div class="term-info">
                  <div class="d-flex align-items-center">
                    <button
                      class="button-term"
                      @click="checkOrientationForEdit('landscape')"
                      :class="{ 'term-active': groupDetailID.orientation === 'landscape' }"
                    ></button>
                    <p @click="checkOrientationForEdit('landscape')">
                      {{ $t('landscape') }} ({{ $t('default') }})
                    </p>
                  </div>
                  <div class="d-flex align-items-center" style="margin-left: 20px">
                    <button
                      class="button-term"
                      @click="checkOrientationForEdit('portrait')"
                      :class="{ 'term-active': groupDetailID.orientation === 'portrait' }"
                    ></button>
                    <p @click="checkOrientationForEdit('portrait')">{{ $t('portrait') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group sf-label-group-lg">
                  <label class="primary-label">{{ $t('group-campaign') }}</label>
                </div>
                <div class="sf-select-group mb-3">
                  <Select2
                    class="sf-select2"
                    v-if="groupDetailID && groupDetailID.campaign"
                    :value="groupDetailID.campaign.id"
                    :options="listCampaignOptionsForEditPlayer"
                    @select="changeCampaignEdit($event)"
                  ></Select2>
                  <Select2
                    class="sf-select2"
                    v-else
                    :options="listCampaignOptionsForEditPlayer"
                    @select="changeCampaignEdit($event)"
                  ></Select2>
                  <span class="lb-right caret"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group sf-label-group-lg">
                  <label class="primary-label">{{ $t('add-player') }}</label>
                </div>
                <div class="sf-select-group mb-3">
                  <Select2
                    class="sf-select2"
                    @select="selectPlayer($event)"
                    :options="listPlayersWithOrientationOptionsForAddGroup"
                    :value="0"
                  ></Select2>
                  <span class="lb-right caret"></span>
                </div>
              </div>
            </div>
            <div class="col-3">
              <button @click="AddPlayerToGroupInEditForm" class="sf-primary">
                {{ $t('add-player') }}
              </button>
            </div>
          </div>

          <div class="row my-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group sf-label-group-lg">
                  <label class="primary-label">{{ $t('players-in-group') }}</label>
                </div>

                <div class="group-player w-100">
                  <p
                    class="name-player d-flex"
                    v-for="(player, index) in groupDetailID.players"
                    :key="index"
                  >
                    {{ player.name }}
                    <span
                      @click="returnAndRemovePlayerEdit(player)"
                      class="d-flex align-items-center"
                      ><img src="@/assets/images/close-tag.png"
                    /></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sf-modal-footer">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalEditGroup = false">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="editGroup">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>
    <SfDeleteModal
      :title="$t('modal.title.confirm-delete')"
      closeOnOverlay
      :show.sync="isShownModalDeletePlayer"
    >
      <div class="content-delete">
        {{ $t('deleting-player-confirm-message') }}
      </div>

      <div class="sf-modal-footer">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button
                  class="sf-secondary"
                  type="button"
                  @click="isShownModalDeletePlayer = false"
                >
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="deletePlayer">
                  {{ $t('confirm') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfDeleteModal>

    <SfWarningModal
      :title="$t('modal.title.warning')"
      closeOnOverlay
      :show.sync="isShownModalUnSupport"
    >
      <div class="content-delete">
        <template v-if="unsupportedType === 'asset_unallow'">
          <p
            class="attempting-use-asset mb-3"
            v-html="$t('player-attempting-use-asset', { asset: slide_template_unallow })"
          ></p>
          <p
            class="contact-to-upgrade mb-3"
            v-html="
              $t('player-contact-to-upgrade', {
                asset: slide_template_unallow,
                device: hardware_require
              })
            "
          ></p>
        </template>
        <template v-else-if="unsupportedType === 'pi_firmware_require'">
          <p
            class="attempting-use-asset mb-3"
            v-html="$t('player-attempting-version-unsupported', { asset: slide_template_unallow })"
          ></p>
          <p
            class="contact-to-upgrade mb-3"
            v-html="
              $t('player-contact-to-upgrade-version', {
                asset: slide_template_unallow,
                applicationVersion: pi_firmware_require
              })
            "
          ></p>
        </template>
        <p class="cantact-number">{{ $t('tell') }}: (844) 772-7336</p>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-primary" @click="isShownModalUnSupport = false">{{ $t('ok') }}</button>
      </div>
    </SfWarningModal>
    <SfWarningModal
      :title="$t('modal.title.warning')"
      closeOnOverlay
      :show.sync="isNewUpdatedBySomeOne"
    >
      <div class="content-delete">
        <p class="cantact-number">
          Someone else has just finished editing this PLAYER. Please reload the page to see their
          changes
        </p>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isNewUpdatedBySomeOne = false">
          {{ $t('keep') }}
        </button>
        <button class="sf-primary" @click="reloadPage">{{ $t('refresh') }}</button>
      </div>
    </SfWarningModal>
  </div>
</template>
<script>
import SfModal from '@/components/SfModal.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import Select2 from 'v-select2-component'
import SfInputSearch from '@/components/SfInputSearch.vue'
import Multiselect from 'vue-multiselect'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import PlayerService from '../../services/player.service'
import PlayerGroupService from '../../services/player-group.service'
import { timeSince } from '@/helper/helper'
import OrganizationService from '@/services/organization.service'
import SfOptionLimitPage from '@/components/SfOptionLimitPage.vue'
import SfWarningModal from '@/components/SfWarningModal.vue'
import AddNewPlayerModal from './AddNewPlayerModal.vue'
import VueTagsInput from '@johmun/vue-tags-input'

export default {
  name: 'PlayerPage',
  components: {
    SfModal,
    SfDeleteModal,
    Select2,
    SfInputSearch,
    SfOptionLimitPage,
    SfWarningModal,
    AddNewPlayerModal,
    VueTagsInput,
    Multiselect
  },
  data() {
    return {
      fields: [
        {
          key: 'image',
          label: '',
          colType: 'image',
          thClass: 'icon-table'
        },
        {
          key: 'name',
          sortable: true,
          colType: 'name',
          label: 'Name'
        },
        {
          key: 'running_campaign',
          sortable: true,
          colType: 'running_campaign',
          label: 'Running Campaign'
        },
        {
          key: 'group_player',
          sortable: true,
          colType: 'group_player',
          label: 'Group'
        },
        {
          key: 'status',
          sortable: true,
          colType: 'status',
          label: 'Status'
        },
        {
          key: 'Action',
          colType: 'button',
          label: 'Actions'
        }
      ],
      isShownModal: false,
      isShownModalEdit: false,
      isShownModalCreateGroup: false,
      isShownModalEditGroup: false,
      isShownModalDelete: false,
      isShownModalDeletePlayer: false,
      currentPage: 1,
      limitPage: {
        name: `25 / ${this.$t('page')}`,
        value: '25'
      },
      limitPageGroup: {
        name: `25 / ${this.$t('page')}`,
        value: '25'
      },
      limitPagePlayerChild: {
        name: `25 / ${this.$t('page')}`,
        value: '25'
      },
      currentPageOfGroup: 1,
      currentPageOfSubOrganization: 1,
      currentPlayerID: '',
      address: '',
      dataSearch: '',
      dataSearchGroup: '',
      showError: false,
      listCountries: [],
      listStates: [],
      activeButton: 'players',
      activeTabModal: 'general',
      checkedOrientation: 'landscape',
      checkedOrientationForEdit: '',
      addressDataFormat: {},
      infoAddGroup: {
        group_player: {
          name: '',
          orientation: 'landscape',
          campaign_id: ''
        },
        player_ids: ''
      },
      currentChoosePlayer: '',
      arrayPlayerChoosen: {
        listID: [],
        listNameChosen: [],
        value: []
      },
      fieldsGroup: [
        {
          key: 'activated',
          label: '',
          colType: 'activated',
          thClass: 'icon-table'
        },
        {
          key: 'image',
          label: '',
          colType: 'image',
          thClass: 'icon-table'
        },
        {
          key: 'name',
          sortable: true,
          colType: 'player-group',
          label: 'Player Group'
        },
        {
          key: 'campaign',
          sortable: true,
          colType: 'active_campaign',
          label: 'Active Campaign'
        },
        {
          key: 'players',
          sortable: true,
          colType: 'player',
          label: 'Players'
        },
        {
          key: 'Action',
          colType: 'button',
          label: 'Actions'
        }
      ],
      currentGroupID: '',
      groupDetailID: {},
      currentRotate: '',
      campaignChosed: {},
      playersChosedToDelete: [],
      user: {},
      autoRefresh: '',
      dataSearchChild: '',
      fieldsPlayerChild: [
        {
          key: 'activated',
          label: '',
          colType: 'activated',
          thClass: 'icon-table'
        },
        {
          key: 'image',
          label: '',
          colType: 'image',
          thClass: 'icon-table'
        },
        {
          key: 'name',
          sortable: true,
          colType: 'name',
          label: 'Name'
        },
        {
          key: 'running_campaign',
          sortable: true,
          colType: 'running_campaign',
          label: 'Running Campaign'
        },
        {
          key: 'group_player',
          sortable: true,
          colType: 'group_player',
          label: 'Group'
        },
        {
          key: 'status',
          sortable: true,
          colType: 'status',
          label: 'Status'
        }
      ],
      subOrgSelected: '',
      optionsSubOrg: [],
      isParentOrg: '',
      slide_template_unallow: '',
      hardware_require: '',
      isShownModalUnSupport: false,
      unsupportedType: '',
      pi_firmware_require: '',
      tag: '',
      isNewUpdatedBySomeOne: false,
      filterOrientation: {
        name: this.$t('both'),
        value: ''
      }
    }
  },
  methods: {
    timeSince,
    ...mapMutations('player', {
      setInfoPlayerId: 'GET_INFO_PLAYER_BY_ID',
      createPlayerFromSocket: 'CREATE_PLAYER_FROM_SOCKET',
      deletePlayerFromSocket: 'DELETE_PLAYER_FROM_SOCKET',
      updatePlayerFromSocket: 'UPDATE_PLAYER_FROM_SOCKET',
      createGroupPlayerFromSocket: 'CREATE_GROUP_PLAYER_FROM_SOCKET',
      deleteGroupPlayerFromSocket: 'DELETE_GROUP_PLAYER_FROM_SOCKET',
      updateGroupPlayerFromSocket: 'UPDATE_GROUP_PLAYER_FROM_SOCKET',
      setPlayerTags: 'SET_PLAYER_TAGS',
      setSearchInput: 'SET_SEARCH_PLAYER_INPUT'
    }),
    ...mapActions('player', {
      getAllPlayerChildren: 'getAllPlayerChildren'
    }),
    fetchData(activePage, limit, search) {
      this.$store.dispatch('player/getAllPlayer', {
        activePage,
        limit,
        search,
        orientation: this.filterOrientation.value
      })
    },
    fetchDataGroup(activePage, limit, search) {
      this.$store.dispatch('player/getGroupsPlayer', { activePage, limit, search })
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage, this.limitPage.value, this.dataSearch)
    },
    changePageGroup(page) {
      this.currentPageOfGroup = page
      this.fetchDataGroup(this.currentPageOfGroup, this.limitPageGroup.value, this.dataSearchGroup)
    },
    changePagePlayerChild(page) {
      this.currentPageOfSubOrganization = page
      this.getAllPlayerChildren({
        id: this.subOrgSelected,
        page: this.currentPageOfSubOrganization,
        limit: this.limitPagePlayerChild.value,
        search: this.dataSearchChild
      })
    },
    editPlayer(id) {
      this.currentPlayerID = id
      PlayerService.getInfoPlayerByID(this.currentPlayerID)
        .then((response) => {
          this.activeTabModal = 'general'
          this.isShownModalEdit = true
          this.setInfoPlayerId(response.data)
          this.showError = false
          this.getAllCampaigns()
          this.getListTimezone()
        })
        .catch((error) => {
          this.$toast.error(
            error.message ? `${error.message}` : 'Something went wrong. Please try again.'
          )
        })
    },
    getAddressData(place) {
      const self = this
      this.formatAddress(place.address_components)
      this.$store.dispatch('player/updateLocation', {
        info: self.addressDataFormat,
        name: place.formatted_address,
        place: place,
        country: self.addressDataFormat.country,
        province_state: self.addressDataFormat.administrative_area_level_1,
        postal_code: self.addressDataFormat.postal_code ? self.addressDataFormat.postal_code : '',
        city: self.addressDataFormat.locality ? self.addressDataFormat.locality : ''
      })
    },
    getAllCampaigns(orientation) {
      this.$store.dispatch('player/getAllCampaigns', orientation)
    },
    updatePlayer() {
      const shallowInfoPlayerID = JSON.parse(JSON.stringify(this.infoPlayerID))
      delete shallowInfoPlayerID.access_code
      delete shallowInfoPlayerID.activated
      delete shallowInfoPlayerID.diagnostics
      delete shallowInfoPlayerID.files
      delete shallowInfoPlayerID.last_connection
      delete shallowInfoPlayerID.orientation
      delete shallowInfoPlayerID.player_code
      shallowInfoPlayerID.time_zone_name = shallowInfoPlayerID.time_zone_value
      const tag_list = shallowInfoPlayerID.tags.map((tag) => tag.text)
      delete shallowInfoPlayerID.tags
      const param = {
        player: {
          ...shallowInfoPlayerID,
          tag_list
        }
      }
      if (this.namePlayer && this.descriptionPlayer.length <= 500) {
        PlayerService.updatePlayerByID(param, shallowInfoPlayerID.id)
          .then((res) => {
            const filter = this.filterOrientation.value
            if (filter !== '' && filter !== 'both' && res.data.orientation !== filter) {
              this.$store.dispatch('player/deletePlayer', res.data.id)
            }
            this.isShownModalEdit = false
            this.$toast.success(res.message || 'Player updated')
          })
          .catch((error) => {
            this.isShownModalEdit = false
            if (error.data && ['pi_firmware_require', 'asset_unallow'].includes(error.data.type)) {
              this.unsupportedType = error.data.type
              const asset = error.data.slide_template_unallow.length > 1 ? ' assets' : ' asset'
              this.slide_template_unallow =
                `<span style="font-weight: 600;">${error.data.slide_template_unallow.join(
                  ', '
                )}</span>` + asset
              this.hardware_require = `<span style="font-weight: 600;">${error.data.hardware_require.displayname}</span>`
              this.pi_firmware_require = `<span style="font-weight: 600;">${error.data.pi_firmware_require}</span>`
              this.isShownModalUnSupport = true
            } else {
              this.$toast.error(
                error.message ? `${error.message}` : 'Something went wrong. Please try again.'
              )
            }
          })
      } else {
        this.showError = true
      }
    },
    deletePlayer() {
      PlayerService.deletePlayer(this.currentPlayerID)
        .then((res) => {
          if (this.listPlayer.length === 1 && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1
          }
          this.$toast.success(res.message || 'Player deleted')
          // this.fetchData(this.currentPage, this.limitPage.value, this.dataSearch)
        })
        .catch((err) => {
          this.isShownModalDeletePlayer = false
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
        .finally(() => {
          this.isShownModalDeletePlayer = false
        })
    },
    getListTimezone() {
      this.$store.dispatch('player/getListTimezone')
    },
    chooseTimezone(value) {
      this.$store.dispatch('player/chooseTimezone', value)
    },
    changeCampaign(value) {
      this.$store.dispatch('player/chooseCampaign', value)
    },
    changeRotate(e) {
      this.$store.dispatch('player/changeRotate', e)
    },
    checkOut(e) {
      this.$store.dispatch('player/updateLocation', {
        info: '',
        name: e
      })
    },
    checkOrientation(orientation) {
      if (this.checkedOrientation !== orientation) {
        if (orientation === 'portrait' && this.arrayPlayerChoosen.listNameChosen.length) {
          this.$toast.error(this.$t('remove-player-before-changing'))
        } else if (orientation === 'portrait' && !this.arrayPlayerChoosen.listNameChosen.length) {
          this.checkedOrientation = orientation
          this.infoAddGroup.group_player.orientation = orientation
          this.$store.dispatch('player/getAllPlayersWithOrientation', this.checkedOrientation)
          this.getAllCampaigns(this.checkedOrientation)
        }
        if (orientation === 'landscape' && this.arrayPlayerChoosen.listNameChosen.length) {
          this.$toast.error(this.$t('remove-player-before-changing'))
        } else if (orientation === 'landscape' && !this.arrayPlayerChoosen.listNameChosen.length) {
          this.checkedOrientation = orientation
          this.infoAddGroup.group_player.orientation = orientation
          this.$store.dispatch('player/getAllPlayersWithOrientation', this.checkedOrientation)
          this.getAllCampaigns(this.checkedOrientation)
        }
      }
    },
    checkOrientationForEdit(orientation) {
      if (this.checkedOrientationForEdit !== orientation) {
        if (orientation === 'portrait' && this.groupDetailID.players.length) {
          this.$toast.error(this.$t('remove-player-before-changing'))
        } else if (orientation === 'portrait' && !this.groupDetailID.players.length) {
          this.checkedOrientationForEdit = orientation
          this.groupDetailID.orientation = orientation
          this.$store.dispatch(
            'player/getAllPlayersWithOrientation',
            this.checkedOrientationForEdit
          )
          this.getAllCampaigns(this.checkedOrientationForEdit)
          this.groupDetailID.campaign = ''
        }
        if (orientation === 'landscape' && this.groupDetailID.players.length) {
          this.$toast.error(this.$t('remove-player-before-changing'))
        } else if (orientation === 'landscape' && !this.groupDetailID.players.length) {
          this.checkedOrientationForEdit = orientation
          this.groupDetailID.orientation = orientation
          this.$store.dispatch(
            'player/getAllPlayersWithOrientation',
            this.checkedOrientationForEdit
          )
          this.getAllCampaigns(this.checkedOrientationForEdit)
          this.groupDetailID.campaign = ''
        }
      }
    },
    ShownModalCreateGroup() {
      this.isShownModalCreateGroup = true
      this.$store.dispatch('player/getAllPlayersWithOrientation', 'landscape')
      this.arrayPlayerChoosen.listID = []
      this.arrayPlayerChoosen.listNameChosen = []
      this.arrayPlayerChoosen.value = []
      this.infoAddGroup.group_player.name = ''
      this.infoAddGroup.group_player.campaign_id = ''
      this.getAllCampaigns('landscape')
      this.checkedOrientation = 'landscape'
      this.campaignChosed = ''
    },
    selectCampaignForGroup(value) {
      this.infoAddGroup.group_player.campaign_id = value.id
      this.campaignChosed = value
    },
    AddPlayerToGroup() {
      if (!this.arrayPlayerChoosen.value.length) {
        this.currentRotate = this.currentChoosePlayer.rotate
      }
      if (this.currentChoosePlayer) {
        if (this.currentRotate === this.currentChoosePlayer.rotate) {
          this.arrayPlayerChoosen.listID.push(this.currentChoosePlayer.id)
          this.arrayPlayerChoosen.listNameChosen.push(this.currentChoosePlayer.text)
          this.arrayPlayerChoosen.value.push({
            id: this.currentChoosePlayer.id,
            name: this.currentChoosePlayer.text,
            time: this.currentChoosePlayer.time,
            rotate: this.currentChoosePlayer.rotate
          })
          this.$store.dispatch('player/deletePlayerWhenAdd', this.currentChoosePlayer.id)
        } else {
          this.$toast.error(this.$t('there-is-already-a-player-in-this-group'))
        }
      } else {
        this.$toast.error(this.$t('select-player-before-adding'))
      }
    },
    AddPlayerToGroupInEditForm() {
      if (!this.groupDetailID.players.length) {
        this.currentRotate = this.currentChoosePlayer.rotate
      }
      if (this.currentChoosePlayer) {
        if (this.currentRotate === this.currentChoosePlayer.rotate) {
          this.groupDetailID.players.push({
            id: this.currentChoosePlayer.id,
            name: this.currentChoosePlayer.text,
            time: this.currentChoosePlayer.time,
            rotate: this.currentChoosePlayer.rotate
          })
          this.$store.dispatch('player/deletePlayerWhenAdd', this.currentChoosePlayer.id)
        } else {
          this.$toast.error(this.$t('there-is-already-a-player-in-this-group'))
        }
      } else {
        this.$toast.error(this.$t('select-player-before-adding'))
      }
    },
    selectPlayer(value) {
      this.currentChoosePlayer = value
    },
    removeAndReturn(index) {
      this.arrayPlayerChoosen.listNameChosen.splice(index, 1)
      this.$store.dispatch('player/removeAndReturnData', this.arrayPlayerChoosen.value[index])
      this.arrayPlayerChoosen.value.splice(index, 1)
      this.arrayPlayerChoosen.listID.splice(index, 1)
    },
    createGroupPage() {
      if (!this.infoAddGroup.group_player.name) {
        this.showError = true
      } else {
        this.infoAddGroup.player_ids = this.arrayPlayerChoosen.listID.toString()
        PlayerGroupService.createPlayerGroup(this.infoAddGroup)
          .then((res) => {
            this.$store.dispatch('player/setNewCampaignPlayerPage', {
              campaign: this.campaignChosed,
              listPlayerChosen: this.arrayPlayerChoosen.listID,
              orientation: this.checkedOrientation,
              nameGroup: this.infoAddGroup.group_player.name
            })
            this.$toast.success(res.message || 'Player Group successfully created')
            this.isShownModalCreateGroup = false
            this.showError = false
            // this.fetchDataGroup(1, this.limitPage.value)
          })
          .catch((err) => {
            this.$toast.error(err.message)
          })
      }
    },
    changeTab(value) {
      this.activeButton = value
      if (value === 'suborg') {
        this.getAllPlayerChildren({
          id: this.subOrgSelected,
          page: this.currentPageOfSubOrganization,
          limit: this.limitPagePlayerChild.value,
          search: this.dataSearchChild
        })
      }
    },
    removeGroupPlayer() {
      const getNameCurrentGroup = this.listPlayerGroup.filter((group) => {
        return group.id === this.currentGroupID
      })
      PlayerGroupService.deletePlayerGroup(this.currentGroupID)
        .then((res) => {
          this.$store.dispatch('player/checkPlayerDeleteGroup', getNameCurrentGroup[0].name)
          this.$toast.success(res.message || 'Player Group sucessfully deleted')
          if (this.listPlayerGroup.length === 1 && this.currentPageOfGroup > 1) {
            this.currentPageOfGroup = this.currentPageOfGroup - 1
          }
          // this.fetchDataGroup(this.currentPageOfGroup, this.paginationGroup.limit, this.dataSearchGroup)
        })
        .catch((err) => {
          this.isShownModalDelete = false
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
        .finally(() => {
          this.isShownModalDelete = false
        })
    },
    shownModalDelete(id) {
      this.isShownModalDelete = true
      this.currentGroupID = id
    },
    shownModalDeletePlayer(id) {
      this.isShownModalDeletePlayer = true
      this.currentPlayerID = id
    },
    shownModalEditGroup(id) {
      this.isShownModalEditGroup = true
      this.currentGroupID = id
      this.campaignChosed = ''
      PlayerGroupService.getDetailGroupByID(id).then(async (res) => {
        this.groupDetailID = res.data
        if (!this.groupDetailID.campaign) {
          this.groupDetailID.campaign = { id: 0 }
        }
        this.getAllCampaigns(this.groupDetailID.orientation)
        if (this.groupDetailID.players.length) {
          this.currentRotate = this.groupDetailID.players[0].rotate
        }
        await this.$store.dispatch(
          'player/getAllPlayersWithOrientation',
          this.groupDetailID.orientation
        )
        this.checkedOrientationForEdit = this.groupDetailID.orientation
        if (this.groupDetailID.players.length) {
          await this.$store.dispatch(
            'player/GetNewListPlayerWhenGetDetailGroup',
            this.groupDetailID.players
          )
        }
      })
    },
    changeCampaignEdit(value) {
      this.groupDetailID.campaign = value
      this.groupDetailID.campaign.id = value.id
      this.campaignChosed = value
    },
    returnAndRemovePlayerEdit(player) {
      this.$store.dispatch('player/removeAndReturnData', player)
      this.groupDetailID.players = this.groupDetailID.players.filter((group) => {
        return group.name !== player.name
      })
      this.playersChosedToDelete.push(player)
    },
    editGroup() {
      const listPlayer = []
      this.groupDetailID.players.map((group) => {
        if (typeof group.id === 'number') {
          listPlayer.push(JSON.stringify(group.id))
        } else {
          listPlayer.push(group.id)
        }
      })
      if (this.groupDetailID.name) {
        PlayerGroupService.editGroupByID(this.currentGroupID, {
          group_player: {
            name: this.groupDetailID.name,
            orientation: this.groupDetailID.orientation,
            campaign_id: this.groupDetailID.campaign ? this.groupDetailID.campaign.id : ''
          },
          player_ids: listPlayer.toString()
        })
          .then((res) => {
            if (this.campaignChosed) {
              this.$store.dispatch('player/setNewCampaignPlayerPage', {
                campaign: this.campaignChosed,
                listPlayerChosen: listPlayer,
                orientation: this.checkedOrientation,
                nameGroup: this.groupDetailID.name
              })
            } else {
              this.$store.dispatch('player/setNewCampaignPlayerPage', {
                campaign: this.groupDetailID.campaign,
                listPlayerChosen: listPlayer,
                orientation: this.checkedOrientation,
                nameGroup: this.groupDetailID.name
              })
            }
            this.$store.dispatch('player/deletePlayerInPlayerChosen', this.playersChosedToDelete)
            this.playersChosedToDelete = []
            this.$toast.success(res.message || 'Player Group updated')
            this.isShownModalEditGroup = false
            // this.fetchDataGroup(this.currentPageOfGroup, this.paginationGroup.limit, this.dataSearchGroup)
          })
          .catch((error) => {
            if (error.data && error.data.type === 'player-not-support') {
              const asset = error.data.slide_template_unallow.length > 1 ? ' assets' : ' asset'
              this.slide_template_unallow =
                `<span style="font-weight: 600;">${error.data.slide_template_unallow.join(
                  ', '
                )}</span>` + asset
              this.hardware_require = `<span style="font-weight: 600;">${error.data.hardware_require.displayname}</span>`
              this.isShownModalUnSupport = true
            } else {
              this.$toast.error(
                error.message ? `${error.message}` : 'Something went wrong. Please try again.'
              )
            }
          })
      } else {
        this.showError = true
      }
    },
    formatAddress(address) {
      const components = {}
      address.map((key, index) => {
        key.types.map((key1, index1) => {
          components[key1] = key.long_name
        })
      })
      this.addressDataFormat = components
    },
    getUser() {
      const user = this.$cookies.get('user')
      if (user) {
        this.user = user
      }
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.setSearchInput(value)
      this.currentPage = 1
      this.fetchData(this.currentPage, this.limitPage.value, value)
    },
    groupSearchInputChange(value) {
      this.dataSearchGroup = value
      this.currentPageOfGroup = 1
      this.fetchDataGroup(this.currentPageOfGroup, this.paginationGroup.limit, value)
    },
    onChangeToggleCacheBrowser(value) {
      this.$store.dispatch('player/changeToggleCache', value.value)
    },
    onChangeToggleKioskMode(value) {
      this.$store.dispatch('player/changeToggleKiosk', value.value)
    },
    onChangeToggleWifiType(value) {
      this.$store.dispatch('player/changeToggleWifiType', value.value)
    },
    resetSearchPlayer() {
      this.fetchData(this.currentPage, this.limitPage.value)
    },
    resetSearchGroup() {
      this.fetchDataGroup(this.currentPageOfGroup, this.paginationGroup.limit)
    },
    resetSearchPlayerChildren() {
      this.dataSearchChild = ''
      this.getAllPlayerChildren({
        id: this.subOrgSelected,
        page: this.currentPageOfSubOrganization,
        limit: this.limitPagePlayerChild.value,
        search: this.dataSearchChild
      })
    },
    getListChildOrg() {
      OrganizationService.getAllChildOrganization()
        .then((res) => {
          const listChildOrg = res.data
          listChildOrg &&
            listChildOrg.length &&
            listChildOrg.map((org) => {
              this.optionsSubOrg.push({ id: org.id, text: org.name })
              this.subOrgSelected = listChildOrg[0].id
            })
        })
        .catch((error) => {
          this.$toast.error(error.message ? `${error.message}` : 'Something went wrong.')
        })
    },
    selectSubOrg(value) {
      this.getAllPlayerChildren({
        id: value.id,
        page: this.currentPageOfSubOrganization,
        limit: this.limitPagePlayerChild.value,
        search: this.dataSearchChild
      })
    },
    inputPlayerChildren() {
      this.getAllPlayerChildren({
        id: this.subOrgSelected,
        page: this.currentPageOfSubOrganization,
        limit: this.limitPagePlayerChild.value,
        search: this.dataSearchChild
      })
    },
    changeLimitPage(data) {
      this.currentPage = 1
      this.limitPage = data
      this.fetchData(this.currentPage, this.limitPage.value, this.dataSearch)
    },
    changeLimitPagePlayerChild(data) {
      this.currentPageOfSubOrganization = 1
      this.limitPagePlayerChild = data
      this.getAllPlayerChildren({
        id: this.subOrgSelected,
        page: this.currentPageOfSubOrganization,
        limit: this.limitPagePlayerChild.value,
        search: this.dataSearchChild
      })
    },
    changeLimitPageGroup(data) {
      this.currentPageOfGroup = 1
      this.limitPageGroup = data
      this.fetchDataGroup(this.currentPageOfGroup, this.limitPageGroup.value, this.dataSearchGroup)
    },
    changeTabEditModal(val) {
      this.activeTabModal = val
    },
    ShownModalAddNewPlayer() {
      if (this.currentUser.role && this.currentUser.role.name === 'org-admin') {
        this.$refs.newPlayer.isShownAddNewPlayerModal = true
        this.$refs.newPlayer.getProductFromChargeOver()
      } else {
        this.isShownModal = true
      }
    },
    reloadPlayer(isSuccess) {
      if (isSuccess) {
        this.fetchData(this.currentPage, this.limitPage.value)
      }
    },
    reloadPage() {
      this.reloadPlayer(true)
      if (this.isShownModalEdit) {
        this.editPlayer(this.currentPlayerID)
      }
      this.isNewUpdatedBySomeOne = false
    },
    changeOrientation(value) {
      this.filterOrientation = value
      this.fetchData(this.currentPage, this.limitPage.value, this.dataSearch)
    }
  },
  channels: {
    PlayersChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (data.action === 'create') {
          this.createPlayerFromSocket({ data: data.data })
        } else if (data.action === 'delete') {
          this.deletePlayerFromSocket({ data: data.data })
        } else if (data.action === 'update') {
          this.updatePlayerFromSocket({ data: data.data })
        }
        if (
          this.isShownModalEdit &&
          this.currentPlayerID === data.data.id &&
          this.user.id !== data.user_id
        ) {
          this.isNewUpdatedBySomeOne = true
        }
      },
      disconnected() {}
    },
    GroupPlayerChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (data.action === 'create') {
          this.createGroupPlayerFromSocket({ data: data.data })
        } else if (data.action === 'delete') {
          this.deleteGroupPlayerFromSocket({ data: data.data })
        } else if (data.action === 'update') {
          this.updateGroupPlayerFromSocket({ data: data.data })
        }
      },
      disconnected() {}
    }
  },
  created() {
    if (this.searchInputState) {
      this.dataSearch = this.searchInputState
    }
    clearInterval(this.autoRefresh)
    const self = this
    this.autoRefresh = setInterval(function () {
      self.fetchData(self.currentPage, self.limitPage.value)
    }, 10 * 60 * 1000)
  },
  mounted() {
    this.getUser()
    this.$cable.subscribe({
      channel: 'PlayersChannel',
      organization: this.user.organization_id
    })
    this.$cable.subscribe({
      channel: 'GroupPlayerChannel',
      organization: this.user.organization_id
    })
    this.fetchData(this.currentPage, this.limitPage.value, this.dataSearch)
    this.fetchDataGroup(this.currentPageOfGroup, this.limitPageGroup.value)
    this.getListChildOrg()
    this.isParentOrg = this.$cookies.get('user').is_parent_organization
  },
  beforeDestroy() {
    this.$cable.unsubscribe('PlayersChannel')
    this.$cable.unsubscribe('GroupPlayerChannel')
    clearInterval(this.autoRefresh)
  },
  computed: {
    ...mapState('player', {
      listPlayer: 'listPlayer',
      pagination: 'pagination',
      error: 'error',
      loading: 'loading',
      infoPlayerID: 'infoPlayerID',
      allCampaigns: 'allCampaigns',
      listTimezone: 'listTimezone',
      listPlayerGroup: 'listPlayerGroup',
      paginationGroup: 'paginationGroup',
      paginationSubOrganization: 'paginationSubOrganization',
      dataPlayerChildren: 'dataPlayerChildren',
      isLoadingDataPlayerChildren: 'isLoadingDataPlayerChildren',
      searchInputState: 'searchPlayerInput'
    }),
    ...mapGetters('player', {
      isEmpty: 'isEmpty',
      listTimezoneOptions: 'listTimezoneOptions',
      listCampaignOptionsForEditPlayer: 'listCampaignOptionsForEditPlayer',
      listCampaignOptions: 'listCampaignOptions',
      listPlayersWithOrientationOptionsForAddGroup: 'listPlayersWithOrientationOptionsForAddGroup'
    }),
    ...mapGetters('user', {
      currentUser: 'getCurrentUser'
    }),
    namePlayer: {
      get() {
        return this.$store.state.player.infoPlayerID.name
      },
      set(value) {
        this.$store.dispatch('player/updatePlayerName', value)
      }
    },
    descriptionPlayer: {
      get() {
        return this.$store.state.player.infoPlayerID.description
      },
      set(value) {
        this.$store.dispatch('player/updateDescripionPlayer', value)
      }
    },
    downloadPlayerURL() {
      const organization_id =
        this.activeButton === 'suborg' && this.subOrgSelected
          ? this.subOrgSelected
          : this.user.organization_id
      return `/apisite/v1/export_csvs/players?organization_id=${organization_id}`
    },
    listOptionOrientation() {
      return [
        {
          name: this.$t('both'),
          value: ''
        },
        {
          name: this.$t('portrait'),
          value: 'portrait'
        },
        {
          name: this.$t('landscape'),
          value: 'landscape'
        }
      ]
    }
  },
  watch: {
    listPlayersWithOrientationOptionsForAddGroup() {
      this.currentChoosePlayer = ''
    },
    activeButton(value) {
      if (value === 'players') {
        this.$bus.$emit('send_active_button_to_header', this.dataSearch)
      } else if (value === 'groups') {
        this.$bus.$emit('send_active_button_to_header', this.dataSearchGroup)
      }
    },
    isShownModalCreateGroup(value) {
      if (!value) {
        this.getAllCampaigns()
      }
    },
    isShownModalEditGroup(value) {
      if (!value) {
        this.getAllCampaigns()
      }
    },
    currentUser(value) {
      const organization = this.currentUser.organization
      this.$cable.subscribe({
        channel: 'CampaignChannel',
        organization: organization.id
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../campaign/campaignPage.scss';
@import '../campaign/editCampaign.scss';
@import './CreditCard.scss';
.cantact-number {
  font-size: 22px;
  font-weight: 600;
  color: #2c7fe1;
}
.player-active {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #76c322;
  margin: auto;
}
.sf-tabs-players .sf-tabs {
  border-bottom: none;
}

.player-inactive {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #e00008;
  margin: auto;
}

.img-player {
  max-width: none !important;
  &.portrait {
    margin-left: 10px;
    margin-top: -5px;
  }
}

.custom-wrapper {
  margin-top: 35px !important;
  position: relative !important;
}
.players-tabs-wraper {
  height: 56px;
}

.form-body {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    &:nth-child(1) {
      color: #4a4a4a;
      font: 14px 'Open Sans', sans-serif;
      font-weight: 600;
      margin-top: 15px;
      margin-bottom: 20px;
    }

    &:nth-child(2) {
      font-size: 22px;
      font-weight: 600;
      color: #2c7fe1;
      margin-bottom: 20px;
    }
  }
}

.select-location {
  background: none;
  width: 100%;
  height: 34px;
  transition: box-shadow 0.15s ease;
  border: 0;
  color: #4a4a4a;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  font: 14px Open Sans, sans-serif;
  background-color: white;
  padding: 6px 12px;
}

.custom-input {
  border: 0 !important;
  height: 34px !important;
  width: 100%;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.custom-description {
  border: 0 !important;
  height: 40px !important;
  width: 100%;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  &::placeholder {
    font: 14px 'Open Sans', sans-serif;
  }
}
.list-button {
  div {
    width: 150px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #ffffff;
    font: 14px 'Open Sans', sans-serif;
    color: #007bff;
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
    &:nth-child(2) {
      margin-left: 10px;
    }
  }
}
.activeButton {
  background-color: #00325b !important;
  color: #ffffff !important;
}
.term-info {
  display: flex;
  align-items: center;
  width: 100%;

  p {
    padding-left: 10px;
    font: 14px 'Open Sans', sans-serif;
    cursor: pointer;
  }

  .button-term {
    width: 16px;
    height: 16px;
    background-color: #eee;
    border: 1px solid #d8dde3;
    border-radius: 50%;
  }

  .term-active {
    background-size: cover;
    background-image: url('../../assets/images/radio-active.svg') !important;
  }
}
.button-add-player {
  color: #fff;
  border-radius: 4px;
  height: 35, 01px;
  width: 90px;
  background-color: #76c322;
  border: 0;
  font-weight: bold;
}
.name-player {
  background-color: #e0edf5;
  border-radius: 3px;
  color: #2f5d92;
  float: left;
  font-weight: 600;
  height: 28px;
  line-height: 28px;
  margin: 0 8px 6px 0;
  padding: 0 1px 0 8px;
  white-space: nowrap;
}
.sf-primary-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #223144;
}
@media only screen and (max-width: 760px) {
  .btn-create {
    font-size: 0%;
  }
}
.campaign-input {
  .sf-select2 {
    width: 417.5px;
  }
}
::v-deep .vue-tags-input {
  .ti-content {
    .ti-tag-center {
      max-width: 360px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .ti-tag {
    span {
      line-height: 2em;
    }
  }
}
.button-delete-table {
  margin-left: 9px;
}
</style>
