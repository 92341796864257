<template>
  <div class="container-fluid">
    <div class="card-header top-bar">
      <div class="header-left">
        <div class="admin-search-box">
          <SfInputSearch
            v-model="dataSearch"
            placeholder="Search"
            v-debounce:500ms="searchInputChange"
            class="sf-input-search"
            @onSearchInputChange="searchInputChange"
          />
        </div>
      </div>
      <div class="header-right">
        <button class="sf-primary-add" @click="showAddTemplateModal = true">Add New</button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 sf-table">
          <b-table
            :busy="loading"
            show-empty
            striped
            hover
            :items="items"
            :fields="fields"
            head-variant="none"
            table-variant="none"
            thead-class="custom-b-table"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>{{ $t('loading') }}</strong>
              </div>
            </template>
            <template v-slot:empty>
              <p class="d-flex justify-content-center">No data available in table</p>
            </template>
            <template v-slot:head(name)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(name)="field">
              <p>{{ field.item.name }}</p>
            </template>

            <template v-slot:head(description)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(description)="field">
              <p>{{ field.item.description }}</p>
            </template>

            <template v-slot:cell(action)="field">
              <button
                @click="shownModalEdit(field.item)"
                class="button-edit-table"
                title="Edit"
              ></button>
              <button
                @click="shownModalDelete(field.item.id)"
                class="button-delete-table"
                title="Delete"
              ></button>
            </template>
          </b-table>

          <div v-if="!isEmpty" class="my-3">
            <b-pagination
              class="sf-b-pagination"
              :total-rows="pagination.limit * pagination.total"
              :per-page="pagination.limit"
              @change="changePage"
              :limit="6"
              v-model="currentPage"
            />
          </div>
        </div>
      </div>
    </div>

    <ModalAddTemplate
      :show.sync="showAddTemplateModal"
      @update:show="showAddTemplateModal = false"
      @modal:next="modalAddTemplateNext"
    />
    <ModalEditTemplate
      :show.sync="showEditTemplateModal"
      :data.sync="dataModal"
      @update:show="showEditTemplateModal = false"
      @modal:next="modalEditTemplateNext"
    />
    <ModalSmartAssetEditor
      :isAdminTemplate="true"
      :show.sync="showSmartAssetEditor"
      :editData.sync="editImageCustomDataModal"
    />
    <SfDeleteModal :title="'Confirm Delete'" closeOnOverlay :show.sync="isShownModalDelete">
      <div class="content-delete">
        Deleting this admin template. Are you sure you want to delete this one?
      </div>
      <div class="footer-button">
        <button class="sf-secondary" @click="isShownModalDelete = false">Cancel</button>
        <button class="sf-primary" @click="deleteTemplate">OK</button>
      </div>
    </SfDeleteModal>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
// import SfModal from '@/components/SfModal.vue'
import AdminSmartAssetsTemplateService from '@/services/admin-smart-asset-template.service'
import SfInputSearch from '@/components/SfInputSearch.vue'
import ModalAddTemplate from './ModalAddTemplate.vue'
import ModalEditTemplate from './ModalEditTemplate.vue'
import ModalSmartAssetEditor from '@/components/smartAssetEditor/ModalSmartAssetEditor'
import SfDeleteModal from '@/components/SfDeleteModal.vue'

export default {
  name: 'admin-posts',

  components: {
    ModalAddTemplate,
    ModalEditTemplate,
    SfInputSearch,
    ModalSmartAssetEditor,
    SfDeleteModal
    // ModalSmartAssetEditor,
  },
  data() {
    return {
      showAddTemplateModal: false,
      showEditTemplateModal: false,
      isShownModalDelete: false,
      currentPage: 1,
      orientation: 'lanscape',
      showSmartAssetEditor: false,
      selectedTemplateId: '',
      dataModal: {},
      editImageCustomDataModal: {},
      fields: [
        {
          key: 'name',
          sortable: true,
          label: 'Name',
          colType: 'name',
          thStyle: {
            width: '40%'
          }
        },
        {
          key: 'description',
          sortable: true,
          label: 'Description',
          colType: 'description',
          thStyle: {
            width: '40%'
          }
        },
        {
          key: 'ACTION',
          label: 'Actions',
          colType: 'button'
        }
      ],
      dataSearch: ''
    }
  },
  methods: {
    ...mapMutations('library', {
      setNewSmartAssetDataModal: 'SET_NEW_SMART_ASSET_DATA_MODAL'
    }),
    fetchData(activePage, search) {
      this.$store.dispatch('admintemplate/getAllTemplates', {
        activePage,
        limit: 10,
        type: 'custom_asset',
        orientation: this.orientation,
        search
      })
    },
    modalAddTemplateNext(data) {
      this.setNewSmartAssetDataModal(data)
      this.editImageCustomDataModal = {}
      this.showSmartAssetEditor = true
      this.showAddTemplateModal = false
    },
    modalEditTemplateNext(data) {
      const { orientation, displayname, tags, smart_asset_type, category, templateDescription } =
        data
      this.setNewSmartAssetDataModal({
        orientation,
        displayname,
        tags,
        smart_asset_type,
        category,
        templateDescription
      })
      this.editImageCustomDataModal = data
      this.showSmartAssetEditor = true
      this.showEditTemplateModal = false
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage, this.dataSearch)
    },
    shownModalEdit(data) {
      this.dataModal.templateId = data.id
      this.dataModal.templateDescription = data.description
      this.getTemplateByID(data.id)
    },
    shownModalDelete(templateId) {
      this.isShownModalDelete = true
      this.selectedTemplateId = templateId
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    },
    getTemplateByID(id) {
      AdminSmartAssetsTemplateService.getTemplate(id)
        .then((response) => {
          this.dataModal = { ...this.dataModal, ...response.data.smart_asset }
          this.dataModal.category = response.data.category
          this.dataModal.tags = response.data.tags
          this.showEditTemplateModal = true
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    deleteTemplate() {
      AdminSmartAssetsTemplateService.deleteAssetTemplate(this.selectedTemplateId)
        .then((data) => {
          this.fetchData(this.currentPage, this.dataSearch)
          this.$toast.success('Template successfully deleted')
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Some things went wrong. Please try again')
        })
        .finally(() => {
          this.isShownModalDelete = false
        })
    }
  },
  computed: {
    ...mapState('admintemplate', {
      items: 'items',
      pagination: 'pagination',
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('admintemplate', ['isEmpty'])
  },

  mounted() {
    this.fetchData(this.currentPage)
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    })
    this.$bus.$on('closeModal', (value) => {
      this.fetchData(this.currentPage)
    })
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
  }
}
</script>

<style lang="scss" scoped>
@import '../../Adminpage.scss';
.text-area-control {
  padding: 6px 0px;
  width: 90%;
  border: none;
  outline: none;
  height: 100px;
  max-height: 150px;
  margin: 1px;
}

.custom-input-group-text {
  align-items: flex-start;
}

.input-group-text-area {
  height: auto !important;
}
</style>
