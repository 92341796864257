<template>
  <SfDeleteModal
    :title="$t('modal.title.confirm-delete')"
    :width="400"
    :height="0"
    closeOnOverlay
    :show.sync="dataShow"
    @update:show="updateShow"
  >
    <div class="content">
      {{ $t('confirm-delete-media-message') }}
    </div>
    <div class="sf-modal-footer">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="sf-modal-action">
              <button class="sf-secondary" type="button" @click="updateShow">
                {{ $t('cancel') }}
              </button>
              <button type="button" class="sf-primary" @click="onClickCornfirm">
                {{ $t('confirm') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SfDeleteModal>
</template>

<script>
import SfDeleteModal from '@/components/SfDeleteModal'
export default {
  components: {
    SfDeleteModal
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dataShow: this.show,
      tag: '',
      tags: [],
      dataInput: {}
    }
  },

  watch: {
    show(value) {
      this.dataShow = value
      this.dataInput = { ...this.data }
    }
  },
  methods: {
    updateShow() {
      this.$emit('update:show', false)
    },
    onClickCornfirm() {
      this.$emit('modal:delete', { ...this.dataInput })
      this.dataInput = {}
    }
  },
  computed: {},
  created() {}
}
</script>

<style lang="scss" scoped>
@import './style-modal-smart-asset';
.content {
  width: 100%;
  text-align: center;
  color: #4a4a4a;
  padding: 35px 45px;
  font-size: 14px;
}
</style>
