import state from './adminSlideTemplateState'
import mutations from './adminSlideTemplateMutations'
import getters from './adminSlideTemplateGetters'
import actions from './adminSlideTemplateActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
