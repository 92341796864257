<template>
  <div class="container-fluid sf-table-page">
    <div class="row mt-3">
      <div class="col-12">
        <h2 class="page-title">Table</h2>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 sf-table">
        <b-table
          show-empty
          striped
          hover
          :items="items"
          :fields="fields"
          head-variant="none"
          table-variant="none"
          thead-class="custom-b-table"
        >
          <template v-slot:empty>
            <p class="d-flex justify-content-center">No data available in table</p>
          </template>
          <template v-slot:head(checkbox)>
            <label class="sf-checkbox">
              <input type="checkbox" />
              <span class="checkmark"></span>
            </label>
          </template>
          <template v-slot:cell(checkbox)>
            <label class="sf-checkbox">
              <input type="checkbox" />
              <span class="checkmark"></span>
            </label>
          </template>

          <template v-slot:head(first_name)="field">
            <span class="custome-arrows">{{ field.label }}</span>
          </template>
          <template v-slot:cell(first_name)="field">
            <p>{{ field.item.first_name }} {{ field.item.last_name }}</p>
          </template>

          <template v-slot:head(email)="field">
            <span class="custome-arrows">{{ field.label }}</span>
          </template>
          <template v-slot:cell(email)="field">
            <p>{{ field.item.email }}</p>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'account',
  components: {},
  data() {
    return {
      items: [
        { age: 40, first_name: 'Dickerson', last_name: 'Macdonald', email: 'test@gmail.com' },
        { age: 21, first_name: 'Larsen', last_name: 'Shaw', email: 'test@gmail.com' },
        { age: 89, first_name: 'Geneva', last_name: 'Wilson', email: 'test@gmail.com' },
        { age: 38, first_name: 'Jami', last_name: 'Carney', email: 'test@gmail.com' }
      ],
      fields: [
        {
          key: 'checkbox',
          colType: 'checkbox',
          label: 'Checkbox',
          sortable: true
        },
        {
          key: 'first_name',
          colType: 'first_name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'email',
          colType: 'email',
          label: 'Email',
          sortable: true
        },
        {
          key: 'headercell',
          colType: 'headercell',
          label: 'Header cell 1'
        },
        {
          key: 'headercell2',
          colType: 'headercell2',
          label: 'Header cell 2'
        },
        {
          key: 'headercell3',
          colType: 'headercell3',
          label: 'Header cell 3'
        }
      ]
    }
  },
  created() {},
  methods: {},
  mounted() {}
}
</script>

<style lang="scss" scoped></style>
