import AdminOverviewService from '../../../../services/admin-overview.service'

export default {
  getOverview({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return AdminOverviewService.getOverview()
      .then((response) => {
        commit('SET_ERROR', '')
        commit('SET_ITEMS', response.data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  }
}
