<template>
  <transition name="modal">
    <div class="ui-modal overlay component" v-if="show" @click="onClickOverlay" ref="overlay">
      <div
        v-bind:style="{
          width: width + 'px',
          height: height ? height + 'px' : 'auto'
        }"
        class="modal-content"
      >
        <div class="header">
          <span class="add-icon"></span>
          <h4 class="title">Media Settings > {{ $t('instagram') }}</h4>
          <button id="closeIGE" class="close" @click="close" v-if="showCloseButton"></button>
        </div>
        <InstagramEditor />
      </div>
    </div>
  </transition>
</template>

<script>
import InstagramEditor from './InstagramEditor'
export default {
  name: 'instagramEditorModal',
  components: { InstagramEditor },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    },
    width: Number,
    title: String,
    height: Number
  },

  methods: {
    close() {
      this.$emit('update:show', false)
    },
    onClickOverlay($event) {
      if (this.closeOnOverlay && $event && $event.target === this.$refs.overlay) {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-modal.overlay.component {
  width: 100vw;
  height: 100vh;
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .modal-content {
    max-width: 100vw;
    background-color: #fff;
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.5);
    border-radius: 0;
    border: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    .header {
      background-image: linear-gradient(to left, #70b1ff, #236cc4);
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      .add-icon {
        background-image: url('../../assets/images/setting-icon.svg');
        width: 40px;
        height: 40%;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        left: 25px;
      }
      h4 {
        margin-bottom: 0;
        font-size: 22px;
        letter-spacing: 1.2px;
        color: #ffffff;
        font-weight: bold;
        font-family: 'Open Sans', sans-serif;
        margin-left: 70px;
      }
      .close {
        width: 32px;
        height: 32px;
        background-image: url('../../assets/images/cancel-icon.svg');
        background-repeat: no-repeat;
        background-position: center;
        justify-self: flex-end;
        position: absolute;
        right: 0;
        opacity: 1;
      }
    }
  }
}

/* modal transition */
.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}
.modal-enter,
.modal-leave-to {
  padding-top: 100px;
  opacity: 0;
}
</style>
