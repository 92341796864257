function getExtension(filename) {
  const parts = filename.split('.')
  return parts[parts.length - 1]
}

export const isValidImage = function (filename) {
  const ext = getExtension(filename)
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'png':
    case 'jpeg':
      return true
  }
  return false
}

export const isValidSvg = function (filename) {
  const ext = getExtension(filename)
  switch (ext.toLowerCase()) {
    case 'svg':
      return true
  }
  return false
}

export const isValidGif = function (filename) {
  const ext = getExtension(filename)
  switch (ext.toLowerCase()) {
    case 'gif':
      return true
  }
  return false
}

export const isValidPDF = function (filename) {
  const ext = getExtension(filename)
  switch (ext.toLowerCase()) {
    case 'pdf':
      return true
  }
  return false
}

export const isValidVideo = function (filename) {
  const ext = getExtension(filename)
  switch (ext.toLowerCase()) {
    case 'mp4':
    case 'webm':
      return true
  }
  return false
}

export const isValidFont = function (filename) {
  const ext = getExtension(filename)
  switch (ext.toLowerCase()) {
    case 'woff':
    case 'woff2':
    case 'ttf':
    case 'otf':
      return true
  }
  return false
}
