import AdminHardwareService from '../../../../services/admin-hardware.service'

export default {
  getAllHardwaresType({ commit }, payload) {
    AdminHardwareService.getAllHardwaresType()
      .then((res) => {
        commit('GET_ALL_HARDWARES', res.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('toast/ERROR', error, { root: true })
        commit('SET_ERROR', error.message)
      })
      .finally(() => commit('SET_LOADING', false))
  }
}
