import state from './adminPostState'
import mutations from './adminPostMutations'
import getters from './adminPostGetters'
import actions from './adminPostActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
