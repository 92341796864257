import BaseService from './base.service'
import Vue from 'vue'
import moment from 'moment-timezone'
class OrganizationService extends BaseService {
  get entity() {
    return 'organizations'
  }

  editOrganizationAccount(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .patch(`${this.entity}/update`, data)
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getAllChildOrganization(type) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/all_child_organization?type=${type}`)
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getChildOrganization(page, limit, search) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(
          `${this.entity}/child_organizations?page=${page}&limit=${limit}${
            search ? `&s=${search}` : ''
          } `
        )
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getAllOrganizationHaveSso() {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/organization_have_sso`)
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  signUpOrganization(data) {
    const params = {
      organization: data
    }
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}`, params)
        .then((response) => {
          Vue.$cookies.set('rememberMe', false)
          const language = response.data.data.user.language
          Vue.$cookies.set('language', language)
          _setAuthData(response.data)
          return resolve(this.responseWrapper(response, response.data))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }
}

function _setAuthData(response) {
  const user = JSON.stringify(response.data.user)
  const remember = Vue.$cookies.get('rememberMe') === 'true'
  if (remember) {
    if (response.data.auth_token) {
      Vue.$cookies.set(
        'accessToken',
        response.data.auth_token,
        formatTime(response.data.access_expires_at)
      )
    }
    if (response.data.refresh_token) {
      Vue.$cookies.set('refreshToken', response.data.refresh_token, '1y')
    }
    Vue.$cookies.set(
      'tokenExpired',
      response.data.access_expires_at,
      formatTime(response.data.access_expires_at)
    )
    if (user) {
      Vue.$cookies.set('user', user, formatTime(response.data.access_expires_at))
    }
  } else {
    if (response.data.auth_token) {
      Vue.$cookies.set('accessToken', response.data.auth_token, 0)
    }
    if (response.data.refresh_token) {
      Vue.$cookies.set('refreshToken', response.data.refresh_token, 0)
    }
    if (response.data.access_expires_at) {
      Vue.$cookies.set('tokenExpired', response.data.access_expires_at, 0)
    }
    if (user) {
      Vue.$cookies.set('user', user, 0)
    }
  }
}

function formatTime(time) {
  return moment(time).format('ddd, DD MMM YYYY HH:mm:ss ZZ')
}

export default new OrganizationService()
