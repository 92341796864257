var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uikit"},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('div',{staticClass:"row mb-1"},[_vm._m(1),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"sf-primary",on:{"click":function($event){return _vm.sfToast('success')}}},[_vm._v("Success")])])]),_c('div',{staticClass:"row mb-1"},[_vm._m(2),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"sf-primary",on:{"click":function($event){return _vm.sfToast('info')}}},[_vm._v("Information")])])]),_c('div',{staticClass:"row mb-1"},[_vm._m(3),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"sf-primary",on:{"click":function($event){return _vm.sfToast('warning')}}},[_vm._v("Warning")])])]),_c('div',{staticClass:"row mb-1"},[_vm._m(4),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"sf-primary",on:{"click":function($event){return _vm.sfToast('error')}}},[_vm._v("Danger")])])]),_c('div',{staticClass:"row mb-1"},[_vm._m(5),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"sf-primary",on:{"click":_vm.sfToastMultiLine}},[_vm._v("Multi lines")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"material-name"},[_c('h2',[_vm._v("Notifications")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("Success")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("Information")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("Warning")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("Danger")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("Multi lines")])])
}]

export { render, staticRenderFns }