var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"vueTree",staticClass:"sl-vue-tree",class:{ 'sl-vue-tree-root': _vm.isRoot },on:{"mousemove":_vm.onMousemoveHandler,"mouseleave":_vm.onMouseleaveHandler,"dragleave":function($event){return _vm.onDragleaveHandler($event)},"dragend":function($event){return _vm.onDragendHandler(null, $event)}}},[_c('div',{ref:"nodes",staticClass:"sl-vue-tree-nodes-list"},[_vm._l((_vm.nodes),function(node,nodeInd){return _c('div',{key:`${node.data.isNew ? 'new-' : ''}${node.data.file_type}-${node.data.id}`,staticClass:"sl-vue-tree-node",class:{ 'sl-vue-tree-selected': node.isSelected },style:({
        display:
          (node.data && node.data.dataHide) ||
          (!node.data.parent_id && node.data.file_type !== 'folder')
            ? 'none'
            : 'initial'
      }),attrs:{"title":node.data.name}},[_c('div',{staticClass:"sl-vue-tree-node-item",class:{
          'sl-vue-tree-cursor-hover':
            _vm.cursorPosition && _vm.cursorPosition.node.pathStr === node.pathStr,
          'sl-vue-tree-cursor-inside':
            _vm.cursorPosition &&
            _vm.cursorPosition.placement === 'inside' &&
            _vm.cursorPosition.node.pathStr === node.pathStr &&
            !_vm.cursorPosition.node.isExpanded,
          'sl-vue-tree-node-is-leaf': node.isLeaf,
          'sl-vue-tree-node-is-folder': !node.isLeaf
        },attrs:{"path":node.pathStr},on:{"contextmenu":function($event){return _vm.emitNodeContextmenu(node, $event)},"dragover":function($event){return _vm.onExternalDragoverHandler(node, $event)},"drop":function($event){return _vm.onExternalDropHandler(node, $event)}}},[_vm._l((_vm.gaps),function(gapInd){return _c('div',{key:gapInd,staticClass:"sl-vue-tree-gap"})}),(_vm.level && _vm.showBranches)?_c('div',{staticClass:"sl-vue-tree-branch"},[_vm._t("branch",function(){return [(!node.isLastChild)?_c('span',[_vm._v(" "+_vm._s(String.fromCharCode(0x251c))+_vm._s(String.fromCharCode(0x2500))+"  ")]):_vm._e(),(node.isLastChild)?_c('span',[_vm._v(" "+_vm._s(String.fromCharCode(0x2514))+_vm._s(String.fromCharCode(0x2500))+"  ")]):_vm._e()]},{"node":node})],2):_vm._e(),_c('div',{staticClass:"sl-vue-tree-title",on:{"click":function($event){return _vm.emitNodeClick(node, $event)},"dblclick":function($event){return _vm.emitNodeDblclick(node, $event)},"mousedown":function($event){return _vm.onNodeMousedownHandler($event, node)},"mouseup":function($event){return _vm.onNodeMouseupHandler($event, node)}}},[(!node.isLeaf)?_c('span',{staticClass:"sl-vue-tree-toggle",class:{ active: !!node.isExpanded },style:({
              opacity: +!!node.data.has_children,
              'pointer-events': !!node.data.has_children ? 'initial' : 'none'
            }),on:{"click":function($event){return _vm.onToggleHandler($event, node)}}},[_vm._t("toggle",function(){return [_c('span',[_vm._v(" "+_vm._s(!node.isLeaf ? (node.isExpanded ? '-' : '+') : '')+" ")])]},{"node":node})],2):_vm._e(),_vm._t("title",function(){return [_vm._v(_vm._s(node.title))]},{"node":node}),(!node.isLeaf && node.children.length == 0 && node.isExpanded)?_vm._t("empty-node",null,{"node":node}):_vm._e()],2),_c('div',{staticClass:"sl-vue-tree-sidebar"},[_vm._t("sidebar",null,{"node":node})],2)],2),(node.children && node.children.length && node.isExpanded)?_c('sl-vue-tree',{attrs:{"value":node.children,"level":node.level,"parentInd":nodeInd,"allowMultiselect":_vm.allowMultiselect,"allowToggleBranch":_vm.allowToggleBranch,"edgeSize":_vm.edgeSize,"showBranches":_vm.showBranches},on:{"dragover":function($event){$event.preventDefault();}},scopedSlots:_vm._u([{key:"title",fn:function({ node }){return [_vm._t("title",function(){return [_vm._v(_vm._s(node.title))]},{"node":node})]}},{key:"toggle",fn:function({ node }){return [_vm._t("toggle",function(){return [_c('span',[_vm._v(" "+_vm._s(!node.isLeaf ? (node.isExpanded ? '-' : '+') : '')+" ")])]},{"node":node})]}},{key:"sidebar",fn:function({ node }){return [_vm._t("sidebar",null,{"node":node})]}},{key:"empty-node",fn:function({ node }){return [(!node.isLeaf && node.children.length == 0 && node.isExpanded)?_vm._t("empty-node",null,{"node":node}):_vm._e()]}}],null,true)}):_vm._e(),_c('div',{staticClass:"sl-vue-tree-cursor sl-vue-tree-cursor_after",style:({
          visibility:
            _vm.cursorPosition &&
            _vm.cursorPosition.node.pathStr === node.pathStr &&
            (_vm.cursorPosition.placement === 'after' || _vm.cursorPosition.placement === 'before')
              ? 'visible'
              : 'hidden'
        }),on:{"dragover":function($event){$event.preventDefault();}}})],1)}),(_vm.isRoot)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDragging),expression:"isDragging"}],ref:"dragInfo",staticClass:"sl-vue-tree-drag-info"},[_vm._t("draginfo",function(){return [_vm._v(" Items: "+_vm._s(_vm.selectionSize)+" ")]})],2):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }