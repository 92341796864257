import BaseService from './base.service'

class PlayerService extends BaseService {
  get entity() {
    return 'players'
  }

  getAllPlayers(page, limit, search, orientation) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(
          search
            ? `${this.entity}?page=${page}&limit=${limit}&s=${search}&orientation=${orientation}`
            : `${this.entity}?page=${page}&limit=${limit}&orientation=${orientation}`
        )
        .then((response) => {
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getInfoPlayerByID(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/${id}`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  updatePlayerByID(data, id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .patch(`${this.entity}/${id}`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  updatePlayerNoteByID(data, id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .patch(`${this.entity}/${id}/notes`, data)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getAllFiles(id, page, limit, search) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(
          search
            ? `${this.entity}/${id}/files?page=${page}&limit=${limit}&s=${search}`
            : `${this.entity}/${id}/files?page=${page}&limit=${limit}`
        )
        .then((response) => {
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getAllPlayersWithOrientation(orientation) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/all_players?orientation=${orientation}`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  deletePlayer(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .delete(`${this.entity}/${id}`)
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  registerPlayer(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/register`, data)
        .then((response) =>
          resolve(this.responseWrapper(response, response.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getProductChargeOver() {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/get_product_chargeover`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, null))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  createCustomerChargeOver() {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/create_customer`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, null))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  updateCustomerAddressChargeOver(address) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .put(`${this.entity}/update_customer`, address)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, null))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  createInvoiceChargeOver(data) {
    const params = {
      id_customer: data.customerId,
      cycle: data.cycle,
      first_rate: data.playerDeviceUnitPrice,
      first_product_id: data.deviceId,
      first_quantity: data.playerDevices,
      second_rate: data.playerLicenseUnitPrice,
      second_product_id: data.licenseId,
      second_quantity: data.playerLicenses,
      third_rate: data.playerShippingUnitPrice,
      third_product_id: data.playerShippingId
    }
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/create_invoice_chargeover`, params)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, null))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  makeChargeOverTransaction(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/transaction`, data)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, null))
        })
        .catch((error) => {
          return reject(error)
        })
    })
  }

  getChargeOverInvoiceById(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/get_invoice?invoice_id=${id}`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  async makeChargeOverPayment(data) {
    try {
      const invoice = await this.createInvoiceChargeOver(data)
      const invoiceId = invoice.data ? invoice.data.response.id : ''
      const dataTrans = {
        id_customer: data.customerId,
        invoice_id: invoiceId,
        token: data.cardToken,
        number_players: data.playerLicenses
      }
      const response = await this.makeChargeOverTransaction(dataTrans)
      response.invoiceId = invoiceId

      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }

  getAllPlayerChildren(payload) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(
          `${this.entity}/players_children?organization_id=${payload.id}&page=${
            payload.page
          }&limit=${payload.limit}${payload.search ? `&s=${payload.search}` : ''}`
        )
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => {
          reject(this.errorWrapper(error))
          this.refresh()
        })
    })
  }

  getUnregisteredPlayers(page, limit, search) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(
          search
            ? `${this.entity}/unregistered?page=${page}&limit=${limit}&s=${search}`
            : `${this.entity}/unregistered?page=${page}&limit=${limit}`
        )
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  connectUnregisteredPlayer(payload) {
    const params = {
      uniqkey: payload.params
    }
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .patch(`${this.entity}/${payload.id}/register`, params)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getConnectedPlayers(uniqkey, page, limit, search) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(
          search
            ? `${this.entity}/players_connected?uniqkey=${uniqkey}&page=${page}&limit=${limit}&s=${search}`
            : `${this.entity}/players_connected?uniqkey=${uniqkey}&page=${page}&limit=${limit}`
        )
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  reconnectPlayer(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/${id}/player_reconnect`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  requestScreenShot(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/${id}/screenshot`)
        .then((response) => resolve(this.responseWrapper(response, response.data.data)))
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  rebootPlayer(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/${id}/reboot_player`)
        .then((response) => resolve(this.responseWrapper(response, response.data.data)))
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  refresh() {
    return window.location.reload()
  }
}

export default new PlayerService()
