/**
 * HTTP request layer
 * if auth is required return patched axios instance(with access token in headers)
 * else return clear axios instance
 */

import axios from 'axios'
import Vue from 'vue'
import * as authService from '../services/auth.service'
import { API_URL } from '../.env'

export default class Http {
  constructor(status) {
    this.isAuth = status && status.auth ? status.auth : false
    this.instance = axios.create({
      baseURL: API_URL
    })

    return this.init()
  }

  init() {
    if (this.isAuth) {
      this.instance.interceptors.request.use(
        (request) => {
          const accessToken = Vue.$cookies.get('accessToken')
          const refreshToken = Vue.$cookies.get('refreshToken')
          let languageCookies = Vue.$cookies.get('language') || 'en'
          if (languageCookies === 'null') {
            languageCookies = 'en'
          }
          const language = languageCookies
          request.headers.Authorization = accessToken
          request.headers.Locale = language
          if (authService.isAccessTokenExpired() && refreshToken) {
            return authService.refreshTokens()
          } else {
            return request
          }
        },
        (error) => {
          return Promise.reject(error)
        }
      )

      return this.instance
    }
    return this.instance
  }
}
