<template>
  <div class="row background">
    <div class="col-12">
      <p class="action-title">{{ $t('background') }}</p>
      <p class="action-desc">
        {{ $t('choose-color-or-custom-artwork') }}
      </p>
    </div>
    <div class="col-12 mt-15">
      <p>{{ $t('images') }} (png or jpg)</p>
      <div class="row mt-10">
        <div class="col-4">
          <div class="bg-upload">
            <div class="upload-btn-wrapper">
              <div class="btn">+</div>
              <input
                type="file"
                name="myfile"
                @change="handleUploadImage($event)"
                accept="image/*"
              />
            </div>
          </div>
        </div>
        <div
          v-if="bgColorEditor.bgImage.imageData && bgColorEditor.bgImage.imageData != 'none'"
          class="col-6"
        >
          <div class="show-upload-img">
            <img :src="bgColorEditor.bgImage.imageData" />
            <a @click="handleRemoveImage" class="remove-img">X</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-15">
      <div class="row">
        <div class="col-6">
          <p>{{ $t('background-color') }}</p>
        </div>
        <div class="col-6">
          <div class="color">
            <ColorPicker :getBgColor="getBgColor" @onColorChange="handleColorChange" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isValidImage } from '@/helper/imageHelper'
import ColorPicker from './ColorPicker.vue'
export default {
  components: {
    ColorPicker
  },
  data() {
    return {
      imageData: ''
    }
  },
  mounted() {},
  computed: {
    getBgColor() {
      const bgColorEditor = this.$store.getters['instagrameditor/getBgColorEditor']
      const bgColor = bgColorEditor.color
      if (bgColor) {
        return this.$emit('onUpdateColor', bgColor)
      }
      return false
    },
    bgColorEditor() {
      return this.$store.getters['instagrameditor/getBgColorEditor']
    }
  },
  methods: {
    handleColorChange(event) {
      this.$store.dispatch('instagrameditor/updateBgColorEditor', event)
      this.$store.dispatch('instagrameditor/pushToHistory')
    },
    handleUploadImage(event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const fileName = input.files[0].name
        if (isValidImage(fileName)) {
          const reader = new FileReader()
          reader.onload = (e) => {
            const imageData = e.target.result
            const payload = {
              imageData,
              file: input.files[0]
            }
            this.$store.dispatch('instagrameditor/setBgImage', payload)
            this.$store.dispatch('instagrameditor/pushToHistory')
          }
          reader.readAsDataURL(input.files[0])
        } else {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Upload image Error',
            text: 'Invaid image! please upload a png or jpg file'
          })
        }
      }
    },
    handleRemoveImage() {
      this.$store.dispatch('instagrameditor/removeBgImage')
    }
  }
}
</script>

<style scoped>
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper .btn {
  border: 1px dotted #2c7fe1;
  width: 60px;
  border-radius: 0;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2c7fe1;
  font-size: 20px;
  font-weight: 600;
}
.show-upload-img img {
  height: 60px;
}

.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
</style>
