<template>
  <SfModal
    :title="'Add Smart Asset'"
    :width="768"
    :height="0"
    closeOnOverlay
    :show.sync="dataShow"
    @update:show="updateShow"
  >
    <div class="sf-modal-body">
      <div class="container">
        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">Title</label>
              </div>
              <input
                type="text"
                name="smart_asset"
                id="smart_asset_displayname"
                class="sf-input-text"
                placeholder="Title"
                v-model.trim="dataInput.displayname"
                :class="{ 'input-error-form': submitted && $v.dataInput.displayname.$invalid }"
              />
            </div>
            <div
              v-show="submitted && !$v.dataInput.displayname.minLength"
              class="invalid-error-text text-right"
            >
              {{ $t('title-minLength-2') }}
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.displayname.required"
            >
              Asset Title can't be blank
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">Tags</label>
              </div>
              <vue-tags-input
                class="sf-tag"
                v-model="tag"
                :tags="dataInput.tags"
                @tags-changed="(newTags) => (dataInput.tags = newTags)"
              />
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group label-top">
                <label class="primary-label">Orientation</label>
              </div>
              <div class="row orientation-type">
                <div class="col-6">
                  <label class="sf-radio"
                    >Landscape (Default)
                    <input
                      id="landscape-radio"
                      type="radio"
                      value="landscape"
                      v-model="dataInput.orientation"
                      class="sf-radio"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-6">
                  <label class="sf-radio"
                    >Portrait
                    <input
                      id="portrait-radio"
                      type="radio"
                      value="portrait"
                      v-model="dataInput.orientation"
                      class="sf-radio"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-12 mt-3">
                  <div class="row orientation-img-wrap">
                    <div
                      for="landscape-radio"
                      :class="{ active: dataInput.orientation === 'landscape' }"
                      class="col-6 landscape orientation-img"
                    >
                      <img src="~@/assets/images/landscape-review.svg" />
                    </div>
                    <div
                      for="portrait-radio"
                      :class="{ active: dataInput.orientation === 'portrait' }"
                      class="col-6 orientation-img portrait d-flex"
                    >
                      <img src="~@/assets/images/portrait-review.svg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sf-modal-footer mt-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="sf-modal-action">
              <button class="sf-secondary" type="button" @click="updateShow">Cancel</button>
              <button type="button" class="sf-primary" @click="onClickNext">Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SfModal>
</template>

<script>
import SfModal from '@/components/SfModal'
import VueTagsInput from '@johmun/vue-tags-input'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  components: {
    SfModal,
    VueTagsInput
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataShow: this.show,
      tag: '',
      dataInput: {
        displayname: '',
        tags: [],
        orientation: 'landscape',
        smart_asset_type: 'custom_asset'
      },
      submitted: false
    }
  },
  validations: {
    dataInput: {
      displayname: {
        required,
        minLength: minLength(2)
      }
    }
  },
  watch: {
    show(value) {
      this.dataShow = value
    }
  },
  methods: {
    updateShow() {
      this.$emit('update:show', false)
    },
    onClickNext() {
      this.submitted = true
      if (this.$v.dataInput.$invalid) {
        return
      }
      this.$emit('modal:next', { ...this.dataInput })
    },
    resetForm() {
      this.dataInput = {
        displayname: '',
        tags: [],
        orientation: 'landscape',
        smart_asset_type: 'custom_asset'
      }
    }
  },
  computed: {},
  created() {
    this.$bus.$on('reset-submitted', (value) => {
      this.submitted = false
      this.resetForm()
    })
  },
  beforeDestroy() {
    this.dataInput = {
      dataInput: '',
      orientation: 'landscape'
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style-modal-smart-asset.scss';
</style>

<style lang="scss">
.ti-tag-center span {
  word-break: break-all;
}
.form-control {
  height: auto !important;
  .vue-tags-input {
    margin: -0.375rem -0.75rem;
    border-radius: 0.25rem;
    * {
      border: transparent;
    }
    border: transparent;
    .ti-tag {
      background-color: #e0edf5;
      border-radius: 3px;
      color: #2f5d92;
      float: left;
      font-weight: 600;
      height: 28px;
      line-height: 28px;
      margin: 0 8px 6px 0;
      padding: 0 1px 0 8px;
      white-space: nowrap;
    }
  }
}
</style>
