import router from '@/router'
export default {
  data() {
    return {
      user: null,
      router: router
    }
  },
  created() {
    const user = this.$cookies.get('user')
    if (user) {
      this.user = user
    }
  },
  methods: {
    // if employee && not subadmin return true, otherwise check if menu is in user menu
    checkMenu(menu) {
      if (this.user) {
        if (this.user.user_type === 'employee' && !this.user.is_subadmin) {
          return true
        } else {
          for (const key in this.user.menu) {
            if (menu === key) {
              return this.user.menu[`${menu}`]
            }
          }
        }
      }
      return false
    },
    checkMenuAll(menu) {
      const allow = Object.entries(menu).some(([key, value]) => {
        return this.checkMenu(value)
      })
      return allow
    },
    redirectIfMenuAvailable(menuConst, prefix = '/admin') {
      const segments = this.$route.path.split('/')
      const lastSegmentUrl = segments[segments.length - 1]
      const admin = this.router.options.routes.find((route) => route.path === prefix)
      const menu = admin.children.find((route) => route.path === lastSegmentUrl)
      if (!menu) return

      for (const route of menu.children) {
        if (this.checkMenu(menuConst[route.name]) && this.$route.path !== route.path) {
          this.$router.push({ path: `${prefix}/${lastSegmentUrl}/${route.path}` })
          break
        }
      }
    }
  }
}
