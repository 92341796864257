export default {
  SET_ERROR(state, data) {
    state.error = data
  },

  SET_LOADING(state, data) {
    state.loading = data
  },

  GET_ALL_FIRMWARE_ADMIN(state, data) {
    state.items = data.items
    state.pagination.total = data.total_pages
    state.pagination.page = data.current_page
  },

  GET_ALL_FIRMWARE_ADMIN_NO_PAGING(state, data) {
    state.all_pi_firmware = data
  }
}
