<template>
  <div class="main">
    <div class="header-404">
      <div class="logo">
        <img
          @click="$router.push({ name: 'index' })"
          src="https://ui.screenfluence.com/images/logo.svg"
        />
      </div>
      <div class="border-linear"></div>
    </div>
    <div class="content">
      <p class="error-type">404</p>
      <p class="tittle">WHOOPS!</p>
      <p class="message">
        Something went wrong on our end, we've been informed of the issue, please try again, or
        contact <a href="mailto:support@screenfluence.com">support@screenfluence.com</a>
      </p>
      <button @click="$router.push({ name: 'index' })">Go home now</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100vh;
  background-image: url(https://ui.screenfluence.com/images/error-bg.svg);
  background-repeat: no-repeat;
  background-position: right;
  .header-404 {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    .logo {
      margin-left: 200px;
      width: 100%;
      cursor: pointer;
      img {
        width: 251px;
        height: 31px;
      }
    }
    .border-linear {
      width: 100%;
      background: linear-gradient(to right, #0b87ec, #7ed321, #0b87ec);
      padding: 1.4px 0;
    }
  }
  .content {
    width: 50%;
    padding: 0px 50px;
    margin-left: 20px;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .error-type {
      width: 100%;
      font: 180px arial, sans-serif;
      font-weight: 600;
      color: #0b87ec;
      text-align: center;
      margin-top: 10px;
    }
    .tittle {
      width: 100%;
      height: 67px;
      font: 45px arial, sans-serif;
      color: #0b87ec;
      text-align: center;
      font-weight: 600;
      padding-bottom: 10px;
    }
    .message {
      text-align: center;
      margin: 16px 0px;
      color: #333333;
      font: 16px arial, sans-serif;
    }
    button {
      width: 200px;
      height: 40px;
      border-radius: 20px;
      border: solid 1px #55a0f9;
      display: inline-block;
      line-height: 40px;
      text-decoration: none;
      text-align: center;
      background: #fff;
      color: #0b87ec;
      font: 16px arial, sans-serif;
      margin-top: 40px;
    }
  }
}
@media only screen and (max-width: 1100px) {
  .main {
    background-image: none;
    .content {
      width: 100%;
      margin-left: 0;
    }
  }
}
@media only screen and (max-width: 768px) {
  .main {
    .header-404 {
      .logo {
        margin-left: 20px !important;
      }
    }
    .content {
      .error-type {
        font-size: 80px;
      }
      .tittle {
        font-size: 36px;
      }
    }
  }
}
</style>
