import state from './libraryState'
import mutations from './libraryMutations'
import getters from './libraryGetters'
import actions from './libraryActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
