export default {
  isAuthentication: false,
  listIG: [],
  currentPost: 0,
  isFirstSlide: true,
  isLastSlide: false,
  templateID: '',
  backgroundExisting: false,
  logoExisting: false,
  templateType: 'smart_asset',
  templateAction: true,
  photoSize: {
    isTall: false,
    isWide: false,
    isSquare: false
  },
  toolBar: {
    overall: false,
    widget: false,
    edit: true,
    background: false
  },
  activeAlignItem: {
    top: true,
    middle: false,
    bottom: false
  },
  activeTextAlign: {
    left: true,
    right: false,
    center: false,
    justify: false
  },
  photo: {
    visitable: true,
    x: 0,
    y: 0,
    w: 360,
    h: 360,
    imageURL: ''
  },
  profilePic: {
    visitable: true,
    x: 400,
    y: 20,
    w: 50,
    h: 50,
    imageURL: ''
  },
  titleIg: {
    visitable: true,
    x: 400,
    y: 100,
    w: 160,
    h: 40,
    textAttr: {
      textAlign: 'left',
      alignItems: 'flex-start',
      textColor: '#212529',
      backgroundColor: '#ffffff00',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14
    },
    text: ''
  },
  textIg: {
    visitable: true,
    x: 400,
    y: 160,
    w: 220,
    h: 50,
    textAttr: {
      textAlign: 'left',
      alignItems: 'flex-start',
      textColor: '#212529',
      backgroundColor: '#ffffff00',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14
    },
    mentions: {
      textColor: '#2c7fe1',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14
    },
    hashtags: {
      textColor: '#2c7fe1',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14
    },
    text: ''
  },
  tagsIg: {
    visitable: false,
    x: 400,
    y: 240,
    w: 220,
    h: 50,
    textAttr: {
      textAlign: 'left',
      alignItems: 'flex-start',
      textColor: '#212529',
      backgroundColor: '#ffffff00',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14
    },
    mentions: {
      textColor: '#2c7fe1',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14
    },
    hashtags: {
      textColor: '#2c7fe1',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14
    },
    text: ''
  },
  bgEditor: {
    color: '#ffffff',
    bgImage: {
      imageData: '',
      file: '',
      templateID: ''
    }
  },
  logo: {
    imageData: '',
    file: '',
    templateID: ''
  },
  mask: {},
  elementActive: {
    photo: true,
    profilePic: false,
    titleIg: false,
    textIg: false,
    tagsIg: false
  },
  gridDrag: [1, 1],
  snap: false,
  instagramInfo: {},
  instagramData: {},
  numberData: {
    numberOfSlide: 5,
    durationPerSlide: 10
  },
  templateName: ''
}
