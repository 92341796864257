<template>
  <div class="edit-photo-container">
    <div class="row background">
      <div class="col-12">
        <p class="action-title">{{ $t('edit-logo') }}</p>
        <p class="action-desc">
          {{ $t('choose-color-or-custom-artwork') }}
        </p>
      </div>
      <div class="col-12 mt-15">
        <p>{{ $t('upload-new-logo') }} (png or jpg)</p>
        <div class="row edit-logo mt-10">
          <div class="col-4">
            <div class="bg-upload">
              <div class="upload-btn-wrapper">
                <div class="btn">+</div>
                <input
                  type="file"
                  name="myfile"
                  @change="handleUploadImage($event)"
                  accept="image/*"
                />
              </div>
            </div>
          </div>
          <div v-if="getLogo && getLogo != 'none'" class="col-6">
            <div class="show-upload-img">
              <img :src="getLogo" />
              <a @click="handleRemoveImage" class="remove-img">X</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="remove-widget-wrapper">
      <div class="remove-widget">
        <button @click="removeWidget" class="remove-widget-btn">{{ $t('remove-widget') }}</button>
      </div>
    </div>
  </div>
</template>
<script>
// import ColorPicker from './ColorPicker.vue'
import { isValidImage } from '@/helper/imageHelper'
export default {
  components: {
    // ColorPicker
  },
  data() {
    return {
      imageData: ''
    }
  },
  mounted() {},
  computed: {
    getLogo() {
      const logo = this.$store.getters['instagrameditor/getLogo']
      if (logo && logo.imageData) {
        return logo.imageData
      } else {
        return null
      }
    }
  },
  methods: {
    removeWidget() {
      this.$store.dispatch('instagrameditor/removeWidget', 'profilePic')
    },
    handleUploadImage(event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const fileName = input.files[0].name
        if (isValidImage(fileName)) {
          const reader = new FileReader()
          reader.onload = (e) => {
            const imageData = e.target.result
            const payload = {
              imageData,
              file: input.files[0]
            }
            this.$store.dispatch('instagrameditor/setLogoImage', payload)
            this.$store.dispatch('instagrameditor/pushToHistory')
          }
          reader.readAsDataURL(input.files[0])
        } else {
          this.$toast.open({
            message: 'Invaid image! please upload a png or jpg file',
            type: 'error'
          })
        }
      }
    },
    handleRemoveImage() {
      this.$store.dispatch('instagrameditor/removeLogoImage')
    }
  }
}
</script>

<style scoped></style>
