import state from './adminOrganizationState'
import mutations from './adminOrganizationMutations'
import getters from './adminOrganizationGetters'
import actions from './adminOrganizationActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
