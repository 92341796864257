<template>
  <div v-if="active" class="attr">
    <div>
      <div class="row origin">
        <div class="col-2">
          <p>
            <b>{{ $t('positioning') }}</b>
          </p>
        </div>
        <div class="col-6 positioning">
          <div class="positioning-field">
            <input
              name="originX"
              v-model="attributeEl.x"
              @input="setOriginX(attributeEl.x)"
              @change="setOriginX(attributeEl.x)"
              class="form-control"
              type="text"
            />
            <span>X</span>
          </div>
          <div class="positioning-field">
            <input
              name="originY"
              v-model="attributeEl.y"
              @input="setOriginY(attributeEl.y)"
              @change="setOriginY(attributeEl.y)"
              class="form-control"
              type="text"
            />
            <span>Y</span>
          </div>
          <div class="positioning-field b-left">
            <input
              v-model="attributeEl.w"
              @input="setDimensionsWidth(attributeEl.w)"
              @change="setDimensionsWidth(attributeEl.w)"
              class="form-control"
              type="text"
            />
            <span>W</span>
          </div>
          <div class="positioning-field">
            <input
              v-model="attributeEl.h"
              @input="setDimensionsHeight(attributeEl.h)"
              @change="setDimensionsHeight(attributeEl.h)"
              class="form-control"
              type="text"
            />
            <span>H</span>
          </div>
        </div>

        <div v-if="getSnap" class="col-4 align-box">
          <ul class="lst-align-box">
            <li class="align-item">
              <a @click="setAlignTop()"><img v-bind:src="alignToTopIcon" /></a>
            </li>
            <li class="align-item">
              <a @click="setAlignMiddle()"><img v-bind:src="alignToMiddleIcon" /></a>
            </li>
            <li class="align-item">
              <a @click="setAlignBottom()"><img v-bind:src="alignToBottomIcon" /></a>
            </li>

            <li class="align-item">
              <a @click="setJustifyLeft()"><img v-bind:src="justifyToLeftIcon" /></a>
            </li>

            <li class="align-item">
              <a @click="setJustifyCenter()"><img v-bind:src="justifyToMiddleIcon" /></a>
            </li>

            <li class="align-item">
              <a @click="setJustifyRight()"><img v-bind:src="justifyToRightIcon" /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import alignToTopIcon from '@/assets/images/align-to-top-icon.svg'
import alignToMiddleIcon from '@/assets/images/align-to-middle-icon.svg'
import alignToBottomIcon from '@/assets/images/align-to-bottom-icon.svg'
import justifyToMiddleIcon from '@/assets/images/justify-to-middle-icon.svg'
import justifyToLeftIcon from '@/assets/images/justify-to-left-icon.svg'
import justifyToRightIcon from '@/assets/images/justify-to-right-icon.svg'

export default {
  name: 'origin',
  props: {
    active: Boolean,
    attributeEl: Object
  },
  data: function () {
    return {
      alignToTopIcon,
      alignToMiddleIcon,
      alignToBottomIcon,
      justifyToMiddleIcon,
      justifyToLeftIcon,
      justifyToRightIcon,
      snapActive: this.$store.getters['instagrameditor/getSnap']
    }
  },
  updated() {},
  computed: {
    getSnap() {
      const snap = this.$store.getters['instagrameditor/getSnap']
      return !snap
    }
  },
  methods: {
    setOriginX(originX) {
      if (originX > 0) {
        this.$store.dispatch('instagrameditor/setOriginX', Number(originX))
        this.$store.dispatch('instagrameditor/pushToHistory')
      }
    },
    setOriginY(originY) {
      if (originY > 0) {
        this.$store.dispatch('instagrameditor/setOriginY', Number(originY))
        this.$store.dispatch('instagrameditor/pushToHistory')
      }
    },
    setDimensionsWidth(width) {
      if (width > 0) {
        this.$store.dispatch('instagrameditor/setDimensionsWidth', Number(width))
        this.$store.dispatch('instagrameditor/pushToHistory')
      }
    },
    setDimensionsHeight(height) {
      if (height > 0) {
        this.$store.dispatch('instagrameditor/setDimensionsHeight', Number(height))
        this.$store.dispatch('instagrameditor/pushToHistory')
      }
    },
    setAlignTop() {
      this.$store.dispatch('instagrameditor/setOriginY', 0)
      this.$store.dispatch('instagrameditor/pushToHistory')
    },
    setAlignBottom() {
      const editorArea = document.getElementById('editorArea')
      const editorAreaHeight = editorArea.offsetHeight
      const elementHeight = this.attributeEl.h
      const bottom = editorAreaHeight - elementHeight
      this.$store.dispatch('instagrameditor/setOriginY', bottom)
      this.$store.dispatch('instagrameditor/pushToHistory')
    },

    setAlignMiddle() {
      const editorArea = document.getElementById('editorArea')
      const editorAreaHeight = editorArea.offsetHeight
      const elementHeight = this.attributeEl.h
      const middle = editorAreaHeight / 2 - elementHeight / 2
      this.$store.dispatch('instagrameditor/setOriginY', Math.floor(middle))
      this.$store.dispatch('instagrameditor/pushToHistory')
    },
    setJustifyLeft() {
      this.$store.dispatch('instagrameditor/setOriginX', 0)
      this.$store.dispatch('instagrameditor/pushToHistory')
    },
    setJustifyCenter() {
      const editorArea = document.getElementById('editorArea')
      const editorAreaWidth = editorArea.offsetWidth
      const elementWidth = this.attributeEl.w
      const center = editorAreaWidth / 2 - elementWidth / 2
      this.$store.dispatch('instagrameditor/setOriginX', Math.floor(center))
      this.$store.dispatch('instagrameditor/pushToHistory')
    },

    setJustifyRight() {
      const editorArea = document.getElementById('editorArea')
      const editorAreaWidth = editorArea.offsetWidth
      const elementWidth = this.attributeEl.w
      const right = editorAreaWidth - elementWidth
      this.$store.dispatch('instagrameditor/setOriginX', Math.floor(right))
      this.$store.dispatch('instagrameditor/pushToHistory')
    }
  }
}
</script>
<style scoped>
.attr {
  height: 42px;
}
.origin {
  margin: 15px 0;
}
.positioning {
  display: flex;
}

.lst-align-box {
  padding: 0;
  list-style: none;
}

.lst-align-box li {
  float: left;
  margin: 0px 3px;
  border-radius: 4px;
  border: solid 1px #e8e8e8;
}
.align-item a {
  cursor: pointer;
  padding: 3px;
  display: block;
}
.positioning-field {
  padding: 0 5px;
  position: relative;
  height: 25px;
}
.positioning-field input {
  max-width: 80px;
  height: 25px !important;
  font-size: 12px;
  box-shadow: none;
  padding-left: 5px;
}
.positioning-field span {
  position: absolute;
  right: 10px;
  top: 8px;
  color: #bdbdbd;
  font-size: 10px;
}
.positioning-field.b-left {
  border-left: 1.5px solid #dcdcdc;
}
</style>
