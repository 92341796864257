import { sortNameFolder } from '../../../helper/helper'
import Vue from 'vue'
import i18n from '../../../i18n'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import set from 'lodash/set'
export default {
  // LOOP DETAIL PAGE
  SET_PAGE_LOOP_ID(state, id) {
    state.loopId = id
  },

  SET_ALL_NESTED_ITEMS_IN_FOlDER(state, data) {
    state.nested_items = data.nested_items
  },
  RESET_ALL_NESTED_ITEMS_IN_FOlDER(state, data) {
    state.nested_items = []
  },

  SET_ITEMS(state, data) {
    state.items = data.items || state.items
    state.pagination.page = data.current_page || state.pagination.page
    state.pagination.total = data.total_pages
    state.statistical = data.statistical || state.statistical
  },
  SET_DEFAULT_BREADCRUMB(state) {
    state.breadcrumb = [
      {
        name: i18n.t('library'),
        url: '/apisite/v1/library',
        type: 'main'
      }
    ]
  },

  SET_DROP_BY_ID(state, id) {
    state.items = state.items.filter((item) => item.id !== id)
  },

  SET_ITEMS_IN_FOlDER(state, data) {
    state.items = data.items || state.items
    state.pagination.page = data.current_page || state.pagination.page
    state.pagination.total = data.total_pages || state.pagination.total
    state.statistical = data.statistical
    data.breadcrumb[0].type = 'main'
    for (let i = 1; i < data.breadcrumb.length; i++) {
      data.breadcrumb[i].type = 'folder'
      const url = data.breadcrumb[i].url
      const urlSplit = url.split('/')
      data.breadcrumb[i].id = urlSplit[urlSplit.length - 2]
    }
    state.breadcrumb = data.breadcrumb
  },

  SET_PAGINATION_PAGE(state, data) {
    state.pagination.page = data
  },

  SET_TREE_FOLDER(state, data) {
    state.treeFolder = formatterTreeFolder(data)
    state.treeFolder = sortNameFolder(state.treeFolder)
  },

  SET_TREE_FOLDER_CHANGED(state, data) {
    state.treeFolder = [...data]
  },

  SET_TREE_FOLDER_ID(state, payload) {
    const id = payload.id
    const treeFolder = payload.items
    const loopDetailPageTreeFolder = state.treeFolder
    const temp = findById(loopDetailPageTreeFolder, id)
    // temp.isExpanded = true
    temp.data.isGetData = true

    let newValue = [...temp.children, ...formatterTreeFolder(treeFolder)]
    newValue = sortNameFolder(newValue)
    const valueTemp = {}
    newValue.forEach((element) => {
      valueTemp[`${element.data.file_type}-${element.data.id}`] = element
    })
    temp.children = Object.keys(valueTemp).map((item) => valueTemp[item])
    state.treeFolder = [...loopDetailPageTreeFolder]
  },
  // ===========================================================================
  SET_ERROR(state, data) {
    state.error = data
  },

  SET_LOADING(state, data) {
    state.loading = data
  },
  SET_LOADING_ASSETS(state, data) {
    state.loadingAssets = data
  },

  CREATE_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.items)
    let treeFolder = Vue._.cloneDeep(state.treeFolder)
    const item = data.data
    const folderId = get(item, 'folder.id', item.id)

    let parentCurrent = ''
    let root = false
    const parentId = item.parent_id
      ? item.parent_id
      : item.type === 'folder'
      ? null
      : item.folder.parent_id
    if (parentId) {
      parentCurrent = findById(treeFolder, parentId)
    } else {
      if (item.root_id) {
        parentCurrent = findById(treeFolder, item.root_id)
        root = true
      } else {
        if (item.organization_id !== data.currentOrganization) {
          parentCurrent = findById(treeFolder, item.organization_id)
        } else {
          root = true
        }
      }
    }
    if (root) {
      if (item.type === 'folder') {
        treeFolder = sortNameFolder([formatTypeFolder(item), ...treeFolder])
      }
    } else {
      if (!isEmpty(parentCurrent)) {
        parentCurrent.isExpanded = true
        parentCurrent.data.has_children = true
        if (item.type === 'folder') {
          parentCurrent.children = sortNameFolder([
            formatTypeFolder(item),
            ...parentCurrent.children
          ])
        } else {
          const newItem = formatTypeItem(item)
          set(newItem, 'data.id', folderId)
          set(newItem, 'data.parent_id', get(newItem, 'data.folder.parent_id', null))
          parentCurrent.children = sortNameFolder([newItem, ...parentCurrent.children])
        }
      }
    }
    if (
      !parentId ||
      get(state.breadcrumb, `[${state.breadcrumb.length - 1}].id`, null) === parentId.toString()
    ) {
      items.unshift(item)
    }

    state.items = items
    state.treeFolder = sortNameFolder(treeFolder)
  },

  DELETE_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.items)
    let treeFolder = Vue._.cloneDeep(state.treeFolder)

    const item = data.data
    const folder = item.folder ? item.folder : item
    const foundIndexItems = items.findIndex((x) => x.id === item.id)
    if (foundIndexItems >= 0) {
      items.splice(foundIndexItems, 1)
    }
    let rootList = []
    let parentCurrent = ''

    if (folder.parent_id) {
      // delete subfolder
      parentCurrent = findById(treeFolder, folder.parent_id)
      rootList = parentCurrent.children
      if (item.type === 'folder') {
        const currentFolder = findById(treeFolder, item.id)
        const children = currentFolder ? currentFolder.children : null
        // show sub folders in tree folder when delete parent folder that has children
        if (children && children.length) {
          const subFolders = children.filter((p) => p.data.file_type === 'folder')
          subFolders.forEach((p) => {
            const folder = cloneDeep(p)
            folder.data.parent_id = null
            treeFolder.unshift(folder)
          })
        }
      }
    } else {
      if (item.type === 'folder') {
        // delete folder root
        const currentFolder = treeFolder.find((x) => x.data.id === item.id)
        const children = currentFolder ? currentFolder.children : null
        const indexChild = treeFolder.findIndex((x) => x.data.id === item.id)
        treeFolder.splice(indexChild, 1)
        // show sub folders in tree folder when delete root folder that has children
        if (children && children.length) {
          const subFolders = children.filter((p) => p.data.file_type === 'folder')
          subFolders.forEach((p) => {
            const folder = cloneDeep(p)
            folder.data.parent_id = null
            treeFolder.unshift(folder)
          })
        }
      }
      if (folder.root_id) {
        parentCurrent = findById(treeFolder, folder.root_id)
        if (!isEmpty(parentCurrent)) {
          treeFolder = treeFolder.filter((p) => data.data.id !== p.data.id)
          rootList = !isEmpty(parentCurrent) ? parentCurrent.children : treeFolder
        }
      } else {
        rootList = treeFolder
      }
    }

    if (rootList && rootList.length > 0) {
      const indexChild = rootList.findIndex((x) => x.data.id === folder.id)
      if (indexChild >= 0) {
        rootList.splice(indexChild, 1)
        if (rootList.length === 0 && parentCurrent) {
          parentCurrent.data.has_children = false
        }
      }
    }
    state.items = items
    state.treeFolder = sortNameFolder(treeFolder)
  },

  UPDATE_COUNT_ASSET_STATISTICAL(state, data) {
    if (data.type === 'create') {
      state.statistical.total += 1
      state.statistical[data.typeAsset] += 1
    } else {
      state.statistical.total -= 1
      state.statistical[data.typeAsset] -= 1
    }
  },

  SET_LOOP_CAMPAIGN_ITEMS(state, data) {
    if (data) {
      state.campaignItems = data.items
      state.campaignPagination.total = data.total_pages
      state.campaignPagination.page = data.current_page
      state.errorCampaign = false
    }
  },
  SET_LOOP_PLAYER_ITEMS(state, data) {
    if (data) {
      state.playerItems = data.items
      state.playerPagination.total = data.total_pages
      state.playerPagination.page = data.current_page
      state.errorPlayer = false
    }
  },
  SET_LOOP_PLAYER_LOADING(state, data) {
    state.loadingPlayer = data
  },
  SET_LOOP_CAMPAIGNLOADING(state, data) {
    state.loadingCampaign = data
  },
  SET_LOOP_CAMPAIGN_EMPTY(state, data) {
    state.isEmptyCampaign = data
  },
  SET_LOOP_PLAYER_EMPTY(state, data) {
    state.isEmptyPlayer = data
  }
}

// COMON
// formatter tree folder by sl vue tree
function formatterTreeFolder(preData) {
  return preData.map((item) => {
    if (item.file_type === 'folder') {
      return {
        title: item.name,
        isExpanded: false,
        data: {
          ...item,
          isGetData: false,
          source_id: item.id
        },
        children: []
      }
    }
    return {
      title: item.name,
      isLeaf: true,
      data: {
        ...item
      }
    }
  })
}

function findById(nodes, id) {
  let result = {}
  for (const child of nodes) {
    if (Object.keys(result).length) break
    const nodeTemp = recurseFind(child, id)
    result = Object.keys(nodeTemp).length ? nodeTemp : result
  }
  return result
}

function recurseFind(node, id) {
  if (node.data.file_type !== 'folder') {
    return {}
  }

  if (node.data.id === id) {
    return node
  }

  let result = {}
  for (const child of node.children) {
    if (Object.keys(result).length) break
    const nodeTemp = recurseFind(child, id)
    result = Object.keys(nodeTemp).length ? nodeTemp : result
  }
  return result
}

function formatTypeFolder(node) {
  return {
    title: node.name,
    isExpanded: false,
    data: {
      ...node,
      isGetData: false
    },
    children: []
  }
}

function formatTypeItem(node) {
  return {
    title: node.name,
    isLeaf: true,
    data: {
      file_type: node.type,
      name: node.name,
      ...node
    }
  }
}
