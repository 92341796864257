<template>
  <div class="container-fluid">
    <div class="col-6">
      <div class="sf-card">
        <div class="card-heading">
          <p>Customer</p>
        </div>
        <div class="card-body editable">
          <div class="row">
            <div class="col-12">
              <button class="button-edit-pen" @click="shownModalEdit()"></button>
              <div class="mt-3">
                <p>
                  <span class="sf-text-strong mr-2">Name</span>
                  <content-placeholders :class="'placeholder-text-detail'" v-if="loadingCustomer">
                    <content-placeholders-text :lines="1" />
                  </content-placeholders>
                  <span v-else>{{
                    displayUser.user.first_name + ' ' + displayUser.user.last_name
                  }}</span>
                </p>
              </div>

              <div class="mt-3">
                <p>
                  <span class="sf-text-strong mr-2">Email</span>
                  <content-placeholders :class="'placeholder-text-detail'" v-if="loadingCustomer">
                    <content-placeholders-text :lines="1" />
                  </content-placeholders>
                  <span v-else>{{ displayUser.user.email }}</span>
                </p>
              </div>

              <div class="mt-3">
                <p>
                  <span class="sf-text-strong mr-2">Organization</span>
                  <content-placeholders :class="'placeholder-text-detail'" v-if="loadingCustomer">
                    <content-placeholders-text :lines="1" />
                  </content-placeholders>
                  <span v-else>{{ displayUser.user.organization.name }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SfModal
      title="Edit Customer"
      :icon="'fa fa-user'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loading">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">First Name</label>
                </div>
                <input
                  v-model="currentUser.user.first_name"
                  placeholder="First Name ..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.first_name.required"
              >
                First Name required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.first_name.maxLength"
              >
                Use less than 250 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loading">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Last Name</label>
                </div>
                <input
                  v-model="currentUser.user.last_name"
                  placeholder="Last Name ..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.last_name.required"
              >
                Last Name required.
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.last_name.maxLength"
              >
                Use less than 250 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loading">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Email</label>
                </div>
                <input
                  v-on:keyup="checkEmailExist(currentUserID, currentUser.user.email)"
                  v-model="currentUser.user.email"
                  placeholder="Email ..."
                  class="sf-input-text"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.currentUser.user.email.required">
                Email Address required
              </div>
              <div class="invalid-error" v-if="submitted && !$v.currentUser.user.email.maxLength">
                Use less than 100 characters
              </div>
              <div class="invalid-error" v-if="submitted && !$v.currentUser.user.email.email">
                Must be valid Email Address
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loading">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Organization</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    @change="selectOrganization"
                    v-model="currentUser.user.organization_id"
                    :options="organizationOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.organization_id.required"
              >
                Select an Organization.
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loading">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Role</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="currentUser.user.role_id"
                    :options="roleListNoPagingOptions"
                    :settings="{ minimumResultsForSearch: -1 }"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div class="invalid-error" v-if="submitted && !$v.currentUser.user.role_id.required">
                Please select Role
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loading">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Password</label>
                </div>
                <input
                  :type="'password'"
                  v-model="currentUser.user.password"
                  placeholder="Create a password"
                  class="sf-input-text"
                  autocomplete="new-password"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.password.strongPassword"
              >
                Password must be at least 8 characters, with at least 1 capital and 1 number
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loading">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Confirm Password</label>
                </div>
                <input
                  :type="'password'"
                  v-model="currentUser.user.passowrd_confirm"
                  placeholder="Retype your Password"
                  class="sf-input-text"
                  autocomplete="new-password"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.passowrd_confirm.sameAsPassword"
              >
                Passwords must be identical
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalEdit = false">Cancel</button>
        <button class="sf-primary" @click="updateUser()">Save</button>
      </div>
    </SfModal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import SfModal from '@/components/SfModal.vue'
import Select2 from 'v-select2-component'
import AdminUserService from '@/services/admin-user.service'
import { required, maxLength, email, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'admin-user-customer',

  components: {
    SfModal,
    Select2
  },

  data() {
    return {
      msg: 'Welcome to Index!!!',
      isShownModalEdit: false,
      submitted: false,
      currentUserID: this.$route.params.id,
      displayUser: {
        user: {
          first_name: '',
          last_name: '',
          email: '',
          user_sub_type: 0,
          user_type: 'customer',
          organization_id: 0,
          organization: {
            id: 0,
            name: ''
          }
        }
      },
      currentUser: {
        user: {
          first_name: '',
          last_name: '',
          email: '',
          role_id: '',
          user_type: 'customer',
          organization_id: '',
          organization: {
            id: 0
          },
          password: '',
          passowrd_confirm: ''
        }
      },
      roleListDefault: [
        {
          id: '',
          text: 'Select Role'
        }
      ],
      loadingCustomer: false
    }
  },

  validations: {
    currentUser: {
      user: {
        first_name: {
          required,
          maxLength: maxLength(250)
        },
        last_name: {
          required,
          maxLength: maxLength(250)
        },
        email: {
          required,
          maxLength: maxLength(100),
          email
        },
        password: {
          strongPassword(password) {
            return (
              (/[A-Z]/.test(password) && // checks for A-Z
                /[0-9]/.test(password) && // checks for 0-9
                password.length >= 8 &&
                password !== '') ||
              password === ''
            )
          }
        },
        passowrd_confirm: {
          sameAsPassword: sameAs('password')
        },
        organization_id: {
          required
        },
        role_id: {
          required
        }
      }
    }
  },

  methods: {
    ...mapActions('adminorganization', {
      getRoleListByOrganizationNoPaging: 'getRoleListByOrganizationNoPaging'
    }),
    loadOrganization() {
      this.$store.dispatch('adminuser/getAllOrganizationNoPaging')
    },
    shownModalEdit() {
      this.submitted = false
      this.loadOrganization()
      this.isShownModalEdit = true
    },
    getItemByID() {
      this.loadingCustomer = true
      AdminUserService.detailUser(this.currentUserID)
        .then((response) => {
          this.loadingCustomer = false
          this.setDataUser(this.currentUser.user, response.data)
          this.setDataUser(this.displayUser.user, response.data)
          this.getRoleListByOrganizationNoPaging(this.currentUser.user.organization_id)
        })
        .catch((err) => {
          this.isShownModalEdit = false
          this.$toast.error(err.message)
        })
    },
    updateUser() {
      this.submitted = true
      if (this.$v.currentUser.$invalid) {
        return
      }
      AdminUserService.updateUser(this.currentUserID, this.currentUser)
        .then((response) => {
          this.$toast.success('Successfully updated')
          this.setDataUser(this.displayUser.user, response.data)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalEdit = false
    },
    setDataUser(user, new_user) {
      user.first_name = new_user.first_name
      user.last_name = new_user.last_name
      user.email = new_user.email
      user.role_id = new_user.role_id
      user.user_type = new_user.user_type
      user.organization_id = new_user.organization.id
      user.organization = new_user.organization
    },
    selectOrganization(id) {
      if (id) {
        this.getRoleListByOrganizationNoPaging(id)
      }
      this.currentUser.user.role_id = ''
    }
  },

  computed: {
    ...mapState('adminuser', {
      userSupTypeOptions: 'userSupTypeOptions',
      organizationOptions: 'organizationOptions',
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('adminuser', ['isEmpty']),
    ...mapGetters('adminorganization', {
      roleListNoPagingOptions: 'roleListNoPagingOptions'
    })
  },

  mounted() {
    this.getItemByID()
  }
}
</script>
<style lang="scss" scoped>
@import '../../Adminpage.scss';
</style>
