import state from './adminTemplateState'
import mutations from './adminTemplateMutations'
import getters from './adminTemplateGetters'
import actions from './adminTemplateActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
