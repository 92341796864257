export default {
  loading: false,
  error: false,
  listPlayer: [],
  isEmpty: true,
  pagination: {
    limit: 10,
    page: 0,
    total: 0
  },
  infoPlayerID: '',
  allCampaigns: [],
  listTimezone: [],
  allStates: [],
  files: [],
  loadingFiles: '',
  allPlayersWithOrientaion: [],
  listPlayerGroup: [],
  paginationGroup: {
    limit: 10,
    page: 0,
    total: 0
  },
  paginationSubOrganization: {
    limit: 10,
    page: 0,
    total: 0
  },
  dataPlayerChildren: [],
  isLoadingDataPlayerChildren: false,
  listUnregisteredPlayers: [],
  paginationUnregisteredPlayers: {
    limit: 10,
    page: 0,
    total: 0
  },
  isLoadingUnregisteredPlayers: false,
  listConnectedPlayers: [],
  paginationConnectedPlayers: {
    limit: 10,
    page: 0,
    total: 0
  },
  isLoadingConnectedPlayers: false,
  editPlayerIds: [],
  searchPlayerInput: ''
}
