import Vue from 'vue'
export default {
  SET_ITEMS(state, data) {
    state.items = data.items
    state.pagination.total = data.total_pages
    state.pagination.page = data.current_page
  },

  SET_PAGINATION(state, payload) {
    state.pagination = {
      page: payload.page || state.pagination.page,
      limit: payload.limit || state.pagination.limit,
      total: payload.total || state.pagination.total
    }
  },

  SET_ERROR(state, data) {
    state.error = data
  },

  SET_LOADING(state, data) {
    state.loading = data
  },

  SET_ITEM_ID(state, data) {
    state.itemByID = data
  },

  SET_EMPTY(state, data) {
    state.isEmpty = data
  },

  SET_ITEMS_LOOP(state, data) {
    state.loopItems = data
  },

  SET_ITEMS_MAIN_LOOPS(state, data) {
    state.itemByID.main_loops = data
  },

  PUSH_ITEM_TO_LOOP(state, data) {
    state.itemByID.main_loops.push(data)
  },

  REMOVE_LOOP(state, data) {
    state.itemByID.main_loops.splice(data, 1)
  },

  CHOOSE_LOOP(state, data) {
    if (
      state.itemByID.main_loops &&
      state.itemByID.main_loops.length === 1 &&
      data.value.id === '0'
    ) {
      state.itemByID.main_loops = []
    } else {
      state.itemByID.main_loops[data.index].loop_asset.displayname = data.value.displayname
      state.itemByID.main_loops[data.index].loop_asset.id = data.value.id
    }
  },

  CHOOSE_ADD_LOOP(state, data) {
    state.itemByID.main_loops[data.index].loop_asset_id = data.value.id
    state.itemByID.main_loops[data.index].displayname = data.value.displayname
  },
  CHOOSE_ADD_FIRST_LOOP(state, data) {
    state.itemByID.main_loops.push({
      title: null,
      id: 0,
      start_year: null,
      start_month: null,
      start_day: null,
      start_hour: null,
      start_minute: null,
      start_second: null,
      end_year: null,
      end_month: null,
      end_day: null,
      end_hour: null,
      end_minute: null,
      end_second: null,
      loop_asset_id: null,
      repeat_info: null,
      play_info: 'main',
      // add to show client
      text: ''
    })
    state.itemByID.main_loops[0].loop_asset_id = data.value.id
    state.itemByID.main_loops[0].displayname = data.value.displayname
    state.itemByID.main_loops[0].text = data.value.text
  },
  ADD_MORE_EXCEPTION(state, data) {
    state.itemByID.list_of_exeption.unshift(data)
  },

  UPDATE_EXCEPTION(state, data) {
    const items = Vue._.cloneDeep(state.itemByID.list_of_exeption)
    const foundIndexItems = items.findIndex((x) => x.id === data.id)
    if (foundIndexItems >= 0) {
      items[foundIndexItems] = data
    }
    state.itemByID.list_of_exeption = items
  },

  REMOVE_EXCEPTION(state, data) {
    state.itemByID.list_of_exeption.splice(data, 1)
  },

  CHANGE_ROTATED(state, data) {
    state.itemByID.list_of_exeption[data].isRotated =
      !state.itemByID.list_of_exeption[data].isRotated
  },

  UPDATE_TITLE(state, data) {
    state.itemByID.list_of_exeption[data.indexException].title = data.value
  },

  UPDATE_DISPLAYNAME(state, data) {
    state.itemByID.displayname = data
  },

  UPDATE_POSITION(state, data) {
    state.itemByID.list_of_exeption[data.index].position = data.futureIndex
    state.itemByID.list_of_exeption[data.futureIndex].position = data.index
  },

  UPDATE_DATA(state, data) {
    state.itemByID.list_of_exeption = data
  },

  CREATE_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.items)
    const isDuplicate = items.some((existingItem) => existingItem.id === data.data.id)
    if (!isDuplicate) {
      items.unshift(data.data)
      state.items = items
    }
  },

  DELETE_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.items)
    const foundIndexItems = items.findIndex((x) => x.id === data.data.id)
    items.splice(foundIndexItems, 1)
    state.items = items
  },

  UPDATE_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.items)
    const foundIndexItems = items.findIndex((x) => x.id === data.data.id)
    if (foundIndexItems >= 0) {
      items[foundIndexItems] = data.data
    }
    state.items = items
  },

  CREATE_LOOP_ASSET_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.loopItems)
    items.splice(1, 0, {
      id: data.data.id,
      text: data.data.name,
      orientation: data.data.orientation
    })
    state.loopItems = items
  },

  DELETE_LOOP_ASSET_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.loopItems)
    const foundIndexItems = items.findIndex((x) => x.id === data.data.id)
    items.splice(foundIndexItems, 1)
    state.loopItems = items
  },

  UPDATE_LOOP_ASSET_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.loopItems)
    const foundIndexItems = items.findIndex((x) => x.id === data.data.id)
    if (foundIndexItems >= 0) {
      items[foundIndexItems] = {
        id: data.data.id,
        text: data.data.name,
        orientation: data.data.orientation
      }
    }
    state.loopItems = items
  },

  SET_LIST_CAMPAIGN_CHILDRED(state, data) {
    state.dataCampaignChildren = data
  },

  SET_LOADING_DATA_CAMPAIGN_CHILDREN(state, data) {
    state.isLoadingDataCampaignChildren = data
  },

  SET_EDITABLE_CAMPAIGN_IDS(state, data) {
    if (
      !state.editCampaignIds.some(
        (item) => item.resourceable_id === data.resourceable_id && item.user_id === data.user_id
      )
    ) {
      state.editCampaignIds.push(data)
    }
  },
  LOAD_EDITABLE_CAMPAIGN(state, data) {
    state.editCampaignIds = data
  },
  RESET_EDITABLE_CAMPAIGN_IDS(state, data) {
    state.editCampaignIds = state.editCampaignIds.filter((item) => {
      return !(item.resourceable_id === data.resourceable_id && item.user_id === data.user_id)
    })
  }
}
