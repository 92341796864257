<template>
  <vue-draggable-resizable
    class="d-flex align-items-center justify-content-center"
    :class="{ selected: index === activeBlockIndex }"
    @activated="onActivated(index, item)"
    :active="index === activeBlockIndex"
    :lock-aspect-ratio="item.aspectRatio"
    :style="{
      zIndex: item.zIndex,
      borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`,
      backgroundColor: item.style.backgroundColor
    }"
    @dragstop="onDragStop"
    @dragging="onDragging"
    @resizestop="onResizeStop"
    @resizing="onResize"
    :grid="smartAsset.grid"
    :x="item.x"
    :y="item.y"
    :w="widthDateTimeDefault"
    :h="item.h >= 0 ? item.h : null"
  >
    <div
      class="content-editable-container content-editable w-100 h-100"
      :style="{
        padding: `${item.paddingValue.top}px ${item.paddingValue.right}px ${item.paddingValue.bottom}px ${item.paddingValue.left}px`,
        borderRadius: `${item.borderRadiusValue.topLeft}px ${item.borderRadiusValue.topRight}px ${item.borderRadiusValue.bottomRight}px ${item.borderRadiusValue.bottomLeft}px`,
        color: item.textStyle.color,
        fontFamily: item.textStyle.fontFamily,
        fontWeight: item.textStyle.fontWeight,
        lineHeight: item.textStyle.lineHeight,
        letterSpacing: item.textStyle.letterSpacing,
        textShadow: item.textStyle.textShadow,
        alignItems: item.style.alignItems,
        justifyContent: item.style.justifyContent,
        flexDirection: item.style.flexDirection ? item.style.flexDirection : 'column',
        whiteSpace: 'pre-line',
        display: item.style.display ? item.style.display : 'flex',
        overflow: 'hidden'
      }"
      contenteditable="false"
    >
      <div
        class="d-flex flex-column"
        :style="{
          alignItems: item.textStyle.textAlign
        }"
      >
        <span
          :style="{
            fontSize: `calc( ${item.textStyle.fontSize} + ${item.textStyle.fontSize} )`,
            lineHeight: item.textStyle.lineHeight,
            whiteSpace: 'nowrap'
          }"
          >{{ hourCurrentFormat }}</span
        ><span
          :style="{
            fontSize: item.textStyle.fontSize,
            lineHeight: item.textStyle.lineHeight
          }"
          >{{ dateCurrentFormat }}</span
        >
      </div>
    </div>
  </vue-draggable-resizable>
</template>
<script>
import moment from 'moment-timezone'
import get from 'lodash/get'

export default {
  data() {
    return {
      timeCurrentTimer: null,
      currentTime: null
    }
  },
  props: {
    item: {
      type: Object
    },
    index: {
      type: Number
    },
    activeBlockIndex: {
      type: [Number, Object]
    },
    smartAsset: {
      type: Object
    }
  },
  computed: {
    hourCurrentFormat() {
      const hour12 = get(this.smartAsset, `blocks[${this.index}].hour12`, 'false') === 'true'
      const timezoneString = get(this.smartAsset, `blocks[${this.index}].timezone`, '')
      const timezone = timezoneString ? timezoneString.split('|')[0] : moment.tz.guess()
      if (this.currentTime)
        return moment(this.currentTime)
          .tz(`${timezone}`)
          .format(`${hour12 ? 'hh:mm:ss A' : 'HH:mm:ss'}`)
      return moment()
        .tz(`${timezone}`)
        .format(`${hour12 ? 'hh:mm:ss A' : 'HH:mm:ss'}`)
    },
    dateCurrentFormat() {
      const timezoneString = get(this.smartAsset, `blocks[${this.index}].timezone`, '')
      const timezone = timezoneString ? timezoneString.split('|')[0] : moment.tz.guess()
      if (this.currentTime) return moment(this.currentTime).tz(`${timezone}`).format('MM/DD/YYYY')
      return moment().tz(`${timezone}`).format('MM/DD/YYYY')
    },
    widthDateTimeDefault() {
      if (!this.item.w) return 0
      return (
        parseInt(this.item.w) +
        parseInt(this.item.paddingValue.left) +
        parseInt(this.item.paddingValue.right)
      )
    }
  },
  methods: {
    onDragStop(x, y) {
      this.$emit('onDragStop', x, y)
    },
    onDragging(x, y) {
      this.$emit('onDragging', x, y)
    },
    onResizeStop(x, y, w, h) {
      this.$emit('onResizeStop', x, y, w, h)
    },
    onResize(x, y, w, h) {
      this.$emit('onResize', x, y, w, h)
    },
    onActivated(index, item) {
      this.$emit('onActivated', index, item)
    }
  },
  mounted() {
    this.timeCurrentTimer = setInterval(() => {
      this.currentTime = new Date()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timeCurrentTimer)
  }
}
</script>
<style lang="scss" scoped></style>
