import router from '@/router'
import loopState from '../store/modules/loop/loopState'

export const dashboardStep1 = {
  target: '#v-step-1',
  content:
    'Welcome to Screenfluence! We’ll give you a quick walkthrough on how everything works.<br>' +
    'If at any point you want to learn more about what you can do with Screenfluence, just check out our support site by clicking on the help button.<br>',
  before: (type) =>
    new Promise((resolve, reject) => {
      router.push({ name: 'overview' }).catch((error) => {
        if (
          error.name !== 'NavigationDuplicated' &&
          !error.message.includes('Avoided redundant navigation to current location')
        ) {
        }
      })
      resolve('overview')
    })
}

export const dashboardStep2 = {
  target: '#v-step-2',
  content:
    'This is your dashboard, it gives you an at a glance look at your digital signage network. You can see how many media assets you have in your library, the number of loops you’ve created, the different campaigns, and of course the number of players on your network.'
}

export const dashboardStep3 = {
  target: '.v-step-3',
  content:
    'Screenfluence works like a river. Everything flows from one direction to the other. Changes in your Library flow through to your Loops, then through your Campaigns, and finally end up on your player.'
}

export const library = {
  target: '#v-step-5',
  content:
    'This is your Library. This is where you upload all of your images and videos. You can also make all kinds of dynamic assets in Screenfluence that will display on your screens.<br>' +
    'You can search, sort, filter, and even make folders to help organize your library.',
  params: {
    placement: 'right'
  },
  before: (type) =>
    new Promise((resolve, reject) => {
      router.push({ name: 'library' })
      resolve('library')
    })
}

export const loops = {
  target: '#v-step-6',
  content:
    "This is your collection of Loops. It looks a lot like your library, except it only contains your loops. Loops are playlists that will continue to loop forever while on your screen. This is where you see all the loops you've created. Let's take a look at what's inside a loop!",
  params: {
    placement: 'right'
  },
  before: (type) =>
    new Promise((resolve, reject) => {
      router.push({ name: 'loop' })
      resolve('loop')
    })
}

export const loopEditor = {
  target: '#v-step-7',
  content:
    'This is the Loop Editor. You can make and change your loop inside the loop editor. On the right hand-side are the contents of your loop, and on the left-hand side, you have your asset library from before. You can drag and drop the content from your library into the loop editor.',
  before: (type) =>
    new Promise((resolve, reject) => {
      const items = loopState.items.filter((item) => item.name === 'Test Loop')
      items[0]
        ? router.push(`/loop_assets/${items[0].id}/edit`)
        : router.push('/loop_assets/id/edit')
      resolve('loop')
    })
}

export const campaigns = {
  target: '#v-step-8',
  content:
    'Sometimes, you may want to do more than just play the same content on repeat. You may want to control what plays on your screen by the date, or time of day, or using other triggers. That’s when campaigns can be useful. Each campaign has a default loop which plays. You can then add rules which will trigger other loops to play.',
  params: {
    placement: 'right'
  },
  before: (type) =>
    new Promise((resolve, reject) => {
      router.push({ name: 'campaign' })
      resolve('campaign')
    })
}

export const player = {
  target: '#v-step-9',
  content:
    'Finally, this is where you can see all your players. As soon as you assign a campaign to a player, your player will update and your content will be showing on your screen. Because of how Screenfluence flows, making updates is even easier the second time around. Any changes you make at any point in the process will automatically flow to your screen!',
  params: {
    placement: 'right'
  },
  before: (type) =>
    new Promise((resolve, reject) => {
      router.push({ name: 'players-page' })
      resolve('player')
    })
}
