import OverviewService from '@/services/overview.service'

export default {
  getOverview({ commit }) {
    commit('SET_LOADING', true)
    return OverviewService.getOverview()
      .then((overview) => {
        commit('SET_OVERVIEW_DATA', overview.data)
      })
      .catch((error) =>
        commit('toast/NEW', { type: 'error', message: error.message }, { root: true })
      )
      .finally(() => commit('SET_LOADING', false))
  }
}
