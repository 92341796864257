<template>
  <SfModal
    :title="$t('modal.title.add-website')"
    :width="768"
    :height="0"
    closeOnOverlay
    :show.sync="dataShow"
    @update:show="updateShow"
  >
    <div class="sf-modal-body mb-3">
      <div class="container">
        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('title') }}</label>
              </div>
              <input
                type="text"
                name="smart_asset[displayname]"
                id="smart_asset_displayname"
                class="sf-input-text"
                :placeholder="$t('placeholder.title')"
                v-model.trim="dataInput.title"
                :class="{ 'input-error-form': submitted && $v.dataInput.title.$invalid }"
              />
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.title.required"
            >
              {{ $t('title-required') }}
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.title.minLength"
            >
              {{ $t('title-minLength-2') }}
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">URL</label>
              </div>
              <input
                type="text"
                name="property[url]"
                id="property_url"
                class="sf-input-text"
                :placeholder="$t('placeholder.url-of-website')"
                v-model="dataInput.url"
                :class="{ 'input-error-form': submitted && $v.dataInput.url.$invalid }"
              />
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.url.urlValid"
            >
              {{ $t('url-invalid-message') }}
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.url.required"
            >
              {{ $t('url-invalid-null-message') }}
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.url.maxLength"
            >
              {{ $t('url-invalid-long-message') }}
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('position') }} ({{ $t('pixels') }})</label>
              </div>
              <input
                type="number"
                name="property[position]"
                id="property_position"
                value="0"
                class="sf-input-text"
                :placeholder="$t('placeholder.position')"
                v-model="dataInput.position"
              />
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('run-script') }}</label>
              </div>
              <div class="w-100">
                <toggle-button
                  :color="'#76c322'"
                  :value="dataInput.run_script"
                  :sync="true"
                  @change="onChangeToggleScript"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3" v-if="dataInput.run_script">
          <div class="col-11">
            <div class="sf-form-group">
              <div class="sf-label-group label-top">
                <label class="primary-label">{{ $t('custom-script') }}</label>
              </div>
              <div class="codemirror-wrap">
                <codemirror
                  ref="myCm"
                  :value="dataInput.custom_script"
                  :options="cmOption"
                  @input="updateScript"
                  :placeholder="$t('placeholder.custom-script')"
                >
                </codemirror>
              </div>
            </div>
            <div class="invalid-error-text text-right" v-if="isSynTaxError">{{ lineError }}</div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('tags') }}</label>
              </div>
              <vue-tags-input
                class="sf-tag"
                v-model="tag"
                :tags="dataInput.tags"
                @tags-changed="(newTags) => (dataInput.tags = newTags)"
                :placeholder="$t('placeholder.add-tags')"
              />
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group label-top">
                <label class="primary-label">{{ $t('orientation') }}</label>
              </div>
              <div class="row orientation-type">
                <div class="col-6">
                  <label class="sf-radio"
                    >{{ $t('landscape') }} ({{ $t('default') }})
                    <input
                      checked
                      id="landscape-radio"
                      name="smart_asset[orientation]"
                      type="radio"
                      value="landscape"
                      class="sf-radio"
                      v-model="dataInput.orientation"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-6">
                  <label class="sf-radio"
                    >{{ $t('portrait') }}
                    <input
                      class="sf-radio"
                      id="portrait-radio"
                      name="smart_asset[orientation]"
                      type="radio"
                      value="portrait"
                      v-model="dataInput.orientation"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-12 mt-3">
                  <div class="row orientation-img-wrap">
                    <div
                      for="landscape-radio"
                      :class="{ active: dataInput.orientation === 'landscape' }"
                      class="col-6 landscape orientation-img"
                    >
                      <img src="~@/assets/images/landscape-review.svg" />
                    </div>
                    <div
                      for="portrait-radio"
                      :class="{ active: dataInput.orientation === 'portrait' }"
                      class="col-6 orientation-img portrait"
                    >
                      <img src="~@/assets/images/portrait-review.svg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sf-modal-footer">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="sf-modal-action">
              <button class="sf-secondary" type="button" @click="updateShow">
                {{ $t('cancel') }}
              </button>
              <button type="button" class="sf-primary" @click="onClickSave">
                {{ $t('save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SfModal>
</template>

<script>
import SfModal from '@/components/SfModal'
import VueTagsInput from '@johmun/vue-tags-input'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import { urlValid } from '@/helper/customVuelidate'

export default {
  components: {
    SfModal,
    VueTagsInput
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataShow: this.show,
      tag: '',
      dataInput: {
        title: '',
        position: 0,
        tags: [],
        orientation: 'landscape',
        url: '',
        run_script: false,
        is_cache: true,
        custom_script: ''
      },
      submitted: false,
      cmOption: {
        tabSize: 2,
        mode: 'text/javascript',
        theme: 'default',
        lineNumbers: true,
        line: true
      },
      isSynTaxError: false,
      lineError: ''
    }
  },
  validations: {
    dataInput: {
      title: {
        required,
        minLength: minLength(2)
      },
      url: {
        required,
        urlValid,
        maxLength: maxLength(250)
      }
    }
  },
  watch: {
    show(value) {
      this.dataShow = value
    }
  },
  methods: {
    updateShow() {
      this.$emit('update:show', false)
    },
    onClickSave() {
      this.submitted = true
      if (this.$v.dataInput.$invalid || this.isSynTaxError) {
        return
      }
      this.$emit('modal:save', { ...this.dataInput })
    },
    resetForm() {
      this.dataInput = {
        title: '',
        position: 0,
        tags: [],
        orientation: 'landscape',
        url: '',
        run_script: false,
        is_cache: true,
        custom_script: ''
      }
    },
    onChangeToggleScript(value) {
      this.dataInput.run_script = value.value
    },
    onChangeToggleCache(value) {
      this.dataInput.is_cache = value.value
    },
    updateScript(value) {
      this.dataInput.custom_script = value
      this.vadidationScript()
    },
    vadidationScript() {
      const self = this
      const custom_script = this.dataInput.custom_script.split(';')
      custom_script.some((x) => {
        if (x.trim()) {
          if (!isNaN(x)) {
            self.lineError = x
            self.isSynTaxError = true
          } else {
            const functionName = 'this.' + x
            const script = this.excuteText(functionName)
            if (script && typeof script === 'string') {
              self.isSynTaxError = false
            } else {
              self.lineError = x
              self.isSynTaxError = true
            }
          }
        } else {
          self.lineError = ''
          self.isSynTaxError = false
        }
        return self.isSynTaxError
      })
    },
    excuteText(text) {
      try {
        /* eslint-disable */
        return eval(text)
      } catch (_) {}
    },
    type(selector, value) {
      if (selector && value) {
        return this.get_element(selector) + '.value = ' + value
      } else {
        return ''
      }
    },
    click(selector) {
      if (selector) {
        return this.get_element(selector) + '.click()'
      } else {
        return ''
      }
    },
    refresh() {
      return 'location.reload()'
    },
    wait(seconds) {
      if (seconds) {
        if (typeof seconds === 'number') {
          return 'wait()'
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    get_element(selector) {
      let element = ''
      switch (selector) {
        case /#/.test(selector):
          element = 'document.getElementById(' + selector + ')'
          break
        case /\./.test(selector):
          element = 'document.getElementsByClassName(' + selector + ')[0]'
          break
        case /name=/.test(selector):
          selector = selector.replace('name=', '')
          element = 'document.getElementsByClassName(' + selector + ')[0]'
          break
        default:
          element = 'getElementByXpath(' + selector + ')'
          break
      }
      return element
    }
  },
  computed: {},
  created() {
    this.$bus.$on('reset-submitted', (value) => {
      this.submitted = value
      this.resetForm()
    })
  },
  beforeDestroy() {
    this.dataInput = {
      title: '',
      position: 0,
      tags: [],
      orientation: 'landscape',
      url: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style-modal-smart-asset';
</style>
<style lang="scss">
.form-control {
  height: auto !important;
  .vue-tags-input {
    margin: -0.375rem -0.75rem;
    border-radius: 0.25rem;
    * {
      border: transparent;
    }
    border: transparent;
    .ti-tag {
      background-color: #e0edf5;
      border-radius: 3px;
      color: #2f5d92;
      float: left;
      font-weight: 600;
      height: 28px;
      line-height: 28px;
      margin: 0 8px 6px 0;
      padding: 0 1px 0 8px;
      white-space: nowrap;
    }
  }
}
.custom-textarea {
  border: 0 !important;
  max-height: 150px;
  width: 100%;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}

.have-toggle {
  display: flex;
  justify-content: space-between;
}
</style>
