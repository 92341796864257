<template>
  <div>
    <template>
      <SfModal
        :title="$t('modal.title.edit-canva')"
        :width="768"
        :height="0"
        closeOnOverlay
        :show.sync="dataShow"
        @update:show="updateShow"
      >
        <div class="sf-modal-body mb-3">
          <div class="container">
            <div class="row mt-3">
              <div class="col">
                <FolderPath v-if="data.folder_path" :paths="data.folder_path" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <ul class="sf-tabs">
                  <li>
                    <a
                      @click="changeTab('overview')"
                      :class="{ active: activeTab === 'overview' }"
                      >{{ $t('overview') }}</a
                    >
                  </li>
                  <li>
                    <a @click="changeTab('linkto')" :class="{ active: activeTab === 'linkto' }">{{
                      $t('linked-to')
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div v-if="activeTab === 'overview'" class="container">
            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">{{ $t('title') }}</label>
                  </div>
                  <input
                    type="text"
                    v-model="dataInput.displayname"
                    class="sf-input-text"
                    :placeholder="$t('placeholder.title')"
                    :class="{ 'input-error-form': submitted && $v.dataInput.displayname.$invalid }"
                  />
                </div>
                <div
                  class="invalid-error-text text-right"
                  v-if="submitted && !$v.dataInput.displayname.required"
                >
                  Title required
                </div>
                <div
                  class="invalid-error-text text-right"
                  v-if="submitted && !$v.dataInput.displayname.minLength"
                >
                  {{ $t('title-minLength-2') }}
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">{{ $t('url') }}</label>
                  </div>
                  <input
                    type="text"
                    name="property[url]"
                    id="property_url"
                    class="sf-input-text"
                    :placeholder="$t('placeholder.url-of-canva')"
                    v-model="dataInput.properties.url"
                    :class="{
                      'input-error-form': submitted && $v.dataInput.properties.url.$invalid
                    }"
                  />
                </div>
                <div
                  class="invalid-error-text text-right"
                  v-if="submitted && !$v.dataInput.properties.url.urlValid"
                >
                  {{ $t('url-invalid-message') }}
                </div>
                <div
                  class="invalid-error-text text-right"
                  v-if="submitted && !$v.dataInput.properties.url.required"
                >
                  {{ $t('url-invalid-null-message') }}
                </div>
              </div>
            </div>

            <!-- <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                    <div class="sf-label-group">
                      <label class="primary-label">{{$t('position')}} ({{$t('pixels')}})</label>
                    </div>
                    <input
                      type="number"
                      name="property[position]"
                      id="property_position"
                      value="0"
                      class="sf-input-text"
                      placeholder="Position"
                      v-model="dataInput.properties.position"
                    />
                </div>
              </div>
            </div> -->
            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">{{ $t('tags') }}</label>
                  </div>
                  <vue-tags-input
                    class="sf-tag"
                    v-model="tag"
                    :tags="dataInput.tags"
                    @tags-changed="(newTags) => (dataInput.tags = newTags)"
                    :placeholder="$t('placeholder.add-tags')"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group label-top">
                    <label class="primary-label">{{ $t('orientation') }}</label>
                  </div>
                  <div class="row orientation-type">
                    <div class="col-6">
                      <label class="sf-radio"
                        >{{ $t('landscape') }} ({{ $t('default') }})
                        <input
                          id="landscape-radio"
                          type="radio"
                          value="landscape"
                          v-model="dataInput.orientation"
                          class="sf-radio"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="col-6">
                      <label class="sf-radio"
                        >{{ $t('portrait') }}
                        <input
                          id="portrait-radio"
                          type="radio"
                          value="portrait"
                          v-model="dataInput.orientation"
                          class="sf-radio"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="col-12 mt-3">
                      <div class="row orientation-img-wrap">
                        <div
                          for="landscape-radio"
                          :class="{ active: dataInput.orientation === 'landscape' }"
                          class="col-6 landscape orientation-img"
                        >
                          <img src="~@/assets/images/landscape-review.svg" />
                        </div>
                        <div
                          for="portrait-radio"
                          :class="{ active: dataInput.orientation === 'portrait' }"
                          class="col-6 orientation-img portrait"
                        >
                          <img src="~@/assets/images/portrait-review.svg" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="activeTab === 'linkto'" class="container">
            <div class="row">
              <div class="col-6 mt-3">
                <div class="row">
                  <div class="col-3">{{ $t('show') }}</div>
                  <div class="col-9">
                    <label class="sf-radio mb-1">
                      {{ $t('loop') }}
                      <input type="radio" value="loop" v-model="showBy" />
                      <span class="checkmark"></span>
                    </label>
                    <label class="sf-radio">
                      {{ $t('campaign') }}
                      <input type="radio" value="campaign" v-model="showBy" />
                      <span class="checkmark"></span>
                    </label>
                    <label class="sf-radio">
                      {{ $t('player') }}
                      <input type="radio" value="player" v-model="showBy" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-6 mt-3">
                <LinkedToInputSearch
                  :showBy="showBy"
                  @searchChange="searchChange"
                  @onSearchInputChange="onSearchInputChange"
                />
              </div>
            </div>
            <div class="row mt-3">
              <div v-if="showBy === 'loop'" class="col-12 sf-table">
                <b-table
                  show-empty
                  striped
                  hover
                  :items="loopItems"
                  :fields="fields"
                  head-variant="none"
                  table-variant="none"
                  thead-class="custom-b-table"
                  :busy="loadingLoop"
                >
                  <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t('loading') }}...</strong>
                    </div>
                  </template>
                  <template v-slot:empty="isLoopEmpty">
                    <p class="d-flex justify-content-center">
                      {{ $t('no-data-available-in-table') }}
                    </p>
                  </template>
                  <template v-slot:head(name)="field">
                    <span class="custome-arrows">{{ $t('name') }}</span>
                  </template>
                  <template v-slot:cell(name)="field">
                    <p>
                      <router-link :to="`/loop_assets/${field.item.id}/edit`">{{
                        field.item.name
                      }}</router-link>
                    </p>
                  </template>
                </b-table>
                <div class="mt-3" v-if="!isLoopEmpty">
                  <b-pagination
                    class="sf-b-pagination"
                    :total-rows="loopPagination.limit * loopPagination.total"
                    :per-page="loopPagination.limit"
                    @change="changeLoopPage"
                    :limit="6"
                    v-model="currentLoopPage"
                  />
                </div>
              </div>

              <div v-if="showBy === 'campaign'" class="col-12 sf-table">
                <b-table
                  show-empty
                  striped
                  hover
                  :items="campaignItems"
                  :fields="fields"
                  head-variant="none"
                  table-variant="none"
                  thead-class="custom-b-table"
                  :busy="loadingCampaign"
                >
                  <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t('loading') }}...</strong>
                    </div>
                  </template>
                  <template v-slot:empty="isCampaignEmpty">
                    <p class="d-flex justify-content-center">
                      {{ $t('no-data-available-in-table') }}
                    </p>
                  </template>
                  <template v-slot:head(name)="field">
                    <span class="custome-arrows">{{ field.label }}</span>
                  </template>
                  <template v-slot:cell(name)="field">
                    <p>
                      <router-link :to="`/campaigns/${field.item.id}/edit`">{{
                        field.item.name
                      }}</router-link>
                    </p>
                  </template>
                </b-table>
                <div class="mt-3" v-if="!isCampaignEmpty">
                  <b-pagination
                    class="sf-b-pagination"
                    :total-rows="campaignPagination.limit * campaignPagination.total"
                    :per-page="campaignPagination.limit"
                    @change="changeCampaignPage"
                    :limit="6"
                    v-model="currentCampaignPage"
                  />
                </div>
              </div>

              <div v-if="showBy === 'player'" class="col-12 sf-table">
                <b-table
                  show-empty
                  striped
                  hover
                  :items="playerItems"
                  :fields="fields"
                  head-variant="none"
                  table-variant="none"
                  thead-class="custom-b-table"
                  :busy="loadingPlayer"
                >
                  <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t('loading') }}...</strong>
                    </div>
                  </template>
                  <template v-slot:empty="isPlayerEmpty">
                    <p class="d-flex justify-content-center">
                      {{ $t('no-data-available-in-table') }}
                    </p>
                  </template>
                  <template v-slot:head(name)="field">
                    <span class="custome-arrows">{{ $t('name') }}</span>
                  </template>
                  <template v-slot:cell(name)="field">
                    <p>
                      <router-link
                        tag="a"
                        :to="`#`"
                        @click.native="onEditPlayerModalOpen(field.item.id)"
                        >{{ field.item.name }}</router-link
                      >
                    </p>
                  </template>
                </b-table>
                <div class="mt-3" v-if="!isPlayerEmpty">
                  <b-pagination
                    class="sf-b-pagination"
                    :total-rows="playerPagination.limit * playerPagination.total"
                    :per-page="playerPagination.limit"
                    @change="changePlayerPage"
                    :limit="6"
                    v-model="currentPlayerPage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sf-modal-footer">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button class="sf-secondary" type="button" @click="updateShow">
                    {{ $t('cancel') }}
                  </button>
                  <button type="button" class="sf-primary" @click="onClickSave">
                    {{ $t('save') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SfModal>
    </template>
    <template>
      <EditPlayerModal
        v-if="isShownModalEdit"
        :player_id="editPlayerId"
        :onClose="onEditModalClose"
      />
    </template>
  </div>
</template>

<script>
import FolderPath from './FolderPath.vue'
import SfModal from '@/components/SfModal'
import VueTagsInput from '@johmun/vue-tags-input'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import { mapState, mapGetters, mapActions } from 'vuex'
import { urlValid } from '@/helper/customVuelidate'
import { LINKED_TYPE } from '../../constant'
import EditPlayerModal from '@/components/player/EditPlayerModal.vue'
import LinkedToInputSearch from '@/components/library/LinkedToInputSearch.vue'

export default {
  components: {
    SfModal,
    VueTagsInput,
    FolderPath,
    EditPlayerModal,
    LinkedToInputSearch
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dataShow: this.show,
      tag: '',
      activeTab: 'overview',
      dataDefault: {
        id: 1,
        displayname: '#noname',
        smart_asset_type: 'canva',
        orientation: 'portrait',
        created_at: '1900-01-01T00:00:01.000Z',
        updated_at: '1900-01-01T00:00:01.000Z',
        size: null,
        duration: null,
        origin_link: null,
        thumb_link: null,
        properties: {
          url: '#url',
          position: '0',
          run_script: false,
          is_cache: true,
          custom_script: ''
        }
      },
      dataInput: null,
      submitted: false,
      cmOption: {
        tabSize: 2,
        mode: 'text/javascript',
        theme: 'default',
        lineNumbers: true,
        line: true
      },
      isSynTaxError: false,
      lineError: '',
      showBy: 'loop',
      searchValue: '',
      currentLoopPage: 1,
      currentCampaignPage: 1,
      currentPlayerPage: 1,
      isShownModalEdit: false,
      editPlayerId: null,
      fields: [
        {
          key: 'name',
          colType: 'name',
          label: 'Name',
          sortable: true
        }
      ]
    }
  },
  validations: {
    dataInput: {
      displayname: {
        required,
        minLength: minLength(2)
      },
      properties: {
        url: {
          required,
          urlValid,
          maxLength: maxLength(250)
        }
      }
    }
  },
  watch: {
    show(value) {
      this.dataShow = value
      this.dataInput = { ...this.dataInput, ...this.data }
      this.dataInput.tags = this.dataInput.tags.map((item) => ({ text: item.name, ...item }))
      this.submitted = false
      this.vadidationScript()
      this.getLoopsByAssetID({ assetID: this.dataInput.id, page: 1, limit: 5, search: '' })
      this.getCampaignsByAssetID({ assetID: this.dataInput.id, page: 1, limit: 5, search: '' })
      this.getPlayerByAssetID({ assetID: this.dataInput.id, page: 1, limit: 5, search: '' })
    }
  },
  methods: {
    ...mapActions('library', {
      getLoopsByAssetID: 'getLoopsByAssetID',
      getCampaignsByAssetID: 'getCampaignsByAssetID',
      getPlayerByAssetID: 'getPlayerByAssetID'
    }),
    changeLoopPage(page) {
      this.currentLoopPage = page
      this.getLoopsByAssetID({
        assetID: this.dataInput.id,
        page: this.currentLoopPage,
        limit: this.loopPagination.limit,
        search: this.searchValue
      })
    },
    changeCampaignPage(page) {
      this.currentCampaignPage = page
      this.getCampaignsByAssetID({
        assetID: this.dataInput.id,
        page: this.currentCampaignPage,
        limit: this.campaignPagination.limit,
        search: this.searchValue
      })
    },
    changePlayerPage(page) {
      this.currentPlayerPage = page
      this.getPlayerByAssetID({
        assetID: this.dataInput.id,
        page: this.currentPlayerPage,
        limit: this.playerPagination.limit,
        search: this.searchValue
      })
    },
    onSearchInputChange() {
      if (this.showBy === LINKED_TYPE.LOOP) {
        this.getLoopsByAssetID({ assetID: this.dataInput.id, page: 1, limit: 5, search: '' })
      } else if (this.showBy === LINKED_TYPE.CAMPAIGN) {
        this.getCampaignsByAssetID({ assetID: this.dataInput.id, page: 1, limit: 5, search: '' })
      } else if (this.showBy === LINKED_TYPE.PLAYER) {
        this.getPlayerByAssetID({ assetID: this.dataInput.id, page: 1, limit: 5, search: '' })
      }
    },
    onEditPlayerModalOpen(player_id) {
      this.editPlayerId = player_id
      this.isShownModalEdit = true
      this.dataShow = false
      this.updateShow()
    },
    onEditModalClose() {
      this.editPlayerId = null
      this.isShownModalEdit = false
    },
    searchChange(value) {
      this.searchValue = value
      switch (this.showBy) {
        case 'loop':
          this.searchLoopChange()
          break
        case 'campaign':
          this.searchCampaignChange()
          break
        case 'player':
          this.searchPlayerChange()
          break

        default:
          break
      }
    },
    searchLoopChange() {
      this.getLoopsByAssetID({
        assetID: this.dataInput.id,
        page: 1,
        limit: this.loopPagination.limit,
        search: this.searchValue
      })
    },
    searchCampaignChange() {
      this.getCampaignsByAssetID({
        assetID: this.dataInput.id,
        page: 1,
        limit: this.campaignPagination.limit,
        search: this.searchValue
      })
    },
    searchPlayerChange() {
      this.getPlayerByAssetID({
        assetID: this.dataInput.id,
        page: 1,
        limit: this.playerPagination.limit,
        search: this.searchValue
      })
    },
    updateShow() {
      this.showBy = 'loop'
      this.$emit('update:show', false)
    },
    changeTab(val) {
      this.activeTab = val
    },
    onClickSave() {
      this.submitted = true
      if (this.$v.dataInput.$invalid || this.isSynTaxError) {
        return
      }
      this.$emit('modal:save', { ...this.dataInput })
    },
    onChangeToggleScript(value) {
      this.dataInput.properties.run_script = value.value
    },
    onChangeToggleCache(value) {
      this.dataInput.properties.is_cache = value.value
    },
    updateScript(value) {
      this.dataInput.properties.custom_script = value
      this.vadidationScript()
    },
    vadidationScript() {
      const self = this
      if (typeof this.dataInput.properties.custom_script !== 'string') {
        return
      }
      const custom_script = this.dataInput.properties.custom_script.split(';')
      custom_script.some((x) => {
        if (x.trim()) {
          if (!isNaN(x)) {
            self.lineError = x
            self.isSynTaxError = true
          } else {
            const functionName = 'this.' + x
            const a = this.excuteText(functionName)
            if (a && typeof a === 'string') {
              self.isSynTaxError = false
            } else {
              self.lineError = x
              self.isSynTaxError = true
            }
          }
        } else {
          self.lineError = ''
          self.isSynTaxError = false
        }
        return self.isSynTaxError
      })
    },
    excuteText(text) {
      try {
        /* eslint-disable */
        return eval(text)
      } catch (_) {}
    },
    type(selector, value) {
      if (selector && value) {
        return this.get_element(selector) + '.value = ' + value
      } else {
        return ''
      }
    },
    click(selector) {
      if (selector) {
        return this.get_element(selector) + '.click()'
      } else {
        return ''
      }
    },
    wait(seconds) {
      if (seconds) {
        if (typeof seconds === 'number') {
          return 'wait()'
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    refresh() {
      return 'location.reload()'
    },
    get_element(selector) {
      let element = ''
      switch (selector) {
        case /#/.test(selector):
          element = 'document.getElementById(' + selector + ')'
          break
        case /\./.test(selector):
          element = 'document.getElementsByClassName(' + selector + ')[0]'
          break
        case /name=/.test(selector):
          selector = selector.replace('name=', '')
          element = 'document.getElementsByClassName(' + selector + ')[0]'
          break
        default:
          element = 'getElementByXpath(' + selector + ')'
          break
      }
      return element
    }
  },
  computed: {
    ...mapState('library', {
      loadingLoop: 'loadingLoop',
      loadingCampaign: 'loadingCampaign',
      loadingPlayer: 'loadingPlayer'
    }),
    ...mapGetters('library', {
      isLoopEmpty: 'isLoopEmpty',
      isCampaignEmpty: 'isCampaignEmpty',
      isPlayerEmpty: 'isPlayerEmpty',
      loopItems: 'loopItems',
      campaignItems: 'campaignItems',
      playerItems: 'playerItems',
      loopPagination: 'loopPagination',
      campaignPagination: 'campaignPagination',
      playerPagination: 'playerPagination'
    })
  },
  created() {
    this.dataInput = this.dataDefault
  }
}
</script>

<style lang="scss" scoped>
@import './style-modal-smart-asset';
</style>
<style lang="scss">
.form-control {
  height: auto !important;
  .vue-tags-input {
    margin: -0.375rem -0.75rem;
    border-radius: 0.25rem;
    * {
      border: transparent;
    }
    border: transparent;
    .ti-tag {
      background-color: #e0edf5;
      border-radius: 3px;
      color: #2f5d92;
      float: left;
      font-weight: 600;
      height: 28px;
      line-height: 28px;
      margin: 0 8px 6px 0;
      padding: 0 1px 0 8px;
      white-space: nowrap;
    }
  }
}
</style>
