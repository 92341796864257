import state from './adminHardwareState'
import mutations from './adminHardwareMutations'
import getters from './adminHardwareGetters'
import actions from './adminHardwareActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
