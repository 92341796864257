import BaseService from './base.service'

class SmartAssetsTemplateService extends BaseService {
  get entity() {
    return 'smart_asset_templates'
  }

  getSmartAssetTemplate(orientation) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}?orientation=${orientation}`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getAllTemplates(type, orientation, name) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}?type=${type}&orientation=${orientation}&name=${name || ''}`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  deleteAssetTemplate(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .delete(`${this.entity}/${id}`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getAllSmartAssetTemplate(payload) {
    const { orientation, categoryId, search } = payload
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(
          `${this.entity}/all_smart_asset_templates?orientation=${orientation}&category_id=${
            categoryId || ''
          }&name=${search || ''}`
        )
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  editTemplateName({ id, name }) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .patch(`${this.entity}/${id}`, {
          smart_asset_template: {
            name
          }
        })
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }
}

export default new SmartAssetsTemplateService()
