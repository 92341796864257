<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-6 overview">
        <div class="sf-card">
          <div class="card-heading">
            <p class="title-section">
              {{ $t('overview') }}
            </p>
          </div>
          <div class="card-body">
            <!-- Organizations -->
            <div class="row">
              <div class="col-sm-4 custom-sm-4">
                <span class="fa fa-building"></span>
                {{ $t('organizations') }}:
              </div>
              <div v-if="loading" class="col-sm-8 custom-sm-8">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="col-sm-8 custom-sm-8">{{ overviewData.organization }}</div>
            </div>

            <!-- Players -->
            <div class="row">
              <div class="col-sm-4 custom-sm-4">
                <span class="fa fa-tv"></span>
                {{ $t('players') }}:
              </div>
              <div v-if="loading" class="col-sm-8 custom-sm-8">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="col-sm-8 custom-sm-8">{{ overviewData.player }}</div>
            </div>

            <!-- Users -->
            <div class="row">
              <div class="col-sm-4 custom-sm-4">
                <span class="fa fa-user"></span>
                {{ $t('users') }}:
              </div>
              <div v-if="loading" class="col-sm-8 custom-sm-8">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="col-sm-8 custom-sm-8">{{ overviewData.user }}</div>
            </div>

            <!-- Contents -->
            <div class="row">
              <div class="col-sm-4 custom-sm-4">
                <span class="fa fa-chevron-circle-right"></span>
                {{ $t('contents') }}:
              </div>
              <div v-if="loading" class="col-sm-8 custom-sm-8">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="col-sm-8 custom-sm-8">
                {{ bytesToSize(overviewData.content || 0) }}
              </div>
            </div>

            <!-- Loops -->
            <div class="row">
              <div class="col-sm-4 custom-sm-4">
                <span class="fa fa-sync-alt"></span>
                {{ $t('loops') }}:
              </div>
              <div v-if="loading" class="col-sm-8 custom-sm-8">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="col-sm-8 custom-sm-8">{{ overviewData.loop_asset }}</div>
            </div>

            <!-- Campaigns -->
            <div class="row">
              <div class="col-sm-4 custom-sm-4">
                <span class="fa fa-calendar"></span>
                {{ $t('campaigns') }}:
              </div>
              <div v-if="loading" class="col-sm-8 custom-sm-8">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="col-sm-8 custom-sm-8">{{ overviewData.campaign }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { bytesToSize } from '@/helper/mathHelper'

export default {
  name: 'admin-overview',
  components: {},

  computed: {
    ...mapState('adminoverview', {
      overviewData: 'overviewData',
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('adminoverview', ['isEmpty'])
  },

  data() {
    return {}
  },

  methods: {
    bytesToSize,
    fetchData(activePage) {
      this.$store.dispatch('adminoverview/getOverview', { page: activePage })
    }
  },

  mounted() {
    this.fetchData(1, 10)
  }
}
</script>

<style lang="scss" scoped>
.overview {
  .card-header {
    background: #fff;
    justify-content: space-between;
    align-items: center;
    color: #267eb0;
    font-weight: 600;
    font-size: 16px;
  }
  .row {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 900px) {
  .overview {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .custom-sm-4 {
    width: 40% !important;
    margin-bottom: 10px !important;
  }
  .custom-sm-8 {
    width: 60% !important;
    margin-bottom: 10px !important;
  }
}
</style>
