<template>
  <div class="row mt-3">
    <div class="col-12">
      <div class="card-header top-bar">
        <div class="header-left">
          <div class="admin-search-box">
            <SfInputSearch
              v-model="searchValue"
              :placeholder="$t('search')"
              v-debounce:500ms="searchInputChange"
              class="sf-input-search"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- btable all sub org -->
    <div class="col-12 sf-table">
      <b-table
        :items="listSubOrg"
        responsive="true"
        :busy="loading"
        show-empty
        striped
        hover
        :fields="fields"
        head-variant="none"
        table-variant="none"
        thead-class="custom-b-table"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>{{ $t('loading') }}...</strong>
          </div>
        </template>

        <template v-slot:head(status)="field">
          <span>{{ field.label }}</span>
        </template>
        <template v-slot:cell(status)="field">
          <div
            class="status"
            :class="field.item.status ? 'status-active' : 'status-inactive'"
          ></div>
        </template>

        <template v-slot:head(sub_org_name)="field">
          <span>{{ $t('organization-name') }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(sub_org_name)="field">
          <p class="t-name">
            {{ field.item.name }}
          </p>
        </template>

        <template v-slot:head(contact_name)="field">
          <span>{{ $t('contact-name') }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(contact_name)="field">
          <p>{{ field.item.fname }} {{ field.item.lname }}</p>
        </template>

        <template v-slot:head(contact_email)="field">
          <span>{{ $t('contact-email') }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(contact_email)="field">
          <p>{{ field.item.email }}</p>
        </template>

        <template v-slot:head(phone)="field">
          <span>{{ $t('phone') }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(phone)="field">
          <p>{{ field.item.phone }}</p>
        </template>
        <template v-slot:cell(action)="field">
          <button class="sf-primary" @click="shownModalAddUser(field.item.id)">Add New</button>
        </template>
      </b-table>
      <div v-if="!isEmpty" class="py-3">
        <b-pagination
          class="sf-b-pagination"
          :total-rows="10 * totalPages"
          :per-page="10"
          @change="changePage"
          :limit="6"
          v-model="currentPage"
        />
      </div>
    </div>
    <!-- Modal Add new User -->
    <SfModal
      title="Add New User"
      :icon="'fas fa-plus-circle'"
      :width="768"
      closeOnOverlay
      :show.sync="isShowAddUserModal"
      @onClose="handleCancelNewUser"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">First Name</label>
                </div>
                <input
                  v-model="newUser.user.first_name"
                  placeholder="First Name..."
                  class="sf-input-text"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.first_name.required">
                First Name required
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.first_name.maxLength">
                Use less than 250 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Last Name</label>
                </div>
                <input
                  v-model="newUser.user.last_name"
                  placeholder="Last Name..."
                  class="sf-input-text"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.last_name.required">
                Last Name required
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.last_name.maxLength">
                Use less than 250 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Email</label>
                </div>
                <input
                  v-on:keyup="checkEmailExist('', newUser.user.email)"
                  v-model="newUser.user.email"
                  placeholder="Email..."
                  class="sf-input-text"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.email.required">
                Email Address required
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.email.maxLength">
                Use less than 100 characters
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.email.email">
                Must be valid Email Address
              </div>
              <div class="invalid-error" v-if="!emailCanUsed">Email is currently used</div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Role</label>
                </div>
                <div
                  class="sf-select-group"
                  :class="{ 'input-error': submitted && $v.newUser.user.role_id.$invalid }"
                >
                  <Select2
                    class="sf-select2"
                    v-model="newUser.user.role_id"
                    :options="roleListNoPagingOptions"
                    :settings="{ minimumResultsForSearch: -1 }"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newUser.user.role_id.required">
                Select a Role
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Password</label>
                </div>
                <input
                  v-model="newUser.user.password"
                  :type="'password'"
                  placeholder="Create a password"
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newUser.user.password.strongPassword"
              >
                Password must be at least 8 characters, with at least 1 capital and 1 number
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Confirm Password</label>
                </div>
                <input
                  v-model="newUser.user.password_confirm"
                  :type="'password'"
                  placeholder="Retype your Password"
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newUser.user.password_confirm.sameAsPassword"
              >
                Passwords must be identical
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="handleCancelNewUser">Cancel</button>
        <button class="sf-primary" @click="addNewUser">Save</button>
      </div>
    </SfModal>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import SfInputSearch from '@/components/SfInputSearch.vue'
import { required, maxLength, email, sameAs } from 'vuelidate/lib/validators'
import AdminUserService from '@/services/admin-user.service'
import UsersService from '@/services/users.service'
import SfModal from '@/components/SfModal.vue'
import Select2 from 'v-select2-component'

export default {
  name: 'subOrganization',
  components: {
    SfInputSearch,
    SfModal,
    Select2
  },
  data() {
    return {
      fields: [
        {
          key: 'status',
          sortable: true,
          colType: 'status',
          label: '',
          class: 't-status'
        },
        {
          key: 'sub_org_name',
          sortable: true,
          colType: 'sub_org_name',
          label: 'Organization Name'
        },
        {
          key: 'contact_name',
          sortable: true,
          colType: 'contact_name',
          label: 'Contact Name'
        },
        {
          key: 'contact_email',
          sortable: true,
          colType: 'contact_email',
          label: 'Contact Email'
        },
        {
          key: 'phone',
          sortable: true,
          colType: 'phone',
          label: 'Phone'
        },
        {
          key: 'action',
          colType: 'action',
          label: ''
        }
      ],
      currentPage: 1,
      searchValue: '',
      limit: 10,
      isShowAddUserModal: false,
      newUser: {
        user: {
          first_name: '',
          last_name: '',
          email: '',
          role_id: '',
          organization_id: '',
          user_type: 'customer',
          password: '',
          password_confirm: ''
        }
      },
      submitted: false,
      emailCanUsed: true,
      orgId: ''
    }
  },
  validations: {
    newUser: {
      user: {
        first_name: {
          required,
          maxLength: maxLength(250)
        },
        last_name: {
          required,
          maxLength: maxLength(250)
        },
        email: {
          required,
          maxLength: maxLength(100),
          email
        },
        role_id: {
          required
        },
        password: {
          strongPassword(password) {
            return (
              /[A-Z]/.test(password) && // checks for A-Z
              /[0-9]/.test(password) && // checks for 0-9
              password.length >= 8
            )
          }
        },
        password_confirm: {
          sameAsPassword: sameAs('password')
        }
      }
    }
  },
  methods: {
    ...mapActions('suborg', {
      getChildOrganization: 'getChildOrganization'
    }),
    ...mapActions('adminorganization', {
      getRoleListByOrganizationNoPaging: 'getRoleListByOrganizationNoPaging'
    }),
    changePage(page) {
      this.currentPage = page
      this.getChildOrganization({ page: this.currentPage, limit: this.limit })
    },
    searchInputChange(val) {
      this.searchValue = val
      this.currentPage = 1
      this.getChildOrganization({
        page: this.currentPage,
        limit: this.limit,
        search: this.searchValue
      })
    },
    shownModalAddUser(id) {
      this.submitted = false
      this.isShowAddUserModal = true
      this.getRoleListByOrganizationNoPaging(this.orgId)
      this.newUser.user.organization_id = id
    },
    checkEmailExist(id, email) {
      UsersService.checkEmailExist({ user_id: id, email: email }).then((res) => {
        this.emailCanUsed = res.data.can_use
      })
    },
    addNewUser() {
      this.submitted = true
      if (this.$v.newUser.$invalid || !this.emailCanUsed) return
      delete this.newUser.user.direct_login
      delete this.newUser.user.is_2fa
      AdminUserService.createUser(this.newUser)
        .then((_) => {
          this.setNewUserToDefault()
          this.getChildOrganization({
            page: this.currentPage,
            limit: this.limit,
            search: this.searchValue
          })
          this.$toast.success('Successfully created')
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
        .finally(() => {
          this.isShowAddUserModal = false
        })
    },
    handleCancelNewUser() {
      this.isShowAddUserModal = false
      this.setNewUserToDefault()
    },
    setNewUserToDefault() {
      this.newUser = {
        user: {
          first_name: '',
          last_name: '',
          email: '',
          role_id: '',
          organization_id: '',
          user_type: 'customer',
          password: '',
          password_confirm: ''
        }
      }
    }
  },
  mounted() {
    this.orgId = this.$route.params.id
    this.getChildOrganization({ page: 1, limit: this.limit })
    this.$bus.$on('onSearchInputChange', (value) => {
      this.searchValue = value
      this.currentPage = 1
      this.getChildOrganization({
        page: this.currentPage,
        limit: this.limit,
        search: this.searchValue
      })
    })
  },
  computed: {
    ...mapState('suborg', {
      listSubOrg: 'listSubOrg',
      isEmpty: 'isEmpty',
      loading: 'loading',
      error: 'error',
      totalPages: 'totalPages'
    }),
    ...mapGetters('adminorganization', {
      roleListNoPagingOptions: 'roleListNoPagingOptions'
    })
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
  }
}
</script>
<style lang="scss" scoped>
@import '@/pages/admin/Adminpage.scss';

.title-user {
  color: #a4aabd;
  height: 70px;
  padding: 19px 21px;
  background-color: white;
  border-color: #f1eff2;
  font-weight: 600;
  margin-left: 15px;
  flex: 0 0 calc(100% - 30px);
}

.status {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 5px;
  &.status-active {
    background-color: #4ac67e;
  }
  &.status-inactive {
    background-color: #aa0a1e;
  }
}
</style>
