<template>
  <div class="">
    <multiselect
      class="sf-multiselect"
      style="width: 115px"
      v-model="dataLimitPage"
      :options="optionPages"
      :searchable="false"
      :allow-empty="false"
      deselectLabel=""
      selectLabel=""
      selectedLabel=""
      label="name"
      track-by="name"
      @select="changeLimit"
    >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'SfOptionPage',
  components: {
    Multiselect
  },
  data() {
    return {
      dataLimitPage: this.limitPage
    }
  },
  props: {
    limitPage: {
      type: Object,
      default() {
        return {
          name: `25 / ${this.$t('page')}`,
          value: '25'
        }
      }
    }
  },
  watch: {
    '$i18n.locale'() {
      this.translateMultiselect()
    },
    limitPage(value) {
      this.dataLimitPage = value
      this.translateMultiselect()
    }
  },
  methods: {
    changeLimit(value) {
      this.$emit('changeLimitPage', value)
    },
    translateMultiselect() {
      const listOptionPage = this.optionPages
      const page = listOptionPage.find((option) => option.value === this.dataLimitPage.value)
      this.dataLimitPage = page
    }
  },
  mounted() {
    this.translateMultiselect()
  },
  computed: {
    optionPages() {
      return [
        {
          name: `25 / ${this.$t('page')}`,
          value: '25'
        },
        {
          name: `50 / ${this.$t('page')}`,
          value: '50'
        },
        {
          name: `100 / ${this.$t('page')}`,
          value: '100'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
