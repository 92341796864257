import state from './adminUserState'
import mutations from './adminUserMutations'
import getters from './adminUserGetters'
import actions from './adminUserActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
