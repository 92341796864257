<template>
  <div class="ui-input-search">
    <div class="sf-search-wrap">
      <input
        class="sf-input-search"
        :id="labelId"
        :type="inputType"
        :style="inputHeight"
        :class="inputClasses"
        :value="input.text"
        :placeholder="placeholder"
        v-on="listeners"
      />
      <span
        @click="onSearchClick"
        v-bind:style="{ backgroundImage: `url(${getSearchIcon})` }"
        class="sf-input-search-icon"
      ></span>
    </div>
  </div>
</template>

<script>
import searchIcon from '@/assets/images/sf-search-icon.svg'
import closeIcon from '@/assets/images/close-x.svg'
import get from 'lodash/get'
export default {
  name: 'SfInputSearch',

  props: {
    height: { type: String, default: '36px' },
    password: { type: Boolean, default: false },
    value: { type: String },
    placeholder: { type: String },
    error: {
      type: [String, Boolean],
      default: false
    }
  },
  data() {
    return {
      searchIcon,
      closeIcon,
      input: {
        text: ''
      }
    }
  },

  methods: {
    onSearchClick() {
      if (this.input.text.length) {
        this.input.text = ''
        this.$emit('onSearchInputChange', this.input.text)
      }
    },
    getRandomInt() {
      const min = 1
      const max = 1000000000
      return Math.floor(Math.random() * (max - min + 1) + min)
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.input.text = val
      }
    }
  },

  computed: {
    inputHeight() {
      return `height: ${this.height};`
    },
    inputClasses() {
      return {
        'is-input-error': this.error,
        'border-radius-start': !this.$slots.before,
        'border-radius-end': !this.$slots.after
      }
    },
    getSearchIcon() {
      if (get(this.input, 'input.text.length', 0)) {
        return closeIcon
      } else {
        return searchIcon
      }
    },
    labelId() {
      return `inputId${this.getRandomInt()}`
    },
    inputType() {
      return this.password ? 'password' : 'text'
    },
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => this.$emit('input', event.target.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sf-input-search-icon {
  right: 16px;
  position: absolute;
  top: 33%;
  display: inline-block;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 14px;
}
.sf-input-search {
  min-width: 120px;
}
</style>
