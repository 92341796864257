export default {
  getState(state) {
    return state
  },
  getPhoto(state) {
    return state.photo
  },
  photo(state) {
    return state.photo
  },
  getProfilePic(state) {
    return state.profilePic
  },
  getTitleIg(state) {
    return state.titleIg
  },
  getTextIg(state) {
    return state.textIg
  },
  getTagsIg(state) {
    return state.tagsIg
  },
  getElementActive(state) {
    return state.elementActive
  },
  getBgColorEditor(state) {
    return state.bgEditor
  },
  getLogo(state) {
    return state.logo
  },
  getMask(state) {
    return state.mask
  },
  getInstagramData(state) {
    return state.instagramData
  },
  getBgTextColor(state) {
    for (const item in state.elementActive) {
      if (state.elementActive[item] === true) {
        return state[item].textAttr.backgroundColor
      }
    }
  },

  getAuthentication(state) {
    return state.isAuthentication
  },
  getNumberData(state) {
    return state.numberData
  },
  getGridDrag(state) {
    return state.gridDrag
  },
  getSnap(state) {
    return state.snap
  },
  getPhotoSize(state) {
    return state.photoSize
  },
  getTemplateName(state) {
    return state.templateName
  },
  getToolBar(state) {
    return state.toolBar
  },
  getWidget(state) {
    return state.widget
  },
  getTemplateID(state) {
    return state.templateID
  },
  getBackgroundExisting(state) {
    return state.backgroundExisting
  },
  getLogoExisting(state) {
    return state.logoExisting
  },
  getActiveAlignItem(state) {
    return state.activeAlignItem
  },
  getActiveTextAlign(state) {
    return state.activeTextAlign
  },
  getFontSelected(state) {
    for (const item in state.elementActive) {
      if (state.elementActive[item] === true) {
        return state[item]
      }
    }
  },
  getActiveWidget(state) {
    for (const item in state.elementActive) {
      if (state.elementActive[item] === true) {
        return state[item]
      }
    }
  },
  getTemplateAction(state) {
    return state.templateAction
  }
}
