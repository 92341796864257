import BaseService from './base.service'

class AdminSmartAssetsTemplateService extends BaseService {
  get entity() {
    return 'admin/smart_asset_templates'
  }

  getSmartAssetTemplate(orientation) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}?orientation=${orientation}`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getAllTemplates(page, limit, type, orientation, categoryId, search) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(
          `${
            this.entity
          }?page=${page}&limit=${limit}&type=${type}&orientation=${orientation}&category_id=${
            categoryId || ''
          }&s=${search || ''}`
        )
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getTemplate(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/${id}`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  deleteAssetTemplate(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .delete(`${this.entity}/${id}`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  createSmartAssets(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}`, data)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  editSmartAssets(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .patch(`${this.entity}`, data)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getSmartAssetsByID(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/${id}`)
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  editSmartAssetsByID(data, id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .patch(`${this.entity}/${id}`, data)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }
}

export default new AdminSmartAssetsTemplateService()
