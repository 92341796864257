import state from './adminOrgTypeState'
import mutations from './adminOrgTypeMutations'
import getters from './adminOrgTypeGetters'
import actions from './adminOrgTypeActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
