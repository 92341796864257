<template>
  <div v-if="active" class="text-attr-container">
    <div class="text-attr">
      <div class="row">
        <div class="col-12 mb-15">
          <p class="action-title">{{ title }}</p>
          <p class="action-desc">{{ description }}</p>
        </div>
        <!-- <div class="col-12">
          <p class="pull-right reset">
            <a @click="resetTextAttr">Reset</a>
          </p>
        </div> -->
      </div>
      <div v-if="hasTabs" class="row">
        <div class="col-12">
          <ul class="ig-editor-tab ig-text-handle">
            <li v-bind:class="{ active: activeTab.text }">
              <a @click="setActiveTab('text')">Text</a>
            </li>
            <li v-bind:class="{ active: activeTab.mentions }">
              <a @click="setActiveTab('mentions')">Mentions</a>
            </li>
            <li v-bind:class="{ active: activeTab.hashtags }">
              <a @click="setActiveTab('hashtags')">Hashtags</a>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="activeTab.text">
        <div class="row">
          <div class="col-12">
            <p class="fw-600">Font</p>
            <div class="row mt-10">
              <div class="col-9">
                <div class="select-group">
                  <Select2
                    v-model="activeWidget.textAttr.fontFamily"
                    :options="[
                      { id: 'Roboto', text: 'Roboto' },
                      { id: 'Lato', text: 'Lato' },
                      { id: 'Open Sans', text: 'Open Sans' },
                      { id: 'color-emoji', text: 'Emoji' }
                    ]"
                    :settings="{ minimumResultsForSearch: -1 }"
                    @change="changeFontFamily($event, 'text')"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div class="col-3">
                <input
                  v-model="activeWidget.textAttr.fontSize"
                  @keyup="changeFontSize($event, 'text')"
                  class="input-ig fw-600"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mt-10">
            <p class="fw-600">Styles</p>
            <div class="font-style-select mt-10">
              <!-- <select
                v-model="activeWidget.textAttr.fontWeight"
                v-on:change="changeFontStyle($event, 'text')"
                class="input-ig"
                id="fontStyle"
              >
                <option value>Font Weight</option>
                <option value="300">Light</option>
                <option value="400">Regular</option>
                <option value="700">Bold</option>
              </select> -->
              <div class="select-group">
                <Select2
                  v-model="activeWidget.textAttr.fontWeight"
                  :options="[
                    { id: '300', text: 'Light' },
                    { id: '400', text: 'Regular' },
                    { id: '700', text: 'Bold' }
                  ]"
                  :settings="{ minimumResultsForSearch: -1 }"
                  @change="changeFontStyle($event, 'text')"
                />
                <span class="lb-right caret"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-attr-color">
          <div class="col-12 mt-10">
            <div class="row">
              <div class="col-8">
                <p>Text color</p>
              </div>
              <div class="col-4">
                <ColorPicker
                  :color="activeWidget.textAttr.textColor"
                  @onColorChange="changeTextColor($event, 'text')"
                />
              </div>
            </div>
          </div>

          <div class="col-12 mt-10">
            <div class="row">
              <div class="col-8">
                <p>Text background color</p>
              </div>
              <div class="col-4">
                <div class="color">
                  <ColorPicker :color="getBgTextColor" @onColorChange="changeBgColor" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-10">
          <div class="col-12 mt-10">
            <p class="fw-600">Horizontal Text Alignment</p>
            <ul class="align-text mt-10">
              <li :class="textAlign.left ? 'active' : 'unactive'">
                <a class="align-left" @click="setTextAlign('left')">
                  <img
                    class="align-icon"
                    :src="textAlign.left ? alignIcon.leftActive : alignIcon.left"
                  />
                </a>
              </li>
              <li :class="textAlign.right ? 'active' : 'unactive'">
                <a class="align-right" @click="setTextAlign('right')">
                  <img
                    class="align-icon"
                    :src="textAlign.right ? alignIcon.rightActive : alignIcon.right"
                  />
                </a>
              </li>

              <li :class="textAlign.center ? 'active' : 'unactive'">
                <a class="align-center" @click="setTextAlign('center')">
                  <img
                    class="align-icon"
                    :src="textAlign.center ? alignIcon.centerActive : alignIcon.center"
                  />
                </a>
              </li>
              <li :class="textAlign.justify ? 'active' : 'unactive'">
                <a class="align-Justify" @click="setTextAlign('justify')">
                  <img
                    class="align-icon"
                    :src="textAlign.justify ? alignIcon.justifyActive : alignIcon.justify"
                  />
                </a>
              </li>
            </ul>
          </div>

          <div class="col-12 mt-10">
            <p class="fw-600">Vertical Text Alignment</p>
            <ul class="align-text align-text-vertical mt-10">
              <li :class="activeAlignItem.top ? 'active' : 'unactive'">
                <a @click="setTextAlignItems('top')">Top</a>
              </li>
              <li :class="activeAlignItem.middle ? 'active' : 'unactive'">
                <a @click="setTextAlignItems('middle')">Middle</a>
              </li>

              <li :class="activeAlignItem.bottom ? 'active' : 'unactive'">
                <a @click="setTextAlignItems('bottom')">Bottom</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-if="activeTab.mentions">
        <div class="row">
          <div class="col-12">
            <p>Font</p>
            <div class="row mt-10">
              <div class="col-9">
                <div class="select-group">
                  <Select2
                    v-model="activeWidget.mentions.fontFamily"
                    :options="[
                      { id: 'Roboto', text: 'Roboto' },
                      { id: 'Lato', text: 'Lato' },
                      { id: 'Open Sans', text: 'Open Sans' },
                      { id: 'color-emoji', text: 'Emoji' }
                    ]"
                    :settings="{ minimumResultsForSearch: -1 }"
                    @change="changeFontFamily($event, 'mentions')"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div class="col-3">
                <input
                  v-model="activeWidget.mentions.fontSize"
                  @keyup="changeFontSize($event, 'mentions')"
                  class="input-ig fw-600"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mt-10">
            <p>Font style</p>
            <div class="font-style-select mt-10">
              <div class="select-group">
                <Select2
                  v-model="activeWidget.mentions.fontWeight"
                  :options="[
                    { id: '300', text: 'Light' },
                    { id: '400', text: 'Regular' },
                    { id: '700', text: 'Bold' }
                  ]"
                  :settings="{ minimumResultsForSearch: -1 }"
                  @change="changeFontStyle($event, 'mentions')"
                />
                <span class="lb-right caret"></span>
              </div>
            </div>
          </div>

          <div class="col-12 mt-10">
            <div class="row">
              <div class="col-6">
                <p>Font color</p>
              </div>
              <div class="col-6">
                <ColorPicker
                  :color="activeWidget.mentions.textColor"
                  @onColorChange="changeTextColor($event, 'mentions')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="activeTab.hashtags">
        <div class="row">
          <div class="col-12">
            <p>Font</p>
            <div class="row mt-10">
              <div class="col-9">
                <div class="select-group">
                  <Select2
                    v-model="activeWidget.hashtags.fontFamily"
                    :options="[
                      { id: 'Roboto', text: 'Roboto' },
                      { id: 'Lato', text: 'Lato' },
                      { id: 'Open Sans', text: 'Open Sans' },
                      { id: 'color-emoji', text: 'Emoji' }
                    ]"
                    :settings="{ minimumResultsForSearch: -1 }"
                    @change="changeFontFamily($event, 'hashtags')"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div class="col-3">
                <input
                  v-model="activeWidget.hashtags.fontSize"
                  @keyup="changeFontSize($event, 'hashtags')"
                  class="input-ig fw-600"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mt-10">
            <p>Font style</p>
            <div class="font-style-select mt-10">
              <div class="select-group">
                <Select2
                  v-model="activeWidget.hashtags.fontWeight"
                  :options="[
                    { id: '300', text: 'Light' },
                    { id: '400', text: 'Regular' },
                    { id: '700', text: 'Bold' }
                  ]"
                  :settings="{ minimumResultsForSearch: -1 }"
                  @change="changeFontStyle($event, 'hashtags')"
                />
                <span class="lb-right caret"></span>
              </div>
            </div>
          </div>

          <div class="col-12 mt-10">
            <div class="row">
              <div class="col-6">
                <p>Font color</p>
              </div>
              <div class="col-6">
                <ColorPicker
                  :color="activeWidget.hashtags.textColor"
                  @onColorChange="changeTextColor($event, 'hashtags')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="remove-widget-wrapper">
      <div class="remove-widget">
        <button @click="removeWidget" class="remove-widget-btn">Remove widget</button>
      </div>
    </div>
  </div>
</template>
<script>
import ColorPicker from './ColorPicker.vue'
import alignLeftIcon from '@/assets/images/align-left.svg'
import alignRightIcon from '@/assets/images/align-right.svg'
import alignJustifyIcon from '@/assets/images/align-justify.svg'
import alignCenterIcon from '@/assets/images/align-center.svg'

import alignLeftIconActive from '@/assets/images/align-left-active.svg'
import alignRightIconActive from '@/assets/images/align-right-active.svg'
import alignJustifyIconActive from '@/assets/images/align-justify-active.svg'
// import alignCenterIconActive from '@/assets/images/align-center-active.svg'
import Select2 from 'v-select2-component'

export default {
  name: 'textattribute',
  components: {
    ColorPicker,
    Select2
  },
  props: {
    title: String,
    description: String,
    active: Boolean,
    textAttributeEl: Object,
    hasTabs: Boolean
  },
  data() {
    return {
      alignIcon: {
        left: alignLeftIcon,
        right: alignRightIcon,
        center: alignCenterIcon,
        justify: alignJustifyIcon,
        leftActive: alignLeftIconActive,
        rightActive: alignRightIconActive,
        centerActive: alignRightIconActive,
        justifyActive: alignJustifyIconActive
      },
      activeTab: {
        text: true,
        mentions: false,
        hashtags: false
      }
    }
  },
  created() {},
  computed: {
    getBgTextColor() {
      return this.$store.getters['instagrameditor/getBgTextColor']
    },
    activeAlignItem() {
      return this.$store.getters['instagrameditor/getActiveAlignItem']
    },
    textAlign() {
      return this.$store.getters['instagrameditor/getActiveTextAlign']
    },

    activeWidget() {
      return this.$store.getters['instagrameditor/getActiveWidget']
    }
  },
  methods: {
    setTextAlign(align) {
      this.$store.dispatch('instagrameditor/setTextAlign', align)
      this.$store.dispatch('instagrameditor/pushToHistory')
      for (const item in this.textAlign) {
        if (item === align) {
          this.textAlign[item] = true
        } else {
          this.textAlign[item] = false
        }
      }
    },
    setTextAlignItems(position) {
      this.$store.dispatch('instagrameditor/setTextAlignItems', position)
      // this.$store.dispatch("pushToHistory");
      for (const item in this.activeAlignItem) {
        if (item === position) {
          this.activeAlignItem[item] = true
        } else {
          this.activeAlignItem[item] = false
        }
      }
    },
    changeFontFamily(e, type) {
      const value = e
      if (value) {
        this.$store.dispatch('instagrameditor/setFontFamily', { value, type })
        this.$store.dispatch('instagrameditor/pushToHistory')
      }
    },
    changeFontSize(e, type) {
      const value = e.target.value
      if (value) {
        this.$store.dispatch('instagrameditor/setFontSize', { value, type })
        this.$store.dispatch('instagrameditor/pushToHistory')
      }
    },
    changeFontStyle(e, type) {
      const value = e
      if (value) {
        this.$store.dispatch('instagrameditor/setFontStyle', { value, type })
        this.$store.dispatch('instagrameditor/pushToHistory')
      }
    },
    changeBgColor(event) {
      this.$store.dispatch('instagrameditor/setTextBgColor', event)
      this.$store.dispatch('instagrameditor/pushToHistory')
    },
    changeTextColor(event, type) {
      const value = event
      this.$store.dispatch('instagrameditor/setTextColor', { value, type })
      this.$store.dispatch('instagrameditor/pushToHistory')
    },

    resetTextAttr() {
      this.$store.dispatch('instagrameditor/resetTextAttr')
      this.$store.dispatch('instagrameditor/pushToHistory')
    },
    setActiveTab(tab) {
      for (const item in this.activeTab) {
        // compare
        if (item === tab) {
          this.activeTab[item] = true
        } else {
          this.activeTab[item] = false
        }
      }
    },
    removeWidget() {
      const elementActive = this.$store.getters['instagrameditor/getElementActive']
      for (const item in elementActive) {
        if (elementActive[item]) {
          this.$store.dispatch('instagrameditor/removeWidget', item)
        }
      }
    }
  }
}
</script>
<style scoped>
.align-icon {
  width: 32px;
}
ul.align-text {
  list-style: none;
  padding: 0;
}
ul.align-text li {
  float: left;
  margin-right: 20px;
}
.align-text li a {
  cursor: pointer;
}

.align-text.align-text-vertical li a {
  color: #bdbdbd;
  width: 70px;
  height: 25px;
  line-height: 25px;
  border-radius: 4px;
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}
.align-text.align-text-vertical li.active a {
  color: #fff;
  background-color: #2c7fe1;
}

.slidecontainer {
  width: 100%;
}

.opacity {
  display: flex;
  justify-content: center;
  align-items: center;
}
.opacity-value {
  position: relative;
}
.opacity-value span.unit {
  position: absolute;
  right: 5px;
  top: 3px;
}
.input-ig-opacity {
  padding-right: 15px !important;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background: #2c7fe1;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #2c7fe1;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
}
.ig-text-handle li {
  cursor: pointer;
}
.remove-widget-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
}
.remove-widget-wrapper button {
  width: 142px;
  height: 26px;
  border-radius: 4px;
  background-color: #d0021b;
  color: #fff;
  border: 0;
}
</style>
