<template>
  <div class="row setting-page">
    <div class="col-12">
      <h2 class="text-center mt-3 coming-soon">
        {{ $t('coming-soon') }}
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Billing',
  components: {},
  data() {
    return {}
  },

  methods: {},
  mounted() {}
}
</script>

<style lang="scss" scoped>
@import './accountPage.scss';
.coming-soon {
  font-size: 30px;
}
</style>
