var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid sf-pagination-page"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-3"},[_vm._v("TAB WITH ICON")]),_c('div',{staticClass:"col-9"},[_c('ul',{staticClass:"sf-tabs"},[_c('li',[_c('a',{staticClass:"active",attrs:{"tabindex":"1"}},[_c('img',{attrs:{"src":_vm.add}}),_c('span',[_vm._v("Add")])])]),_c('li',[_c('a',{attrs:{"tabindex":"2"}},[_c('img',{attrs:{"src":_vm.playlistEditor}}),_c('span',[_vm._v("Customise")])])]),_c('li',[_c('a',{attrs:{"tabindex":"3"}},[_c('img',{attrs:{"src":_vm.previewEye}}),_c('span',[_vm._v("Overview")])])])])])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"page-title"},[_vm._v("Tabs")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-3"},[_vm._v("TAB GROUP")]),_c('div',{staticClass:"col-9"},[_c('ul',{staticClass:"sf-tabs"},[_c('li',[_c('a',{staticClass:"active",attrs:{"tabindex":"1"}},[_vm._v("Active")])]),_c('li',[_c('a',{attrs:{"tabindex":"2"}},[_vm._v("Default")])]),_c('li',[_c('a',{attrs:{"tabindex":"3"}},[_vm._v("Default")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"page-title"},[_vm._v("Pagination")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-3"},[_vm._v("PAGINATION GROUP")]),_c('div',{staticClass:"col-9"},[_c('ul',{staticClass:"sf-pagination"},[_c('li',{staticClass:"page-item"},[_c('span',{staticClass:"previous-page"})]),_c('li',{staticClass:"page-item active"},[_c('a',{attrs:{"href":"#1"}},[_vm._v("1")])]),_c('li',{staticClass:"page-item"},[_c('a',{attrs:{"href":"#2"}},[_vm._v("2")])]),_c('li',{staticClass:"page-item"},[_c('a',{attrs:{"href":"#3"}},[_vm._v("3")])]),_c('li',{staticClass:"page-item"},[_c('a',{attrs:{"href":"#2"}},[_vm._v("4")])]),_c('li',{staticClass:"page-item"},[_c('a',{attrs:{"href":"#3"}},[_vm._v("5")])]),_c('li',{staticClass:"page-item"},[_c('span',{staticClass:"next-page"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-3"},[_vm._v("PAGINATION GROUP - OVERFLOW")]),_c('div',{staticClass:"col-9"},[_c('ul',{staticClass:"sf-pagination"},[_c('li',{staticClass:"page-item"},[_c('span',{staticClass:"previous-page"})]),_c('li',{staticClass:"page-item active"},[_c('a',{attrs:{"href":"#1"}},[_vm._v("1")])]),_c('li',{staticClass:"page-item"},[_c('a',{attrs:{"href":"#2"}},[_vm._v("2")])]),_c('li',{staticClass:"page-item"},[_c('a',{attrs:{"href":"#3"}},[_vm._v("3")])]),_c('li',{staticClass:"page-item"},[_c('a',{attrs:{"href":"#2"}},[_vm._v("...")])]),_c('li',{staticClass:"page-item"},[_c('a',{attrs:{"href":"#3"}},[_vm._v("100")])]),_c('li',{staticClass:"page-item"},[_c('span',{staticClass:"next-page"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"page-title"},[_vm._v("Breadcrumbs")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-3"},[_vm._v("BREADCRUMB GROUP")]),_c('div',{staticClass:"col-9"},[_c('div',{staticClass:"sf-breadcrumbs"},[_c('ul',[_c('li',[_c('a',{staticClass:"sf-link active"},[_vm._v("Level 1")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-3"},[_vm._v("BREADCRUMB GROUP")]),_c('div',{staticClass:"col-9"},[_c('div',{staticClass:"sf-breadcrumbs"},[_c('ul',[_c('li',[_c('a',{staticClass:"sf-link"},[_vm._v("Level 1")])]),_c('li',[_c('a',{staticClass:"sf-link active"},[_vm._v("Level 2")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-3"},[_vm._v("BREADCRUMB GROUP")]),_c('div',{staticClass:"col-9"},[_c('div',{staticClass:"sf-breadcrumbs"},[_c('ul',[_c('li',[_c('a',{staticClass:"sf-link"},[_vm._v("Level 1")])]),_c('li',[_c('a',{staticClass:"sf-link"},[_vm._v("Level 2")])]),_c('li',[_c('a',{staticClass:"sf-link active"},[_vm._v("Level 3")])])])])])])
}]

export { render, staticRenderFns }