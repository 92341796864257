var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-draggable-resizable',{staticClass:"d-flex align-items-center justify-content-center",class:{ selected: _vm.index === _vm.activeBlockIndex },style:({
    zIndex: _vm.item.zIndex,
    borderRadius: `${_vm.item.borderRadiusValue.topLeft}px ${_vm.item.borderRadiusValue.topRight}px ${_vm.item.borderRadiusValue.bottomRight}px ${_vm.item.borderRadiusValue.bottomLeft}px`,
    backgroundColor: _vm.item.style.backgroundColor
  }),attrs:{"active":_vm.index === _vm.activeBlockIndex,"lock-aspect-ratio":_vm.item.aspectRatio,"grid":_vm.smartAsset.grid,"x":_vm.item.x,"y":_vm.item.y,"w":_vm.widthDateTimeDefault,"h":_vm.item.h >= 0 ? _vm.item.h : null},on:{"activated":function($event){return _vm.onActivated(_vm.index, _vm.item)},"dragstop":_vm.onDragStop,"dragging":_vm.onDragging,"resizestop":_vm.onResizeStop,"resizing":_vm.onResize}},[_c('div',{staticClass:"content-editable-container content-editable w-100 h-100",style:({
      padding: `${_vm.item.paddingValue.top}px ${_vm.item.paddingValue.right}px ${_vm.item.paddingValue.bottom}px ${_vm.item.paddingValue.left}px`,
      borderRadius: `${_vm.item.borderRadiusValue.topLeft}px ${_vm.item.borderRadiusValue.topRight}px ${_vm.item.borderRadiusValue.bottomRight}px ${_vm.item.borderRadiusValue.bottomLeft}px`,
      color: _vm.item.textStyle.color,
      fontFamily: _vm.item.textStyle.fontFamily,
      fontWeight: _vm.item.textStyle.fontWeight,
      lineHeight: _vm.item.textStyle.lineHeight,
      letterSpacing: _vm.item.textStyle.letterSpacing,
      textShadow: _vm.item.textStyle.textShadow,
      alignItems: _vm.item.style.alignItems,
      justifyContent: _vm.item.style.justifyContent,
      flexDirection: _vm.item.style.flexDirection ? _vm.item.style.flexDirection : 'column',
      whiteSpace: 'pre-line',
      display: _vm.item.style.display ? _vm.item.style.display : 'flex',
      overflow: 'hidden'
    }),attrs:{"contenteditable":"false"}},[_c('div',{staticClass:"d-flex flex-column",style:({
        alignItems: _vm.item.textStyle.textAlign
      })},[_c('span',{style:({
          fontSize: `calc( ${_vm.item.textStyle.fontSize} + ${_vm.item.textStyle.fontSize} )`,
          lineHeight: _vm.item.textStyle.lineHeight,
          whiteSpace: 'nowrap'
        })},[_vm._v(_vm._s(_vm.hourCurrentFormat))]),_c('span',{style:({
          fontSize: _vm.item.textStyle.fontSize,
          lineHeight: _vm.item.textStyle.lineHeight
        })},[_vm._v(_vm._s(_vm.dateCurrentFormat))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }