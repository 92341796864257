<template>
  <div class="container-fluid">
    <div class="col-12">
      <div class="card-header">
        <div class="header-left">Slide Template</div>
        <div class="header-right">
          <button
            class="ajax-popup-add-new-organization btn btn-primary btn-success hidden-xs pull-right"
            @click="updateSlideTemplate()"
          >
            Save
          </button>
        </div>
      </div>

      <div class="card-body">
        <div class="content-infomation">
          <div class="row exception-row">
            <div class="col-6">
              <p class="title">Markup:</p>
              <codemirror
                ref="myCm"
                :value="markup_edit"
                :options="cmOption"
                @input="updatePreviewMarkup"
              >
              </codemirror>
            </div>
            <div class="col-6">
              <p class="title">Preview:</p>
              <iframe :id="'preview'" :srcdoc="markup_preview"></iframe>
            </div>
          </div>

          <div class="row exception-row">
            <div class="col-6">
              <div class="row">
                <div class="col-3">
                  <p>Name:</p>
                </div>
                <div class="col-9">
                  <p>{{ currentSlideTemplate.slide_template.name }}</p>
                </div>
              </div>

              <div class="row">
                <div class="col-3">
                  <p>Version:</p>
                </div>
                <div class="col-9">
                  <p>{{ currentSlideTemplate.slide_template.version }}</p>
                </div>
              </div>

              <div class="row">
                <div class="col-3">
                  <p>Active:</p>
                </div>
                <div class="col-9">
                  <span
                    v-if="currentSlideTemplate.slide_template.active"
                    class="badge badge-pill badge-success slide-template-active"
                    >YES</span
                  >
                  <span v-else class="badge badge-pill badge-danger slide-template-active">NO</span>
                </div>
              </div>

              <div class="row">
                <div class="col-3">
                  <p>Type:</p>
                </div>
                <div class="col-9">
                  <p>{{ currentSlideTemplate.slide_template.template_type }}</p>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-5">
                  <p>Portrait (default Landscape):</p>
                </div>
                <div class="col-7">
                  <toggle-button
                    :color="'#3498db'"
                    :value="this.portrait"
                    :sync="true"
                    :labels="{ checked: 'Yes', unchecked: 'No' }"
                    @change="onChangeToggle"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AdminSlideTemplateService from '@/services/admin-slide-template.service'
import { required, maxLength, minValue } from 'vuelidate/lib/validators'
// import VueCodemirror from 'vue-codemirror'
import imagePreview from '@/assets/images/image-preview.jpeg'

export default {
  name: 'admin-user-customer',

  components: {
    // VueCodemirror
  },

  data() {
    return {
      msg: 'Welcome to Index!!!',
      currentPage: 1,
      currentSlideTemplateID: this.$route.params.id,
      submitted: false,
      currentSlideTemplate: {
        slide_template: {
          name: '',
          markup: '',
          portrait_markup: '',
          description: '',
          template_type: 0,
          version: 0,
          actice: false
        }
      },
      markup_preview: '',
      image_preview: imagePreview,
      portrait: false,
      markup_edit: '',
      cmOption: {
        tabSize: 2,
        mode: 'text/html',
        theme: 'default',
        lineNumbers: true,
        line: true
      }
    }
  },

  validations: {
    currentSlideTemplate: {
      slide_template: {
        name: {
          required,
          maxLength: maxLength(250)
        },
        version: {
          required,
          maxLength: maxLength(10),
          minValue: minValue(0)
        },
        template_type: {
          required
        }
      }
    }
  },

  methods: {
    getItemByID() {
      AdminSlideTemplateService.detailSlideTemplate(this.currentSlideTemplateID)
        .then((response) => {
          this.setDataSlideTemplate(this.currentSlideTemplate.slide_template, response.data)
        })
        .catch((err) => {
          this.isShownModalEdit = false
          this.$toast.error(err.message)
        })
    },
    updateSlideTemplate() {
      this.submitted = true
      if (this.$v.currentSlideTemplate.$invalid) {
        return
      }
      if (this.portrait) {
        this.currentSlideTemplate.slide_template.portrait_markup = this.markup_edit
      } else {
        this.currentSlideTemplate.slide_template.markup = this.markup_edit
      }
      AdminSlideTemplateService.updateSlideTemplate(
        this.currentSlideTemplateID,
        this.currentSlideTemplate
      )
        .then((response) => {
          this.$toast.success('Slide Template successfully updated.')
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalEdit = false
    },
    setDataSlideTemplate(slide_template, new_slide_template) {
      slide_template.name = new_slide_template.name
      slide_template.markup = new_slide_template.markup ? new_slide_template.markup : ' '
      slide_template.portrait_markup = new_slide_template.portrait_markup
      slide_template.description = new_slide_template.description
      slide_template.template_type = new_slide_template.template_type
      slide_template.version = new_slide_template.version
      slide_template.active = new_slide_template.active
      this.setMarkupPreview(this.currentSlideTemplate.slide_template.markup)
      this.markup_edit = this.currentSlideTemplate.slide_template.markup
    },
    updatePreviewMarkup(input) {
      this.setMarkupPreview(input)
      this.markup_edit = input
    },
    setMarkupPreview(markup) {
      this.markup_preview = markup
        ? markup
            .replace('__SRC1__', this.image_preview)
            .replace('1920px', '100%')
            .replace('1080px', '100%')
        : markup
    },
    onChangeToggle() {
      this.portrait = !this.portrait
      if (this.portrait) {
        this.currentSlideTemplate.slide_template.markup = this.markup_edit
        this.markup_edit = this.currentSlideTemplate.slide_template.portrait_markup
      } else {
        this.currentSlideTemplate.slide_template.portrait_markup = this.markup_edit
        this.markup_edit = this.currentSlideTemplate.slide_template.markup
      }
    }
  },

  computed: {
    ...mapState('adminorgtype', {
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('adminorgtype', ['isEmpty'])
  },

  mounted() {
    this.getItemByID()
  }
}
</script>
<style lang="scss" scoped>
@import '../../Adminpage.scss';
.slide-template-active {
  width: 50px;
  height: 20px;
  margin-top: 5px;
  line-height: 15px;
}

#preview {
  width: 640px;
  height: 360px;
}
</style>
