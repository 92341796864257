import axios from 'axios'
import { ResponseWrapper } from './util'

export function googleSheetUrl({ id }) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `https://sheets.googleapis.com/v4/spreadsheets/${id}?key=AIzaSyAf59ieOP-dMCnuLkwgDq2KJnLVw3OXZck`
      )
      .then((response) => {
        return resolve(new ResponseWrapper(response, response.data))
      })
      .catch((error) => {
        reject(error)
      })
  })
}
