export default {
  isEmpty: (state) => state.allPlayerAdmin.length === 0,
  center: (state) => {
    return {
      lat: state.itemsByID.lat ? parseFloat(state.itemsByID.lat) : 0,
      lng: state.itemsByID.lng ? parseFloat(state.itemsByID.lng) : 0
    }
  },
  listCampaignByOrgOptions: (state) => {
    const listCampaignOptions = [
      {
        id: 0,
        text: 'Please select campaign'
      }
    ]
    state.listCampaignByOrg.map((campaign) => {
      return listCampaignOptions.push({
        id: campaign.id,
        text: campaign.displayname
      })
    })
    return listCampaignOptions
  }
}
