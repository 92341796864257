import state from './overviewState'
import mutations from './overviewMutations'
import getters from './overviewGetters'
import actions from './overviewActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
