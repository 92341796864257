<template>
  <div class="row">
    <div class="col-6">
      <div class="sf-card">
        <div class="card-heading">
          <p class="title-section">Resource Caps</p>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-6">
                  <p class="mb-3">
                    <span class="sf-text-strong mr-2">Users</span>
                    <span v-if="loadingOrganization">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </span>
                    <span v-else> {{ dataOrg && dataOrg.max_users }} users</span>
                  </p>
                  <p class="mb-3">
                    <span class="sf-text-strong mr-2">Library</span>
                    <span v-if="loadingOrganization">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </span>
                    <span v-else> {{ dataOrg && dataOrg.max_media }} assets</span>
                  </p>

                  <p class="mb-3">
                    <span class="sf-text-strong mr-2">Campaigns</span>
                    <span v-if="loadingOrganization">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </span>
                    <span v-else> {{ dataOrg && dataOrg.max_campaigns }} campaigns</span>
                  </p>
                </div>
                <div class="col-6">
                  <p class="mb-3">
                    <span class="sf-text-strong mr-2">Storage</span>
                    <span v-if="loadingOrganization">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </span>
                    <span v-else> {{ dataOrg && dataOrg.max_data }} GB</span>
                  </p>

                  <p class="mb-3">
                    <span class="sf-text-strong mr-2">Loops</span>
                    <span v-if="loadingOrganization">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </span>
                    <span v-else> {{ dataOrg && dataOrg.max_loops }} loops</span>
                  </p>

                  <p class="mb-3">
                    <span class="sf-text-strong mr-2">Players</span>
                    <span v-if="loadingOrganization">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </span>
                    <span v-else> {{ dataOrg && dataOrg.players }} players</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sf-card">
        <div class="card-heading">
          <p class="title-section">Links</p>
        </div>
        <div class="card-body editable">
          <div class="row">
            <div class="col-12">
              <button
                v-if="!loadingOrganization"
                @click="showModalEditLink"
                class="button-edit-pen"
              ></button>
              <p class="mb-3">
                <span class="sf-text-strong mr-2">Freshdesk</span>
                <span v-if="loadingOrganization">
                  <content-placeholders>
                    <content-placeholders-text :lines="1" />
                  </content-placeholders>
                </span>
                <a
                  v-else
                  class="info info-account"
                  target="_blank"
                  :href="organizationDisplay.freshdesk"
                >
                  {{ organizationDisplay.freshdesk }}</a
                >
              </p>

              <p class="mb-3">
                <span class="sf-text-strong mr-2">ChargeOver</span>
                <span v-if="loadingOrganization">
                  <content-placeholders>
                    <content-placeholders-text :lines="1" />
                  </content-placeholders>
                </span>
                <a :href="orgChargeOverView" v-else target="_blank" class="info info-account">
                  {{ organizationDisplay.chargeover }}</a
                >
              </p>

              <p class="mb-3">
                <span class="sf-text-strong mr-2">Xero</span>
                <span v-if="loadingOrganization">
                  <content-placeholders>
                    <content-placeholders-text :lines="1" />
                  </content-placeholders>
                </span>
                <a
                  :href="organizationDisplay.xero"
                  v-else
                  target="_blank"
                  class="info info-account"
                >
                  {{ organizationDisplay.xero }}</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="sf-card">
        <div class="card-heading">
          <p class="title-section">Single sign-on</p>
        </div>
        <div class="card-body editable">
          <div class="row">
            <div class="col-12">
              <button
                v-if="!loadingOrganization"
                @click="showModalEditSso"
                class="button-edit-pen"
              ></button>
              <p class="mb-3">
                <span class="sf-text-strong mr-2">Issuer</span>
                <span v-if="loadingOrganization">
                  <content-placeholders>
                    <content-placeholders-text :lines="1" />
                  </content-placeholders>
                </span>
                <a
                  v-else
                  class="info info-account"
                  target="_blank"
                  :href="organizationDisplay.idp_entity_id"
                >
                  {{ organizationDisplay.idp_entity_id }}</a
                >
              </p>

              <p class="mb-3">
                <span class="sf-text-strong mr-2">SSO URL</span>
                <span v-if="loadingOrganization">
                  <content-placeholders>
                    <content-placeholders-text :lines="1" />
                  </content-placeholders>
                </span>
                <a
                  :href="organizationDisplay.idp_sso_target_url"
                  v-else
                  target="_blank"
                  class="info info-account"
                >
                  {{ organizationDisplay.idp_sso_target_url }}</a
                >
              </p>

              <p class="mb-3">
                <span class="sf-text-strong mr-2">Certificate Fingerprint</span>
                <span v-if="loadingOrganization">
                  <content-placeholders>
                    <content-placeholders-text :lines="1" />
                  </content-placeholders>
                </span>
                <a
                  :href="organizationDisplay.idp_cert_fingerprint"
                  v-else
                  target="_blank"
                  class="info info-account"
                >
                  {{ organizationDisplay.idp_cert_fingerprint }}</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Edit Modal Links in Organization -->
    <SfModal
      title="Edit Organization"
      :icon="'fa fa-building'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEditLink"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Freshdesk</label>
                </div>
                <input
                  v-model="organization.freshdesk"
                  placeholder="Freshdesk..."
                  class="sf-input-text"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">ChargeOver</label>
                </div>
                <input
                  v-model="organization.chargeover"
                  placeholder="ChargeOver..."
                  class="sf-input-text"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Xero</label>
                </div>
                <input v-model="organization.xero" placeholder="Xero..." class="sf-input-text" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalEditLink = false">
                  Cancel
                </button>
                <button type="button" class="sf-primary" @click="updateLinks">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>

    <SfModal
      title="Edit Single sign-on"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEditSso"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Issuer</label>
                </div>
                <input
                  v-model="organization.idp_entity_id"
                  placeholder="Issuer..."
                  class="sf-input-text"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">SSO URL</label>
                </div>
                <input
                  v-model="organization.idp_sso_target_url"
                  placeholder="SSO URL..."
                  class="sf-input-text"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Certificate</label>
                </div>
                <input
                  v-model="organization.idp_cert_fingerprint"
                  placeholder="Certificate Fingerprint SHA-256..."
                  class="sf-input-text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalEditSso = false">
                  Cancel
                </button>
                <button type="button" class="sf-primary" @click="updateSso">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>
  </div>
</template>
<script>
import { CHARGEOVER_URL } from '@/.env.js'
import SfModal from '@/components/SfModal.vue'
import OrganizationService from '@/services/admin-organization.service.js'
export default {
  name: 'organizationTable',
  components: {
    SfModal
  },
  props: {
    dataOrg: {},
    org_id: {},
    itemsByID: {},
    loadingOrganization: {
      type: Boolean
    }
  },
  watch: {
    itemsByID(value) {
      this.setDataOrganization(this.organization, value)
      this.setDataOrganization(this.organizationDisplay, value)
    }
  },
  data() {
    return {
      isShownModalEditLink: false,
      isShownModalEditSso: false,
      organization: {
        freshdesk: '',
        chargeover: '',
        xero: '',
        idp_entity_id: '',
        idp_sso_target_url: '',
        idp_cert_fingerprint: ''
      },
      organizationDisplay: {
        freshdesk: '',
        chargeover: '',
        xero: '',
        idp_entity_id: '',
        idp_sso_target_url: '',
        idp_cert_fingerprint: ''
      }
    }
  },
  computed: {
    orgChargeOverView() {
      return `${CHARGEOVER_URL}/admin/r/customer/view/${this.organizationDisplay.chargeover}`
    }
  },

  methods: {
    showModalEditLink() {
      this.isShownModalEditLink = true
      this.setDataOrganization(this.organization, this.organizationDisplay)
    },
    showModalEditSso() {
      this.isShownModalEditSso = true
      this.setDataOrganization(this.organization, this.organizationDisplay)
    },
    updateLinks() {
      const data = this.organization
      OrganizationService.updateOrganization(this.org_id, data)
        .then((response) => {
          this.$toast.success('Edit organization successfully')
          this.setDataOrganization(this.organization, response.data)
          this.setDataOrganization(this.organizationDisplay, response.data)
        })
        .catch((error) => {
          this.$toast.error(error.message)
        })
        .finally(() => {
          this.isShownModalEditLink = false
        })
    },
    updateSso() {
      const data = this.organization
      OrganizationService.updateOrganization(this.org_id, data)
        .then((response) => {
          this.$toast.success('Edit organization successfully')
          this.setDataOrganization(this.organization, response.data)
          this.setDataOrganization(this.organizationDisplay, response.data)
        })
        .catch((error) => {
          this.$toast.error(error.message)
        })
        .finally(() => {
          this.isShownModalEditSso = false
        })
    },
    setDataOrganization(organization, data) {
      organization.freshdesk = data.freshdesk
      organization.chargeover = data.chargeover
      organization.xero = data.xero
      organization.idp_entity_id = data.idp_entity_id
      organization.idp_sso_target_url = data.idp_sso_target_url
      organization.idp_cert_fingerprint = data.idp_cert_fingerprint
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/pages/account/accountPage.scss';
@import '@/pages/admin/Adminpage.scss';
.card-information {
  width: 50%;
  &:nth-child(1) {
    margin-right: 15px;
  }
  &:nth-child(2) {
    margin-left: 15px;
  }
}
.card-body {
  background-color: #ffffff;
  border: none;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}
.style-link {
  margin-left: 30px;
}
.style-sso {
  margin-left: 15px;
}
</style>
