export default {
  // Library page
  isEmpty: (state) => !state.items.length,
  isLoopEmpty: (state) => !!state.loopItems.length <= 0,
  isCampaignEmpty: (state) => !!state.campaignItems.length <= 0,
  isPlayerEmpty: (state) => !!state.playerItems.length <= 0,
  items: (state) => customItems(state.items),
  loopItems: (state) => {
    return state.loopItems
  },
  campaignItems: (state) => {
    return state.campaignItems
  },
  playerItems: (state) => {
    return state.playerItems
  },
  treeFolder: (state) => state.treeFolder,
  breadcrumb: (state) => state.breadcrumb,
  pagination: (state) => state.pagination,
  loopPagination: (state) => state.loopPagination,
  campaignPagination: (state) => state.campaignPagination,
  playerPagination: (state) => state.playerPagination,
  pagePagination: (state) => state.pagination.page,
  totalPagination: (state) => state.pagination.total,
  limitPagition: (state) => state.pagination.limit,
  statistical: (state) => state.statistical,
  getSmartAssetDataModal: (state) => {
    return state.smartAssetDataModal
  }
}

function customItems(items) {
  const itemOtherSubOrg = items.filter((x) => x.folder_type !== 'sub_organization')
  const folderSubOrganization = items.filter((x) => x.folder_type === 'sub_organization')
  return [...folderSubOrganization, ...itemOtherSubOrg]
}
