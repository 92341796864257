import mutations from './adminFirmwareMutations'
import getters from './adminFirmwareGetters'
import actions from './adminFirmwareActions'
import state from './adminFirmwareState'
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
