<template>
  <div class="container-fluid">
    <div class="card-header top-bar">
      <div class="header-left">
        <div class="admin-search-box">
          <SfInputSearch
            placeholder="Search"
            v-debounce:500ms="searchInputChange"
            class="sf-input-search"
          />
        </div>
      </div>
      <div class="header-right">
        <button class="sf-primary-add" @click="showModalCreate()">Add New Language</button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 sf-table">
          <b-table
            :busy="loading"
            show-empty
            striped
            hover
            :items="items"
            :fields="fields"
            head-variant="none"
            table-variant="none"
            thead-class="custom-b-table"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty>
              <p class="d-flex justify-content-center">No data available in table</p>
            </template>

            <template v-slot:head(name)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(name)="field">
              <p>
                <router-link :to="`/admin/system/languages/${field.item.id}`">{{
                  field.item.name
                }}</router-link>
              </p>
            </template>

            <template v-slot:head(code)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(code)="field">
              <p>{{ field.item.code }}</p>
            </template>

            <template v-slot:cell(BE)="field">
              <a
                class="sf-link"
                :href="`${API_URL}/admin/languages/${field.item.id}/download?type=original`"
                >Original</a
              >
              <a
                class="sf-link"
                :href="`${API_URL}/admin/languages/${field.item.id}/download?type=csv`"
                >CSV</a
              >
            </template>

            <template v-slot:cell(FE)="field">
              <a
                class="sf-link"
                href="javascript:void(0)"
                @click="exportLanguageFile(field.item, 'original')"
                >Original</a
              >
              <a
                class="sf-link"
                href="javascript:void(0)"
                @click="exportLanguageFile(field.item, 'csv')"
                >CSV</a
              >
            </template>

            <template v-slot:cell(action)="field">
              <button
                @click="shownModalEdit(field.item.id)"
                class="button-edit-table"
                title="Edit"
              ></button>
              <button
                @click="shownModalDelete(field.item.id)"
                class="button-delete-table"
                title="Delete"
              ></button>
            </template>
          </b-table>

          <div v-if="!isEmpty" class="my-3">
            <b-pagination
              class="sf-b-pagination"
              :total-rows="pagination.limit * pagination.total"
              :per-page="pagination.limit"
              @change="changePage"
              :limit="6"
              v-model="currentPage"
            />
          </div>
        </div>
      </div>
    </div>
    <SfModal
      title="Create New Language"
      :icon="'fa fa-user'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalCreate"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Name</label>
                </div>
                <input
                  v-model="newLanguage.language.name"
                  placeholder="Name..."
                  class="sf-input-text"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newLanguage.language.name.required">
                Name is required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Code</label>
                </div>
                <input
                  v-model="newLanguage.language.code"
                  placeholder="Code..."
                  class="sf-input-text"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newLanguage.language.code.required">
                Code is required
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalCreate = false">Cancel</button>
        <button class="sf-primary" @click="saveLanguage()">Save</button>
      </div>
    </SfModal>
    <SfModal
      :title="'Edit Language'"
      :icon="'fa fa-user'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingLanguageDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Name</label>
                </div>
                <input
                  v-model="currentLanguage.language.name"
                  placeholder="Name..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentLanguage.language.name.required"
              >
                Name is required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingLanguageDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Code</label>
                </div>
                <input
                  v-model="currentLanguage.language.code"
                  placeholder="Code..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentLanguage.language.code.required"
              >
                Code is required
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalEdit = false">Cancel</button>
        <button class="sf-primary" @click="updateLanguage()">Save</button>
      </div>
    </SfModal>
    <SfDeleteModal :title="'Confirm Delete'" closeOnOverlay :show.sync="isShownModalDelete">
      <div class="content-delete">
        Deleting this language. Are you sure you want to delete this one?
      </div>
      <div class="footer-button">
        <button class="sf-secondary" @click="isShownModalDelete = false">Cancel</button>
        <button class="sf-primary" @click="removeLanguage()">OK</button>
      </div>
    </SfDeleteModal>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import SfModal from '@/components/SfModal.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import AdminLanguageService from '@/services/admin-language.service'
import { required } from 'vuelidate/lib/validators'
import SfInputSearch from '@/components/SfInputSearch.vue'
import { API_URL } from '@/.env'

export default {
  name: 'admin-languages',

  components: {
    SfModal,
    SfDeleteModal,
    SfInputSearch
  },
  data() {
    return {
      API_URL,
      msg: 'Welcome to Index!!!',
      isShownModalCreate: false,
      isShownModalDelete: false,
      isShownModalEdit: false,
      submitted: false,
      currentPage: 1,
      fields: [
        {
          key: 'name',
          sortable: true,
          label: 'Name',
          colType: 'name'
        },
        {
          key: 'code',
          sortable: true,
          label: 'Code',
          colType: 'code'
        },
        {
          key: 'BE',
          colType: 'backend',
          label: 'Backend'
        },
        {
          key: 'FE',
          colType: 'frontend',
          label: 'Frontend'
        },
        {
          key: 'ACTION',
          colType: 'button',
          label: 'Action'
        }
      ],
      newLanguage: {
        language: {
          name: '',
          code: ''
        }
      },
      currentLanguage: {
        language: {
          name: '',
          code: ''
        }
      },
      defaultLanguage: {
        language: {
          name: '',
          code: ''
        }
      },
      dataSearch: '',
      loadingLanguageDetail: false,
      json_data: {
        from: 'From',
        active: 'Active',
        overview: 'Overview',
        library: 'Library',
        loop: 'Loop'
      },
      dataMessage: [['Key', 'Content']]
    }
  },
  validations: {
    newLanguage: {
      language: {
        name: {
          required
        },
        code: {
          required
        }
      }
    },
    currentLanguage: {
      language: {
        name: {
          required
        },
        code: {
          required
        }
      }
    }
  },
  methods: {
    fetchData(activePage, search) {
      this.$store.dispatch('adminlanguage/getAllLanguages', { page: activePage, limit: 10, search })
    },
    showModalCreate() {
      this.submitted = false
      this.setDataLanguage(this.newLanguage.language, this.defaultLanguage.language)
      this.isShownModalCreate = true
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage, this.dataSearch)
    },
    saveLanguage() {
      this.submitted = true
      if (this.$v.newLanguage.$invalid) {
        return
      }
      AdminLanguageService.createLanguage(this.newLanguage)
        .then((response) => {
          this.$toast.success('Successfully created')
          this.isShownModalCreate = false
          this.fetchData(this.currentPage)
        })
        .catch((err) => {
          this.$toast.error(err.message)
          this.isShownModalCreate = false
        })
    },
    shownModalEdit(language_id) {
      this.setDataLanguage(this.currentLanguage.language, this.defaultLanguage.language)
      this.submitted = false
      this.isShownModalEdit = true
      this.currentLanguageID = language_id
      this.getItemByID()
    },
    shownModalDelete(language_id) {
      this.isShownModalDelete = true
      this.currentLanguageID = language_id
    },
    getItemByID() {
      this.loadingLanguageDetail = true
      AdminLanguageService.detailLanguage(this.currentLanguageID)
        .then((response) => {
          this.setDataLanguage(this.currentLanguage.language, response.data)
          this.loadingLanguageDetail = false
          this.currentLanguage.payload = []
        })
        .catch((err) => {
          this.isShownModalEdit = false
          this.$toast.error(err.message)
        })
    },
    setDataLanguage(language, res) {
      language.name = res.name
      language.code = res.code
    },
    updateLanguage() {
      this.submitted = true
      if (this.$v.currentLanguage.$invalid) {
        return
      }
      AdminLanguageService.updateLanguage(this.currentLanguageID, this.currentLanguage)
        .then((response) => {
          this.$toast.success('Language successfully updated')
          this.isShownModalEdit = false
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
          this.isShownModalEdit = false
        })
    },
    removeLanguage() {
      AdminLanguageService.removeLanguage(this.currentLanguageID)
        .then((response) => {
          this.$toast.success('Language successfully deleted')
          if (this.items.length === 1 && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1
          }
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalDelete = false
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    },
    exportLanguageFile(item, type) {
      const objectLanguage = this.$i18n.messages[item.code]
      if (type === 'csv') {
        this.construct_csv_row('', objectLanguage)
        this.sendFile(item.id, this.dataMessage, type)
      } else if (type === 'original') {
        this.sendFile(item.id, JSON.stringify(objectLanguage), type)
      }
    },
    sendFile(id, data, type) {
      AdminLanguageService.createBackupFrontendLanguage(id, {
        language_file: data,
        type: type
      })
        .then((response) => {
          const link = document.createElement('a')
          link.setAttribute('href', response.data.expiring_url)
          link.setAttribute('download', response.data.name)
          link.click()
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong.')
        })
    },
    construct_csv_row(key, value) {
      if (value) {
        if (typeof value === 'string') {
          this.dataMessage.push([key, `"${value}"`])
        } else if (typeof value === 'object') {
          for (const i in value) {
            const newKey = key ? `${key}.${i}` : i
            this.construct_csv_row(newKey, value[i])
          }
        }
      }
    }
  },
  computed: {
    ...mapState('adminlanguage', {
      items: 'items',
      pagination: 'pagination',
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('adminlanguage', ['isEmpty'])
  },

  mounted() {
    this.fetchData(this.currentPage)
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    })
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
  }
}
</script>

<style lang="scss" scoped>
@import '../../Adminpage.scss';
.panel-body {
  border: 2px dashed #888888;
  cursor: pointer;
  padding: 35px;
}
</style>
