<template>
  <SfModal
    :title="$t('modal.title.add-pdf')"
    :width="768"
    :height="0"
    closeOnOverlay
    :show.sync="dataShow"
    @update:show="updateShow"
  >
    <div class="sf-modal-body mb-3">
      <div class="container">
        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('title') }}</label>
              </div>
              <input
                v-model.trim="dataInput.title"
                type="text"
                name="smart_asset"
                id="smart_asset_displayname"
                class="sf-input-text"
                :placeholder="$t('placeholder.title')"
                :class="{ 'input-error-form': submitted && $v.dataInput.title.$invalid }"
              />
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.title.required"
            >
              {{ $t('name-required') }}
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.title.minLength"
            >
              {{ $t('title-minLength-2') }}
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('page-number') }}</label>
              </div>
              <input
                type="number"
                name="property"
                id="property_page_number"
                value="1"
                class="sf-input-text"
                :placeholder="$t('placeholder.page-number')"
                v-model="dataInput.pageNumber"
                :class="{ 'input-error-form': submitted && $v.dataInput.pageNumber.$invalid }"
              />
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.pageNumber.minValue"
            >
              Enter a value greater than 0
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.pageNumber.required"
            >
              Select a page number
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('tags') }}</label>
              </div>
              <vue-tags-input
                class="sf-tag"
                v-model="tag"
                :tags="dataInput.tags"
                @tags-changed="(newTags) => (dataInput.tags = newTags)"
                :placeholder="$t('placeholder.add-tags')"
              />
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('fit') }}</label>
              </div>
              <div class="wrap-checkbox days-of-week">
                <div class="checkbox-item">
                  <label class="label-checkbox">
                    <input
                      type="radio"
                      name="property"
                      id="property_fit_width"
                      value="width"
                      class="value-checkbox hidden"
                      checked="checked"
                      v-model="dataInput.orientationfit"
                    />
                    <span class="label-checkbox">{{ $t('fit-to-width') }}</span>
                  </label>
                </div>
                <div class="checkbox-item">
                  <label class="label-checkbox">
                    <input
                      type="radio"
                      name="property"
                      id="property_fit_height"
                      value="height"
                      class="value-checkbox hidden"
                      v-model="dataInput.orientationfit"
                    />
                    <span class="label-checkbox">{{ $t('fit-to-height') }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('file') }}</label>
              </div>
              <div
                class="group-file"
                :class="{ 'input-error-form': submitted && $v.dataInput.file.$invalid }"
              >
                <input
                  type="file"
                  name="smart_asset_detail"
                  id="smart_asset_detail_asset_file"
                  class="gui-input"
                  accept="application/pdf"
                  @change="handleUploadImage($event)"
                />
              </div>
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.file.required"
            >
              {{ $t('file-invalid-message') }}
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group label-top">
                <label class="primary-label">{{ $t('orientation') }}</label>
              </div>
              <div class="row orientation-type">
                <div class="col-6">
                  <label class="sf-radio"
                    >{{ $t('landscape') }} ({{ $t('default') }})
                    <input
                      checked=""
                      class="sf-radio"
                      id="landscape-radio"
                      name="smart_asset"
                      type="radio"
                      value="landscape"
                      v-model="dataInput.orientation"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-6">
                  <label class="sf-radio"
                    >{{ $t('portrait') }}
                    <input
                      class="sf-radio"
                      id="portrait-radio"
                      name="smart_asset"
                      type="radio"
                      value="portrait"
                      v-model="dataInput.orientation"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-12 mt-3">
                  <div class="row orientation-img-wrap">
                    <div
                      for="landscape-radio"
                      :class="{ active: dataInput.orientation === 'landscape' }"
                      class="col-6 landscape orientation-img"
                    >
                      <img src="~@/assets/images/landscape-review.svg" />
                    </div>
                    <div
                      for="portrait-radio"
                      :class="{ active: dataInput.orientation === 'portrait' }"
                      class="col-6 orientation-img portrait d-flex"
                    >
                      <img src="~@/assets/images/portrait-review.svg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sf-modal-footer">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="sf-modal-action">
              <button class="sf-secondary" type="button" @click="updateShow">
                {{ $t('cancel') }}
              </button>
              <button type="button" class="sf-primary" @click="onClickSave">
                {{ $t('save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SfModal>
</template>

<script>
import SfModal from '@/components/SfModal'
import VueTagsInput from '@johmun/vue-tags-input'
import { isValidPDF } from '@/helper/imageHelper'
import { required, minValue, minLength } from 'vuelidate/lib/validators'
import SmartAssetsService from '@/services/smart-assets.service'
import { uuid } from 'vue-uuid'

export default {
  components: {
    SfModal,
    VueTagsInput
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataShow: this.show,
      tag: '',
      tags: [],
      dataInput: {
        tags: [],
        orientation: 'landscape',
        orientationfit: 'width',
        title: '',
        pageNumber: '',
        file: ''
      },
      submitted: false
    }
  },
  validations: {
    dataInput: {
      title: {
        required,
        minLength: minLength(2)
      },
      pageNumber: {
        minValue: minValue(0),
        required
      },
      file: {
        required
      }
    }
  },
  watch: {
    show(value) {
      this.dataShow = value
    }
  },
  methods: {
    updateShow() {
      this.$emit('update:show', false)
    },
    onClickSave() {
      this.submitted = true
      if (this.$v.dataInput.$invalid) {
        return
      }
      this.$emit('update:isLoading', true)
      // step 1: rename file to uuid name
      const fileExtension = this.dataInput.file.name.split('.').pop()
      const uuidFileName = `${uuid.v1()}.${fileExtension}`
      this.dataInput.file = new File([this.dataInput.file], uuidFileName, {
        type: this.dataInput.file.type
      })
      // step 2: get presigned url s3
      SmartAssetsService.getPresignedS3(this.dataInput.file.type)
        .then(({ data }) => {
          const formData = new FormData()
          Object.entries(data.url_fields).forEach(([key, value]) => {
            formData.append(key, value)
          })
          formData.append('file', this.dataInput.file)
          // step 3: upload file to s3
          SmartAssetsService.uploadFileToS3(data.url, formData)
            .then((data) => {
              this.dataInput.key = data.key
              this.$emit('modal:save', { ...this.dataInput })
            })
            .catch((error) => {
              this.$toast.open({
                message: error.message ? error.message : 'Something went wrong',
                type: 'error'
              })
              this.$emit('update:isLoading', false)
            })
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message ? error.message : 'Something went wrong',
            type: 'error'
          })
          this.$emit('update:isLoading', false)
        })
    },
    handleUploadImage(event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const fileName = input.files[0].name
        if (isValidPDF(fileName)) {
          const reader = new FileReader()
          reader.onload = (e) => {
            this.dataInput.file = input.files[0]
          }
          reader.readAsDataURL(input.files[0])
        } else {
          this.$toast.open({
            message: 'Invalid file type',
            type: 'error'
          })
        }
      }
    },
    resetForm() {
      this.dataInput = {
        tags: [],
        orientation: 'landscape',
        orientationfit: 'width',
        title: '',
        pageNumber: '',
        file: ''
      }
    }
  },
  computed: {},
  created() {
    this.$bus.$on('reset-submitted', (value) => {
      this.submitted = value
      this.resetForm()
    })
  },
  beforeDestroy() {
    this.dataInput = {
      tags: [],
      orientation: 'landscape',
      orientationfit: 'width',
      title: '',
      pageNumber: '',
      file: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import './style-modal-smart-asset';
</style>
<style lang="scss">
.form-control {
  height: auto !important;
  .vue-tags-input {
    margin: -0.375rem -0.75rem;
    border-radius: 0.25rem;
    * {
      border: transparent;
    }
    border: transparent;
    .ti-tag {
      background-color: #e0edf5;
      border-radius: 3px;
      color: #2f5d92;
      float: left;
      font-weight: 600;
      height: 28px;
      line-height: 28px;
      margin: 0 8px 6px 0;
      padding: 0 1px 0 8px;
      white-space: nowrap;
    }
  }
}
</style>
