<template>
  <div class="uikit">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="material-name mt-3">
            <h2>Color</h2>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3>Brand</h3>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <div class="color-brand-box brand-color-1"></div>
          <div class="color-hex text-brand-color-1">#0F52EF</div>
        </div>
        <div class="col-4">
          <div class="color-brand-box brand-color-2"></div>
          <div class="color-hex text-brand-color-2">#43EB7E</div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3>App</h3>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-2">
          <div class="color-app-box color-primary-90"></div>
          <div class="color-hex text-color-primary-90">#193880</div>
        </div>
        <div class="col-2">
          <div class="color-app-box color-primary-80"></div>
          <div class="color-hex text-color-primary-80">#126CDB</div>
        </div>
        <div class="col-2">
          <div class="color-app-box color-primary-70"></div>
          <div class="color-hex text-color-primary-70">#4599FF</div>
        </div>
        <div class="col-2">
          <div class="color-app-box color-primary-30"></div>
          <div class="color-hex text-color-primary-30">#A5CDFF</div>
        </div>
        <div class="col-2">
          <div class="color-app-box color-primary-20"></div>
          <div class="color-hex text-color-primary-20">#CCE3FF</div>
        </div>
        <div class="col-2">
          <div class="color-app-box color-primary-10"></div>
          <div class="color-hex text-color-primary-10">#E6F1FF</div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-2">
          <div class="color-app-box color-accent-90"></div>
          <div class="color-hex text-color-accent-90">#0F5E39</div>
        </div>
        <div class="col-2">
          <div class="color-app-box color-accent-80"></div>
          <div class="color-hex text-color-accent-80">#008748</div>
        </div>
        <div class="col-2">
          <div class="color-app-box color-accent-70"></div>
          <div class="color-hex text-color-accent-70">#12A661</div>
        </div>
        <div class="col-2">
          <div class="color-app-box color-accent-30"></div>
          <div class="color-hex text-color-accent-30">#5BC996</div>
        </div>
        <div class="col-2">
          <div class="color-app-box color-neutral"></div>
          <div class="color-hex text-color-neutral">#F1F1F3</div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3>Greys</h3>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-2">
          <div class="color-app-box color-grey-100"></div>
          <div class="color-hex text-color-grey-100">#223144</div>
        </div>
        <div class="col-2">
          <div class="color-app-box color-grey-70"></div>
          <div class="color-hex text-color-grey-70">#6C7785</div>
        </div>
        <div class="col-2">
          <div class="color-app-box color-grey-40"></div>
          <div class="color-hex text-color-grey-40">#9DABBD</div>
        </div>
        <div class="col-2">
          <div class="color-app-box color-grey-15"></div>
          <div class="color-hex text-color-grey-15">#D8DDE3</div>
        </div>
        <div class="col-2">
          <div class="color-app-box color-white fs-border-default"></div>
          <div class="color-hex">#FFFFFF</div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3>Statuses</h3>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-2">
          <div class="color-bar color-traffic-red-text"></div>
          <div class="color-hex text-color-traffic-red-text">#D62222</div>
        </div>
        <div class="col-2">
          <div class="color-bar color-traffic-amber-text"></div>
          <div class="color-hex text-color-traffic-amber-text">#966E00</div>
        </div>
        <div class="col-2">
          <div class="color-bar color-traffic-green-text"></div>
          <div class="color-hex text-color-traffic-green-text">#038A18</div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-2">
          <div class="color-bar color-traffic-red"></div>
          <div class="color-hex text-color-traffic-red">#FA0505</div>
        </div>
        <div class="col-2">
          <div class="color-bar color-traffic-amber"></div>
          <div class="color-hex text-color-traffic-amber">#FFBB00</div>
        </div>
        <div class="col-2">
          <div class="color-bar color-traffic-green"></div>
          <div class="color-hex text-color-traffic-green">#02FF2C</div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <h3>Alerts</h3>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-2">
          <div class="color-bar color-alert-danger-text"></div>
          <div class="color-hex text-color-alert-danger-text">#721C23</div>
        </div>
        <div class="col-2">
          <div class="color-bar color-alert-warning-text"></div>
          <div class="color-hex text-color-alert-warning-text">#3D3700</div>
        </div>
        <div class="col-2">
          <div class="color-bar color-alert-success-text"></div>
          <div class="color-hex text-color-alert-success-text">#155724</div>
        </div>
        <div class="col-2">
          <div class="color-bar color-alert-info-text"></div>
          <div class="color-hex text-color-alert-info-text">#004085</div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-2">
          <div class="color-bar color-alert-danger-bg"></div>
          <div class="color-hex text-color-alert-danger-bg">#FFD0E1</div>
        </div>
        <div class="col-2">
          <div class="color-bar color-alert-warning-bg"></div>
          <div class="color-hex text-color-alert-warning-bg">#FFE7C8</div>
        </div>
        <div class="col-2">
          <div class="color-bar color-alert-success-bg"></div>
          <div class="color-hex text-color-alert-success-bg">#9EE1C8</div>
        </div>
        <div class="col-2">
          <div class="color-bar color-alert-info-bg"></div>
          <div class="color-hex text-color-alert-info-bg">#9DD6FF</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'color',
  components: {},
  data() {
    return {}
  },
  created() {},
  methods: {},
  mounted() {}
}
</script>
