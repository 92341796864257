import CampaignService from '../../../services/campaign.service'
import { getExceptionDays } from '../../../helper/helper'
import LoopService from '../../../services/loop.service'
import router from '@/router'
import Vue from 'vue'
import { EventBus } from '@/event-bus.js'
import i18n from '../../../i18n'
import { textWithoutNonBreakingSpaceEntities } from '@/helper/helper'

export default {
  getAllCampaign({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return CampaignService.getAllCampaigns(payload.activePage, payload.limit, payload.search)
      .then((response) => {
        commit('SET_ITEMS', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING', false))
  },
  getCampaignByID({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return CampaignService.getCampaignByID(payload)
      .then((response) => {
        response.data.list_of_exeption.map((exception) => {
          exception.repeat_info_render = getExceptionDays(exception.repeat_info)
          exception.isRotated = false
          for (const key in exception) {
            if (exception[key] === null && key !== 'title') {
              exception[key] = 0
            }
          }
        })
        for (let i = 0; i < response.data.list_of_exeption.length; i++) {
          response.data.list_of_exeption[i].position = i
        }
        commit('SET_ITEM_ID', response.data)
        commit('SET_EMPTY', false)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        router.push('/campaigns')
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING', false))
  },
  getAllLoops({ state, commit, rootState }, payload) {
    return LoopService.getAllLoops(payload)
      .then((response) => {
        const data = [{ id: 0, text: `-- ${i18n.t('prompt-select.loop')} --` }]
        if (response.data.length > 0) {
          response.data.map((exception) => {
            data.push({
              id: exception.id,
              text: textWithoutNonBreakingSpaceEntities(exception.displayname),
              orientation: exception.orientation
            })
          })
        }
        commit('SET_ITEMS_LOOP', data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING', false))
  },
  addLoopItems({ state, commit, rootState }, payload) {
    commit('PUSH_ITEM_TO_LOOP', payload)
  },

  removeLoop({ state, commit, rootState }, payload) {
    commit('REMOVE_LOOP', payload)
  },

  chooseLoop({ state, commit, rootState }, payload) {
    commit('CHOOSE_LOOP', payload)
  },

  chooseAddLoop({ state, commit, rootState }, payload) {
    commit('CHOOSE_ADD_LOOP', payload)
  },

  chooseAddFirstLoop({ state, commit, rootState }, payload) {
    commit('CHOOSE_ADD_FIRST_LOOP', payload)
  },
  addMoreException({ state, commit, rootState }, payload) {
    // parseDaysToRepeatInfo(payload.repeat_info)
    payload.repeat_info = parseInt(payload.repeat_info, 2)
    commit('ADD_MORE_EXCEPTION', payload)
  },

  cloneException({ state, commit, rootState }, payload) {
    // parseDaysToRepeatInfo(payload.repeat_info)
    commit('ADD_MORE_EXCEPTION', payload)
  },

  updateException({ state, commit, rootState }, payload) {
    // parseDaysToRepeatInfo(payload.repeat_info)
    payload.repeat_info = parseInt(payload.repeat_info, 2)
    payload.repeat_info_render = getExceptionDays(payload.repeat_info)
    commit('UPDATE_EXCEPTION', payload)
  },

  editCampaignByID({ state, commit, rootState }, payload) {
    if (payload.data.campaign.hooks_json_string === '[]') {
      Vue.$toast.error('Campaigns require at least one main loop')
      return
    }
    return CampaignService.editCampaignByID(payload.data, payload.campaign_id)
      .then((_) => {
        Vue.$toast.success('Campaign successfully updated')
        router.push('/campaigns')
      })
      .catch((error) => {
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
  },

  removeException({ state, commit, rootState }, payload) {
    commit('REMOVE_EXCEPTION', payload)
  },

  changeRotated({ state, commit, rootState }, payload) {
    commit('CHANGE_ROTATED', payload)
  },

  updateTitle({ state, commit, rootState }, payload) {
    commit('UPDATE_TITLE', payload)
  },

  addNewCampaign({ state, commit, rootState }, payload) {
    return CampaignService.addNewCampaign(payload)
      .then((response) => {
        router.push(`/campaigns/${response.data.campaign_code}/edit`)
        Vue.$toast.success('Campaign successfully created.')
      })
      .catch((error) => {
        Vue.$toast.error(error.message)
      })
      .catch((error) => {
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
        EventBus.$emit('turn_off_modal', false)
      })
  },

  updateDisplayname({ state, commit, rootState }, payload) {
    return CampaignService.updateName(payload.displayname, payload.id)
      .then((res) => {
        Vue.$toast.success('Campaign successfully updated')
        commit('UPDATE_DISPLAYNAME', res.data.displayname)
      })
      .catch((error) => {
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
  },
  checkMove({ state, commit, rootState }, payload) {
    commit('UPDATE_POSITION', payload)
  },
  setData({ state, commit, rootState }, payload) {
    commit('UPDATE_DATA', payload)
  },
  updateCampaignFormSocket({ state, commit, rootState }, payload) {
    commit('SET_ITEM_ID', payload)
  },

  getAllCampaignChildren({ commit }, payload) {
    commit('SET_LOADING_DATA_CAMPAIGN_CHILDREN', true)
    return CampaignService.getAllCampaignChildren(payload).then((res) => {
      commit('SET_LIST_CAMPAIGN_CHILDRED', res.data)
      commit('SET_LOADING_DATA_CAMPAIGN_CHILDREN', false)
    })
  },

  cloneCampaignByID({ state, commit, rootState }, payload) {
    return CampaignService.cloneCampaignByID(payload)
      .then((_) => {
        Vue.$toast.success('Campaign successfully cloned')
        if (router.currentRoute.path !== '/campaigns') {
          router.push('/campaigns')
        }
      })
      .catch((error) => {
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
  },

  updateCampaignIds({ state, commit, rootState }, payload) {
    commit('SET_EDITABLE_CAMPAIGN_IDS', payload)
  },

  resetCampaignIds({ state, commit, rootState }, payload) {
    commit('RESET_EDITABLE_CAMPAIGN_IDS', payload)
  }
}
