import { mathRound10 } from '../../../helper/mathHelper'
const instagramStorage = localStorage.getItem('instagram')
let isPortrait = false
if (instagramStorage) {
  const instagram = JSON.parse(instagramStorage)
  const orientation = instagram.orientation
  if (orientation === 'portrait') {
    isPortrait = true
  }
}
const defaultPhoto = {
  visitable: true,
  x: 0,
  y: 0,
  w: isPortrait ? 270 : 360,
  h: isPortrait ? 270 : 360
}
const defaultProfilePic = {
  visitable: true,
  x: isPortrait ? 10 : 400,
  y: isPortrait ? 280 : 20,
  w: 50,
  h: 50
}
const defaultTitleIg = {
  visitable: true,
  x: isPortrait ? 80 : 400,
  y: isPortrait ? 280 : 100,
  w: isPortrait ? 140 : 160,
  h: isPortrait ? 30 : 40,
  textAttr: {
    textAlign: 'left',
    alignItems: 'flex-start',
    textColor: '#212529',
    backgroundColor: '#ffffff00',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14
  }
}

const defaultTextIg = {
  visitable: true,
  x: isPortrait ? 10 : 400,
  y: isPortrait ? 340 : 160,
  w: 220,
  h: 50,
  textAttr: {
    textAlign: 'left',
    alignItems: 'flex-start',
    textColor: '#212529',
    backgroundColor: '#ffffff00',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14
  },
  mentions: {
    textColor: '#2c7fe1',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14
  },
  hashtags: {
    textColor: '#2c7fe1',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14
  }
}

const defaultTagsIg = {
  visitable: false,
  x: isPortrait ? 10 : 400,
  y: isPortrait ? 400 : 240,
  w: 220,
  h: 50,
  textAttr: {
    textAlign: 'left',
    alignItems: 'flex-start',
    textColor: '#212529',
    backgroundColor: '#ffffff00',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14
  },
  mentions: {
    textColor: '#2c7fe1',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14
  },
  hashtags: {
    textColor: '#2c7fe1',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14
  }
}
const defaultBgEditor = {
  color: '#ffffff',
  bgImage: {
    imageData: 'none',
    file: 'delete',
    templateID: ''
  }
}

const defaultLogo = {
  imageData: 'none',
  file: 'delete',
  templateID: ''
}

const resetPhoto = {
  visitable: true,
  x: 0,
  y: 0,
  w: isPortrait ? 270 : 360,
  h: isPortrait ? 270 : 360
}
const resetProfilePic = {
  visitable: true,
  x: isPortrait ? 10 : 400,
  y: isPortrait ? 280 : 20,
  w: 50,
  h: 50
}
const resetTitleIg = {
  visitable: true,
  x: isPortrait ? 80 : 400,
  y: isPortrait ? 280 : 100,
  w: isPortrait ? 140 : 160,
  h: isPortrait ? 30 : 40,
  textAttr: {
    textAlign: 'left',
    alignItems: 'flex-start',
    textColor: '#212529',
    backgroundColor: '#ffffff00',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14
  }
}

const resetTextIg = {
  visitable: true,
  x: isPortrait ? 10 : 400,
  y: isPortrait ? 340 : 160,
  w: 220,
  h: 50,
  textAttr: {
    textAlign: 'left',
    alignItems: 'flex-start',
    textColor: '#212529',
    backgroundColor: '#ffffff00',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14
  },
  mentions: {
    textColor: '#2c7fe1',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14
  },
  hashtags: {
    textColor: '#2c7fe1',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14
  }
}

const resetTagsIg = {
  visitable: false,
  x: isPortrait ? 10 : 400,
  y: isPortrait ? 400 : 240,
  w: 220,
  h: 50,
  textAttr: {
    textAlign: 'left',
    alignItems: 'flex-start',
    textColor: '#212529',
    backgroundColor: '#ffffff00',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14
  },
  mentions: {
    textColor: '#2c7fe1',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14
  },
  hashtags: {
    textColor: '#2c7fe1',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14
  }
}

const resetBgEditor = {
  color: '#ffffff',
  bgImage: {
    imageData: 'none',
    file: 'delete'
  }
}
const resetLogo = {
  imageData: 'none',
  file: 'delete'
}

const updateIgProperty = (propName, value) => {
  if (value.x || value.x === 0) propName.x = value.x
  if (value.y || value.y === 0) propName.y = value.y
  if (value.w || value.w === 0) propName.w = value.w
  if (value.h || value.h === 0) propName.h = value.h
  propName.visitable = value.visitable
  if (value.textAttr) {
    propName.textAttr.textAlign = value.textAttr.textAlign
    propName.textAttr.textColor = value.textAttr.textColor
    propName.textAttr.backgroundColor = value.textAttr.backgroundColor
    propName.textAttr.fontFamily = value.textAttr.fontFamily
    propName.textAttr.fontWeight = value.textAttr.fontWeight
    propName.textAttr.alignItems = value.textAttr.alignItems
    propName.textAttr.fontSize = value.textAttr.fontSize
  }
  if (value.mentions) {
    propName.mentions.textAlign = value.mentions.textAlign
    propName.mentions.textColor = value.mentions.textColor
    propName.mentions.backgroundColor = value.mentions.backgroundColor
    propName.mentions.fontFamily = value.mentions.fontFamily
    propName.mentions.fontWeight = value.mentions.fontWeight
    propName.mentions.alignItems = value.mentions.alignItems
    propName.mentions.fontSize = value.mentions.fontSize
  }
  if (value.hashtags) {
    propName.hashtags.textAlign = value.hashtags.textAlign
    propName.hashtags.textColor = value.hashtags.textColor
    propName.hashtags.backgroundColor = value.hashtags.backgroundColor
    propName.hashtags.fontFamily = value.hashtags.fontFamily
    propName.hashtags.fontWeight = value.hashtags.fontWeight
    propName.hashtags.alignItems = value.hashtags.alignItems
    propName.hashtags.fontSize = value.hashtags.fontSize
  }
}
const updateBgEditor = (propName, value) => {
  propName.color = value.color
  propName.bgImage = {
    ...propName.bgImage,
    imageData: value.bgImage.imageData,
    file: value.bgImage.file,
    templateID: value.bgImage.templateID
  }
}

const updateLogo = (propName, value) => {
  propName.file = value.file
  propName.imageData = value.imageData
  propName.templateID = value.templateID
}

const state = {
  isAuthentication: false,
  listIG: [],
  currentPost: 0,
  isFirstSlide: true,
  isLastSlide: false,
  templateID: '',
  backgroundExisting: false,
  logoExisting: false,
  templateType: 'smart_asset',
  templateAction: true,
  photoSize: {
    isTall: false,
    isWide: false,
    isSquare: false
  },
  toolBar: {
    overall: false,
    widget: false,
    edit: true,
    background: false
  },
  activeAlignItem: {
    top: true,
    middle: false,
    bottom: false
  },
  activeTextAlign: {
    left: true,
    right: false,
    center: false,
    justify: false
  },
  photo: {
    visitable: true,
    x: 0,
    y: 0,
    w: isPortrait ? 270 : 360,
    h: isPortrait ? 270 : 360,
    imageURL: ''
  },
  profilePic: {
    visitable: true,
    x: isPortrait ? 10 : 400,
    y: isPortrait ? 280 : 20,
    w: 50,
    h: 50,
    imageURL: ''
  },
  titleIg: {
    visitable: true,
    x: isPortrait ? 80 : 400,
    y: isPortrait ? 280 : 100,
    w: isPortrait ? 140 : 160,
    h: isPortrait ? 30 : 40,
    textAttr: {
      textAlign: 'left',
      alignItems: 'flex-start',
      textColor: '#212529',
      backgroundColor: '#ffffff00',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14
    },
    text: ''
  },
  textIg: {
    visitable: true,
    x: isPortrait ? 10 : 400,
    y: isPortrait ? 340 : 160,
    w: 220,
    h: 50,
    textAttr: {
      textAlign: 'left',
      alignItems: 'flex-start',
      textColor: '#212529',
      backgroundColor: '#ffffff00',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14
    },
    mentions: {
      textColor: '#2c7fe1',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14
    },
    hashtags: {
      textColor: '#2c7fe1',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14
    },
    text: ''
  },
  tagsIg: {
    visitable: false,
    x: isPortrait ? 10 : 400,
    y: isPortrait ? 400 : 240,
    w: 220,
    h: 50,
    textAttr: {
      textAlign: 'left',
      alignItems: 'flex-start',
      textColor: '#212529',
      backgroundColor: '#ffffff00',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14
    },
    mentions: {
      textColor: '#2c7fe1',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14
    },
    hashtags: {
      textColor: '#2c7fe1',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14
    },
    text: ''
  },
  bgEditor: {
    color: '#ffffff',
    bgImage: {
      imageData: '',
      file: '',
      templateID: ''
    }
  },
  logo: {
    imageData: '',
    file: '',
    templateID: ''
  },
  mask: {},
  elementActive: {
    photo: true,
    profilePic: false,
    titleIg: false,
    textIg: false,
    tagsIg: false
  },
  gridDrag: [1, 1],
  snap: false,
  instagramInfo: {},
  instagramData: {},
  numberData: {
    numberOfSlide: 5,
    durationPerSlide: 10
  },
  templateName: ''
}

const stateHistory = []
let step = 0
stateHistory.push(JSON.stringify(state))
export default {
  SET_IG_MODE(state, payload) {
    state.photo.visitable = true
    state.photo.visitable = true
    if (payload.orientation === 'portrait') {
      state.photo.x = 0
      state.photo.y = 0
      state.photo.w = 270
      state.photo.h = 270

      state.profilePic.x = 10
      state.profilePic.y = 280

      state.titleIg.x = 80
      state.titleIg.y = 280
      state.titleIg.w = 140
      state.titleIg.h = 30

      state.textIg.x = 10
      state.textIg.y = 340
    } else {
      state.photo.x = 0
      state.photo.y = 0
      state.photo.w = 360
      state.photo.h = 360

      state.profilePic.x = 400
      state.profilePic.y = 20

      state.titleIg.x = 400
      state.titleIg.y = 100
      state.titleIg.w = 160
      state.titleIg.h = 40

      state.textIg.x = 400
      state.textIg.y = 160
    }
  },
  PUSH_TO_HISTORY(state) {
    const strState = JSON.stringify(state)
    step++
    if (step < stateHistory.length) {
      stateHistory.length = step
    }
    stateHistory.push(strState)
  },
  UNDO(state) {
    if (step > 0) {
      step--
      const strState = stateHistory[step]
      if (strState) {
        const undostate = JSON.parse(strState)
        updateIgProperty(state.profilePic, undostate.profilePic)
        updateIgProperty(state.photo, undostate.photo)
        updateIgProperty(state.titleIg, undostate.titleIg)
        updateIgProperty(state.textIg, undostate.textIg)
        updateIgProperty(state.tagsIg, undostate.tagsIg)
        updateBgEditor(state.bgEditor, undostate.bgEditor)
        updateLogo(state.logo, undostate.logo)
      }
    }
  },
  REDO(state) {
    if (step < stateHistory.length - 1) {
      step++
      const strState = stateHistory[step]
      if (strState) {
        const redostate = JSON.parse(strState)
        updateIgProperty(state.profilePic, redostate.profilePic)
        updateIgProperty(state.photo, redostate.photo)
        updateIgProperty(state.titleIg, redostate.titleIg)
        updateIgProperty(state.textIg, redostate.textIg)
        updateIgProperty(state.tagsIg, redostate.tagsIg)
        updateBgEditor(state.bgEditor, redostate.bgEditor)
        updateLogo(state.logo, redostate.logo)
      }
    }
  },
  SET_ACTIVE_ELEMENT(state, payload) {
    for (const item in state.elementActive) {
      if (item === payload) {
        state.elementActive[item] = true
      } else {
        state.elementActive[item] = false
      }
    }
  },
  LOAD_ALIGN_ITEM(state, payload) {
    const alignItems = state[payload].textAttr ? state[payload].textAttr.alignItems : ''
    if (alignItems) {
      switch (alignItems) {
        case 'center':
          state.activeAlignItem.top = false
          state.activeAlignItem.middle = true
          state.activeAlignItem.bottom = false
          break
        case 'flex-end':
          state.activeAlignItem.top = false
          state.activeAlignItem.middle = false
          state.activeAlignItem.bottom = true
          break

        default:
          state.activeAlignItem.top = true
          state.activeAlignItem.middle = false
          state.activeAlignItem.bottom = false
          break
      }
    }
  },
  LOAD_TEXT_ALIGN(state, payload) {
    const textAlign = state[payload].textAttr ? state[payload].textAttr.textAlign : ''
    if (textAlign) {
      for (const item in state.activeTextAlign) {
        if (item === textAlign) {
          state.activeTextAlign[item] = true
        } else {
          state.activeTextAlign[item] = false
        }
      }
    }
  },
  SET_ORIGIN_X(state, payload) {
    for (const item in state.elementActive) {
      if (state.elementActive[item]) {
        state[item].x = payload
      }
    }
  },
  SET_ORIGIN_Y(state, payload) {
    for (const item in state.elementActive) {
      if (state.elementActive[item]) {
        state[item].y = payload
      }
    }
  },
  SET_DIMENTIONS_HEIGHT(state, payload) {
    for (const item in state.elementActive) {
      if (state.elementActive[item]) {
        state[item].h = payload
      }
    }
  },
  SET_DIMENTIONS_WIDTH(state, payload) {
    for (const item in state.elementActive) {
      if (state.elementActive[item]) {
        state[item].w = payload
      }
    }
  },
  UPDATE_ATTR_EL(state, payload) {
    for (const item in state.elementActive) {
      if (state.elementActive[item]) {
        state[item].x = payload.x
        state[item].y = payload.y
        state[item].w = payload.w
        state[item].h = payload.h
      }
    }
  },
  UPDATE_ALL_ATTR_EL(state, payload) {
    for (const item in state.elementActive) {
      state[item].x = payload.x
      state[item].y = payload.y
      state[item].w = payload.w
      state[item].h = payload.h
    }
  },
  UPDATE_BG_COLOR(state, payload) {
    state.bgEditor.color = payload
  },
  LOAD_INSTAGRAM(state, payload) {
    state.instagramData = payload
    state.isAuthentication = true
    // let imageWidth = payload.images.standard_resolution.width
    // let imageHeight = payload.images.standard_resolution.height
    // if (imageWidth === imageHeight) {
    //   state.photoSize.isSquare = true
    //   state.photoSize.isWide = false
    //   state.photoSize.isTall = false
    // }
    // if (imageWidth > imageHeight) {
    //   state.photoSize.isWide = true
    //   state.photoSize.isSquare = false
    //   state.photoSize.isTall = false
    // }
    // if (imageWidth === imageHeight) {
    //   state.photoSize.isSquare = true
    // }
    // if (imageWidth < imageHeight) {
    //   state.photoSize.isTall = true
    //   state.photoSize.isWide = false
    //   state.photoSize.isSquare = false
    // }
  },
  SET_TEXT_ALIGN(state, payload) {
    for (const item in state.elementActive) {
      if (state.elementActive[item] === true) {
        state[item].textAttr.textAlign = payload
      }
    }
  },
  SET_TEXT_ALIGN_ITEMS(state, payload) {
    for (const item in state.elementActive) {
      if (state.elementActive[item] === true) {
        if (payload === 'top') {
          state[item].textAttr.alignItems = 'flex-start'
        }
        if (payload === 'middle') {
          state[item].textAttr.alignItems = 'center'
        }
        if (payload === 'bottom') {
          state[item].textAttr.alignItems = 'flex-end'
        }
      }
    }
  },

  SET_FONT_FAMILY(state, payload) {
    for (const item in state.elementActive) {
      if (state.elementActive[item] === true) {
        if (payload.type === 'mentions') {
          state[item].mentions.fontFamily = payload.value
        } else if (payload.type === 'hashtags') {
          state[item].hashtags.fontFamily = payload.value
        } else {
          state[item].textAttr.fontFamily = payload.value
        }
      }
    }
  },
  SET_FONT_SIZE(state, payload) {
    for (const item in state.elementActive) {
      if (state.elementActive[item] === true) {
        if (payload.type === 'mentions') {
          state[item].mentions.fontSize = payload.value
        } else if (payload.type === 'hashtags') {
          state[item].hashtags.fontSize = payload.value
        } else {
          state[item].textAttr.fontSize = payload.value
        }
      }
    }
  },
  SET_FONT_STYLE(state, payload) {
    for (const item in state.elementActive) {
      if (state.elementActive[item] === true) {
        if (payload.type === 'mentions') {
          state[item].mentions.fontWeight = payload.value
        } else if (payload.type === 'hashtags') {
          state[item].hashtags.fontWeight = payload.value
        } else {
          state[item].textAttr.fontWeight = payload.value
        }
      }
    }
  },

  SET_TEXT_BG_COLOR(state, payload) {
    for (const item in state.elementActive) {
      if (state.elementActive[item] === true) {
        state[item].textAttr.backgroundColor = payload
      }
    }
  },
  SET_TEXT_COLOR(state, payload) {
    for (const item in state.elementActive) {
      if (state.elementActive[item] === true) {
        if (payload.type === 'mentions') {
          state[item].mentions.textColor = payload.value
        }
        if (payload.type === 'hashtags') {
          state[item].hashtags.textColor = payload.value
        }
        if (payload.type === 'text') {
          state[item].textAttr.textColor = payload.value
        }
      }
    }
  },

  SET_IG_TOKEN(state, payload) {
    state.instagramInfo = payload
    state.isAuthentication = true
  },
  SET_DURATION_PER_SLIDE(state, payload) {
    state.numberData.durationPerSlide = payload
  },
  SET_NUMBER_OF_SLIDE(state, payload) {
    state.numberData.numberOfSlide = payload
  },
  ACTIVE_SNAP(state, payload) {
    state.profilePic.w = mathRound10(state.profilePic.w, 1)
    state.profilePic.h = mathRound10(state.profilePic.h, 1)
    state.profilePic.x = mathRound10(state.profilePic.x, 1)
    state.profilePic.y = mathRound10(state.profilePic.y, 1)

    state.photo.w = mathRound10(state.photo.w, 1)
    state.photo.h = mathRound10(state.photo.h, 1)
    state.photo.x = mathRound10(state.photo.x, 1)
    state.photo.y = mathRound10(state.photo.y, 1)

    state.titleIg.w = mathRound10(state.titleIg.w, 1)
    state.titleIg.h = mathRound10(state.titleIg.h, 1)
    state.titleIg.x = mathRound10(state.titleIg.x, 1)
    state.titleIg.y = mathRound10(state.titleIg.y, 1)

    state.textIg.w = mathRound10(state.textIg.w, 1)
    state.textIg.h = mathRound10(state.textIg.h, 1)
    state.textIg.x = mathRound10(state.textIg.x, 1)
    state.textIg.y = mathRound10(state.textIg.y, 1)

    state.tagsIg.w = mathRound10(state.tagsIg.w, 1)
    state.tagsIg.h = mathRound10(state.tagsIg.h, 1)
    state.tagsIg.x = mathRound10(state.tagsIg.x, 1)
    state.tagsIg.y = mathRound10(state.tagsIg.y, 1)

    setTimeout(function () {
      if (payload === true) {
        state.gridDrag = [10, 10]
        state.snap = true
      } else {
        state.gridDrag = [1, 1]
        state.snap = false
      }
    }, 100)
  },
  SET_BG_IMAGE(state, payload) {
    state.bgEditor.bgImage = payload
  },
  REMOVE_BG_IMAGE(state) {
    if (state.backgroundExisting) {
      state.bgEditor.bgImage = {
        imageData: 'none',
        file: 'delete'
      }
    } else {
      state.bgEditor.bgImage = {}
    }
  },
  REMOVE_LOGO_IMAGE(state) {
    if (state.logoExisting) {
      state.logo = {
        imageData: 'none',
        file: 'delete'
      }
    } else {
      state.logo = {}
    }
  },
  SET_LOGO_IMAGE(state, payload) {
    state.logo = payload
  },

  REMOVE_MASK_IMAGE(state) {
    state.mask = {}
  },
  SET_MASK_IMAGE(state, payload) {
    state.mask = payload
  },
  SET_TEMPLATE_NAME(state, payload) {
    state.templateName = payload
  },
  SET_TEMPLATE_ID(state, payload) {
    state.templateID = payload
  },
  SET_TEMPLATE_ACTION(state, payload) {
    state.templateAction = payload
  },
  DEFAULT_SETTING_IG(state) {
    updateIgProperty(state.profilePic, defaultProfilePic)
    updateIgProperty(state.photo, defaultPhoto)
    updateIgProperty(state.titleIg, defaultTitleIg)
    updateIgProperty(state.textIg, defaultTextIg)
    updateIgProperty(state.tagsIg, defaultTagsIg)
    updateBgEditor(state.bgEditor, defaultBgEditor)
    updateLogo(state.logo, defaultLogo)
  },
  UPDATE_BG_IMG_STATUS(state, payload) {
    state.backgroundExisting = payload
  },
  UPDATE_LOGO_IMG_STATUS(state, payload) {
    state.logoExisting = payload
  },
  RESET_TEXT_ATTR(state) {
    for (const item in state.elementActive) {
      if (state.elementActive[item] === true) {
        switch (item) {
          case 'titleIg':
            updateIgProperty(state.titleIg, defaultTitleIg)
            break
          case 'textIg':
            updateIgProperty(state.textIg, defaultTextIg)
            break
          case 'tagsIg':
            updateIgProperty(state.tagsIg, defaultTagsIg)
            break

          default:
            break
        }
      }
    }
  },
  LOAD_IG_TEMPLATE(state, payload) {
    const postCount = payload.smart_asset.properties.image_count
    const duration = payload.smart_asset.properties.period
    state.numberData.numberOfSlide = postCount ? Number(postCount) : 5
    state.numberData.durationPerSlide = duration ? Number(duration) : 10
    updateIgProperty(state.profilePic, payload.smart_asset.properties.avtart_photo)
    updateIgProperty(state.photo, payload.smart_asset.properties.main_photo)
    updateIgProperty(state.titleIg, payload.smart_asset.properties.username)
    updateIgProperty(state.textIg, payload.smart_asset.properties.caption)
    updateIgProperty(state.tagsIg, payload.smart_asset.properties.tags)
    const smartAsset = payload.smart_asset
    if (smartAsset) {
      const smartAssetID = smartAsset.id
      if (smartAssetID) {
        state.smartAssetTemplateID = smartAssetID
      }
    }
    const color = payload.smart_asset.properties.background_color
    const templateID = payload.id

    const templateName = payload.templateName
    const background_image = payload.smart_asset.origin_link

    const logo = payload.smart_asset.logo
    state.backgroundExisting = !!background_image
    state.logoExisting = !!logo
    const bgObj = {
      color: color,
      bgImage: {
        imageData: background_image,
        file: ''
      }
    }

    const logoObj = {
      imageData: logo,
      file: ''
    }
    updateBgEditor(state.bgEditor, bgObj)
    updateLogo(state.logo, logoObj)
    if (templateID) {
      bgObj.bgImage.templateID = templateID
      if (background_image) {
        bgObj.bgImage.file = 'usedTemplate'
      } else {
        bgObj.bgImage.file = 'delete'
      }
      state.templateName = templateName
      state.templateID = templateID
      state.templateType = 'template'
      logoObj.templateID = templateID
      if (logo) {
        logoObj.file = 'usedTemplate'
      } else {
        logoObj.file = 'delete'
      }
    } else {
      state.templateID = smartAsset.id
      state.templateType = 'smart_asset'
    }

    // state.bgEditor.color = payload.properties.background_color;
    // state.bgEditor.bgImage.imageData = payload.properties.background_image;
  },
  LOAD_IG_PROPERTIES(state, payload) {
    const postCount = payload.properties.image_count
    const duration = payload.properties.period
    state.numberData.numberOfSlide = postCount ? Number(postCount) : 5
    state.numberData.durationPerSlide = duration ? Number(duration) : 10
    updateIgProperty(state.profilePic, payload.properties.avtart_photo)
    updateIgProperty(state.photo, payload.properties.main_photo)
    updateIgProperty(state.titleIg, payload.properties.username)
    updateIgProperty(state.textIg, payload.properties.caption)
    updateIgProperty(state.tagsIg, payload.properties.tags)
    const color = payload.properties.background_color
    const origin_link = payload.origin_link
    const logo = payload.logo
    const bgObj = {
      color: color,
      bgImage: {
        imageData: origin_link,
        file: ''
      }
    }

    const logoObj = {
      imageData: logo,
      file: ''
    }
    updateLogo(state.logo, logoObj)
    updateBgEditor(state.bgEditor, bgObj)

    const background_image = payload.origin_link
    state.backgroundExisting = !!background_image
    state.logoExisting = !!logo
  },
  RESET_SETTING_IG(state) {
    updateIgProperty(state.profilePic, resetProfilePic)
    updateIgProperty(state.photo, resetPhoto)
    updateIgProperty(state.titleIg, resetTitleIg)
    updateIgProperty(state.textIg, resetTextIg)
    updateIgProperty(state.tagsIg, resetTagsIg)
    updateBgEditor(state.bgEditor, resetBgEditor)
    updateLogo(state.logo, resetLogo)
  },
  SET_DEFAULT_EDIT(payload) {
    resetPhoto.x = payload.photo.x
    resetPhoto.y = payload.photo.y
    resetPhoto.w = payload.photo.w
    resetPhoto.h = payload.photo.h

    resetProfilePic.x = payload.profilePic.x
    resetProfilePic.y = payload.profilePic.y
    resetProfilePic.w = payload.profilePic.w
    resetProfilePic.h = payload.profilePic.h

    resetTitleIg.x = payload.titleIg.x
    resetTitleIg.y = payload.titleIg.y
    resetTitleIg.w = payload.titleIg.w
    resetTitleIg.h = payload.titleIg.h
    resetTitleIg.textAttr.backgroundColor = payload.titleIg.textAttr.backgroundColor
    resetTitleIg.textAttr.fontFamily = payload.titleIg.textAttr.fontFamily
    resetTitleIg.textAttr.fontWeight = payload.titleIg.textAttr.fontWeight
    resetTitleIg.textAttr.textAlign = payload.titleIg.textAttr.textAlign
    resetTitleIg.textAttr.textColor = payload.titleIg.textAttr.textColor

    resetTextIg.x = payload.textIg.x
    resetTextIg.y = payload.textIg.y
    resetTextIg.w = payload.textIg.w
    resetTextIg.h = payload.textIg.h
    resetTextIg.textAttr.backgroundColor = payload.textIg.textAttr.backgroundColor
    resetTextIg.textAttr.fontFamily = payload.textIg.textAttr.fontFamily
    resetTextIg.textAttr.fontWeight = payload.textIg.textAttr.fontWeight
    resetTextIg.textAttr.textAlign = payload.textIg.textAttr.textAlign
    resetTextIg.textAttr.textColor = payload.textIg.textAttr.textColor
    resetTextIg.mentions.fontFamily = payload.textIg.mentions.fontFamily
    resetTextIg.mentions.fontWeight = payload.textIg.mentions.fontWeight
    resetTextIg.mentions.textColor = payload.textIg.mentions.textColor
    resetTextIg.hashtags.fontFamily = payload.textIg.hashtags.fontFamily
    resetTextIg.hashtags.fontWeight = payload.textIg.hashtags.fontWeight
    resetTextIg.hashtags.textColor = payload.textIg.hashtags.textColor

    resetTagsIg.x = payload.tagsIg.x
    resetTagsIg.y = payload.tagsIg.y
    resetTagsIg.w = payload.tagsIg.w
    resetTagsIg.h = payload.tagsIg.h
    resetTagsIg.textAttr.backgroundColor = payload.tagsIg.textAttr.backgroundColor
    resetTagsIg.textAttr.fontFamily = payload.tagsIg.textAttr.fontFamily
    resetTagsIg.textAttr.fontWeight = payload.tagsIg.textAttr.fontWeight
    resetTagsIg.textAttr.textAlign = payload.tagsIg.textAttr.textAlign
    resetTagsIg.textAttr.textColor = payload.tagsIg.textAttr.textColor
    resetTagsIg.mentions.fontFamily = payload.textIg.mentions.fontFamily
    resetTagsIg.mentions.fontWeight = payload.textIg.mentions.fontWeight
    resetTagsIg.mentions.textColor = payload.textIg.mentions.textColor
    resetTagsIg.hashtags.fontFamily = payload.textIg.hashtags.fontFamily
    resetTagsIg.hashtags.fontWeight = payload.textIg.hashtags.fontWeight
    resetTagsIg.hashtags.textColor = payload.textIg.hashtags.textColor

    resetBgEditor.color = payload.bgEditor.color
    resetBgEditor.bgImage.file = payload.bgEditor.bgImage.file
    resetBgEditor.bgImage.imageData = payload.bgEditor.bgImage.imageData
    resetBgEditor.bgImage.templateID = payload.bgEditor.bgImage.templateID

    resetLogo.file = payload.logo.file
    resetLogo.imageData = payload.logo.imageData
    resetLogo.templateID = payload.logo.templateID
  },
  ACTIVE_TOOL_BAR(state, payload) {
    for (const item in state.toolBar) {
      if (item === payload) {
        state.toolBar[item] = true
      } else {
        state.toolBar[item] = false
      }
    }
  },
  ACTIVE_WIDGET_MENU(state, payload) {
    for (const item in state.widget) {
      if (item === payload) {
        state.widget[item].activeMenu = true
      } else {
        state.widget[item].activeMenu = false
      }
    }
  },
  ACTIVE_WIDGET_ELEMENT(state, payload) {
    state[payload].visitable = !state[payload].visitable
  },
  REMOVE_WIDGET(state, payload) {
    state[payload].visitable = false
  },
  GET_LIST_IG(state, payload) {
    state.listIG = payload
  },
  PREV_SLIDE(state) {
    state.currentPost--
    state.isLastSlide = false
    if (state.currentPost === 0) {
      state.isFirstSlide = true
    }
  },
  NEXT_SLIDE(state) {
    state.currentPost++
    state.isFirstSlide = false
    if (state.currentPost === state.numberData.numberOfSlide - 1) {
      state.isLastSlide = true
    }
  },
  RESET_AUTH(state) {
    state.isAuthentication = false
  }
}
