export default {
  loading: false,
  error: false,
  loopId: -1,
  items: [],
  treeFolder: [],
  breadcrumb: [],
  nested_items: [],
  loadingAssets: false,
  pagination: {
    limit: 10,
    page: 1,
    total: -1
  },
  statistical: {},
  campaignItems: [],
  errorPlayer: false,
  errorCampaign: false,
  playerItems: [],
  loadingCampaign: false,
  campaignPagination: {
    limit: 5,
    page: 1,
    total: -1
  },
  playerPagination: {
    limit: 5,
    page: 1,
    total: -1
  }
}
