<template>
  <div>
    <template>
      <SfModal
        :title="'Edit Custom Image'"
        :width="768"
        :height="0"
        closeOnOverlay
        :show.sync="dataShow"
        @update:show="updateShow"
      >
        <div class="sf-modal-body">
          <div class="container">
            <div class="row mt-3">
              <div class="col">
                <FolderPath v-if="data.folder_path" :paths="data.folder_path" />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <ul class="sf-tabs">
                  <li>
                    <a @click="changeTab('overview')" :class="{ active: activeTab === 'overview' }"
                      >Overview</a
                    >
                  </li>
                  <li>
                    <a @click="changeTab('linkto')" :class="{ active: activeTab === 'linkto' }"
                      >Linked To</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div v-if="activeTab === 'overview'" class="container">
            <div class="row mt-3">
              <input type="hidden" name="type" id="type" value="gridview" />
              <input
                type="hidden"
                value="video"
                name="smart_asset"
                id="smart_asset_smart_asset_type"
              />
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">Title</label>
                  </div>
                  <input
                    type="text"
                    name="smart_asset"
                    id="smart_asset_displayname"
                    v-model="dataInput.name"
                    class="sf-input-text"
                    placeholder="Title"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group">
                    <label class="primary-label">Tags</label>
                  </div>
                  <vue-tags-input
                    v-model="tag"
                    class="sf-tag"
                    :tags="dataInput.tags"
                    @tags-changed="(newTags) => (dataInput.tags = newTags)"
                  />
                </div>
              </div>
            </div>

            <!-- <div class="row mt-3">
                <div class="col-9">
                    <div class="sf-form-group">
                      <div class="sf-label-group label-top">
                        <label class="primary-label">Orientation</label>
                      </div>
                      <div class="row orientation-type">
                        <div class="col-6">
                          <label class="sf-radio">Landscape (Default)
                            <input
                              id="landscape-radio"
                              type="radio"
                              value="landscape"
                              v-model="dataInput.orientation"
                              class="sf-radio"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="col-6">
                          <label class="sf-radio">Portrait
                            <input
                              id="portrait-radio"
                              type="radio"
                              value="portrait"
                              v-model="dataInput.orientation"
                              class="sf-radio"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="col-12 mt-3">
                          <div class="row">
                            <div class="col-6 landscape orientation-img" :class="{ active: dataInput.orientation === 'landscape' }">
                                <img src="~@/assets/images/landscape-review.svg" />
                            </div>
                            <div class="col-6 orientation-img portrait" :class="{ active: dataInput.orientation === 'portrait' }">
                              <img src="~@/assets/images/portrait-review.svg" />
                            </div>
                        </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div> -->
          </div>
        </div>
        <div v-if="activeTab === 'linkto'" class="container">
          <div class="row">
            <div class="col-6 mt-3">
              <div class="row">
                <div class="col-3">Show</div>
                <div class="col-9">
                  <label class="sf-radio mb-1">
                    Loop
                    <input type="radio" value="loop" v-model="showBy" />
                    <span class="checkmark"></span>
                  </label>
                  <label class="sf-radio">
                    Campaign
                    <input type="radio" value="campaign" v-model="showBy" />
                    <span class="checkmark"></span>
                  </label>
                  <label class="sf-radio">
                    Player
                    <input type="radio" value="player" v-model="showBy" />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-6 mt-3">
              <LinkedToInputSearch
                :showBy="showBy"
                @searchChange="searchChange"
                @onSearchInputChange="onSearchInputChange"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div v-if="showBy === 'loop'" class="col-12 sf-table">
              <b-table
                show-empty
                striped
                hover
                :items="loopItems"
                :fields="fields"
                head-variant="none"
                table-variant="none"
                thead-class="custom-b-table"
                :busy="loadingLoop"
              >
                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template v-slot:empty="isLoopEmpty">
                  <p class="d-flex justify-content-center">No data available in table</p>
                </template>
                <template v-slot:head(name)="field">
                  <span class="custome-arrows">{{ field.label }}</span>
                </template>
                <template v-slot:cell(name)="field">
                  <p>
                    <router-link :to="`/loop_assets/${field.item.id}/edit`">{{
                      field.item.name
                    }}</router-link>
                  </p>
                </template>
              </b-table>
              <div class="mt-3" v-if="!isLoopEmpty">
                <b-pagination
                  class="sf-b-pagination"
                  :total-rows="loopPagination.limit * loopPagination.total"
                  :per-page="loopPagination.limit"
                  @change="changeLoopPage"
                  :limit="6"
                  v-model="currentLoopPage"
                />
              </div>
            </div>

            <div v-if="showBy === 'campaign'" class="col-12 sf-table">
              <b-table
                show-empty
                striped
                hover
                :items="campaignItems"
                :fields="fields"
                head-variant="none"
                table-variant="none"
                thead-class="custom-b-table"
                :busy="loadingCampaign"
              >
                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template v-slot:empty="isCampaignEmpty">
                  <p class="d-flex justify-content-center">No data available in table</p>
                </template>
                <template v-slot:head(name)="field">
                  <span class="custome-arrows">{{ field.label }}</span>
                </template>
                <template v-slot:cell(name)="field">
                  <p>
                    <router-link :to="`/campaigns/${field.item.id}/edit`">{{
                      field.item.name
                    }}</router-link>
                  </p>
                </template>
              </b-table>
              <div class="mt-3" v-if="!isCampaignEmpty">
                <b-pagination
                  class="sf-b-pagination"
                  :total-rows="campaignPagination.limit * campaignPagination.total"
                  :per-page="campaignPagination.limit"
                  @change="changeCampaignPage"
                  :limit="6"
                  v-model="currentCampaignPage"
                />
              </div>
            </div>

            <div v-if="showBy === 'player'" class="col-12 sf-table">
              <b-table
                show-empty
                striped
                hover
                :items="playerItems"
                :fields="fields"
                head-variant="none"
                table-variant="none"
                thead-class="custom-b-table"
                :busy="loadingPlayer"
              >
                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>{{ $t('loading') }}...</strong>
                  </div>
                </template>
                <template v-slot:empty="isPlayerEmpty">
                  <p class="d-flex justify-content-center">
                    {{ $t('no-data-available-in-table') }}
                  </p>
                </template>
                <template v-slot:head(name)="field">
                  <span class="custome-arrows">{{ $t('name') }}</span>
                </template>
                <template v-slot:cell(name)="field">
                  <p>
                    <router-link
                      tag="a"
                      :to="`#`"
                      @click.native="onEditPlayerModalOpen(field.item.id)"
                      >{{ field.item.name }}</router-link
                    >
                  </p>
                </template>
              </b-table>
              <div class="mt-3" v-if="!isPlayerEmpty">
                <b-pagination
                  class="sf-b-pagination"
                  :total-rows="playerPagination.limit * playerPagination.total"
                  :per-page="playerPagination.limit"
                  @change="changePlayerPage"
                  :limit="6"
                  v-model="currentPlayerPage"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="sf-modal-footer mt-3">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button class="sf-secondary" type="button" @click="updateShow">Cancel</button>
                  <button type="button" class="sf-primary" @click="onClickNext">Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SfModal>
    </template>
    <template>
      <EditPlayerModal
        v-if="isShownModalEdit"
        :player_id="editPlayerId"
        :onClose="onEditModalClose"
      />
    </template>
  </div>
</template>

<script>
import FolderPath from './FolderPath.vue'
import SfModal from '@/components/SfModal'
import VueTagsInput from '@johmun/vue-tags-input'
import SmartAsset from '@/services/smart-assets.service'
import { mapState, mapGetters, mapActions } from 'vuex'
import { LINKED_TYPE } from '../../constant'
import EditPlayerModal from '@/components/player/EditPlayerModal.vue'
import LinkedToInputSearch from '@/components/library/LinkedToInputSearch.vue'

export default {
  components: {
    SfModal,
    VueTagsInput,
    FolderPath,
    EditPlayerModal,
    LinkedToInputSearch
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dataShow: this.show,
      tag: '',
      activeTab: 'overview',
      dataInput: {
        name: '',
        tags: [],
        orientation: 'landscape'
      },
      showBy: 'loop',
      currentLoopPage: 1,
      currentCampaignPage: 1,
      currentPlayerPage: 1,
      isShownModalEdit: false,
      editPlayerId: null,
      fields: [
        {
          key: 'name',
          colType: 'name',
          label: 'Name',
          sortable: true
        }
      ],
      searchValue: ''
    }
  },

  watch: {
    async show(value) {
      this.dataShow = value
      if (value) {
        this.dataInput = { ...this.dataInput, ...this.data }
      }
      this.dataInput.tags = this.dataInput.tags.map((item) => ({ text: item, ...item }))
      this.getLoopsByAssetID({ assetID: this.dataInput.id, page: 1, limit: 5, search: '' })
      this.getCampaignsByAssetID({ assetID: this.dataInput.id, page: 1, limit: 5, search: '' })
      this.getPlayerByAssetID({ assetID: this.dataInput.id, page: 1, limit: 5, search: '' })
    }
  },
  methods: {
    ...mapActions('library', {
      getLoopsByAssetID: 'getLoopsByAssetID',
      getCampaignsByAssetID: 'getCampaignsByAssetID',
      getPlayerByAssetID: 'getPlayerByAssetID'
    }),
    searchChange(value) {
      this.searchValue = value
      switch (this.showBy) {
        case 'loop':
          this.searchLoopChange()
          break
        case 'campaign':
          this.searchCampaignChange()
          break
        case 'player':
          this.searchPlayerChange()
          break

        default:
          break
      }
    },
    changeLoopPage(page) {
      this.currentLoopPage = page
      this.getLoopsByAssetID({
        assetID: this.dataInput.id,
        page: this.currentLoopPage,
        limit: this.loopPagination.limit,
        search: this.searchValue
      })
    },
    changeCampaignPage(page) {
      this.currentCampaignPage = page
      this.getCampaignsByAssetID({
        assetID: this.dataInput.id,
        page: this.currentCampaignPage,
        limit: this.campaignPagination.limit,
        search: this.searchValue
      })
    },
    changePlayerPage(page) {
      this.currentPlayerPage = page
      this.getPlayerByAssetID({
        assetID: this.dataInput.id,
        page: this.currentPlayerPage,
        limit: this.playerPagination.limit,
        search: this.searchValue
      })
    },
    onSearchInputChange() {
      if (this.showBy === LINKED_TYPE.LOOP) {
        this.getLoopsByAssetID({ assetID: this.dataInput.id, page: 1, limit: 5, search: '' })
      } else if (this.showBy === LINKED_TYPE.CAMPAIGN) {
        this.getCampaignsByAssetID({ assetID: this.dataInput.id, page: 1, limit: 5, search: '' })
      } else if (this.showBy === LINKED_TYPE.PLAYER) {
        this.getPlayerByAssetID({ assetID: this.dataInput.id, page: 1, limit: 5, search: '' })
      }
    },
    onEditPlayerModalOpen(player_id) {
      this.editPlayerId = player_id
      this.isShownModalEdit = true
      this.dataShow = false
      this.updateShow()
    },
    onEditModalClose() {
      this.editPlayerId = null
      this.isShownModalEdit = false
    },
    searchLoopChange() {
      this.getLoopsByAssetID({
        assetID: this.dataInput.id,
        page: 1,
        limit: this.loopPagination.limit,
        search: this.searchValue
      })
    },
    searchCampaignChange() {
      this.getCampaignsByAssetID({
        assetID: this.dataInput.id,
        page: 1,
        limit: this.campaignPagination.limit,
        search: this.searchValue
      })
    },
    searchPlayerChange() {
      this.getPlayerByAssetID({
        assetID: this.dataInput.id,
        page: 1,
        limit: this.playerPagination.limit,
        search: this.searchValue
      })
    },
    updateShow() {
      this.showBy = 'loop'
      this.$emit('update:show', false)
    },
    changeTab(val) {
      this.activeTab = val
    },
    async onClickNext() {
      const tags = this.dataInput.tags
      this.dataInput.displayname = this.dataInput.name
      const dataTemp = await SmartAsset.getSmartAssetsByID(this.data.id)
      localStorage.setItem(
        'instagram',
        JSON.stringify({
          smart_asset_id: this.dataInput.id,
          displayname: this.dataInput.name,
          tags: tags,
          orientation: this.dataInput.orientation,
          current_folder: {},
          organization_id: this.organizationId,
          smart_asset_type: 'instagram',
          orientation_changed: !(this.dataInput.orientation === dataTemp.data.orientation)
        })
      )

      localStorage.setItem(
        'instagramInfo',
        JSON.stringify({
          access_token: dataTemp.data.properties.ig_access_token,
          user: {
            id: dataTemp.data.properties.query
          }
        })
      )
      this.$emit('modal:next', { ...this.dataInput })
    }
  },
  computed: {
    ...mapState('library', {
      loadingLoop: 'loadingLoop',
      loadingCampaign: 'loadingCampaign',
      loadingPlayer: 'loadingPlayer',
      organizationId: 'organizationId'
    }),
    ...mapGetters('library', {
      isLoopEmpty: 'isLoopEmpty',
      isCampaignEmpty: 'isCampaignEmpty',
      isPlayerEmpty: 'isPlayerEmpty',
      loopItems: 'loopItems',
      campaignItems: 'campaignItems',
      playerItems: 'playerItems',
      loopPagination: 'loopPagination',
      campaignPagination: 'campaignPagination',
      playerPagination: 'playerPagination'
    })
  },
  created() {}
}
</script>

<style lang="scss" scoped>
@import './style-modal-smart-asset.scss';
</style>

<style lang="scss">
.form-control {
  height: auto !important;
  .vue-tags-input {
    margin: -0.375rem -0.75rem;
    border-radius: 0.25rem;
    * {
      border: transparent;
    }
    border: transparent;
    .ti-tag {
      background-color: #e0edf5;
      border-radius: 3px;
      color: #2f5d92;
      float: left;
      font-weight: 600;
      height: 28px;
      line-height: 28px;
      margin: 0 8px 6px 0;
      padding: 0 1px 0 8px;
      white-space: nowrap;
    }
  }
}
</style>
