var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uikit uikit-button"},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"row mb-2"},[_vm._m(6),_c('div',{staticClass:"col-6"},[_c('toggle-button',{attrs:{"value":_vm.toggleButton,"sync":true,"width":44,"height":22,"margin":2},on:{"change":function($event){_vm.toggleButton = !_vm.toggleButton}}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"material-name"},[_c('h2',[_vm._v("Buttons")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("PRIMARY CTA")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"sf-primary-add"},[_vm._v("Primary")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("SECONDARY CTA")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"sf-secondary-add"},[_vm._v("Secondary")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("SEGMENTED FILTER")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"sf-sort"},[_vm._v("Sort")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("LINK BUTTON")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"sf-link"},[_vm._v("Text")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("ICON BUTTON")])]),_c('div',{staticClass:"col-6"},[_c('button',{staticClass:"sf-icon-button"},[_c('img',{attrs:{"src":require("@/assets/images/close-x.svg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4"},[_c('div',{staticClass:"material-title"},[_vm._v("SWITCH")])])
}]

export { render, staticRenderFns }