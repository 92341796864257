<template>
  <SfModal
    :title="$t('modal.title.share-folder')"
    :width="768"
    :height="0"
    closeOnOverlay
    :show.sync="dataShow"
    @update:show="updateShow"
  >
    <div class="modal-body">
      <div class="row my-3">
        <div class="col-9">
          <div class="sf-form-group">
            <div class="sf-label-group">
              <label class="primary-label">{{ $t('folder-sharing') }}</label>
            </div>
            <input
              class="sf-input-text"
              type="text"
              name="search"
              id="folder_sharing"
              :placeholder="$t('search')"
              v-model="searchChildOrg"
            />
          </div>
        </div>
      </div>
      <!-- table sub org -->
      <div class="admin-org-detail table-edit-folder col-12">
        <div class="sf-table">
          <b-table
            responsive="true"
            :items="displayListOrg"
            show-empty
            striped
            hover
            :fields="fields"
            head-variant="none"
            table-variant="none"
            thead-class="custom-b-table"
            :busy="isLoading"
            :current-page="currentPage"
            :per-page="perPage"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>{{ 'loading' }}...</strong>
              </div>
            </template>
            <template v-slot:empty="emptySubOrg">
              <p class="d-flex justify-content-center">{{ $t('no-data-available-in-table') }}</p>
            </template>
            <template v-slot:head(shared)="field">
              <button
                :class="isSelect ? 'sf-btn-check-active' : 'sf-btn-check'"
                @click="selectAllSubOrg"
              ></button>
            </template>
            <template v-slot:cell(shared)="field">
              <button
                @click="selectOrg(field.item.id)"
                :class="field.item.selected ? 'sf-btn-check-active' : 'sf-btn-check'"
              ></button>
            </template>

            <template v-slot:head(sub_organization)="field">
              <span>{{ field.label }}</span>
            </template>
            <template v-slot:cell(sub_organization)="field">
              <p>{{ field.item.name }}</p>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="displayListOrg.length"
            :per-page="perPage"
            :class="'sf-b-pagination'"
            @change="changePage"
          >
          </b-pagination>
        </div>
      </div>
    </div>
    <div class="sf-modal-footer mt-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="sf-modal-action">
              <button class="sf-secondary" type="button" @click="updateShow">
                {{ $t('cancel') }}
              </button>
              <button type="button" class="sf-primary" @click="onClickSave">
                {{ $t('save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SfModal>
</template>

<script>
import SfModal from '@/components/SfModal'
import { required, maxLength } from 'vuelidate/lib/validators'
import OrganizationService from '@/services/organization.service'
export default {
  components: {
    SfModal
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dataShow: this.show,
      submitted: false,
      tag: '',
      tags: [],
      dataInput: this.data,
      fields: [
        {
          key: 'shared',
          sortable: true,
          colType: 'shared',
          label: 'Shared',
          class: 'check-share'
        },
        {
          key: 'sub_organization',
          sortable: true,
          colType: 'sub_organization',
          label: this.$t('sub-organizations')
        }
      ],
      isSelect: false,
      isParentOrg: false,
      listOrg: [],
      displayListOrg: [],
      isLoading: false,
      shared_organization_ids: [],
      searchChildOrg: '',
      currentPage: 1,
      perPage: 10
    }
  },
  watch: {
    show(value) {
      this.dataShow = value
      this.listOrg = []
      this.dataInput = this.data
      this.currentPage = 1
      this.searchChildOrg = ''
    },
    dataInput() {
      this.getAllChildOrganization()
    },
    listOrg(value) {
      this.getCheckedOrg(value)
      this.displayListOrg = value.filter((org) => {
        return this.searchChildOrg
          .toLowerCase()
          .split(' ')
          .every((v) => org.name.toLowerCase().includes(v))
      })
    },
    shared_organization_ids(value) {
      this.isSelect = value.length === this.listOrg.length
    },
    searchChildOrg(value) {
      if (value) {
        this.displayListOrg = this.listOrg.filter((org) => {
          return value
            .toLowerCase()
            .split(' ')
            .every((v) => org.name.toLowerCase().includes(v))
        })
      } else {
        this.displayListOrg = this.listOrg
      }
    }
  },
  validations: {
    dataInput: {
      name: {
        required,
        maxLength: maxLength(250)
      }
    }
  },
  methods: {
    updateShow() {
      this.submitted = false
      this.$emit('update:show', false)
    },
    onClickSave() {
      this.submitted = true
      if (this.$v.dataInput.$invalid) {
        return
      }
      this.dataInput.shared_organization_ids = this.shared_organization_ids
      this.$emit('modal:save', { ...this.dataInput })
    },
    selectAllSubOrg() {
      this.isSelect = !this.isSelect
      const listOrg = this.listOrg
      listOrg.map((org) => {
        org.selected = this.isSelect
      })
      this.listOrg = [...listOrg]
    },
    getAllChildOrganization() {
      this.isLoading = true
      const self = this
      OrganizationService.getAllChildOrganization('loop')
        .then((res) => {
          self.isLoading = self.listOrg = res.data
          self.displayListOrg = res.data
          const sharedOrganizationIds = self.dataInput.shared_organizations
          self.listOrg.map((org) => {
            org.selected = sharedOrganizationIds.length && sharedOrganizationIds.includes(org.id)
          })
        })
        .catch((error) => {
          self.$toast.error(error.message ? error.message : 'Something went wrong')
        })
        .finally(() => {
          self.isLoading = false
        })
    },
    getCheckedOrg(listOrg) {
      this.shared_organization_ids = listOrg
        .filter(function (org) {
          return org.selected
        })
        .map((x) => x.id)
    },
    selectOrg(id) {
      const listOrg = this.listOrg
      const orgSelected = listOrg.findIndex((org) => org.id === id)
      listOrg[orgSelected].selected = !listOrg[orgSelected].selected
      this.listOrg = [...listOrg]
    },
    changePage(page) {
      this.currentPage = page
    },
    emptySubOrg() {
      return this.displayListOrg.length === 0
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.isParentOrg = this.$cookies.get('user').is_parent_organization
  }
}
</script>

<style lang="scss" scoped>
@import './style-modal-smart-asset';
.input-search {
  background-image: url(~@/assets/images/magnifying-glass.svg);
  background-repeat: no-repeat;
  background-position: 15px center;
  padding: 12px 20px 12px 50px;
}
.modal-footer {
  text-align: center;
  margin-top: 25px;
}
.term-info {
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-top: 15px;
  line-height: 30px;
  font-size: 14px;
  font-weight: normal;
  color: #4a4a4a;

  p {
    padding-left: 10px;
    font: 14px 'Open Sans', sans-serif;
  }
}

.button-term {
  width: 25px;
  height: 25px;
  background-size: cover;
  background-color: transparent;
  border: 1px solid #a7b1c7;
}

.button-term-active {
  background-image: url(~@/assets/images/checked-all.svg);
  width: 25px;
  height: 25px;
  background-size: cover;
  background-color: transparent;
  border: 1px solid #a7b1c7;
}
</style>
