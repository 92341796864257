import state from './adminTaxState'
import mutations from './adminTaxMutations'
import getters from './adminTaxGetters'
import actions from './adminTaxActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
