<template>
  <div class="admin">
    <nav class="admin-menu">
      <ul class="admin-menu-list">
        <li class="admin-menu-item">
          <router-link :to="{ path: '/admin/dashboard/overview' }">Dashboard</router-link>
        </li>
        <li v-if="checkMenuAll(adminClients)" class="admin-menu-item">
          <router-link :to="{ path: '/admin/clients' }">Clients</router-link>
        </li>
        <li v-if="checkMenuAll(adminManagement)" class="admin-menu-item">
          <router-link :to="{ path: '/admin/management' }">Management</router-link>
        </li>
        <li v-if="checkMenuAll(adminSystem)" class="admin-menu-item">
          <router-link :to="{ path: '/admin/system' }">System</router-link>
        </li>
      </ul>
    </nav>

    <router-view></router-view>
  </div>
</template>

<script>
import { adminClients, adminManagement, adminSystem } from '@/constant/menu'
import permissionMixin from '@/mixins/permissionMixin'
export default {
  name: 'account',
  mixins: [permissionMixin],
  data() {
    return {
      idOrgan: 1,
      userID: 0,
      selected: undefined,
      adminClients,
      adminManagement,
      adminSystem
    }
  },
  methods: {
    getUserID() {
      const userStr = localStorage.getItem('user')
      if (userStr) {
        const user = JSON.parse(userStr)
        this.userID = user.id
      }
    }
  },
  mounted() {
    const adminMenu = document.querySelectorAll('.admin-menu .admin-menu-item a')
    adminMenu.forEach((element) => {
      element.addEventListener('click', function () {
        adminMenu.forEach((element) => {
          element.classList.remove('is-active')
        })
        this.classList.add('is-active')
      })
    })
    this.getUserID()
  }
}
</script>

<style lang="scss" scoped>
.admin-menu {
  background-color: #fff;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border: solid 1px #f0f0f0;
  .admin-menu-list {
    display: flex;
    .admin-menu-item {
      padding: 0 15px;
      min-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .admin-menu-active {
        font-weight: bold;
        color: #00325b;
      }
      &:first-child {
        border-left: none;
      }
      a {
        text-transform: uppercase;
        color: #4a4a4a;
        &.is-active {
          font-weight: bold;
          color: #00325b;
        }
        .admin-menu-arrow {
          margin-left: 5px;
        }
      }
      &:hover {
        .admin-menu-dropdown {
          display: block;
        }
      }
      .admin-menu-dropdown {
        position: absolute;
        z-index: 10;
        top: 40px;
        width: 200px;
        left: 0;
        background-color: #fff;
        padding: 0 15px;
        border: 1px solid #ebebeb;
        display: none;
        li {
          margin: 15px 0;
          a {
            color: #424242;
          }
        }
      }
    }
  }
}
</style>
