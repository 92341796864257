<template>
  <div class="uikit uikit-button">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-12">
          <div class="material-name">
            <h2>Buttons</h2>
          </div>
        </div>
      </div>
      <!-- <div class="row mb-2">
        <div class="col-4">
          <div class="material-title">
            Primary
          </div>
        </div>
        <div class="col-6">
          <button class="sf-primary">Primary</button>
        </div>
      </div> -->
      <div class="row mb-2">
        <div class="col-4">
          <div class="material-title">PRIMARY CTA</div>
        </div>
        <div class="col-6">
          <button class="sf-primary-add">Primary</button>
        </div>
      </div>
      <!-- <div class="row mb-2">
        <div class="col-4">
          <div class="material-title">
            PRIMARY CTA ACTIVE
          </div>
        </div>
        <div class="col-6">
          <button class="sf-primary-add active">Primary</button>
        </div>
      </div> -->

      <!-- <div class="row  mb-2">
        <div class="col-4">
          <div class="material-title">
            Seconary
          </div>
        </div>
        <div class="col-6">
          <button class="sf-secondary">Secondary</button>
        </div>
      </div> -->

      <div class="row mb-2">
        <div class="col-4">
          <div class="material-title">SECONDARY CTA</div>
        </div>
        <div class="col-6">
          <button class="sf-secondary-add">Secondary</button>
        </div>
      </div>

      <!-- <div class="row mb-2">
        <div class="col-4">
          <div class="material-title">
            SECONDARY CTA ACTIVE
          </div>
        </div>
        <div class="col-6">
          <button class="sf-secondary-add active">Secondary</button>
        </div>
      </div> -->

      <div class="row mb-2">
        <div class="col-4">
          <div class="material-title">SEGMENTED FILTER</div>
        </div>
        <div class="col-6">
          <button class="sf-sort">Sort</button>
        </div>
      </div>

      <!-- <div class="row mb-2">
        <div class="col-4">
          <div class="material-title">
            SEGMENTED FILTER ACTIVE
          </div>
        </div>
        <div class="col-6">
          <button class="sf-sort active">Sort</button>
        </div>
      </div> -->

      <div class="row mb-2">
        <div class="col-4">
          <div class="material-title">LINK BUTTON</div>
        </div>
        <div class="col-6">
          <button class="sf-link">Text</button>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-4">
          <div class="material-title">ICON BUTTON</div>
        </div>
        <div class="col-6">
          <button class="sf-icon-button"><img src="@/assets/images/close-x.svg" /></button>
        </div>
      </div>
      <!-- <div class="row mb-2">
        <div class="col-4">
          <div class="material-title">
            LINK BUTTON ACTIVE
          </div>
        </div>
        <div class="col-6">
          <button class="sf-link active">Text</button>
        </div>
      </div> -->
      <div class="row mb-2">
        <div class="col-4">
          <div class="material-title">SWITCH</div>
        </div>
        <div class="col-6">
          <toggle-button
            :value="toggleButton"
            :sync="true"
            :width="44"
            :height="22"
            :margin="2"
            @change="toggleButton = !toggleButton"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      toggleButton: false
    }
  },
  created() {},
  methods: {},
  computed: {}
}
</script>

<style lang="scss" scoped></style>
