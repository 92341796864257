import PlayerService from '../../../services/player.service'
import CampaignService from '../../../services/campaign.service'
import CountriesService from '../../../services/countries.service'
import PlayerGroupService from '../../../services/player-group.service'
import Vue from 'vue'
import router from '@/router'

export default {
  getAllPlayer({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return PlayerService.getAllPlayers(
      payload.activePage,
      payload.limit,
      payload.search,
      payload.orientation
    )
      .then((response) => {
        commit('GET_LIST_PLAYER', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING', false))
  },

  getInfoPlayerByID({ state, commit, rootState }, payload) {
    commit('SET_LOADING', payload.setLoading)
    return PlayerService.getInfoPlayerByID(payload.id)
      .then((response) => {
        commit('GET_INFO_PLAYER_BY_ID', response.data)
        commit('SET_EMPTY', false)
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        router.push('/players')
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING', false))
  },

  getAllCampaigns({ state, commit, rootState }, payload) {
    return CampaignService.getAllCampaignsNoPaging(payload)
      .then((response) => {
        commit('GET_ALL_CAMPAIGNS', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  },

  updateLocation({ state, commit, rootState }, payload) {
    commit('UPDATE_LOCATION', payload)
  },

  getListTimezone({ state, commit, rootState }, payload) {
    if (!state.listTimezone.length) {
      return CountriesService.getListTimezone()
        .then((response) => {
          commit('GET_LIST_TIMEZONE', response.data)
          commit('SET_ERROR', '')
        })
        .catch((error) => commit('SET_ERROR', error.message))
        .finally(() => commit('SET_LOADING', false))
    }
  },

  updatePlayerName({ state, commit, rootState }, payload) {
    commit('UPDATE_PLAYER_NAME', payload)
  },

  updateDescripionPlayer({ state, commit, rootState }, payload) {
    commit('UPDATE_DESCRIPTION_PLAYER', payload)
  },

  chooseTimezone({ commit }, payload) {
    commit('CHOOSE_TIMEZONE', payload)
  },

  chooseCampaign({ commit }, payload) {
    commit('CHOOSE_CAMPAIGN', payload)
  },

  changeRotate({ commit }, payload) {
    commit('CHANGE_ROTATE', payload)
  },

  changeToggleCache({ commit }, payload) {
    commit('CHANGE_TOGGLE_CACHE', payload)
  },

  changeToggleKiosk({ commit }, payload) {
    commit('CHANGE_TOGGLE_KIOSK', payload)
  },

  changeToggleWifiType({ commit }, payload) {
    commit('CHANGE_TOGGLE_WIFI_TYPE', payload)
  },

  getAllStates({ state, commit, rootState }, payload) {
    return CountriesService.getAllStates()
      .then((response) => {
        commit('GET_ALL_STATES', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  },

  getAllFiles({ state, commit, rootState }, payload) {
    commit('SET_LOADING_FLIES', true)
    return PlayerService.getAllFiles(payload.id, payload.page, payload.limit, payload.search)
      .then((response) => {
        commit('GET_ALL_FILES', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING_FLIES', false))
  },

  getAllPlayersWithOrientation({ commit }, payload) {
    return PlayerService.getAllPlayersWithOrientation(payload)
      .then((response) => {
        response.data.map((data) => {
          data.time = new Date(data.last_connection).getTime()
        })
        commit('GET_ALL_PLAYERS_WITH_ORIENTATION', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  },

  deletePlayerWhenAdd({ commit }, payload) {
    commit('DELETE_PLAYER_WHEN_ADD', payload)
  },
  deletePlayer({ commit }, payload) {
    commit('DELETE_PLAYER', payload)
  },
  removeAndReturnData({ commit }, payload) {
    commit('REMOVE_AND_RETURN_DATA', payload)
  },

  getGroupsPlayer({ state, commit }, payload) {
    commit('SET_LOADING', true)
    return PlayerGroupService.getPlayerGroup(payload.activePage, payload.limit, payload.search)
      .then((response) => {
        commit('GET_GROUPS_PLAYER', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  },

  GetNewListPlayerWhenGetDetailGroup({ commit }, payload) {
    commit('GET_NEW_LIST_PLAYER_WHEN_GET_DETAIL_GROUP', payload)
  },

  setNewCampaignPlayerPage({ commit }, payload) {
    commit('SET_NEW_CAMPAIGN_PLAYER_PAGE', payload)
  },

  checkPlayerDeleteGroup({ commit }, payload) {
    commit('CHECK_PLAYER_DELETE_GROUP', payload)
  },

  deletePlayerInPlayerChosen({ commit }, payload) {
    commit('DELETE_PLAYER_IN_PLAYER_CHOSEN', payload)
  },

  getAllPlayerChildren({ commit }, payload) {
    commit('SET_LOADING_DATA_PLAYER_CHILDREN', true)
    return PlayerService.getAllPlayerChildren(payload).then((res) => {
      commit('SET_LIST_PLAYER_CHILDRED', res.data)
      commit('SET_LOADING_DATA_PLAYER_CHILDREN', false)
    })
  },

  getUnregisteredPlayers({ state, commit, rootState }, payload) {
    commit('SET_LOADING_UNREGISTERED_PLAYERS', true)
    return PlayerService.getUnregisteredPlayers(payload.activePage, payload.limit, payload.search)
      .then((response) => {
        commit('SET_UNREGISTERED_PLAYERS', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING_UNREGISTERED_PLAYERS', false))
  },

  getConnectedPlayers({ state, commit, rootState }, payload) {
    commit('SET_LOADING_CONNECTED_PLAYERS', true)
    return PlayerService.getConnectedPlayers(
      payload.unqikey,
      payload.activePage,
      payload.limit,
      payload.search
    )
      .then((response) => {
        commit('SET_CONNECTED_PLAYERS', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING_CONNECTED_PLAYERS', false))
  },
  updatePlayerIds({ state, commit, rootState }, payload) {
    commit('SET_EDITABLE_PLAYER_IDS', payload)
  },

  resetPlayerIds({ state, commit, rootState }, payload) {
    commit('RESET_EDITABLE_PLAYER_IDS', payload)
  }
}
