import state from './adminSettingsState'
import mutations from './adminSettingsMutations'
import getters from './adminSettingsGetters'
import actions from './adminSettingsActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
