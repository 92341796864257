export default {
  GET_LIST_SUBORG(state, data) {
    state.listSubOrg = data.items
    state.totalPages = data.total_pages
  },
  SET_LOADING(state, data) {
    state.loading = data
  },
  SET_ERROR(state, data) {
    state.error = data
  },
  SET_EMPTY(state, data) {
    state.isEmpty = data
  }
}
