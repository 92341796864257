<template>
  <div class="uikit">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="material-name mt-3">
            <h2>Typography</h2>
          </div>
          <div class="material-desc mt-3">
            <h6>Font family: Open Sans</h6>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <h3>Headers</h3>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <h5>regular</h5>
        </div>
        <div class="col-4">
          <h5>semi bold</h5>
        </div>
        <div class="col-2">
          <h5>Size</h5>
        </div>
        <div class="col-2">
          <h5>Line height and color</h5>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <h1>Heading 1</h1>
        </div>
        <div class="col-4">
          <h1 class="semi-bold">Heading 1</h1>
        </div>
        <div class="col-2">
          <h1>28px</h1>
        </div>
        <div class="col-2 typography-note">
          <p>Line Height: 120%</p>
          <p>Color: #223144</p>
          <p>$color-grey-100</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <h2>Heading 2</h2>
        </div>
        <div class="col-4">
          <h2 class="semi-bold">Heading 2</h2>
        </div>
        <div class="col-2">
          <h2>24px</h2>
        </div>
        <div class="col-2 typography-note">
          <p>Line Height: 120%</p>
          <p>Color: #223144</p>
          <p>$color-grey-100</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <h3>Heading 3</h3>
        </div>
        <div class="col-4">
          <h3 class="semi-bold">Heading 3</h3>
        </div>
        <div class="col-2">
          <h3>20px</h3>
        </div>
        <div class="col-2 typography-note">
          <p>Line Height: 120%</p>
          <p>Color: #223144</p>
          <p>$color-grey-100</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <h4>Heading 4</h4>
        </div>
        <div class="col-4">
          <h4 class="semi-bold">Heading 4</h4>
        </div>
        <div class="col-2">
          <h3>16px</h3>
        </div>
        <div class="col-2 typography-note">
          <p>Line Height: 120%</p>
          <p>Color: #223144</p>
          <p>$color-grey-100</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <h5>Heading 5</h5>
        </div>
        <div class="col-4">
          <h5 class="semi-bold">Heading 5</h5>
        </div>
        <div class="col-2">
          <h3>14px</h3>
        </div>
        <div class="col-2 typography-note">
          <p>Line Height: 120%</p>
          <p>Color: #223144</p>
          <p>$color-grey-100</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <h6>Heading 6</h6>
        </div>
        <div class="col-4">
          <h6 class="semi-bold">Heading 6</h6>
        </div>
        <div class="col-2">
          <h3>12px</h3>
        </div>
        <div class="col-2 typography-note">
          <p>Line Height: 120%</p>
          <p>Color: #223144</p>
          <p>$color-grey-100</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="material-name mt-3">
            <h2>Paragraph</h2>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-4">
          <h5>Regular</h5>
        </div>
        <div class="col-4">
          <h5>Semi bold</h5>
        </div>
        <div class="col-2">
          <h5>Size</h5>
        </div>
        <div class="col-2">
          <h5>Line height and color</h5>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <p class="p-large mb-2">Paragraph large</p>
          <p class="p-large">ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
          <p class="p-large">abcdefghijklmnopqrstuvwxyz</p>
          <p class="p-large">1234567890(,.;:?!$&*)</p>
        </div>
        <div class="col-4">
          <p class="p-large semi-bold mb-2">Paragraph large</p>
          <p class="p-large semi-bold">ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
          <p class="p-large semi-bold">abcdefghijklmnopqrstuvwxyz</p>
          <p class="p-large semi-bold">1234567890(,.;:?!$&*)</p>
        </div>
        <div class="col-2">
          <h5>16px</h5>
        </div>
        <div class="col-2 typography-note">
          <p>Line Height: 140%</p>
          <p>Color: #223144</p>
          <p>$color-grey-100</p>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-4">
          <p class="mb-2">Paragraph large</p>
          <p>ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
          <p>abcdefghijklmnopqrstuvwxyz</p>
          <p>1234567890(,.;:?!$&*)</p>
        </div>
        <div class="col-4">
          <p class="semi-bold mb-2">Paragraph large</p>
          <p class="semi-bold">ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
          <p class="semi-bold">abcdefghijklmnopqrstuvwxyz</p>
          <p class="semi-bold">1234567890(,.;:?!$&*)</p>
        </div>
        <div class="col-2">
          <h5>14px</h5>
        </div>
        <div class="col-2 typography-note">
          <p>Line Height: 140%</p>
          <p>Color: #223144</p>
          <p>$color-grey-100</p>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-4">
          <p class="p-small mb-2">Paragraph small</p>
          <p class="p-small">ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
          <p class="p-small">abcdefghijklmnopqrstuvwxyz</p>
          <p class="p-small">1234567890(,.;:?!$&*)</p>
        </div>
        <div class="col-4">
          <p class="p-small semi-bold mb-2">Paragraph small</p>
          <p class="p-small semi-bold">ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
          <p class="p-small semi-bold">abcdefghijklmnopqrstuvwxyz</p>
          <p class="p-small semi-bold">1234567890(,.;:?!$&*)</p>
        </div>
        <div class="col-2">
          <h5>12px</h5>
        </div>
        <div class="col-2 typography-note">
          <p>Line Height: 140%</p>
          <p>Color: #223144</p>
          <p>$color-grey-100</p>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="material-name mt-3">
            <h2>Links</h2>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-4">
          <h5>Link</h5>
        </div>
        <div class="col-4">
          <h5>Link hover</h5>
        </div>
        <div class="col-2">
          <h5>Size</h5>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-4">
          <a class="a-large mb-2">Link(large)</a>
        </div>
        <div class="col-4">
          <a class="a-large mb-2">Link(large)</a>
        </div>
        <div class="col-2">
          <h5>16px</h5>
        </div>
        <div class="col-2 typography-note">
          <p>Line Height: 140%</p>
          <p>Color: #126CDB</p>
          <p>$color-primary-80</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <a class="mb-2">Link</a>
        </div>
        <div class="col-4">
          <a class="mb-2">Link</a>
        </div>
        <div class="col-2">
          <h5>14px</h5>
        </div>
        <div class="col-2 typography-note">
          <p>Line Height: 140%</p>
          <p>Color: #126CDB</p>
          <p>$color-primary-80</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <a class="a-small mb-2">Link(small)</a>
        </div>
        <div class="col-4">
          <a class="a-small mb-2">Link(small)</a>
        </div>
        <div class="col-2">
          <h5>12px</h5>
        </div>
        <div class="col-2 typography-note">
          <p>Line Height: 140%</p>
          <p>Color: #126CDB</p>
          <p>$color-primary-80</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'typography',
  components: {},
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>
