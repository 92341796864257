export default {
  loading: false,
  error: false,
  items: [],
  loopItems: [],
  defaultLoopItems: [],
  itemByID: {},
  pagination: {
    limit: 10,
    page: 0,
    total: 0
  },
  editCampaignIds: [],
  isEmpty: true,
  dataCampaignChildren: [],
  isLoadingDataCampaignChildren: false
}
