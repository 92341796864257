import Vue from 'vue'
import AppLayout from './layout/index.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import setGlobalHelpers from './global.helpers'
import moment from 'moment-timezone'
import html2canvas from 'html2canvas'
import vueDebounce from 'vue-debounce'
import Vuelidate from 'vuelidate'
import ToggleButton from 'vue-js-toggle-button'
import VueCodemirror from 'vue-codemirror'
import VueCookies from 'vue-cookies'
import VueTour from 'vue-tour'
import VueContentPlaceholders from 'vue-content-placeholders'
import ActionCableVue from 'actioncable-vue'
import VueDraggableResizable from 'vue-draggable-resizable'
import VueSlider from 'vue-slider-component'
import './mixins'
import './plugins'
import './thirdParty'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import './scss/style.scss'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'vue-toast-notification/dist/theme-default.css'
import 'codemirror/lib/codemirror.css'
import 'vue-slider-component/theme/default.css'
// optionally import default styles
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import 'vue-tour/dist/vue-tour.css'
import i18n from './i18n'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import { WEB_SOCKET } from './.env'

// require('vue-tour/dist/vue-tour.css')
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectionUrl: WEB_SOCKET, // or function which returns a string with your JWT appended to your server URL as a query parameter
  connectImmediately: true
})

setGlobalHelpers()
Vue.config.productionTip = false
Vue.component('vue-draggable-resizable', VueDraggableResizable)
Vue.component('vue-slider', VueSlider)
Vue.use(BootstrapVue)
Vue.use(vueDebounce)
Vue.use(VueTour)
Vue.use(ToggleButton)
// /* eslint-disable no-new */
Vue.prototype.moment = moment
Vue.prototype.html2canvas = html2canvas
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBE26ZOGGAQfW35407_QowCIaO-T8gn9xs',
    libraries: 'places',
    language: 'en'
  }
})
Vue.use(Vuelidate)
Vue.use(VueCodemirror)
Vue.use(VueCookies)
Vue.use(VueContentPlaceholders)
Vue.use(VueLodash, { name: 'custom', lodash: lodash })

Vue.directive('click-inside', {
  bind: function (el, binding, vnode) {
    el.clickInsideEvent = function (event) {
      if (el === event.target || el.contains(event.target)) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickInsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickInsideEvent)
  }
})

new Vue({
  name: 'Root',
  router,
  store,

  mounted() {
    store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth)
    window.addEventListener('resize', () => store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth))
  },

  beforeDestroy() {
    window.removeEventListener('resize', () =>
      store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth)
    )
  },

  i18n,
  render: (h) => h(AppLayout)
}).$mount('#app')
