export default {
  loading: false,
  loadingUser: false,
  error: false,
  currentUser: {},

  infoUser: '',
  allUsers: [],
  pagination: {
    limit: 10,
    page: 0,
    total: 0
  },
  infoUserByID: '',
  listUserType: '',
  listRole: '',
  customRole: ''
}
