<template>
  <div>
    <div v-if="!changeTag" class="header-edit">
      <div class="custom-class" v-if="loading">
        <content-placeholders>
          <content-placeholders-text :lines="1" />
        </content-placeholders>
      </div>
      <h4 v-else class="title">
        {{ itemByID.displayname }}
        <button @click="changeTag = true" class="edit-title"></button>
      </h4>
    </div>
    <div v-else class="header-edit">
      <input :value="itemByID.displayname" @input="changeDisplayname" class="sf-input-text" />
      <button @click="cancelChangeDisplayname" class="sf-secondary">{{ $t('cancel') }}</button>
      <button @click="updateDisplayname" class="sf-primary">{{ $t('save') }}</button>
    </div>
    <div class="d-flex co-edit">
      <div :class="{ 'plus-right': isShownLoop }" @click="isShownLoop = !isShownLoop" class="menu">
        <div class="menu-mini"></div>
      </div>
      <div :class="{ 'plus-width': isShownLoop }" class="loop">
        <div class="d-flex col-12 justify-content-between align-items-center pt-3">
          <h4>{{ $t('main-loop') }}</h4>
          <button
            :disabled="
              (itemByID.main_loops && !itemByID.main_loops.length) ||
              (itemByID.main_loops &&
                itemByID.main_loops[0].loop_asset &&
                !itemByID.main_loops[0].loop_asset.id) ||
              (itemByID.main_loops &&
                itemByID.main_loops[0].loop_asset_id &&
                !itemByID.main_loops[0].loop_asset_id)
            "
            @click="addMoreLoop"
            class="sf-primary-add max-width-150"
            type="button"
          >
            {{ $t('add-loop') }}
          </button>
        </div>
        <div v-if="loading" class="list-multiselect mt-3 ml-2">
          <div v-for="(item, index) in lengthListPlaceholder" :key="index">
            <content-placeholders>
              <content-placeholders-text :class="'custom-placeholder'" :lines="1" />
            </content-placeholders>
          </div>
        </div>
        <div v-else class="list-multiselect mt-3">
          <div class="select2-list" v-if="itemByID.main_loops && itemByID.main_loops.length === 0">
            <div class="sf-select-group">
              <Select2
                class="sf-select2"
                :value="newLoop.loop_asset_id"
                :options="loopItems"
                @select="onChangeFirstLoop"
              />
              <span class="lb-right caret"></span>
            </div>
          </div>
          <div v-for="(item, index) in itemByID.main_loops" :key="index" class="list-select">
            <div class="select2-list">
              <div class="sf-select-group">
                <Select2
                  class="sf-select2"
                  v-if="item.loop_asset"
                  :value="item.loop_asset.id"
                  @select="onChange($event, index)"
                  :options="loopItems"
                />
                <Select2
                  v-else
                  :value="item.loop_asset_id"
                  @select="onChangeLoops($event, index)"
                  :options="loopItems"
                  class="sf-select2"
                />
                <span class="lb-right caret"></span>
              </div>
            </div>
            <!-- <button v-if="index > 0" @click="removeMainLoop(index)">x</button> -->
            <button v-if="index > 0" @click="removeMainLoop(index)" class="sf-icon-button">
              <img src="@/assets/images/close-x.svg" />
            </button>
          </div>
        </div>
      </div>
      <div class="exceptions">
        <div class="d-flex header-loop">
          <h4>{{ $t('exceptions') }}</h4>
          <button
            @click="shownModalCreate"
            :disabled="
              (itemByID.main_loops && !itemByID.main_loops.length) ||
              (itemByID.main_loops &&
                itemByID.main_loops[0].loop_asset &&
                !itemByID.main_loops[0].loop_asset.id) ||
              (itemByID.main_loops &&
                itemByID.main_loops[0].loop_asset_id &&
                !itemByID.main_loops[0].loop_asset_id)
            "
            class="sf-primary-add"
            type="button"
          >
            {{ $t('add-new-exception') }}
          </button>
        </div>
        <div>
          <!-- Exeption -->
          <div class="scrollAble">
            <div
              v-if="itemByID.list_of_exeption && !itemByID.list_of_exeption.length && !loading"
              class="content-exception no-exception"
            >
              {{ $t('no-exception') }}
            </div>
            <div v-if="loading">
              <div
                v-for="(exception, index) in lengthListPlaceholderException"
                :key="index"
                class="content-exception drag-item"
              >
                <div class="d-flex justify-content-between header-content">
                  <div class="d-flex left-header">
                    <div class="toggle-caret">
                      <span class="caret"></span>
                    </div>
                    <content-placeholders v-if="loading">
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                  </div>
                  <div class="d-flex right-header"></div>
                </div>
                <b-collapse visible :id="`collapse-${index}`">
                  <div class="row table-header">
                    <div class="col-3">{{ $t('loop-name') }}</div>
                    <div class="col-3">{{ $t('date-range') }}</div>
                    <div class="col-3">{{ $t('time-range') }}</div>
                    <div class="col-3">{{ $t('days-of-week') }}</div>
                  </div>
                  <div class="row table-body">
                    <div class="col-3">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </div>
                    <div v-if="exception.start_day" class="col-3">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </div>
                    <div v-else class="col-3">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </div>
                    <div class="col-3">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </div>
                    <div class="col-3">
                      <content-placeholders>
                        <content-placeholders-text :lines="1" />
                      </content-placeholders>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <div v-else>
              <draggable
                v-model="listException"
                class="list-group"
                ghost-class="ghost"
                @end="updateSlideFromSocket"
              >
                <div
                  v-for="(exception, index) in itemByID.list_of_exeption"
                  :key="index"
                  class="content-exception drag-item"
                >
                  <div class="d-flex justify-content-between header-content">
                    <div class="d-flex left-header">
                      <div
                        class="toggle-caret"
                        v-b-toggle="`collapse-${index}`"
                        :class="{ rotate: exception.isRotated }"
                        @click="changeRotated(index)"
                      >
                        <span class="caret"></span>
                      </div>
                      <p :title="exception.title" class="text-truncate exception-title">
                        {{ exception.title }}
                      </p>
                    </div>
                    <div class="d-flex right-header">
                      <button @click="cloneException(exception)" class="copy"></button>
                      <button @click="shownModalUpdate(exception)" class="edit"></button>
                      <button @click="deleteException(index)" class="delete"></button>
                    </div>
                  </div>
                  <b-collapse visible :id="`collapse-${index}`">
                    <div class="row table-header">
                      <div class="col-3">{{ $t('loop-name') }}</div>
                      <div class="col-3">{{ $t('date-range') }}</div>
                      <div class="col-3">{{ $t('time-range') }}</div>
                      <div class="col-3">{{ $t('days-of-week') }}</div>
                    </div>
                    <div class="row table-body">
                      <div v-if="exception.loop_asset" class="col-3">
                        <p :title="exception.loop_asset.displayname" class="text-truncate">
                          {{ exception.loop_asset.displayname }}
                        </p>
                      </div>
                      <div v-else :title="exception.displayname" class="col-3 text-truncate">
                        {{ exception.displayname }}
                      </div>
                      <div v-if="exception.start_day" class="col-3">
                        <p class="date">
                          Start:
                          <span
                            >{{
                              9 >= exception.start_day
                                ? '0' + exception.start_day
                                : exception.start_day
                            }}/{{
                              9 >= exception.start_month
                                ? '0' + exception.start_month
                                : exception.start_month
                            }}/{{
                              9 >= exception.start_year
                                ? '0' + exception.start_year
                                : exception.start_year
                            }}</span
                          >
                        </p>
                        <p class="date">
                          End:
                          <span
                            >{{
                              9 >= exception.end_day ? '0' + exception.end_day : exception.end_day
                            }}/{{
                              9 >= exception.end_month
                                ? '0' + exception.end_month
                                : exception.end_month
                            }}/{{
                              9 >= exception.end_year
                                ? '0' + exception.end_year
                                : exception.end_year
                            }}</span
                          >
                        </p>
                      </div>
                      <div v-else class="col-3">
                        <p>{{ $t('always') }}</p>
                      </div>
                      <div class="col-3">
                        <p class="date">
                          {{ $t('start') }}:<span
                            >{{
                              9 >= exception.start_hour
                                ? '0' + exception.start_hour
                                : exception.start_hour
                            }}:{{
                              9 >= exception.start_minute
                                ? '0' + exception.start_minute
                                : exception.start_minute
                            }}:{{
                              9 >= exception.start_second
                                ? '0' + exception.start_second
                                : exception.start_second
                            }}</span
                          >
                        </p>
                        <p class="date">
                          {{ $t('end') }}:
                          <span
                            >{{
                              9 >= exception.end_hour
                                ? '0' + exception.end_hour
                                : exception.end_hour
                            }}:{{
                              9 >= exception.end_minute
                                ? '0' + exception.end_minute
                                : exception.end_minute
                            }}:{{
                              9 >= exception.end_second
                                ? '0' + exception.end_second
                                : exception.end_second
                            }}</span
                          >
                        </p>
                      </div>
                      <div v-if="exception.loop_asset" class="col-3 d-flex">
                        <p>{{ exception.repeat_info_render }}</p>
                      </div>
                      <div v-else class="col-3 d-flex days-choosen">
                        <p v-for="(day, index) in exception.getDayChoose" :key="index">
                          {{ day.name }}
                        </p>
                      </div>
                    </div>
                  </b-collapse>
                </div>
              </draggable>
            </div>
          </div>
        </div>
        <div class="button-updates-footer">
          <div class="list-button-updates">
            <button class="sf-secondary" @click="backCampaignPage">{{ $t('cancel') }}</button>
            <button class="sf-primary" @click="updateCampaign">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>
    <SfDeleteModal
      :title="$t('modal.title.delete-exception')"
      closeOnOverlay
      :show.sync="isShownModalDelete"
    >
      <div class="content-delete">
        {{ $t('remove-exception-confirmation-message') }}
      </div>
      <div class="footer-button">
        <button class="sf-secondary" @click="isShownModalDelete = false">{{ $t('cancel') }}</button>
        <button class="sf-primary" @click="removeException">{{ $t('ok') }}</button>
      </div>
    </SfDeleteModal>
    <SfModal
      :title="$t('modal.title.add-exception')"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalCreate"
    >
      <form @submit.prevent="checkForm">
        <div v-if="showError">
          <p v-for="(error, index) in listErrors" :key="index" class="text-danger">{{ error }}</p>
        </div>

        <div class="sf-modal-body sf-modal-exception">
          <div class="container">
            <div class="row mt-24">
              <div class="col-10">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">{{ $t('exception-name') }}</label>
                  </div>
                  <input
                    class="sf-input-text"
                    v-model="newException.title"
                    :placeholder="$t('placeholder.name-exception')"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-24">
              <div class="col-10">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">{{ $t('choose-exception-loop') }}</label>
                  </div>
                  <div class="sf-select-group exception-input">
                    <Select2
                      class="sf-select2"
                      :value="newException.id"
                      @select="chooseException"
                      :options="loopItemFilter"
                    ></Select2>
                    <span class="lb-right caret"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-24">
              <div class="col-10">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">{{ $t('always') }}</label>
                  </div>
                  <div class="w-100">
                    <!-- <span @click="isShownCheckBox =! isShownCheckBox"
                    :class="{'change-background' : isShownCheckBox}"></span> -->
                    <label class="sf-checkbox">
                      <span class="check-title">{{ $t('yes') }}</span>
                      <input v-model="isShownCheckBox" type="checkbox" checked="checked" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-24" v-if="!isShownCheckBox">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">{{ $t('date-range') }}</label>
                  </div>
                  <div class="w-100 d-flex select-date align-items-center justify-content-between">
                    <datepicker class="date-picker" v-model="dateStart"></datepicker>
                    <div class="to-word">{{ $t('to') }}</div>
                    <datepicker class="date-picker" v-model="dateEnd"></datepicker>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-24">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg label-top">
                    <label class="primary-label">{{ $t('time-range') }}</label>
                    <label class="secondary-label">(24 {{ $t('hours') }})</label>
                  </div>
                  <div class="sf-exception-time-picker">
                    <div class="time-picker">
                      <div class="body-date">
                        <div class="d-flex input-date">
                          <input
                            :value="
                              9 >= newException.start_hour
                                ? '0' + newException.start_hour
                                : newException.start_hour
                            "
                            @input="changeStartHour($event, newException)"
                          />
                          <p>:</p>
                          <input
                            :value="
                              9 >= newException.start_minute
                                ? '0' + newException.start_minute
                                : newException.start_minute
                            "
                            @input="changeStartMinute($event, newException)"
                          />
                        </div>
                        <div class="d-flex justify-content-center align-items-center content-date">
                          <div>
                            <button
                              type="button"
                              @click="newException.start_hour++"
                              class="up-time"
                            ></button>
                            <p @click="statusRange.showNoteStartHour = true">
                              {{
                                9 >= newException.start_hour
                                  ? '0' + newException.start_hour
                                  : newException.start_hour
                              }}
                            </p>
                            <button
                              type="button"
                              @click="newException.start_hour--"
                              class="down-time"
                            ></button>
                          </div>
                          <div>:</div>
                          <div>
                            <button
                              type="button"
                              @click="newException.start_minute++"
                              class="up-time"
                            ></button>
                            <p @click="statusRange.showNoteStartMinute = true">
                              {{
                                9 >= newException.start_minute
                                  ? '0' + newException.start_minute
                                  : newException.start_minute
                              }}
                            </p>
                            <button
                              type="button"
                              @click="newException.start_minute--"
                              class="down-time"
                            ></button>
                          </div>
                        </div>
                        <div
                          v-if="statusRange.showNoteStartHour"
                          class="select-time"
                          :class="{ 'select-time-change': !isShownCheckBox }"
                        >
                          <input
                            readonly
                            v-for="(hour, index) in hours"
                            :key="index"
                            :value="hour"
                            @click="chooseStartHour(hour, newException)"
                          />
                        </div>
                        <div
                          v-if="statusRange.showNoteStartMinute"
                          class="select-time select-minute"
                          :class="{ 'select-time-change': !isShownCheckBox }"
                        >
                          <input
                            readonly
                            v-for="(minute, index) in minutes"
                            :key="index"
                            :value="minute"
                            @click="chooseStartMinute(minute, newException)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="word-time">{{ $t('to') }}</div>
                    <div class="time-picker">
                      <div class="body-date">
                        <div class="d-flex input-date">
                          <input
                            :value="
                              9 >= newException.end_hour
                                ? '0' + newException.end_hour
                                : newException.end_hour
                            "
                            @input="changeEndHour($event, newException)"
                          />
                          <p>:</p>
                          <input
                            :value="
                              9 >= newException.end_minute
                                ? '0' + newException.end_minute
                                : newException.end_minute
                            "
                            @input="changeEndMinute($event, editException)"
                          />
                        </div>
                        <div class="d-flex justify-content-center align-items-center content-date">
                          <div>
                            <button
                              type="button"
                              @click="newException.end_hour++"
                              class="up-time"
                            ></button>
                            <p @click="statusRange.showNoteEndHour = true">
                              {{
                                9 >= newException.end_hour
                                  ? '0' + newException.end_hour
                                  : newException.end_hour
                              }}
                            </p>
                            <button
                              type="button"
                              @click="newException.end_hour--"
                              class="down-time"
                            ></button>
                          </div>
                          <div>:</div>
                          <div>
                            <button
                              type="button"
                              @click="newException.end_minute++"
                              class="up-time"
                            ></button>
                            <p @click="statusRange.showNoteEndMinute = true">
                              {{
                                9 >= newException.end_minute
                                  ? '0' + newException.end_minute
                                  : newException.end_minute
                              }}
                            </p>
                            <button
                              type="button"
                              @click="newException.end_minute--"
                              class="down-time"
                            ></button>
                          </div>
                        </div>
                        <div
                          v-if="statusRange.showNoteEndHour"
                          class="select-time"
                          :class="{ 'select-time-change': !isShownCheckBox }"
                        >
                          <input
                            readonly
                            v-for="(hour, index) in hours"
                            :key="index"
                            :value="hour"
                            @click="chooseEndHour(hour, newException)"
                          />
                        </div>
                        <div
                          v-if="statusRange.showNoteEndMinute"
                          class="select-time select-minute"
                          :class="{ 'select-time-change': !isShownCheckBox }"
                        >
                          <input
                            readonly
                            v-for="(minute, index) in minutes"
                            :key="index"
                            :value="minute"
                            @click="chooseEndMinute(minute, newException)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-24">
              <div class="col-10">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">{{ $t('days') }}</label>
                  </div>
                  <div class="w-100">
                    <!-- <span @click="chooseEveryDay"
                    :class="{'change-background' : isSelectedEveryDay}"></span> -->
                    <label class="sf-checkbox">
                      <span class="check-title">{{ $t('every-day') }}</span>
                      <input
                        v-model="isSelectedEveryDay"
                        @click="chooseEveryDay(newException)"
                        type="checkbox"
                        checked="checked"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label"></label>
                  </div>
                  <div class="d-flex sf-day-picker w-100">
                    <div
                      v-for="(day, i) in days"
                      :key="i"
                      @click="chooseDay(day, newException)"
                      :class="{ 'day-chosen': day.isChoose }"
                    >
                      <p>{{ day.name }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sf-modal-footer mt-24">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button class="sf-secondary mr-2" @click.prevent="isShownModalCreate = false">
                    {{ $t('cancel') }}
                  </button>
                  <button type="submit" class="sf-primary">{{ $t('add-new') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </SfModal>
    <SfModal
      :title="$t('modal.title.edit-exception')"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalUpdate"
    >
      <form @submit.prevent="checkFormUpdate">
        <div v-if="showError">
          <p v-for="(error, index) in listErrors" :key="index" class="text-danger">{{ error }}</p>
        </div>

        <div class="sf-modal-body sf-modal-exception">
          <div class="container">
            <div class="row mt-24">
              <div class="col-10">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">{{ $t('exception-name') }}</label>
                  </div>
                  <input
                    class="sf-input-text"
                    v-model="editException.title"
                    :placeholder="$t('placeholder.name-exception')"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-24">
              <div class="col-10">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">{{ $t('choose-exception-loop') }}</label>
                  </div>
                  <div class="sf-select-group exception-input">
                    <Select2
                      class="sf-select2"
                      :value="editException.loop_asset_id"
                      @select="updateLoopException"
                      :options="loopItemFilter"
                    ></Select2>
                    <span class="lb-right caret"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-24">
              <div class="col-10">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">{{ $t('always') }}</label>
                  </div>
                  <div class="w-100">
                    <!-- <span @click="isShownCheckBox =! isShownCheckBox"
                    :class="{'change-background' : isShownCheckBox}"></span> -->
                    <label class="sf-checkbox">
                      <span class="check-title">{{ $t('yes') }}</span>
                      <input v-model="isShownCheckBox" type="checkbox" checked="checked" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-24" v-if="!isShownCheckBox">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">{{ $t('date-range') }}</label>
                  </div>
                  <div class="w-100 d-flex select-date align-items-center justify-content-between">
                    <datepicker class="date-picker" v-model="dateStart"></datepicker>
                    <div class="to-word">{{ $t('to') }}</div>
                    <datepicker class="date-picker" v-model="dateEnd"></datepicker>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-24">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg label-top">
                    <label class="primary-label">{{ $t('time-range') }}</label>
                    <label class="secondary-label">(24 {{ $t('hours') }})</label>
                  </div>
                  <div class="sf-exception-time-picker">
                    <div class="time-picker">
                      <div class="body-date">
                        <div class="d-flex input-date">
                          <input
                            :value="
                              9 >= editException.start_hour
                                ? '0' + editException.start_hour
                                : editException.start_hour
                            "
                            @input="changeStartHour($event, editException)"
                          />
                          <p>:</p>
                          <input
                            :value="
                              9 >= editException.start_minute
                                ? '0' + editException.start_minute
                                : editException.start_minute
                            "
                            @input="changeStartMinute($event, editException)"
                          />
                        </div>
                        <div class="d-flex justify-content-center align-items-center content-date">
                          <div>
                            <button
                              type="button"
                              @click="editException.start_hour++"
                              class="up-time"
                            ></button>
                            <p @click="statusRange.showNoteStartHour = true">
                              {{
                                9 >= editException.start_hour
                                  ? '0' + editException.start_hour
                                  : editException.start_hour
                              }}
                            </p>
                            <button
                              type="button"
                              @click="editException.start_hour--"
                              class="down-time"
                            ></button>
                          </div>
                          <div>:</div>
                          <div>
                            <button
                              type="button"
                              @click="editException.start_minute++"
                              class="up-time"
                            ></button>
                            <p @click="statusRange.showNoteStartMinute = true">
                              {{
                                9 >= editException.start_minute
                                  ? '0' + editException.start_minute
                                  : editException.start_minute
                              }}
                            </p>
                            <button
                              type="button"
                              @click="editException.start_minute--"
                              class="down-time"
                            ></button>
                          </div>
                        </div>
                        <div
                          v-if="statusRange.showNoteStartHour"
                          class="select-time"
                          :class="{ 'select-time-change': !isShownCheckBox }"
                        >
                          <input
                            readonly
                            v-for="(hour, index) in hours"
                            :key="index"
                            :value="hour"
                            @click="chooseStartHour(hour, editException)"
                          />
                        </div>
                        <div
                          v-if="statusRange.showNoteStartMinute"
                          class="select-time select-minute"
                          :class="{ 'select-time-change': !isShownCheckBox }"
                        >
                          <input
                            readonly
                            v-for="(minute, index) in minutes"
                            :key="index"
                            :value="minute"
                            @click="chooseStartMinute(minute, editException)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="word-time">{{ $t('to') }}</div>
                    <div class="time-picker">
                      <div class="body-date">
                        <div class="d-flex input-date">
                          <input
                            :value="
                              9 >= editException.end_hour
                                ? '0' + editException.end_hour
                                : editException.end_hour
                            "
                            @input="changeEndHour($event, editException)"
                          />
                          <p>:</p>
                          <input
                            :value="
                              9 >= editException.end_minute
                                ? '0' + editException.end_minute
                                : editException.end_minute
                            "
                            @input="changeEndMinute($event, editException)"
                          />
                        </div>
                        <div class="d-flex justify-content-center align-items-center content-date">
                          <div>
                            <button
                              type="button"
                              @click="editException.end_hour++"
                              class="up-time"
                            ></button>
                            <p @click="statusRange.showNoteEndHour = true">
                              {{
                                9 >= editException.end_hour
                                  ? '0' + editException.end_hour
                                  : editException.end_hour
                              }}
                            </p>
                            <button
                              type="button"
                              @click="editException.end_hour--"
                              class="down-time"
                            ></button>
                          </div>
                          <div>:</div>
                          <div>
                            <button
                              type="button"
                              @click="editException.end_minute++"
                              class="up-time"
                            ></button>
                            <p @click="statusRange.showNoteEndMinute = true">
                              {{
                                9 >= editException.end_minute
                                  ? '0' + editException.end_minute
                                  : editException.end_minute
                              }}
                            </p>
                            <button
                              type="button"
                              @click="editException.end_minute--"
                              class="down-time"
                            ></button>
                          </div>
                        </div>
                        <div
                          v-if="statusRange.showNoteEndHour"
                          class="select-time"
                          :class="{ 'select-time-change': !isShownCheckBox }"
                        >
                          <input
                            readonly
                            v-for="(hour, index) in hours"
                            :key="index"
                            :value="hour"
                            @click="chooseEndHour(hour, editException)"
                          />
                        </div>
                        <div
                          v-if="statusRange.showNoteEndMinute"
                          class="select-time select-minute"
                          :class="{ 'select-time-change': !isShownCheckBox }"
                        >
                          <input
                            readonly
                            v-for="(minute, index) in minutes"
                            :key="index"
                            :value="minute"
                            @click="chooseEndMinute(minute, editException)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-24">
              <div class="col-10">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">{{ $t('days') }}</label>
                  </div>
                  <div class="w-100">
                    <!-- <span @click="chooseEveryDay"
                    :class="{'change-background' : isSelectedEveryDay}"></span> -->
                    <label class="sf-checkbox">
                      <span class="check-title">{{ $t('every-day') }}</span>
                      <input
                        v-model="isSelectedEveryDay"
                        @click="chooseEveryDay(editException)"
                        type="checkbox"
                        checked="checked"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label"></label>
                  </div>
                  <div class="d-flex sf-day-picker w-100">
                    <div
                      v-for="(day, i) in days"
                      :key="i"
                      @click="chooseDay(day, editException)"
                      :class="{ 'day-chosen': day.isChoose }"
                    >
                      <p>{{ day.name }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sf-modal-footer mt-24">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button class="sf-secondary mr-2" @click="isShownModalUpdate = false">
                    {{ $t('cancel') }}
                  </button>
                  <button type="submit" class="sf-primary">{{ $t('update') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </SfModal>
    <SfWarningModal
      :title="$t('modal.title.warning')"
      closeOnOverlay
      :show.sync="isShownModalUnSupport"
    >
      <div class="content-delete">
        <template v-if="unsupportedType === 'asset_unallow'">
          <p
            class="attempting-use-asset mb-3"
            v-html="$t('campaign-attempting-use-asset', { asset: slide_template_unallow })"
          ></p>
          <ul class="mb-3">
            <li class="player-name" v-for="player in players_unallow" :key="player">
              {{ player }}
            </li>
          </ul>
          <p
            class="contact-to-upgrade mb-3"
            v-html="
              $t('campaign-contact-to-upgrade', {
                asset: slide_template_unallow,
                device: hardware_require
              })
            "
          ></p>
        </template>
        <template v-else-if="unsupportedType === 'pi_firmware_require'">
          <p
            class="attempting-use-asset mb-3"
            v-html="
              $t('campaign-attempting-version-unsupported', { asset: slide_template_unallow })
            "
          ></p>
          <ul class="mb-3">
            <li class="player-name" v-for="player in players_unallow" :key="player">
              {{ player }}
            </li>
          </ul>
          <p
            class="contact-to-upgrade mb-3"
            v-html="
              $t('campaign-contact-to-upgrade-version', {
                asset: slide_template_unallow,
                applicationVersion: pi_firmware_require
              })
            "
          ></p>
        </template>
        <p class="cantact-number">{{ $t('tell') }}: (844) 772-7336</p>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-primary" @click="isShownModalUnSupport = false">{{ $t('ok') }}</button>
      </div>
    </SfWarningModal>

    <SfWarningModal
      :title="$t('modal.title.warning')"
      closeOnOverlay
      :show.sync="isNewUpdatedBySomeOne"
    >
      <div class="content-delete">
        <p class="cantact-number">
          Someone else has just finished editing this Campaign. Please reload the page to see their
          changes
        </p>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isNewUpdatedBySomeOne = false">
          {{ $t('keep') }}
        </button>
        <button class="sf-primary" @click="reloadPage">{{ $t('refresh') }}</button>
      </div>
    </SfWarningModal>
  </div>
</template>
<script>
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import ClickOutside from 'vue-click-outside'
import draggable from 'vuedraggable'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import SfModal from '@/components/SfModal.vue'
import Datepicker from 'vuejs-datepicker'
import Select2 from 'v-select2-component'
import { mapState, mapMutations } from 'vuex'
import CampaignService from '../../services/campaign.service'
import SfWarningModal from '@/components/SfWarningModal.vue'
import Vue from 'vue'
import { getExceptionDays } from '../../helper/helper'

export default {
  name: 'edit-campaign',
  components: {
    SfDeleteModal,
    SfModal,
    Datepicker,
    draggable,
    Select2,
    SfWarningModal
  },
  data() {
    return {
      inputTitle: '',
      title: 'default',
      changeTag: false,
      titleException: 'default',
      isShownModalDelete: false,
      isRotated: false,
      isShownLoop: false,
      isShownModalCreate: false,
      isShownModalUpdate: false,
      isShownCheckBox: true,
      isSelectedEveryDay: false,
      dateStart: new Date(),
      dateEnd: new Date(),
      time: {
        startHour: 0,
        startMinute: 0,
        endHour: 0,
        endMinute: 0
      },
      days: [
        { name: this.$t('days-in-week.sun'), isChoose: false },
        { name: this.$t('days-in-week.mon'), isChoose: false },
        { name: this.$t('days-in-week.tue'), isChoose: false },
        { name: this.$t('days-in-week.wed'), isChoose: false },
        { name: this.$t('days-in-week.thu'), isChoose: false },
        { name: this.$t('days-in-week.fri'), isChoose: false },
        { name: this.$t('days-in-week.sat'), isChoose: false }
      ],
      hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      minutes: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
      statusRange: {
        showNoteStartHour: false,
        showNoteStartMinute: false,
        showNoteEndHour: false,
        showNoteEndMinute: false
      },
      countDayChoose: null,
      campaignID: '',
      newException: {
        title: null,
        id: null,
        start_year: 0,
        start_month: 0,
        start_day: 0,
        start_hour: 0,
        start_minute: 0,
        start_second: 0,
        end_year: 0,
        end_month: 0,
        end_day: 0,
        end_hour: 23,
        end_minute: 59,
        end_second: 0,
        play_info: 'weekly',
        loop_asset_id: 0,
        repeat_info: '',
        // Should remove before sending request
        displayname: '',
        isRotated: false,
        getDayChoose: []
      },
      editException: {
        title: null,
        id: null,
        start_year: 0,
        start_month: 0,
        start_day: 0,
        start_hour: 0,
        start_minute: 0,
        start_second: 0,
        end_year: 0,
        end_month: 0,
        end_day: 0,
        end_hour: 23,
        end_minute: 59,
        end_second: 0,
        play_info: 'weekly',
        loop_asset_id: 0,
        repeat_info: '',
        // Should remove before sending request
        displayname: '',
        isRotated: false,
        getDayChoose: []
      },
      newLoop: {
        title: null,
        id: 0,
        start_year: null,
        start_month: null,
        start_day: null,
        start_hour: null,
        start_minute: null,
        start_second: null,
        end_year: null,
        end_month: null,
        end_day: null,
        end_hour: null,
        end_minute: null,
        end_second: null,
        loop_asset_id: 0,
        repeat_info: null,
        play_info: 'main',
        // add to show client
        displayname: null
      },
      idException: null,
      listErrors: [],
      showError: false,
      currentDisplayname: '',
      currentOrientation: '',
      listLoop: [],
      from: '',
      lengthListPlaceholder: 5,
      lengthListPlaceholderException: 2,
      user: {},
      players_unallow: [],
      slide_template_unallow: '',
      hardware_require: '',
      isShownModalUnSupport: false,
      unsupportedType: '',
      pi_firmware_require: '',
      isNewUpdatedBySomeOne: false
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from
    })
  },
  methods: {
    ...mapMutations('campaign', {
      createLoopAssetFromSocket: 'CREATE_LOOP_ASSET_FROM_SOCKET',
      deleteLoopAssetFromSocket: 'DELETE_LOOP_ASSET_FROM_SOCKET',
      updateLoopAssetFromSocket: 'UPDATE_LOOP_ASSET_FROM_SOCKET',
      updateDisplaynameFromSocket: 'UPDATE_DISPLAYNAME',
      setItemsMainLoops: 'SET_ITEMS_MAIN_LOOPS'
    }),
    addMoreLoop() {
      this.$store.dispatch('campaign/addLoopItems', { ...this.newLoop })
    },
    setRangeTime() {
      var exception = this.newException
      if (this.isShownModalUpdate) {
        exception = this.editException
      }
      if (exception.start_hour < 0) {
        exception.start_hour = 23
      } else if (exception.start_minute < 0) {
        exception.start_minute = 59
        exception.start_hour--
      } else if (exception.end_hour < 0) {
        exception.end_hour = 23
      } else if (exception.end_minute < 0) {
        exception.end_minute = 59
        exception.end_hour--
      } else if (exception.start_hour > 23) {
        exception.start_hour = 0
      } else if (exception.start_minute > 59) {
        exception.start_minute = 0
        exception.start_hour++
      } else if (exception.end_hour > 23) {
        exception.end_hour = 0
      } else if (exception.end_minute > 59) {
        exception.end_minute = 0
        exception.end_hour++
      }
    },
    chooseDay(day, object) {
      day.isChoose = !day.isChoose
      this.countDayChoose = this.days.filter((obj) => {
        return obj.isChoose === true
      }).length
      if (this.countDayChoose === 7) {
        this.isSelectedEveryDay = true
      } else {
        this.isSelectedEveryDay = false
      }
      object.getDayChoose = this.days.filter((obj) => {
        return obj.isChoose === true
      })
      object.repeat_info = ''
      this.parseDaysOfWeekToString(this.days, object)
    },
    chooseEveryDay(object) {
      this.isSelectedEveryDay = !this.isSelectedEveryDay
      if (this.isSelectedEveryDay) {
        this.selectAllDay(object)
      } else {
        this.selectNone(object)
      }
    },
    selectAllDay(object) {
      this.days.map((day) => {
        day.isChoose = true
      })
      this.countDayChoose = this.days.filter((obj) => {
        return obj.isChoose === true
      }).length
      object.getDayChoose = this.days.filter((obj) => {
        return obj.isChoose === true
      })
      object.repeat_info = ''
      this.parseDaysOfWeekToString(this.days, object)
    },
    selectNone(object) {
      this.days.map((day) => {
        day.isChoose = false
      })
      this.countDayChoose = this.days.filter((obj) => {
        return obj.isChoose === true
      }).length
      object.getDayChoose = this.days.filter((obj) => {
        return obj.isChoose === true
      })
      object.repeat_info = ''
      this.parseDaysOfWeekToString(this.days, object)
    },
    chooseStartHour(hour, object) {
      object.start_hour = hour
      this.statusRange.showNoteStartHour = false
    },
    chooseStartMinute(minute, object) {
      object.start_minute = minute
      this.statusRange.showNoteStartMinute = false
    },
    chooseEndHour(hour, object) {
      object.end_hour = hour
      this.statusRange.showNoteEndHour = false
    },
    chooseEndMinute(minute, object) {
      object.end_minute = minute
      this.statusRange.showNoteEndMinute = false
    },
    fetchDataCampaignByID(id) {
      this.$store.dispatch('campaign/getCampaignByID', id)
    },
    fetchDataLoops() {
      this.$store.dispatch('campaign/getAllLoops', this.campaignID)
    },
    displayName({ displayname }) {
      return `${displayname}`
    },
    removeMainLoop(index) {
      this.$store.dispatch('campaign/removeLoop', index)
    },
    onChange(value, index) {
      if (index === 0) {
        if (value.orientation === this.currentOrientation) {
          this.$store.dispatch('campaign/chooseLoop', { value, index })
        } else if (
          !this.itemByID.list_of_exeption.length &&
          this.itemByID.main_loops.length === 1
        ) {
          this.$store.dispatch('campaign/chooseLoop', { value, index })
          this.currentOrientation = value.orientation
        } else {
          if (this.itemByID.list_of_exeption && this.itemByID.list_of_exeption.length) {
            this.fetchDataLoops()
            this.$toast.error(this.$t('delete-exceptions-before-changing-orientation'))
          } else if (this.itemByID.main_loops && this.itemByID.main_loops.length > 1) {
            this.fetchDataLoops()
            this.$toast.error(this.$t('delete-loops-before-change-orientation'))
          }
        }
      } else {
        if (value.orientation === this.currentOrientation) {
          this.$store.dispatch('campaign/chooseLoop', { value, index })
        } else {
          this.fetchDataLoops()
          this.$toast.error(this.$t('loops-orientation-must-match'))
        }
      }
    },
    onChangeLoops(value, index) {
      if (index === 0) {
        if (value.orientation === this.currentOrientation) {
          this.$store.dispatch('campaign/chooseAddLoop', { value, index })
        } else if (
          !this.itemByID.list_of_exeption.length &&
          this.itemByID.main_loops.length === 1
        ) {
          this.$store.dispatch('campaign/chooseAddLoop', { value, index })
          this.currentOrientation = value.orientation
        } else {
          if (this.itemByID.list_of_exeption && this.itemByID.list_of_exeption.length) {
            this.fetchDataLoops()
            this.$toast.error(this.$t('delete-exceptions-before-changing-orientation'))
          } else if (this.itemByID.main_loops && this.itemByID.main_loops.length > 1) {
            this.fetchDataLoops()
            this.$toast.error(this.$t('delete-loops-before-change-orientation'))
          }
        }
      } else {
        if (value.orientation === this.currentOrientation) {
          this.$store.dispatch('campaign/chooseAddLoop', { value, index })
        } else {
          this.fetchDataLoops()
          this.$toast.error(this.$t('loops-orientation-must-match'))
        }
      }
    },
    onChangeFirstLoop(value, index) {
      this.$store.dispatch('campaign/chooseAddFirstLoop', { value, index })
      this.currentOrientation = value.orientation
    },
    chooseException(value) {
      this.newException.displayname = value.text
      this.newException.loop_asset_id = value.id
    },
    updateLoopException(value) {
      this.editException.displayname = value.text
      this.editException.loop_asset_id = value.id
    },
    addMoreException() {
      this.newException.id = `new-${
        this.$store.state.campaign.itemByID.list_of_exeption.length + 1
      }`
      this.$store.dispatch('campaign/addMoreException', { ...this.newException })
      this.isShownModalCreate = false
    },
    cloneException(exception) {
      const newException = JSON.parse(JSON.stringify(exception))
      if (newException.loop_asset) {
        newException.loop_asset_id = newException.loop_asset.id
        newException.displayname = newException.loop_asset.displayname
      }
      newException.id = `new-${this.$store.state.campaign.itemByID.list_of_exeption.length + 1}`
      this.$store.dispatch('campaign/cloneException', { ...newException })
    },
    updateException() {
      this.editException.loop_asset = {
        id: this.editException.loop_asset_id,
        displayname: this.editException.displayname
      }
      this.$store.dispatch('campaign/updateException', { ...this.editException })
      this.isShownModalUpdate = false
    },
    parseDaysOfWeekToString(days, object) {
      object.repeat_info = ''
      days.map((day) => {
        if (day.isChoose) {
          object.repeat_info = object.repeat_info + '1'
        } else {
          object.repeat_info = object.repeat_info + '0'
        }
      })
    },
    updateCampaign() {
      if (this.itemByID.displayname) {
        let updateError = false
        const campaign_id = this.campaignID
        const params = {
          displayname: '',
          hooks_json_string: []
        }
        const shallowItemByID = JSON.parse(JSON.stringify(this.itemByID))

        // handle params here;
        for (let i = 0; i < shallowItemByID.list_of_exeption.length; i++) {
          shallowItemByID.list_of_exeption[i].position = i
        }
        params.displayname = shallowItemByID.displayname
        // removing hasn't selected loop
        const mainLoopsFormat = shallowItemByID.main_loops.filter(
          (loop) => !isEqual(loop, this.newLoop)
        )
        this.setItemsMainLoops(mainLoopsFormat)
        mainLoopsFormat.map((loop) => {
          params.hooks_json_string.push(loop)
        })
        shallowItemByID.list_of_exeption.map((list) => {
          if (typeof list.id === 'string' && list.id.includes('new')) {
            list.id = ''
          }
          params.hooks_json_string.push(list)
        })
        params.hooks_json_string.map((param) => {
          delete param.text
          if (param.hasOwnProperty('loop_asset')) {
            param.loop_asset_id = param.loop_asset.id
            delete param.loop_asset
            delete param.campaign_id
          }
          if (param.hasOwnProperty('repeat_info_render')) {
            delete param.isRotated
            delete param.repeat_info_render
          }
          if (param.hasOwnProperty('loop_asset_id')) {
            delete param.displayname
          }
          if (param.hasOwnProperty('getDayChoose')) {
            delete param.getDayChoose
            delete param.displayname
            delete param.isRotated
          }
          if (param.loop_asset_id === '0') {
            updateError = true
          }
          for (let key in param) {
            let exceptionKey = [
              'start_hour',
              'start_minute',
              'start_second',
              'end_hour',
              'end_minute',
              'end_second'
            ]
            if (!exceptionKey.includes(key) && (param[key] === 0 || param[key] === '')) {
              param[key] = null
            }
          }
        })
        if (params.hooks_json_string.length > 0) {
          params.hooks_json_string = JSON.stringify(params.hooks_json_string)
        } else {
          updateError = true
        }
        let data = { campaign: params }
        if (updateError && this.itemByID.player_count > 0) {
          this.$toast.error('Campaigns require at least one default loop')
        } else {
          CampaignService.editCampaignByID(data, campaign_id)
            .then((res) => {
              this.$toast.success(`${res.message || 'Campaign successfully updated'}`)
              this.$router.push('/campaigns')
            })
            .catch((error) => {
              if (
                error.data &&
                ['pi_firmware_require', 'asset_unallow'].includes(error.data.type)
              ) {
                this.unsupportedType = error.data.type
                this.players_unallow = error.data.players_unallow
                let asset = error.data.slide_template_unallow.length > 1 ? ' assets' : ' asset'
                this.slide_template_unallow =
                  `<span style="font-weight: 600;">${error.data.slide_template_unallow.join(
                    ', '
                  )}</span>` + asset
                this.hardware_require = `<span style="font-weight: 600;">${error.data.hardware_require.displayname}</span>`
                this.pi_firmware_require = `<span style="font-weight: 600;">${error.data.pi_firmware_require}</span>`
                this.isShownModalUnSupport = true
              } else if (error.message) {
                this.$toast.open({
                  message: error.message,
                  type: 'error'
                })
              }
            })
        }
      } else {
        return this.$toast.error('Name required')
      }
    },
    deleteException(index) {
      this.isShownModalDelete = true
      this.idException = index
    },
    removeException() {
      this.$store.dispatch('campaign/removeException', this.idException)
      this.isShownModalDelete = false
    },
    changeRotated(index) {
      this.$store.dispatch('campaign/changeRotated', index)
    },
    turnOffEditedTitle(index) {
      this.$store.dispatch('campaign/turnOffEditedTitle', index)
      this.idException = index
    },
    updateTitle(e) {
      let value = e.target.value
      let indexException = this.idException
      this.$store.dispatch('campaign/updateTitle', { value, indexException })
    },
    checkDateEqual(dateStart, dateEnd) {
      let checkDateStart = dateStart.toISOString().replace('-', '/').split('T')[0].replace('-', '/')
      let checkDateEnd = dateEnd.toISOString().replace('-', '/').split('T')[0].replace('-', '/')
      if (checkDateStart <= checkDateEnd) {
        return true
      }
      return false
    },
    checkForm(e) {
      if (!this.newException.displayname && !this.countDayChoose) {
        this.listErrors = []
        this.listErrors.push(this.$t('loop-exception-required'))
        e.preventDefault()
      } else if (!this.countDayChoose) {
        this.listErrors = []
        this.listErrors.push(this.$t('day-exception-required'))
        e.preventDefault()
      } else if (!this.newException.displayname) {
        this.listErrors = []
        this.listErrors.push(this.$t('loop-exception-required'))
        e.preventDefault()
      } else if (!this.isShownCheckBox && !this.checkDateEqual(this.dateStart, this.dateEnd)) {
        this.listErrors = []
        this.listErrors.push(this.$t('date-exception-required'))
      } else {
        this.addMoreException()
      }
      this.showError = true
    },
    checkFormUpdate(e) {
      if (!this.editException.displayname && !this.countDayChoose) {
        this.listErrors = []
        this.listErrors.push(this.$t('loop-exception-required'))
        e.preventDefault()
      } else if (!this.countDayChoose) {
        this.listErrors = []
        this.listErrors.push(this.$t('day-exception-required'))
        e.preventDefault()
      } else if (!this.editException.displayname) {
        this.listErrors = []
        this.listErrors.push(this.$t('loop-exception-required'))
        e.preventDefault()
      } else if (!this.isShownCheckBox && !this.checkDateEqual(this.dateStart, this.dateEnd)) {
        this.listErrors = []
        this.listErrors.push(this.$t('date-exception-required'))
      } else {
        this.updateException()
      }
      this.showError = true
    },
    shownModalCreate() {
      this.isShownModalCreate = true
      this.showError = false
      this.newException.title = null
      this.newException.start_hour = 0
      this.newException.start_minute = 0
      this.newException.end_hour = 23
      this.newException.end_minute = 59
      this.newException.displayname = null
      this.days = [
        { name: this.$t('days-in-week.sun'), isChoose: false },
        { name: this.$t('days-in-week.mon'), isChoose: false },
        { name: this.$t('days-in-week.tue'), isChoose: false },
        { name: this.$t('days-in-week.wed'), isChoose: false },
        { name: this.$t('days-in-week.thu'), isChoose: false },
        { name: this.$t('days-in-week.fri'), isChoose: false },
        { name: this.$t('days-in-week.sat'), isChoose: false }
      ]
      this.countDayChoose = null
      this.isShownCheckBox = true
    },
    shownModalUpdate(exception) {
      this.isShownModalUpdate = true
      this.showError = false
      this.editException = Vue._.cloneDeep(exception)
      this.editException.displayname = exception.loop_asset
        ? exception.loop_asset.displayname
        : exception.displayname
      this.editException.loop_asset_id = exception.loop_asset
        ? exception.loop_asset.id
        : exception.loop_asset_id
      this.editException.repeat_info_render = getExceptionDays(this.editException.repeat_info)
      this.days = [
        {
          name: this.$t('days-in-week.sun'),
          isChoose: this.editException.repeat_info_render.includes(this.$t('days-in-week.sun'))
        },
        {
          name: this.$t('days-in-week.mon'),
          isChoose: this.editException.repeat_info_render.includes(this.$t('days-in-week.mon'))
        },
        {
          name: this.$t('days-in-week.tue'),
          isChoose: this.editException.repeat_info_render.includes(this.$t('days-in-week.tue'))
        },
        {
          name: this.$t('days-in-week.wed'),
          isChoose: this.editException.repeat_info_render.includes(this.$t('days-in-week.wed'))
        },
        {
          name: this.$t('days-in-week.thu'),
          isChoose: this.editException.repeat_info_render.includes(this.$t('days-in-week.thu'))
        },
        {
          name: this.$t('days-in-week.fri'),
          isChoose: this.editException.repeat_info_render.includes(this.$t('days-in-week.fri'))
        },
        {
          name: this.$t('days-in-week.sat'),
          isChoose: this.editException.repeat_info_render.includes(this.$t('days-in-week.sat'))
        }
      ]
      this.parseDaysOfWeekToString(this.days, this.editException)
      this.countDayChoose = this.editException.repeat_info_render.split(' ').length
      if (this.editException.start_year && this.editException.end_year) {
        this.isShownCheckBox = false
        this.dateStart = new Date(
          `${this.editException.start_year}-${this.editException.start_month}-${this.editException.start_day}`
        )
        this.dateEnd = new Date(
          `${this.editException.end_year}-${this.editException.end_month}-${this.editException.end_day}`
        )
      } else {
        this.isShownCheckBox = true
      }
    },
    changeDisplayname(e) {
      this.currentDisplayname = e.target.value
    },
    updateDisplayname() {
      this.changeTag = false
      this.$store.dispatch('campaign/updateDisplayname', {
        displayname: this.currentDisplayname,
        id: this.campaignID
      })
    },
    cancelChangeDisplayname() {
      this.changeTag = false
      this.currentDisplayname = this.itemByID.displayname
    },
    changeStartHour(e, object) {
      object.start_hour = parseInt(e.target.value ? e.target.value : '0')
      this.setRangeTime()
      if (!object.start_hour) {
        object.start_hour = 0
      }
    },
    changeStartMinute(e, object) {
      object.start_minute = parseInt(e.target.value ? e.target.value : '0')
      this.setRangeTime()
      if (!object.start_minute) {
        object.start_minute = 0
      }
    },
    changeEndHour(e, object) {
      object.end_hour = parseInt(e.target.value ? e.target.value : '0')
      this.setRangeTime()
      if (!object.end_hour) {
        object.end_hour = 0
      }
    },
    changeEndMinute(e, object) {
      object.end_minute = parseInt(e.target.value ? e.target.value : '0')
      this.setRangeTime()
      if (!object.end_minute) {
        object.end_minute = 0
      }
    },
    backCampaignPage() {
      this.$router.push({ name: 'campaign' })
    },
    updateSlideFromSocket() {
      this.$cable.perform({
        channel: 'CampaignEditorChannel',
        action: 'add_loop_to_campaign',
        data: {
          data: this.itemByID
        }
      })
    },
    getUser() {
      let user = this.$cookies.get('user')
      if (user) {
        this.user = user
      }
    },
    reloadPage() {
      this.fetchDataLoops()
      this.fetchDataCampaignByID(this.campaignID)
      this.isNewUpdatedBySomeOne = false
    }
  },
  directives: {
    ClickOutside
  },
  watch: {
    'newException.start_hour': function () {
      this.setRangeTime()
    },
    'newException.start_minute': function () {
      this.setRangeTime()
    },
    'newException.end_hour': function () {
      this.setRangeTime()
    },
    'newException.end_minute': function () {
      this.setRangeTime()
    },
    isShownCheckBox: function () {
      var exception = this.newException
      if (this.isShownModalUpdate) {
        exception = this.editException
      }
      if (this.isShownCheckBox) {
        exception.play_info = 'weekly'
        exception.start_day = 0
        exception.end_day = 0
        exception.start_month = 0
        exception.end_month = 0
        exception.start_year = 0
        exception.end_year = 0
      } else {
        exception.start_day = this.dateStart.getDate()
        exception.start_month = this.dateStart.getMonth() + 1
        exception.start_year = this.dateStart.getFullYear()
        exception.end_day = this.dateEnd.getDate()
        exception.end_month = this.dateEnd.getMonth() + 1
        exception.end_year = this.dateEnd.getFullYear()
        exception.play_info = 'weekly'
      }
    },
    dateStart: function () {
      var exception = this.newException
      if (this.isShownModalUpdate) {
        exception = this.editException
      }
      exception.start_day = this.dateStart.getDate()
      exception.start_month = this.dateStart.getMonth() + 1
      exception.start_year = this.dateStart.getFullYear()
    },
    dateEnd: function () {
      var exception = this.newException
      if (this.isShownModalUpdate) {
        exception = this.editException
      }
      exception.end_day = this.dateEnd.getDate()
      exception.end_month = this.dateEnd.getMonth() + 1
      exception.end_year = this.dateEnd.getFullYear()
    },
    itemByID(value) {
      if (value.main_loops && value.main_loops.length) {
        this.currentOrientation = value.main_loops[0].loop_asset.orientation
      }
    },
    '$i18n.locale'() {
      this.fetchDataLoops()
      this.fetchDataCampaignByID(this.campaignID)
    }
  },
  computed: {
    ...mapState('campaign', {
      itemByID: 'itemByID',
      loopItems: 'loopItems',
      defaultLoopItems: 'defaultLoopItems',
      error: 'error',
      loading: 'loading',
      isEmpty: 'isEmpty'
    }),
    listException: {
      get() {
        return this.$store.state.campaign.itemByID.list_of_exeption
      },
      set(value) {
        this.$store.dispatch('campaign/setData', value)
      }
    },
    loopItemFilter() {
      let items = cloneDeep(this.loopItems)
      if (isEmpty(items)) return []
      items = items.filter((loop) => {
        return loop.orientation === this.currentOrientation
      })
      return items
    }
  },
  channels: {
    CampaignChannel: {
      connected() {},
      rejected() {},
      received(data) {
        this.isNewUpdatedBySomeOne =
          this.user.id !== data.user_id && this.campaignID === data.data.campaign_code
      },
      disconnected() {}
    }
  },
  mounted() {
    this.campaignID = this.$route.params.campaignID
    this.getUser()
    this.$cable.subscribe({
      channel: 'CampaignChannel',
      organization: this.user.organization_id
    })
    this.fetchDataLoops()
    this.fetchDataCampaignByID(this.campaignID)
  },
  beforeDestroy() {
    this.$cable.unsubscribe('CampaignEditorChannel')
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import '../campaign/editCampaign.scss';
.cantact-number {
  font-size: 22px;
  font-weight: 600;
  color: #2c7fe1;
}
.player-name {
  color: #2c7fe1;
}
.no-exception {
  background-color: #fffef0;
  border-radius: 0px;
  height: 51px;
  padding: 10px 15px;
  color: #6a70c1;
  border: 1px solid #e3e3e3;
  width: 98% !important;
  margin-left: 1% !important;
}
.list-complete-enter,
.list-complete-leave-active {
  opacity: 0;
}
.drag-item {
  cursor: -moz-grab;
  cursor: -webkit-grab;
  transition: transform 0.25s cubic-bezier(0.02, 1.01, 0.94, 1.01) !important;
  will-change: transform !important;
}
.text-danger {
  font: 14px 'Open Sans', sans-serif;
  margin-top: 20px;
  margin-left: 40px;
}

.days-choosen {
  display: flex;
  flex-wrap: wrap;
  p {
    margin-left: 2.5px !important;
  }
}
.time-picker {
  width: 47%;
}
.select-date {
  .date-picker {
    width: 47%;
  }
  .to-word {
    padding: 5px 8px;
  }
}
.word-time {
  padding-left: 8px;
  padding-right: 8px;
}
.custom-placeholder {
  .vue-content-placeholders-text__line {
    height: 30px !important;
  }
}
.custom-class {
  width: 150px;
  height: auto;
}
.header-edit {
  width: 98%;
}
.exception-input {
  .sf-select2 {
    width: 348px;
  }
}
.edit-title {
  position: relative;
  top: 8px;
}
h4.title {
  word-break: break-word;
}
</style>
