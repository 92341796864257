<template>
  <div class="main-term">
    <section class="content_wrapper">
      <div class="content">
        <div class="container">
          <h4>{{ $t('change-your-password') }}</h4>
          <div class="row mt-3">
            <div class="col-12">
              <div
                class="sf-form-group"
                :class="{ 'input-error': submitted && $v.password.$invalid }"
              >
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('password') }}</label>
                </div>
                <input
                  id="password"
                  class="sf-input-text"
                  type="password"
                  v-model="password"
                  :placeholder="$t('password')"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.password.strongPassword">
                {{ $t('strong-password-required') }}
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <div
                class="sf-form-group"
                :class="{ 'input-error': submitted && $v.password_confirm.$invalid }"
              >
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('retype-your-password') }}</label>
                </div>
                <input
                  id="password"
                  class="sf-input-text"
                  type="password"
                  v-model="password_confirm"
                  :placeholder="$t('retype-your-password')"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.password_confirm.sameAsPassword">
                {{ $t('passwords-must-be-identical') }}
              </div>
            </div>
          </div>
          <div class="footer-content d-flex justify-content-end">
            <button class="sf-primary" @click="resetPassWord">
              <div class="text" v-if="!isLoading">
                {{ $t('update') }}
              </div>
              <div v-else class="spinner-border" role="status">
                <span class="sr-only">{{ $t('loading') }}...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import passwordService from '../services/password.service'
import { sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'Login',
  data() {
    return {
      password: '',
      password_confirm: '',
      token: this.$route.query.reset_password_token,
      submitted: false,
      isLoading: false
    }
  },
  validations: {
    password: {
      strongPassword(password) {
        return (
          /[A-Z]/.test(password) && // checks for A-Z
          /[0-9]/.test(password) && // checks for 0-9
          password.length >= 8
        )
      }
    },
    password_confirm: {
      sameAsPassword: sameAs('password')
    }
  },
  methods: {
    resetPassWord() {
      this.submitted = true
      if (this.$v.password.$invalid) {
        return
      }
      this.isLoading = true
      passwordService
        .resetPassword({ password: this.password, token: this.token })
        .then((response) => {
          this.$router.push('/login')
          this.$toast.success(response.message)
          this.isLoading = false
        })
        .catch((error) => {
          this.$toast.error(error.status === 404 ? 'Request not found' : error.message)
          this.isLoading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.main-term {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../src/assets/images/app-background.jpg');
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;

  .content {
    width: 600px;
    height: 300px;
    background-color: #ffffff;
    margin-top: 20%;
    position: relative;

    .container {
      padding-top: 40px;
      padding-left: 20px;

      h4 {
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        color: #2c7fe1;
        font-weight: 400;
        margin: 15px 0 10px;
        text-align: center;
      }

      p {
        color: #626262;
        font: 14px 'Roboto', sans-serif;
      }
    }
  }
}
.footer-content {
  margin-top: 15px;
}
.exception-row {
  margin: 5px 10px 30px 0px;
  padding-left: 0;
  &.password {
    margin-bottom: 35px;
  }
}

.reset-password {
  border: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  width: 150px;
  margin: 0 25px;
  height: 40px;
  border-radius: 20px;
  background-color: #2c7fe1;
  box-shadow: 0 3px 5px 0 rgba(0, 89, 160, 0.18);
  float: right;
}
.input-group {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  height: 39px;
  .input-group-prepend {
    .input-group-text {
      background-color: transparent;
      border-right: none;
      color: #bbb;
      border: none;
      font-size: 12px;
    }
  }
  .form-control {
    border-left: none;
    border: none;
    margin: auto;
    display: block;
    margin: 1px;
    &:focus {
      box-shadow: none;
      border-color: #ced4da;
    }
  }
  .select-custom {
    border: none;
    margin: 1px;
  }
}
@media only screen and (max-width: 500px) {
  .content {
    width: 80% !important;
    margin: 10%;
    margin-left: 10%;
    height: 250px !important;
  }
  .footer-content {
    flex-direction: column;
  }
  .reset-password {
    margin-left: 20px;
    margin-top: 10px;
  }
}
.invalid-error {
  color: #de888a;
  font-size: 12px;
  text-align: right;
}
.input-error {
  border-color: #de888a;
  &:focus {
    border-color: #de888a !important;
  }
}
</style>
