<template>
  <div class="login">
    <div id="large-header" class="large-header">
      <canvas id="demo-canvas"></canvas>
      <div class="main">
        <div class="panel-login">
          <div class="logo">
            <img :src="logo" alt="logo" />
            <p class="title-login">Create an account</p>
          </div>
          <div class="login-form">
            <div class="row mb-2">
              <div class="col-12">
                <div>
                  <label class="primary-label label-w">Company*</label>
                  <input
                    id="name"
                    :disabled="isLoading"
                    placeholder="Company"
                    class="sf-input-text"
                    type="text"
                    v-model="dataInput.name"
                  />
                </div>
                <div
                  class="invalid-error-text text-right error-right"
                  v-if="submitted && !$v.dataInput.name.required"
                >
                  Name is required
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <div>
                  <label class="primary-label label-w">Name*</label>
                  <input
                    id="point_of_contact"
                    :disabled="isLoading"
                    placeholder="Name"
                    class="sf-input-text"
                    type="text"
                    v-model="dataInput.fullName"
                  />
                </div>
                <div
                  class="invalid-error-text text-right error-right"
                  v-if="submitted && !$v.dataInput.fullName.required"
                >
                  Name is required
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <div>
                  <label class="primary-label label-w">Email*</label>
                  <input
                    id="email"
                    :disabled="isLoading"
                    class="sf-input-text"
                    placeholder="name@email.com"
                    type="text"
                    v-model="dataInput.email"
                  />
                </div>
                <div
                  class="invalid-error-text text-right error-right"
                  v-if="submitted && !$v.dataInput.email.required"
                >
                  Email is required
                </div>
                <div
                  class="invalid-error-text text-right error-right"
                  v-else-if="submitted && !$v.dataInput.email.email"
                >
                  Email is invalid
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <div>
                  <label class="primary-label label-w">Password*</label>
                  <input
                    id="password"
                    :disabled="isLoading"
                    placeholder="Create password"
                    autocomplete="new-password"
                    class="sf-input-text"
                    type="password"
                    v-model="dataInput.password"
                  />
                </div>
                <div
                  class="invalid-error-text text-right error-right"
                  v-if="submitted && !$v.dataInput.password.required"
                >
                  Password is required
                </div>
                <div
                  class="invalid-error-text text-right error-right"
                  v-else-if="submitted && !$v.dataInput.password.strongPassword"
                >
                  Password must be at least 8 characters, with at least 1 capital and 1 number
                </div>
              </div>
            </div>
            <div class="row mb-2 row-terms-conditions">
              <div class="col-12 py-2">
                <label class="terms_container">
                  <span
                    class="terms_required"
                    v-if="submitted && !$v.dataInput.acceptTerms.checked"
                  >
                    <i
                      class="fas fa-exclamation-triangle"
                      style="color: #cc3100; width: 12px; height: 6px"
                    ></i
                    ><span class="ml-1">Please tick this box if you want to proceed.</span></span
                  >
                  <input
                    type="checkbox"
                    :class="{ 'invalid-terms': submitted && !$v.dataInput.acceptTerms.checked }"
                    class="ace"
                    name="accept_terms"
                    v-model="dataInput.acceptTerms"
                  />
                  <span class="lbl">
                    <span class="terms-of-service-link">
                      I accept the
                      <a href="https://www.screenfluence.com/terms-and-conditions/" target="_blank"
                        >Terms &amp; Conditions</a
                      >
                    </span>
                  </span>
                </label>
              </div>
            </div>
            <div class="buttons">
              <button class="btn-login" :disabled="isLoading" @click="signUpOrganization">
                <div class="text" v-if="!isLoading">Submit</div>
                <div v-else class="spinner-border" role="status">
                  <span class="sr-only">{{ $t('loading') }}...</span>
                </div>
              </button>
            </div>
          </div>
          <div class="text-center form-footer">
            <a href="/login"> Already have an account? <span>Log in</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/images/logo.svg'
import OrganizationService from '@/services/organization.service'
import { required, email } from 'vuelidate/lib/validators'
import { DOMAIN_TITLE } from '../.env'
import Vue from 'vue'

export default {
  name: 'Login',
  data() {
    return {
      logo,
      dataInput: {
        name: '',
        email: '',
        password: '',
        fullName: '',
        company: '',
        acceptTerms: false
      },
      isLoading: false,
      submitted: false,
      industryOptions: []
    }
  },
  validations: {
    dataInput: {
      name: {
        required
      },
      password: {
        required,
        strongPassword(password) {
          return (
            /[A-Z]/.test(password) && // checks for A-Z
            /[0-9]/.test(password) && // checks for 0-9
            password.length >= 8
          )
        }
      },
      email: {
        required,
        email
      },
      fullName: {
        required
      },
      acceptTerms: {
        checked: (value) => value === true
      }
    }
  },
  beforeDestroy() {
    this.resetForm()
  },
  methods: {
    signUpOrganization() {
      this.submitted = true
      if (this.$v.dataInput.$invalid) {
        return
      }
      this.isLoading = true
      const data = {
        name: this.dataInput.name,
        email: this.dataInput.email,
        password: this.dataInput.password,
        full_name: this.dataInput.fullName,
        url: window.location.href
      }
      OrganizationService.signUpOrganization(data)
        .then((response) => {
          document.title = DOMAIN_TITLE
          if (response && response.data.data.user.new_term) {
            this.$router.push('/term-user')
          } else if (response && !response.data.data.user.new_term) {
            const path = Vue.$cookies.get('historyPath') ? Vue.$cookies.get('historyPath') : '/'
            this.$router.push(path)
            Vue.$cookies.remove('historyPath')
          }
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    resetForm() {
      this.dataInput = {
        name: '',
        email: '',
        password: '',
        company: ''
      }
      this.submitted = false
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../assets/images/app-background.jpg') no-repeat #2d494d;
  background-size: cover;
  background-position: center center;
  .main {
    background: #fff;
    width: 100%;
    position: relative;
    max-width: 465px;
    height: 625px;
    border-radius: 5px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12);
    .panel-login {
      width: 465px;
      height: 100%;
      float: left;
      border-radius: 5px;
      background-color: #ffffff;
      .logo {
        width: 100%;
        text-align: center;
        margin: 50px 0 10px;
        img {
          width: 230px;
        }
        .title-login {
          margin-top: 30px;
          font-size: 21px;
          font-weight: 700;
          line-height: 1.1;
        }
      }

      .login-form {
        font-family: 'Open Sans', sans-serif !important;
        max-width: 325px;
        margin: auto;
        .switch-type {
          margin-top: 10px;
        }
        .sso-link {
          a {
            display: block;
          }
        }
        .label-w {
          width: 150px;
          margin-bottom: 4px;
        }
      }

      .form-group {
        margin-bottom: 0px;
        label {
          font-size: 14px;
          color: #4a4a4a;
          font-weight: 600;
        }
      }
    }
    .header {
      text-align: center;
    }

    .buttons {
      margin-top: 10%;
      .btn-login {
        width: 100%;
        height: 45px;
        border-radius: 5px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
        box-shadow: 0 2px 4px 0 rgba(159, 214, 100, 0.6);
        background-color: #76c322;
        border: none;
        font-size: 15px;
        color: #fff;
      }
    }

    .error {
      background-color: red;
      padding: 10px;
      font-size: 12px;
      opacity: 1;
      transition: all 0.5s;
    }

    .error-right {
      position: absolute;
      right: 1rem;
    }
    input {
      height: 40px;
      padding: 12px;
      border-radius: 5px;
      &::placeholder {
        color: #c5ced6;
      }
    }
    .form-footer {
      bottom: 10px;
      padding-top: 0;
      left: auto;
      right: auto;
      width: 100%;
      position: absolute;
      padding: 0 18px 9px 18px;
      a {
        font-weight: 400;
        text-shadow: none;
        color: #1e1c24;
        text-decoration: none;
        span {
          font-weight: bold;
          color: #76c322;
        }
      }
    }
    .row-terms-conditions {
      margin-top: 23px;
      .terms_container {
        .terms_required {
          padding: 13px 10px;
          position: absolute;
          height: 44px;
          background-color: #ffffff;
          color: #000000;
          font-size: 12px;
          border-radius: 5px;
          box-shadow: -2px 0px 14px 4px rgba(0, 0, 0, 0.12);
          z-index: 9999;
          margin-top: -44px;
          margin-left: -8px;
          text-transform: none;
          display: flex;
          max-width: 285px;
          &::before {
            border-left: solid transparent 6px;
            border-top: solid #ffffff 6px;
            border-right: solid transparent 6px;
            content: '';
            position: absolute;
            margin-top: 29px;
          }
        }
        .ace {
          opacity: 0;
          border-radius: 5px;
          position: absolute;
          z-index: 12;
          width: 18px;
          height: 18px;
          cursor: pointer;
          &:checked + .lbl::before {
            content: ' ';
            display: inline-block;
            background: url('~@/assets/images/checked-icon.jpg') no-repeat;
            height: 16px;
            width: 16px;
            background-size: 100%;
          }
        }
        .lbl::before {
          content: '\a0';
          color: #000000;
          background-color: #fff;
          border-radius: 5px;
          font-family: 'fontAwesome';
          font-weight: normal;
          font-size: 12px;
          border: 1px solid #c8c8c8;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          height: 16px;
          line-height: 14px;
          min-width: 16px;
          margin-right: 1px;
        }
        .invalid-terms + .lbl::before {
          border-color: #de888a;
        }
      }
    }
  }
}
</style>
