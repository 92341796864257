import AdminTaxService from '../../../../services/admin-tax.service'
import CountriesService from '../../../../services/countries.service'
import StatesService from '../../../../services/states.service'
export default {
  getAllTaxes({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return AdminTaxService.getAllTaxes(payload.page, payload.limit, payload.search)
      .then((response) => {
        commit('SET_ERROR', '')
        commit('GET_ALL_TAX_ADMIN', response.data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  },

  getListCountries({ state, commit, rootState }, payload) {
    return CountriesService.getAllCountries()
      .then((response) => {
        const data = [{ id: 0, text: 'Select a Country' }]
        response.data.map((exception) => {
          data.push({ id: exception.id, text: exception.name })
        })
        commit('SET_ERROR', '')
        commit('GET_LIST_COUNTRIES', data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  },

  getListStates({ state, commit, rootState }, payload) {
    return StatesService.getAllStates(payload)
      .then((response) => {
        const data = [{ id: 0, text: 'Select a Province/State' }]
        response.data.map((exception) => {
          data.push({ id: exception.id, text: exception.name })
        })
        commit('SET_ERROR', '')
        commit('GET_LIST_STATE', data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  },

  updateListStates({ state, commit, rootState }, payload) {
    commit('UPDATE_LIST_STATE', { id: payload.id, text: payload.text })
  }
}
