<template>
  <SfInputSearch
    v-model="searchValue"
    :placeholder="
      showBy === 'loop'
        ? 'Search loop'
        : showBy === 'campaign'
        ? 'Search campaign'
        : capitalizeFirstLetter([$t('search'), $t('player')])
    "
    v-debounce:400ms="searchChange"
    :height="'40px'"
    @onSearchInputChange="onSearchInputChange"
  />
</template>

<script>
import SfInputSearch from '@/components/SfInputSearch.vue'

export default {
  name: 'LinkedToInputSearch',
  components: {
    SfInputSearch
  },
  props: {
    showBy: {
      type: String,
      require: true,
      default: 'loop'
    }
  },
  data() {
    return {
      searchValue: '',
      searchValueHistory: {
        loop: '',
        campaign: '',
        player: ''
      }
    }
  },
  methods: {
    searchChange() {
      this.$emit('searchChange', this.searchValue)
      switch (this.showBy) {
        case 'loop':
          this.searchValueHistory.loop = this.searchValue
          break
        case 'campaign':
          this.searchValueHistory.campaign = this.searchValue
          break
        case 'player':
          this.searchValueHistory.player = this.searchValue
          break
        default:
          break
      }
    },
    onSearchInputChange() {
      this.$emit('onSearchInputChange')
    }
  },
  watch: {
    showBy(value) {
      if (value) {
        this.searchValue = this.searchValueHistory[value]
      }
    }
  }
}
</script>

<style></style>
