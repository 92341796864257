<template>
  <div class="container-fluid">
    <div class="card-header top-bar">
      <div class="header-left">
        <div class="admin-search-box">
          <!-- <input v-debounce:400ms="searchInputChange" class="quicksearch" placeholder="Search..." type="text" />
          <div class="magnifying-glass"></div>-->
          <SfInputSearch
            placeholder="Search"
            v-debounce:500ms="searchInputChange"
            class="sf-input-search"
          />
        </div>
      </div>
      <div class="header-right">
        <!-- <button class="btn btn-primary btn-success pull-right" @click="showModalCreate"> -->
        <button class="sf-primary-add" @click="isShowAddNewOrgModal = true">
          Add New Organization
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="sf-table col-12">
          <b-table
            show-empty
            striped
            hover
            :items="items"
            :fields="fields"
            head-variant="none"
            table-variant="none"
            thead-class="custom-b-table"
            :busy="loading"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty>
              <p class="d-flex justify-content-center">No data available in table</p>
            </template>
            <template v-slot:head(name)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(name)="field">
              <p>
                <router-link :to="{ path: `/admin/clients/organizations/${field.item.id}` }">{{
                  field.item.name
                }}</router-link>
              </p>
            </template>

            <template v-slot:head(status)="field">{{ field.label }}</template>
            <template v-slot:cell(status)="field">
              <div v-if="field.item.status" class="sf-table-status active"></div>
              <div v-else class="sf-table-status inactive"></div>
            </template>

            <template v-slot:head(org_type)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(org_type)="field">
              <p>{{ field.item.org_type }}</p>
            </template>

            <template v-slot:head(email)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(email)="field">
              <p>{{ field.item.email }}</p>
            </template>

            <template v-slot:head(users)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(users)="field">
              <p>{{ field.item.users }}</p>
            </template>

            <template v-slot:head(players)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(players)="field">
              <p>{{ field.item.players }}</p>
            </template>

            <template v-slot:head(action)="field">{{ field.label }}</template>
            <template v-slot:cell(action)="field">
              <button
                @click="showJoinOrganization(field.item.id)"
                class="button-join-organization"
                :title="'Join'"
              ></button>
              <button
                @click="shownModalEdit(field.item.id)"
                class="button-edit-table"
                :title="'Edit'"
              ></button>
              <button
                @click="shownModalDelete(field.item.id)"
                class="button-delete-table"
                :title="'Delete'"
              ></button>
            </template>
          </b-table>
          <div v-if="!isEmpty" class="my-3">
            <b-pagination
              :total-rows="pagination.limit * pagination.total"
              :per-page="pagination.limit"
              @change="changePage"
              :limit="6"
              v-model="currentPage"
              class="sf-b-pagination"
            />
          </div>
        </div>
      </div>

      <SfDeleteModal
        title="DELETE ORGANIZATION"
        :width="800"
        closeOnOverlay
        :show.sync="isShownModalDelete"
      >
        <div class="content-delete">
          <div class="container">
            <p class="title">
              By deleting this organization you will also be deleting the following:
            </p>
            <ul>
              <li>
                <span>{{ formatTotal(allInformation.smart_assets_count, 'Asset') }}</span>
              </li>
              <li>
                <span>{{ formatTotal(allInformation.loop_assets_count, 'Loop') }}</span>
              </li>
              <li>
                <span>{{ formatTotal(allInformation.campaigns_count, 'Campaign') }}</span>
              </li>
              <li>
                <span>{{ formatTotal(allInformation.players_count, 'Player') }}</span>
              </li>
              <li>
                <span>{{ formatTotal(allInformation.users_count, 'User') }}</span>
              </li>
              <li>Any and all related data.</li>
            </ul>
            <p class="title">
              You will also be deleting
              <span>{{
                formatTotal(allInformation.sub_organizations_count, 'Sub Organization')
              }}</span>
              and all related data.
            </p>
            <p class="title condition">
              If you want to delete this Organization, please enter
              <span class="highlight">{{ allInformation.name }}</span> below
            </p>
            <div class="form-group center-input">
              <input
                type="text"
                v-model="deleteOrganization.organization.name"
                class="sf-input-text"
              />
            </div>
          </div>
        </div>

        <div class="sf-modal-footer mt-3">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button class="sf-secondary" type="button" @click="isShownModalDelete = false">
                    Cancel
                  </button>
                  <button type="button" class="sf-primary" @click="removeOrganization">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SfDeleteModal>

      <SfModal
        title="Confirm Join Organization"
        :width="400"
        closeOnOverlay
        :show.sync="isShownModalConfirm"
      >
        <div class="content-delete mb-3">
          Joining this organization. Are you sure you want to join?
        </div>
        <div class="footer-button">
          <button class="sf-secondary" @click="isShownModalConfirm = false">Cancel</button>
          <button class="sf-primary" @click="joinOrganization">OK</button>
        </div>
      </SfModal>
      <AddNewOrgModal
        title="Add New Organization"
        :width="768"
        closeOnOverlay
        @modal:finishSave="onFinishCreateOrg"
        :show.sync="isShowAddNewOrgModal"
      ></AddNewOrgModal>
      <EditOrgModal
        title="Edit Organization"
        :width="800"
        :orgID="orgIdUpdate"
        closeOnOverlay
        @modal:finishUpdate="onFinishUpdateOrg"
        @modal:updateSuccess="onSuccessUpdateOrg"
        :show.sync="isShownModalEdit"
      ></EditOrgModal>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AddNewOrgModal from './AddNewOrgModal.vue'
import EditOrgModal from './EditOrgModal.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import SfModal from '@/components/SfModal.vue'
import AdminOrganizationService from '@/services/admin-organization.service'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import SfInputSearch from '@/components/SfInputSearch.vue'
import moment from 'moment-timezone'

export default {
  name: 'admin-organizations',

  components: {
    SfDeleteModal,
    SfModal,
    AddNewOrgModal,
    EditOrgModal,
    SfInputSearch
  },

  data() {
    return {
      msg: 'Welcome to Index!!!',
      isShownModalCreate: false,
      isShownModalDelete: false,
      isShownModalEdit: false,
      isShownModalConfirm: false,
      isShowAddNewOrgModal: false,
      currentOrganizationID: 0,
      orgIdUpdate: 0,
      addressDataFormat: {},
      fields: [
        {
          key: 'status',
          label: '',
          colType: 'status',
          thClass: 'customTd',
          class: 'sf-table-header-status'
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          colType: 'name'
        },
        {
          key: 'org_type',
          label: 'Org Type',
          sortable: true,
          colType: 'org_type'
        },
        {
          key: 'email',
          label: 'Primary Email',
          sortable: true,
          colType: 'email'
        },
        {
          key: 'users',
          label: 'Users',
          sortable: true,
          colType: 'users'
        },
        {
          key: 'players',
          label: 'Players',
          sortable: true,
          colType: 'players'
        },
        {
          key: 'action',
          label: 'Actions',
          sortable: false,
          colType: 'button'
        }
      ],
      currentPage: 1,
      newOraganization: {
        organization: {
          org_type_id: '',
          pricing: '',
          name: '',
          fname: '',
          lname: '',
          email: '',
          phone: '',
          address: '',
          country_id: '',
          province_state: '',
          city: '',
          postal_zip: ''
        }
      },
      currentOrganization: {
        organization: {
          org_type_id: '',
          pricing: '',
          name: '',
          fname: '',
          lname: '',
          email: '',
          phone: '',
          address: '',
          country_id: '',
          province_state: '',
          city: '',
          postal_zip: '',
          country: {
            id: ''
          },
          org_type: {
            id: ''
          }
        }
      },
      defaultOrganization: {
        organization: {
          org_type_id: '',
          pricing: '',
          name: '',
          fname: '',
          lname: '',
          email: '',
          phone: '',
          address: '',
          country_id: '',
          province_state: '',
          city: '',
          postal_zip: '',
          country: {
            id: ''
          },
          org_type: {
            id: ''
          }
        }
      },
      deleteOrganization: {
        organization: {
          name: ''
        }
      },
      submitted: false,
      dataSearch: '',
      user: '',
      similarOrg: false,
      allInformation: {
        name: 'Organization Name',
        campaigns_count: 0,
        loop_assets_count: 0,
        players_count: 0,
        smart_assets_count: 0,
        sub_organizations_count: 0,
        users_count: 0
      }
    }
  },
  validations: {
    newOraganization: {
      organization: {
        name: {
          required,
          maxLength: maxLength(250)
        },
        fname: {
          required,
          maxLength: maxLength(250)
        },
        lname: {
          required,
          maxLength: maxLength(250)
        },
        email: {
          required,
          maxLength: maxLength(100),
          email
        },
        phone: {
          required,
          maxLength: maxLength(13)
        },
        address: {
          required,
          maxLength: maxLength(250)
        },
        city: {
          required,
          maxLength: maxLength(20)
        },
        postal_zip: {
          required,
          maxLength: maxLength(10)
        },
        country_id: {
          required
        },
        province_state: {
          required
        },
        org_type_id: {
          required
        },
        pricing: {
          required
        }
      }
    },
    currentOrganization: {
      organization: {
        name: {
          required,
          maxLength: maxLength(250)
        },
        fname: {
          required,
          maxLength: maxLength(250)
        },
        lname: {
          required,
          maxLength: maxLength(250)
        },
        email: {
          required,
          maxLength: maxLength(100),
          email
        },
        phone: {
          required,
          maxLength: maxLength(13)
        },
        address: {
          required,
          maxLength: maxLength(250)
        },
        city: {
          required,
          maxLength: maxLength(20)
        },
        postal_zip: {
          required,
          maxLength: maxLength(10)
        },
        country_id: {
          required
        },
        province_state: {
          required
        },
        org_type_id: {
          required
        },
        pricing: {
          required
        }
      }
    }
  },
  methods: {
    fetchData(activePage, limit, search) {
      this.$store.dispatch('adminorganization/getAllAdminOrganizations', {
        activePage,
        limit,
        search
      })
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, this.pagination.limit, value)
    },
    loadPricing() {
      this.$store.dispatch('adminorganization/getListPricing')
    },
    loadOrgType() {
      this.$store.dispatch('adminorganization/getListOrgtype')
    },
    loadCountries() {
      this.$store.dispatch('adminorganization/getListCountries')
    },
    loadProvinceState(country) {
      this.$store.dispatch('adminorganization/getListStates', country)
    },
    getInfoUser() {
      this.$store.dispatch('user/getCurrent')
    },
    shownModalDelete(organization_id) {
      this.isShownModalDelete = true
      this.currentOrganizationID = organization_id
      this.deleteOrganization.organization.name = ''
      this.allInformation.name = 'Organization Name'
      AdminOrganizationService.getAllInformation(this.currentOrganizationID)
        .then((response) => {
          this.allInformation = response.data
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
    },
    showJoinOrganization(organization_id) {
      this.isShownModalConfirm = true
      this.currentOrganizationID = organization_id
    },
    shownModalEdit(organization_id) {
      this.orgIdUpdate = organization_id
      this.isShownModalEdit = true
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage, this.pagination.limit, this.dataSearch)
    },
    onChangeCountry(val) {
      this.newOraganization.organization.province_state = ''
      this.loadProvinceState(val)
    },
    setAddressData(place) {
      const self = this
      this.formatAddress(place.address_components)
      const country_id = this.countryOptions.filter(function (elem) {
        if (elem.text === self.addressDataFormat.country) return elem.id
      })
      this.newOraganization.organization.country_id = country_id[0] ? country_id[0].id : 0
      this.$store.dispatch('adminorganization/updateListStates', {
        id: self.addressDataFormat.administrative_area_level_1,
        text: 'Select a Province/State'
      })
      this.loadProvinceState(this.currentOrganization.organization.country_id)

      const province_state = this.provinceStateOptions.filter(function (elem) {
        if (elem.text === self.addressDataFormat.administrative_area_level_1) {
          return elem.id
        }
      })
      this.newOraganization.organization.province_state = province_state[0]
        ? province_state[0].id
        : self.addressDataFormat.administrative_area_level_1
      this.newOraganization.organization.postal_zip = self.addressDataFormat.postal_code
      this.newOraganization.organization.city = self.addressDataFormat.locality
      this.newOraganization.organization.address = place.formatted_address
    },
    setAddressDataEditForm(place) {
      const self = this
      this.formatAddress(place.address_components)
      const country_id = this.countryOptions.filter(function (elem) {
        if (elem.text === self.addressDataFormat.country) return elem.id
      })
      this.currentOrganization.organization.country_id = country_id[0] ? country_id[0].id : 0
      this.loadProvinceState(this.currentOrganization.organization.country_id)
      this.currentOrganization.organization.postal_zip = self.addressDataFormat.postal_code
      this.currentOrganization.organization.city = self.addressDataFormat.locality
      this.currentOrganization.organization.address = place.formatted_address
    },
    setProvinceState() {
      if (this.currentOrganization) {
        if (Object.keys(this.addressDataFormat).length > 0) {
          const province_state = this.provinceStateOptions.filter((elem) => {
            if (elem.text === this.addressDataFormat.administrative_area_level_1) {
              return elem.id
            }
          })
          this.currentOrganization.organization.province_state = province_state[0]
            ? province_state[0].id
            : 0
        }
      }
    },
    saveOrganization() {
      this.submitted = true
      if (this.$v.newOraganization.$invalid) {
        return
      }
      AdminOrganizationService.createOrganization(this.newOraganization.organization)
        .then((response) => {
          this.$toast.success('Organization successfully created')
          this.fetchData(this.currentPage, this.pagination.limit)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalCreate = false
    },
    onFinishCreateOrg() {
      this.isShowAddNewOrgModal = false
      this.fetchData(this.currentPage, this.pagination.limit, this.dataSearch)
    },
    onFinishUpdateOrg(val) {
      this.isShownModalEdit = false
      this.orgIdUpdate = 0
      if (!val.onCancel) {
        this.fetchData(this.currentPage, this.pagination.limit, this.dataSearch)
      }
    },
    onSuccessUpdateOrg(data) {
      if (this.user.organization_id === data.id) {
        this.getInfoUser()
      }
    },
    removeOrganization() {
      if (this.items.length === 1 && this.currentPage > 1) {
        this.currentPage = this.currentPage - 1
      }
      AdminOrganizationService.deleteOrganization(
        this.currentOrganizationID,
        this.deleteOrganization
      )
        .then((response) => {
          this.$toast.success('Organization successfully destroyed')
          this.fetchData(this.currentPage, this.pagination.limit, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalDelete = false
    },
    updateOrganization() {
      this.submitted = true
      if (this.$v.currentOrganization.$invalid) {
        return
      }
      AdminOrganizationService.updateOrganization(this.orgIdUpdate, this.currentOrganization)
        .then((response) => {
          this.$toast.success('Organization successfully updated')
          this.fetchData(this.currentPage, this.pagination.limit, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalEdit = false
    },
    joinOrganization() {
      AdminOrganizationService.joinOrganization(this.currentOrganizationID)
        .then((response) => {
          this.$toast.success('Joined Organization')
          this.setCookiesAndUpdateHeader(response.data)
          this.getInfoUser()
          this.$cable.unsubscribe('AccountChannel')
          this.$cable.subscribe({
            channel: 'AccountChannel',
            organization: this.currentOrganizationID
          })
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalConfirm = false
    },

    setDataOrganization(organization, new_organization) {
      organization.org_type_id = Object.keys(new_organization.org_type).length
        ? new_organization.org_type.id
        : ''
      organization.country_id = new_organization.country.id
      organization.pricing = new_organization.pricing
      organization.name = new_organization.name
      organization.fname = new_organization.fname
      organization.lname = new_organization.lname
      organization.email = new_organization.email
      organization.province_state = new_organization.province_state
      organization.city = new_organization.city
      organization.postal_zip = new_organization.postal_zip
      organization.address = new_organization.address
      organization.phone = new_organization.phone
      organization.country = new_organization.country
      organization.org_type = new_organization.org_type
      if (organization.name === this.user.organization) {
        this.similarOrg = true
      }
    },
    formatAddress(address) {
      const components = {}
      address.map((key, index) => {
        key.types.map((key1, index1) => {
          components[key1] = key.long_name
        })
      })
      this.addressDataFormat = components
    },
    getUser() {
      const user = this.$cookies.get('user')
      if (user) {
        this.user = user
      }
    },
    formatTotal(count, text) {
      return count > 1 ? count + ' ' + text + 's' : count + ' ' + text
    },
    setCookiesAndUpdateHeader(data) {
      const tokenExpired = this.$cookies.get('tokenExpired')
      const remember = this.$cookies.get('rememberMe') === 'true'
      this.user.organization = data.organization.name
      this.user.is_parent_organization = data.organization.is_parent_organization
      if (tokenExpired && remember) {
        this.$cookies.set(
          'user',
          this.user,
          moment(tokenExpired).format('ddd, DD MMM YYYY HH:mm:ss ZZ')
        )
      } else {
        this.$cookies.set('user', this.user, 0)
      }
      this.$bus.$emit('update_joins_organization', {
        organization: { name: data.organization.name }
      })
    }
  },
  watch: {
    provinceStateOptions(value) {
      this.setProvinceState()
    }
  },
  computed: {
    ...mapState('adminorganization', {
      items: 'items',
      pagination: 'pagination',
      pricingOptions: 'pricingOptions',
      orgTypeOptions: 'orgTypeOptions',
      countryOptions: 'countryOptions',
      provinceStateOptions: 'provinceStateOptions',
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('adminorganization', ['isEmpty'])
  },

  mounted() {
    this.loadOrgType()
    this.loadCountries()
    this.loadProvinceState()
    this.fetchData(this.currentPage, 10)
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, this.pagination.limit, value)
    })
    this.getUser()
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
  }
}
</script>

<style lang="scss" scoped>
@import '../../Adminpage.scss';
.button-join-organization {
  background-image: url('../../../../assets/images/pointing-right.svg');
  width: 24px;
  height: 24px;
  background-size: cover;
  background-color: transparent;
  border: 0;
  margin-right: 20px;
  &:hover {
    background-image: url('../../../../assets/images/pointing-right-active.svg');
  }
}
.content-delete {
  padding: 35px 45px 0px 45px;
  text-align: center;
  color: #4a4a4a;
  .container {
    text-align: left;
    color: #002476;
    .title {
      margin: 10px 0px;
      span {
        font-weight: 700;
        &.highlight {
          color: #aa0a1e;
        }
      }
      &.condition {
        font-size: 18px;
        font-weight: 600;
      }
    }
    ul {
      list-style: inside;
      li {
        span {
          font-weight: 700;
        }
      }
    }
    .center-input {
      display: flex;
      justify-content: center;
      align-items: center;
      input {
        width: 275px;
      }
    }
  }
}
</style>
