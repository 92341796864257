import SmartAssetsService from '@/services/smart-assets.service'
import FolderService from '@/services/folder.service'
import Vue from 'vue'
import LoopAssetsService from '@/services/loop-assets.service'

export default {
  // Loop Detail
  getListSmarttAsset({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return SmartAssetsService.getListSmartAsset(
      payload.page,
      payload.limit,
      payload.search,
      'loop_edit',
      payload.loopId,
      payload.type
    )
      .then((response) => {
        commit('SET_ITEMS', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING', false))
  },
  getListSmartAssetInFolder({ sate, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return FolderService.getAssetInFolder(
      payload.id,
      payload.page,
      payload.limit,
      'library',
      payload.loopId,
      payload.orientation,
      payload.search,
      payload.type
    )
      .then((response) => {
        commit('SET_ITEMS_IN_FOlDER', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING', false))
  },
  getNestedListSmartAssetInFolder({ state, commit, rootState }, payload) {
    commit('SET_LOADING_ASSETS', true)
    return FolderService.getAllNestedAssetInFolder(
      payload.id,
      'library',
      payload.loopId,
      payload.orientation
    )
      .then((response) => {
        commit('SET_ALL_NESTED_ITEMS_IN_FOlDER', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING_ASSETS', false))
  },
  getMultipleAssetInFolder({ state, commit, rootState }, payload) {
    commit('SET_LOADING_ASSETS', true)
    return FolderService.getMultipleAssetInFolder(payload.folder_ids, payload.orientation)
      .then((response) => {
        commit('SET_ALL_NESTED_ITEMS_IN_FOlDER', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING_ASSETS', false))
  },
  resetNestedListArrayinFolder({ state, commit, rootState }, payload) {
    commit('RESET_ALL_NESTED_ITEMS_IN_FOlDER')
  },
  getListFolder({ state, commit, rootState }, payload) {
    return FolderService.getListFolders('library', 'loop_edit')
      .then((response) => {
        commit('SET_TREE_FOLDER', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
  },

  getTreeFolderChildById({ state, commit, rootState }, payload) {
    return FolderService.getListChildrenFolders(payload.id, 'library', payload.orientation)
      .then((response) => {
        commit('SET_TREE_FOLDER_ID', {
          id: payload.id,
          items: response.data
        })
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING', false))
  },

  getCampaignsByLoopId({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    commit('SET_LOOP_CAMPAIGN_ITEMS', null)
    const { loopId, page, limit, search } = payload

    return LoopAssetsService.getCampaignsByLoopId(loopId, page, limit, search)
      .then((resposne) => {
        commit('SET_LOOP_CAMPAIGN_ITEMS', resposne.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING', false))
  },

  getPlayerByLoopId({ state, commit, rootState }, payload) {
    commit('SET_LOOP_PLAYER_LOADING', true)
    commit('SET_LOOP_PLAYER_ITEMS', null)
    const { loopId, page, limit, search } = payload

    return LoopAssetsService.getPlayersByLoopId(loopId, page, limit, search)
      .then((resposne) => {
        commit('SET_LOOP_PLAYER_ITEMS', resposne.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOOP_PLAYER_LOADING', false))
  }
  // ===========================================================================
}
