import state from './adminTemplateCategoryState'
import mutations from './adminTemplateCategoryMutations'
import getters from './adminTemplateCategoryGetters'
import actions from './adminTemplateCategoryActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
