import state from './playerState'
import mutations from './playerMutations'
import getters from './playerGetters'
import actions from './playerActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
