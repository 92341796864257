<template>
  <SfModal
    :title="$t('modal.title.edit-player')"
    :width="786"
    closeOnOverlay
    :show.sync="isShownModalEdit"
  >
    <div class="container">
      <div class="row my-3">
        <div class="col-12">
          <ul class="sf-tabs">
            <li>
              <a
                @click="changeTabEditModal('general')"
                :class="{ active: activeTabModal === 'general' }"
                >{{ $t('general') }}</a
              >
            </li>
            <li>
              <a
                @click="changeTabEditModal('advanced')"
                :class="{ active: activeTabModal === 'advanced' }"
                >{{ $t('advanced') }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="activeTabModal === 'general'" class="container">
      <div class="row mt-3">
        <div class="col-9">
          <div class="sf-form-group">
            <div class="sf-label-group">
              <label class="primary-label">{{ $t('name') }}</label>
            </div>
            <input
              v-model="namePlayer"
              class="sf-input-text"
              :placeholder="$t('placeholder.name')"
            />
          </div>
          <p v-if="!namePlayer && showError" class="error-text text-right">
            {{ $t('name-required') }}
          </p>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-9">
          <div class="sf-form-group">
            <div class="sf-label-group">
              <label class="primary-label">{{ $t('description') }}</label>
            </div>
            <input
              v-model="descriptionPlayer"
              class="sf-input-text"
              :placeholder="$t('placeholder.description')"
            />
          </div>
          <p
            v-if="descriptionPlayer && descriptionPlayer.length > 255 && showError"
            class="error-text text-right"
          >
            {{ $t('long-text-validation') }}
          </p>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-9">
          <div class="sf-form-group">
            <div class="sf-label-group">
              <label class="primary-label">{{ $t('location') }}</label>
            </div>
            <gmap-autocomplete
              class="sf-input-text"
              @place_changed="getAddressData"
              :placeholder="`${$t('placeholder.address')} ..`"
              :value="infoPlayerID.location"
            >
            </gmap-autocomplete>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-9">
          <div class="sf-form-group">
            <div class="sf-label-group">
              <label class="primary-label">{{ $t('time-zone') }}</label>
            </div>
            <div class="sf-select-group">
              <Select2
                class="sf-select2"
                :value="infoPlayerID.time_zone_value"
                @change="chooseTimezone"
                :options="listTimezoneOptions"
              />
              <span class="lb-right caret"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-9">
          <div class="sf-form-group">
            <div class="sf-label-group">
              <label class="primary-label">{{ $t('rotation') }}</label>
            </div>
            <div class="sf-select-group">
              <Select2
                class="sf-select2"
                :value="infoPlayerID.rotate"
                :options="[
                  { id: 'default', text: $t('default') },
                  { id: 'flipped', text: $t('flipped') }
                ]"
                @change="changeRotate"
                :settings="{ minimumResultsForSearch: -1 }"
              />
              <span class="lb-right caret"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-9">
          <div class="sf-form-group">
            <div class="sf-label-group">
              <label class="primary-label">{{ $t('campaign') }}</label>
            </div>
            <div class="sf-select-group">
              <Select2
                class="sf-select2"
                v-if="infoPlayerID.running_campaign"
                @select="changeCampaign($event)"
                :value="infoPlayerID.running_campaign.id"
                :options="listCampaignOptionsForEditPlayer"
              ></Select2>
              <Select2
                class="sf-select2"
                v-else
                :options="listCampaignOptionsForEditPlayer"
                @select="changeCampaign($event)"
              ></Select2>
              <span class="lb-right caret"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="container">
      <div class="row mt-3">
        <div class="col-9">
          <div class="sf-form-group">
            <div class="sf-label-group">
              <label class="primary-label">{{ $t('browser-cache') }}</label>
            </div>
            <div class="w-100">
              <toggle-button
                :value="infoPlayerID.is_browser_cache"
                :sync="true"
                :width="44"
                :height="22"
                :margin="2"
                @change="onChangeToggleCacheBrowser"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-9">
          <div class="sf-form-group">
            <div class="sf-label-group">
              <label class="primary-label">{{ $t('kiosk-mode') }}</label>
            </div>
            <div class="w-100">
              <toggle-button
                :value="infoPlayerID.is_kiosk_mode"
                :sync="true"
                :width="44"
                :height="22"
                :margin="2"
                @change="onChangeToggleKioskMode"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-9">
          <div class="sf-form-group">
            <div class="sf-label-group">
              <label class="primary-label">{{ $t('wifi-type') }}</label>
            </div>
            <div class="w-100">
              <toggle-button
                :value="infoPlayerID.is_wifi_type"
                :sync="true"
                :width="44"
                :height="22"
                :margin="2"
                @change="onChangeToggleWifiType"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sf-modal-footer mt-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="sf-modal-action">
              <button class="sf-secondary" type="button" @click="closeModal">
                {{ $t('cancel') }}
              </button>
              <button type="button" class="sf-primary" @click="updatePlayer">
                {{ $t('save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SfModal>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import Select2 from 'v-select2-component'
import SfModal from '@/components/SfModal.vue'
import PlayerService from '../../services/player.service'
export default {
  components: {
    SfModal,
    Select2
  },
  props: {
    player_id: {
      type: Number,
      required: true
    },
    onClose: {
      type: Function
    }
  },
  data() {
    return {
      isShownModalEdit: true,
      activeTabModal: 'general',
      showError: false,
      addressDataFormat: {}
    }
  },
  methods: {
    ...mapMutations('player', {
      setInfoPlayerId: 'GET_INFO_PLAYER_BY_ID'
    }),
    changeTabEditModal(val) {
      this.activeTabModal = val
    },
    chooseTimezone(value) {
      this.$store.dispatch('player/chooseTimezone', value)
    },
    changeCampaign(value) {
      this.$store.dispatch('player/chooseCampaign', value)
    },
    onChangeToggleKioskMode(value) {
      this.$store.dispatch('player/changeToggleKiosk', value.value)
    },
    editPlayer(id) {
      this.currentPlayerID = id
      PlayerService.getInfoPlayerByID(this.currentPlayerID)
        .then((response) => {
          this.activeTabModal = 'general'
          this.isShownModalEdit = true
          this.setInfoPlayerId(response.data)
          this.showError = false
          this.getAllCampaigns()
          this.getListTimezone()
        })
        .catch((error) => {
          this.$toast.error(
            error.message ? `${error.message}` : 'Something went wrong. Please try again.'
          )
        })
    },
    updatePlayer() {
      const shallowInfoPlayerID = JSON.parse(JSON.stringify(this.infoPlayerID))
      delete shallowInfoPlayerID.access_code
      delete shallowInfoPlayerID.activated
      delete shallowInfoPlayerID.diagnostics
      delete shallowInfoPlayerID.files
      delete shallowInfoPlayerID.last_connection
      delete shallowInfoPlayerID.orientation
      delete shallowInfoPlayerID.player_code
      shallowInfoPlayerID.time_zone_name = shallowInfoPlayerID.time_zone_value
      const param = {
        player: shallowInfoPlayerID
      }
      if (this.namePlayer && this.descriptionPlayer.length < 255) {
        PlayerService.updatePlayerByID(param, shallowInfoPlayerID.id)
          .then((res) => {
            // this.fetchData(this.currentPage, this.limitPage.value, this.dataSearch)
            this.isShownModalEdit = false
            this.onClose()
            this.$toast.success(res.message || 'Player updated')
          })
          .catch((error) => {
            this.isShownModalEdit = false
            if (error.data && ['pi_firmware_require', 'asset_unallow'].includes(error.data.type)) {
              this.unsupportedType = error.data.type
              const asset = error.data.slide_template_unallow.length > 1 ? ' assets' : ' asset'
              this.slide_template_unallow =
                `<span style="font-weight: 600;">${error.data.slide_template_unallow.join(
                  ', '
                )}</span>` + asset
              this.hardware_require = `<span style="font-weight: 600;">${error.data.hardware_require.displayname}</span>`
              this.pi_firmware_require = `<span style="font-weight: 600;">${error.data.pi_firmware_require}</span>`
              this.isShownModalUnSupport = true
            } else {
              this.$toast.error(
                error.message ? `${error.message}` : 'Something went wrong. Please try again.'
              )
            }
          })
      } else {
        this.showError = true
      }
    },
    getAddressData(place) {
      const self = this
      this.formatAddress(place.address_components)
      this.$store.dispatch('player/updateLocation', {
        info: self.addressDataFormat,
        name: place.formatted_address,
        place: place,
        country: self.addressDataFormat.country,
        province_state: self.addressDataFormat.administrative_area_level_1,
        postal_code: self.addressDataFormat.postal_code ? self.addressDataFormat.postal_code : '',
        city: self.addressDataFormat.locality ? self.addressDataFormat.locality : ''
      })
    },
    onChangeToggleCacheBrowser(value) {
      this.$store.dispatch('player/changeToggleCache', value.value)
    },
    onChangeToggleWifiType(value) {
      this.$store.dispatch('player/changeToggleWifiType', value.value)
    },
    changeRotate(e) {
      this.$store.dispatch('player/changeRotate', e)
    },
    getAllCampaigns(orientation) {
      this.$store.dispatch('player/getAllCampaigns', orientation)
    },
    getListTimezone() {
      this.$store.dispatch('player/getListTimezone')
    },
    formatAddress(address) {
      const components = {}
      address.map((key, index) => {
        key.types.map((key1, index1) => {
          components[key1] = key.long_name
        })
      })
      this.addressDataFormat = components
    },
    closeModal() {
      this.isShownModalEdit = false
      this.onClose()
    }
  },
  computed: {
    ...mapState('player', {
      infoPlayerID: 'infoPlayerID'
    }),
    ...mapGetters('player', {
      listTimezoneOptions: 'listTimezoneOptions',
      listCampaignOptionsForEditPlayer: 'listCampaignOptionsForEditPlayer'
    }),
    namePlayer: {
      get() {
        return this.$store.state.player.infoPlayerID.name
      },
      set(value) {
        this.$store.dispatch('player/updatePlayerName', value)
      }
    },
    descriptionPlayer: {
      get() {
        return this.$store.state.player.infoPlayerID.description
      },
      set(value) {
        this.$store.dispatch('player/updateDescripionPlayer', value)
      }
    }
  },
  mounted() {
    this.editPlayer(this.player_id)
  }
}
</script>

<style></style>
