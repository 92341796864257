<template>
  <SfModal
    :title="$t('modal.title.new-smart-asset')"
    :width="768"
    :height="520"
    closeOnOverlay
    :show.sync="dataShow"
    @update:show="updateShow"
  >
    <div class="form-group mb-0">
      <div class="form-body modal-body pb-0">
        <div class="modal-body-content">
          <div class="row">
            <div class="col-sm-5">
              <template v-for="item in filterMenuType">
                <div
                  v-if="typeDetail === item.name"
                  :key="item.name + 'icon'"
                  class="smart-asset-info smart-asset-default"
                >
                  <div class="smart-asset-info-title">
                    <h4>{{ item.title }}</h4>
                  </div>
                  <div class="smart-asset-info-desc">
                    <p>{{ item.description }}</p>
                  </div>
                  <div class="smart-asset-info-image">
                    <img :src="item.descriptionIcon" />
                  </div>
                </div>
              </template>
            </div>
            <div class="col-sm-7 smart-asset-images-wrap">
              <div class="smart-asset-images">
                <div class="row">
                  <div class="col-12 pd-custom">
                    <div class="search-smart-asset">
                      <input
                        class="quicksearch-menu"
                        id="searchSmartAsset"
                        :placeholder="`${$t('search')} ${$t('smart-asset')}`"
                        type="text"
                        v-model="searchSmartAsset"
                      />
                    </div>
                  </div>
                </div>
                <div class="row menu-type" style="">
                  <template v-for="item in filterMenuType">
                    <div
                      v-if="item.name !== 'SMART_ASSETS'"
                      class="element-item col-sm-4 pd-custom"
                      :key="item.name"
                      :class="{ active: item.name === typeDetail }"
                      @click="updateDetail(item)"
                      :title="item.isAvailable ? '' : 'Coming Soon'"
                    >
                      <div class="smart-asset-item" :class="{ active: typeDetail === item.name }">
                        <div class="smart-asset-icon">
                          <img :src="item.icon" />
                          <div class="div-coming-soon" v-if="!item.isAvailable">
                            {{ $t('coming-soon') }}
                          </div>
                        </div>
                        <div class="smart-asset-name">
                          <span>{{ item.title }}</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="modal-footer justify-content-end">
        <button
          aria-label="Close"
          class="sf-secondary"
          type="button"
          @click="updateShow"
        >
          CANCEL
        </button>
        <button
          class="sf-primary"
          :class="{'btn-custom-save': isAvailable, 'btn-coming-soon': !isAvailable}"
          id="addSmartAsset"
          type="button"
          :disabled="!isAvailable"
          @click="onClickContinue($event)"
        >
          {{isAvailable || typeDetail === "SMART_ASSETS"  ? 'CONTINUE' : 'COMING SOON'}}
        </button>
      </div> -->
    </div>

    <div class="sf-modal-footer">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="sf-modal-action">
              <button class="sf-secondary" type="button" @click="updateShow">
                {{ $t('cancel') }}
              </button>
              <button
                :class="{ 'sf-primary': isAvailable, 'sf-primary btn-coming-soon': !isAvailable }"
                id="addSmartAsset"
                type="button"
                :disabled="!isAvailable"
                @click="onClickContinue($event)"
              >
                {{
                  isAvailable || typeDetail === 'SMART_ASSETS' ? $t('continue') : $t('coming-soon')
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SfModal>
</template>

<script>
// import instagramIcon from '@/assets/images/smart-assets/instagram-icon.svg'
import pdfIcon from '@/assets/images/smart-assets/pdf-icon.png'
import websiteIcon from '@/assets/images/smart-assets/website-icon.png'
import googleSheetsIcon from '@/assets/images/smart-assets/google-sheets-icon.svg'
import googleSlidesIcon from '@/assets/images/smart-assets/google-slides-icon.svg'
import youtubeIcon from '@/assets/images/smart-assets/youtube-icon.svg'
import customAssetIcon from '@/assets/images/smart-assets/browser-page-layout.svg'
// import facebookIcon from '@/assets/images/smart-assets/facebook-icon.png'
// import twitterIcon from '@/assets/images/smart-assets/twitter-icon.png'
// import clockIcon from '@/assets/images/smart-assets/clock-icon.png'
// import newsIcon from '@/assets/images/smart-assets/news-icon.png'
// import weatherIcon from '@/assets/images/smart-assets/weather-icon.png'

// import instagramDescriptionIcon from '@/assets/images/smart-assets/description-instagram.png'
import pdfDescriptionIcon from '@/assets/images/smart-assets/description-pdf.png'
import websiteDescriptionIcon from '@/assets/images/smart-assets/description-website.png'
import newsDescriptionIcon from '@/assets/images/smart-assets/description-news.png'
// import facebookDescriptionIcon from '@/assets/images/smart-assets/description-facebook.png'
// import twitterDescriptionIcon from '@/assets/images/smart-assets/description-twitter.png'
// import clockDescriptionIcon from '@/assets/images/smart-assets/description-clock.png'
// import weatherDescriptionIcon from '@/assets/images/smart-assets/description-weather.png'
import canvaIcon from '@/assets/images/smart-assets/canva-icon.svg'

import SfModal from '@/components/SfModal'
export default {
  components: {
    SfModal
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataShow: this.show,
      customMenuType: [
        {
          name: 'SMART_ASSETS',
          icon: '',
          descriptionIcon: '',
          title: this.$t('smart-asset'),
          description: this.$t('smart-asset-descriptions.smart-asset'),
          emitTo: '',
          isAvailable: false
        },
        {
          name: 'CUSTOM ASSET',
          icon: customAssetIcon,
          descriptionIcon: newsDescriptionIcon,
          title: 'Screen Editor',
          description:
            'Display dynamic assets that you can create and customize in our editor. Click Continue to get started.',
          emitTo: 'continue:smartAsset',
          isAvailable: true
        },
        // {
        //   name: 'INSTAGRAM',
        //   icon: instagramIcon,
        //   descriptionIcon: instagramDescriptionIcon,
        //   title: this.$t('instagram'),
        //   description: this.$t('smart-asset-descriptions.instagram'),
        //   emitTo: 'continue:instagram',
        //   isAvailable: true
        // },
        {
          name: 'PDF',
          icon: pdfIcon,
          descriptionIcon: pdfDescriptionIcon,
          title: this.$t('pdf'),
          description: this.$t('smart-asset-descriptions.pdf'),
          emitTo: 'continue:pdf',
          isAvailable: true
        },
        {
          name: 'WEBSITE',
          icon: websiteIcon,
          descriptionIcon: websiteDescriptionIcon,
          title: this.$t('website'),
          description: this.$t('smart-asset-descriptions.website'),
          emitTo: 'continue:website',
          isAvailable: true
        },
        {
          name: 'YOUTUBE',
          icon: youtubeIcon,
          descriptionIcon: '',
          title: this.$t('youtube'),
          description: this.$t('smart-asset-descriptions.youtube'),
          emitTo: 'continue:youtube',
          isAvailable: true
        },
        {
          name: 'GOOGLE SHEETS',
          icon: googleSheetsIcon,
          descriptionIcon: websiteDescriptionIcon,
          title: 'Google Sheets',
          description: 'Display a googlesheet easily.',
          emitTo: 'continue:googleSheets',
          isAvailable: true
        },
        {
          name: 'GOOGLE SLIDES',
          icon: googleSlidesIcon,
          descriptionIcon: websiteDescriptionIcon,
          title: 'Google Slide',
          description: 'Display a googleslide easily.',
          emitTo: 'continue:googleSlides',
          isAvailable: true
        },
        {
          name: 'CANVA',
          icon: canvaIcon,
          descriptionIcon: ' ',
          title: 'Canva',
          description: this.$t('smart-asset-descriptions.canva'),
          emitTo: 'continue:canva',
          isAvailable: true
        }
        // {
        //   name: 'FACEBOOK',
        //   icon: facebookIcon,
        //   descriptionIcon: facebookDescriptionIcon,
        //   title: 'Facebook',
        //   description: this.$t('smart-asset-descriptions.facebook'),
        //   emitTo: '',
        //   isAvailable: false
        // },
        // {
        //   name: 'TWITTER',
        //   icon: twitterIcon,
        //   descriptionIcon: twitterDescriptionIcon,
        //   title: 'Twitter',
        //   description: this.$t('smart-asset-descriptions.twitter'),
        //   emitTo: '',
        //   isAvailable: false
        // },
        // {
        //   name: 'CLOCK',
        //   icon: clockIcon,
        //   descriptionIcon: clockDescriptionIcon,
        //   title: this.$t('clock'),
        //   description: this.$t('smart-asset-descriptions.clock'),
        //   emitTo: '',
        //   isAvailable: false
        // },
        // {
        //   name: 'NEWS',
        //   icon: newsIcon,
        //   descriptionIcon: newsDescriptionIcon,
        //   title: this.$t('news'),
        //   description: this.$t('smart-asset-descriptions.new'),
        //   emitTo: '',
        //   isAvailable: false
        // },
        // {
        //   name: 'WEATHER',
        //   icon: weatherIcon,
        //   descriptionIcon: weatherDescriptionIcon,
        //   title: this.$t('weather'),
        //   description: this.$t('smart-asset-descriptions.weather'),
        //   emitTo: '',
        //   isAvailable: false
        // }
      ],
      typeDetail: 'SMART_ASSETS',
      searchSmartAsset: '',
      isAvailable: false
    }
  },

  watch: {
    show(value) {
      this.dataShow = value
      this.typeDetail = 'SMART_ASSETS'
    }
  },
  methods: {
    updateShow() {
      this.typeDetail = ''
      this.$emit('update:show', false)
    },
    updateDetail(item) {
      this.typeDetail = item.name
      this.isAvailable = item.isAvailable
    },
    onClickContinue(event) {
      if (this.typeDetail) {
        const index = this.customMenuType.findIndex((x) => x.name === this.typeDetail)
        const currentType = this.customMenuType[index]
        if (currentType.isAvailable) {
          this.$emit(currentType.emitTo)
        }
      }
    }
  },
  computed: {
    filterMenuType() {
      if (!this.searchSmartAsset) {
        return this.customMenuType
      }
      return this.customMenuType.filter((item) =>
        item.name.includes(this.searchSmartAsset.toUpperCase())
      )
    }
  },
  created() {}
}
</script>

<style lang="scss" scoped>
.modal-body-content {
  width: calc(100% - 2px);
  margin-top: -15px;
  .smart-asset-images-wrap {
    background-color: #f9f9f9;
    border-left: 1px solid #e7e5e5;
    .smart-asset-images {
      height: 370px;
      overflow-y: auto;
      width: 100%;
      padding: 0 15px;
      margin-top: 15px;
      .search-smart-asset {
        margin-bottom: 10px;
        input {
          width: 100%;
          height: 50px;
          border-radius: 25px;
          border: solid 1px #e1e1e1;
          background-color: #ffffff;
          padding: 0 15px;
          &:focus {
            border-radius: 25px;
            outline: none;
          }
        }
      }
    }
  }
  .smart-asset-info {
    margin-top: 15px;
    padding: 0 15px;
    &.smart-asset-default {
      display: block;
    }
    .smart-asset-info-title {
      margin-bottom: 15px;
      h4 {
        font-size: 16px;
        color: #234f82;
      }
    }
    .smart-asset-info-desc {
      p {
        font-size: 12px;
      }
    }
    .smart-asset-info-image {
      margin-top: 15px;
      img {
        width: 100%;
      }
    }
  }
  .pd-custom {
    padding: 0 8px;
  }
  .smart-asset-title {
    color: #2c7fe1;
    letter-spacing: 1.2px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .smart-asset-item {
    display: inline-block;
    text-align: center;
    width: 100%;
    background-color: #ffffff;
    padding: 15px 5px;
    margin-bottom: 15px;
    font-size: 12px;
    min-height: 120px;
    border-radius: 8px;
    color: #234f82;
    cursor: pointer;
    &:hover {
      box-shadow: 0 4px 16px 0 rgba(35, 108, 196, 0.2);
      border: solid 1px #236cc4;
    }
    &.active {
      box-shadow: 0 4px 16px 0 rgba(35, 108, 196, 0.2);
      border: solid 1px #236cc4;
    }
    .smart-asset-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
        width: 58px;
        height: 58px;
      }
      .div-coming-soon {
        position: absolute;
        text-align: center;
        width: 80%;
        background-color: #dadada;
        font-size: 10px;
        font-weight: 600;
        padding: 1px 0px;
      }
    }
    .smart-asset-name {
      text-align: center;
      width: 100%;
      margin-top: 10px;
    }
    &:last-child {
      margin-right: 0;
    }
    p {
      color: #9b9b9b;
      margin-top: 10px;
    }
    &.available {
      p {
        color: #000000;
        margin-top: 10px;
      }
      .btn {
        background-color: #f6f6f6;
      }
    }
    &.comming-soon {
      position: relative;
      .commings-soon-content {
        position: absolute;
        top: -35px;
        z-index: 999;
        width: 120px;
        padding: 0 !important;
        line-height: 30px;
        margin: 0 !important;
        left: -15px;
        display: none;
        background-color: #fff;
        box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.13);
        color: #000000;
      }
      &:hover {
        .commings-soon-content {
          display: block;
        }
      }
      .btn {
        background-color: #dcdcdc;
      }
    }
    .btn {
      width: 95px;
      height: 95px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 50%;
      &.instagram {
        background-image: url('~@/assets/images/smart-assets/instagram-icon.svg');
        &:hover {
          background-image: url('~@/assets/images/smart-asset-instagram-active.svg');
          background-color: #2c7fe1;
        }
      }
      &.youtube {
        background-image: url('~@/assets/images/smart-asset-youtube.svg');
      }
      &.facebook {
        background-image: url('~@/assets/images/smart-asset-facebook.svg');
      }
      &.twitter {
        background-image: url('~@/assets/images/smart-asset-twitter.svg');
      }
      &.sun {
        background-image: url('~@/assets/images/smart-asset-sun.svg');
      }
      &.calendar {
        background-image: url('~@/assets/images/smart-asset-calendar.svg');
      }
      &.rss {
        background-image: url('~@/assets/images/smart-asset-rss.svg');
      }
      &.clock {
        background-image: url('~@/assets/images/smart-asset-clock.svg');
      }
      &.code {
        background-image: url('~@/assets/images/smart-asset-code.svg');
      }
      &.website {
        background-image: url('~@/assets/images/smart-assets/website-icon.png');
        &:hover {
          background-color: #2c7fe1;
          background-image: url('~@/assets/images/smart-asset-website-active.svg');
        }
      }
      &.pdf {
        background-image: url('~@/assets/images/pdf.svg');
        &:hover {
          background-color: #2c7fe1;
          background-image: url('~@/assets/images/pdf-active.svg');
        }
      }
    }
  }
}
.modal-footer {
  padding: 15px 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ccc;
  text-align: right;
}
.btn {
  font-size: 14px;
}
.btn-custom-save {
  width: 150px;
  height: 40px;
  background-color: #2c7fe1;
  border-radius: 4px;
  font-weight: bold;
  border: 1px solid #2c7fe1;
  color: #fff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  &:hover {
    color: #fff;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
}
.btn-coming-soon {
  background-color: #9b9b9b;
  border: 1px solid #9b9b9b;
}
.btn-custom {
  width: 150px;
  height: 40px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #2c7fe1;
  color: #2c7fe1;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  &:hover {
    color: #2c7fe1;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
}
.menu-type {
  position: relative;
  height: 310px;
  overflow: auto;
}
</style>
