export default {
  // LoopDetail
  isEmpty: (state) => !state.items.length,
  items: (state) => state.items,
  loading: (state) => state.loading,
  loopId: (state) => state.loopId,
  treeFolder: (state) => state.treeFolder,
  breadcrumb: (state) => state.breadcrumb,
  pagination: (state) => state.pagination,
  pagePagination: (state) => state.pagination.page,
  totalPagination: (state) => state.pagination.total,
  limitPagination: (state) => state.pagination.limit,
  statistical: (state) => state.statistical,
  campaignItems: (state) => {
    return state.campaignItems
  },
  playerItems: (state) => {
    return state.playerItems
  },
  isCampaignEmpty: (state) => !!state.campaignItems.length <= 0,
  isPlayerEmpty: (state) => !!state.playerItems.length <= 0,
  campaignPagination: (state) => state.campaignPagination,
  playerPagination: (state) => state.playerPagination,
  nested_items: (state) => state.nested_items,
  loadingAssets: (state) => state.loadingAssets
  // ==========================================================================
}
