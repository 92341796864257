<template>
  <div class="container-fluid">
    <div class="card-header top-bar">
      <div class="header-left">
        <div class="admin-search-box">
          <SfInputSearch
            placeholder="Search"
            v-debounce:500ms="searchInputChange"
            class="sf-input-search"
          />
          <!-- <input v-debounce:400ms="searchInputChange" class="quicksearch" placeholder="Search..." type="text" />
          <div class="magnifying-glass"></div> -->
        </div>
      </div>
      <div class="header-right">
        <button class="sf-primary-add" @click="showModalCreate()">Add New Slide Template</button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 sf-table">
          <b-table
            :busy="loading"
            show-empty
            striped
            hover
            :items="items"
            :fields="fields"
            head-variant="none"
            table-variant="none"
            thead-class="custom-b-table"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty>
              <p class="d-flex justify-content-center">No data available in table</p>
            </template>
            <template v-slot:head(name)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(name)="field">
              <p>
                <router-link :to="{ path: `/admin/system/slide_templates/${field.item.id}/edit` }"
                  >{{ field.item.name }}
                </router-link>
              </p>
            </template>

            <template v-slot:head(displayname)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(displayname)="field">
              <p>
                {{ field.item.displayname }}
              </p>
            </template>

            <template v-slot:head(hardware_type)="field">
              <span class="custome-arrows">Hardware</span>
            </template>
            <template v-slot:cell(hardware_type)="field">
              <p v-if="field.item.hardware_type">
                {{ field.item.hardware_type.displayname }}
              </p>
            </template>

            <template v-slot:head(pi_firmware)="field">
              <span class="custome-arrows">Version application</span>
            </template>
            <template v-slot:cell(pi_firmware)="field">
              <p v-if="field.item.pi_firmware">
                {{ field.item.pi_firmware.version }} ({{ field.item.pi_firmware.version_code }})
              </p>
            </template>

            <template v-slot:head(description)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(description)="field">
              <p>{{ field.item.description }}</p>
            </template>

            <template v-slot:head(template_type)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(template_type)="field">
              <p>{{ field.item.template_type }}</p>
            </template>

            <template v-slot:head(version)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(version)="field">
              <p>{{ field.item.version }}</p>
            </template>

            <template v-slot:head(active)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(active)="field">
              <span
                v-if="field.item.active"
                class="badge badge-pill badge-success slide-template-active"
                >YES</span
              >
              <span v-else class="badge badge-pill badge-danger slide-template-active">NO</span>
            </template>

            <template v-slot:cell(action)="field">
              <button
                @click="shownModalActive(field.item.id)"
                class="button-active-table"
                title="Active"
              ></button>
              <button
                @click="shownModalEdit(field.item.id)"
                class="button-edit-table"
                title="Edit"
              ></button>
              <button
                @click="shownModalDelete(field.item.id)"
                class="button-delete-table"
                title="Delete"
              ></button>
            </template>
          </b-table>

          <div v-if="!isEmpty" class="my-3">
            <b-pagination
              class="sf-b-pagination"
              :total-rows="pagination.limit * pagination.total"
              :per-page="pagination.limit"
              @change="changePage"
              :limit="6"
              v-model="currentPage"
            />
          </div>
        </div>
      </div>
    </div>
    <SfModal
      :title="'Add New Slide Template'"
      :icon="'fa fa-code'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalCreate"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Name</label>
                </div>
                <input
                  v-model="newSlideTemplate.slide_template.name"
                  placeholder="Name..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newSlideTemplate.slide_template.name.required"
              >
                Name required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newSlideTemplate.slide_template.name.maxLength"
              >
                Use less than 250 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Display name</label>
                </div>
                <input
                  v-model="newSlideTemplate.slide_template.displayname"
                  placeholder="Display name..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newSlideTemplate.slide_template.displayname.required"
              >
                Display name required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newSlideTemplate.slide_template.displayname.maxLength"
              >
                Use less than 250 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Hardware</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="newSlideTemplate.slide_template.admin_hardware_type_id"
                    :options="listHardwareOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="
                  submitted && !$v.newSlideTemplate.slide_template.admin_hardware_type_id.required
                "
              >
                Hardware required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Version application</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="newSlideTemplate.slide_template.admin_pi_firmware_id"
                    :options="all_pi_firmware"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="
                  submitted && !$v.newSlideTemplate.slide_template.admin_pi_firmware_id.required
                "
              >
                Version application required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Version</label>
                </div>
                <input
                  :type="'number'"
                  v-model="newSlideTemplate.slide_template.version"
                  placeholder="Version..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newSlideTemplate.slide_template.version.required"
              >
                Version required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newSlideTemplate.slide_template.version.maxLength"
              >
                Please Use less than 10 characters
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newSlideTemplate.slide_template.version.minValue"
              >
                Version must be more than 0
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Description</label>
                </div>
                <textarea
                  v-model="newSlideTemplate.slide_template.description"
                  placeholder="Description..."
                  class="sf-input-text"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Type</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="newSlideTemplate.slide_template.template_type"
                    :options="optionSlideTemplateType"
                    :settings="{ minimumResultsForSearch: -1 }"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalCreate = false">Cancel</button>
        <button class="sf-primary" @click="saveSlideTemplate()">Save</button>
      </div>
    </SfModal>

    <SfModal
      :title="'Edit Slide Template'"
      :icon="'fa fa-code'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingTemplateDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Name</label>
                </div>
                <input
                  v-model="currentSlideTemplate.slide_template.name"
                  placeholder="Name..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentSlideTemplate.slide_template.name.required"
              >
                Name required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentSlideTemplate.slide_template.name.maxLength"
              >
                Use less than 250 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingTemplateDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Display name</label>
                </div>
                <input
                  v-model="currentSlideTemplate.slide_template.displayname"
                  placeholder="Display name..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentSlideTemplate.slide_template.displayname.required"
              >
                Display name required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentSlideTemplate.slide_template.displayname.maxLength"
              >
                Use less than 250 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingTemplateDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Hardware</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="currentSlideTemplate.slide_template.admin_hardware_type_id"
                    :options="listHardwareOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="
                  submitted &&
                  !$v.currentSlideTemplate.slide_template.admin_hardware_type_id.required
                "
              >
                Hardware required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingTemplateDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Version application</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="currentSlideTemplate.slide_template.admin_pi_firmware_id"
                    :options="all_pi_firmware"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="
                  submitted && !$v.currentSlideTemplate.slide_template.admin_pi_firmware_id.required
                "
              >
                Version application required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingTemplateDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Version</label>
                </div>
                <input
                  :type="'number'"
                  v-model="currentSlideTemplate.slide_template.version"
                  placeholder="Version..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentSlideTemplate.slide_template.version.required"
              >
                Version required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentSlideTemplate.slide_template.version.maxLength"
              >
                Use less than 10 characters
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentSlideTemplate.slide_template.version.minValue"
              >
                Version must be more than 0
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingTemplateDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Description</label>
                </div>
                <textarea
                  v-model="currentSlideTemplate.slide_template.description"
                  placeholder="Description..."
                  class="sf-input-text"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingTemplateDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Type</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="currentSlideTemplate.slide_template.template_type"
                    :options="optionSlideTemplateType"
                    :settings="{ minimumResultsForSearch: -1 }"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentSlideTemplate.slide_template.template_type.required"
              >
                Template Type required
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalEdit = false">Cancel</button>
        <button class="sf-primary" @click="updateSlideTemplate()">Save</button>
      </div>
    </SfModal>

    <SfDeleteModal :title="'Confirm Delete'" closeOnOverlay :show.sync="isShownModalDelete">
      <div class="content-delete">
        Deleting this slide template. Are you sure you want to delete this one?
      </div>
      <div class="footer-button">
        <button class="sf-secondary" @click="isShownModalDelete = false">Cancel</button>
        <button class="sf-primary" @click="removeSlideTemplate">OK</button>
      </div>
    </SfDeleteModal>

    <SfModal
      :title="'Active Slide Template'"
      :width="400"
      closeOnOverlay
      :show.sync="isShownModalConfirm"
    >
      <div class="content-delete">Active this template. Are you sure you want to active?</div>
      <div class="footer-button">
        <button class="sf-secondary" @click="isShownModalConfirm = false">Cancel</button>
        <button class="sf-primary" @click="activeSlideTemplate">OK</button>
      </div>
    </SfModal>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import SfModal from '@/components/SfModal.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import AdminSlideTemplateService from '@/services/admin-slide-template.service'
import { required, maxLength, minValue } from 'vuelidate/lib/validators'
import Select2 from 'v-select2-component'
import SfInputSearch from '@/components/SfInputSearch.vue'
import AdminHardwareService from '@/services/admin-hardware.service'

export default {
  name: 'admin-user-customer',

  components: {
    SfModal,
    SfDeleteModal,
    Select2,
    SfInputSearch
  },

  data() {
    return {
      msg: 'Welcome to Index!!!',
      isShownModalCreate: false,
      isShownModalDelete: false,
      isShownModalEdit: false,
      isShownModalConfirm: false,
      currentPage: 1,
      currentSlideTemplateID: '',
      submitted: false,
      fields: [
        {
          key: 'name',
          sortable: true,
          label: 'Name',
          colType: 'name'
        },
        {
          key: 'displayname',
          sortable: true,
          label: 'Display name',
          colType: 'displayname'
        },
        {
          key: 'hardware_type',
          sortable: true,
          colType: 'hardware-type',
          label: 'Hardware Type'
        },
        {
          key: 'pi_firmware',
          sortable: true,
          colType: 'pi_firmware',
          label: 'Version application'
        },
        {
          key: 'description',
          sortable: true,
          label: 'Description',
          colType: 'description'
        },
        {
          key: 'template_type',
          sortable: true,
          label: 'Type',
          colType: 'template_type'
        },
        {
          key: 'version',
          sortable: true,
          label: 'Version',
          colType: 'version'
        },
        {
          key: 'active',
          sortable: true,
          label: 'Active',
          colType: 'active'
        },
        {
          key: 'action',
          colType: 'button',
          label: 'Actions',
          class: 'sf-table-actions'
        }
      ],
      newSlideTemplate: {
        slide_template: {
          name: '',
          displayname: '',
          description: '',
          template_type: '',
          version: 0,
          actice: false,
          admin_hardware_type_id: 1,
          admin_pi_firmware_id: 0
        }
      },
      currentSlideTemplate: {
        slide_template: {
          name: '',
          displayname: '',
          description: '',
          template_type: '',
          version: 0,
          actice: false,
          admin_hardware_type_id: 1,
          admin_pi_firmware_id: 0
        }
      },
      defaultSlideTemplate: {
        slide_template: {
          name: '',
          displayname: '',
          description: '',
          template_type: '',
          version: '',
          actice: false,
          hardware_type: {
            id: 1
          },
          pi_firmware: {
            id: 0
          }
        }
      },
      dataSearch: '',
      loadingTemplateDetail: false,
      listHardwareOptions: []
    }
  },

  validations: {
    newSlideTemplate: {
      slide_template: {
        name: {
          required,
          maxLength: maxLength(250)
        },
        displayname: {
          required,
          maxLength: maxLength(250)
        },
        version: {
          required,
          maxLength: maxLength(10),
          minValue: minValue(0)
        },
        template_type: {
          required
        },
        admin_hardware_type_id: {
          required
        },
        admin_pi_firmware_id: {
          required
        }
      }
    },
    currentSlideTemplate: {
      slide_template: {
        name: {
          required,
          maxLength: maxLength(250)
        },
        displayname: {
          required,
          maxLength: maxLength(250)
        },
        version: {
          required,
          maxLength: maxLength(10),
          minValue: minValue(0)
        },
        template_type: {
          required
        },
        admin_hardware_type_id: {
          required
        },
        admin_pi_firmware_id: {
          required
        }
      }
    }
  },

  methods: {
    fetchData(activePage, search) {
      this.$store.dispatch('adminslidetemplate/getAllSlideTemplate', {
        page: activePage,
        limit: 10,
        search
      })
    },
    loadSlideTemplateType() {
      this.$store.dispatch('adminslidetemplate/getSlideTemplateType')
    },
    getAllFirmwaresNoPaging(activePage, search) {
      this.$store.dispatch('adminfirmware/getAllFirmwaresNoPaging')
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage)
    },
    showModalCreate() {
      this.setDataSlideTemplate(
        this.newSlideTemplate.slide_template,
        this.defaultSlideTemplate.slide_template
      )
      this.submitted = false
      this.isShownModalCreate = true
      this.loadSlideTemplateType()
    },
    shownModalDelete(customer_id) {
      this.isShownModalDelete = true
      this.currentSlideTemplateID = customer_id
    },
    shownModalActive(customer_id) {
      this.isShownModalConfirm = true
      this.currentSlideTemplateID = customer_id
    },
    shownModalEdit(customer_id) {
      this.currentSlideTemplateID = customer_id
      this.submitted = false
      this.getItemByID()
      this.setDataSlideTemplate(
        this.currentSlideTemplate.slide_template,
        this.defaultSlideTemplate.slide_template
      )
      this.isShownModalEdit = true
      this.loadSlideTemplateType()
    },
    saveSlideTemplate() {
      this.submitted = true
      if (this.$v.newSlideTemplate.$invalid) {
        return
      }
      AdminSlideTemplateService.createSlideTemplate(this.newSlideTemplate)
        .then((response) => {
          this.$toast.success('Slide Template successfully created')
          this.fetchData(this.currentPage)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalCreate = false
    },
    getItemByID() {
      this.loadingTemplateDetail = true
      AdminSlideTemplateService.detailSlideTemplate(this.currentSlideTemplateID)
        .then((response) => {
          this.setDataSlideTemplate(this.currentSlideTemplate.slide_template, response.data)
          this.loadingTemplateDetail = false
        })
        .catch((err) => {
          this.isShownModalEdit = false
          this.$toast.error(err.message)
        })
    },
    updateSlideTemplate() {
      this.submitted = true
      if (this.$v.currentSlideTemplate.$invalid) {
        return
      }
      AdminSlideTemplateService.updateSlideTemplate(
        this.currentSlideTemplateID,
        this.currentSlideTemplate
      )
        .then((response) => {
          this.$toast.success('Slide Template successfully updated')
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalEdit = false
    },
    setDataSlideTemplate(slide_template, new_slide_template) {
      slide_template.name = new_slide_template.name
      slide_template.displayname = new_slide_template.displayname
      slide_template.description = new_slide_template.description
      slide_template.template_type = new_slide_template.template_type
      slide_template.version = new_slide_template.version
      slide_template.active = new_slide_template.active
      slide_template.admin_hardware_type_id = new_slide_template.hardware_type
        ? new_slide_template.hardware_type.id
        : 1
      slide_template.admin_pi_firmware_id = new_slide_template.pi_firmware
        ? new_slide_template.pi_firmware.id
        : 0
    },
    removeSlideTemplate() {
      AdminSlideTemplateService.removeSlideTemplate(this.currentSlideTemplateID)
        .then((response) => {
          this.$toast.success('Slide Template successfully deleted')
          if (this.items.length === 1 && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1
          }
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalDelete = false
    },
    activeSlideTemplate() {
      AdminSlideTemplateService.activeSlideTemplate(this.currentSlideTemplateID)
        .then((response) => {
          this.$toast.success('Slide Template successfully actived')
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalConfirm = false
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    },
    getListHardwareType() {
      AdminHardwareService.getAllHardwaresNoPaging()
        .then((res) => {
          this.listHardwareOptions = res.data.map((hardwareType) => {
            return {
              id: hardwareType.id,
              text: hardwareType.displayname
            }
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
    }
  },

  computed: {
    ...mapState('adminslidetemplate', {
      items: 'items',
      pagination: 'pagination',
      optionSlideTemplateType: 'optionSlideTemplateType',
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('adminslidetemplate', ['isEmpty']),
    ...mapState('adminfirmware', {
      all_pi_firmware: 'all_pi_firmware'
    })
  },

  mounted() {
    this.fetchData(this.currentPage)
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    })
    this.getListHardwareType()
    this.getAllFirmwaresNoPaging()
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
  }
}
</script>

<style lang="scss" scoped>
@import '../../Adminpage.scss';
.text-area-control {
  padding: 6px 0px;
  width: 90%;
  border: none;
  outline: none;
  height: 100px;
  max-height: 150px;
  margin: 1px;
}

.custom-input-group-text {
  align-items: flex-start;
}

.input-group-text-area {
  height: auto !important;
}

.slide-template-active {
  width: 50px;
  height: 20px;
  margin-top: 15px;
  line-height: 15px;
}

.button-active-table {
  background-image: url('../../../../assets/images/active-slide-template.svg');
  width: 24px;
  height: 24px;
  background-size: cover;
  background-color: transparent;
  border: 0;
  margin-right: 20px;
  &:hover {
    background-image: url('../../../../assets/images/active-slide-template-active.svg');
  }
}
</style>
