import state from './adminProgramState'
import mutations from './adminProgramMutations'
import getters from './adminProgramGetters'
import actions from './adminProgramActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
