export default {
  SET_ITEMS(state, data) {
    state.items = data.items
    state.pagination.total = data.total_pages
    state.pagination.page = data.current_page
  },
  SET_ERROR(state, data) {
    state.error = data
  },
  SET_LOADING(state, data) {
    state.loading = data
  },
  SET_LIST_USER_SUB_TYPE(state, data) {
    state.userSupTypeOptions = data
  },
  SET_LIST_ORGANIZATION(state, data) {
    state.organizationOptions = data
  }
}
