<template>
  <div class="uikit">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="material-name">
            <h2>Notifications</h2>
          </div>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-4">
          <div class="material-title">Success</div>
        </div>
        <div class="col-6">
          <button @click="sfToast('success')" class="sf-primary">Success</button>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-4">
          <div class="material-title">Information</div>
        </div>
        <div class="col-6">
          <button @click="sfToast('info')" class="sf-primary">Information</button>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-4">
          <div class="material-title">Warning</div>
        </div>
        <div class="col-6">
          <button @click="sfToast('warning')" class="sf-primary">Warning</button>
        </div>
      </div>

      <div class="row mb-1">
        <div class="col-4">
          <div class="material-title">Danger</div>
        </div>
        <div class="col-6">
          <button @click="sfToast('error')" class="sf-primary">Danger</button>
        </div>
      </div>

      <div class="row mb-1">
        <div class="col-4">
          <div class="material-title">Multi lines</div>
        </div>
        <div class="col-6">
          <button @click="sfToastMultiLine" class="sf-primary">Multi lines</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'account',
  components: {},
  data() {
    return {}
  },
  created() {},
  methods: {
    sfToastMultiLine() {
      this.$toast.open({
        message:
          'Danger. Something went wrong with the server and we were unable to save. Please try again.',
        type: 'error',
        duration: 200000
      })
    },
    sfToast(type) {
      this.$toast.open({
        message: 'Screenfluence notification!',
        type: type,
        duration: 2000
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
