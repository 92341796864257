import state from './subOrgState'
import mutations from './subOrgMutations'
import getters from './subOrgGetters'
import actions from './subOrgActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
