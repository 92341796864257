<template>
  <div class="diagnostics">
    <div class="row">
      <div class="col-6">
        <div class="sf-card">
          <div class="card-heading">
            <p>Basic information</p>
          </div>
          <div class="card-body">
            <div class="mt-3">
              <p>
                <span class="sf-text-strong mr-2">Device Name</span>
                <span>{{ dataDiagnostics && dataDiagnostics.deviceName }}</span>
              </p>
            </div>
            <div class="mt-3">
              <p>
                <span class="sf-text-strong mr-2">Free Space</span>
                <span>{{ dataDiagnostics && dataDiagnostics.free_space }}</span>
              </p>
            </div>

            <div class="mt-3">
              <p>
                <span class="sf-text-strong mr-2">Last Reboot</span>
                <span v-if="dataDiagnostics.lastReboot" id="last-reboot-popover">
                  {{
                    moment
                      .tz(dataDiagnostics.lastReboot, itemsByID.time_zone_name)
                      .format('YYYY-MM-DD HH:mm:ss')
                  }}
                </span>
                <b-popover
                  target="last-reboot-popover"
                  v-if="dataDiagnostics.lastReboot"
                  triggers="hover"
                  placement="top"
                >
                  <div>
                    {{
                      moment.tz(dataDiagnostics.lastReboot, 'UTC').format('YYYY-MM-DD HH:mm:ss z')
                    }}
                  </div>
                </b-popover>
              </p>
            </div>

            <div class="mt-3">
              <p>
                <span class="sf-text-strong mr-2">Current Campaign</span>
                <span>{{ dataDiagnostics && dataDiagnostics.currentCampaign }}</span>
              </p>
            </div>

            <div class="mt-3">
              <p>
                <span class="sf-text-strong mr-2">Last Update Stamp</span>
                <span>{{ dataDiagnostics && dataDiagnostics.lastUpdateStamp }}</span>
              </p>
            </div>

            <div class="mt-3">
              <p>
                <span class="sf-text-strong mr-2">Application Version</span>
                <span>{{ dataDiagnostics && dataDiagnostics.applicationVersion }}</span>
              </p>
            </div>

            <div class="mt-3">
              <p>
                <span class="sf-text-strong mr-2">Device ID</span>
                <span>{{ dataDiagnostics && dataDiagnostics.deviceID }}</span>
              </p>
            </div>

            <div class="mt-3">
              <p>
                <span class="sf-text-strong mr-2">Mac Address</span>
                <span>{{ dataDiagnostics && dataDiagnostics.macAddress }}</span>
              </p>
            </div>

            <div class="mt-3">
              <p>
                <span class="sf-text-strong mr-2">IP Address</span>
                <span>{{ dataDiagnostics && dataDiagnostics.ip_address }}</span>
              </p>
            </div>

            <div class="mt-3">
              <p>
                <span class="sf-text-strong mr-2">Killed At</span>
                <span v-if="dataDiagnostics.killed_at" id="killed-at-popover">
                  {{
                    moment
                      .tz(dataDiagnostics.killed_at, itemsByID.time_zone_name)
                      .format('YYYY-MM-DD HH:mm:ss')
                  }}
                </span>
                <b-popover
                  target="killed-at-popover"
                  v-if="dataDiagnostics.killed_at"
                  triggers="hover"
                  placement="top"
                >
                  <div>
                    {{
                      moment.tz(dataDiagnostics.killed_at, 'UTC').format('YYYY-MM-DD HH:mm:ss z')
                    }}
                  </div>
                </b-popover>
              </p>
            </div>

            <div class="mt-3">
              <p>
                <span class="sf-text-strong mr-2">Connection Type</span>
                <span>{{ dataDiagnostics && dataDiagnostics.connection_type }}</span>
              </p>
            </div>

            <div class="mt-3">
              <p>
                <span class="sf-text-strong mr-2">Wifi Type</span>
                <span>{{ dataDiagnostics && dataDiagnostics.wifi_security_type }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="sf-card">
          <div class="card-heading d-flex justify-content-between">
            <p>Detail Information</p>
            <div class="">
              <toggle-button
                :value="toggleStatus"
                :sync="true"
                :width="44"
                :height="22"
                @change="onChangeToggle"
              />
              <span class="ml-2">Active</span>
            </div>
          </div>
          <div v-if="toggleStatus" class="card-body">
            <div class="mt-3">
              <p>
                <span class="sf-text-strong mr-2">CPU Temperature</span>
                <span>{{ dataDiagnostics && dataDiagnostics.cpu_temperature }}</span>
              </p>
            </div>

            <div class="mt-3">
              <p>
                <span class="sf-text-strong mr-2">RAM Usage</span>
                <span>{{ dataDiagnostics && dataDiagnostics.ram_usage }}</span>
              </p>
            </div>

            <div class="mt-3">
              <p>
                <span class="sf-text-strong mr-2">CPU Usage</span>
                <span>{{ dataDiagnostics && dataDiagnostics.cpu_usage }}</span>
              </p>
            </div>

            <div class="mt-3">
              <p>
                <span class="sf-text-strong mr-2">Bytes Received</span>
                <span>{{ dataDiagnostics && dataDiagnostics.bytes_received }}</span>
              </p>
            </div>

            <div class="mt-3">
              <p>
                <span class="sf-text-strong mr-2">Bytes Sent</span>
                <span>{{ dataDiagnostics && dataDiagnostics.bytes_sent }}</span>
              </p>
            </div>
          </div>
          <div v-else class="card-body">
            <div class="special-display">
              <p>Enable detailed diagnostic logging</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sf-table mt-3">
      <b-table
        :fields="fields"
        :items="files"
        show-empty
        striped
        hover
        table-variant="none"
        :busy="loadingFiles"
        thead-class="custom-b-table"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template v-slot:head(displayname)="field">
          <span class="custome-arrows">{{ field.label }}</span>
        </template>
        <template v-slot:cell(displayname)="field">
          <p>{{ field.item.displayname }}</p>
        </template>

        <template v-slot:head(type)="field">
          <span class="custome-arrows">{{ field.label }}</span>
        </template>
        <template v-slot:cell(type)="field">
          <img
            v-if="field.item.type"
            class="img-diagnostic"
            :src="require('@/assets/images/' + field.item.type + '.svg')"
          />
        </template>

        <template v-slot:head(name)="field">
          <span class="custome-arrows">{{ field.label }}</span>
        </template>
        <template v-slot:cell(name)="field">
          <p>{{ field.item.name }}</p>
        </template>

        <template v-slot:head(size)="field">
          <span class="custome-arrows">{{ field.label }}</span>
        </template>
        <template v-slot:cell(size)="field">
          <p>{{ field.item.size }}</p>
        </template>
      </b-table>
    </div>

    <div class="my-2">
      <b-pagination
        class="sf-b-pagination"
        :total-rows="pagination.limit * pagination.total"
        :per-page="pagination.limit"
        @change="changePage"
        :limit="6"
        v-model="currentPage"
      />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import AdminPlayerService from '@/services/admin-player.service.js'
export default {
  name: 'Dianogstics',
  props: {
    dataDiagnostics: {},
    show: {},
    currentPlayerID: {},
    special: {
      type: Boolean
    }
  },
  data() {
    return {
      toggleStatus: false,
      currentPage: 1,
      fields: [
        {
          key: 'displayname',
          sortable: true,
          colType: 'displayname',
          label: 'Display File Name'
        },
        {
          key: 'type',
          sortable: true,
          colType: 'type',
          label: 'Asset Type'
        },
        {
          key: 'name',
          sortable: true,
          colType: 'name',
          label: 'Actual File Name'
        },
        {
          key: 'size',
          sortable: true,
          colType: 'size',
          label: 'File Size'
        }
      ]
    }
  },
  methods: {
    ...mapMutations('adminplayer', {
      setPlayer: 'SET_DATA_PLAYER'
    }),
    onChangeToggle(value) {
      AdminPlayerService.updateAdminPlayer(this.currentPlayerID, {
        player: {
          special: this.toggleStatus
        }
      })
        .then((res) => {
          this.$toast.success('Change status player successfully')
          this.toggleStatus = value.value
          this.setPlayer(res.data)
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
    },
    getAllFiles(id, page, search) {
      const payload = {
        id: id,
        page: page,
        limit: 10,
        search: search
      }
      this.$store.dispatch('player/getAllFiles', payload)
    },
    changePage(value) {
      this.currentPage = value
      this.getAllFiles(this.currentPlayerID, this.currentPage)
    }
  },
  computed: {
    ...mapState('player', {
      files: 'files',
      pagination: 'pagination',
      loadingFiles: 'loadingFiles'
    }),
    ...mapState('adminplayer', {
      itemsByID: 'itemsByID'
    })
  },
  mounted() {
    this.$bus.$off('onSearchInputChange')
    this.getAllFiles(this.currentPlayerID, this.currentPage, this.dataSearch)
    this.toggleStatus = this.special
    this.$bus.$emit('showSearchBox', true)
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.currentPage = 1
      this.getAllFiles(this.currentPlayerID, this.currentPage, this.dataSearch)
    })
    this.$bus.$emit('send_active_button_to_header', this.dataSearch)
  }
}
</script>
<style lang="scss" scoped>
@import '@/pages/player/ViewPlayer.scss';
.custom-description {
  height: 75px !important;
}
.custom-title-infomation {
  font: 14px 'Open Sans', sans-serif;
  font-weight: 600;
  color: #212529;
}
.custom-infomation {
  font: 12px 'Open Sans', sans-serif;
  font-weight: 400;
  color: #212529;
}
.info-diagnostic {
  .row {
    &:not(:nth-child(1)) {
      margin-top: 10px;
    }
  }
}
.show_info {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
}
.col-custom {
  padding: 0 7px;
  width: 50%;
  margin-left: 10px;
}
.card-information {
  margin: 0;
  border-radius: 0;
  border: none;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.admin-form {
  line-height: 1.231;
  font-size: 14px;
  height: 50px;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background: #fff;
  border: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #267eb0;
  font-weight: 600;
  padding-bottom: 5px;
  padding-top: 5px;
  &:nth-child(1) {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
}
.table {
  width: 100%;
  color: #212529;
  max-width: 100%;
  border-collapse: collapse;
  background-color: transparent;
  border-spacing: 0;
}
th {
  text-align: inherit;
  padding: 9px;
  line-height: 1.49;
  vertical-align: middle;
  border: none;
}
p {
  margin: 0rem;
}
.list-button-detail {
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
}
.detail-info {
  font: 14px 'Opens Sans', sans-serif;
  font-weight: bold;
  border: none;
}
.authen-overview {
  margin-right: 10px;
}
.panel-body {
  position: relative;
  padding: 15px;
  border: 1px solid #e2e2e2;
}
.table-diag {
  margin-top: 50px;
}

@media only screen and (max-width: 900px) {
  .main-content {
    flex-direction: column;
    .authen-overview,
    .col-custom {
      width: 100%;
    }
    .col-custom {
      margin-left: 0%;
      margin-top: 2%;
    }
  }
}
</style>
