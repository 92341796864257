import { SITE_DOMAIN } from '@/.env.js'
import SmartAsset from '@/services/smart-assets.service'
export default {
  setInstagramMode(context, payload) {
    context.commit('SET_IG_MODE', payload)
  },
  setOriginX(context, number) {
    context.commit('SET_ORIGIN_X', number)
  },
  setOriginY(context, number) {
    context.commit('SET_ORIGIN_Y', number)
  },
  setActiveEl(context, active) {
    context.commit('SET_ACTIVE_ELEMENT', active)
  },
  loadAlignItem(context, activeElement) {
    context.commit('LOAD_ALIGN_ITEM', activeElement)
  },

  loadTextAlign(context, activeElement) {
    context.commit('LOAD_TEXT_ALIGN', activeElement)
  },

  setDimensionsHeight(context, number) {
    context.commit('SET_DIMENTIONS_HEIGHT', number)
  },
  setDimensionsWidth(context, number) {
    context.commit('SET_DIMENTIONS_WIDTH', number)
  },
  updateAttrEl(context, payload) {
    context.commit('UPDATE_ATTR_EL', payload)
  },
  updateAllAttrEl(context, payload) {
    context.commit('UPDATE_ALL_ATTR_EL', payload)
  },
  updateBgColorEditor(context, payload) {
    context.commit('UPDATE_BG_COLOR', payload)
  },
  getInstagramToken(context, payload) {
    const formData = new FormData()
    formData.append('code', payload.code)
    formData.append('redirect_uri', SITE_DOMAIN + 'library')
    SmartAsset.getInstagramToken(formData).then((res) => {
      const instagramInfo = {
        access_token: res.data.access_token,
        expires_in: res.data.expires_in,
        user: {
          id: res.data.user_id
        }
      }
      context.commit('SET_IG_TOKEN', instagramInfo)
      localStorage.removeItem('instagramInfo')
      localStorage.setItem('instagramInfo', JSON.stringify(instagramInfo))
      if (payload.reAuthentication) {
        this.dispatch('instagrameditor/loadIgEditorInfo')
      } else {
        this.dispatch('instagrameditor/loadNewInstagram', instagramInfo)
      }
    })
  },
  loadNewInstagram(context, igInfo) {
    SmartAsset.getInstagramMedia(igInfo.user.id).then((res) => {
      res.data.media.data[0].username = res.data.username
      const instagramData = res.data.media.data[0]
      this.dispatch('instagrameditor/getListIG', res.data.media.data)
      this.dispatch('instagrameditor/loadIgDetail', instagramData)
    })
  },
  loadIgEditorInfo(context) {
    let instagram = localStorage.getItem('instagram')
    if (instagram) {
      instagram = JSON.parse(instagram)
      const igEditorID = instagram.smart_asset_id
      const orientationChanged = instagram.orientation_changed
      const orientation = instagram.orientation
      SmartAsset.getSmartAssetsByID(igEditorID).then((igEditorInfo) => {
        const instagramInfoStorage = localStorage.getItem('instagramInfo')
        const instagramInfo = JSON.parse(instagramInfoStorage)
        // let numberOfSlide = igEditorInfo.data.properties.image_count ? igEditorInfo.data.properties.image_count : 5
        const backgroundImage = igEditorInfo.data.properties.background_image
        const logo = igEditorInfo.data.properties.logo
        context.commit('UPDATE_BG_IMG_STATUS', !!backgroundImage)
        context.commit('UPDATE_LOGO_IMG_STATUS', !!logo)
        // let instagramURL = `https://api.instagram.com/v1/users/${instagramInfo.user.id}/media/recent/?access_token=${instagramInfo.access_token}&count=${numberOfSlide}`
        SmartAsset.getInstagramMedia(instagramInfo.user.id).then((res) => {
          if (res.data.media.data) {
            res.data.media.data[0].username = res.data.username
            const instagramData = res.data.media.data[0]
            this.dispatch('instagrameditor/loadIgDetail', instagramData)
            this.dispatch('instagrameditor/getListIG', res.data.media.data)
            if (orientationChanged) {
              context.commit('SET_IG_MODE', { orientation })
            } else {
              context.commit('LOAD_IG_PROPERTIES', igEditorInfo.data)
              context.commit('SET_DEFAULT_EDIT', igEditorInfo.data)
            }
          } else {
            this.dispatch('instagrameditor/reAuthenticationIG')
          }
        })
      })
    }
  },

  loadIgDetail(context, instagramData) {
    context.commit('LOAD_INSTAGRAM', instagramData)
    // let instagramInfoStorage = localStorage.getItem('instagramInfo');
    // let instagramInfo = JSON.parse(instagramInfoStorage);
    // let igPostID = instagramData.id;
    // if(igPostID) {
    //   let instagramURL = `https://api.instagram.com/v1/media/${igPostID}/comments?access_token=${instagramInfo.access_token}`;
    //     fetch(instagramURL, {
    //       method: 'get'
    //       }).then(res=> {
    //         return res.json();
    //       }).then(jsonData => {
    //         if (jsonData && jsonData.data.length){
    //           let posterComments = jsonData.data.filter(function(data) {
    //             return data.from.username === instagramData.user.username;
    //           });
    //           let instagramComment = posterComments.length ? posterComments[0] : '';
    //           if(instagramComment) {
    //             instagramData.firstComment = instagramComment;
    //             context.commit('LOAD_INSTAGRAM', instagramData);
    //           }
    //           else {
    //             context.commit('LOAD_INSTAGRAM', instagramData);
    //           }
    //         }
    //         else {
    //           context.commit('LOAD_INSTAGRAM', instagramData);
    //         }
    //   })
    // }
  },

  reAuthenticationIG(context) {
    const instagramRedirectUri = SITE_DOMAIN + 'library'
    const popupWidth = 700
    const popupHeight = 500
    const popupLeft = (window.screen.width - popupWidth) / 2
    const popupTop = (window.screen.height - popupHeight) / 2
    const popup = window.open(
      'https://api.instagram.com/oauth/authorize/?client_id=581481562798275&response_type=code&scope=user_profile,user_media' +
        '&redirect_uri=' +
        instagramRedirectUri,
      '_blank',
      'width=' +
        popupWidth +
        ',height=' +
        popupHeight +
        ',left=' +
        popupLeft +
        ',top=' +
        popupTop +
        ''
    )
    var interval = setInterval(() => {
      try {
        if (popup.location.search) {
          clearInterval(interval)
          const search = popup.location.search
          const arraySearch = search.split('=')
          if (arraySearch && arraySearch.length > 1) {
            const code = arraySearch[1]
            const payload = {
              code,
              reAuthentication: true
            }
            this.dispatch('instagrameditor/getInstagramToken', payload)
          }
          popup.close()
        }
      } catch (evt) {}
    }, 500)
  },

  setTextAlign(context, payload) {
    context.commit('SET_TEXT_ALIGN', payload)
  },
  setTextAlignItems(context, payload) {
    context.commit('SET_TEXT_ALIGN_ITEMS', payload)
  },
  setFontFamily(context, payload) {
    context.commit('SET_FONT_FAMILY', payload)
  },
  setFontSize(context, payload) {
    context.commit('SET_FONT_SIZE', payload)
  },
  setFontStyle(context, payload) {
    context.commit('SET_FONT_STYLE', payload)
  },

  setTextBgColor(context, payload) {
    context.commit('SET_TEXT_BG_COLOR', payload)
  },

  setTextColor(context, payload) {
    context.commit('SET_TEXT_COLOR', payload)
  },

  setDurationPerSlide(context, payload) {
    context.commit('SET_DURATION_PER_SLIDE', payload)
  },
  setNumberOfSlide(context, payload) {
    context.commit('SET_NUMBER_OF_SLIDE', payload)
  },
  activeSnap(context, payload) {
    context.commit('ACTIVE_SNAP', payload)
  },
  setBgImage(context, payload) {
    context.commit('SET_BG_IMAGE', payload)
  },
  removeBgImage(context) {
    context.commit('REMOVE_BG_IMAGE')
  },
  setLogoImage(context, payload) {
    context.commit('SET_LOGO_IMAGE', payload)
  },
  removeLogoImage(context) {
    context.commit('REMOVE_LOGO_IMAGE')
  },
  setMaskImage(context, payload) {
    context.commit('SET_MASK_IMAGE', payload)
  },

  removeMaskImage(context) {
    context.commit('REMOVE_MASK_IMAGE')
  },

  setTemplateName(context, payload) {
    context.commit('SET_TEMPLATE_NAME', payload)
  },
  setTemplateID(context, payload) {
    context.commit('SET_TEMPLATE_ID', payload)
  },
  setTemplateAction(context, payload) {
    context.commit('SET_TEMPLATE_ACTION', payload)
  },
  resetAll(context) {
    context.commit('RESET_SETTING_IG')
  },
  setDefaultSetting(context) {
    context.commit('DEFAULT_SETTING_IG')
  },
  resetTextAttr(context) {
    context.commit('RESET_TEXT_ATTR')
  },
  loadTemplate(context, payload) {
    context.commit('LOAD_IG_TEMPLATE', payload)
    context.commit('SET_DEFAULT_EDIT', payload.smart_asset)
  },
  activeToolBar(context, payload) {
    context.commit('ACTIVE_TOOL_BAR', payload)
  },
  activeWidgetMenu(context, payload) {
    context.commit('ACTIVE_WIDGET_MENU', payload)
  },
  activeWidgetElement(context, payload) {
    context.commit('ACTIVE_WIDGET_ELEMENT', payload)
  },
  pushToHistory(context) {
    context.commit('PUSH_TO_HISTORY')
  },
  undo(context) {
    context.commit('UNDO')
  },
  redo(context) {
    context.commit('REDO')
  },
  removeWidget(context, payload) {
    context.commit('REMOVE_WIDGET', payload)
  },
  getListIG(context, payload) {
    context.commit('GET_LIST_IG', payload)
  },
  prevSlide(context) {
    if (this.state.instagrameditor.currentPost > 0) {
      context.commit('PREV_SLIDE')
      this.dispatch(
        'instagrameditor/loadIgDetail',
        this.state.instagrameditor.listIG[this.state.instagrameditor.currentPost]
      )
    }
  },
  nextSlide(context) {
    if (
      this.state.instagrameditor.currentPost <
      this.state.instagrameditor.numberData.numberOfSlide - 1
    ) {
      context.commit('NEXT_SLIDE')
      this.dispatch(
        'instagrameditor/loadIgDetail',
        this.state.instagrameditor.listIG[this.state.instagrameditor.currentPost]
      )
    }
  }
}
