import Vue from 'vue'
import Router from 'vue-router'

import {
  checkAccessMiddleware,
  setPageTitleMiddleware,
  checkAccessIsAdmin,
  checkAccessIsOrgAdmin,
  checkPermissionAccess
} from './middlewares'
import { routes } from './routes'

Vue.use(Router)

const router = new Router({
  linkActiveClass: 'is-active',
  mode: 'history',
  routes
})

// router.beforeEach(initCurrentUserStateMiddleware)
router.beforeEach(checkPermissionAccess)
router.beforeEach(checkAccessMiddleware)
router.beforeEach(checkAccessIsOrgAdmin)
router.beforeEach(checkAccessIsAdmin)
router.beforeEach(setPageTitleMiddleware)

export default router
