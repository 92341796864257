export const adminClients = {
  adminOrganizations: 'organization',
  adminPlayers: 'player',
  adminCustomers: 'user',
  adminSettings: 'setting'
}
export const adminManagement = {
  adminOrgTypes: 'orgType',
  adminEmployees: 'employee',
  adminPost: 'post',
  adminTemplate: 'smartAssetTemplate'
}
export const adminSystem = {
  adminFirmware: 'adminPiFirmware',
  adminSlideTemplates: 'slideTemplate',
  adminTerm: 'term',
  adminRole: 'role',
  adminUpdate: 'update',
  adminHardware: 'adminHardwareType',
  adminLanguage: 'language',
  adminTemplateCategories: 'templateCategory'
}
