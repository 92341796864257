<template>
  <div class="row preview pt-3">
    <div class="col-12">
      <div class="row">
        <div class="col-xs-9">
          <div class="slide-btn">
            <a @click="prevSlide" v-bind:class="{ first: getState.isFirstSlide }" class="prev"
              >&#8249;</a
            >
            <a @click="nextSlide" v-bind:class="{ last: getState.isLastSlide }" class="next"
              >&#8250;</a
            >
          </div>
        </div>

        <div class="col-3 snap">
          <div class="form-group">
            <label>
              <input
                @click="handleSnapClick($event)"
                id="snap-checked"
                type="checkbox"
                class="check-custom toggle-switch"
              />
              <span class="check-toggle"></span>
            </label>
            <span>{{ $t('snap-to-grid') }}</span>
          </div>
        </div>
        <div class="col-12">
          <div
            class="background-editor"
            v-bind:style="{
              backgroundColor: bgColorEditor.color,
              'background-image': 'url(' + bgColorEditor.bgImage.imageData + ')'
            }"
          >
            <div class="fullscreen-size">
              <div v-if="elementActive.photo">
                <span v-if="isPortrait">(1080x1920) | </span>
                <span v-else>(1920x1080) | </span>
                <span>x: {{ isPortrait ? photo.x * 4 : photo.x * 3 }}</span
                >; <span>y: {{ isPortrait ? photo.y * 4 : photo.y * 3 }}</span
                >; <span>w: {{ isPortrait ? photo.w * 4 : photo.w * 3 }}</span
                >;
                <span>h: {{ isPortrait ? photo.h * 4 : photo.h * 3 }}</span>
              </div>
              <div v-if="elementActive.profilePic">
                <span v-if="isPortrait">(1080x1920) | </span>
                <span v-else>(1920x1080) | </span>
                <span>x: {{ isPortrait ? profilePic.x * 4 : profilePic.x * 3 }}</span
                >; <span>y: {{ isPortrait ? profilePic.y * 4 : profilePic.y * 3 }}</span
                >; <span>w: {{ isPortrait ? profilePic.w * 4 : profilePic.w * 3 }}</span
                >;
                <span>h: {{ isPortrait ? profilePic.h * 4 : profilePic.h * 3 }}</span>
              </div>
              <div v-if="elementActive.titleIg">
                <span v-if="isPortrait">(1080x1920) | </span>
                <span v-else>(1920x1080) | </span>
                <span>x: {{ isPortrait ? titleIg.x * 4 : titleIg.x * 3 }}</span
                >; <span>y: {{ isPortrait ? titleIg.y * 4 : titleIg.y * 3 }}</span
                >; <span>w: {{ isPortrait ? titleIg.w * 4 : titleIg.w * 3 }}</span
                >;
                <span>h: {{ isPortrait ? titleIg.h * 4 : titleIg.h * 3 }}</span>
              </div>

              <div v-if="elementActive.textIg">
                <span v-if="isPortrait">(1080x1920) | </span>
                <span v-else>(1920x1080) | </span>
                <span>x: {{ isPortrait ? textIg.x * 4 : textIg.x * 3 }}</span
                >; <span>y: {{ isPortrait ? textIg.y * 4 : textIg.y * 3 }}</span
                >; <span>w: {{ isPortrait ? textIg.w * 4 : textIg.w * 3 }}</span
                >;
                <span>h: {{ isPortrait ? textIg.h * 4 : textIg.h * 3 }}</span>
              </div>

              <div v-if="elementActive.tagsIg">
                <span v-if="isPortrait">(1080x1920) | </span>
                <span v-else>(1920x1080) | </span>
                <span>x: {{ isPortrait ? tagsIg.x * 4 : tagsIg.x * 3 }}</span
                >; <span>y: {{ isPortrait ? tagsIg.y * 4 : tagsIg.y * 3 }}</span
                >; <span>w: {{ isPortrait ? tagsIg.w * 4 : tagsIg.w * 3 }}</span
                >;
                <span>h: {{ isPortrait ? tagsIg.h * 4 : tagsIg.h * 3 }}</span>
              </div>
            </div>

            <div class="editor-area-wrap" id="editorArea" v-bind:class="{ grid: getSnap }">
              <vue-draggable-resizable
                v-if="instagramData && instagramData.media_url && getState.photo.visitable"
                @resizestop="onResizstop"
                @dragstop="onDragstop"
                @activated="onActivated('photo')"
                :parent="true"
                :grid="getGridDrag"
                :x="photo.x >= 0 ? photo.x : null"
                :y="photo.y >= 0 ? photo.y : null"
                :w="photo.w >= 0 ? photo.w : null"
                :h="photo.h >= 0 ? photo.h : null"
              >
                <div
                  class="photo"
                  v-bind:style="{
                    backgroundImage: `url(${instagramData.media_url})`
                  }"
                ></div>
                <div
                  v-if="getMaskImage"
                  class="mask"
                  v-bind:style="{
                    backgroundImage: `url(${getMaskImage})`
                  }"
                ></div>
              </vue-draggable-resizable>

              <vue-draggable-resizable
                v-if="instagramData && getState.profilePic.visitable"
                @activated="onActivated('profilePic')"
                @resizestop="onResizstop"
                @dragstop="onDragstop"
                :parent="true"
                :grid="getGridDrag"
                :x="profilePic.x >= 0 ? profilePic.x : null"
                :y="profilePic.y >= 0 ? profilePic.y : null"
                :w="profilePic.w >= 0 ? profilePic.w : null"
                :h="profilePic.h >= 0 ? profilePic.h : null"
              >
                <img :src="getProfilePic" class="profile-picture" />
              </vue-draggable-resizable>

              <vue-draggable-resizable
                v-if="getState.titleIg.visitable"
                @activated="onActivated('titleIg')"
                @resizestop="onResizstop"
                @dragstop="onDragstop"
                :parent="true"
                :grid="getGridDrag"
                :x="titleIg.x >= 0 ? titleIg.x : null"
                :y="titleIg.y >= 0 ? titleIg.y : null"
                :w="titleIg.w >= 0 ? titleIg.w : null"
                :h="titleIg.h >= 0 ? titleIg.h : null"
              >
                <div
                  class="text-attr-area"
                  v-bind:style="{
                    backgroundColor: titleIg.textAttr.backgroundColor,
                    alignItems: titleIg.textAttr.alignItems,
                    fontFamily: titleIg.textAttr.fontFamily,
                    fontWeight: titleIg.textAttr.fontWeight,
                    fontSize: titleIg.textAttr.fontSize + 'px'
                  }"
                >
                  <p
                    class="text-attr-area-content"
                    v-bind:style="{
                      textAlign: titleIg.textAttr.textAlign,
                      color: titleIg.textAttr.textColor
                    }"
                    v-if="instagramData"
                  >
                    {{ instagramData.username }}
                  </p>
                </div>
              </vue-draggable-resizable>

              <vue-draggable-resizable
                v-if="instagramData && instagramData.caption && getState.textIg.visitable"
                @activated="onActivated('textIg')"
                @resizestop="onResizstop"
                @dragstop="onDragstop"
                :parent="true"
                :grid="getGridDrag"
                :x="textIg.x >= 0 ? textIg.x : null"
                :y="textIg.y >= 0 ? textIg.y : null"
                :w="textIg.w >= 0 ? textIg.w : null"
                :h="textIg.h >= 0 ? textIg.h : null"
              >
                <div
                  class="text-attr-area"
                  v-bind:style="{
                    backgroundColor: textIg.textAttr.backgroundColor,
                    alignItems: textIg.textAttr.alignItems,
                    fontFamily: textIg.textAttr.fontFamily,
                    fontWeight: textIg.textAttr.fontWeight,
                    fontSize: textIg.textAttr.fontSize + 'px'
                  }"
                >
                  <p
                    class="text-attr-area-content"
                    v-bind:style="{
                      textAlign: textIg.textAttr.textAlign,
                      color: textIg.textAttr.textColor
                    }"
                    v-html="getCaption"
                  ></p>
                </div>
              </vue-draggable-resizable>

              <!-- <vue-draggable-resizable
                v-if=" instagramData.firstComment && instagramData.firstComment.text && getState.tagsIg.visitable"
                @activated="onActivated('tagsIg')"
                @resizestop="onResizstop"
                @dragstop="onDragstop"
                :parent="true"
                :grid="getGridDrag"
                :x="tagsIg.x"
                :y="tagsIg.y"
                :w="tagsIg.w"
                :h="tagsIg.h"
              >
                <div class="text-attr-area"
                  v-bind:style="{
                  backgroundColor: tagsIg.textAttr.backgroundColor,
                  alignItems: tagsIg.textAttr.alignItems,
                  fontFamily: tagsIg.textAttr.fontFamily,
                  fontWeight: tagsIg.textAttr.fontWeight,
                  color: tagsIg.textAttr.textColor,
                  fontSize: tagsIg.textAttr.fontSize+'px',
                  }">
                  <p
                    class="text-attr-area-content"
                      v-bind:style="{
                        textAlign: tagsIg.textAttr.textAlign,
                    }"
                    v-html="getInstagramComment"></p>
                </div>
              </vue-draggable-resizable> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import VueDraggableResizable from 'vue-draggable-resizable'
// import { SITE_DOMAIN } from '@/.env.js'
import prevIcon from '@/assets/images/previous.svg'
import nextIcon from '@/assets/images/next.svg'
import igAvatar from '@/assets/images/ig-avatar.svg'
export default {
  data() {
    return {
      prevIcon,
      nextIcon,
      igAvatar,
      activeOriginX: true,
      templateName: this.$store.getters.getTemplateName,
      widgets: this.$store.getters.getWidget
    }
  },
  created() {},
  updated() {
    this.templateName = this.$store.getters.getTemplateName
  },
  computed: {
    elementActive() {
      return this.$store.getters['instagrameditor/getElementActive']
    },

    getActiveToolbar() {
      return this.$store.getters['instagrameditor/getToolBar']
    },

    getState() {
      const state = this.$store.getters['instagrameditor/getState']
      return state
    },

    instagramData() {
      return this.$store.getters['instagrameditor/getInstagramData']
    },
    getGridDrag() {
      return this.$store.getters['instagrameditor/getGridDrag']
    },

    getSnap() {
      return this.$store.getters['instagrameditor/getSnap']
    },

    getPhotoSize() {
      return this.$store.getters['instagrameditor/getPhotoSize']
    },

    getCaption() {
      const instagramData = this.$store.getters['instagrameditor/getInstagramData']
      const captionText = instagramData.caption
      const getTextIg = this.$store.getters['instagrameditor/getTextIg']
      const textRedered = this.renderText(captionText, getTextIg)
      return textRedered
    },
    getInstagramComment() {
      const instagramData = this.$store.getters['instagrameditor/getInstagramData']
      const tagsIg = this.$store.getters['instagrameditor/getTagsIg']
      const firstComment = instagramData.firstComment.text
      const textRedered = this.renderText(firstComment, tagsIg)
      return textRedered
    },
    getProfilePic() {
      // let getInstagramData = this.$store.getters['instagrameditor/getInstagramData']
      const logo = this.$store.getters['instagrameditor/getLogo']
      if (logo && logo.imageData && logo.imageData !== 'none') {
        return logo.imageData
      } else {
        return igAvatar
        // return getInstagramData.user.profile_picture
      }
    },
    getMaskImage() {
      const mask = this.$store.getters['instagrameditor/getMask']
      if (mask && mask.imageData) {
        return mask.imageData
      } else {
        return null
      }
    }
  },
  methods: {
    onDragstop(left, top) {
      this.$store.dispatch('instagrameditor/setOriginY', Number(top))
      this.$store.dispatch('instagrameditor/setOriginX', Number(left))
      this.$store.dispatch('instagrameditor/pushToHistory')
    },
    prevSlide() {
      this.$store.dispatch('instagrameditor/prevSlide')
    },
    nextSlide() {
      this.$store.dispatch('instagrameditor/nextSlide')
    },
    onResizstop(left, top, width, height) {
      const payload = {
        x: Number(left),
        y: Number(top),
        w: Number(width),
        h: Number(height)
      }
      this.$store.dispatch('instagrameditor/updateAttrEl', payload)
      this.$store.dispatch('instagrameditor/pushToHistory')
    },
    onActivated(El) {
      this.$store.dispatch('instagrameditor/setActiveEl', El)
      this.$store.dispatch('instagrameditor/loadAlignItem', El)
      this.$store.dispatch('instagrameditor/loadTextAlign', El)
      this.$store.dispatch('instagrameditor/activeToolBar', 'edit')
    },
    handleSnapClick(e) {
      const snapChecked = e.currentTarget.checked
      this.$store.dispatch('instagrameditor/activeSnap', snapChecked)
    },
    renderText(text, activeItem) {
      if (text) {
        const textRedered = text
          .replace(/#(\w+)/g, function (_, $1) {
            return `<span
                    class="tag"
                    style="color: ${
                      activeItem && activeItem.hashtags ? activeItem.hashtags.textColor : ''
                    };
                           font-family: ${
                             activeItem && activeItem.hashtags ? activeItem.hashtags.fontFamily : ''
                           };
                           font-weight: ${
                             activeItem && activeItem.hashtags ? activeItem.hashtags.fontWeight : ''
                           };
                           font-size: ${
                             activeItem && activeItem.hashtags
                               ? activeItem.hashtags.fontSize + 'px'
                               : ''
                           };
                           ">#${$1}</span>`
          })
          .replace(/@(\w+)/g, function (_, $1) {
            return `<span
                    class="mention"
                    style="color: ${
                      activeItem && activeItem.mentions ? activeItem.mentions.textColor : ''
                    };
                           font-family: ${
                             activeItem && activeItem.mentions ? activeItem.mentions.fontFamily : ''
                           };
                           font-weight: ${
                             activeItem && activeItem.mentions ? activeItem.mentions.fontWeight : ''
                           };
                           font-size: ${
                             activeItem && activeItem.mentions
                               ? activeItem.mentions.fontSize + 'px'
                               : ''
                           };
                           ">@${$1}</span>`
          })
        return textRedered
      }
    }
  },
  props: {
    instagramUser: Object,
    instagramImage: String,
    haddle: String,
    photo: Object,
    profilePic: Object,
    titleIg: Object,
    textIg: Object,
    tagsIg: Object,
    bgColorEditor: Object,
    isPortrait: Boolean
  }
}
</script>
<style scoped>
.preview {
  background-color: #efefef;
  min-height: 480px;
}
.portrait .preview {
  min-height: 600px;
}
.portrait .background-editor {
  width: 270px;
  height: 480px;
  margin: 15px auto 0;
}
.background-editor {
  width: 640px;
  height: 360px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 15px auto;
  position: relative;
}
.portrait .editor-area-wrap {
  width: 270px;
  height: 480px;
}
.editor-area-wrap {
  position: relative;
  width: 640px;
  height: 360px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.grid {
  background: linear-gradient(-90deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / 10px 10px,
    linear-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / 10px 10px;
}
.photo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.profile-picture {
  width: 100%;
  height: 100%;
}
.text-attr-area {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
}
.text-attr-area .text-attr-area-content {
  width: 100%;
}

.slide-btn {
  display: flex;
}

.slide-btn a {
  cursor: pointer;
  margin: 0 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 72px;
  height: 25px;
  border-radius: 4px;
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
  font-size: 36px;
  line-height: 15px;
  text-align: center;
  font-weight: 100;
}
.slide-btn a:hover {
  color: rgb(51, 122, 183);
  text-decoration: none !important;
}
.slide-btn a.last,
.slide-btn a.first {
  color: gray;
  cursor: default;
  background-color: #ccc;
}
.slide-btn a span {
  font-size: 4rem;
}

.slide-btn a img {
  width: 32px;
}
.fullscreen-size {
  position: absolute;
  top: -18px;
  right: 0;
  font-size: 10px;
}
</style>
