<template>
  <div class="header">
    <div class="header-container">
      <div class="logo">
        <router-link :to="{ name: 'index' }">
          <img :src="logo" alt="logo" />
        </router-link>
      </div>
      <div class="menu-icon">
        <a @click="onMenuIconClick">
          <menuIcon />
        </a>
      </div>
      <div v-if="infoUser" id="v-step-0" class="user-info d-none d-sm-flex">
        <div class="user-dropdown-wrap">
          <p>
            <b class="username">{{ infoUser && infoUser.name ? infoUser.name : infoUser.email }}</b>
            {{ $t('from') }}
            <b class="organization">{{
              infoUser && infoUser.organization ? infoUser.organization.name : ''
            }}</b>
          </p>
          <p class="t-down">&#x2039;</p>
          <div class="user-dropdown">
            <div class="user-dropdown-item" @click="gotToProfile">
              <span>{{ $t('profile') }}</span> <i class="far fa-user"></i>
            </div>
            <div class="user-dropdown-item" @click="logout">
              <span>{{ $t('logout') }}</span> <i class="fas fa-sign-out-alt"></i>
            </div>
          </div>
        </div>
        <div class="ml-3">
          <!-- <div class="sf-select-group">
            <Select2
              class="sf-select2"
              v-model="$i18n.locale"
              :options="languageConfig"
              :settings="{ minimumResultsForSearch: -1 }"
              @change="changeLanguage($event)"
            />
          </div> -->
        </div>
      </div>
    </div>
    <!-- <v-tour name="myTour" :steps="steps"></v-tour> -->
    <SfWarningModal
      :title="$t('modal.title.warning')"
      closeOnOverlay
      :showCloseButton="false"
      :show.sync="isDeleteOrgByAdmin"
    >
      <div class="content-warning">
        <p class="content">{{ $t('someone-else-delete-org-by-admin') }}</p>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="onClickOk">
          {{ $t('ok') }}
        </button>
      </div>
    </SfWarningModal>
  </div>
</template>

<script>
import * as authService from '../services/auth.service'
import logo from '@/assets/images/logo.svg'
import menuIcon from '@/assets/images/menu.svg?inline'
import { mapState, mapActions, mapGetters } from 'vuex'
// import magnifyingGlass from '@/assets/images/magnifying-glass.svg?inline'
import searchIcon from '@/assets/images/sf-search-icon.svg'
import closeIcon from '@/assets/images/close-x.svg'
import { language } from '@/config'
// import Select2 from 'v-select2-component'
import UsersService from '@/services/users.service'
import SfWarningModal from '@/components/SfWarningModal.vue'
export default {
  name: 'Header',
  components: {
    menuIcon,
    SfWarningModal
    // Select2
  },
  data() {
    return {
      langs: ['en', 'fr', 'es'],
      languageConfig: language(),
      showSearchBox: true,
      searchIcon,
      closeIcon,
      logo,
      user: {},
      editProfileForm: {
        user: {
          language: 'en'
        }
      },
      isDeleteOrgByAdmin: false
    }
  },
  channels: {
    AccountChannel: {
      connected() {},
      rejected() {},
      received(data) {
        const user = this.$cookies.get('user')
        if (data.action === 'delete' && data.user_id === user.id) {
          return authService.makeLogout()
        }
        if (data.action === 'change') {
          this.isDeleteOrgByAdmin = true
        }
        if (data.action === 'update') {
          this.getInfoUser()
        }
      },
      disconnected() {}
    }
  },
  computed: {
    ...mapState('user', {
      infoUser: 'infoUser'
    }),
    ...mapGetters('user', {
      currentUser: 'getCurrentUser'
    })
  },
  watch: {
    infoUser(data) {
      const { language } = data
      this.$i18n.locale = language || 'en'
    }
  },
  methods: {
    ...mapActions('user', {
      getCurrentUser: 'getCurrent'
    }),
    getInfoUser() {
      this.$store.dispatch('user/getCurrent')
    },

    logout() {
      authService.makeLogout()
    },
    gotToProfile() {
      const userID = this.user.id
      this.$router.push(`/users/${userID}/edit_profile`)
    },
    onMenuIconClick() {
      const body = document.body
      const classList = body.classList
      if (classList && classList[0] === 'enlarged') {
        body.classList.remove('enlarged')
      } else {
        body.classList.add('enlarged')
      }
    },
    changeLanguage(value) {
      this.editProfileForm.user.language = value
      UsersService.updateProfileUser(this.editProfileForm)
        .then((res) => {
          this.$toast.success(this.$t('language-successfully-updated'))
          this.$cookies.remove('language')
          this.$cookies.set('language', res.data.language || 'en')
          this.getInfoUser()
          this.$bus.$emit('changeLanguage', {
            language: res.data.language || 'en'
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    onClickOk() {
      location.reload()
    }
  },
  created() {},
  mounted() {
    // this.$tours['myTour'].start()
    this.getCurrentUser()
    const user = this.$cookies.get('user')
    this.$cable.subscribe({
      channel: 'AccountChannel',
      organization: user.organization_id
    })
    if (user) {
      this.user = user
    }
  }
}
</script>

<style lang="scss" scoped>
.locale-changer {
  width: 100px;
}
.header {
  .is-active {
    color: #e01b3c;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu {
    font-size: 25px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      padding: 0 15px;
    }

    &.side {
      font-size: 15px;
      .logout-button {
        cursor: pointer;
      }
    }
  }
  .searchBox {
    position: relative;
    margin-left: 15px;
    .magnifying-glass {
      position: absolute;
      right: 5px;
      top: 8px;
    }
    .quicksearch {
      border-radius: 20px;
      background-color: #f3f3f3;
      font-size: 14px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
      border: none;
      height: 33px;
      padding-right: 33px;
      padding-left: 10px;
      width: 181px;
    }
  }
  .user-info {
    position: absolute;
    right: 0;
    margin-right: 10px;
    height: 35px;
    line-height: 35px;
    background-color: #f1f1f1;
    padding-left: 10px;
    border-radius: 1px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.11);
    &:hover {
      background-color: #e3e3e3;
    }
    cursor: pointer;
    .user-dropdown-wrap {
      display: flex;
      position: relative;
      .t-down {
        transform: rotate(-90deg);
        margin-left: 3px;
        font-size: 25px;
      }
      &:hover {
        .user-dropdown {
          display: block;
        }
      }
      .user-dropdown {
        display: none;
        width: 185px;
        height: 86px;
        border-radius: 1px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.11);
        background-color: #ffffff;
        position: absolute;
        right: -10px;
        top: 35px;
        z-index: 10;
        color: #a4aabd;
        font-weight: 600;
        padding: 15px;
        .user-dropdown-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    p {
      b {
        font-weight: bold;
      }
      .organization {
        color: #76c322;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .content-warning {
    padding: 35px 45px;
    text-align: center;
    color: #4a4a4a;
    font: 14px 'Open Sans', sans-serif;
    .content {
      font-size: 22px;
      font-weight: 600;
      color: #2c7fe1;
    }
  }
}
</style>
