export default {
  loading: false,
  error: false,
  items: [],
  treeFolder: [],
  treeFolderBefore: [],
  breadcrumb: [],
  editLoopIds: [],
  pagination: {
    limit: 10,
    page: 1,
    total: -1
  }
}
