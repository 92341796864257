<template>
  <div class="container-fluid">
    <div class="card-header top-bar">
      <div class="header-left">
        <div class="admin-search-box">
          <SfInputSearch
            placeholder="Search"
            v-debounce:500ms="searchInputChange"
            class="sf-input-search"
          />
        </div>
      </div>
      <div class="header-right">
        <button class="sf-primary-add" @click="showModalCreate()">Add New Org Type</button>
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 sf-table">
          <b-table
            :busy="loading"
            show-empty
            striped
            hover
            :items="items"
            :fields="fields"
            head-variant="none"
            table-variant="none"
            thead-class="custom-b-table"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty>
              <p class="d-flex justify-content-center">No data available in table</p>
            </template>
            <template v-slot:head(name)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(name)="field">
              <p>
                <router-link :to="{ path: `/admin/management/org_types/${field.item.id}` }">{{
                  field.item.name
                }}</router-link>
              </p>
            </template>

            <template v-slot:head(max_users)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(max_users)="field">
              <p>{{ field.item.max_users }}</p>
            </template>

            <template v-slot:head(max_media)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(max_media)="field">
              <p>{{ field.item.max_media }}</p>
            </template>

            <template v-slot:head(max_data)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(max_data)="field">
              <p>{{ field.item.max_data }}</p>
            </template>

            <template v-slot:head(max_players)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(max_players)="field">
              <p>{{ field.item.max_players }}</p>
            </template>

            <template v-slot:head(max_loops)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(max_loops)="field">
              <p>{{ field.item.max_loops }}</p>
            </template>

            <template v-slot:head(max_campaigns)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(max_campaigns)="field">
              <p>{{ field.item.max_campaigns }}</p>
            </template>

            <template v-slot:cell(action)="field">
              <button
                title="Edit"
                @click="shownModalEdit(field.item.id)"
                class="button-edit-table"
              ></button>
              <button
                @click="shownModalDelete(field.item.id)"
                class="button-delete-table"
                :title="'Delete'"
              ></button>
            </template>
          </b-table>
          <div v-if="!isEmpty" class="my-3">
            <b-pagination
              class="sf-b-pagination"
              :total-rows="pagination.limit * pagination.total"
              :per-page="pagination.limit"
              @change="changePage"
              :limit="6"
              v-model="currentPage"
            />
          </div>
        </div>
      </div>
    </div>
    <SfModal
      title="Add New Org Type"
      :icon="'fa fa-wrench'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalCreate"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Org Type Name</label>
                </div>
                <input
                  v-model="newOrgType.org_type.name"
                  placeholder="Org Type Name..."
                  class="sf-input-text"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newOrgType.org_type.name.required">
                Organization Type required
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newOrgType.org_type.name.maxLength">
                Use less than 250 characters.
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Org Type Price</label>
                </div>
                <input
                  :type="'number'"
                  v-model="newOrgType.org_type.price"
                  placeholder="Org Type Price..."
                  class="sf-input-text"
                  :step="'0.01'"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newOrgType.org_type.price.required">
                Price required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.price.maxLength"
              >
                Use less than 10 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Description</label>
                </div>
                <textarea
                  v-model="newOrgType.org_type.display_description"
                  placeholder="Org Type Description..."
                  class="sf-input-text"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Max Users</label>
                </div>
                <input
                  :type="'number'"
                  v-model="newOrgType.org_type.max_users"
                  placeholder="Max Users..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.max_users.required"
              >
                Max Users required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.max_users.maxLength"
              >
                Use less than 10 characters
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.max_users.minValue"
              >
                Max Users must be more than 0
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Max Data</label>
                </div>
                <input
                  :type="'number'"
                  v-model="newOrgType.org_type.max_data"
                  placeholder="Max Data..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.max_data.required"
              >
                Max Data required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.max_data.maxLength"
              >
                Use less than 10 characters
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.max_data.minValue"
              >
                Max Data must be more than 0
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Max Media</label>
                </div>
                <input
                  :type="'number'"
                  v-model="newOrgType.org_type.max_media"
                  placeholder="Max Media..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.max_media.required"
              >
                Max Media required.
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.max_media.maxLength"
              >
                Use less than 10 characters
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.max_media.minValue"
              >
                Max Media must be more than 0
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Max Loop</label>
                </div>
                <input
                  :type="'number'"
                  v-model="newOrgType.org_type.max_loops"
                  placeholder="Max Loops..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.max_loops.required"
              >
                Max Loops required.
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.max_loops.maxLength"
              >
                Use less than 10 characters
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.max_loops.minValue"
              >
                Please use less than 10 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Max Campaigns</label>
                </div>
                <input
                  :type="'number'"
                  v-model="newOrgType.org_type.max_campaigns"
                  placeholder="Max Campaigns..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.max_campaigns.required"
              >
                Max Campaigns required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.max_campaigns.maxLength"
              >
                Use less than 10 characters
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.max_campaigns.minValue"
              >
                Max Campaigns must be more than 0
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Max Players</label>
                </div>
                <input
                  :type="'number'"
                  v-model="newOrgType.org_type.max_players"
                  placeholder="Max Players..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.max_players.required"
              >
                Max Players required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.max_players.maxLength"
              >
                Use less than 10 characters
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newOrgType.org_type.max_players.minValue"
              >
                Max Players must be more than 0
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalCreate = false">Cancel</button>
        <button class="sf-primary" @click="saveOrgType()">Save</button>
      </div>
    </SfModal>

    <SfModal title="Edit Org Type" :width="768" closeOnOverlay :show.sync="isShownModalEdit">
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingOrgTypeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Org Type Name</label>
                </div>
                <input
                  v-model="currentOrgType.org_type.name"
                  placeholder="Org Type Name..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.name.required"
              >
                Organization Type required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.name.maxLength"
              >
                Use less than 10 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingOrgTypeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Org Type Name</label>
                </div>
                <input
                  :type="'number'"
                  v-model="currentOrgType.org_type.price"
                  placeholder="Org Type Price..."
                  class="sf-input-text"
                  :step="'0.01'"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.price.required"
              >
                Price required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.price.maxLength"
              >
                Use less than 10 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingOrgTypeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Description</label>
                </div>
                <textarea
                  v-model="currentOrgType.org_type.display_description"
                  placeholder="Org Type Description..."
                  class="sf-input-text"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingOrgTypeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Max Users</label>
                </div>
                <input
                  :type="'number'"
                  v-model="currentOrgType.org_type.max_users"
                  placeholder="Max Users..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_users.required"
              >
                Max Users required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_users.maxLength"
              >
                Use less than 10 characters
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_users.minValue"
              >
                Max Users must be more than 0
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingOrgTypeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Max Data</label>
                </div>
                <input
                  :type="'number'"
                  v-model="currentOrgType.org_type.max_data"
                  placeholder="Max Data..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_data.required"
              >
                Max Data required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_data.maxLength"
              >
                Use less than 10 characters
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_data.minValue"
              >
                Max Data must be more than 0
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingOrgTypeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Max Media</label>
                </div>
                <input
                  :type="'number'"
                  v-model="currentOrgType.org_type.max_media"
                  placeholder="Max Media..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_media.required"
              >
                Max Media required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_media.maxLength"
              >
                Use less than 10 characters
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_media.minValue"
              >
                Max Media must be more than 0
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingOrgTypeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Max Loops</label>
                </div>
                <input
                  :type="'number'"
                  v-model="currentOrgType.org_type.max_loops"
                  placeholder="Max Loops..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_loops.required"
              >
                Max Loops required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_loops.maxLength"
              >
                Use less than 10 characters
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_loops.minValue"
              >
                Max Loops must be more than 0
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingOrgTypeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Max Players</label>
                </div>
                <input
                  :type="'number'"
                  v-model="currentOrgType.org_type.max_players"
                  placeholder="Max Players..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_players.required"
              >
                Max Players required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_players.maxLength"
              >
                Use less than 10 characters
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_players.minValue"
              >
                Players must be more than 0
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalEdit = false">Cancel</button>
        <button class="sf-primary" @click="updateOrgType()">Save</button>
      </div>
    </SfModal>

    <SfDeleteModal :title="'Confirm Delete'" closeOnOverlay :show.sync="isShownModalDelete">
      <div class="content-delete">
        Deleting this customer. Are you sure you want to delete this one?
      </div>
      <div class="footer-button">
        <button class="sf-secondary" @click="isShownModalDelete = false">Cancel</button>
        <button class="sf-primary" @click="removeOrgType">OK</button>
      </div>
    </SfDeleteModal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SfModal from '@/components/SfModal.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import SfInputSearch from '@/components/SfInputSearch.vue'
// import Select2 from 'v-select2-component'
import AdminOrgTypeService from '@/services/admin-org-type.service'
import { required, maxLength, minValue } from 'vuelidate/lib/validators'
export default {
  name: 'admin-user-customer',

  components: {
    SfModal,
    SfDeleteModal,
    SfInputSearch
  },

  data() {
    return {
      msg: 'Welcome to Index!!!',
      isShownModalCreate: false,
      isShownModalDelete: false,
      isShownModalEdit: false,
      currentPage: 1,
      currentOrgTypeID: '',
      submitted: false,
      fields: [
        {
          key: 'name',
          sortable: true,
          label: 'Type',
          colType: 'name'
        },
        {
          key: 'max_users',
          sortable: true,
          label: 'Max Users',
          colType: 'max_users'
        },
        {
          key: 'max_media',
          sortable: true,
          label: 'Max Media',
          colType: 'max_media'
        },
        {
          key: 'max_data',
          sortable: true,
          label: 'Max Data',
          colType: 'max_data'
        },
        {
          key: 'max_players',
          sortable: true,
          label: 'Max Players',
          colType: 'max_players'
        },
        {
          key: 'max_loops',
          sortable: true,
          label: 'Max Loops',
          colType: 'max_loops'
        },
        {
          key: 'max_campaigns',
          sortable: true,
          label: 'Max Campaigns',
          colType: 'max_campaigns'
        },
        {
          key: 'ACTION',
          colType: 'button',
          label: 'Actions',
          class: 'sf-table-actions'
        }
      ],
      newOrgType: {
        org_type: {
          name: '',
          price: '',
          display_description: '',
          max_users: '',
          max_media: '',
          max_data: '',
          max_players: '',
          max_loops: '',
          max_campaigns: ''
        }
      },
      currentOrgType: {
        org_type: {
          name: '',
          price: '',
          display_description: '',
          max_users: 0,
          max_media: 0,
          max_data: 0,
          max_players: 0,
          max_loops: 0,
          max_campaigns: 0
        }
      },
      defaultOrgType: {
        org_type: {
          name: '',
          price: '',
          display_description: '',
          max_users: '',
          max_media: '',
          max_data: '',
          max_players: '',
          max_loops: '',
          max_campaigns: ''
        }
      },
      dataSearch: '',
      loadingOrgTypeDetail: false
    }
  },

  validations: {
    newOrgType: {
      org_type: {
        name: {
          required,
          maxLength: maxLength(250)
        },
        price: {
          required,
          maxLength: maxLength(10)
        },
        max_users: {
          required,
          maxLength: maxLength(10),
          minValue: minValue(0)
        },
        max_media: {
          required,
          maxLength: maxLength(10),
          minValue: minValue(0)
        },
        max_data: {
          required,
          maxLength: maxLength(10),
          minValue: minValue(0)
        },
        max_players: {
          required,
          maxLength: maxLength(10),
          minValue: minValue(0)
        },
        max_loops: {
          required,
          maxLength: maxLength(10),
          minValue: minValue(0)
        },
        max_campaigns: {
          required,
          maxLength: maxLength(10),
          minValue: minValue(0)
        }
      }
    },
    currentOrgType: {
      org_type: {
        name: {
          required,
          maxLength: maxLength(250)
        },
        price: {
          required,
          maxLength: maxLength(10),
          minValue: minValue(0)
        },
        max_users: {
          required,
          maxLength: maxLength(10),
          minValue: minValue(0)
        },
        max_media: {
          required,
          maxLength: maxLength(10),
          minValue: minValue(0)
        },
        max_data: {
          required,
          maxLength: maxLength(10),
          minValue: minValue(0)
        },
        max_players: {
          required,
          maxLength: maxLength(10),
          minValue: minValue(0)
        },
        max_loops: {
          required,
          maxLength: maxLength(10),
          minValue: minValue(0)
        },
        max_campaigns: {
          required,
          maxLength: maxLength(10),
          minValue: minValue(0)
        }
      }
    }
  },

  methods: {
    fetchData(activePage, search) {
      this.$store.dispatch('adminorgtype/getAllOrgType', {
        page: activePage,
        limit: 10,
        search
      })
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage, this.dataSearch)
    },
    showModalCreate() {
      this.submitted = false
      this.setDataOrgType(this.newOrgType.org_type, this.defaultOrgType.org_type)
      this.isShownModalCreate = true
    },
    shownModalDelete(customer_id) {
      this.isShownModalDelete = true
      this.currentOrgTypeID = customer_id
    },
    shownModalEdit(customer_id) {
      this.submitted = false
      this.setDataOrgType(this.currentOrgType.org_type, this.defaultOrgType.org_type)
      this.isShownModalEdit = true
      this.currentOrgTypeID = customer_id
      this.getItemByID()
    },
    saveOrgType() {
      this.submitted = true
      if (this.$v.newOrgType.$invalid) {
        return
      }
      AdminOrgTypeService.createOrgType(this.newOrgType)
        .then((response) => {
          this.$toast.success('Org Type successfully created')
          this.fetchData(this.currentPage)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalCreate = false
    },
    getItemByID() {
      this.loadingOrgTypeDetail = true
      AdminOrgTypeService.detailOrgType(this.currentOrgTypeID)
        .then((response) => {
          this.setDataOrgType(this.currentOrgType.org_type, response.data)
          this.loadingOrgTypeDetail = false
        })
        .catch((err) => {
          this.isShownModalEdit = false
          this.$toast.error(err.message)
        })
    },
    updateOrgType() {
      this.submitted = true
      if (this.$v.currentOrgType.$invalid) {
        return
      }
      AdminOrgTypeService.updateOrgType(this.currentOrgTypeID, this.currentOrgType)
        .then((response) => {
          this.$toast.success('Organization Type successfully updated')
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalEdit = false
    },
    setDataOrgType(org_type, new_org_type) {
      org_type.name = new_org_type.name
      org_type.price = new_org_type.price
      org_type.display_description = new_org_type.display_description
      org_type.max_users = new_org_type.max_users
      org_type.max_media = new_org_type.max_media
      org_type.max_data = new_org_type.max_data
      org_type.max_players = new_org_type.max_players
      org_type.max_loops = new_org_type.max_loops
      org_type.max_campaigns = new_org_type.max_campaigns
    },
    removeOrgType() {
      AdminOrgTypeService.removeOrgType(this.currentOrgTypeID)
        .then((response) => {
          this.$toast.success('Organization Type successfully deleted')
          if (this.items.length === 1 && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1
          }
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalDelete = false
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    }
  },

  computed: {
    ...mapState('adminorgtype', {
      items: 'items',
      pagination: 'pagination',
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('adminorgtype', ['isEmpty'])
  },

  mounted() {
    this.fetchData(this.currentPage)
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    })
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
  }
}
</script>

<style lang="scss" scoped>
@import '../../Adminpage.scss';
.text-area-control {
  padding: 6px 0px;
  width: 90%;
  border: none;
  outline: none;
  height: 100px;
  max-height: 150px;
  margin: 1px;
}

.custom-input-group-text {
  align-items: flex-start;
}

.input-group-text-area {
  height: auto !important;
}
</style>
