<template>
  <div class="login">
    <div id="large-header" class="large-header">
      <canvas id="demo-canvas"></canvas>
      <div class="main" v-if="step === 1">
        <div class="panel-background">
          <p class="login-title">{{ $t('login') }}</p>
          <p class="login-desc">{{ $t('show-anything-anywhere') }}</p>
        </div>
        <div class="panel-login">
          <div class="logo">
            <img :src="logo" alt="logo" />
          </div>
          <div class="login-form">
            <div class="row mt-3">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sm">
                    <label class="primary-label">{{ $t('email') }}</label>
                  </div>
                  <input id="email" class="sf-input-text" type="text" v-model="email" />
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sm">
                    <label class="primary-label">{{ $t('password') }}</label>
                  </div>
                  <input
                    id="password"
                    class="sf-input-text"
                    type="password"
                    v-model="password"
                    v-on:keyup.enter="makeLogin"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sm">
                    <label class="primary-label"></label>
                  </div>
                  <div class="w-100">
                    <label class="sf-checkbox">
                      <span class="check-title">{{ $t('remember-me') }}</span>
                      <input type="checkbox" value="1" v-model="remember" id="user_remember_me" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sm">
                    <label class="primary-label"></label>
                  </div>
                  <div class="w-100">
                    <a class="forgot-password" href="/forgotpassword">{{
                      $t('forgot-password')
                    }}</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sm">
                    <label class="primary-label"></label>
                  </div>
                  <div class="w-100">
                    <router-link class="forgot-password" :to="{ path: `/login-sso` }">{{
                      $t('login-via-sso')
                    }}</router-link>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sm">
                    <label class="primary-label"></label>
                  </div>
                  <div class="w-100">
                    <router-link class="forgot-password" :to="{ path: `/signup` }">{{
                      $t('create-new-account')
                    }}</router-link>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sm">
                    <label class="primary-label"></label>
                  </div>
                  <div class="w-100">
                    <button class="sf-primary" @click="makeLogin">
                      <div class="text" v-if="!isLoading">
                        {{ $t('login') }}
                      </div>
                      <div v-else class="spinner-border" role="status">
                        <span class="sr-only">{{ $t('loading') }}</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="buttons">
        <button class="btn-login" @click="makeLogin">
          <div class="text" v-if="!isLoading">
            Login
          </div>
          <div v-else class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
      </div> -->
        </div>
      </div>
      <div class="main vertify-code" v-if="step === 2">
        <div class="panel-login">
          <div class="logo">
            <img :src="logo" alt="logo" />
          </div>
          <h2 class="label-code">{{ $t('enter-your-code') }}</h2>
          <div class="login-form">
            <div class="form-group">
              <vue-auth-code-input
                @inputChange="handleChangeCode"
                @inputComplete="makeLoginByCode"
              />
            </div>
          </div>

          <div class="buttons">
            <button class="btn-login" @click="makeLoginByCode">
              <div class="text" v-if="!isLoading">
                {{ $t('enter') }}
              </div>
              <div v-else class="spinner-border" role="status">
                <span class="sr-only">{{ $t('loading') }}...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as authService from '../services/auth.service'
import VueAuthCodeInput from 'vue-auth-code-input'
import logo from '@/assets/images/logo.svg'
import { DOMAIN_TITLE } from '../.env'
import Vue from 'vue'

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      code: '',
      logo,
      remember: false,
      isLoading: false,
      step: 1,
      tokens: ''
    }
  },

  components: {
    VueAuthCodeInput
  },

  methods: {
    makeLogin() {
      this.isLoading = true
      authService
        .makeLogin({
          email: this.email,
          password: this.password,
          remember: this.remember
        })
        .then((response) => {
          this.isLoading = false
          if (response.data.data.is_2fa) {
            this.step++
            this.tokens = response.data.data.auth_token
          } else {
            this.error = ''
            document.title = DOMAIN_TITLE
            if (response && response.data.data.user.new_term) {
              this.$router.push('/term-user')
            } else if (response && !response.data.data.user.new_term) {
              const path = Vue.$cookies.get('historyPath') ? Vue.$cookies.get('historyPath') : '/'
              this.$router.push(path)
              Vue.$cookies.remove('historyPath')
            }
          }
        })
        .catch((error) => {
          this.$toast.error(
            error.status === 404 ? 'User with same email not found' : error.response.data.message
          )
          this.isLoading = false
        })
    },
    makeLoginByCode() {
      this.isLoading = true
      authService
        .makeLoginByCode({
          code: this.code,
          tokens: this.tokens,
          remember: this.remember
        })
        .then((response) => {
          this.isLoading = false
          this.error = ''
          document.title = DOMAIN_TITLE
          if (response && response.data.data.user.new_term) {
            this.$router.push('/term-user')
          } else if (response && !response.data.data.user.new_term) {
            const path = Vue.$cookies.get('historyPath') ? Vue.$cookies.get('historyPath') : '/'
            this.$router.push(path)
            Vue.$cookies.remove('historyPath')
          }
        })
        .catch((error) => {
          this.$toast.error(
            error.status === 404 ? 'Code has expired or incorrect' : error.response.data.message
          )
          this.isLoading = false
        })
    },
    handleChangeCode(code, index) {
      this.code = code.toString().replace(/,/g, '')
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../assets/images/app-background.jpg') no-repeat #2d494d;
  background-size: cover;
  background-position: center center;
  .main {
    background: #fff;
    width: 100%;
    position: relative;
    max-width: 610px;
    height: 488px;
    border-radius: 2px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12);
    &.vertify-code {
      height: 300px;
    }
    .panel-background {
      width: 258px;
      float: left;
      height: 100%;
      text-align: center;
      color: #ffffff;
      font-size: 14px;
      background: url('../assets/images/background-login.jpeg') no-repeat #201244;
      background-size: cover;
      background-position: center center;
      .login-title {
        margin-top: 51px;
        font-size: 36px;
        font-weight: 300;
        color: #ffffff;
      }
      .login-desc {
        color: #ffffff;
      }
    }
    .panel-login {
      width: 352px;
      height: 100%;
      float: left;
      background-color: #ffffff;
      padding: 0 30px;
      .label-code {
        text-align: center;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 20px;
      }
      .logo {
        width: 100%;
        text-align: center;
        margin: 40px 0 60px;
        img {
          width: 230px;
        }
      }
      .form-group {
        label {
          font-size: 14px;
          color: #4a4a4a;
          font-weight: 600;
        }
      }
      .remember {
        input[type='checkbox'] {
          width: 30px;
          position: absolute;
          height: 30px;
          opacity: 0;
          z-index: 4;
          top: -5px;
          cursor: pointer;
        }
        input[type='checkbox'] + label {
          padding-left: 31px;
          line-height: 30px;
          background-repeat: no-repeat;
          cursor: pointer;
          background-position: left center;
          font-size: 14px;
          font-weight: normal;
          color: #4a4a4a;
          display: flex;
          align-items: center;
          &.checkbox-group {
            background-image: url('../assets/images/radio.svg');
          }
        }
        input[type='checkbox']:checked + label {
          &.checkbox-group {
            background-image: url('../assets/images/radio-active.svg');
          }
        }
        .forgot-password {
          height: 30px;
          display: flex;
          align-items: center;
          font-size: 14px;
        }
      }

      .remember {
        input[type='checkbox'] {
          width: 30px;
          position: absolute;
          height: 30px;
          opacity: 0;
          z-index: 4;
          top: -5px;
          cursor: pointer;
        }
        input[type='checkbox'] + label {
          padding-left: 31px;
          line-height: 30px;
          background-repeat: no-repeat;
          cursor: pointer;
          background-position: left center;
          font-size: 14px;
          font-weight: normal;
          color: #4a4a4a;
          display: flex;
          align-items: center;
          &.checkbox-group {
            background-image: url('../assets/images/radio.svg');
          }
        }
        input[type='checkbox']:checked + label {
          &.checkbox-group {
            background-image: url('../assets/images/radio-active.svg');
          }
        }
        .forgot-password {
          height: 30px;
          display: flex;
          align-items: center;
          font-size: 14px;
        }
      }
    }
    .header {
      text-align: center;
    }

    .buttons {
      display: flex;
      justify-content: center;
      .btn-login {
        width: 150px;
        height: 40px;
        border-radius: 20px;
        box-shadow: 0 2px 4px 0 rgba(159, 214, 100, 0.6);
        background-color: #76c322;
        border: none;
        font-size: 14px;
        color: #fff;
      }
    }

    .error {
      background-color: red;
      padding: 10px;
      font-size: 12px;
      opacity: 1;
      transition: all 0.5s;
    }
  }
}
</style>
