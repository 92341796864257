<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <div class="sf-card">
          <div class="card-heading">
            <p>Org Type</p>
          </div>
          <div class="card-body editable">
            <div class="row">
              <div class="col-12">
                <button class="button-edit-pen" @click="shownModalEdit()"></button>
                <div class="mt-3">
                  <p class="d-flex">
                    <span class="sf-text-strong mr-2">Name</span>
                    <content-placeholders
                      :class="'placeholder-text-detail'"
                      v-if="loadingOrgTypeDetail"
                    >
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <span v-else>{{ displayOrgType.org_type.name }}</span>
                  </p>
                </div>

                <div class="mt-3">
                  <p class="d-flex">
                    <span class="sf-text-strong mr-2">Price</span>
                    <content-placeholders
                      :class="'placeholder-text-detail'"
                      v-if="loadingOrgTypeDetail"
                    >
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <span v-else>{{ displayOrgType.org_type.price }}</span>
                  </p>
                </div>

                <div class="mt-3">
                  <p class="d-flex">
                    <span class="sf-text-strong mr-2">Description</span>
                    <content-placeholders
                      :class="'placeholder-text-detail'"
                      v-if="loadingOrgTypeDetail"
                    >
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <span v-else>{{ displayOrgType.org_type.display_description }}</span>
                  </p>
                </div>

                <div class="mt-3">
                  <p class="d-flex">
                    <span class="sf-text-strong mr-2">Max users</span>
                    <content-placeholders
                      :class="'placeholder-text-detail'"
                      v-if="loadingOrgTypeDetail"
                    >
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <span v-else>{{ displayOrgType.org_type.max_users }}</span>
                  </p>
                </div>

                <div class="mt-3">
                  <p class="d-flex">
                    <span class="sf-text-strong mr-2">Max data</span>
                    <content-placeholders
                      :class="'placeholder-text-detail'"
                      v-if="loadingOrgTypeDetail"
                    >
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <span v-else>{{ displayOrgType.org_type.max_data }}</span>
                  </p>
                </div>

                <div class="mt-3">
                  <p class="d-flex">
                    <span class="sf-text-strong mr-2">Max Players</span>
                    <content-placeholders
                      :class="'placeholder-text-detail'"
                      v-if="loadingOrgTypeDetail"
                    >
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <span v-else>{{ displayOrgType.org_type.max_players }}</span>
                  </p>
                </div>

                <div class="mt-3">
                  <p class="d-flex">
                    <span class="sf-text-strong mr-2">Max Loops</span>
                    <content-placeholders
                      :class="'placeholder-text-detail'"
                      v-if="loadingOrgTypeDetail"
                    >
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <span v-else>{{ displayOrgType.org_type.max_loops }}</span>
                  </p>
                </div>

                <div class="mt-3">
                  <p class="d-flex">
                    <span class="sf-text-strong mr-2">Max Campaigns</span>
                    <content-placeholders
                      :class="'placeholder-text-detail'"
                      v-if="loadingOrgTypeDetail"
                    >
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <span v-else>{{ displayOrgType.org_type.max_campaigns }}</span>
                  </p>
                </div>

                <div class="mt-3">
                  <p class="d-flex">
                    <span class="sf-text-strong mr-2">Max Campaigns</span>
                    <content-placeholders
                      :class="'placeholder-text-detail'"
                      v-if="loadingOrgTypeDetail"
                    >
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <span v-else>{{ displayOrgType.org_type.max_campaigns }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SfModal title="Edit Org Type" :width="768" closeOnOverlay :show.sync="isShownModalEdit">
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingOrgTypeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Org Type Name</label>
                </div>
                <input
                  v-model="currentOrgType.org_type.name"
                  placeholder="Org Type Name..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.name.required"
              >
                Name required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.name.maxLength"
              >
                Use less than 10 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingOrgTypeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Org Type Price</label>
                </div>
                <input
                  :type="'number'"
                  v-model="currentOrgType.org_type.price"
                  placeholder="Org Type Price..."
                  class="sf-input-text"
                  :step="'0.01'"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.price.required"
              >
                Price required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.price.maxLength"
              >
                Use less than 10 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingOrgTypeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Description</label>
                </div>
                <textarea
                  v-model="currentOrgType.org_type.display_description"
                  placeholder="Org Type Description..."
                  class="sf-input-text"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingOrgTypeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Max Users</label>
                </div>
                <input
                  :type="'number'"
                  v-model="currentOrgType.org_type.max_users"
                  placeholder="Max Users..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_users.required"
              >
                Max Users required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_users.maxLength"
              >
                Use less than 10 characters
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_users.minValue"
              >
                Max Users must be more than 0
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingOrgTypeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Max Data</label>
                </div>
                <input
                  :type="'number'"
                  v-model="currentOrgType.org_type.max_data"
                  placeholder="Max Data..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_data.required"
              >
                Max Data required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_data.maxLength"
              >
                Use less than 10 characters
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_data.minValue"
              >
                Max Data must be more than 0
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingOrgTypeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Max Media</label>
                </div>
                <input
                  :type="'number'"
                  v-model="currentOrgType.org_type.max_media"
                  placeholder="Max Media..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_media.required"
              >
                Max Media required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_media.maxLength"
              >
                Use less than 10 characters
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_media.minValue"
              >
                Max Media must be more than 0
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingOrgTypeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Max Loops</label>
                </div>
                <input
                  :type="'number'"
                  v-model="currentOrgType.org_type.max_loops"
                  placeholder="Max Loops..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_loops.required"
              >
                Max Loops required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_loops.maxLength"
              >
                Use less than 10 characters
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_loops.minValue"
              >
                Max Loops must be more than 0
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingOrgTypeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Max Players</label>
                </div>
                <input
                  :type="'number'"
                  v-model="currentOrgType.org_type.max_players"
                  placeholder="Max Players..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_players.required"
              >
                Max Players required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_players.maxLength"
              >
                Use less than 10 characters
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentOrgType.org_type.max_players.minValue"
              >
                Players must be more than 0
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalEdit = false">Cancel</button>
        <button class="sf-primary" @click="updateOrgType()">Save</button>
      </div>
    </SfModal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import AdminCreateModal from '@/components/admin/AdminCreateModal.vue'
import SfModal from '@/components/SfModal.vue'
// import AdminDeleteModal from '@/components/admin/AdminDeleteModal.vue'
// import Select2 from 'v-select2-component'
import AdminOrgTypeService from '@/services/admin-org-type.service'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'admin-user-customer',

  components: {
    // AdminCreateModal,
    SfModal
    // Select2,
    // AdminDeleteModal
  },

  data() {
    return {
      msg: 'Welcome to Index!!!',
      isShownModalEdit: false,
      currentPage: 1,
      currentOrgTypeID: this.$route.params.id,
      submitted: false,
      displayOrgType: {
        org_type: {
          name: '',
          price: '',
          display_description: '',
          max_users: 0,
          max_media: 0,
          max_data: 0,
          max_players: 0,
          max_loops: 0,
          max_campaigns: 0
        }
      },
      currentOrgType: {
        org_type: {
          name: '',
          price: '',
          display_description: '',
          max_users: 0,
          max_media: 0,
          max_data: 0,
          max_players: 0,
          max_loops: 0,
          max_campaigns: 0
        }
      },
      loadingOrgTypeDetail: true
    }
  },

  validations: {
    currentOrgType: {
      org_type: {
        name: {
          required,
          maxLength: maxLength(250)
        },
        price: {
          required,
          maxLength: maxLength(10)
        },
        max_users: {
          required,
          maxLength: maxLength(10)
        },
        max_media: {
          required,
          maxLength: maxLength(10)
        },
        max_data: {
          required,
          maxLength: maxLength(10)
        },
        max_players: {
          required,
          maxLength: maxLength(10)
        },
        max_loops: {
          required,
          maxLength: maxLength(10)
        },
        max_campaigns: {
          required,
          maxLength: maxLength(10)
        }
      }
    }
  },

  methods: {
    shownModalEdit() {
      this.submitted = false
      this.isShownModalEdit = true
    },
    getItemByID() {
      this.loadingOrgTypeDetail = true
      AdminOrgTypeService.detailOrgType(this.currentOrgTypeID)
        .then((response) => {
          this.setDataOrgType(this.currentOrgType.org_type, response.data)
          this.setDataOrgType(this.displayOrgType.org_type, response.data)
          this.loadingOrgTypeDetail = false
        })
        .catch((err) => {
          this.isShownModalEdit = false
          this.$toast.error(err.message)
        })
    },
    updateOrgType() {
      this.submitted = true
      if (this.$v.currentOrgType.$invalid) {
        return
      }
      AdminOrgTypeService.updateOrgType(this.currentOrgTypeID, this.currentOrgType)
        .then((response) => {
          this.$toast.success('Organization Type successfully updated')
          this.setDataOrgType(this.displayOrgType.org_type, response.data)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalEdit = false
    },
    setDataOrgType(org_type, new_org_type) {
      org_type.name = new_org_type.name
      org_type.price = new_org_type.price
      org_type.display_description = new_org_type.display_description
      org_type.max_users = new_org_type.max_users
      org_type.max_media = new_org_type.max_media
      org_type.max_data = new_org_type.max_data
      org_type.max_players = new_org_type.max_players
      org_type.max_loops = new_org_type.max_loops
      org_type.max_campaigns = new_org_type.max_campaigns
    }
  },

  computed: {
    ...mapState('adminorgtype', {
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('adminorgtype', ['isEmpty'])
  },

  mounted() {
    this.getItemByID()
  }
}
</script>

<style lang="scss" scoped>
@import '../../Adminpage.scss';
.text-area-control {
  padding: 6px 0px;
  width: 90%;
  border: none;
  outline: none;
  height: 100px;
  max-height: 150px;
  margin: 1px;
}

.custom-input-group-text {
  align-items: flex-start;
}

.input-group-text-area {
  height: auto !important;
}
</style>
