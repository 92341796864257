import { sortNameFolder } from '../../../helper/helper'
import Vue from 'vue'
import i18n from '../../../i18n'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
export default {
  // LOOP PAGE
  SET_ITEMS(state, data) {
    if (data) {
      state.items = data.items || state.items
      state.pagination.page = data.current_page || state.pagination.page
      state.pagination.total = data.total_pages
    } else {
      state.items = []
    }
  },

  SET_DEFAULT_BREADCRUMB(state) {
    state.breadcrumb = [
      {
        name: i18n.t('loops'),
        url: '/apisite/v1/loop_assets',
        type: 'main'
      }
    ]
  },

  SET_TREE_FOLDER(state, data) {
    state.treeFolder = formatterTreeFolder(data)
    state.treeFolder = sortNameFolder(state.treeFolder)
    state.treeFolderBefore = cloneDeep(state.treeFolder)
  },
  SET_TREE_FOLDER_BY_ID(state, payload) {
    const { id, data } = payload
    const treeFolder = cloneDeep(state.treeFolder)
    const folder = findById(treeFolder, id)
    folder.children = data
    state.treeFolder = [...treeFolder]
  },

  SET_ITEMS_DROP_BY_ID(state, id) {
    state.items = state.items.filter((item) => item.id !== id)
  },

  SET_ITEMS_IN_FOlDER(state, data) {
    state.items = data.items
    state.pagination.page = data.current_page
    state.pagination.total = data.total_pages
    data.breadcrumb[0].type = 'main'
    for (let i = 1; i < data.breadcrumb.length; i++) {
      data.breadcrumb[i].type = 'folder'
      const url = data.breadcrumb[i].url
      const urlSplit = url.split('/')
      data.breadcrumb[i].id = urlSplit[urlSplit.length - 2]
    }
    state.breadcrumb = data.breadcrumb
  },

  SET_TREE_FOLDER_CHANGED(state, data) {
    state.treeFolderBefore = [...state.treeFolder]
    state.treeFolder = data
  },

  SET_TREE_FOLDER_ID(state, payload) {
    const id = payload.id
    const treeFolder = payload.items
    const loopPageTreeFolder = state.treeFolder
    const temp = findById(loopPageTreeFolder, id)
    temp.data.isGetData = true
    temp.isSelected = true
    const newValue = [...formatterTreeFolder(treeFolder)]
    const valueTemp = {}
    newValue.forEach((element) => {
      valueTemp[`${element.data.file_type}-${element.data.id}`] = element
    })
    temp.children = Object.keys(valueTemp).map((item) => valueTemp[item])

    state.treeFolder = [...loopPageTreeFolder]
    state.treeFolderBefore = [...loopPageTreeFolder]
  },

  SET_FOLDER_NAME(state, data) {
    const foundIndex = state.items.findIndex((x) => x.id === data.id)
    state.items[foundIndex].name = data.name
  },

  SET_NEW_LOOP(state, data) {
    state.items = [{ ...data, name: data.displayname, type: 'loop_asset' }, ...state.items]
  },
  ADD_NEW_FOLDER(state, data) {
    state.items = [{ ...data, type: 'folder' }, ...state.items]
  },
  SET_LOOP_ASSET(state, data) {
    const items = Vue._.cloneDeep(state.items)
    const foundIndex = items.findIndex((x) => x.id === data.id)
    items[foundIndex].name = data.name
    items[foundIndex].orientation = data.orientation
    state.items = items
  },
  // ===========================================================================
  SET_ERROR(state, data) {
    state.error = data
  },

  SET_LOADING(state, data) {
    state.loading = data
  },
  SET_ITEMS_IN_FOlDER_AFTER_REMOVE(state, data) {
    state.items = [...data.items, ...state.items]
  },
  SET_UPDATE_ITEM(state, data) {
    const foundIndex = state.items.findIndex((x) =>
      x.folder ? x.folder.id === data.id : x.id === data.id
    )

    const foundIndexBreadCrumb = state.breadcrumb.findIndex((x) => parseInt(x.id) === data.id)
    if (foundIndexBreadCrumb > 0) {
      state.breadcrumb[foundIndexBreadCrumb].name = data.name
    }
    if (foundIndex >= 0) {
      state.items[foundIndex].name = data.name
    }
  },

  SET_UPDATE_ITEM_WITH_FOLDER(state, data) {
    const foundIndex = state.items.findIndex((x) =>
      x.folder ? x.folder.id === data.id : x.id === data.id
    )
    const foundIndexBreadCrumb = state.breadcrumb.findIndex((x) => parseInt(x.id) === data.id)
    if (foundIndexBreadCrumb > 0) {
      state.breadcrumb[foundIndexBreadCrumb].name = data.name
    }
    if (foundIndex >= 0) {
      state.items[foundIndex].name = data.name
    }
  },

  SET_NEW_FOLDER_TREE_FOLDER(state, data) {
    let loopPageTreeFolder = state.treeFolder
    if (data.folderData.id) {
      const parent = findById(loopPageTreeFolder, data.folderData.id)
      parent.data.has_children = true
      parent.children = [
        {
          title: data.data.name,
          isLeaf: false,
          data: {
            ...data.data,
            isGetData: false,
            isNew: true,
            source_id: data.data.id
          },
          children: []
        },
        ...parent.children
      ]
      parent.children = sortNameFolder(parent.children)
    } else {
      loopPageTreeFolder = [
        {
          title: data.data.name,
          isLeaf: false,
          data: {
            ...data.data,
            isGetData: false,
            isNew: true,
            source_id: data.data.id
          },
          children: []
        },
        ...loopPageTreeFolder
      ]
    }
    loopPageTreeFolder = sortNameFolder(loopPageTreeFolder)
    state.treeFolder = [...loopPageTreeFolder]
  },
  SET_NEW_FOLDER_ITEMS(state, data) {
    state.items = [
      {
        ...data,
        name: data.name,
        type: 'folder'
      },
      ...state.items
    ]
  },
  ADD_NEW_LOOP_ITEM(state, data) {
    state.items = [
      {
        ...data,
        name: data.displayname,
        type: 'loop_asset'
      },
      ...state.items
    ]
  },
  REMOVE_FOLDER_ITEMS(state, data) {
    // remove on list view
    const foundIndexItems = state.items.findIndex((x) =>
      x.folder ? x.folder.id === data.nodeCurrent.id : x.id === data.nodeCurrent.id
    )
    state.items.splice(foundIndexItems, 1)
    // remove in tree folder
    const loopPageTreeFolder = state.treeFolder
    if (data.nodeParent) {
      const parent = findById(loopPageTreeFolder, data.nodeParent.id)

      if (parent.children.length > 0) {
        const indexChild = parent.children.findIndex((x) => x.data.id === data.nodeCurrent.id)
        parent.children.splice(indexChild, 1)
      }
      if (parent.children.length === 0) {
        parent.data.has_children = false
        state.treeFolder = [...loopPageTreeFolder]
        const nodeDrag = window.slVueTree.getNodeById(parent.data.id)
        window.slVueTree.select([...nodeDrag.path])
      }
    } else {
      const indexnodeCurrent = state.treeFolder.findIndex((x) => x.data.id === data.nodeCurrent.id)
      state.treeFolder.splice(indexnodeCurrent, 1)
    }
  },
  SET_UPDATE_FOLDER(state, data) {
    const loopPageTreeFolder = Vue._.cloneDeep(state.treeFolder)
    const nodeCurrent = findById(loopPageTreeFolder, data.id)
    if (Object.keys(nodeCurrent).length) {
      nodeCurrent.title = data.name
      nodeCurrent.data.name = data.name
      state.treeFolder = [...loopPageTreeFolder]
    }
  },
  SET_OPEN_FOLDER_HAS_CHILDREN(state, data) {
    const loopPageTreeFolder = state.treeFolder
    const parent = findById(loopPageTreeFolder, data.parent)
    if (data.type === 'item') {
      parent.isExpanded = true
      parent.data.has_children = true
    }
    if (parent.children.length > 0) {
      parent.data.has_children = true
    }
    state.treeFolder = [...loopPageTreeFolder]
  },
  SET_NEW_ITEM_TREE_FOLDER(state, data) {
    const libraryPageTreeFolder = state.treeFolder
    if (data.folderData && data.data) {
      const parent = findById(libraryPageTreeFolder, data.folderData)
      if (parent.data.folder_type !== 'organizations') {
        parent.data.has_children = true
        parent.children = [
          {
            title: data.data.displayname,
            isLeaf: true,
            data: {
              file_type: 'loop',
              folder_type: 'loops',
              name: data.data.displayname,
              ...data.data
            }
          },
          ...parent.children
        ]
        parent.children = sortNameFolder(parent.children)
      }
    }
    state.treeFolder = [...libraryPageTreeFolder]
  },
  REMOVE_ITEM_ITEMS(state, data) {
    // remove on list view
    const foundIndexItems = state.items.findIndex((x) => x.id === data.item.id)
    state.items.splice(foundIndexItems, 1)
    // remove in tree folder
    const libraryPageTreeFolder = state.treeFolder
    if (data.nodeParent.id) {
      const parent = findById(libraryPageTreeFolder, data.nodeParent.id)
      if (parent.data.folder_type !== 'organizations') {
        if (parent.children.length > 0) {
          const indexChild = parent.children.findIndex((x) => x.data.id === data.item.folder.id)
          parent.children.splice(indexChild, 1)
        }
      }
    }
  },

  SORT_NAME_FOLDER(state, data) {
    if (data.data.parent_id) {
      const loopPageTreeFolder = state.treeFolder
      const current = findById(loopPageTreeFolder, data.data.id)
      const parent = findById(state.treeFolder, current.data.parent_id)
      parent.children = sortNameFolder(parent.children)
    }
    state.treeFolder = sortNameFolder(state.treeFolder)
  },

  CREATE_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.items)
    let treeFolder = Vue._.cloneDeep(state.treeFolder)
    const item = data.data
    const folderId = get(item, 'folder.id', item.id)

    let parentCurrent = ''
    let root = false
    const parentId = item.parent_id
      ? item.parent_id
      : item.type === 'folder'
      ? null
      : item.folder.parent_id
    if (parentId) {
      parentCurrent = findById(treeFolder, parentId)
    } else {
      if (item.root_id) {
        parentCurrent = findById(treeFolder, item.root_id)
        root = true
      } else {
        if (item.organization_id !== data.currentOrganization) {
          parentCurrent = findById(treeFolder, item.organization_id)
        } else {
          root = true
        }
      }
    }
    if (root) {
      if (item.type === 'folder') {
        treeFolder = sortNameFolder([formatTypeFolder(item), ...treeFolder])
      }
    } else {
      if (!isEmpty(parentCurrent)) {
        parentCurrent.data.has_children = true
        if (item.type === 'folder') {
          parentCurrent.children = sortNameFolder([
            formatTypeFolder(item),
            ...parentCurrent.children
          ])
        } else {
          const newItem = formatTypeItem(item)
          set(newItem, 'data.id', folderId)
          set(newItem, 'data.parent_id', get(newItem, 'data.folder.parent_id', null))
          parentCurrent.children = sortNameFolder([newItem, ...parentCurrent.children])
        }
      }
    }
    const activeFolderId = get(state.breadcrumb, `[${state.breadcrumb.length - 1}].id`, null)
    if ((!parentId && !activeFolderId) || (parentId && activeFolderId === parentId.toString())) {
      items.unshift(item)
    }

    state.items = items
    state.treeFolder = sortNameFolder(treeFolder)
    state.treeFolderBefore = sortNameFolder(treeFolder)
  },

  DELETE_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.items)
    let treeFolder = Vue._.cloneDeep(state.treeFolder)

    const item = data.data
    const folder = item.folder ? item.folder : item
    const foundIndexItems = items.findIndex((x) => x.id === item.id)
    if (foundIndexItems >= 0) {
      items.splice(foundIndexItems, 1)
    }
    let rootList = []
    let parentCurrent = ''

    if (folder.parent_id) {
      // delete subfolder
      parentCurrent = findById(treeFolder, folder.parent_id)
      rootList = parentCurrent.children
      if (item.type === 'folder') {
        const currentFolder = findById(treeFolder, item.id)
        const children = currentFolder ? currentFolder.children : null
        // show sub folders in tree folder when delete parent folder that has children
        if (children && children.length) {
          const subFolders = children.filter((p) => p.data.file_type === 'folder')
          subFolders.forEach((p) => {
            const folder = cloneDeep(p)
            folder.data.parent_id = null
            treeFolder.unshift(folder)
          })
        }
      }
    } else {
      if (item.type === 'folder') {
        // delete folder root
        const currentFolder = treeFolder.find((x) => x.data.id === item.id)
        const children = currentFolder ? currentFolder.children : null
        const indexChild = treeFolder.findIndex((x) => x.data.id === item.id)
        treeFolder.splice(indexChild, 1)
        // show sub folders in tree folder when delete root folder that has children
        if (children && children.length) {
          const subFolders = children.filter((p) => p.data.file_type === 'folder')
          subFolders.forEach((p) => {
            const folder = cloneDeep(p)
            folder.data.parent_id = null
            treeFolder.unshift(folder)
          })
        }
      }
      if (folder.root_id) {
        parentCurrent = findById(treeFolder, folder.root_id)
        if (!isEmpty(parentCurrent)) {
          treeFolder = treeFolder.filter((p) => data.data.id !== p.data.id)
          rootList = !isEmpty(parentCurrent) ? parentCurrent.children : treeFolder
        }
      } else {
        rootList = treeFolder
      }
    }

    if (rootList && rootList.length > 0) {
      const indexChild = rootList.findIndex((x) => x.data.id === folder.id)
      if (indexChild >= 0) {
        rootList.splice(indexChild, 1)
        if (rootList.length === 0 && parentCurrent) {
          parentCurrent.data.has_children = false
        }
      }
    }
    state.items = items
    state.treeFolder = sortNameFolder(treeFolder)
    state.treeFolderBefore = sortNameFolder(treeFolder)
  },
  UPDATE_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.items)
    let treeFolder = Vue._.cloneDeep(state.treeFolder)
    const treeFolderBefore = cloneDeep(state.treeFolderBefore)
    const item = data.data
    const parentId = item.parent_id ? item.parent_id : get(item, 'folder.parent_id', null)
    const folderId = get(item, 'folder.id', item.id)
    // update item
    const nodeCurrentInTreeFolderBefore = cloneDeep(
      findById(treeFolderBefore, item.type === 'folder' ? item.id : item.folder.id)
    )
    const foundIndexItems = items.findIndex((x) => x.id === item.id)
    if (foundIndexItems >= 0) {
      const previousItem = items[foundIndexItems]
      items[foundIndexItems] = item
      const oldParentId = previousItem.folder
        ? previousItem.folder.parent_id
        : previousItem.parent_id
      const IS_DIFFERENT_PARENT_FOLDER = parentId !== oldParentId
      if (IS_DIFFERENT_PARENT_FOLDER) items.splice(foundIndexItems, 1)
    } else {
      // build item if list not include item
      if (
        parentId &&
        parentId.toString() === get(state.breadcrumb, `[${state.breadcrumb.length - 1}].id`, null)
      ) {
        const newItem = cloneDeep(item)
        set(
          newItem,
          'shared_folder',
          get(findById(treeFolder, parentId), 'data.shared_folder', undefined)
        )
        items.unshift(newItem)
      }
    }
    // update folder
    const nodeCurrent = findById(treeFolder, folderId)
    if (Object.keys(nodeCurrent).length) {
      nodeCurrent.title = item.name
      nodeCurrent.data.name = item.name
      nodeCurrent.data.parent_id = parentId
      const foundIndexBreadCrumb = state.breadcrumb.findIndex((x) => parseInt(x.id) === item.id)
      if (foundIndexBreadCrumb > 0) {
        state.breadcrumb[foundIndexBreadCrumb].name = item.name
      }
    }
    if (parentId) {
      if (!isEmpty(nodeCurrentInTreeFolderBefore)) {
        if (item.type === 'folder') {
          treeFolder = treeFolder.filter((p) => p.data.id !== item.id)
        } else {
          const oldParent = findById(treeFolder, nodeCurrentInTreeFolderBefore.data.parent_id)
          if (!isEmpty(oldParent) && oldParent.children && oldParent.children.length > 0) {
            const oldPlace = oldParent.children.findIndex((x) => x.data.id === folderId)
            if (oldPlace >= 0) {
              oldParent.children.splice(oldPlace, 1)
              if (oldParent.children.length === 0) {
                oldParent.isExpanded = false
                oldParent.data.has_children = false
              }
            }
          }
        }
      }
      const newParent = findById(treeFolder, parentId)
      if (newParent && newParent.children) {
        const isExisted = newParent.children.find(
          (p) => p.data.id === get(data, 'data.folder.id', data.data.id)
        )
        if (isEmpty(isExisted)) {
          if (item.type === 'folder') {
            const oldFolder = findById(treeFolderBefore, item.id)
            newParent.children = sortNameFolder([
              formatTypeFolder({
                ...item,
                has_children: get(oldFolder, 'data.has_children', false)
              }),
              ...newParent.children
            ])
          } else {
            const newItem = formatTypeItem(item)
            set(newItem, 'data.id', folderId)
            set(newItem, 'data.parent_id', get(newItem, 'data.folder.parent_id', null))
            newParent.children = sortNameFolder([newItem, ...newParent.children])
          }
        } else {
          newParent.children = sortNameFolder([...newParent.children])
        }
        // move to new parent
        newParent.data.has_children = true
        // remove redundant item
        newParent.children = newParent.children.filter(
          (p) =>
            !get(p, 'data.folder.id', null) || get(p, 'data.folder.id', null) === get(p, 'data.id')
        )
      }
    } else if (!parentId && item.type === 'folder') {
      const oldFolder = findById(treeFolderBefore, item.id)
      const folderHasChildren = get(oldFolder, 'data.has_children', false)
      if (folderHasChildren) {
        const currentFolder = findById(treeFolder, item.id)
        set(currentFolder, 'data.has_children', true)
      }
      // add root folder
      if (treeFolder.findIndex((p) => p.data.id === item.id) === -1) {
        const folder = findById(treeFolder, item.id)
        treeFolder.push({ ...folder, has_children: folderHasChildren })
      }
      // remove root folder in old parent
      const folderOnTreeFolderBefore = findById(treeFolderBefore, item.id)
      if (folderOnTreeFolderBefore && folderOnTreeFolderBefore.data.parent_id) {
        const oldFolderOnTreeFolderBefore = findById(
          treeFolder,
          folderOnTreeFolderBefore.data.parent_id
        )
        oldFolderOnTreeFolderBefore.children = oldFolderOnTreeFolderBefore.children.filter(
          (p) => p.data.id !== item.id
        )
      }
    }

    const oldParentId = get(nodeCurrentInTreeFolderBefore, 'data.parent_id', null)
      ? get(nodeCurrentInTreeFolderBefore, 'data.parent_id', null)
      : get(nodeCurrent, 'data.parent_id', null)

    if (oldParentId) {
      const oldParent = findById(treeFolder, oldParentId)
      if (!isEmpty(oldParent) && oldParent.children && !oldParent.children.length)
        oldParent.data.has_children = false
    }
    state.items = items
    treeFolder = treeFolder.length ? treeFolder.filter((p) => !get(p, 'isLeaf', null)) : []
    state.treeFolder = sortNameFolder(treeFolder)
    state.treeFolderBefore = sortNameFolder(treeFolder)
  },

  DRAG_FROM_SOCKET(state, data) {
    const items = Vue._.cloneDeep(state.items)
    const treeFolder = Vue._.cloneDeep(state.treeFolder)

    const foundIndexItems = items.findIndex((x) => {
      return x.id === data.data.id
    })
    if (foundIndexItems !== -1) {
      items.splice(foundIndexItems, 1)
    }

    if (data.currentFolderID && data.currentFolderID === data.data.folder.parent_id) {
      const parent = findById(treeFolder, data.currentFolderID)
      if (parent.children) {
        const index = parent.children.findIndex((p) => p.data.id === data.data.id)
        if (index !== -1) parent.children.splice(index, 1)
      }
      parent.children = sortBy(
        [
          {
            title: data.data.displayname,
            isLeaf: true,
            data: {
              file_type: data.data.type,
              name: data.data.displayname,
              ...data.data
            }
          },
          ...parent.children
        ],
        [(e) => e.data.name.toLowerCase()]
      )
      // remove at old parent
      if (
        data.oldFolderID &&
        data.oldFolderID.toString() ===
          get(state.breadcrumb, `[${state.breadcrumb.length - 1}].id`, null)
      ) {
        const oldParent = findById(treeFolder, data.oldFolderID)
        if (oldParent.children) {
          const index = oldParent.children.findIndex((p) => p.data.id === data.data.folder.id)
          if (index !== -1) oldParent.children.splice(index, 1)
        }
      }
    }

    state.items = items
    state.treeFolder = sortNameFolder(treeFolder)
  },

  SET_EDITABLE_LOOP_IDS(state, data) {
    if (!state.editLoopIds.some((item) => item.resourceable_id === data.resourceable_id)) {
      state.editLoopIds.push(data)
    }
  },
  LOAD_EDITABLE_LOOP(state, data) {
    state.editLoopIds = data
  },
  RESET_EDITABLE_LOOP_IDS(state, data) {
    state.editLoopIds = state.editLoopIds.filter((item) => {
      return !(item.resourceable_id === data.resourceable_id && item.user_id === data.user_id)
    })
  }
}

function formatTypeFolder(node) {
  const source_id = node.folder_type === 'organizations' ? node.source_id : node.id
  return {
    title: node.name,
    isExpanded: false,
    data: {
      ...node,
      isGetData: false,
      source_id: source_id
    },
    children: []
  }
}

function formatTypeItem(node) {
  return {
    title: node.displayname || node.name,
    isLeaf: true,
    data: {
      file_type: node.type,
      name: node.displayname,
      ...node,
      id: get(node, 'folder.id', node.id),
      parent_id: get(node, 'folder.parent_id', null)
    }
  }
}

// COMON
// formatter tree folder by sl vue tree
function formatterTreeFolder(preData) {
  return preData.map((item) => {
    if (item.file_type === 'folder') {
      return {
        title: item.name,
        isExpanded: false,
        data: {
          ...item,
          isGetData: false,
          source_id: item.id
        },
        children: []
      }
    }
    return {
      title: item.name,
      isLeaf: true,
      data: {
        ...item
      }
    }
  })
}

function findById(nodes, id) {
  let result = {}
  for (const child of nodes) {
    if (Object.keys(result).length) break
    const nodeTemp = recurseFind(child, id)
    result = Object.keys(nodeTemp).length ? nodeTemp : result
  }
  return result
}

function recurseFind(node, id) {
  if (node.data.file_type !== 'folder') {
    if (node.data.id === id) {
      return node
    } else {
      return {}
    }
  }

  if (node.data.id === id) {
    return node
  }

  let result = {}
  for (const child of node.children) {
    if (Object.keys(result).length) break
    const nodeTemp = recurseFind(child, id)
    result = Object.keys(nodeTemp).length ? nodeTemp : result
  }
  return result
}
