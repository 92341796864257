import OrganizationService from '../../../services/organization.service'

export default {
  getChildOrganization({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return OrganizationService.getChildOrganization(payload.page, payload.limit, payload.search)
      .then((response) => {
        commit('GET_LIST_SUBORG', response.data)
        commit('SET_EMPTY', false)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  }
}
