<template>
  <div
    :class="{ 'show-left': postion == 'left', 'show-top': postion == 'top' }"
    class="color-picker-wrap"
  >
    <div class="input-group color-picker" ref="colorpicker">
      <input
        type="text"
        class="color-picker-text"
        v-model="colorValue"
        @focus="showPicker()"
        @input="updateFromInput"
      />
      <span class="color-picker-container">
        <span
          class="current-color"
          :style="'background-color: ' + colorValue"
          @click="togglePicker()"
        ></span>
        <chrome-picker :value="colors" @input="updateFromPicker" v-if="displayPicker" />
      </span>
    </div>
  </div>
</template>
<script>
import { Chrome } from 'vue-color'
export default {
  components: {
    'chrome-picker': Chrome
  },
  data() {
    return {
      colors: {
        hex: '#000000'
      },
      colorValue: '',
      displayPicker: false
    }
  },
  props: {
    colorPickerType: String,
    color: String,
    postion: String,
    getBgColor: Function,
    parentData: {
      type: String,
      default() {
        return ''
      }
    }
  },
  beforeMount() {
    this.colorValue = this.parentData // save props data to itself's data and deal with it
  },
  mounted() {
    if (this.colorPickerType === 'textColor') {
      this.$parent.$on('onUpdateTextColor', this.onUpdateTextColor)
    }
    if (this.colorPickerType === 'borderColor') {
      this.$parent.$on('onUpdateBorderColor', this.onUpdateBorderColor)
    }
    if (this.colorPickerType === 'mentions') {
      this.$parent.$on('onUpdateMentionsColor', this.onUpdateMentionsColor)
    }
    if (this.colorPickerType === 'hashtags') {
      this.$parent.$on('onUpdateHashtagsColor', this.onUpdateHashtagsColor)
    }
    if (this.colorPickerType === 'textBgColor') {
      this.$parent.$on('onUpdateTextBgColor', this.onUpdateTextBgColor)
    }
    if (this.colorPickerType === 'textShadowColor') {
      this.$parent.$on('onUpdateTextShadowColor', this.onUpdateTextShadowColor)
    }
    if (this.colorPickerType === 'backgroundColor') {
      this.$parent.$on('onUpdateBackgroundColor', this.onUpdateBackgroundColor)
    }

    this.setColor(this.color || '#FFFFFF')
  },
  methods: {
    onUpdateTextColor(value) {
      this.setColor(value)
    },
    onUpdateBorderColor(value) {
      this.setColor(value)
    },
    onUpdateMentionsColor(value) {
      this.setColor(value)
    },
    onUpdateHashtagsColor(value) {
      this.setColor(value)
    },
    onUpdateTextBgColor(value) {
      this.setColor(value)
    },
    onUpdateTextShadowColor(value) {
      this.setColor(value)
    },
    onUpdateBackgroundColor(value) {
      this.setColor(value)
    },
    setColor(color) {
      this.updateColors(color)
      this.colorValue = color
    },
    updateColors(color) {
      if (color.slice(0, 1) === '#') {
        this.colors = {
          hex: color
        }
      } else if (color.slice(0, 4) === 'rgba') {
        const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
        const hex =
          '#' +
          ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2]))
            .toString(16)
            .slice(1)
        this.colors = {
          hex: hex,
          a: rgba[3]
        }
      }
    },
    showPicker() {
      document.addEventListener('click', this.documentClick)
      this.displayPicker = true
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick)
      this.displayPicker = false
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker()
    },
    updateFromInput() {
      this.updateColors(this.colorValue)
    },
    updateFromPicker(color) {
      this.colors = color
      if (color.rgba.a === 1) {
        this.colorValue = color.hex
      } else {
        this.colorValue =
          'rgba(' +
          color.rgba.r +
          ', ' +
          color.rgba.g +
          ', ' +
          color.rgba.b +
          ', ' +
          color.rgba.a +
          ')'
      }
      this.$emit('onColorChange', this.colorValue)
    },
    documentClick(e) {
      const el = this.$refs.colorpicker
      const target = e.target
      if (el) {
        if (el !== target && !el.contains(target)) {
          this.hidePicker()
        }
      }
    }
  },
  watch: {
    colorValue(val) {
      if (val) {
        this.updateColors(val)
        this.$emit('input', val)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.color-picker-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.color-picker-text {
  width: 80px;
  border: 0;
  margin: 0 10px;
  display: none;
  padding: 0;
}
.color-picker-text:focus {
  box-shadow: none;
  outline: none;
}
.color-picker-wrap {
  &.show-left {
    .vc-chrome {
      position: absolute;
      left: -200px;
      z-index: 9;
    }
  }
  &.show-top {
    .vc-chrome {
      position: absolute;
      top: -250px;
      z-index: 9;
    }
  }
}
.vc-chrome {
  position: absolute;
  top: 35px;
  z-index: 9;
}
.current-color {
  display: inline-block;
  background-color: #ffffff;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  border: 1px solid #9dabbd;
}
.color-picker-container {
  display: flex;
  align-items: center;
}
</style>
