import { render, staticRenderFns } from "./ModalDeleteItemInFolder.vue?vue&type=template&id=ddb19638&scoped=true&"
import script from "./ModalDeleteItemInFolder.vue?vue&type=script&lang=js&"
export * from "./ModalDeleteItemInFolder.vue?vue&type=script&lang=js&"
import style0 from "./ModalDeleteItemInFolder.vue?vue&type=style&index=0&id=ddb19638&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddb19638",
  null
  
)

export default component.exports