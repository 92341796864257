<template>
  <div class="row templateIg">
    <div class="col-12">
      <!-- <select @change="onChange($event)" class="ig-template" id="template">
          <option value="">Choose Template</option>
          <option value="default">Template Default</option>
          <option v-for="option in options" v-bind:key="option.id" v-bind:value="option.id">
            {{ option.name }}
          </option>
        </select> -->
      <!-- <Select2 v-model="selectedValue" :options="myOptions" /> -->
      <div class="select-group">
        <Select2
          v-model="templateName"
          :options="options"
          @select="selectEvent($event)"
          :settings="{ minimumResultsForSearch: -1 }"
        />
        <span class="lb-right caret"></span>
      </div>
    </div>
  </div>
</template>

<script>
import Select2 from 'v-select2-component'
import SmartAssetsTemplateService from '@/services/smart-asset-template.service'
export default {
  name: 'template',
  components: {
    Select2
  },
  data: function () {
    return {
      templateName: 'default'
    }
  },
  props: {
    options: Array,
    orientation: String
  },
  methods: {
    selectEvent({ id, text }) {
      const textValue = text
      if (id) {
        if (id === 'default') {
          this.$store.dispatch('instagrameditor/activeSnap', false)
          const snapchecked = document.getElementById('snap-checked')
          snapchecked.checked = false
          // this.$store.dispatch('instagrameditor/setDefaultSetting')
          this.$store.dispatch('instagrameditor/setInstagramMode', {
            orientation: this.orientation
          })
          this.$store.dispatch('instagrameditor/setTemplateAction', true)
        } else {
          this.$store.dispatch('instagrameditor/setTemplateAction', false)
          SmartAssetsTemplateService.getById(id)
            .then((jsonData) => {
              jsonData.data.templateID = id
              jsonData.data.templateName = textValue
              this.$store.dispatch('instagrameditor/activeSnap', false)
              this.$store.dispatch('instagrameditor/setTemplateID', jsonData.data.id)
              this.$store.dispatch('instagrameditor/setTemplateName', jsonData.data.name)
              const snapchecked = document.getElementById('snap-checked')
              snapchecked.checked = false
              const x = 100
              const y = 100
              const w = 0
              const h = 0
              this.$store.dispatch('instagrameditor/updateAllAttrEl', { x, y, w, h })
              setTimeout(() => {
                this.$store.dispatch('instagrameditor/loadTemplate', jsonData.data)
              }, 200)
            })
            .catch((_) => {})
        }
      } else {
        this.$store.dispatch('instagrameditor/setTemplateAction', true)
      }
    },
    onChange(event) {
      const selectedIndex = event.currentTarget.options.selectedIndex
      const textValue = event.currentTarget.options[selectedIndex].text
      const id = event.target.value
      if (id) {
        if (id === 'default') {
          this.$store.dispatch('instagrameditor/activeSnap', false)
          const snapchecked = document.getElementById('snap-checked')
          snapchecked.checked = false
          // this.$store.dispatch('instagrameditor/setDefaultSetting')
          this.$store.dispatch('instagrameditor/setInstagramMode', {
            orientation: this.orientation
          })
          this.$store.dispatch('instagrameditor/setTemplateAction', true)
        } else {
          this.$store.dispatch('instagrameditor/setTemplateAction', false)
          SmartAssetsTemplateService.getById(id)
            .then((jsonData) => {
              jsonData.data.templateID = id
              jsonData.data.templateName = textValue
              this.$store.dispatch('instagrameditor/activeSnap', false)
              this.$store.dispatch('instagrameditor/setTemplateID', jsonData.data.id)
              this.$store.dispatch('instagrameditor/setTemplateName', jsonData.data.name)
              const snapchecked = document.getElementById('snap-checked')
              snapchecked.checked = false
              const x = 100
              const y = 100
              const w = 0
              const h = 0
              this.$store.dispatch('instagrameditor/updateAllAttrEl', { x, y, w, h })
              setTimeout(() => {
                this.$store.dispatch('instagrameditor/loadTemplate', jsonData.data)
              }, 200)
            })
            .catch((err) => {
              this.$toast.error(
                err.message ? err.message : 'Something went wrong. Please try again.'
              )
            })
        }
      } else {
        this.$store.dispatch('instagrameditor/setTemplateAction', true)
      }
    }
  },
  created() {}
}
</script>
<style scoped>
#template {
  height: 25px;
  width: 230px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  border: solid 0.5px #dcdcdc;
}
.templateIg {
  min-width: 265px;
}
</style>
