import state from './smartAssetEditorState'
import mutations from './smartAssetEditorMutations'
import getters from './smartAssetEditorGetters'
import actions from './smartAssetEditorActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
