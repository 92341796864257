<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <div class="sf-card">
          <div class="card-heading">
            <p>Language</p>
          </div>
          <div class="card-body editable">
            <div class="row">
              <div class="col-12">
                <p class="d-flex">
                  <span class="sf-text-strong mr-2">{{ $t('name') }}</span>
                  <span>{{ languageDetail.name }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-header top-bar">
      <div class="header-left">
        <div class="admin-search-box">
          <SfInputSearch
            placeholder="Search"
            v-debounce:500ms="searchInputChange"
            class="sf-input-search"
          />
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 sf-table">
          <b-table
            :busy="loading"
            show-empty
            striped
            hover
            :items="backupLanguages"
            :fields="fields"
            head-variant="none"
            table-variant="none"
            thead-class="custom-b-table"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty>
              <p class="d-flex justify-content-center">No data available in table</p>
            </template>

            <template v-slot:head(name)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(name)="field">
              <p>
                <a :href="`${field.item.expiring_url}`" download>{{ field.item.name }}</a>
              </p>
            </template>

            <template v-slot:head(language_type)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(language_type)="field">
              <p class="text-capitalize">{{ field.item.language_type }}</p>
            </template>

            <template v-slot:head(type_file)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(type_file)="field">
              <p>{{ field.item.type_file }}</p>
            </template>

            <template v-slot:head(created_at)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(created_at)="field">
              <p>{{ field.item.created_at }}</p>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdminLanguageService from '@/services/admin-language.service'
import SfInputSearch from '@/components/SfInputSearch.vue'

export default {
  name: 'admin-languages',

  components: {
    SfInputSearch
  },
  data() {
    return {
      msg: 'Welcome to Index!!!',
      currentPage: 1,
      limit: 25,
      isEmpty: true,
      loading: true,
      fields: [
        {
          key: 'name',
          sortable: true,
          label: 'Name',
          colType: 'name'
        },
        {
          key: 'language_type',
          sortable: true,
          label: 'Source',
          colType: 'language_type'
        },
        {
          key: 'type_file',
          sortable: true,
          label: 'Type',
          colType: 'type_file'
        },
        {
          key: 'created_at',
          sortable: true,
          label: 'Date',
          colType: 'created_at'
        }
      ],
      dataSearch: '',
      loadingLanguageDetail: false,
      backupLanguages: [],
      languageDetail: {}
    }
  },
  methods: {
    fetchData(activePage, search) {
      AdminLanguageService.getBackupLanguages(
        this.currentLanguageID,
        this.currentPage,
        this.limit,
        this.dataSearch
      )
        .then((response) => {
          this.backupLanguages = response.data.items
          this.isEmpty = response.data.items.length === 0
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong.')
        })
        .finally(() => {
          this.loading = false
        })
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage, this.dataSearch)
    },
    getItemByID() {
      this.loadingLanguageDetail = true
      AdminLanguageService.detailLanguage(this.currentLanguageID)
        .then((response) => {
          this.languageDetail = response.data
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    }
  },
  mounted() {
    this.currentLanguageID = this.$route.params.id
    this.fetchData(this.currentPage, this.currentPage)
    this.getItemByID()
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    })
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
  }
}
</script>

<style lang="scss" scoped>
@import '../../Adminpage.scss';
.panel-body {
  border: 2px dashed #888888;
  cursor: pointer;
  padding: 35px;
}
</style>
