<template>
  <div id="loop-detail">
    <div class="top-header mb-hidden row mx-0">
      <div class="col-6 col-sm-4 d-flex">
        <div class="mr-3">
          <SfInputSearch
            v-model="dataSearch"
            :placeholder="$t('search')"
            v-debounce:500ms="searchInputChange"
            :height="'40px'"
          />
        </div>
        <div class="col d-none d-sm-block mb-1">
          <multiselect
            style="width: 175px"
            v-model="filterAssets"
            :options="optionAssests"
            :searchable="false"
            :allow-empty="false"
            deselectLabel=""
            selectLabel=""
            selectedLabel=""
            label="name"
            track-by="name"
            class="sf-multiselect"
            @select="filterForType"
          >
          </multiselect>
        </div>
        <div class="col mb-1">
          <button
            type="button"
            v-if="!selectAllButton && items.length > 0"
            @click="selectAllButton = !selectAllButton"
            class="sf-primary btn btn-block multiselect sf-multiselect select-btn"
          >
            <img class="data-image" :src="squareCheck" /> Select
          </button>
          <button
            type="button"
            v-if="selectAllButton"
            @click="
              selectAllButton = !selectAllButton
              selectedItems = []
            "
            class="sf-primary btn btn-block multiselect sf-multiselect select-btn"
          >
            <img class="data-image" :src="squareCross" /> Unselect
          </button>
        </div>
      </div>
      <div class="col-7 col-sm-8 mb-hidden">
        <div class="group-button d-flex justify-content-end align-items-center">
          <button
            class="btn btn-show switcher_grid"
            id="btn-gridview"
            :class="{ active: viewType === 'grid-view' }"
            @click="viewType = 'grid-view'"
          ></button>
          <button
            class="btn btn-show switcher_list"
            id="btn-listview"
            :class="{ active: viewType === 'list-view' }"
            @click="viewType = 'list-view'"
          ></button>
          <div class="btn-group btn-group-header" role="group">
            <button class="btn btn-default btn-custom" type="button" @click="sortByName">
              {{ $t('name') }}
            </button>
            <button class="btn btn-default btn-custom" type="button" @click="sortBySize">
              {{ $t('size') }}
            </button>
            <button class="btn btn-default btn-custom" type="button" @click="sortByDuration">
              {{ $t('duration') }}
            </button>
            <button class="btn btn-default btn-custom" type="button" @click="sortByDate">
              {{ $t('date') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="content-page-loop">
      <div class="content">
        <div class="top-block row">
          <div class="sf-breadcrumbs col-6 d-none d-sm-block">
            <ul>
              <template v-if="breadcrumb.length">
                <li v-for="item in breadcrumb" :key="item.url" @click="clickBreadcrumb(item)">
                  <a class="sf-link"
                    ><span>{{ item.name }}</span></a
                  >
                </li>
              </template>
              <template v-else>
                <li
                  @click="
                    clickBreadcrumb({
                      url: '/apisite/v1/library',
                      type: 'main'
                    })
                  "
                >
                  <a class="sf-link"
                    ><span>{{ $t('library') }}</span></a
                  >
                </li>
              </template>
            </ul>
          </div>
          <div class="col-12 col-sm-6 d-flex justify-content-end align-items-center">
            <b-pagination
              v-model="currentPage"
              :pills="true"
              :total-rows="total"
              per-page="1"
              limit="5"
              :class="'sf-b-pagination'"
              @change="changePage"
            >
            </b-pagination>
            <SfOptionLimitPage @changeLimitPage="changeLimitPage" :limitPage.sync="limitPage" />
          </div>
        </div>
        <div class="slim-div">
          <itemFolder
            :viewType="viewType"
            :nodes="items"
            :loading.sync="loading"
            :limitPage="limitPage"
            @openFolder="openFolder"
            @onCloneBulk="onCloneBulk"
            :selectAllButton="selectAllButton"
          />
        </div>
      </div>

      <div
        class="mb-hidden"
        id="tree-view"
        :class="{
          'loop-editor': chooseComponent === 'loopEditor'
        }"
      >
        <div class="button-create">
          <p
            :class="{
              active: chooseComponent === 'folder'
            }"
            @click="chooseComponent = 'folder'"
          >
            {{ $t('folder') }}
          </p>
          <p
            id="v-step-7"
            :class="{
              active: chooseComponent === 'loopEditor'
            }"
            @click="chooseComponent = 'loopEditor'"
          >
            {{ $t('loop-editor') }}
          </p>
        </div>
        <template v-if="chooseComponent === 'folder'">
          <div class="folder_default">
            <img src="~@/assets/images/home-folder-library.svg" />
            <div class="wrap_p">
              <p>{{ $t('library') }}</p>
            </div>
          </div>
          <vueTree
            :nodesTree="treeFolder"
            @selectedFolder="onSelect"
            @dataChanged="treeFolderChange"
            :isShowSidebar="true"
          />
        </template>
        <template v-if="chooseComponent === 'loopEditor'">
          <div v-if="loadingLoop || loadingAssets" :key="`loading-placeholders`">
            <content-placeholders>
              <div class="dragndrop-loop">
                <div class="dragndrop-loop-name">
                  <content-placeholders-text :lines="1" />
                </div>
                <div class="dragndrop-loop-time">{{ $t('running-time') }} 00:00</div>
                <div class="dragndrop-loop-dragArea">
                  <template v-for="i in 5">
                    <div class="element-item-runtime loop-asset-item" :key="`loading-${i}`">
                      <i class="fa fa-ellipsis-v"></i>
                      <div class="detail-section">
                        <div class="img-name">
                          <div class="background-img">
                            <div class="wrap-img">
                              <content-placeholders-img />
                            </div>
                            <div class="name">
                              <div class="wrap-p">
                                <p><content-placeholders-text :lines="2" /></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <span class="truncate">
                        <p><content-placeholders-text :lines="1" /></p>
                      </span>
                    </div>
                  </template>
                </div>
              </div>
            </content-placeholders>
          </div>
          <div v-else :key="`content-item`">
            <div class="dragndrop-loop">
              <div class="dragndrop-loop-name">
                {{ loopItem.displayname }}
              </div>
              <div class="dragndrop-loop-time">
                {{ $t('running-time') }} {{ toDuration(durationRender) }}
              </div>
              <draggable
                class="dragndrop-loop-dragArea"
                :list="loopItem.slide_asets"
                group="item"
                @change="changeStateDraggable"
                @add="onAddItem"
              >
                <div v-if="loopItem.slide_asets.length === 0" class="dragndrop-loop-dragArea-empty">
                  <p>{{ $t('loop-empty-message') }}</p>
                  <img src="~@/assets/images/image_default-running.png" />
                </div>
                <div v-else v-for="element in loopItem.slide_asets" :key="element.index">
                  <div
                    v-if="element.template_type !== 'folder'"
                    class="element-item-runtime loop-asset-item"
                  >
                    <i class="fa fa-ellipsis-v"></i>
                    <div class="detail-section">
                      <div class="img-name">
                        <div class="background-img">
                          <div class="wrap-img">
                            <img
                              v-if="
                                element.template_type === typeFile.video ||
                                element.template_type === typeFile.image
                              "
                              class="img"
                              :src="element.thumb_url"
                            />
                            <img
                              v-if="element.template_type === typeFile.pdf"
                              class="img"
                              src="~@/assets/images/pdf-icon.svg"
                            />
                            <img
                              v-if="element.template_type === typeFile.instagram"
                              class="img"
                              src="~@/assets/images/instagram.svg"
                            />
                            <img
                              v-if="element.template_type === typeFile.website"
                              class="img"
                              src="~@/assets/images/web-icon.svg"
                            />
                            <img
                              v-if="element.template_type === typeFile.youtube"
                              class="img"
                              src="~@/assets/images/youtube-icon.svg"
                            />
                            <img
                              v-if="element.template_type === typeFile.google_sheet"
                              class="img"
                              src="~@/assets/images/google-sheet-icon.svg"
                            />
                            <img
                              v-if="element.template_type === typeFile.google_slide"
                              class="img"
                              src="~@/assets/images/google-slide-icon.svg"
                            />
                            <img
                              v-if="element.template_type === typeFile.custom_asset"
                              class="img"
                              src="~@/assets/images/custom-asset-icon.svg"
                            />
                            <img
                              v-if="element.template_type === typeFile.canva"
                              class="img"
                              src="~@/assets/images/canva-icon.svg"
                            />
                          </div>
                          <div class="name">
                            <div class="wrap-p">
                              <p :title="element.displayname">{{ element.displayname }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="size-section runtime">
                      <img
                        v-if="element.template_type === typeFile.video"
                        src="~@/assets/images/video-listview-icon.svg"
                      />
                      <img
                        v-else-if="element.template_type === typeFile.website"
                        src="~@/assets/images/web-listview-icon.svg"
                      />
                      <img
                        v-else-if="element.template_type === typeFile.pdf"
                        src="~@/assets/images/pdf-listview-icon.svg"
                      />
                      <img
                        v-else-if="element.template_type === typeFile.instagram"
                        src="~@/assets/images/instagram-listview-icon.svg"
                      />
                      <img
                        v-else-if="element.template_type === typeFile.image"
                        src="~@/assets/images/image-listview-icon.svg"
                      />
                      <img
                        v-else-if="element.template_type === typeFile.youtube"
                        src="~@/assets/images/youtube-listview-icon.svg"
                      />
                      <img
                        v-else-if="element.template_type === typeFile.google_sheet"
                        src="~@/assets/images/google-sheet-listview-icon.svg"
                      />
                      <img
                        v-else-if="element.template_type === typeFile.google_slide"
                        src="~@/assets/images/google-slide-listview-icon.svg"
                      />
                      <img
                        v-else-if="element.template_type === typeFile.custom_asset"
                        src="~@/assets/images/custom-asset-listview-icon.svg"
                      />
                      <img
                        v-if="element.template_type === typeFile.canva"
                        class="img"
                        src="~@/assets/images/Canva_icon_15x15.svg"
                      />
                    </div>

                    <span class="truncate" v-if="element.template_type === typeFile.video">
                      <p class="asset-duration-video">
                        {{ secondsToString(element.duration, slideDuration) }}
                      </p>
                    </span>

                    <span class="truncate" v-else>
                      <input
                        type="text"
                        class="asset-duration"
                        v-model="element.duration"
                        @input="validateInput($event, element.index)"
                        @dblclick="selectText"
                        @click="handleSelection"
                        maxlength="9"
                        preventOnFilter="false"
                      />
                      <span class="display-duration">{{
                        secondsToString(element.duration, slideDuration)
                      }}</span>
                    </span>
                    <div class="js-remove">
                      <button @click="removeItem(element.index)" class="sf-icon-button">
                        <img src="@/assets/images/close-x.svg" />
                      </button>
                    </div>
                  </div>
                </div>
              </draggable>
            </div>
            <div class="group_button row dragndrop-loop-action-bottom">
              <div class="col-12 d-flex justify-content-end">
                <button @click="cancelLoopDetail" class="sf-secondary mr-2" type="button">
                  {{ $t('cancel') }}
                </button>
                <button
                  :disabled="$route.params.loopId === 'id'"
                  @click="saveLoopDetail"
                  class="sf-primary"
                  type="button"
                >
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <SfWarningModal
      :title="$t('modal.title.warning')"
      closeOnOverlay
      :show.sync="isShownModalUnSupport"
    >
      <div class="content-delete">
        <template v-if="unsupportedType === 'asset_unallow'">
          <p
            class="attempting-use-asset mb-3"
            v-html="$t('loop-attempting-use-asset', { asset: slide_template_unallow })"
          ></p>
          <ul class="mb-3">
            <li class="player-name" v-for="player in players_unallow" :key="player">
              {{ player }}
            </li>
          </ul>
          <p
            class="contact-to-upgrade mb-3"
            v-html="
              $t('loop-contact-to-upgrade', {
                asset: slide_template_unallow,
                device: hardware_require
              })
            "
          ></p>
        </template>
        <template v-else-if="unsupportedType === 'pi_firmware_require'">
          <p
            class="attempting-use-asset mb-3"
            v-html="$t('loop-attempting-version-unsupported', { asset: slide_template_unallow })"
          ></p>
          <ul class="mb-3">
            <li class="player-name" v-for="player in players_unallow" :key="player">
              {{ player }}
            </li>
          </ul>
          <p
            class="contact-to-upgrade mb-3"
            v-html="
              $t('loop-contact-to-upgrade-version', {
                asset: slide_template_unallow,
                applicationVersion: pi_firmware_require
              })
            "
          ></p>
        </template>
        <p class="cantact-number">{{ $t('tell') }}: (844) 772-7336</p>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-primary" @click="isShownModalUnSupport = false">{{ $t('ok') }}</button>
      </div>
    </SfWarningModal>
    <ModalCloneItemInFolder
      :show.sync="showCloneItemBulk"
      :data.sync="dataModal"
      @update:show="updateShow('showCloneItemBulk')"
      @modal:save="confirmCloneItemBulk"
    />

    <SfWarningModal
      :title="$t('modal.title.warning')"
      closeOnOverlay
      :show.sync="isNewUpdatedBySomeOne"
    >
      <div class="content-delete">
        <p class="cantact-number">
          Someone else has just finished editing this Loop. Please reload the page to see their
          changes
        </p>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isNewUpdatedBySomeOne = false">
          {{ $t('keep') }}
        </button>
        <button class="sf-primary" @click="reloadPage">{{ $t('refresh') }}</button>
      </div>
    </SfWarningModal>

    <SfWarningModal
      :title="$t('modal.title.warning')"
      :showCloseButton="false"
      :show.sync="isAssetDeleted"
      @update:show="reloadListItems"
    >
      <div class="content-delete">
        <p class="cantact-number">
          Some assets in this Loop have been updated. Please reload the page to see their changes
        </p>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-primary" @click="reloadListItems">{{ $t('refresh') }}</button>
      </div>
    </SfWarningModal>

    <loading :active.sync="isLoading" backgroundColor="rgba(0,0,0,0.6)" color="#126CDB"></loading>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Multiselect from 'vue-multiselect'
import vueTree from '@/components/VueTree'
import ItemFolder from '@/components/itemFolder/LibraryInLoopDetail'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin'
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin'
import typeFile from '@/constant/typeFile'
import draggable from 'vuedraggable'
import LoopAssetsService from '@/services/loop-assets.service'
import { toDuration, secondsToString } from '@/helper/helper'
import SfInputSearch from '@/components/SfInputSearch.vue'
import SfOptionLimitPage from '@/components/SfOptionLimitPage.vue'
import SfWarningModal from '@/components/SfWarningModal.vue'
import ModalCloneItemInFolder from '@/components/loop/ModalCloneItemInFolder.vue'
import squareCheck from '@/assets/square-check.svg'
import squareCross from '@/assets/square-cross.svg'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import Loading from 'vue-loading-overlay'

export default {
  name: 'LoopdetailPage',
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  components: {
    Multiselect,
    vueTree,
    ItemFolder,
    draggable,
    SfInputSearch,
    SfOptionLimitPage,
    SfWarningModal,
    ModalCloneItemInFolder,
    Loading
  },
  props: {},
  data() {
    return {
      typeFile,
      currentAssetEl: null,
      viewType: 'grid-view',
      result: '',
      rows: 100,
      perpage: 10,
      currentPage: 1,
      limitItem: 3,
      dataModal: {},
      chooseComponent: 'loopEditor',
      showslide_asets: [],
      typeShowSmartAsset: 'main',
      slideDuration: 5,
      folderData: {
        id: ''
      },
      loopItem: {
        displayname: 'dnt-loop-1',
        sum_duration: '00:00:00',
        slide_asets: []
      },
      filterAssets: {
        name: 'All Assets (0)',
        value: ''
      },
      limitPage: {
        name: '25 / page',
        value: '25'
      },
      firstDataLoopEditor: [],
      currentOrientation: '',
      loadingLoop: false,
      user: {},
      dataSearch: '',
      players_unallow: [],
      slide_template_unallow: '',
      hardware_require: '',
      isShownModalUnSupport: false,
      unsupportedType: '',
      pi_firmware_require: '',
      selectAllButton: false,
      showCloneItemBulk: false,
      squareCheck,
      squareCross,
      assetItems: [],
      isNewUpdatedBySomeOne: false,
      isLoading: false,
      isAssetDeleted: false,
      removedAssets: []
    }
  },

  watch: {
    'loopItem.slide_asets'(value) {
      if (JSON.stringify(value) !== JSON.stringify(this.firstDataLoopEditor)) {
        this.binLeavePage()
      } else {
        window.onbeforeunload = null
      }
    },
    nested_items(arr) {
      if (arr.length > 0) {
        this.loopItem.slide_asets = this.loopItem.slide_asets.concat(arr)
        this.resetNestedListArrayinFolder()
        this.resetShowEditDuration(this.loopItem.slide_asets)
      }
    },
    loopItem: {
      handler: function (val, oldVal) {
        if (JSON.stringify(oldVal) !== JSON.stringify(val)) {
          const arrSlides = cloneDeep(val.slide_asets)
          let isNullDuration = false
          if (arrSlides && arrSlides.length) {
            arrSlides.forEach((element) => {
              element.duration = element.duration ? parseInt(element.duration, 10) : ''
              const duration = element.duration
              if (!duration || duration <= 0) {
                isNullDuration = true
              }
            })

            if (isNullDuration) {
              setTimeout(() => {
                arrSlides.forEach((element) => {
                  const duration = element.duration
                  if (!duration || duration <= 0) {
                    element.duration = this.slideDuration
                  }
                })
                this.loopItem.slide_asets = arrSlides
              }, 2000)
            } else {
              this.loopItem.slide_asets = arrSlides
            }
          }
        }
      },
      deep: true
    },
    statistical: {
      deep: true,
      handler: function (val, oldVal) {
        this.translateMultiselect()
      }
    },
    '$i18n.locale'() {
      this.translateMultiselect()
    }
  },

  methods: {
    ...mapActions('loopdetail', {
      getListSmartAsset: 'getListSmarttAsset',
      getListTreeFolder: 'getListFolder',
      getTreeFolderChildren: 'getTreeFolderChildById',
      getListSmartAssetInFolder: 'getListSmartAssetInFolder',
      getNestedListSmartAssetInFolder: 'getNestedListSmartAssetInFolder',
      getMultipleAssetInFolder: 'getMultipleAssetInFolder',
      resetNestedListArrayinFolder: 'resetNestedListArrayinFolder'
    }),
    ...mapMutations('loopdetail', {
      setItem: 'SET_ITEMS',
      setLoopId: 'SET_PAGE_LOOP_ID',
      setPage: 'SET_PAGINATION_PAGE',
      setTreeFolder: 'SET_TREE_FOLDER_CHANGED',
      setItemsFiter: 'SET_ITEMS_DROP_BY_ID',
      createFromSocket: 'CREATE_FROM_SOCKET',
      deleteFromSocket: 'DELETE_FROM_SOCKET',
      setDefaultBreadcrumb: 'SET_DEFAULT_BREADCRUMB',
      updateCountAssetStatistical: 'UPDATE_COUNT_ASSET_STATISTICAL'
    }),
    ...mapMutations('loop', {
      updateFromSocket: 'UPDATE_FROM_SOCKET'
    }),
    toDuration,
    secondsToString,
    openFolder(node) {
      this.folderData.id = node.id
      this.typeShowSmartAsset = 'folder'
      this.getListAsset(1, this.limitPage.value)
    },
    onDurationChange(e) {
      // let value = e.target.innerText
    },
    cancelLoopDetail() {
      this.$router.push({
        name: 'loop',
        params: {
          selectedNode: this.$route.params.selectedNode,
          viewType: this.$route.params.viewType,
          filterOrientation: this.$route.params.filterOrientation,
          dataSearch: this.$route.params.dataSearch,
          sortedInfo: this.$route.params.sortedInfo
        }
      })
    },
    saveLoopDetail() {
      const slide_asets = JSON.stringify(
        this.loopItem.slide_asets.filter((i) => i.template_type !== 'folder')
      )
      const arrSlides = JSON.parse(slide_asets)
      arrSlides.forEach((element) => {
        if (element.newItem) {
          element.hooks_json_string = JSON.stringify({
            [element.default_hook_key]: {
              type: element.template_type,
              id: element.id
            }
          })
          element.id = 'new'
        }
        delete element.template_type
        delete element.thumb_url
        delete element.index
        delete element.newItem
        delete element.default_hook_key
        element.duration = Number(element.duration)
      })
      // let loop_json_string = arrSlides.map(i => {
      //   return JSON.stringify(i)
      // })
      const param = {
        loop_asset: {
          displayname: this.loopItem.displayname,
          orientation: this.loopItem.orientation,
          loop_json_string: JSON.stringify(arrSlides)
        }
      }
      this.isLoading = true
      LoopAssetsService.editLoopAssetsByID(param, this.loopId)
        .then((res) => {
          this.$toast.open({
            message: res.message || 'Loop successfully updated',
            type: 'success'
          })
          this.firstDataLoopEditor = [...this.loopItem.slide_asets]
          window.onbeforeunload = null
          setTimeout(() => {
            this.$router.push({
              name: 'loop',
              params: {
                selectedNode: this.$route.params.selectedNode,
                viewType: this.$route.params.viewType,
                filterOrientation: this.$route.params.filterOrientation,
                dataSearch: this.$route.params.dataSearch,
                sortedInfo: this.$route.params.sortedInfo
              }
            })
          }, 300)
        })
        .catch((error) => {
          this.resetShowEditDuration(this.firstDataLoopEditor)
          if (error.data && ['pi_firmware_require', 'asset_unallow'].includes(error.data.type)) {
            this.unsupportedType = error.data.type
            const asset = error.data.slide_template_unallow.length > 1 ? ' assets' : ' asset'
            this.slide_template_unallow =
              `<span style="font-weight: 600;">${error.data.slide_template_unallow.join(
                ', '
              )}</span>` + asset
            this.hardware_require = `<span style="font-weight: 600;">${error.data.hardware_require.displayname}</span>`
            this.pi_firmware_require = `<span style="font-weight: 600;">${error.data.pi_firmware_require}</span>`
            this.players_unallow = error.data.players_unallow
            this.isShownModalUnSupport = true
          } else {
            this.$toast.open({
              message: error.message,
              type: 'error'
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    treeFolderChange(treeFolder) {
      this.setTreeFolder(treeFolder)
    },
    getLoopAssetsByID() {
      this.loadingLoop = true
      LoopAssetsService.getLoopAssetsByID(this.loopId)
        .then((res) => {
          const data = res.data
          data.slide_asets.forEach((item) => {
            item.duration = item.duration / 1000
          })
          this.loopItem = data
          this.resetShowEditDuration(this.loopItem.slide_asets)
          this.firstDataLoopEditor = [...data.slide_asets]
          this.currentOrientation = data.orientation
          this.loadingLoop = false
        })
        .catch((err) => {
          if (err.message) {
            this.$toast.error(`${err.message}`)
          }
        })
    },
    clickBreadcrumb(item) {
      if (item.name === 'Library') {
        this.setDefaultBreadcrumb()
      }
      if (item.type === 'main') {
        this.typeShowSmartAsset = 'main'
      } else if (item.type === 'folder') {
        this.folderData.id = item.id
        this.typeShowSmartAsset = 'folder'
      }
      this.getListAsset(1, this.limitPage.value)
    },
    getListAsset(page, limit, type) {
      if (this.typeShowSmartAsset === 'main') {
        this.getListSmartAsset({
          page,
          limit,
          search: this.dataSearch,
          page_name: 'loop_edit',
          loopId: this.loopId,
          type: this.filterAssets.value,
          orientation: this.currentOrientation
        })
        this.typeShowSmartAsset = 'main'
      } else if (this.typeShowSmartAsset === 'folder') {
        this.getListSmartAssetInFolder({
          id: this.folderData.id,
          page,
          limit,
          search: this.dataSearch,
          loopId: this.loopId,
          type: this.filterAssets.value,
          orientation: this.currentOrientation
        })
      }
    },
    getSlideDuration() {
      const userStorage = this.$cookies.get('user')
      if (userStorage) {
        const user = userStorage
        this.slideDuration = user.default_slide_duration / 1000
      }
    },
    onSelect(node) {
      if (node.data.file_type === 'folder') {
        this.getListSmartAssetInFolder({
          id: node.data.id,
          page: 1,
          limit: this.limitPage.value,
          loopId: this.loopId,
          orientation: this.currentOrientation
        })
        this.folderData.id = node.data.id
        this.typeShowSmartAsset = 'folder'
      }
      if (!node.data.isGetData && node.data.has_children) {
        this.getTreeFolderChildren({ id: node.data.id, orientation: this.currentOrientation })
      }
    },
    turnOffEditDuration(index) {},
    resetShowEditDuration(node = [], dataChange) {
      this.loopItem.slide_asets = node.map((item, index) => {
        let tempItem = { ...item }
        const duration = tempItem.duration ? tempItem.duration : this.slideDuration
        if (tempItem.hasOwnProperty('name')) {
          tempItem = {
            id: item.id,
            newItem: true,
            displayname: item.name,
            template_type: item.type,
            duration: duration,
            thumb_url: item.thumb_link,
            slide_template_id: item.default_slide_template,
            default_hook_key: item.default_hook_key
          }
        }
        tempItem.index = index
        return tempItem
      })
    },
    changeStateDraggable(event) {
      this.resetShowEditDuration(this.loopItem.slide_asets, true)
    },
    onAddItem(event) {
      const draggedItem = this.loopItem.slide_asets[event.newIndex]
      if (
        (draggedItem && draggedItem.template_type === 'folder') ||
        (draggedItem && draggedItem.type === 'folder')
      ) {
        this.loopItem.slide_asets.splice(event.newIndex, 1)
        this.getNestedListSmartAssetInFolder({
          id: draggedItem.id,
          loopId: this.$route.params.loopId,
          type: this.filterAssets.value,
          orientation: this.currentOrientation
        })
      }
    },
    onDragMove(event) {},
    editDuration(index) {
      this.loopItem.slide_asets.find((item) => item.index === index).showEdit = true
    },
    removeItem(index) {
      this.loopItem.slide_asets = this.loopItem.slide_asets.filter((item) => item.index !== index)
      this.resetShowEditDuration(this.loopItem.slide_asets, true)
    },
    sortItem(compare) {
      const nodesSort = JSON.parse(JSON.stringify(this.items))
        .map((item) => {
          item.created_at = new Date(item.created_at)
          return item
        })
        .sort((a, b) => compare(a, b))

      if (JSON.stringify(this.items) === JSON.stringify(nodesSort)) {
        this.setItem({ items: nodesSort.reverse() })
      } else {
        this.setItem({ items: nodesSort })
      }
    },
    compareName(a, b) {
      return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
    },
    compareSize(a, b) {
      return (a.size || 0) > (b.size || 0) ? -1 : (a.size || 0) < (b.size || 0) ? 1 : 0
    },
    compareDuration(a, b) {
      return (a.duration || 0) > (b.duration || 0)
        ? -1
        : (a.duration || 0) < (b.duration || 0)
        ? 1
        : 0
    },
    compareDate(a, b) {
      return (a.created_at || Date.now()) > (b.created_at || Date.now())
        ? -1
        : (a.created_at || Date.now()) < (b.created_at || Date.now())
        ? 1
        : 0
    },
    sortByName() {
      this.sortItem(this.compareName)
    },
    sortBySize() {
      this.sortItem(this.compareSize)
    },
    sortByDuration() {
      this.sortItem(this.compareDuration)
    },
    sortByDate() {
      this.sortItem(this.compareDate)
    },
    binLeavePage() {
      window.onbeforeunload = function (e) {
        const message =
          'Are you sure you would like to leave this page without saving your progress?'
        e = e || window.event
        // For IE and Firefox
        if (e) {
          e.returnValue = message
        }

        // For Safari
        return message
      }
    },
    getUser() {
      const user = this.$cookies.get('user')
      if (user) {
        this.user = user
        this.limitPage = {
          name: `${user.default_number_asset || 25} / ${this.$t('page')}`,
          value: `${user.default_number_asset || 25}`
        }
      }
    },

    listOptionAsset() {
      return [
        {
          name: this.$t('all-assets') + ` (${this.statistical.total})`,
          value: ''
        },
        {
          name: this.$t('videos') + ` (${this.statistical.video})`,
          value: typeFile.video
        },
        {
          name: this.$t('images') + ` (${this.statistical.image})`,
          value: typeFile.image
        },
        {
          name: `Screen Editor (${this.statistical.custom_asset})`,
          value: typeFile.custom_asset
        },
        {
          name: this.$t('pdf') + ` (${this.statistical.pdf})`,
          value: typeFile.pdf
        },
        {
          name: this.$t('website') + ` (${this.statistical.web})`,
          value: typeFile.website
        },
        {
          name: this.$t('instagram') + ` (${this.statistical.instagram})`,
          value: typeFile.instagram
        },
        {
          name: this.$t('folder') + ` (${this.statistical.folder})`,
          value: typeFile.folder
        },
        {
          name: this.$t('youtube') + ` (${this.statistical.youtube})`,
          value: typeFile.youtube
        },
        {
          name: `Google Sheet (${this.statistical.google_sheet})`,
          value: typeFile.google_sheet
        },
        {
          name: `Google Slide (${this.statistical.google_slide})`,
          value: typeFile.google_slide
        }
      ]
    },
    getListSmartAssetByFilter(page = 1) {
      this.currentPage = page
      this.getListAsset(this.currentPage, this.limitPage.value, this.filterAssets.value)
    },
    filterForType(value) {
      this.filterAssets = value
      this.getListSmartAssetByFilter()
    },
    changePage(value) {
      this.currentPage = value
      this.getListSmartAssetByFilter(this.currentPage)
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.getListSmartAssetByFilter()
    },
    translateMultiselect() {
      const listOptionAsset = this.listOptionAsset()
      const type = listOptionAsset.find((option) => option.value === this.filterAssets.value)
      this.filterAssets.name = type.name
    },
    changeLimitPage(data) {
      this.limitPage = data
      this.currentPage = 1
      this.getListSmartAssetByFilter()
    },
    updateCountAsset(typeAsset, type = 'create') {
      this.updateCountAssetStatistical({ type: type, typeAsset: typeAsset })
    },
    selectText(event) {
      const input = event.target
      input.setSelectionRange(0, input.value.length)
    },
    handleSelection(event) {
      const input = event.target
      if (navigator.userAgent.indexOf('Chrome') === -1) {
        input.selectionStart = input.selectionEnd = input.selectionDirection = input.value.length
      }
    },
    validateInput(event, index) {
      let value = event.target.value.replace(/[^0-9]/g, '') // remove non-numeric characters
      if (value.length > 9) {
        value = value.slice(0, 9) // limit to 9 characters
      }
      this.loopItem.slide_asets[index].duration = value // update the model value
    },
    onCloneBulk(data) {
      this.showCloneItemBulk = true
      this.dataModal = { dataInput: data }
    },
    updateShow(name, event) {
      this[name] = false
    },
    confirmCloneItemBulk({ dataInput: data }) {
      const folders = []
      const assets = []

      data.forEach((obj, index) => {
        if (obj.type === 'folder') {
          folders.push(obj.id)
        } else {
          assets.push(obj)
        }
      })
      this.loopItem.slide_asets = this.loopItem.slide_asets.concat(assets)
      this.resetNestedListArrayinFolder()
      this.resetShowEditDuration(this.loopItem.slide_asets)

      const ids = {
        folders: folders
      }
      this.showCloneItemBulk = false

      this.getMultipleAssetInFolder({
        folder_ids: ids.folders,
        orientation: this.currentOrientation
      })
    },
    reloadPage() {
      this.getLoopAssetsByID()
      this.isNewUpdatedBySomeOne = false
    },
    reloadListItems() {
      this.getListAsset(this.pagination.page, this.limitPage.value)
      this.loopItem.slide_asets = this.loopItem.slide_asets.filter(
        (slide) => !this.removedAssets.some((asset) => asset.id === slide.id)
      )
      this.isAssetDeleted = false
      this.removedAssets = []
    },
    updateAsset(data) {
      const index = findIndex(this.loopItem.slide_asets, (item) => {
        return !!data.slide_assets.find((p) => p.id === item.id)
      })
      if (index !== -1) {
        this.loopItem.slide_asets[index] = {
          ...this.loopItem.slide_asets[index],
          displayname: data.name,
          template_type: data.type,
          thumb_url: data.thumb_link,
          slide_template_id: data.default_slide_template,
          default_hook_key: data.default_hook_key
        }
      } else {
        const newAssetIndex = this.loopItem.slide_asets.findIndex((item) => item.id === data.id)
        if (newAssetIndex !== -1)
          this.loopItem.slide_asets[newAssetIndex] = {
            id: data.id,
            displayname: data.name,
            template_type: data.type,
            duration: this.loopItem.slide_asets[newAssetIndex].duration,
            thumb_url: data.thumb_link,
            slide_template_id: data.default_slide_template,
            default_hook_key: data.default_hook_key
          }
      }
    }
  },
  computed: {
    ...mapGetters('loopdetail', {
      isEmpty: 'isEmpty',
      items: 'items',
      loading: 'loading',
      treeFolder: 'treeFolder',
      breadcrumb: 'breadcrumb',
      pagination: 'pagination',
      total: 'totalPagination',
      page: 'pagePagination',
      limit: 'limitPagination',
      loopId: 'loopId',
      statistical: 'statistical',
      nested_items: 'nested_items',
      loadingAssets: 'loadingAssets'
    }),
    nodeLoop: {
      get: function () {
        return this.loopItem.slide_asets
      },
      set: function (value) {
        this.loopItem.slide_asets = value
      }
    },
    durationRender() {
      return this.loopItem.slide_asets.reduce((acc, cur) => acc + Number(cur.duration), 0)
    },
    optionAssests() {
      return this.listOptionAsset()
    }
  },

  created() {
    window.addEventListener('beforeunload', (e) => {
      e.preventDefault()
      if (JSON.stringify(this.loopItem.slide_asets) !== JSON.stringify(this.firstDataLoopEditor)) {
        e.returnValue = ''
      }
    })
    this.setLoopId(this.$route.params.loopId)
  },

  channels: {
    LibraryChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (data.action === 'create') {
          if (this.currentOrientation === data.data.orientation) {
            this.updateCountAsset(data.data.type)
            this.createFromSocket({ currentFolderID: this.folderData.id, data: data.data })
          }
        } else if (data.action === 'update') {
          if (this.currentOrientation === data.data.orientation) {
            this.updateCountAsset(data.data.type, 'delete')
            this.deleteFromSocket({ currentFolderID: this.folderData.id, data: data.data })
            this.updateCountAsset(data.data.type)
            this.createFromSocket({ currentFolderID: this.folderData.id, data: data.data })
          } else {
            this.updateCountAsset(data.data.type, 'delete')
            this.deleteFromSocket({ currentFolderID: this.folderData.id, data: data.data })
          }
          this.updateAsset(data.data)
        } else if (data.action === 'delete') {
          if (this.loopItem.slide_asets.some((slide) => slide.id === data.data.id)) {
            this.isAssetDeleted = true
            this.removedAssets.push(data.data)
          }
          this.deleteFromSocket({ currentFolderID: this.folderData.id, data: data.data })
        }
      },
      disconnected() {}
    },
    LoopChannel: {
      connected() {},
      rejected() {},
      received(data) {
        this.isNewUpdatedBySomeOne =
          this.user.id !== data.user_id && parseInt(this.loopId) === data.data.id
        if (data.action === 'update') {
          this.updateFromSocket({ data: data.data })
        }
      },
      disconnected() {}
    }
  },

  mounted() {
    this.getUser()
    this.$cable.subscribe({
      channel: 'LibraryChannel',
      organization: this.user.organization_id
    })
    this.$cable.subscribe({
      channel: 'LoopChannel',
      organization: this.user.organization_id
    })
    if (this.$route.params.loopId !== 'id') {
      this.getListAsset(this.pagination.page, this.limitPage.value)
      this.getListTreeFolder()
      this.resetShowEditDuration(this.loopItem.slide_asets)
      this.getLoopAssetsByID()
      this.getSlideDuration()
    }
    window.addEventListener('click', function (e) {
      const assetDurationAll = document.querySelectorAll('.asset-duration')
      assetDurationAll.forEach((el) => {
        el.style.display = 'none'
      })
      const displayDurationAll = document.querySelectorAll('.display-duration')
      displayDurationAll.forEach((el) => {
        el.style.display = 'block'
      })

      if (e.target.className === 'asset-duration') {
        e.target.style.display = 'block'
        e.target.addEventListener('keyup', function (event) {
          if (event.key === 'Enter') {
            event.target.style.display = 'none'
          }
        })
      } else if (e.target.className === 'display-duration') {
        e.target.style.display = 'none'
        const assetDuration = e.target.parentElement.querySelector('.asset-duration')
        this.currentAssetEl = null
        this.currentAssetEl = assetDuration
        this.currentAssetEl.style.display = 'block'
      } else {
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    if (JSON.stringify(this.loopItem.slide_asets) !== JSON.stringify(this.firstDataLoopEditor)) {
      const answer = window.confirm('Changes you made may not be saved')
      if (answer) {
        window.onbeforeunload = null
        next()
      } else {
        next(false)
      }
    } else {
      window.onbeforeunload = null
      next()
    }
  },
  beforeDestroy() {
    this.$cable.unsubscribe('LibraryChannel')
    this.$cable.unsubscribe('LoopChannel')
    this.setDefaultBreadcrumb()
  }
}
</script>
<style lang="scss" scoped>
.select-btn {
  line-height: 20px;
}
.cantact-number {
  font-size: 22px;
  font-weight: 600;
  color: #2c7fe1;
}
.player-name {
  color: #2c7fe1;
}
.button-create {
  padding: 0px !important;
  p {
    margin: 0 !important;
    padding: 0 !important;
    height: 100% !important;
    width: 50%;
    background: #e3e9ee;
    text-align: center;
    line-height: 50px;
    vertical-align: middle;
    cursor: pointer;
    color: #b4c3ce;
    &.active {
      background-color: white;
      color: #0074d2;
    }
  }
}
[type='text'] {
  width: 120;
}

.content-page-loop {
  .content {
    width: calc(100% - 440px);
  }
  #tree-view {
    width: 425px;
    grid-template-rows: min-content min-content auto;
    &.loop-editor {
      grid-template-rows: min-content auto min-content;
    }
  }
}

.hidden {
  display: none;
}

.dragndrop-loop {
  width: 100%;
  height: calc(100vh - 230px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: auto;
  &-name {
    margin: 10px 5px 0;
    font-weight: 700;
    color: #00325b;
  }
  &-time {
    margin: 10px 5px 0;
    font-weight: 700;
    color: black;
  }
  &-dragArea {
    height: calc(100%);
    margin: 10px;
    overflow: auto;
    min-height: initial !important;
    &-empty {
      background-color: #f9f8f9;
      border: 1px solid #e1e1e1;
      text-align: center;
      margin-bottom: 10px;
      p {
        font-size: 11px;
        font-style: italic;
        margin: 20px auto;
        text-align: center;
        color: black;
      }
      img {
        margin: 0px auto 20px auto;
      }
    }
  }
  &-action-bottom {
    height: 50px;
    margin: 0px 0px;
    padding-bottom: 15px;
    background-color: #fff;
  }
}

.btn-custom-save {
  background-color: #2c7fe1;
  color: white;
  font-weight: 600;
  border: 1px solid #2c7fe1;
  padding: 6px;
  width: 115px;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  &:hover {
    color: #fff;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
}
.btn-custom-cancel {
  background-color: transparent;
  border: 1px solid #2c7fe1;
  color: #2c7fe1;
  font-weight: 600;
  padding: 6px;
  width: 115px;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  &:hover {
    color: #2c7fe1;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
}
.tab-content {
  width: 100%;
  height: 100%;
  .dragArea {
    width: 100%;
    height: 100%;
  }
}

.element-item-runtime {
  cursor: pointer;
  margin: 0 0 10px;
  display: flex;
  align-items: center;
  height: 65px;
  background-color: #f3f3f9;
  .fa-ellipsis-v {
    opacity: 0;
  }
  &:hover {
    .fa-ellipsis-v {
      opacity: 1;
    }
  }
  .detail-section {
    display: inline-block;
    width: 65%;
    //width: 49%;
    vertical-align: top;
    .background-img {
      display: inline-block;
      margin-left: 8px;
    }
  }
  .btn-custom-loop {
    background-color: transparent;
  }
  .img-name {
    .wrap-img {
      width: 50px;
      height: 40px !important;
      line-height: 50px !important;
      text-align: center;
      line-height: 52px;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
      img {
        height: 40px;
        width: 40px;
      }

      i {
        cursor: pointer;
        &.fa-instagram {
          color: #125688;
        }
        &.fa-folder-open {
          color: #80aece;
        }
      }
    }
    .portrait_img {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: auto;
        max-height: 50px;
        max-width: 40px;
      }
      .portrait_image {
        transform: rotate(-90deg);
      }
    }
    .name {
      display: inline-block;
      vertical-align: middle;
      .wrap-p {
        width: 77px !important;
        margin-left: 0px;
        margin-right: 0px;
        p {
          width: 160px !important;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-size: 12px;
          color: #4a4a4a;
          margin-left: 12px;
        }
      }
    }
  }
  .date-action {
    display: none;
  }
  .size-section {
    height: 40px;
    width: 40px;
    display: inline-block;
    &.runtime {
      display: flex;
      align-items: center;
      .icon-film,
      .icon-image,
      .icon-social {
        line-height: 40px;
        display: inline;
      }
      .size {
        display: inline;
        font-size: 11px;
        margin: 0px;
      }
    }
  }
  .sec {
    font-size: 11px;
    width: 47px;
    color: #878787;
    &:hover {
      color: #000;
      border-bottom: 1px dashed #000;
    }
  }
  .truncate {
    display: flex;
    align-items: center;
    line-height: 38px;
    width: 90px !important;
    height: 40px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    position: relative;

    .asset-duration {
      font-size: 11px;
      color: #878787;
      display: inline-block;
      text-align: center;
      height: 30px;
      border: 0;
      background-color: #fff;
      border: 1px solid #ced4da;
      width: 70px;
      outline: none;
      position: absolute;
      z-index: 100;
      display: none;
      -moz-user-select: text;
    }
    .display-duration {
      font-size: 11px;
      color: #878787;
      display: inline-block;
      text-align: center;
      height: 30px;
      background-color: transparent;
      border: none;
      width: 70px;
      outline: none;
      position: absolute;
      line-height: 30px;
    }
    .asset-duration-video {
      font-size: 11px;
      color: #878787;
      display: inline-block;
      text-align: center;
      height: 30px;
      border: 0;
      background-color: transparent;
      border: none;
      width: 70px;
      outline: none;
      position: absolute;
      line-height: 30px;
    }
  }
  .js-remove {
    float: right;
    width: 40px;
    height: 65px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@import './LoopPage.scss';
</style>

<style lang="scss">
#loop-detail {
  // end: mutilselect
  .dragndrop-loop-dragArea {
    .sortable-ghost {
      width: 100% !important;
      height: 65px !important;
      // background: #aaa;
      position: relative;
      margin-bottom: 10px;
      overflow: hidden;
      border-radius: 0.25rem;
      &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #aaa;
        z-index: 3;
      }
    }
  }

  .select-limit-page {
    min-width: 100px;
  }
}
.dragndrop-loop-name {
  word-break: break-all;
}
</style>
