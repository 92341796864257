<template>
  <div class="container-fluid">
    <div class="card-header top-bar">
      <div class="header-left">
        <div class="admin-search-box">
          <SfInputSearch
            placeholder="Search"
            v-debounce:500ms="searchInputChange"
            class="sf-input-search"
          />
        </div>
      </div>
      <div class="header-right">
        <button class="sf-primary-add" @click="showModalCreate()">Add New Post</button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 sf-table">
          <b-table
            :busy="loading"
            show-empty
            striped
            hover
            :items="items"
            :fields="fields"
            head-variant="none"
            table-variant="none"
            thead-class="custom-b-table"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>{{ $t('loading') }}</strong>
              </div>
            </template>
            <template v-slot:empty>
              <p class="d-flex justify-content-center">No data available in table</p>
            </template>
            <template v-slot:head(link)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(link)="field">
              <p>{{ field.item.link }}</p>
            </template>

            <template v-slot:head(description)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(description)="field">
              <p>{{ field.item.description }}</p>
            </template>

            <template v-slot:cell(action)="field">
              <button
                @click="shownModalEdit(field.item.id)"
                class="button-edit-table"
                title="Edit"
              ></button>
              <button
                @click="shownModalDelete(field.item.id)"
                class="button-delete-table"
                title="Delete"
              ></button>
            </template>
          </b-table>

          <div v-if="!isEmpty" class="my-3">
            <b-pagination
              class="sf-b-pagination"
              :total-rows="pagination.limit * pagination.total"
              :per-page="pagination.limit"
              @change="changePage"
              :limit="6"
              v-model="currentPage"
            />
          </div>
        </div>
      </div>
    </div>

    <SfModal
      :title="'Create New Post'"
      :icon="'fa fa-user'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalCreate"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Link</label>
                </div>
                <input v-model="newPost.post.link" placeholder="Link..." class="sf-input-text" />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newPost.post.link.required">
                Link required
              </div>
            </div>
          </div>

          <!--           <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Language</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="newPost.post.language"
                    :options="languageConfig"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
            </div>
          </div> -->

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Description</label>
                </div>
                <textarea
                  v-model="newPost.post.description"
                  placeholder="Description..."
                  class="sf-input-text"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.newPost.post.description.required">
                Description required
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalCreate = false">Cancel</button>
        <button class="sf-primary" @click="savePost()">Save</button>
      </div>
    </SfModal>
    <SfModal
      title="Edit Post"
      :icon="'fa fa-user'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingPostDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Link</label>
                </div>
                <input
                  v-model="currentPost.post.link"
                  placeholder="Link..."
                  class="sf-input-text"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.currentPost.post.link.required">
                Link required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingPostDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Language</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="currentPost.post.language"
                    :options="languageConfig"
                    @select="changeLanguage"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingPostDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Description</label>
                </div>
                <textarea
                  v-model="currentPost.post.description"
                  placeholder="Description..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentPost.post.description.required"
              >
                Description required
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalEdit = false">Cancel</button>
        <button class="sf-primary" @click="updatePost()">Save</button>
      </div>
    </SfModal>
    <SfDeleteModal :title="'Confirm Delete'" closeOnOverlay :show.sync="isShownModalDelete">
      <div class="content-delete">
        Deleting this post. Are you sure you want to delete this one?
      </div>
      <div class="footer-button">
        <button class="sf-secondary" @click="isShownModalDelete = false">Cancel</button>
        <button class="sf-primary" @click="removePost()">OK</button>
      </div>
    </SfDeleteModal>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import SfModal from '@/components/SfModal.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import AdminPostService from '@/services/admin-post.service'
import { required } from 'vuelidate/lib/validators'
import SfInputSearch from '@/components/SfInputSearch.vue'
import Select2 from 'v-select2-component'
import { language } from '@/config'

export default {
  name: 'admin-posts',

  components: {
    SfModal,
    SfDeleteModal,
    SfInputSearch,
    Select2
  },
  data() {
    return {
      msg: 'Welcome to Index!!!',
      isShownModalCreate: false,
      isShownModalDelete: false,
      isShownModalEdit: false,
      submitted: false,
      currentPage: 1,
      languageConfig: language(),
      fields: [
        {
          key: 'link',
          sortable: true,
          label: 'Link',
          colType: 'link',
          thStyle: {
            width: '40%'
          }
        },
        {
          key: 'description',
          sortable: true,
          label: 'Description',
          colType: 'description',
          thStyle: {
            width: '40%'
          }
        },
        {
          key: 'ACTION',
          label: 'Actions',
          colType: 'button'
        }
      ],
      newPost: {
        post: {
          link: '',
          description: '',
          language: 'en',
          translations: []
        }
      },
      currentPost: {
        post: {
          link: '',
          description: '',
          language: 'en',
          translations: []
        }
      },
      defualtPost: {
        post: {
          link: '',
          description: '',
          language: 'en',
          translations: []
        }
      },
      dataSearch: '',
      loadingPostDetail: false
    }
  },
  validations: {
    newPost: {
      post: {
        link: {
          required
        },
        description: {
          required
        }
      }
    },
    currentPost: {
      post: {
        link: {
          required
        },
        description: {
          required
        }
      }
    }
  },
  methods: {
    fetchData(activePage, search) {
      this.$store.dispatch('adminpost/getAllPosts', { page: activePage, limit: 10, search })
    },
    showModalCreate() {
      this.submitted = false
      this.setDataPost(this.newPost.post, this.defualtPost.post)
      this.isShownModalCreate = true
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage, this.dataSearch)
    },
    savePost() {
      this.submitted = true
      if (this.$v.newPost.$invalid) {
        return
      }
      AdminPostService.createPost(this.newPost)
        .then((response) => {
          this.$toast.success('Post successfully created')
          this.fetchData(this.currentPage)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalCreate = false
    },
    shownModalEdit(post_id) {
      this.setDataPost(this.currentPost.post, this.defualtPost.post)
      this.submitted = false
      this.isShownModalEdit = true
      this.currentPostID = post_id
      this.getItemByID()
    },
    shownModalDelete(post_id) {
      this.isShownModalDelete = true
      this.currentPostID = post_id
    },
    getItemByID() {
      this.loadingPostDetail = true
      AdminPostService.detailPost(this.currentPostID)
        .then((response) => {
          this.setDataPost(this.currentPost.post, response.data)
          this.loadingPostDetail = false
        })
        .catch((err) => {
          this.isShownModalEdit = false
          this.$toast.error(err.message)
        })
    },
    setDataPost(post, res) {
      post.link = res.link
      post.description = res.description
      post.translations = res.translations
      post.language = 'en'
    },
    updatePost() {
      this.submitted = true
      if (this.$v.currentPost.$invalid) {
        return
      }
      AdminPostService.updatePost(this.currentPostID, this.currentPost)
        .then((response) => {
          this.$toast.success('Post successfully updated.')
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalEdit = false
    },
    changeLanguage(value) {
      const postOfLanguage = this.currentPost.post.translations.find(
        (option) => option.locale === value.id
      )
      if (postOfLanguage) {
        this.currentPost.post.description = postOfLanguage.description
      } else {
        this.currentPost.post.description = ''
      }
    },
    removePost() {
      AdminPostService.removePost(this.currentPostID)
        .then((response) => {
          this.$toast.success('Post successfully deleted.')
          if (this.items.length === 1 && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1
          }
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalDelete = false
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    }
  },
  computed: {
    ...mapState('adminpost', {
      items: 'items',
      pagination: 'pagination',
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('adminpost', ['isEmpty'])
  },

  mounted() {
    this.fetchData(this.currentPage)
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    })
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
  }
}
</script>

<style lang="scss" scoped>
@import '../../Adminpage.scss';

.text-area-control {
  padding: 6px 0px;
  width: 90%;
  border: none;
  outline: none;
  height: 100px;
  max-height: 150px;
  margin: 1px;
}

.custom-input-group-text {
  align-items: flex-start;
}

.input-group-text-area {
  height: auto !important;
}
</style>
