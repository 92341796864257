export default {
  GET_ALL_LANGUAGES(state, data) {
    state.items = data.items
  },
  SET_ERROR(state, data) {
    state.error = data
  },
  SET_LOADING(state, data) {
    state.loading = data
  }
}
