<template>
  <div class="container-fluid">
    <div class="card-header top-bar">
      <div class="header-left">
        <div class="admin-search-box">
          <SfInputSearch
            placeholder="Search"
            v-debounce:500ms="searchInputChange"
            class="sf-input-search"
          />
        </div>
      </div>
      <div class="header-right">
        <button class="sf-primary-add" @click="showModalCreate()">Add New Player</button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="sf-table col-12">
          <b-table
            show-empty
            striped
            hover
            :items="allPlayerAdmin"
            :fields="fields"
            head-variant="none"
            table-variant="none"
            thead-class="custom-b-table"
            :busy="loading"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty>
              <p class="d-flex justify-content-center">No data available in table</p>
            </template>
            <template v-slot:head(status)="field">{{ field.label }}</template>

            <template v-slot:head(organization)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(organization)="field">
              <p v-if="field.item.organization">
                <router-link :to="`/admin/clients/organizations/${field.item.organization.id}`">{{
                  field.item.organization.name
                }}</router-link>
              </p>
            </template>

            <template v-slot:head(name)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(name)="field">
              <p>
                <router-link
                  :to="`/admin/clients/players/${field.item.id}`"
                  :class="{ 'text-color-grey-40': !field.item.activated }"
                  >{{ field.item.name }}</router-link
                >
              </p>
            </template>

            <template v-slot:head(hardware_type)="field">
              <span class="custome-arrows">Hardware</span>
            </template>
            <template v-slot:cell(hardware_type)="field">
              <p v-if="field.item.hardware_type">
                {{ field.item.hardware_type.displayname }}
              </p>
            </template>

            <template v-slot:head(running_campaign)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(running_campaign)="field">
              <p>{{ field.item.running_campaign }}</p>
            </template>

            <template v-slot:head(last_connection)="field">
              <span>{{ field.label }}</span>
              <span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(last_connection)="field">
              <p v-if="field.item.last_connection && field.item.activated">
                {{ timeSince(field.item.last_connection) }}
              </p>
            </template>

            <template v-slot:cell(actions)="field">
              <button
                @click="shownModalEdit(field.item.id, field.item.organization)"
                class="button-edit-table"
                title="Edit"
              ></button>
              <button
                @click="shownModalDelete(field.item.id)"
                class="button-delete-table"
                title="Delete"
              ></button>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div v-if="!isEmpty" class="my-3">
      <b-pagination
        class="sf-b-pagination"
        :total-rows="pagination.limit * pagination.total"
        :per-page="pagination.limit"
        @change="changePage"
        :limit="6"
        v-model="currentPage"
      />
    </div>

    <SfModal
      title="Add New Player"
      :icon="'fa fa-user'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalCreate"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Name</label>
                </div>
                <input
                  v-model="newAdminPlayer.player.name"
                  placeholder="Name..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newAdminPlayer.player.name.required"
              >
                Name required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newAdminPlayer.player.name.maxLength"
              >
                Use less than 50 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Hardware</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="newAdminPlayer.player.admin_hardware_type_id"
                    :options="listHardwareOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newAdminPlayer.player.admin_hardware_type_id.required"
              >
                Hardware required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Address</label>
                </div>
                <gmap-autocomplete
                  class="sf-input-text"
                  @place_changed="setAddressData"
                  placeholder="Address ..."
                ></gmap-autocomplete>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newAdminPlayer.player.location.maxLength"
              >
                Use less than 100 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Organization</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="newAdminPlayer.player.organization_id"
                    :options="organizationOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newAdminPlayer.player.organization_id.required"
              >
                Organization required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Time Zone</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    v-model="newAdminPlayer.player.time_zone_name"
                    :options="listTimezoneOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newAdminPlayer.player.time_zone_name.required"
              >
                Time Zone required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Description</label>
                </div>
                <textarea
                  v-model="newAdminPlayer.player.description"
                  placeholder="Description..."
                  class="sf-input-text"
                />
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Browser Cache</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="newAdminPlayer.player.is_browser_cache"
                    :sync="true"
                    :width="44"
                    :height="22"
                    :margin="2"
                    @change="onChangeToggleCacheBrowser"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Kiosk Mode</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="newAdminPlayer.player.is_kiosk_mode"
                    :sync="true"
                    :width="44"
                    :height="22"
                    :margin="2"
                    @change="onChangeToggleKioskMode"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Wifi Type</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="newAdminPlayer.player.is_wifi_type"
                    :sync="true"
                    :width="44"
                    :height="22"
                    :margin="2"
                    @change="onChangeToggleWifiType"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalCreate = false">Cancel</button>
        <button class="sf-primary" @click="createAdminPlayer">Save</button>
      </div>
    </SfModal>

    <SfModal
      title="Edit Player"
      :icon="'fa fa-user'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingPlayer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Name</label>
                </div>
                <input
                  v-model="updateAdminPlayer.player.name"
                  placeholder="Name..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.updateAdminPlayer.player.name.required"
              >
                Name required
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.updateAdminPlayer.player.name.maxLength"
              >
                Use less than 50 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingPlayer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Hardware</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="updateAdminPlayer.player.admin_hardware_type_id"
                    :options="listHardwareOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.updateAdminPlayer.player.admin_hardware_type_id.required"
              >
                Hardware required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingPlayer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Address</label>
                </div>
                <gmap-autocomplete
                  class="sf-input-text"
                  @place_changed="setAddressDataForEditPlayer"
                  placeholder="Address..."
                  :value="updateAdminPlayer.player.location"
                ></gmap-autocomplete>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.updateAdminPlayer.player.location.maxLength"
              >
                Use less than 100 characters
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingPlayer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Organization</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    @select="changeOrganization"
                    v-model="updateAdminPlayer.player.organization_id"
                    :options="organizationOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.updateAdminPlayer.player.organization_id.required"
              >
                Organization required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingPlayer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Rotate</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="updateAdminPlayer.player.rotate"
                    :options="rotate"
                    :settings="{ minimumResultsForSearch: -1 }"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.updateAdminPlayer.player.rotate.required"
              >
                Rotate required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingPlayer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Campaign</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="updateAdminPlayer.player.campaign_id"
                    :options="listCampaignByOrgOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingPlayer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Time Zone</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    v-model="updateAdminPlayer.player.time_zone_name"
                    :options="listTimezoneOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingPlayer">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Description</label>
                </div>
                <textarea
                  v-model="updateAdminPlayer.player.description"
                  placeholder="Description..."
                  class="sf-input-text"
                />
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Browser Cache</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="updateAdminPlayer.player.is_browser_cache"
                    :sync="true"
                    :width="44"
                    :height="22"
                    :margin="2"
                    @change="onChangeToggleUpdateCacheBrowser"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Kiosk Mode</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="updateAdminPlayer.player.is_kiosk_mode"
                    :sync="true"
                    :width="44"
                    :height="22"
                    :margin="2"
                    @change="onChangeToggleUpdateKioskMode"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Wifi Type</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="updateAdminPlayer.player.is_wifi_type"
                    :sync="true"
                    :width="44"
                    :height="22"
                    :margin="2"
                    @change="onChangeToggleUpdateWifiType"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalEdit = false">Cancel</button>
        <button class="sf-primary" @click="editAdminPlayer">Save</button>
      </div>
    </SfModal>

    <SfDeleteModal :title="'Confirm Delete'" closeOnOverlay :show.sync="isShownModalDelete">
      <div class="content-delete">
        Deleting this player. Are you sure you want to delete this one?
      </div>
      <div class="footer-button delete-confirm">
        <button class="sf-secondary" @click="isShownModalDelete = false">Cancel</button>
        <button class="sf-primary" @click="deleteAdminPlayer">OK</button>
      </div>
    </SfDeleteModal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SfModal from '@/components/SfModal.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import Select2 from 'v-select2-component'
import AdminPlayerService from '@/services/admin-player.service.js'
import { required, maxLength } from 'vuelidate/lib/validators'
import { timeSince } from '@/helper/helper'
import CountriesService from '@/services/countries.service'
import StatesService from '@/services/states.service'
import AdminHardwareService from '@/services/admin-hardware.service'
import SfInputSearch from '@/components/SfInputSearch.vue'

export default {
  name: 'admin-player',
  components: {
    SfModal,
    SfDeleteModal,
    Select2,
    SfInputSearch
  },
  data() {
    return {
      msg: 'Welcome to Index!!!',
      isShownModalCreate: false,
      isShownModalEdit: false,
      isShownModalDelete: false,
      currentPage: 1,
      submitted: false,
      currentPlayerID: '',
      listCountries: [],
      listStates: [],
      addressDataFormat: {},
      fields: [
        {
          key: 'name',
          sortable: true,
          colType: 'name',
          label: 'Name'
        },
        {
          key: 'hardware_type',
          sortable: true,
          colType: 'hardware-type',
          label: 'Hardware Type'
        },
        {
          key: 'organization',
          sortable: true,
          colType: 'organization',
          label: 'Organization'
        },
        {
          key: 'running_campaign',
          sortable: true,
          colType: 'running_campaign',
          label: 'Running Campaign',
          tdClass: 'running-campaign'
        },
        {
          key: 'last_connection',
          sortable: true,
          colType: 'last_connection',
          label: 'Last Connection'
        },
        {
          key: 'ACTIONS',
          colType: 'button',
          label: 'Actions',
          tdClass: 'edit-delete-button'
        }
      ],
      newAdminPlayer: {
        player: {
          name: 'New Player',
          location: '',
          lat: '',
          lng: '',
          organization_id: '',
          postal_zip: '',
          city: '',
          province_state: '',
          country: '',
          time_zone_name: 'UTC',
          description: 'No description set',
          is_browser_cache: true,
          is_kiosk_mode: false,
          is_wifi_type: false,
          admin_hardware_type_id: ''
        }
      },
      updateAdminPlayer: {
        player: {
          name: '',
          location: '',
          lat: '',
          lng: '',
          organization_id: '',
          postal_zip: '',
          city: '',
          province_state: '',
          country: '',
          time_zone_name: '',
          rotate: 'default',
          campaign_id: '',
          description: '',
          is_browser_cache: true,
          is_kiosk_mode: false,
          is_wifi_type: false,
          admin_hardware_type_id: ''
        }
      },
      defualtAdminPlayer: {
        player: {
          name: '',
          location: '',
          lat: '',
          lng: '',
          organization_id: '',
          postal_zip: '',
          city: '',
          province_state: '',
          time_zone_value: 'UTC',
          rotate: 'default',
          campaign_id: '',
          description: '',
          is_browser_cache: true,
          is_kiosk_mode: false,
          is_wifi_type: false,
          organization: {
            id: ''
          },
          running_campaign: {
            id: ''
          },
          admin_hardware_type: {
            id: ''
          }
        }
      },
      rotate: [
        {
          id: 'default',
          text: 'Default'
        },
        {
          id: 'flipped',
          text: 'Flipped'
        }
      ],
      dataSearch: '',
      currentOrgID: '',
      loadingPlayer: false,
      listHardwareOptions: [],
      hardwareTypeDefault: ''
    }
  },

  validations: {
    newAdminPlayer: {
      player: {
        name: {
          required,
          maxLength: maxLength(50)
        },
        location: {
          maxLength: maxLength(250)
        },
        organization_id: {
          required
        },
        time_zone_name: {
          required
        },
        admin_hardware_type_id: {
          required
        }
      }
    },
    updateAdminPlayer: {
      player: {
        name: {
          required,
          maxLength: maxLength(50)
        },
        location: {
          maxLength: maxLength(250)
        },
        organization_id: {
          required
        },
        time_zone_name: {
          required
        },
        rotate: {
          required
        },
        admin_hardware_type_id: {
          required
        }
      }
    }
  },

  methods: {
    timeSince,
    getAllPlayersAdmin(page, limit, search) {
      this.$store.dispatch('adminplayer/getAllPlayersAdmin', {
        page,
        limit,
        search
      })
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.currentPage = 1
      this.getAllPlayersAdmin(this.currentPage, this.pagination.limit, value)
    },
    changePage(page) {
      this.currentPage = page
      this.getAllPlayersAdmin(this.currentPage, this.pagination.limit, this.dataSearch)
    },
    shownModalEdit(playerID, org) {
      this.submitted = false
      this.setDataPlayer(this.updateAdminPlayer.player, this.defualtAdminPlayer.player)
      this.isShownModalEdit = true
      this.currentPlayerID = playerID
      this.currentOrgID = org ? org.id : ''
      this.getListOrganization()
      this.getListTimezone()
      this.getAllCampaigns(this.currentOrgID)
      this.getItemByID()
      this.getListCountries()
      this.getListStates()
    },

    getItemByID() {
      this.loadingPlayer = true
      AdminPlayerService.getDetailAdminPlayer(this.currentPlayerID)
        .then((response) => {
          this.loadingPlayer = false
          this.setDataPlayer(this.updateAdminPlayer.player, response.data)
        })
        .catch((err) => {
          this.isShownModalEdit = false
          this.$toast.error(err.message)
        })
    },
    setDataPlayer(player, new_player) {
      player.name = new_player.name
      player.location = new_player.location
      player.rotate = new_player.rotate
      player.time_zone_name = new_player.time_zone_value
      player.organization_id =
        new_player.organization && new_player.organization.id ? new_player.organization.id : ''
      player.campaign_id =
        new_player.running_campaign && new_player.running_campaign.id
          ? new_player.running_campaign.id
          : ''
      player.description = new_player.description
      player.is_browser_cache = new_player.is_browser_cache
      player.is_kiosk_mode = new_player.is_kiosk_mode
      player.is_wifi_type = new_player.is_wifi_type
      player.admin_hardware_type_id =
        new_player.hardware_type && new_player.hardware_type.id ? new_player.hardware_type.id : ''
    },
    showModalCreate() {
      this.submitted = false
      this.getListOrganization()
      this.getListTimezone()
      this.getListCountries()
      this.getListStates()
      this.setDataPlayer(this.newAdminPlayer.player, this.defualtAdminPlayer.player)
      this.newAdminPlayer.player.admin_hardware_type_id = this.hardwareTypeDefault
      this.isShownModalCreate = true
    },
    getListOrganization() {
      this.$store.dispatch('adminuser/getAllOrganizationNoPaging')
    },
    getListTimezone() {
      this.$store.dispatch('player/getListTimezone')
    },
    getListCountries() {
      CountriesService.getAllCountries()
        .then((res) => {
          this.listCountries = res.data
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
    },
    getListStates(country) {
      StatesService.getAllStates(country)
        .then((res) => {
          this.listStates = res.data
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
    },
    getListHardwareType() {
      AdminHardwareService.getAllHardwaresNoPaging()
        .then((res) => {
          this.listHardwareOptions = res.data
            .filter((hardwareType) => {
              return hardwareType.model_number !== 'all'
            })
            .map((hardwareType) => {
              if (hardwareType.default) this.hardwareTypeDefault = hardwareType.id
              return {
                id: hardwareType.id,
                text: hardwareType.displayname
              }
            })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
    },
    setAddressData(place) {
      const self = this
      this.formatAddress(place.address_components)
      this.newAdminPlayer.player.province_state = self.addressDataFormat.administrative_area_level_1
      this.newAdminPlayer.player.country = self.addressDataFormat.country
      this.newAdminPlayer.player.lat = place.geometry.location.lat()
      this.newAdminPlayer.player.lng = place.geometry.location.lng()
      this.newAdminPlayer.player.location = place.formatted_address
      this.newAdminPlayer.player.postal_zip = self.addressDataFormat.postal_code
        ? self.addressDataFormat.postal_code
        : ''
      this.newAdminPlayer.player.city = self.addressDataFormat.locality
        ? self.addressDataFormat.locality
        : ''
    },
    setAddressDataForEditPlayer(place) {
      const self = this
      this.formatAddress(place.address_components)
      this.updateAdminPlayer.player.country = self.addressDataFormat.country
      this.updateAdminPlayer.player.province_state =
        self.addressDataFormat.administrative_area_level_1
      this.updateAdminPlayer.player.lat = place.geometry.location.lat()
      this.updateAdminPlayer.player.lng = place.geometry.location.lng()
      this.updateAdminPlayer.player.location = place.formatted_address
      this.updateAdminPlayer.player.postal_zip = self.addressDataFormat.postal_code
        ? self.addressDataFormat.postal_code
        : ''
      this.updateAdminPlayer.player.city = self.addressDataFormat.locality
        ? self.addressDataFormat.locality
        : ''
    },
    createAdminPlayer() {
      this.submitted = true
      if (this.$v.newAdminPlayer.$invalid) {
        return
      }
      AdminPlayerService.createAdminPlayer(this.newAdminPlayer)
        .then((_) => {
          this.$toast.success('Player successfully created')
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
        .finally(() => {
          this.isShownModalCreate = false
        })
    },
    getAllCampaigns(orgID) {
      this.$store.dispatch('adminplayer/getCampaignByOrg', orgID)
    },
    editAdminPlayer() {
      this.submitted = true
      if (this.$v.updateAdminPlayer.$invalid) {
        return
      }
      AdminPlayerService.updateAdminPlayer(this.currentPlayerID, this.updateAdminPlayer)
        .then((_) => {
          this.$toast.success('Player successfully updated')
          this.getAllPlayersAdmin(this.currentPage, this.pagination.limit, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
        .finally(() => {
          this.isShownModalEdit = false
        })
    },
    changeLocation(e) {
      this.updateAdminPlayer.player.location = e
    },
    changeLocationNewAdminPlayer(e) {
      this.newAdminPlayer.player.location = e
    },
    deleteAdminPlayer() {
      AdminPlayerService.deleteAdminPlayer(this.currentPlayerID)
        .then((_) => {
          if (this.allPlayerAdmin.length === 1 && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1
          }
          this.$toast.success('Player successfully deleted')
          this.getAllPlayersAdmin(this.currentPage, this.pagination.limit, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
        .finally(() => {
          this.isShownModalDelete = false
        })
    },
    shownModalDelete(id) {
      this.currentPlayerID = id
      this.isShownModalDelete = true
    },
    formatAddress(address) {
      const components = {}
      address.map((key, index) => {
        key.types.map((key1, index1) => {
          components[key1] = key.long_name
        })
      })
      this.addressDataFormat = components
    },
    changeOrganization(org) {
      this.getAllCampaigns(org.id)
      this.updateAdminPlayer.player.campaign_id = ''
    },

    onChangeToggleCacheBrowser(value) {
      this.newAdminPlayer.player.is_browser_cache = value.value
    },
    onChangeToggleKioskMode(value) {
      this.newAdminPlayer.player.is_kiosk_mode = value.value
    },
    onChangeToggleUpdateCacheBrowser(value) {
      this.updateAdminPlayer.player.is_browser_cache = value.value
    },
    onChangeToggleUpdateKioskMode(value) {
      this.updateAdminPlayer.player.is_kiosk_mode = value.value
    },
    onChangeToggleWifiType(value) {
      this.newAdminPlayer.player.is_wifi_type = value.value
    },
    onChangeToggleUpdateWifiType(value) {
      this.updateAdminPlayer.player.is_wifi_type = value.value
    }
  },
  computed: {
    ...mapState('adminplayer', {
      allPlayerAdmin: 'allPlayerAdmin',
      pagination: 'pagination',
      loading: 'loading',
      error: 'error'
    }),
    ...mapGetters('adminplayer', {
      isEmpty: 'isEmpty',
      listCampaignByOrgOptions: 'listCampaignByOrgOptions'
    }),
    ...mapState('adminuser', {
      organizationOptions: 'organizationOptions'
    }),
    ...mapState('player', {
      listTimezone: 'listTimezone'
    }),
    ...mapGetters('player', {
      listTimezoneOptions: 'listTimezoneOptions',
      listCampaignOptions: 'listCampaignOptions'
    })
  },
  mounted() {
    this.getAllPlayersAdmin(this.currentPage, this.pagination.limit)
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.currentPage = 1
      this.getAllPlayersAdmin(this.currentPage, this.pagination.limit, value)
    })
    this.getListHardwareType()
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
  }
}
</script>

<style lang="scss" scoped>
@import '../../Adminpage.scss';
.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  height: 35px !important;
}
.text-area-control {
  padding: 12px 0px;
  width: 90%;
  border: none;
  outline: none;
  height: 100px;
  max-height: 150px;
  min-height: 37px;
  margin: 1px;
}

.input-group-text-area {
  height: auto !important;
  .custom-input-group-text {
    align-items: flex-start;
    .input-group-text {
      height: 37px !important;
    }
  }
}
::v-deep .edit-delete-button {
  display: flex;
}
::v-deep .running-campaign {
  word-break: break-all;
  width: 20%;
}
</style>
