import state from './adminPlayerState'
import mutations from './adminPlayerMutations'
import getters from './adminPlayerGetters'
import actions from './adminPlayerActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
