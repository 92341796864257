export default {
  loading: false,
  error: false,
  loadingLoop: false,
  errorLoop: false,
  loadingCampaign: false,
  errorCampaign: false,
  playerCampaign: false,
  errorPlayer: false,
  items: [],
  loopItems: [],
  campaignItems: [],
  playerItems: [],
  treeFolder: [],
  treeFolderBefore: [],
  smartAssetDataModal: {},
  editAssetIds: [],
  breadcrumb: [],
  pagination: {
    limit: 10,
    page: 1,
    total: -1
  },
  loopPagination: {
    limit: 5,
    page: 1,
    total: -1
  },
  campaignPagination: {
    limit: 5,
    page: 1,
    total: -1
  },
  playerPagination: {
    limit: 5,
    page: 1,
    total: -1
  },

  statistical: {},
  organizationId: ''
}
