import BaseService from './base.service'

class TermLatestService extends BaseService {
  get entity() {
    return 'term_users'
  }

  getTermLatestService() {
    return new Promise((resolve, reject) => {
      return this.request({
        auth: true
      })
        .get(`${this.entity}/latest`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  acceptTermLatesService() {
    return new Promise((resolve, reject) => {
      return this.request({
        auth: true
      })
        .post(`${this.entity}`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
}

export default new TermLatestService()
