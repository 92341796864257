import state from './campaignState'
import mutations from './campaignMutations'
import getters from './campaignGetters'
import actions from './campaignActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
