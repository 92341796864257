import BaseService from './base.service'

class UsersService extends BaseService {
  get entity() {
    return 'users'
  }

  getCurrent() {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/profile`)
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  refreshcurrentUser() {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/refresh_current_user`)
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getPostsByUserId(user_id = window.required()) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/${user_id}/posts`)
        .then((response) => {
          const data = {
            content: response.data.data,
            total: response.data.data.total ? response.data.data.total : ''
          }
          resolve(this.responseWrapper(response, data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getAllUser(page, limit, search) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(
          search
            ? `${this.entity}/all_users?page=${page}&limit=${limit}&s=${search}`
            : `${this.entity}/all_users?page=${page}&limit=${limit}`
        )
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getInfoUserById(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/${id}/user_detail`)
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getListUserSubType() {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/list_user_sub_type`)
        .then((response) => resolve(this.responseWrapper(response, response.data.data)))
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  editUser(id, data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .patch(`${this.entity}/${id}/update_user`, data)
        .then((response) => resolve(this.responseWrapper(response, response.data.data)))
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  addUser(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/create_user`, data)
        .then((response) => resolve(this.responseWrapper(response, response.data.data)))
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  updateProfileUser(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .patch(`${this.entity}/update_profile`, data)
        .then((response) => resolve(this.responseWrapper(response, response.data.data)))
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  deleteUser(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .delete(`${this.entity}/${id}/delete_user`)
        .then((response) => resolve(this.responseWrapper(response, response.data.data)))
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  checkEmailExist(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/check_email`, data)
        .then((response) => resolve(this.responseWrapper(response, response.data.data)))
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  setup2Fa(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/setup_2fa`)
        .then((response) => resolve(this.responseWrapper(response, response.data.data)))
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  disable2Fa(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/disable_2fa`)
        .then((response) => resolve(this.responseWrapper(response, response.data.data)))
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  enable2Fa(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/enable_2fa`, data)
        .then((response) => resolve(this.responseWrapper(response, response.data.data)))
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  updateGuided() {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .patch(`${this.entity}/update_is_guided`)
        .then((response) => resolve(this.responseWrapper(response, response.data.data)))
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
}

export default new UsersService()
