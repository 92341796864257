<template>
  <div class="login">
    <div id="large-header" class="large-header">
      <canvas id="demo-canvas"></canvas>
      <div class="main">
        <div class="panel-login">
          <div class="logo">
            <img :src="logo" alt="logo" />
            <p class="title-login">{{ $t('login-with-sso') }}</p>
          </div>
          <div class="login-form">
            <div v-if="withEmail" class="row mt-3">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sm">
                    <label class="primary-label">{{ $t('email') }}</label>
                  </div>
                  <div class="w-100">
                    <input
                      id="email"
                      class="sf-input-text"
                      type="text"
                      v-model="email"
                      v-on:keyup.enter="requestLoginSso"
                    />
                  </div>
                </div>
                <div class="switch-type text-right">
                  <a href="javascript:void(0)" @click="loginWithSlug">{{ $t('no-account-yet') }}</a>
                </div>
              </div>
            </div>

            <div v-else class="row">
              <div class="col-12">
                <div class="sf-form-group">
                  <div class="sf-label-group sm">
                    <label class="primary-label">{{ $t('organization') }}</label>
                  </div>
                  <div class="w-100">
                    <input
                      id="email"
                      class="sf-input-text"
                      type="text"
                      v-model="slug"
                      v-on:keyup.enter="requestLoginSso"
                    />
                  </div>
                </div>
                <div class="switch-type text-right">
                  <a href="javascript:void(0)" @click="loginWithEmail"
                    >{{ $t('sso-with-email') }}?</a
                  >
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="switch-type text-right">
                  <router-link class="forgot-password" :to="{ path: `/login` }">{{
                    $t('login-with-password')
                  }}</router-link>
                </div>
              </div>
            </div>

            <!-- <div class="form-group" v-if="withEmail">
            <label for="email">Email</label>
            <input id="email" class="form-control" type="text" v-model="email" v-on:keyup.enter="requestLoginSso">
            <div class="switch-type">
              <a href="javascript:void(0)" @click="loginWithSlug">No account yet?</a>
            </div>
          </div>
          <div class="form-group" v-else >
            <label>Organization</label>
            <input id="email" class="form-control" type="text" v-model="slug" v-on:keyup.enter="requestLoginSso">
            <div class="switch-type">
              <a href="javascript:void(0)" @click="loginWithEmail">SSO with email?</a>
            </div>
          </div> -->
            <!-- <div class="sso-link">
            <router-link class="forgot-password" :to="{path:  `/login`}">Login with password</router-link>
          </div> -->
          </div>

          <div class="buttons">
            <button class="sf-primary" @click="requestLoginSso">
              <div class="text" v-if="!isLoading">
                {{ $t('login-with-sso') }}
              </div>
              <div v-else class="spinner-border" role="status">
                <span class="sr-only">{{ $t('loading') }}...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as authService from '../services/auth.service'
import logo from '@/assets/images/logo.svg'
import { SITE_DOMAIN } from '../.env'

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      logo,
      isLoading: false,
      withEmail: true,
      slug: ''
    }
  },
  methods: {
    requestLoginSso() {
      this.isLoading = true
      authService
        .requestLoginSso({
          email: this.email,
          slug: this.slug
        })
        .then((response) => {
          location.href = SITE_DOMAIN + 'auth/sso/' + response.data.data.slug
        })
        .catch((error) => {
          this.isLoading = false
          this.$toast.error(
            error.status === 404 ? 'User with same email not found' : error.response.data.message
          )
        })
    },
    loginWithEmail() {
      this.withEmail = true
      this.slug = ''
    },
    loginWithSlug() {
      this.withEmail = false
      this.email = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../assets/images/app-background.jpg') no-repeat #2d494d;
  background-size: cover;
  background-position: center center;
  .main {
    background: #fff;
    width: 100%;
    position: relative;
    max-width: 610px;
    height: 320px;
    border-radius: 2px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12);
    .panel-login {
      width: 450px;
      height: 100%;
      float: left;
      background-color: #ffffff;
      padding: 0 30px;
      .logo {
        width: 100%;
        text-align: center;
        margin: 40px 0 40px;
        img {
          width: 230px;
        }
        .title-login {
          margin: 10px;
          font-size: 18px;
          font-weight: 600;
        }
      }

      .login-form {
        margin-bottom: 10px;
        .switch-type {
          margin-top: 10px;
        }
        .sso-link {
          a {
            display: block;
          }
        }
      }

      .form-group {
        margin-bottom: 0px;
        label {
          font-size: 14px;
          color: #4a4a4a;
          font-weight: 600;
        }
      }
    }
    .header {
      text-align: center;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      .btn-login {
        width: 150px;
        height: 40px;
        border-radius: 20px;
        box-shadow: 0 2px 4px 0 rgba(159, 214, 100, 0.6);
        background-color: #76c322;
        border: none;
        font-size: 14px;
        color: #fff;
      }
    }

    .error {
      background-color: red;
      padding: 10px;
      font-size: 12px;
      opacity: 1;
      transition: all 0.5s;
    }
  }
}
</style>
