<template>
  <div class="container-fluid">
    <div class="header-campaign">
      <h2>Player</h2>
    </div>
    <div class="sf-tabs-wrap">
      <ul class="sf-tabs">
        <li>
          <a @click="changeTab('overview')" :class="{ active: statusTab === 'overview' }"
            >Overview</a
          >
        </li>
        <li>
          <a @click="changeTab('diagnos')" :class="{ active: statusTab === 'diagnos' }"
            >Diagnostics</a
          >
        </li>
        <li><a @click="changeTab('bugs')" :class="{ active: statusTab === 'bugs' }">Bugsnag</a></li>
      </ul>
    </div>
    <div v-if="statusTab === 'overview'" class="mt-3">
      <div class="row">
        <div class="col-12">
          <div v-if="loading" class="w-25">
            <content-placeholders>
              <content-placeholders-text :lines="1" />
            </content-placeholders>
          </div>
          <div v-else class="list-button-detail">
            <toggle-button
              :value="itemsByID.activated"
              :sync="true"
              @change="onChangeToggle"
              :width="44"
              :height="22"
              :margin="2"
            />
            <span class="ml-2">Active</span>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- left overview -->
        <div class="col-6">
          <div class="sf-card">
            <div class="card-heading">
              <p>Player</p>
            </div>
            <div class="sf-card-body editable">
              <div class="row">
                <div class="col-12 mb-3">
                  <button @click="showModalEditInfo" class="button-edit-pen"></button>
                  <p>
                    <span class="sf-text-strong mr-2">Player Name</span>
                    <content-placeholders v-if="loading">
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <span v-else>{{ itemsByID.name }}</span>
                  </p>
                </div>

                <div class="col-12 mb-3">
                  <content-placeholders v-if="loading">
                    <content-placeholders-text :lines="1" />
                  </content-placeholders>
                  <p v-else class="description">{{ itemsByID.description }}</p>
                </div>

                <div class="col-12 mb-3">
                  <p>
                    <span class="sf-text-strong mr-2">Assigned Campaign</span>
                    <content-placeholders v-if="loading">
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <span
                      v-else
                      @click="goToEditCampaignPage(itemsByID.campaign_code)"
                      class="custom-text-link"
                    >
                      {{
                        itemsByID &&
                        itemsByID.running_campaign &&
                        itemsByID.running_campaign.displayname
                      }}
                    </span>
                  </p>
                </div>

                <div class="col-12 mb-3">
                  <p>
                    <span class="sf-text-strong mr-2">Orientation</span>
                    <content-placeholders v-if="loading">
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <span v-else>{{ itemsByID.orientation }}</span>
                  </p>
                </div>

                <div class="col-12 d-flex align-items-center mb-3">
                  <div class="d-inline sf-text-strong mr-2">Last Connected</div>
                  <div v-if="loading" class="d-inline-block">
                    <content-placeholders>
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                  </div>
                  <div class="d-inline-block" v-else>
                    <div
                      v-if="itemsByID.last_connection"
                      class="d-flex align-items-center last-connect"
                    >
                      <div
                        v-if="
                          itemsByID.diagnostics &&
                          itemsByID.diagnostics.connection_type === 'Ethernet'
                        "
                      >
                        <img class="wifi-img" src="@/assets/images/ethernet.svg" />
                      </div>
                      <div
                        v-else-if="
                          itemsByID.diagnostics && itemsByID.diagnostics.connection_type === 'WiFi'
                        "
                      >
                        <img class="wifi-img" src="@/assets/images/wifi.svg" />
                      </div>
                      <p class="ml-2" id="popover-target-1">
                        {{ timeSince(itemsByID.last_connection) }}
                      </p>
                      <b-popover target="popover-target-1" triggers="hover" placement="top">
                        <div class="row">
                          <div class="col-4">
                            <label>UTC:</label>
                            <label>Timezone:</label>
                          </div>
                          <div class="col-8">
                            <label>
                              {{
                                moment
                                  .tz(itemsByID.last_connection, 'UTC')
                                  .format('YYYY-MM-DD hh:mm:ss z')
                              }}
                            </label>
                            <label>
                              {{
                                moment
                                  .tz(itemsByID.last_connection, itemsByID.time_zone_name)
                                  .format('YYYY-MM-DD hh:mm:ss Z')
                              }}
                            </label>
                          </div>
                        </div>
                      </b-popover>
                    </div>
                  </div>
                </div>

                <div class="col-12 mb-3">
                  <p>
                    <span class="sf-text-strong mr-2">Rotation</span>
                    <content-placeholders v-if="loading">
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <span v-else>{{ itemsByID.rotate }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="sf-card">
            <div class="card-heading">
              <p>Authentication</p>
            </div>
            <div class="sf-card-body">
              <div class="row">
                <div class="col-8">
                  <div class="mb-3">
                    <span class="sf-text-strong mr-2">Player ID</span>
                    <!-- Player ID -->
                    <content-placeholders v-if="loading">
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <span v-else>{{ itemsByID.player_code }}</span>
                  </div>
                  <div class="mb-3">
                    <span class="sf-text-strong mr-2">Access Code</span>
                    <content-placeholders v-if="loading">
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <span v-else>{{ itemsByID.access_code }}</span>
                  </div>
                </div>
                <div class="col-4">
                  <div class="mt-2 d-flex justify-content-end">
                    <button class="sf-primary" @click="showModalResetPlayer">Reset Player</button>
                  </div>
                  <div class="mt-2 d-flex justify-content-end">
                    <button class="sf-primary" @click="showModalCheckStatus">Check Status</button>
                  </div>
                  <div class="mt-2 d-flex justify-content-end">
                    <button class="sf-primary" @click="showModalReboot">Reboot Player</button>
                  </div>
                  <div class="mt-2 d-flex justify-content-end">
                    <button class="sf-primary" @click="showModalScreenshot">Screenshot</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- right overview -->
        <div class="col-6">
          <div class="sf-card">
            <div class="card-heading">
              <p>Local Address and Time</p>
            </div>
            <div class="sf-card-body editable">
              <div class="row">
                <div class="col-12">
                  <p>The current address on file for the organization</p>
                  <button @click="showModalEditLocation" class="button-edit-pen"></button>
                </div>
                <div class="col-12 mt-3">
                  <span class="sf-text-strong mr-2">Address</span>
                  <content-placeholders v-if="loading">
                    <content-placeholders-text :lines="1" />
                  </content-placeholders>
                  <span v-else>{{ itemsByID.location }}</span>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  <div class="mb-3">
                    <span class="sf-text-strong mr-2">Country</span>
                    <span>{{ itemsByID.country }}</span>
                  </div>
                  <div class="mb-3">
                    <span class="sf-text-strong mr-2">City</span>
                    <span>{{ itemsByID.city }}</span>
                  </div>
                  <div class="mb-3">
                    <span class="sf-text-strong mr-2">Latitude</span>
                    <span>{{ itemsByID.lat }}</span>
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <span class="sf-text-strong mr-2">Province/State</span>
                    <span>{{ itemsByID.province_state }}</span>
                  </div>
                  <div class="mb-3">
                    <span class="sf-text-strong mr-2">Postal/Zip</span>
                    <span>{{ itemsByID.postal_zip }}</span>
                  </div>

                  <div class="mb-3">
                    <span class="sf-text-strong mr-2">Longitude</span>
                    <span>{{ itemsByID.lng }}</span>
                  </div>
                </div>
              </div>

              <!-- Maps -->
              <div v-if="loading" class="maps">
                <content-placeholders>
                  <content-placeholders-img />
                </content-placeholders>
              </div>
              <div v-else class="maps">
                <div class="clock">
                  <img src="@/assets/images/clock.svg" />
                  <p>{{ timesOfClock }}</p>
                </div>
                <gmap-map
                  :options="{
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    disableDefaultUi: false
                  }"
                  :center="center"
                  :zoom="12"
                  style="width: 100%; height: 200px; margin-top: 10px"
                >
                  <gmap-marker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    @click="center = m.position"
                  ></gmap-marker>
                </gmap-map>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="statusTab === 'diagnos'" class="mt-3">
      <Dianogstics
        :dataDiagnostics="itemsByID.diagnostics"
        :currentPlayerID="currentPlayerID"
        :special="itemsByID.special"
        :show.sync="statusTab"
      >
      </Dianogstics>
    </div>

    <div v-if="statusTab === 'bugs'" class="mt-3">
      <Bugsnag :currentPlayerID="currentPlayerID" :show.sync="statusTab"> </Bugsnag>
    </div>
    <SfModal title="Edit Player" :width="768" closeOnOverlay :show.sync="isShownModalEditInfo">
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Name</label>
                </div>
                <input v-model="updatePlayer.name" class="sf-input-text" />
              </div>
              <div class="invalid-error-text" v-if="submitted && !$v.updatePlayer.name.required">
                Name required.
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Pin</label>
                </div>
                <input v-model="updatePlayer.pin" class="sf-input-text" :type="'number'" />
              </div>
              <div class="invalid-error-text" v-if="submitted && !$v.updatePlayer.pin.required">
                Pin required.
              </div>
              <div class="invalid-error-text" v-if="submitted && !$v.updatePlayer.pin.minLength">
                Use 6 characters.
              </div>
              <div class="invalid-error-text" v-if="submitted && !$v.updatePlayer.pin.maxLength">
                Use 6 characters.
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Player Description</label>
                </div>
                <textarea v-model="updatePlayer.description" class="sf-input-text" />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Campaign</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="updatePlayer.campaign_id"
                    :options="listCampaignByOrgOptions"
                  />
                  <span class="lb-right caret have-lable"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Rotation</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="updatePlayer.rotate"
                    :options="[
                      { id: 'default', text: 'Default' },
                      { id: 'flipped', text: 'Flipped' }
                    ]"
                    :settings="{ minimumResultsForSearch: -1 }"
                  />
                  <span class="lb-right caret have-lable"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Reboot Time</label>
                </div>
                <div class="sf-timepicker-wrap">
                  <vue-timepicker
                    v-model="updatePlayer.reboot_time"
                    input-class="sf-timepicker"
                  ></vue-timepicker>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Reboot frequency</label>
                </div>
                <input
                  v-model="updatePlayer.reboot_frequency"
                  class="sf-input-text"
                  :type="'number'"
                />
              </div>
              <div
                class="invalid-error-text"
                v-if="submitted && !$v.updatePlayer.reboot_frequency.required"
              >
                Reboot frequency is required.
              </div>
              <div
                class="invalid-error-text"
                v-if="submitted && !$v.updatePlayer.reboot_frequency.minValue"
              >
                Reboot frequency less than 1
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalEditInfo = false">Cancel</button>
        <button class="sf-primary" @click="editInfoPlayer">Save</button>
      </div>
    </SfModal>

    <SfModal
      :title="'Edit Contact'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEditLocation"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Player Address</label>
                </div>
                <gmap-autocomplete
                  @place_changed="setAddressDataEditForm"
                  :value="updatePlayer.location"
                  class="sf-input-text"
                  placeholder="Address..."
                >
                </gmap-autocomplete>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Country</label>
                </div>
                <input
                  v-model="updatePlayer.country"
                  class="sf-input-text"
                  placeholder="Country..."
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Province/State</label>
                </div>
                <input
                  v-model="updatePlayer.province_state"
                  class="sf-input-text"
                  placeholder="Province/State"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">City</label>
                </div>
                <input v-model="updatePlayer.city" class="sf-input-text" placeholder="City" />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Postal zip</label>
                </div>
                <input
                  v-model="updatePlayer.postal_zip"
                  class="sf-input-text"
                  placeholder="Postal zip"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Latitude</label>
                </div>
                <input v-model="updatePlayer.lat" class="sf-input-text" placeholder="Latitude" />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Longtitude</label>
                </div>
                <input v-model="updatePlayer.lng" class="sf-input-text" placeholder="Longtitude" />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Timezone</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="updatePlayer.time_zone_value"
                    :options="listTimezoneOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalEditLocation = false">Cancel</button>
        <button class="sf-primary" @click="editLocationPlayer">Save</button>
      </div>
    </SfModal>
    <SfModal
      :title="'Confirm Join Organization'"
      :width="400"
      closeOnOverlay
      :show.sync="isShownModalConfirm"
    >
      <div class="content-delete">
        You have no permission campaign of
        {{ itemsByID.organization && itemsByID.organization.name }}. You should join
        {{ itemsByID.organization && itemsByID.organization.name }} before adding, editing or
        viewing. Are you sure you want to join?
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalConfirm = false">Cancel</button>
        <button class="sf-primary" @click="joinOrg">OK</button>
      </div>
    </SfModal>
    <SfModal
      :title="'Confirm Activated'"
      :width="400"
      closeOnOverlay
      :show.sync="isShownModalConfirmActive"
    >
      <div class="content-delete">Do you want change status activated of player?</div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalConfirmActive = false">Cancel</button>
        <button class="sf-primary" @click="activePlayer">OK</button>
      </div>
    </SfModal>
    <SfModal
      :title="'Confirm Reset Player'"
      :width="400"
      closeOnOverlay
      :show.sync="isShownModalConfirmResetPlayer"
    >
      <div class="content-delete">
        After reset, current device can't access this player. Do you want reset this player?
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalConfirmResetPlayer = false">Cancel</button>
        <button class="sf-primary" @click="resetPlayer">OK</button>
      </div>
    </SfModal>

    <SfModal
      :title="'Checking Connection'"
      :width="400"
      closeOnOverlay
      :show.sync="isShownModalConfirmCheckStatus"
    >
      <div class="content-delete">
        <div v-if="connected">Player is Online</div>
        <div v-else>
          <div v-if="timeCountDown > 0">
            <span class="countdown">{{ timeCountDown }}</span>
            <div class="loading">Connecting</div>
          </div>
          <div v-else>Player is Unresponsive</div>
        </div>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-primary" @click="isShownModalConfirmCheckStatus = false">OK</button>
      </div>
    </SfModal>
    <SfModal
      :title="'Confirm Reboot Player'"
      :width="400"
      closeOnOverlay
      :show.sync="isShownModalConfirmRebootPlayer"
    >
      <div class="content-delete">Reboot player?</div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalConfirmRebootPlayer = false">
          Cancel
        </button>
        <button class="sf-primary" @click="rebootPlayer">OK</button>
      </div>
    </SfModal>
    <SfModal
      :title="'Screenshot Player'"
      :width="400"
      closeOnOverlay
      :show.sync="isShownModalScreenshot"
    >
      <div class="content-delete">
        <div v-if="urlImageScreenshot">
          <a :href="urlImageScreenshot" target="_blank" download="abc.jpg">
            <div class="screenshot-img">
              <img class="icon-screenshot mb-3" :src="urlThumbImageScreenshot" />
            </div>
            Click to view screenshot.
          </a>
        </div>
        <div v-else class="loading">Waiting a few seconds</div>
      </div>
      <div class="footer-button mt-3">
        <button class="sf-primary" @click="isShownModalScreenshot = false">OK</button>
      </div>
    </SfModal>
  </div>
</template>
<script>
import Dianogstics from './Dianosgtics'
import Bugsnag from './Bugsnag'
import SfModal from '@/components/SfModal.vue'
import Select2 from 'v-select2-component'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { timeSince } from '@/helper/helper'
import AdminPlayerService from '@/services/admin-player.service.js'
import AdminOrganizationService from '@/services/admin-organization.service.js'
import { required, minLength, maxLength, minValue } from 'vuelidate/lib/validators'
import Vue from 'vue'
import moment from 'moment-timezone'
import screenshotIcon from '@/assets/images/get-screenshot.svg'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

export default {
  name: 'AdminPlayerView',
  components: {
    Dianogstics,
    Bugsnag,
    SfModal,
    Select2,
    VueTimepicker
  },
  data() {
    return {
      screenshotIcon,
      isShownModalEditInfo: false,
      isShownModalEditLocation: false,
      isShownModalConfirm: false,
      isShownModalConfirmActive: false,
      isShownModalConfirmResetPlayer: false,
      isShownModalConfirmCheckStatus: false,
      isShownModalConfirmRebootPlayer: false,
      isShownModalScreenshot: false,
      currentPlayerID: '',
      statusTab: 'overview',
      updatePlayer: {
        name: '',
        description: '',
        campaign_id: '',
        last_connection: '',
        orientation: '',
        rotate: '',
        location: '',
        country: '',
        province_state: '',
        city: '',
        postal_zip: '',
        lat: '',
        lng: '',
        time_zone_name: '',
        activated: '',
        pin: '',
        reboot_time: {
          HH: '00',
          mm: '00'
        },
        reboot_frequency: 1
      },
      addressDataFormat: {},
      timesOfClock: '',
      resetTime: '',
      submitted: false,
      isItsSelf: false,
      user: '',
      timeCountDown: 30,
      connected: false,
      countdown: '',
      urlImageScreenshot: '',
      urlThumbImageScreenshot: ''
    }
  },
  validations: {
    updatePlayer: {
      name: {
        required
      },
      pin: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
      reboot_frequency: {
        minValue: minValue(1),
        required
      }
    }
  },
  methods: {
    timeSince,
    ...mapActions('adminplayer', {
      getDetailAdminPlayer: 'getDetailAdminPlayer'
    }),
    ...mapMutations('adminplayer', {
      setPlayer: 'SET_DATA_PLAYER'
    }),
    showModalEditInfo() {
      this.isShownModalEditInfo = true
      this.setDataPlayer(this.updatePlayer, this.itemsByID)
      this.getAllCampaigns(this.itemsByID.organization.id)
      this.submitted = false
    },
    cancelModalDelete() {
      this.updateActive.player.activated = !this.updateActive.player.activated
      this.isShownModalConfirm = false
    },
    onChangeToggleDetail(value) {
      this.statusToggleDetail = value.value
      this.updateSpecial.player.special = value.value
      AdminPlayerService.updateAdminPlayer(this.playerAdminID, this.updateSpecial).then((_) => {
        this.$toast.success('Updated detail diagnostic logging successfully')
        this.isShownModalConfirm = false
      })
    },
    confirmChangeActive() {
      AdminPlayerService.updateAdminPlayer(this.playerAdminID, this.updateActive)
        .then((_) => {
          this.$toast.success('Changed status player successfully')
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
        .finally(() => {
          this.isShownModalConfirm = false
        })
    },
    showModalEditLocation() {
      this.isShownModalEditLocation = true
      this.addressDataFormat = {}
      this.setDataPlayer(this.updatePlayer, this.itemsByID)
      this.loadCountries()
      this.loadProvinceState(this.updatePlayer.country_id)
      this.getListTimezone()
    },
    changeTab(value) {
      this.statusTab = value
      if (this.statusTab === 'overview') {
        this.$bus.$emit('showSearchBox', false)
      }
    },
    setDataPlayer(newPlayer, currentPlayer) {
      newPlayer.name = currentPlayer.name
      newPlayer.description = currentPlayer.description
      newPlayer.campaign_id = currentPlayer.running_campaign ? currentPlayer.running_campaign.id : 0
      newPlayer.last_connection = currentPlayer.last_connection
      newPlayer.orientation = currentPlayer.orientation
      newPlayer.rotate = currentPlayer.rotate
      newPlayer.location = currentPlayer.location
      newPlayer.country = currentPlayer.country
      newPlayer.province_state = currentPlayer.province_state ? currentPlayer.province_state : ''
      newPlayer.city = currentPlayer.city
      newPlayer.postal_zip = currentPlayer.postal_zip
      newPlayer.lat = currentPlayer.lat
      newPlayer.lng = currentPlayer.lng
      newPlayer.time_zone_name = currentPlayer.time_zone_name
      newPlayer.time_zone_value = currentPlayer.time_zone_value
      newPlayer.activated = currentPlayer.activated
      newPlayer.pin = currentPlayer.pin
      newPlayer.reboot_time.HH = currentPlayer.reboot_hour
      newPlayer.reboot_time.mm = currentPlayer.reboot_minute
      newPlayer.reboot_frequency = currentPlayer.reboot_frequency
    },
    // set location for edit location
    loadCountries() {
      this.$store.dispatch('adminorganization/getListCountries')
    },
    loadProvinceState(country) {
      this.$store.dispatch('adminorganization/getListStates', country)
    },
    onChangeCountry(val) {
      this.updatePlayer.province_state = ''
      this.loadProvinceState(val)
    },
    setAddressDataEditForm(place) {
      const self = this
      this.formatAddress(place.address_components)
      this.updatePlayer.country = self.addressDataFormat.country
      this.updatePlayer.province_state = self.addressDataFormat.administrative_area_level_1
      this.updatePlayer.postal_zip = self.addressDataFormat.postal_code
        ? self.addressDataFormat.postal_code
        : self.addressDataFormat.postal_code
      this.updatePlayer.city = self.addressDataFormat.locality
        ? self.addressDataFormat.locality
        : ''
      this.updatePlayer.location = place.formatted_address
      this.updatePlayer.lat = place.geometry.location.lat()
      this.updatePlayer.lng = place.geometry.location.lng()
    },
    formatAddress(address) {
      const components = {}
      address.map((key, index) => {
        key.types.map((key1, index1) => {
          components[key1] = key.long_name
        })
      })
      this.addressDataFormat = components
    },
    getListTimezone() {
      this.$store.dispatch('player/getListTimezone')
    },
    editLocationPlayer() {
      const params = { player: { ...this.updatePlayer } }
      params.player.time_zone_name = this.updatePlayer.time_zone_value
      AdminPlayerService.updateAdminPlayer(this.currentPlayerID, params)
        .then((res) => {
          this.$toast.success('Update successfully')
          this.setPlayer(res.data)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
        .finally(() => {
          this.isShownModalEditLocation = false
        })
    },
    editInfoPlayer() {
      this.submitted = true
      if (this.$v.updatePlayer.$invalid) {
        return
      }
      const params = { player: { ...this.updatePlayer } }
      params.player.time_zone_name = this.updatePlayer.time_zone_value
      params.player.reboot_hour = params.player.reboot_time.HH
      params.player.reboot_minute = params.player.reboot_time.mm
      AdminPlayerService.updateAdminPlayer(this.currentPlayerID, params)
        .then((res) => {
          this.$toast.success('Update successfully')
          this.setPlayer(res.data)
          this.isShownModalEditInfo = false
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
        .finally(() => {
          this.isShownModalEditInfo = false
        })
    },
    getAllCampaigns(id) {
      this.$store.dispatch('adminplayer/getCampaignByOrg', id)
    },
    goToEditCampaignPage(id) {
      if (this.isItsSelf) {
        this.$router.push(`/campaigns/${id}/edit`)
      } else {
        this.isShownModalConfirm = true
      }
    },
    joinOrg() {
      AdminOrganizationService.joinOrganization(this.itemsByID.organization.id)
        .then((res) => {
          this.$bus.$emit('update_joins_organization', res.data)
          this.$toast.success('Joined Organization')
          // set cookies
          const tokenExpired = Vue.$cookies.get('tokenExpired')
          const remember = Vue.$cookies.get('rememberMe') === 'true'
          this.user.organization = res.data.organization.name
          if (tokenExpired && remember) {
            Vue.$cookies.set(
              'user',
              this.user,
              moment(tokenExpired).format('ddd, DD MMM YYYY HH:mm:ss ZZ')
            )
          } else {
            Vue.$cookies.set('user', this.user, 0)
          }
          this.isItself = true
          // go to campaign page
          this.$router.push(`/campaigns/${this.itemsByID.running_campaign.id}/edit`)
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
        .finally(() => {
          this.isShownModalConfirm = false
        })
    },
    getTime(value) {
      const oldTime = new Date()
      clearInterval(this.resetTime)
      this.resetTime = setInterval(() => {
        this.timesOfClock =
          this.moment(oldTime).tz(`${value}`) && this.moment().tz(`${value}`).format('HH:mm')
      }, 1000)
    },
    onChangeToggle(value) {
      this.isShownModalConfirmActive = true
      this.updatePlayer.activated = value.value
    },
    activePlayer() {
      AdminPlayerService.updateAdminPlayer(this.currentPlayerID, {
        player: {
          activated: this.updatePlayer.activated
        }
      })
        .then((res) => {
          this.$toast.success('Active player successfully')
          this.setPlayer(res.data)
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
        .finally(() => {
          this.isShownModalConfirmActive = false
        })
    },
    resetPlayer() {
      AdminPlayerService.updateAdminPlayer(this.currentPlayerID, {
        player: {
          uniqkey: null
        }
      })
        .then((res) => {
          this.$toast.success('Reset player successfully')
          this.setPlayer(res.data)
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
        .finally(() => {
          this.isShownModalConfirmResetPlayer = false
        })
    },
    rebootPlayer() {
      AdminPlayerService.rebootAdminPlayer(this.currentPlayerID)
        .then((res) => {
          this.$toast.success('Reboot player successfully')
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
        .finally(() => {
          this.isShownModalConfirmRebootPlayer = false
        })
    },
    showModalResetPlayer() {
      this.isShownModalConfirmResetPlayer = true
    },
    showModalReboot() {
      this.isShownModalConfirmRebootPlayer = true
    },
    showModalScreenshot() {
      this.urlImageScreenshot = ''
      this.urlThumbImageScreenshot = ''
      this.isShownModalScreenshot = true
      AdminPlayerService.requestScreenShot(this.currentPlayerID)
    },
    showModalCheckStatus() {
      this.connected = false
      this.checkStatus()
      this.timeCountDown = 30
      this.isShownModalConfirmCheckStatus = true
      this.countDownTimer()
    },
    countDownTimer() {
      clearTimeout(this.countdown)
      if (this.timeCountDown > 0) {
        this.countdown = setTimeout(() => {
          this.timeCountDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    checkStatus() {
      AdminPlayerService.checkStatus(this.currentPlayerID)
    }
  },
  created() {
    this.$bus.$emit('showSearchBox', false)
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
  },
  mounted() {
    this.currentPlayerID = this.$route.params.playerID
    this.getDetailAdminPlayer(this.currentPlayerID)
    this.$cable.subscribe({
      channel: 'AdminPlayersChannel',
      player_id: this.currentPlayerID
    })
  },
  computed: {
    ...mapState('adminplayer', {
      itemsByID: 'itemsByID',
      loading: 'loading'
    }),
    ...mapGetters('player', {
      listTimezoneOptions: 'listTimezoneOptions'
    }),
    ...mapGetters('adminplayer', {
      center: 'center',
      listCampaignByOrgOptions: 'listCampaignByOrgOptions'
    }),
    markers: function () {
      return [
        {
          position: this.center
        }
      ]
    }
  },
  channels: {
    AdminPlayersChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (data.url) {
          this.urlImageScreenshot = data.url
          this.urlThumbImageScreenshot = data.thumbnail
        } else {
          this.connected = true
        }
      },
      disconnected() {}
    }
  },
  watch: {
    itemsByID(value) {
      const user = Vue.$cookies.get('user')
      this.user = user
      if (value.organization.name === user.organization) {
        this.isItsSelf = true
      }
      if (value.province_state === '0') {
        value.province_state = parseInt(value.province_state)
      }
      this.getTime(value.time_zone_name)
    },
    'itemsByID.time_zone_name'(value) {
      this.getTime(value)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/pages/player/ViewPlayer.scss';
@import '@/pages/campaign/campaignPage.scss';
@import '@/pages/campaign/editCampaign.scss';
.custom-input {
  border: 0 !important;
  height: 34px !important;
  width: 100%;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}
.custom-textarea {
  border: 0 !important;
  max-height: 150px;
  height: 40px;
  width: 100%;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
}
.select-location {
  background: none;
  width: 100%;
  height: 34px;
  transition: box-shadow 0.15s ease;
  border: 0;
  color: #4a4a4a;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  font: 14px Open Sans, sans-serif;
  background-color: white;
  padding: 6px 12px;
}
.table {
  margin-top: 20px;
}
.clock {
  position: absolute;
  z-index: 1;
  background: #fff;
  width: 91px;
  height: 32px;
  display: flex;
  align-items: center;
  top: 15px;
  left: 5px;
  display: flex;
  justify-content: space-around;
}
.maps {
  position: relative;
}
.custom-text-link {
  cursor: pointer;
}

.button-active {
  margin-bottom: 20px;
  span {
    font: 14px 'Opens Sans', sans-serif;
    color: #267eb0;
    font-weight: 600;
    padding-left: 10px;
  }
}

.countdown {
  font-size: 50px;
  font-weight: 600;
}

.loading {
  font-family: sans-serif;
}

.loading:after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: '';
}

@keyframes dotty {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}

.icon-screenshot {
  width: 250px;
  display: block;
  margin: auto;
}
</style>
