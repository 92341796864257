import SmartAsset from '@/services/smart-assets.service'

export default {
  getFbLongLivedAccessToken(context, accessToken) {
    return SmartAsset.getFbLongliveToken(accessToken).then((res) => {
      context.commit('SET_FB_LONG_LIVED_ACCESS_TOKEN', res.data.access_token)
      context.commit('SET_EXPIRES_IN', res.data.expires_in)
    })
  },
  getUserPage(context, accessToken) {
    return SmartAsset.getUserPage(accessToken).then((res) => {
      context.commit('SET_FB_PAGES', res.data)
    })
  },
  async getIgMediaByFacebook(context, fbPageId) {
    try {
      const igBusinessAccount = await SmartAsset.instagramBusinessAccount(
        context.state.fbLongLivedAccessToken,
        fbPageId
      )
      const igId = igBusinessAccount.data.instagram_business_account.id
      context.commit('SET_INSTAGRAM_BUSINESS_ACCOUNT_ID', igId)
      const igListMedia = await SmartAsset.getIgBusinessMediaObjects(
        context.state.fbLongLivedAccessToken,
        igId
      )

      return igListMedia
    } catch (error) {
      return Promise.reject(error)
    }
  },
  getInstagramAuthorization(context) {
    const params = {
      access_token: context.state.fbLongLivedAccessToken,
      social_id: context.state.igBusinessAccountId
    }
    SmartAsset.getInstagramAuthorization(params)
  },
  getListTimezone({ state, commit, rootState }, payload) {
    if (!state.listTimezone.length) {
      return SmartAsset.getListTimezone().then((response) => {
        commit('SET_LIST_TIMEZONE', response.data)
      })
    }
  }
}
