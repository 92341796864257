import BaseService from './base.service'

class LoopService extends BaseService {
  get entity() {
    return 'loop_assets'
  }

  getAllLoops(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/all_loop_assets?campaign_id=${data}`)
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
}

export default new LoopService()
