<template>
  <div class="row mt-3">
    <div class="col-12">
      <div class="card-header top-bar">
        <div class="header-left">
          <div class="admin-search-box">
            <!-- <input v-debounce:400ms="searchInputChange" v-model="dataSearch" placeholder="Search..." type="text" class="quicksearch"> -->
            <!-- <div class="magnifying-glass"></div> -->
            <SfInputSearch
              v-model="dataSearch"
              :placeholder="$t('search')"
              v-debounce:500ms="searchInputChange"
              class="sf-input-search"
            />
          </div>
        </div>
        <div class="header-right">
          <button class="sf-primary" @click="showModalCreate">
            {{ $t('add-new') }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 sf-table">
      <b-table
        show-empty
        striped
        hover
        :items="allUsers"
        :fields="fields"
        head-variant="none"
        table-variant="none"
        thead-class="custom-b-table"
        :busy="loadingUser"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>{{ $t('loading') }}...</strong>
          </div>
        </template>
        <template v-slot:empty>
          <p class="d-flex justify-content-center">{{ $t('no-data-available-in-table') }}</p>
        </template>
        <template v-slot:head(first_name)="field">
          <span>{{ $t('name') }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(first_name)="field">
          <p class="t-name">
            <router-link :to="`/users/${field.item.id}/view-user`"
              >{{ field.item.first_name }} {{ field.item.last_name }}
            </router-link>
          </p>
        </template>

        <template v-slot:head(email)="field">
          <span>{{ $t('email') }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(email)="field">
          <p>{{ field.item.email }}</p>
        </template>

        <template v-slot:head(role)="field">
          <span>{{ $t('role') }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(role)="field">
          <p>{{ field.item.role ? field.item.role.displayname : '' }}</p>
        </template>

        <template v-slot:head(direct_login)="field">
          <span>{{ $t('direct-login') }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(direct_login)="field">
          <p class="two-fa" :class="{ active: field.item.direct_login }">
            {{ field.item.direct_login ? $t('enabled') : $t('disabled') }}
          </p>
        </template>

        <template v-slot:head(is_2fa)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(is_2fa)="field">
          <p class="two-fa" :class="{ active: field.item.is_2fa }">
            {{ field.item.is_2fa ? $t('enabled') : $t('disabled') }}
          </p>
        </template>

        <template v-slot:head(is_2fa)="field">
          <span>{{ field.label }}</span
          ><span class="custome-arrows"></span>
        </template>
        <template v-slot:cell(is_2fa)="field">
          <p>{{ field.item.is_2fa ? $t('enabled') : $t('disabled') }}</p>
        </template>

        <template v-slot:head(action)="field">
          <span>{{ $t('actions') }}</span>
        </template>
        <template v-slot:cell(action)="field">
          <button
            :title="$t('tooltip.edit')"
            @click="editUser(field.item.id)"
            class="button-edit-table"
          ></button>
          <button
            :title="$t('tooltip.delete')"
            @click="shownModalremoveUser(field.item.id)"
            class="button-delete-table"
          ></button>
        </template>
      </b-table>

      <div v-if="!isEmpty" class="py-3">
        <b-pagination
          class="sf-b-pagination"
          :total-rows="pagination.limit * pagination.total"
          :per-page="pagination.limit"
          @change="changePage"
          :limit="6"
          v-model="currentPage"
        />
      </div>
    </div>

    <SfModal
      :title="$t('modal.title.add-user')"
      :width="768"
      :show.sync="isShownModal"
      closeOnOverlay
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('first-name') }}</label>
                </div>
                <input
                  v-model="newUser.user.first_name"
                  :placeholder="$t('first-name')"
                  class="sf-input-text"
                />
              </div>
              <p v-if="!newUser.user.first_name && showErrorAddUser" class="error-text text-right">
                {{ $t('first-name-required') }}
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('last-name') }}</label>
                </div>
                <input
                  v-model="newUser.user.last_name"
                  :placeholder="$t('last-name')"
                  class="sf-input-text"
                />
              </div>
              <p v-if="!newUser.user.last_name && showErrorAddUser" class="error-text text-right">
                {{ $t('last-name-required') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('email') }}</label>
                </div>
                <input
                  v-model="newUser.user.email"
                  :placeholder="$t('email')"
                  v-on:keyup="checkEmailExist('', newUser.user.email)"
                  class="sf-input-text"
                />
              </div>
              <p v-if="!newUser.user.email && showErrorAddUser" class="error-text text-right">
                {{ $t('enter-an-email-address') }}
              </p>
              <p
                v-else-if="
                  newUser.user.email && !checkEmail(newUser.user.email) && showErrorAddUser
                "
                class="error-text text-right"
              >
                {{ $t('enter-a-valid-email-address') }}
              </p>
              <p v-else-if="!emailCanUsed" class="error-text text-right">
                {{ $t('email-address-already-in-use') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('role') }}</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="newUser.user.role_id"
                    :options="roleListByUser"
                    :settings="{ minimumResultsForSearch: -1 }"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <p v-if="!newUser.user.role_id && showErrorAddUser" class="error-text text-right">
                {{ $t('enter-role') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('password') }}</label>
                </div>
                <input
                  type="password"
                  v-model="newUser.user.password"
                  placeholder="***"
                  class="sf-input-text"
                />
              </div>
              <p v-if="!newUser.user.password && showErrorAddUser" class="error-text text-right">
                {{ $t('password-required') }}
              </p>
              <p
                v-else-if="
                  newUser.user.password &&
                  !checkPasswordAddUser(newUser.user.password) &&
                  showErrorAddUser
                "
                class="error-text text-right"
              >
                {{ $t('strong-password-required') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('confirm-password') }}</label>
                </div>
                <input
                  type="password"
                  v-model="newUser.user.password_confirmation"
                  placeholder="***"
                  class="sf-input-text"
                />
              </div>
              <p
                v-if="
                  newUser.user.password_confirmation &&
                  newUser.user.password != newUser.user.password_confirmation &&
                  showErrorAddUser
                "
                class="error-text text-right"
              >
                {{ $t('repeat-the-password') }}
              </p>
              <p
                v-else-if="!newUser.user.password_confirmation && showErrorAddUser"
                class="error-text text-right"
              >
                {{ $t('repeat-the-password') }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModal = false">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="createUser">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>

    <SfModal
      :title="$t('modal.title.edit-user')"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('first-name') }}</label>
                </div>
                <input
                  :value="editUserForm.user.first_name"
                  @input="changeFirstName"
                  :placeholder="$t('first-name')"
                  class="sf-input-text"
                />
              </div>
              <p v-if="!editUserForm.user.first_name && showError" class="error-text text-right">
                {{ $t('first-name-required') }}
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('last-name') }}</label>
                </div>
                <input
                  :value="editUserForm.user.last_name"
                  @input="changeLastName"
                  :placeholder="$t('last-name')"
                  class="sf-input-text"
                />
              </div>
              <p v-if="!editUserForm.user.last_name && showError" class="error-text text-right">
                {{ $t('last-name-required') }}
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('email') }}</label>
                </div>
                <input
                  type="email"
                  :value="editUserForm.user.email"
                  @input="changeEmail"
                  v-on:keyup="checkEmailExist(editUserForm.user.id, editUserForm.user.email)"
                  :placeholder="$t('email')"
                  class="sf-input-text"
                />
              </div>
              <p v-if="!editUserForm.user.email && showError" class="text-right error-text">
                {{ $t('enter-an-email-address') }}
              </p>
              <p
                v-else-if="editUserForm.user.email && !checkEmail(editUserForm.user.email)"
                class="text-right error-text"
              >
                {{ $t('enter-a-valid-email-address') }}
              </p>
              <p v-else-if="!emailCanUsed" class="text-right error-text">
                {{ $t('email-is-currently-used') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('direct-login') }}</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="editUserForm.user.direct_login"
                    :sync="true"
                    :width="44"
                    :height="22"
                    :margin="2"
                    @change="onChangeToggleDetailDirectLogin"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3" v-if="show2FaToogle">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">2FA</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="editUserForm.user.is_2fa"
                    :sync="true"
                    :width="44"
                    :height="22"
                    :margin="2"
                    @change="onChangeToggleDetail2fa"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3" v-if="editUserForm.user.user_type !== 'employee'">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('role') }}</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="editUserForm.user.role_id"
                    :options="roleListByUser"
                    :settings="{ minimumResultsForSearch: -1 }"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
              <p
                v-if="
                  editUserForm.user.user_type !== 'employee' &&
                  !editUserForm.user.role_id &&
                  showError
                "
                class="error-text text-right"
              >
                {{ $t('enter-a-role') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('password') }}</label>
                </div>
                <input
                  type="password"
                  v-model="editUserForm.user.password"
                  placeholder="***"
                  class="sf-input-text"
                />
              </div>
              <p
                v-if="!checkPassword(editUserForm.user.password) && showError"
                class="error-text text-right"
              >
                {{ $t('strong-password-required') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('confirm-password') }}</label>
                </div>
                <input
                  type="password"
                  v-model="editUserForm.user.password_confirmation"
                  placeholder="***"
                  class="sf-input-text"
                />
              </div>
              <p
                v-if="
                  editUserForm.user.password != editUserForm.user.password_confirmation && showError
                "
                class="error-text text-right"
              >
                {{ $t('repeat-the-password') }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalEdit = false">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="saveEditUser">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>

    <SfDeleteModal
      :title="$t('modal.title.confirm-delete')"
      closeOnOverlay
      :show.sync="isShownModalDelete"
    >
      <div class="content-delete">
        {{ $t('confirm-delete-user') }}
      </div>
      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalDelete = false">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="removeUser">{{ $t('ok') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfDeleteModal>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import SfModal from '@/components/SfModal.vue'
import SfInputSearch from '@/components/SfInputSearch.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import UsersService from '../../services/users.service'
import Select2 from 'v-select2-component'
export default {
  name: 'users',
  components: {
    SfModal,
    SfDeleteModal,
    Select2,
    SfInputSearch
  },
  data() {
    return {
      isShownModal: false,
      isShownModalEdit: false,
      isShownModalDelete: false,
      inputError: false,
      checkboxState: false,
      currentUserId: '',
      fields: [
        {
          key: 'first_name',
          colType: 'first_name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'email',
          colType: 'email',
          label: 'Email',
          sortable: true
        },
        {
          key: 'role',
          sortable: true,
          colType: 'role',
          label: 'Role'
        },
        {
          key: 'direct_login',
          sortable: true,
          colType: 'direct_login',
          label: 'Direct Login'
        },
        {
          key: 'is_2fa',
          sortable: true,
          colType: 'is_2fa',
          label: '2FA'
        },
        {
          key: 'direct_login',
          sortable: true,
          colType: 'direct_login',
          label: 'DIRECT LOGIN'
        },
        {
          key: 'is_2fa',
          sortable: true,
          colType: 'is_2fa',
          label: '2FA'
        },
        {
          key: 'ACTION',
          colType: 'button',
          label: 'Actions'
        }
      ],
      currentPage: 1,
      newUser: {
        user: {
          first_name: '',
          last_name: '',
          password: null,
          password_confirmation: null,
          email: '',
          direct_login: true,
          is_2fa: false,
          role_id: '',
          user_type: 'customer'
        }
      },
      editUserForm: {
        user: {
          id: '',
          first_name: '',
          last_name: '',
          password: null,
          password_confirmation: null,
          email: '',
          direct_login: true,
          is_2fa: false,
          role_id: '',
          user_type: ''
        }
      },
      showError: false,
      showErrorAddUser: false,
      dataSearch: '',
      emailCanUsed: true,
      show2FaToogle: false
    }
  },

  methods: {
    ...mapActions('user', {
      getListRoleByUser: 'getListRoleByUser'
    }),
    ...mapMutations('user', {
      createUserFromSocket: 'CREATE_USER_FROM_SOCKET',
      deleteUserFromSocket: 'DELETE_USER_FROM_SOCKET',
      updateUserFromSocket: 'UPDATE_USER_FROM_SOCKET'
    }),
    searchInputChange(val) {
      this.dataSearch = val
      this.currentPage = 1
      this.getAllUsers(this.currentPage, this.pagination.limit, this.dataSearch)
    },

    getAllUsers(page, limit, search) {
      const payload = {
        page: page,
        limit: limit,
        search: search
      }
      this.$store.dispatch('user/getAllUsers', payload)
    },
    changePage(page) {
      this.currentPage = page
      this.getAllUsers(this.currentPage, this.pagination.limit, this.dataSearch)
    },
    editUser(idUser) {
      this.isShownModalEdit = true
      this.showError = false
      this.editUserForm.user.password = null
      this.editUserForm.user.password_confirmation = null
      this.editUserForm.user.is_2fa = false
      this.currentUserId = idUser
      this.$store.dispatch('user/getInfoUserByID', idUser)
      this.emailCanUsed = true
      this.getListRoleByUser()
    },
    shownModalremoveUser(id) {
      this.currentUserId = id
      this.isShownModalDelete = true
    },
    removeUser() {
      UsersService.deleteUser(this.currentUserId)
        .then((_) => {
          this.$toast.success('Successfully delete')
          this.isShownModalDelete = false
          if (this.allUsers.length === 1 && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1
          }
          // this.getAllUsers(this.currentPage, this.pagination.limit, this.dataSearch)
        })
        .catch((err) => {
          this.isShownModalDelete = false
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    changeFirstName(e) {
      this.editUserForm.user.first_name = e.target.value
    },
    changeLastName(e) {
      this.editUserForm.user.last_name = e.target.value
    },
    changeEmail(e) {
      this.editUserForm.user.email = e.target.value
    },
    checkPassword(text) {
      const strongPassword = new RegExp('(?=.*[A-Z])(?=.*[0-9]).{8,}$')
      if (!text) {
        return true
      } else if (text && strongPassword.test(text)) {
        return true
      } else {
        return false
      }
    },
    checkPasswordAddUser(text) {
      // var strongPassword = new RegExp('(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$')
      const strongPassword = new RegExp('(?=.*[A-Z])(?=.*[0-9]).{8,}$')
      // if (!text) {
      //   return false
      // }
      if (text && strongPassword.test(text)) {
        return true
      } else {
        return false
      }
    },
    checkEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    saveEditUser() {
      let conditionUserType = false
      if (this.editUserForm.user.user_type !== 'employee' && this.editUserForm.user.role_id) {
        conditionUserType = true
      } else if (this.editUserForm.user.user_type === 'employee') {
        conditionUserType = true
      }
      if (
        this.editUserForm.user.first_name &&
        this.editUserForm.user.last_name &&
        this.editUserForm.user.email &&
        this.checkEmail(this.editUserForm.user.email) &&
        this.checkPassword(this.editUserForm.user.password) &&
        this.editUserForm.user.password === this.editUserForm.user.password_confirmation &&
        this.emailCanUsed &&
        conditionUserType
      ) {
        UsersService.editUser(this.currentUserId, this.editUserForm)
          .then((_) => {
            this.isShownModalEdit = false
            this.$toast.success('Successfully updated')
            // this.getAllUsers(this.currentPage, this.pagination.limit, this.dataSearch)
          })
          .catch((err) => {
            this.isShownModalEdit = false
            this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
          })
      } else {
        this.showError = true
      }
    },
    createUser() {
      if (
        this.newUser.user.first_name &&
        this.newUser.user.last_name &&
        this.newUser.user.email &&
        this.checkEmail(this.newUser.user.email) &&
        this.checkPasswordAddUser(this.newUser.user.password) &&
        this.newUser.user.password === this.newUser.user.password_confirmation &&
        this.emailCanUsed &&
        this.newUser.user.role_id
      ) {
        delete this.newUser.user.direct_login
        delete this.newUser.user.is_2fa
        UsersService.addUser(this.newUser)
          .then((_) => {
            this.isShownModal = false
            this.$toast.success('Successfully added')
          })
          .catch((err) => {
            this.isShownModal = false
            this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
          })
      } else {
        this.showErrorAddUser = true
      }
    },
    showModalCreate() {
      this.isShownModal = true
      this.showErrorAddUser = false
      this.newUser.user.first_name = ''
      this.newUser.user.last_name = ''
      this.newUser.user.email = ''
      this.newUser.user.password = null
      this.newUser.user.password_confirmation = null
      this.newUser.user.role_id = ''
      this.emailCanUsed = true
      this.getListRoleByUser()
    },
    checkEmailExist(id, email) {
      UsersService.checkEmailExist({ user_id: id, email: email }).then((res) => {
        this.emailCanUsed = res.data.can_use
      })
    },
    onChangeToggleDetail2Fa(value) {
      this.editUserForm.user.is_2fa = value.value
    },
    onChangeToggleDetailDirectLogin(value) {
      this.editUserForm.user.direct_login = value.value
    },
    onChangeToggleDetail2fa(value) {
      this.editUserForm.user.is_2fa = value.value
    }
  },
  channels: {
    UserChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (data.action === 'create') {
          this.createUserFromSocket({ data: data.data })
        } else if (data.action === 'delete') {
          this.deleteUserFromSocket({ data: data.data })
        } else if (data.action === 'update') {
          this.updateUserFromSocket({ data: data.data })
        }
      },
      disconnected() {}
    }
  },
  mounted() {
    this.$cable.subscribe({
      channel: 'UserChannel',
      organization: this.$cookies.get('user').organization_id
    })
    this.getAllUsers(this.currentPage, this.pagination.limit)
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.currentPage = 1
      this.getAllUsers(this.currentPage, this.pagination.limit, this.dataSearch)
    })
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
    this.$cable.unsubscribe('UserChannel')
  },
  computed: {
    ...mapState('user', {
      allUsers: 'allUsers',
      pagination: 'pagination',
      infoUserByID: 'infoUserByID',
      listUserType: 'listUserType',
      error: 'error',
      loading: 'loading',
      loadingUser: 'loadingUser'
    }),
    ...mapGetters('user', {
      isEmpty: 'isEmpty',
      roleListByUser: 'roleListByUser'
    })
  },
  watch: {
    infoUserByID: function (value) {
      this.editUserForm.user.id = value.id
      this.editUserForm.user.first_name = value.first_name
      this.editUserForm.user.last_name = value.last_name
      this.editUserForm.user.user_type = value.user_type
      this.editUserForm.user.email = value.email
      this.editUserForm.user.is_2fa = value.is_2fa
      this.editUserForm.user.direct_login = value.direct_login
      this.editUserForm.user.role_id = value.role_id ? value.role_id : ''
      this.show2FaToogle = value.is_2fa
    }
  }
}
</script>

<style lang="scss" scoped>
.button-delete-table {
  margin-left: 10px !important;
}
.content-delete {
  padding: 35px 45px;
  text-align: center;
  color: #4a4a4a;
  font: 14px 'Open Sans', sans-serif;
}
.btn-create {
  color: #fff !important;
  border-radius: 4px !important;
  height: 40px !important;
  background-color: #55a5f9 !important;
  border-color: #55a5f9 !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.btn {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  margin-bottom: 0;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  font: 14px 'Open Sans', sans-serif;
  font-weight: 600;
}

.title-user {
  justify-content: space-between;
  align-items: center;
  color: #a4aabd;
  height: 70px;
  padding: 19px 21px;
  background-color: white;
  border-color: #f1eff2;
  font-weight: 600;
  margin-left: 15px;
  flex: 0 0 calc(100% - 30px);
}

.exception-row {
  margin: 0;
  margin-top: 20px;
  padding-left: 0;
  margin-left: 15px;

  .header-modal-exception {
    .input-exception {
      height: 40px;
      border-color: #e8e8e8;
      border-radius: 4px;
      padding: 6px 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font: 14px 'Opens Sans', sans-serif;
    }

    .check-box {
      margin-top: 9px;

      &:nth-child(2) {
        margin-left: 25px;
      }

      span {
        cursor: pointer;
        width: 20px;
        height: 20px;
        border: 1px solid #126cdb;
        border-radius: 2px;
      }

      p {
        margin-left: 8px;
      }
    }
  }

  .name-exception {
    text-transform: capitalize;
    font: 14px 'Open Sans', sans-serif;
    color: #333333;
    font-weight: bold;
    margin: 10px 0 9px;
  }

  .custom-input {
    height: 34px !important;
    width: 100%;
    border: 0 !important;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  }

  .select-location {
    background: none;
    width: 100%;
    height: 34px;
    transition: box-shadow 0.15s ease;
    border: 0;
    color: #4a4a4a;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    font: 14px Open Sans, sans-serif;
    background-color: white;
    padding: 6px 12px;
  }
}

.two-fa {
  color: #aa0a1e !important;
  &.active {
    color: #4ac67e !important;
  }
}
</style>
