<template>
  <div class="material mb-5">
    <div class="material-header"></div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="sf-menu-wrapper">
            <ul class="sf-menus">
              <li class="menu-item" v-bind:class="{ active: currentPage == 'typography' }">
                <router-link
                  v-bind:style="{ backgroundImage: `url(${overviewIcon})` }"
                  :to="{ name: 'typography' }"
                  exact
                  >Typography</router-link
                >
              </li>
              <li class="menu-item" v-bind:class="{ active: currentPage == 'color' }">
                <router-link
                  v-bind:style="{ backgroundImage: `url(${libraryIcon})` }"
                  :to="{ name: 'color' }"
                  >Color</router-link
                >
              </li>
              <li class="menu-item" v-bind:class="{ active: currentPage == 'notification' }">
                <router-link
                  v-bind:style="{ backgroundImage: `url(${loopIcon})` }"
                  :to="{ name: 'notification' }"
                  >Notification</router-link
                >
              </li>

              <li class="menu-item" v-bind:class="{ active: currentPage == 'button' }">
                <router-link
                  v-bind:style="{ backgroundImage: `url(${campaignIcon})` }"
                  :to="{ name: 'button' }"
                  exact
                  >Button</router-link
                >
              </li>
              <li class="menu-item" v-bind:class="{ active: currentPage == 'field' }">
                <router-link
                  v-bind:style="{ backgroundImage: `url(${playerIcon})` }"
                  :to="{ name: 'field' }"
                  >Field</router-link
                >
              </li>
              <li class="menu-item" v-bind:class="{ active: currentPage == 'cardandmodal' }">
                <router-link
                  v-bind:style="{ backgroundImage: `url(${accountIcon})` }"
                  :to="{ name: 'cardandmodal' }"
                  >Modal</router-link
                >
              </li>
              <li class="menu-item" v-bind:class="{ active: currentPage == 'pagination' }">
                <router-link
                  v-bind:style="{ backgroundImage: `url(${accountIcon})` }"
                  :to="{ name: 'pagination' }"
                  >Pagination</router-link
                >
              </li>

              <li class="menu-item" v-bind:class="{ active: currentPage == 'table' }">
                <router-link
                  v-bind:style="{ backgroundImage: `url(${accountIcon})` }"
                  :to="{ name: 'table' }"
                  >Table</router-link
                >
              </li>

              <li class="menu-item" v-bind:class="{ active: currentPage == 'card' }">
                <router-link
                  v-bind:style="{ backgroundImage: `url(${accountIcon})` }"
                  :to="{ name: 'card' }"
                  >Card</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-10">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import folder from '@/assets/images/folder.svg'
// import Multiselect from 'vue-multiselect'
import overviewIcon from '@/assets/images/menu/overview.svg'
import libraryIcon from '@/assets/images/menu/library.svg'
import loopIcon from '@/assets/images/menu/loops.svg'
import campaignIcon from '@/assets/images/menu/campaign.svg'
import playerIcon from '@/assets/images/menu/players.svg'
import accountIcon from '@/assets/images/menu/account.svg'
import adminIcon from '@/assets/images/menu/admin.svg'
import logoutIcon from '@/assets/images/menu/signout.svg'
export default {
  name: 'account',
  components: {},
  data() {
    return {
      folder,
      logoutIcon,
      overviewIcon,
      libraryIcon,
      loopIcon,
      campaignIcon,
      playerIcon,
      accountIcon,
      adminIcon,
      showModal: false,
      showModalFluid: false,
      showDeleteModal: false,
      selectValue: '1',
      multiselectTest: {
        name: 'Both',
        value: 'both'
      }
    }
  },
  created() {
    // window.location.href = '/user/organization';
  },
  methods: {
    openModal() {},
    confirmDelete() {
      this.showDeleteModal = false
    },
    sfToast(type) {
      this.$toast.open({
        message: 'Screenfluence notification!',
        type: type,
        duration: 1000
        // all other options may go here
      })
    }
  },
  mounted() {},
  computed: {
    currentPage() {
      return this.$route.matched[1].name
    }
  }
}
</script>

<style lang="scss"></style>
