<template>
  <SfModal
    :title="$t('modal.title.new-asset')"
    :width="786"
    :height="520"
    closeOnOverlay
    :show.sync="dataShow"
    @update:show="updateShow"
  >
    <div class="form-group">
      <div class="form-body modal-body">
        <div class="row">
          <div class="col-6 new-asset-block">
            <div class="new-asset-image">
              <img src="~@/assets/images/illus-upload.svg" />
            </div>
            <div class="new-asset-action">
              <p class="new-asset-action-title">{{ $t('upload-media') }}</p>
              <p class="new-asset-action-desc">
                {{ $t('upload-media-description') }}
              </p>
              <button class="sf-primary" @click="clickUpload">
                <div class="ico">
                  <div class="cloud-upload" />
                </div>
                {{ $t('upload') }}
              </button>
            </div>
          </div>
          <div class="col-6 new-asset-block">
            <div class="new-asset-image">
              <img src="~@/assets/images/illus-smart-asset.svg" />
            </div>
            <div class="new-asset-action">
              <p class="new-asset-action-title">{{ $t('create-a-smart-asset') }}</p>
              <p class="new-asset-action-desc">
                {{ $t('create-a-smart-asset-note') }}
              </p>
              <button class="sf-primary" type="button" @click="clickStart">
                <div class="ico">
                  <div class="plus-circle" />
                </div>
                {{ $t('get-started') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SfModal>
</template>

<script>
import SfModal from '@/components/SfModal'
export default {
  components: {
    SfModal
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataShow: this.show
    }
  },

  watch: {
    show(value) {
      this.dataShow = value
    }
  },
  methods: {
    updateShow() {
      this.$emit('update:show', false)
    },
    clickUpload() {
      this.$emit('click:uploadAsset')
    },
    clickStart() {
      this.$emit('click:startAsset')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-body {
  .new-asset-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    &:first-child {
      border-right: 1px solid #dcdcdc;
    }

    .new-asset-image {
      margin: 30px 0;
      padding: 5px;

      img {
        width: 237px;
      }
    }

    .new-asset-action {
      text-align: center;
      max-width: 200px;

      .new-asset-action-title {
        font-weight: 600;
      }

      .new-asset-action-desc {
        font-size: 12px;
        margin: 10px 0;
        color: #9b9b9b;
      }
    }
  }

  .modal-body-content {
    width: calc(100% - 2px);
    margin-bottom: 30px;
    margin-top: -15px;

    .smart-asset-images-wrap {
      background-color: #f9f9f9;
      border-left: 1px solid #e7e5e5;

      .smart-asset-images {
        height: 390px;
        overflow-y: auto;
        width: 100%;
        padding: 0 15px;
        margin-top: 15px;

        .search-smart-asset {
          margin-bottom: 10px;

          input {
            width: 100%;
            height: 50px;
            border-radius: 25px;
            border: solid 1px #e1e1e1;
            background-color: #ffffff;
            padding: 0 15px;

            &:focus {
              border-radius: 25px;
              outline: none;
            }
          }
        }
      }
    }

    .smart-asset-title {
      color: #2c7fe1;
      letter-spacing: 1.2px;
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 30px;
    }

    .smart-asset-item {
      display: inline-block;
      text-align: center;
      width: 100%;
      background-color: #ffffff;
      padding: 15px 5px;
      margin-bottom: 15px;
      font-size: 12px;
      min-height: 120px;
      border-radius: 8px;
      color: #234f82;
      cursor: pointer;

      &:hover {
        box-shadow: 0 4px 16px 0 rgba(35, 108, 196, 0.2);
        border: solid 1px #236cc4;
      }

      &.active {
        box-shadow: 0 4px 16px 0 rgba(35, 108, 196, 0.2);
        border: solid 1px #236cc4;
      }

      .smart-asset-icon {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 58px;
          height: 58px;
        }
      }

      .smart-asset-name {
        text-align: center;
        width: 100%;
        margin-top: 10px;
      }

      &:last-child {
        margin-right: 0;
      }

      p {
        color: #9b9b9b;
        margin-top: 10px;
      }

      .btn {
        width: 95px;
        height: 95px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 50%;
      }
    }
  }
  .btn {
    font-size: 14px;
  }
  .btn-custom-save {
    width: 150px;
    height: 40px;
    background-color: #2c7fe1;
    border-radius: 4px;
    font-weight: bold;
    border: 1px solid #2c7fe1;
    color: #fff;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

    &:hover {
      transform: translateY(-1px);
      transform: translateY(-1px);
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    }
  }
  .ico {
    display: inline-block;
    .cloud-upload {
      background-repeat: no-repeat;
      background-position: center center;
      width: 16px;
      height: 14px;
      background-image: url(~@/assets/images/cloud-upload.svg);
    }
    .plus-circle {
      background-repeat: no-repeat;
      background-position: center center;
      width: 14px;
      height: 14px;
      line-height: inherit;
      background-image: url(~@/assets/images/plus-circle.svg);
    }
  }
}
</style>
