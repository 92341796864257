import BaseService from './base.service'

class FolderService extends BaseService {
  get entity() {
    return 'folders'
  }

  getListFolders(type, page = '') {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}?type=${type}&page_name=${page}`)
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getListChildrenFolders(id, type, orientation = '') {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/${id}/get_folders?type=${type}&orientation=${orientation}`)
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getListChildrenAssetsFolders(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/${id}/get_assets`)
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  // data = T<folder> {
  //   name
  //   folder_type : library or loop
  //   parent_id
  //   rgt
  //   depth
  //   orientation
  //   source_type
  //   source_id
  // }
  createFolder(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/`, data)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  // data = T<folder> {
  //   name
  //   folder_type : library or loop
  //   parent_id
  //   rgt
  //   depth
  //   orientation
  //   source_type
  //   source_id
  // }
  editFolderByID(data, id) {
    return new Promise((resolve, reject) => {
      delete data.source_type
      delete data.source_id
      return this.request({ auth: true })
        .patch(`${this.entity}/${id}`, data)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  deleteFolderByID(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .delete(`${this.entity}/${id}`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  deleteFolderByIDs(ids) {
    const data = { data: { ids } }
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .delete(`${this.entity}/destroy_folders`, data)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getDetailFolder(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/${id}`)
        .then((response) =>
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        )
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getAllNestedAssetInFolder(id, page_name = '', loop_id, orientation = '') {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(
          `${this.entity}/${id}/get_all_nested_assets?page_name=${page_name}${
            loop_id ? '&loop_id=' + loop_id : ''
          }&orientation=${orientation}`
        )
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getMultipleAssetInFolder(ids, orientation = '') {
    const data = {
      params: {
        folder_ids: ids
      }
    }
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/get_folder_assets_for_multiple?orientation=${orientation}.json`, data)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getAssetInFolder(
    id,
    page,
    limit,
    page_name = '',
    loop_id,
    orientation = '',
    search = '',
    type = ''
  ) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(
          `${this.entity}/${id}/get_assets?page=${page}&limit=${limit}&page_name=${page_name}${
            loop_id ? '&loop_id=' + loop_id : ''
          }&orientation=${orientation}&search=${search}&type=${type}`
        )
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }
}

export default new FolderService()
