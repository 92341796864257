<template>
  <div>
    <div class="content">
      <div class="select-all container" v-if="selectAllButton">
        <div class="row">
          <div class="col-12 d-flex align-items-center">
            <button type="button" class="sf-btn-outline btn" @click="triggerCheckboxClick">
              <label class="select-all-container"
                ><input
                  type="checkbox"
                  v-if="selectAllButton && nodes.length > 0"
                  class="select-checkbox"
                  :checked="selectedItems.length === assetItems.length"
                  @change="toggleSelectedAllItem($event)"
                />Select All</label
              >
            </button>
            <button
              type="button"
              @click="onDeleteBulk"
              v-if="selectedItems.length > 0"
              class="sf-btn-delete btn btn-del ml-2"
            >
              <img class="delete-item select-item" :src="trashIcon" /> Delete
            </button>
          </div>
        </div>
      </div>
    </div>
    <template v-if="viewType === 'grid-view'">
      <div v-if="loading" :key="`loading-placeholders`">
        <transition-group
          name="fade"
          mode="in-out"
          tag="div"
          class="asset-container"
          ref="divcontainer"
        >
          <template v-for="i in parseInt(this.limitPage.value)">
            <div class="element-item item" :key="`loading-${i}`" v-widthItem="itemWidth">
              <content-placeholders :centered="true">
                <div class="detail-section sf-asset-card">
                  <div class="img-name">
                    <div class="wrap-img">
                      <content-placeholders-img />
                    </div>
                    <div class="name asset-info">
                      <div class="wrap-p">
                        <content-placeholders-text :lines="2" />
                      </div>
                    </div>
                  </div>
                </div>
              </content-placeholders>
            </div>
          </template>
        </transition-group>
      </div>
      <div v-else :key="`content-item`">
        <transition-group name="item" tag="div" class="asset-container" ref="divcontainer">
          <template v-for="item in nodes">
            <div
              :key="`${item.type}-${item.id}`"
              :class="getClassWithType(item)"
              :ref="`item-${item.type}-${item.id}`"
              draggable="true"
              @dblclick="openFolder(item)"
              @dragstart="drag(item.id, item.type, $event)"
              @dragend="dragEnd(item.id, item.type, $event)"
              v-widthItem="itemWidth"
            >
              <input
                type="checkbox"
                v-if="selectAllButton && item.type !== typeFile.folder"
                class="data-image selected-item"
                :checked="selectedItems.includes(item)"
                @change="toggleSelectedItem($event, item)"
              />
              <div class="data-drag hide">
                <img class="data-image" :src="getIconWithType(item)" />
                <p class="data-title">{{ item.name }}</p>
              </div>
              <div
                class="detail-section sf-asset-card"
                :class="selectAllButton && selectedItems.includes(item) ? 'selected' : ''"
              >
                <div class="img-name">
                  <div class="wrap-img">
                    <div v-if="!isFolder(item) && !item.asset_count">
                      <div class="loop-empty wrap-img"></div>
                    </div>
                    <div v-else-if="isImageVideo(item)">
                      <div class="background-opacity"></div>
                      <img :src="item.thumb_link" />
                    </div>
                    <div v-else-if="isFolder(item)">
                      <div
                        class="wrap-img folder-img"
                        :class="
                          item.folder_type === 'organizations' ||
                          item.folder_type === 'sub_organization'
                            ? ' organization none-button'
                            : item.shared_folder
                            ? ' none-button shared-folder'
                            : ''
                        "
                      ></div>
                    </div>
                    <div v-else>
                      <div :class="'wrap-img ' + item.smart_asset_type + '-img'"></div>
                    </div>
                    <div v-if="item.orientation === 'portrait'" class="portrait-note-icon"></div>
                  </div>
                  <div class="name asset-info">
                    <div class="wrap-p">
                      <p class="asset-name">
                        <span :title="item.name">{{ item.name }}</span>
                      </p>
                      <span v-if="item.type !== typeFile.folder" class="asset"
                        >{{ item.asset_count }} {{ $tc('asset', item.asset_count) }}</span
                      >
                      <div>
                        <span>{{ timeSince(item.updated_at) }}</span>
                      </div>
                      <!-- <p class="orientation hidden">
                        {{ item.orientation }}
                      </p> -->
                    </div>
                    <div class="group-tags"></div>
                  </div>
                </div>
                <div class="date-action">
                  <div class="table-row">
                    <div class="wrap-size-date">
                      <div class="date-section"></div>
                      <div class="asset-details"></div>
                    </div>
                    <div class="action-section" :class="{ 'loop-setting': !isFolder(item) }">
                      <div class="wrap-button">
                        <div class="btn-group btn-group-icon" role="group" v-if="!isFolder(item)">
                          <button
                            class="btn btn-control-asset btn-edit btn-loop-asset-detail"
                            :title="$t('tooltip.loop-editor')"
                            @click="onLoopEditor(item.id)"
                          >
                            <img src="~@/assets/images/ig-edit.svg" />
                          </button>
                          <button
                            class="btn btn-control-asset btn-del btn-trash btn-remove-loop-asset"
                            :title="$t('tooltip.delete')"
                            @click="onDelete(item.id)"
                          >
                            <img src="~@/assets/images/delete.svg" />
                          </button>
                        </div>
                      </div>
                      <div class="wrap-button">
                        <div class="btn-group btn-group-icon" role="group">
                          <button
                            v-if="!isFolder(item)"
                            class="btn-control-asset btn-edit"
                            :title="$t('tooltip.preview')"
                            @click="onPreview(item.id)"
                          >
                            <a>
                              <img src="~@/assets/images/preview_loop_grid.svg" />
                            </a>
                          </button>
                          <button
                            class="btn btn-control-asset btn-edit btn-edit-loop-asset"
                            :title="$t('tooltip.edit')"
                            v-if="
                              !item.shared_folder &&
                              item.folder_type !== 'organizations' &&
                              item.folder_type !== 'sub_organization'
                            "
                            @click="onEdit(item.id)"
                          >
                            <img src="~@/assets/images/pencil-edit.svg" />
                          </button>
                          <button
                            class="btn btn-control-asset btn-edit edit-smart-asset"
                            :title="$t('tooltip.share')"
                            type="button"
                            v-if="
                              isParentOrg &&
                              item.type === typeFile.folder &&
                              item.folder_type !== 'organizations' &&
                              item.folder_type !== 'sub_organization'
                            "
                            @click="onShare(item.id)"
                          >
                            <img src="~@/assets/images/share.svg" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </transition-group>
      </div>
    </template>
    <template v-else>
      <div v-if="loading" :key="`loading-placeholders`">
        <transition-group name="fade" mode="in-out" tag="div" class="table-container">
          <template v-for="i in parseInt(this.limitPage.value)">
            <div class="element-item item" :key="`loading-${i}`" v-widthItem="itemWidth">
              <content-placeholders :animated="true">
                <content-placeholders-heading :img="true" />
              </content-placeholders>
            </div>
          </template>
        </transition-group>
      </div>
      <div v-else :key="`content-item`">
        <transition-group name="item" tag="div" class="table-container">
          <template v-for="item in nodes" class="">
            <div
              :key="`${item.type}-${item.id}`"
              :class="getClassWithType(item)"
              :ref="`item-${item.type}-${item.id}`"
              draggable="true"
              @dblclick="openFolder(item)"
              @dragstart="drag(item.id, item.type, $event)"
              @dragend="dragEnd(item.id, item.type, $event)"
              v-widthItem="itemWidth"
            >
              <input
                type="checkbox"
                v-if="selectAllButton && item.type !== typeFile.folder"
                class="data-image selected-item"
                :checked="selectedItems.includes(item)"
                @change="toggleSelectedItem($event, item)"
              />
              <div class="data-drag hide">
                <img class="data-image" :src="getIconWithType(item)" />
                <p class="data-title">{{ item.name }}</p>
              </div>
              <div
                class="detail-section sf-asset-card"
                :class="selectAllButton && selectedItems.includes(item) ? 'selected' : ''"
              >
                <div class="background-img">
                  <div class="wrap-img">
                    <img
                      v-if="!isFolder(item) && !item.asset_count"
                      class="img-default icon-ig"
                      src="~@/assets/images/loops.svg"
                    />
                    <div v-else-if="isImageVideo(item)" class="bg-gradient">
                      <img :src="item.thumb_link" />
                    </div>

                    <div v-else-if="isFolder(item)" class="bg-gradient">
                      <img
                        class="img-default icon-ig"
                        v-if="item.shared_folder"
                        :src="require('@/assets/images/folder-shared.svg')"
                      />
                      <img
                        class="img-default icon-ig"
                        v-else-if="
                          item.folder_type === 'sub_organization' ||
                          item.folder_type === 'organizations'
                        "
                        :src="require('@/assets/images/folder-organization.svg')"
                      />
                      <img
                        class="img-default icon-ig"
                        v-else
                        :src="require('@/assets/images/folder.svg')"
                      />
                    </div>

                    <div v-else class="bg-gradient">
                      <img
                        class="img-default icon-ig"
                        :src="
                          require('@/assets/images/' +
                            item.smart_asset_type.replace('_', '-') +
                            '-list.svg')
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="right-content-element asset-info">
                  <div class="top-content">
                    <div class="item item-left">
                      <p class="asset-name">
                        <span :title="item.name">{{ item.name }}</span>
                      </p>
                    </div>
                    <div class="item item-right">
                      <div class="table-row">
                        <div class="asset-details item-cell">
                          <span v-if="item.type !== typeFile.folder" class="asset"
                            >{{ item.asset_count }} {{ $tc('asset', item.asset_count) }}</span
                          >
                        </div>
                        <div class="date-section item-cell">
                          <p>
                            <img src="~@/assets/images/date-listview.svg" />
                            {{ timeSince(item.updated_at) }}
                          </p>
                          <p
                            v-if="item.type !== typeFile.folder && item.orientation === 'portrait'"
                          >
                            <img
                              class="portrait-icon"
                              src="~@/assets/images/portrait-icon-listview.svg"
                            />
                            {{ $t('portrait') }}
                          </p>
                          <p
                            v-if="item.type !== typeFile.folder && item.orientation === 'landscape'"
                          >
                            <img
                              class="landscape-icon"
                              src="~@/assets/images/landscape-icon-listview.svg"
                            />
                            {{ $t('landscape') }}
                          </p>
                          <p></p>
                        </div>
                        <div
                          v-if="item.type !== typeFile.folder"
                          class="action-section item-cell loop"
                        >
                          <div class="btn-group btn-group-icon" role="group">
                            <button
                              class="btn-control-asset btn-edit-loop-asset"
                              :title="$t('tooltip.edit')"
                              v-if="
                                !item.shared_folder &&
                                item.folder_type !== 'organizations' &&
                                item.folder_type !== 'sub_organization'
                              "
                              @click="onEdit(item.id)"
                            ></button>
                            <button
                              class="btn-control-asset btn-loop-preview"
                              :title="$t('tooltip.preview')"
                              v-if="item.type !== typeFile.folder"
                              @click="onPreview(item.id)"
                            ></button>
                            <button
                              class="btn-control-asset btn-loop-asset-detail tooltip-long-text"
                              :title="$t('tooltip.loop-editor')"
                              v-if="item.type !== typeFile.folder"
                              @click="onLoopEditor(item.id)"
                            ></button>
                            <button
                              class="btn-control-asset btn-remove-loop-asset"
                              :title="$t('tooltip.delete')"
                              v-if="item.type !== typeFile.folder"
                              @click="onDelete(item.id)"
                            ></button>
                          </div>
                        </div>
                        <div v-else class="action-section item-cell loop align-items-end">
                          <div class="btn-group btn-group-icon justify-content-end" role="group">
                            <button
                              class="btn-control-asset btn-edit-loop-asset"
                              :title="$t('tooltip.edit')"
                              v-if="
                                !item.shared_folder &&
                                item.folder_type !== 'organizations' &&
                                item.folder_type !== 'sub_organization'
                              "
                              @click="onEdit(item.id)"
                            ></button>
                            <button
                              class="btn-control-asset btn-share-icon"
                              :title="$t('tooltip.share')"
                              type="button"
                              v-if="
                                isParentOrg &&
                                item.type === typeFile.folder &&
                                item.folder_type !== 'organizations' &&
                                item.folder_type !== 'sub_organization'
                              "
                              @click="onShare(item.id)"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </transition-group>
      </div>
    </template>
    <div class="drag-item" ref="drag">
      <img class="data-image" src="#" />
      <p class="data-title">#</p>
    </div>
  </div>
</template>
<script>
import typeFile from '@/constant/typeFile'
import { timeSince } from '@/helper/helper'
import instagramIcon from '@/assets/images/instagram.svg'
import folderIcon from '@/assets/images/folder.svg'
import webIcon from '@/assets/images/web-icon.svg'
import pdfIcon from '@/assets/images/pdf-icon.svg'
import loopIcon from '@/assets/images/loops.svg'
import youtubeIcon from '@/assets/images/youtube-icon.svg'
import trashIcon from '@/assets/images/icon-trash.svg'

export default {
  props: ['viewType', 'nodes', 'loading', 'limitPage', 'isSomeOneElseEditing', 'selectAllButton'],
  data() {
    return {
      instagramIcon,
      folderIcon,
      webIcon,
      pdfIcon,
      loopIcon,
      youtubeIcon,
      trashIcon,
      typeFile,
      itemWidth: 1,
      eventResize: null,
      dropSuccess: false,
      isParentOrg: false,
      selectedItems: [],
      assetItems: []
    }
  },
  methods: {
    timeSince,
    drag(id, type, event) {
      const eleCurrent = this.$refs[`item-${type}-${id}`][0]
      eleCurrent.style.opacity = 0
      const eleImage = eleCurrent.getElementsByClassName('data-image')[0]
      const eleTitle = eleCurrent.getElementsByClassName('data-title')[0]
      event.dataTransfer.effectAllowed = 'move'
      event.stopPropagation()

      // create html moving
      const eleDrag = this.$refs.drag
      const eleImageDrag = eleDrag.getElementsByClassName('data-image')[0]
      const eleTitleDrag = eleDrag.getElementsByClassName('data-title')[0]
      eleImageDrag.src = eleImage.src
      eleTitleDrag.innerHTML = eleTitle.innerHTML
      document.body.appendChild(eleDrag)
      event.dataTransfer.setData('text', event.target.id)
      event.dataTransfer.setDragImage(eleDrag, 20, 20)

      this.dropSuccess = false
      let nodeDrag = window.slVueTree.getNodeById(id)
      if (!nodeDrag) {
        const nodeTemp = this.nodes.find((item) => item.id === id)
        const nodeCurrent = {
          node: window.slVueTree.getLastNode(),
          placement: 'before'
        }

        window.slVueTree.insert(nodeCurrent, {
          isLeaf: true,
          data: {
            ...nodeTemp,
            file_type: nodeTemp.type,
            dataHide: true,
            source_id: nodeTemp.id,
            newNode: true
          },
          children: []
        })
        nodeDrag = window.slVueTree.getNodeById(id)
      }
      if (nodeDrag) window.slVueTree.select([...nodeDrag.path])
    },
    dragEnd(id, type, event) {
      const eleCurrent = this.$refs[`item-${type}-${id}`][0]
      if (eleCurrent) {
        eleCurrent.style.opacity = 1
      }

      if (!this.dropSuccess) {
        this.$bus.$emit('dragEnd', event)
      }

      const selected = window.slVueTree.getSelected()[0]
      const parentId =
        selected.data.file_type === 'folder'
          ? selected.data.parent_id
          : selected.data.folder.parent_id
      const nodeParent = window.slVueTree.getNodeById(parentId)
      const shouldUpdateNode = nodeParent
        ? !nodeParent.children.some((p) => p.data.id === id)
        : false
      if (selected.path.length === 1) {
        window.slVueTree.remove(selected.path)
      } else if (shouldUpdateNode && this.dropSuccess) {
        window.slVueTree.updateNode(selected.path, {
          data: {
            ...selected.data,
            dataHide: false
          },
          isSelected: false
        })
      }
    },
    onEdit(id) {
      const node = this.nodes.find((item) => item.id === id)
      const type = node.type
      this.$emit('edit:' + type, node)
    },
    onShare(id) {
      const node = this.nodes.find((item) => item.id === id)
      this.$emit('edit:shareFolder', node)
    },
    onPreview(id) {
      const node = this.nodes.find((item) => item.id === id)
      this.$emit('preview', node)
    },
    onLoopEditor(id) {
      const node = this.nodes.find((item) => item.id === id)
      this.$emit('loopEditor', node)
    },
    onDelete(id) {
      const node = this.nodes.find((item) => item.id === id)
      this.$emit('delete', node)
    },
    onResize() {
      if (this.viewType === 'grid-view') {
        const widthCurrent = document.querySelectorAll('.asset-container')[0].clientWidth
        this.itemWidth = Math.max(1, (widthCurrent / 180) >> 0)
      } else {
        this.itemWidth = 1
      }
    },
    openFolder(item) {
      if (this.isFolder(item)) {
        this.$emit('openFolder', item)
      }
    },
    getIconWithType(item) {
      let icon = ''
      if (!item.asset_count && !this.isFolder(item)) {
        icon = this.loopIcon
      } else if (this.isFolder(item)) {
        icon = this.folderIcon
      } else if (this.isImageVideo(item)) {
        icon = item.thumb_link
      } else {
        switch (item.smart_asset_type) {
          case 'instagram':
            icon = this.instagramIcon
            break
          case 'web':
            icon = this.webIcon
            break
          case 'pdf':
            icon = this.pdfIcon
            break
          case 'youtube':
            icon = this.youtubeIcon
            break
          default:
            icon = ''
        }
      }
      return icon
    },
    isImageVideo(item) {
      return item.smart_asset_type === typeFile.image || item.smart_asset_type === typeFile.video
    },
    isFolder(item) {
      return item.type === typeFile.folder
    },
    getClassWithType(item) {
      let className = 'element-item item ' + item.type
      if (!this.isImageVideo(item)) {
        className = className + ' none-img'
      }
      if (this.selectedItems.includes(item)) {
        return `${className} selected`
      }
      return className
    },
    toggleSelectedAllItem(event) {
      if (event.target.checked) {
        this.selectedItems = this.assetItems
      } else {
        this.selectedItems = []
      }
    },
    toggleSelectedItem(event, item) {
      if (item.type !== typeFile.folder) {
        if (event.target.checked) {
          this.selectedItems.push(item)
        } else {
          this.selectedItems = this.selectedItems.filter((selectedItem) => selectedItem !== item)
        }
      }
    },
    triggerCheckboxClick() {
      const checkbox = document.querySelector('.select-checkbox')[0]
      if (checkbox) {
        checkbox.click()
      }
    },
    onDeleteBulk() {
      this.$emit('deleteItemBulk', this.selectedItems)
    }
  },
  computed: {},
  created() {
    this.eventResize = setInterval(this.onResize, 100)
  },
  watch: {
    itemWidth(value) {},
    viewType(value) {
      this.onResize()
    },
    loading(value) {
      this.loading = value
    },
    nodes(values) {
      if (values) {
        this.assetItems = values.filter((item) => item.type !== typeFile.folder)
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.eventResize)
  },
  directives: {
    widthItem: {
      bind(el, binding, vnode) {
        const item = binding.value
        el.style.width = `calc(100% / ${item})`
      },
      update(el, binding, vnode) {
        const item = binding.value
        el.style.width = `calc(100% / ${item})`
      }
    },
    resetWidth: {
      bind(el, binding, vnode) {
        el.style.width = 'calc(100% - 25px)'
      },
      update(el, binding, vnode) {
        el.style.width = 'calc(100% - 25px)'
      }
    }
  },
  mounted() {
    this.onResize()
    this.$bus.$on('dropSuccess', () => {
      this.dropSuccess = true
    })
    this.isParentOrg = this.$cookies.get('user').is_parent_organization
  }
}
</script>
<style lang="scss" scoped>
button {
  border: 0px;
}
.delete-item {
  width: 17px;
  height: 17px;
}
.content {
  padding-left: 5px;
  .select-all {
    position: relative;
    // padding: 0 29px;
    margin: 10px 0;
    .select-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      width: 100%;
      line-height: 20px;

      .select-item {
        width: 17px;
        height: 17px;
        margin: 0 5px;
        &.cancel-icon {
          width: 12px;
        }
        &.unselect-icon {
          width: 10px;
        }
      }
    }
    button {
      border: 1px solid #126cdb;
    }
  }
}
@import './style-grid-view.scss';
@import './style-list-view.scss';

.select-all-container {
  width: max-content;
  display: flex;
  cursor: pointer;
  .select-checkbox {
    width: 15px;
    height: 15px;
    margin: 0 6px 0 0;
  }
}

.item {
  &-enter-active {
    transition: all 0.3s ease-out;
  }

  &-leave-active {
    transition: all 0.3s ease-in;
    position: absolute;
    z-index: 1;
  }

  &-enter,
  &-leave-to {
    transform: scale(0.5);
    opacity: 0;
  }
  &-move {
    transition: transform 0.3s;
  }
}

.drag-item {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100px;
  height: 100px;
  z-index: -1;
  background-color: #fff;
  border-radius: 5px;
  .data-image {
    margin: 3px;
    width: 95%;
    height: 70px;
    border-radius: 5px;
  }
  .data-title {
    font-size: 13.5px;
    width: 100%;
    height: 20px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 3px;
  }
}
.asset-container {
  .element-item {
    height: 221px;
    .name {
      min-height: 77px !important;
    }
  }
}
</style>
