// toastPlugin.js
import Vue from 'vue'
import VueToast from 'vue-toast-notification'

// Sử dụng Vue Toastification
Vue.use(VueToast, {
  queue: true
})

const toastPlugin = (store) => {
  // Tạo một instance Vue để có thể sử dụng $toast trong Vuex
  const toast = Vue.$toast

  // Đăng ký toast trên store để sử dụng ở mọi nơi trong Vuex
  store.$toast = toast
}

export default toastPlugin
