import i18n from '../i18n'

export function getExceptionDays(item) {
  const newItem = byteString(item)
  const daysOfWeek = [
    i18n.t('days-in-week.sun'),
    i18n.t('days-in-week.mon'),
    i18n.t('days-in-week.tue'),
    i18n.t('days-in-week.wed'),
    i18n.t('days-in-week.thu'),
    i18n.t('days-in-week.fri'),
    i18n.t('days-in-week.sat')
  ]
  let daysString = ''
  for (let i = 0; i <= 6; i++) {
    if (newItem[i] === '1') {
      daysString = daysString + daysOfWeek[i] + ' '
    }
  }
  return daysString
}

export function parseIntRepeatInfo(item) {
  return parseInt(item, 2)
}

export function timeSince(date) {
  if (date) {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000)
    let interval = Math.floor(seconds / 31536000)

    if (interval >= 1) {
      return (
        interval +
        (interval > 1
          ? ` ${i18n.tc('datetime.year-ago', 2)}`
          : ` ${i18n.tc('datetime.year-ago', 1)}`)
      )
    }
    interval = Math.floor(seconds / 2592000)
    if (interval >= 1) {
      return (
        interval +
        (interval > 1
          ? ` ${i18n.tc('datetime.month-ago', 2)}`
          : ` ${i18n.tc('datetime.month-ago', 1)}`)
      )
    }
    interval = Math.floor(seconds / 86400)
    if (interval >= 1) {
      return (
        interval +
        (interval > 1 ? ` ${i18n.tc('datetime.day-ago', 2)}` : ` ${i18n.tc('datetime.day-ago', 1)}`)
      )
    }
    interval = Math.floor(seconds / 3600)
    if (interval >= 1) {
      return (
        interval +
        (interval > 1
          ? ` ${i18n.tc('datetime.hour-ago', 2)}`
          : ` ${i18n.tc('datetime.hour-ago', 1)}`)
      )
    }
    interval = Math.floor(seconds / 60)
    if (interval >= 1) {
      return (
        interval +
        (interval > 1
          ? ` ${i18n.tc('datetime.minute-ago', 2)}`
          : ` ${i18n.tc('datetime.minute-ago', 1)}`)
      )
    }
    return (
      Math.floor(seconds) +
      (Math.floor(seconds) > 1
        ? ` ${i18n.tc('datetime.second-ago', 2)}`
        : ` ${i18n.tc('datetime.second-ago', 1)}`)
    )
  } else {
    return ''
  }
}

export function toDuration(number) {
  const sec_num = parseInt(number, 10) // don't forget the second param
  let hours = Math.floor(sec_num / 3600)
  let minutes = Math.floor((sec_num - hours * 3600) / 60)
  let seconds = sec_num - hours * 3600 - minutes * 60

  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  return hours + ':' + minutes + ':' + seconds
}
export function getDurationSecond(second) {
  return second / 1000
}
export function sortNameFolder(listFolder) {
  const outSubOrg = listFolder.filter((folder) => folder.data.folder_type !== 'sub_organization')
  outSubOrg.sort((a, b) => a.data.name.toUpperCase().localeCompare(b.data.name.toUpperCase()))
  const newListFolder = [
    ...listFolder.filter((folder) => folder.data.folder_type === 'sub_organization'),
    ...outSubOrg
  ]
  return newListFolder
}

export function findStatusForLastConnected(time) {
  const seconds = Math.floor((new Date() - new Date(time)) / 1000)
  const time_min = seconds / 60
  if (time_min < 15) {
    return 'normal'
  } else if (time_min >= 15 && time_min < 7 * 24 * 60) {
    return 'yellow'
  } else if (time_min >= 7 * 24 * 60) {
    return 'red'
  }
}
function byteString(n) {
  if (n < 0 || n > 255 || n % 1 !== 0) {
    throw new Error(n + ' does not fit in a byte')
  }
  return ('0000000' + n.toString(2)).substr(-7)
}

export const secondsToString = (time, defaultValue) => {
  let sec_num = parseInt(time, 10) // don't forget the second param
  if (isNaN(sec_num)) {
    sec_num = parseInt(defaultValue, 10)
  }
  let hours = Math.floor(sec_num / 3600)
  let minutes = Math.floor((sec_num - hours * 3600) / 60)
  let seconds = sec_num - hours * 3600 - minutes * 60

  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  return hours + ':' + minutes + ':' + seconds
}

export const formatQuantity = (quantity, name) => {
  return quantity > 1 ? quantity + ' ' + name + 's' : quantity + ' ' + name
}

export const textWithoutNonBreakingSpaceEntities = (text) => {
  // eslint-disable-next-line no-irregular-whitespace
  const pattern = / /g
  return text.replace(pattern, ' ')
}
