export default {
  methods: {
    capitalizeFirstLetter(words) {
      const string = []
      words.forEach(function (item, index) {
        if (index === 0) {
          string.push(item.charAt(0).toUpperCase() + item.slice(1))
        } else {
          string.push(item.toLowerCase())
        }
      })
      return string.join(' ')
    }
  }
}
