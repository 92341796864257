export default {
  loading: false,
  error: false,
  items: [],
  pagination: {
    limit: 10,
    page: 0,
    total: 0
  },
  countryOptions: [{ id: '', text: 'Select a Country' }],
  provinceStateOptions: [{ id: '', text: 'Select a Province/State' }]
}
