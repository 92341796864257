<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="row setting-page">
          <div class="col-12 col-sm-12">
            <div class="sf-card">
              <div class="card-heading">
                <p class="title-section">{{ $t('loops') }}</p>
              </div>
              <div class="card-body editable">
                <div class="row">
                  <div class="col-12">
                    <button @click="shownModalEditSettings" class="button-edit-pen"></button>
                    <p>
                      <span class="sf-text-strong mr-2">{{ $t('default-media-duration') }}</span>
                      <span>{{ infoUser.default_slide_duration }} {{ $t('seconds') }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row setting-page">
          <div class="col-12 col-sm-12">
            <div class="sf-card">
              <div class="card-heading">
                <p class="title-section">{{ $t('library') }}</p>
              </div>
              <div class="card-body editable">
                <div class="row">
                  <div class="col-12">
                    <button @click="shownModalEditNumberAsset" class="button-edit-pen"></button>
                    <p>
                      <span class="sf-text-strong mr-2">{{ $t('default-number-asset') }}</span>
                      <span>{{ infoUser.default_number_asset || 25 }} {{ $t('assets') }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row setting-page">
          <div class="col-12 col-sm-12">
            <div class="sf-card">
              <div class="card-heading">
                <p class="title-section">{{ $t('single-sign-on') }} (SSO)</p>
              </div>
              <div class="card-body editable">
                <div class="row">
                  <div class="col-12">
                    <p>
                      <span class="sf-text-strong mr-2"
                        >Service Provider metadata (SP metadata)</span
                      >
                    </p>
                    <div class="d-flex justify-content-end">
                      <a :href="metadataUrl"
                        ><button class="sf-primary">
                          {{ $t('download-metadata') }}
                        </button></a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row setting-page">
          <div class="col-12 col-sm-12">
            <div class="sf-card">
              <div class="card-heading">
                <p class="title-section">Screen Editor</p>
              </div>
              <div class="card-body editable">
                <div class="row">
                  <div class="col-12">
                    <button @click="openSettingModal" class="button-edit-pen"></button>
                    <p>
                      <span class="sf-text-strong mr-2">{{ $t('default-background-color') }}</span>
                      <span>{{
                        (infoUser && infoUser.default_settings.default_background_color) ||
                        '#ffffff'
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="card-body editable">
                <div class="row">
                  <div class="col-12">
                    <p>
                      <span class="sf-text-strong mr-2">{{ $t('default-text-font') }}</span>
                      <span>{{
                        (infoUser && infoUser.default_settings.default_text_font) || 'Roboto'
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="card-body editable">
                <div class="row">
                  <div class="col-12">
                    <p>
                      <span class="sf-text-strong mr-2">{{ $t('default-text-color') }}</span>
                      <span>{{
                        (infoUser && infoUser.default_settings.default_text_color) || '#223144'
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SfModal
      :title="$t('modal.title.edit-number-of-asset')"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEditNumberAsset"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group sf-label-group-lg">
                  <label class="primary-label">{{ $t('default-number-asset') }}</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    :value="editDefaultSlide.user.default_number_asset"
                    :options="[
                      { id: 25, text: '25' },
                      { id: 50, text: '50' },
                      { id: 100, text: '100' }
                    ]"
                    @change="changeNumberAsset"
                    :settings="{ minimumResultsForSearch: -1 }"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button
                  class="sf-secondary"
                  type="button"
                  @click="isShownModalEditNumberAsset = false"
                >
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="editNumberOfAsset">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>
    <SfModal
      :title="$t('modal.title.edit-duration')"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group sf-label-group-lg">
                  <label class="primary-label">{{ $t('default-media-duration') }}</label>
                </div>
                <input
                  type="text"
                  v-model="editDefaultSlide.user.default_slide_duration"
                  class="sf-input-text"
                  :class="{ 'input-error-form': errors.slideDuration }"
                  @input="allowOnlyDigit()"
                />
              </div>
              <span
                v-if="errors.slideDuration"
                class="error-text text-right"
                style="margin-left: 38%"
              >
                {{ $t('enter-a-valid-default-media-duration') }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalEdit = false">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="editSlideDuration">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>

    <SfModal
      :title="$t('modal.title.edit-default-settings')"
      :width="768"
      closeOnOverlay
      :show.sync="isSlideTextModalOpen"
    >
      <div class="sf-modal-body">
        <!-- Your modal content here -->
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="col-6">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">{{ $t('default-text-color') }}</label>
                  </div>
                  <ColorPicker
                    :postion="'right'"
                    class="color-picker-background"
                    :colorPickerType="'textColor'"
                    v-model="editDefaultSlide.user.default_text_color"
                    :color="editDefaultSlide.user.default_text_color"
                    @onColorChange="changeDefaultTextColor($event, 'default_text_color')"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="col-6">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">{{ $t('default-background-color') }}</label>
                  </div>
                  <ColorPicker
                    :postion="'right'"
                    class="color-picker-background"
                    :colorPickerType="'textColor'"
                    v-model="editDefaultSlide.user.default_background_color"
                    :color="editDefaultSlide.user.default_background_color"
                    @onColorChange="changeDefaultTextColor($event, 'default_background_color')"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="col-6">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">{{ $t('default-font-family') }}</label>
                  </div>
                  <div class="sf-select-group ml-2 pl-2">
                    <Select2
                      class="sf-select2 default-font-text"
                      v-model="editDefaultSlide.user.default_text_font"
                      :options="fonts"
                      :settings="{ placeholder: 'Select font' }"
                      @change="editDefaultSlide.user.default_text_font = $event"
                    />
                    <span class="lb-right caret"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isSlideTextModalOpen = false">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="updateSlideSettings">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>
  </div>
</template>

<script>
import concat from 'lodash/concat'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import { mapState, mapMutations } from 'vuex'
import SfModal from '@/components/SfModal.vue'
import UsersService from '../../services/users.service'
import { SITE_DOMAIN } from '@/.env'
import Select2 from 'v-select2-component'
// import { DEFAULT_SLIDE_BACKGROUND_COLOR, DEFAULT_SLIDE_TEXT_COLOR, DEFAULT_SLIDE_TEXT_FONT } from '../../constant'
import { fonts } from '@/constant/font'
import ColorPicker from '@/components/smartAssetEditor/ColorPicker'
import CustomFontService from '@/services/custom-font.service'

export default {
  name: 'Settings',
  components: {
    SfModal,
    Select2,
    ColorPicker
  },

  data() {
    return {
      isShownModalEdit: false,
      isShownModalEditNumberAsset: false,
      isSlideTextModalOpen: false,
      inputError: false,
      checkboxState: false,
      errors: {
        slideDuration: false
      },

      pagination: {
        limit: 20,
        offset: 0,
        total: 60
      },
      editDefaultSlide: {
        user: {
          default_slide_duration: 0,
          default_text_color: '',
          default_text_font: '',
          default_background_color: ''
        }
      },
      fonts: fonts
    }
  },
  created() {
    this.getCustomFont()
  },
  methods: {
    allowOnlyDigit(word) {
      this.errors.slideDuration = false
      return (this.editDefaultSlide.user.default_slide_duration =
        this.editDefaultSlide.user.default_slide_duration.replace(/[^0-9]/g, ''))
    },
    ...mapMutations('user', {
      setDurationToStore: 'SET_DEFAULT_SLIDE_DURATION'
    }),
    showToast() {
      this.$store.commit('toast/NEW', { type: 'success', message: 'hello' })
    },
    submitModalHandler() {
      // some logic
      this.isShownModal = false
    },
    openSettingModal() {
      this.isSlideTextModalOpen = true
      this.editDefaultSlide.user = this.infoUser.default_settings
    },
    onBlur() {},
    onEnter() {},
    onEsc() {},
    onClickIcon() {},
    getInfoUser() {
      this.$store.dispatch('user/getCurrent')
    },
    shownModalEditSettings() {
      this.isShownModalEdit = true
      this.editDefaultSlide.user.default_slide_duration = this.infoUser.default_slide_duration
    },
    shownModalEditNumberAsset() {
      this.isShownModalEditNumberAsset = true
      this.editDefaultSlide.user.default_number_asset = this.infoUser.default_number_asset || 25
    },
    changeDefaultTextColor(value, attr) {
      this.editDefaultSlide.user[attr] = value
    },
    updateSlideSettings() {
      const user = {
        default_settings: this.editDefaultSlide.user
      }
      UsersService.updateProfileUser(user)
        .then((_) => {
          this.isSlideTextModalOpen = false
          this.$toast.success('Settings successfully updated')
        })
        .catch((err) => {
          this.isSlideTextModalOpen = false
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    editSlideDuration() {
      const shallow = JSON.parse(JSON.stringify(this.editDefaultSlide))
      const slideDuration = shallow.user.default_slide_duration
      if (
        slideDuration === '' ||
        slideDuration === 0 ||
        slideDuration === '0' ||
        parseInt(slideDuration) > 3000
      ) {
        this.errors.slideDuration = true
        return
      }
      shallow.user.default_slide_duration = shallow.user.default_slide_duration * 1000
      UsersService.updateProfileUser(shallow)
        .then((_) => {
          this.isShownModalEdit = false
          // this.getInfoUser()
          this.$toast.success('Settings successfully updated')
        })
        .catch((err) => {
          this.isShownModalEdit = false
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
        .finally(() => {
          this.errors.slideDuration = false
        })
    },
    editNumberOfAsset() {
      let shallow = JSON.parse(JSON.stringify(this.editDefaultSlide))
      UsersService.updateProfileUser(shallow)
        .then((_) => {
          this.isShownModalEditNumberAsset = false
          // this.getInfoUser()
          this.$toast.success('Settings successfully updated')
        })
        .catch((err) => {
          this.isShownModalEditNumberAsset = false
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    changeNumberAsset(e) {
      this.editDefaultSlide.user.default_number_asset = e
    },
    async getCustomFont() {
      CustomFontService.getCustomFont()
        .then((res) => {
          if (res.data.length > 0) {
            res.data.forEach((item) => {
              const font = new FontFace(item.name, `url(${item.file})`)
              font.load().then(() => {
                document.fonts.add(font)
              })
            })
            const customFontInOrg = res.data.map((font) => ({ id: font.name, text: font.name }))
            this.fonts = uniqBy(concat(this.fonts, customFontInOrg), 'id')
          }
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    }
  },
  channels: {
    AccountChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (data.action === 'update') {
          this.setDurationToStore(data.data.default_slide_duration / 1000)
        }
      },
      disconnected() {}
    }
  },
  mounted() {
    this.$cable.subscribe({
      channel: 'AccountChannel',
      organization: this.$cookies.get('user').organization_id
    })
    // this.getInfoUser()
  },
  computed: {
    ...mapState('user', {
      infoUser: 'infoUser'
    }),
    metadataUrl() {
      return this.infoUser
        ? SITE_DOMAIN + '/auth/sso/metadata/' + get(this.infoUser, 'organization.slug', '')
        : ''
    }
  },
  watch: {
    'editDefaultSlide.user.default_slide_duration': function (value) {
      if (value < 0) {
        this.editDefaultSlide.user.default_slide_duration = 0
      }
    }
  },
  beforeDestroy() {
    this.$cable.unsubscribe('AccountChannel')
  }
}
</script>

<style lang="scss" scoped>
.default-font-text {
  min-width: 126px;
}
@import './accountPage.scss';
</style>
