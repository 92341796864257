<template>
  <div class="px-3 folder-path">
    <span>
      Library
      <span class="px-2">/</span>
    </span>
    <template v-for="(path, index) in paths">
      <span :key="index" class="folder-child" :class="{ 'last-item': index === paths.length - 1 }">
        {{ path }}
        <span v-if="index !== paths.length - 1" class="px-2">/</span>
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'FolderPath',
  props: {
    paths: {
      type: Array,
      require: true
    }
  }
}
</script>

<style>
.folder-path {
  font-weight: 600;
}
.last-item {
  color: #6c757d;
}
.folder-child {
  word-break: break-all;
}
</style>
