import BaseService from './base.service'
import { API_URL } from '../.env'
import axios from 'axios'
import { ResponseWrapper, ErrorWrapper } from './util'

class PasswordService extends BaseService {
  get entity() {
    return 'passwords'
  }

  sendEmail(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/${this.entity}`, data)
        .then((response) => {
          return resolve(new ResponseWrapper(response, response.data, response.data.message))
        })
        .catch((error) => {
          reject(new ErrorWrapper(error))
        })
    })
  }

  resetPassword(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}/${this.entity}/reset`, data)
        .then((response) => {
          return resolve(new ResponseWrapper(response, response.data, response.data.message))
        })
        .catch((error) => {
          reject(new ErrorWrapper(error))
        })
    })
  }
}

export default new PasswordService()
