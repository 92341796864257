<template>
  <div class="left-sidebar">
    <div class="sf-menu-wrapper">
      <ul class="sf-menus">
        <li class="menu-item" id="v-step-1" v-bind:class="{ active: currentPage == 'index' }">
          <router-link
            v-bind:style="{ backgroundImage: `url(${overviewIcon})` }"
            :to="{ name: 'index' }"
            exact
            >{{ $t('overview') }}</router-link
          >
        </li>
        <li
          class="menu-item"
          id="v-step-5"
          v-if="checkMenu('library')"
          v-bind:class="{ active: currentPage == 'library' }"
        >
          <router-link
            v-bind:style="{ backgroundImage: `url(${libraryIcon})` }"
            :to="{ name: 'library' }"
            >{{ $t('library') }}</router-link
          >
        </li>
        <li
          class="menu-item"
          id="v-step-6"
          v-if="checkMenu('loop')"
          v-bind:class="{ active: currentPage == 'loop' || currentPage == 'editLoop' }"
        >
          <router-link
            v-bind:style="{ backgroundImage: `url(${loopIcon})` }"
            :to="{ name: 'loop' }"
            >{{ $t('loops') }}</router-link
          >
        </li>
        <li
          class="menu-item"
          id="v-step-8"
          v-if="checkMenu('campaigns')"
          v-bind:class="{ active: currentPage == 'campaign' || currentPage == 'editCampaign' }"
        >
          <router-link
            v-bind:style="{ backgroundImage: `url(${campaignIcon})` }"
            :to="{ name: 'campaign' }"
            >{{ $t('campaigns') }}</router-link
          >
        </li>
        <li
          class="menu-item"
          id="v-step-9"
          v-if="checkMenu('player')"
          v-bind:class="{
            active: currentPage == 'players-page' || currentPage == 'viewPlayersPage'
          }"
        >
          <router-link
            v-bind:style="{ backgroundImage: `url(${playerIcon})` }"
            :to="{ name: 'players-page' }"
            >{{ $t('players') }}</router-link
          >
        </li>
        <li
          class="menu-item"
          v-if="checkMenu('account')"
          v-bind:class="{ active: currentPage == 'user' || currentPage == 'viewUser' }"
        >
          <router-link
            v-bind:style="{ backgroundImage: `url(${accountIcon})` }"
            :to="{ path: `/users/${user.id}` }"
            >{{ $t('account') }}</router-link
          >
        </li>
        <li v-if="checkIsAdmin" class="menu-item" v-bind:class="{ active: currentPage == 'admin' }">
          <router-link
            v-bind:style="{ backgroundImage: `url(${adminIcon})` }"
            :to="{ name: 'admin' }"
            >{{ $t('admin') }}</router-link
          >
        </li>
        <!-- <li class="menu-item">
          <a v-bind:style="{backgroundImage: `url(${logoutIcon})`}" @click="logout">Logout</a>
        </li> -->
        <li class="menu-item" v-if="checkMenu('account')">
          <a
            href="https://help.screenfluence.com/"
            target="_blank"
            v-bind:style="{ backgroundImage: `url(${helpLinkIcon})` }"
            >{{ $t('help') }}</a
          >
        </li>
        <li class="menu-item">
          <a @click="runTutorialsTour">{{ $t('product-tour') }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import overviewIcon from '@/assets/images/menu/overview.svg'
import libraryIcon from '@/assets/images/menu/library.svg'
import loopIcon from '@/assets/images/menu/loops.svg'
import campaignIcon from '@/assets/images/menu/campaign.svg'
import playerIcon from '@/assets/images/menu/players.svg'
import accountIcon from '@/assets/images/menu/account.svg'
import adminIcon from '@/assets/images/menu/admin.svg'
import logoutIcon from '@/assets/images/menu/signout.svg'
import helpLinkIcon from '@/assets/images/menu/help-link.svg'
import * as authService from '../services/auth.service'
import { EventBus } from '@/event-bus.js'
import permissonMixin from '@/mixins/permissionMixin'
export default {
  name: 'LeftSidebar',
  mixins: [permissonMixin],
  data() {
    return {
      currentYear: new Date().getFullYear(),
      logoutIcon,
      overviewIcon,
      libraryIcon,
      loopIcon,
      campaignIcon,
      playerIcon,
      accountIcon,
      helpLinkIcon,
      adminIcon,
      user: {},
      currentMenu: {}
    }
  },
  computed: {
    currentPage() {
      return this.$route.matched[0].name
    },
    checkIsAdmin() {
      if (this.user) {
        return this.user.user_type === 'employee'
      }
      return false
    },
    currentPageToCheck() {
      return this.$route.meta.currentPage
    }
  },
  methods: {
    logout() {
      authService.makeLogout()
    },
    runTutorialsTour() {
      this.$emit('run-tour')
    }
  },
  created() {
    EventBus.$on('refresh_current_user', (value) => {
      const user = this.$cookies.get('user')
      if (
        value.user.user_type === 'customer' &&
        JSON.stringify(user.menu) !== JSON.stringify(value.user.menu)
      ) {
        authService.makeLogout()
        this.$toast.open({
          type: 'error',
          message: 'Admin just updated your role'
        })
        EventBus.$off('refresh_current_user')
      }
    })
  }
}
</script>
