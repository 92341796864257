export default {
  SET_FB_LONG_LIVED_ACCESS_TOKEN(state, payload) {
    state.fbLongLivedAccessToken = payload
  },
  SET_FB_PAGES(state, payload) {
    state.igUserPages = payload
  },
  SET_INSTAGRAM_BUSINESS_ACCOUNT_ID(state, payload) {
    state.igBusinessAccountId = payload
  },
  SET_LIST_TIMEZONE(state, data) {
    state.listTimezone = data
  }
}
