<template>
  <div class="row profile-account-page">
    <div class="col-sm-6 col-12">
      <div class="sf-card">
        <div class="card-heading">
          <p class="title-section">{{ $t('user') }}</p>
        </div>
        <div class="card-body editable">
          <div class="row">
            <div class="col-12">
              <button @click="editProfile" class="button-edit-pen"></button>
              <div class="mt-3">
                <p>
                  <span class="sf-text-strong mr-2">{{ $t('first-name') }}</span>
                  <span>{{ infoUser.first_name }}</span>
                </p>
              </div>
              <div class="mt-3">
                <p>
                  <span class="sf-text-strong mr-2">{{ $t('last-name') }}</span>
                  <span>{{ infoUser.last_name }}</span>
                </p>
              </div>
              <div class="mt-3">
                <p>
                  <span class="sf-text-strong mr-2">{{ $t('email') }}</span>
                  <span>{{ infoUser.email }}</span>
                </p>
              </div>

              <div class="mt-3">
                <div class="row">
                  <div class="col-6">
                    <p>
                      <span class="sf-text-strong mr-2">{{ $t('password') }}</span>
                    </p>
                  </div>
                  <div class="col-6">
                    <div class="mb-2 d-flex justify-content-end">
                      <button class="sf-primary" type="button" @click="shownModalChangePassword">
                        {{ $t('change-password') }}
                      </button>
                    </div>
                    <div class="mb-2 d-flex justify-content-end">
                      <button
                        v-if="is_2fa"
                        class="sf-primary"
                        type="button"
                        @click="shownModal2FaDisable"
                      >
                        {{ $t('disable-2fa') }}
                      </button>
                      <button v-else class="sf-primary" type="button" @click="shownModal2Fa">
                        <img src="@/assets/images/shield.svg" class="icon" />
                        {{ $t('enable-2fa') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="sf-card">
        <div class="card-heading">
          <p>{{ $t('activity') }}</p>
        </div>
        <div class="card-body">
          <table class="table">
            <tbody>
              <tr v-for="(log, index) in userLogs" :key="index">
                <td class="td-content">
                  <div class="wrap-img">
                    <i aria-hidden="true" class="fa fa-tags fa-lg"></i>
                  </div>
                  <div class="wrap-p">
                    <p class="pad-par">
                      {{
                        `${log.user} ${$t('logs.' + log.action_type)} ${$t(
                          'logs.' + log.object_name
                        )} ${log.displayname} ${$t('in')}`
                      }}:
                      <router-link v-if="log.obj_name === 'Library'" to="/library">{{
                        $t(log.obj_name.toLowerCase())
                      }}</router-link>
                      <router-link v-else-if="log.obj_name === 'Players'" to="/players">{{
                        $t(log.obj_name.toLowerCase())
                      }}</router-link>
                      <router-link v-else-if="log.obj_name === 'Loops'" to="/loop_assets">{{
                        $t(log.obj_name.toLowerCase())
                      }}</router-link>
                      <router-link v-else-if="log.obj_name === 'Campaigns'" to="/campaigns">{{
                        $t(log.obj_name.toLowerCase())
                      }}</router-link>
                      <router-link
                        v-else-if="log.obj_name === 'Users'"
                        :to="`/users/${userID}/all_users`"
                        >{{ $t(log.obj_name.toLowerCase()) }}</router-link
                      >
                    </p>
                  </div>
                </td>
                <td class="td-hours">{{ timeSince(log.act_time) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <SfModal
      :title="$t('modal.title.edit-profile')"
      :width="786"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('first-name') }}</label>
                </div>
                <input
                  :placeholder="$t('first-name')"
                  v-model="editProfileForm.user.first_name"
                  class="sf-input-text"
                  maxlength="50"
                />
              </div>
              <p v-if="!editProfileForm.user.first_name && showError" class="error-text text-right">
                {{ $t('first-name-required') }}
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('last-name') }}</label>
                </div>
                <input
                  :placeholder="$t('last-name')"
                  v-model="editProfileForm.user.last_name"
                  class="sf-input-text"
                  maxlength="50"
                />
              </div>
              <p v-if="!editProfileForm.user.last_name && showError" class="error-text text-right">
                {{ $t('last-name-required') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('email') }}</label>
                </div>
                <input
                  :placeholder="$t('email')"
                  v-model="editProfileForm.user.email"
                  class="sf-input-text"
                />
              </div>
              <p v-if="!editProfileForm.user.email && showError" class="error-text text-right">
                {{ $t('enter-an-email-address') }}
              </p>
              <p
                v-else-if="editProfileForm.user.email && !checkEmail(editProfileForm.user.email)"
                class="error-text text-right"
              >
                {{ $t('enter-a-valid-email-address') }}
              </p>
              <p v-else-if="!emailCanUsed" class="error-text text-right">
                {{ $t('email-existed') }}
              </p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('language') }}</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="editProfileForm.user.language"
                    :options="languageConfig"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalEdit = false">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="saveUpdateProfile">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>

    <SfModal
      :title="$t('modal.title.edit-password')"
      :width="600"
      closeOnOverlay
      :show.sync="isShownModalEdit1"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('old-passsword') }}</label>
                </div>
                <input
                  type="password"
                  :placeholder="$t('old-passsword')"
                  v-model="updatePasswordForm.current_password"
                  class="sf-input-text"
                />
              </div>
              <p
                v-if="!updatePasswordForm.current_password && showError"
                class="error-text text-right"
              >
                {{ $t('enter-your-old-password') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('new-password') }}</label>
                </div>
                <input
                  type="password"
                  :placeholder="$t('new-password')"
                  v-model="updatePasswordForm.user.password"
                  class="sf-input-text"
                />
              </div>
              <p
                v-if="!updatePasswordForm.user.password && showErrorPassword"
                class="error-text text-right"
              >
                {{ $t('enter-a-new-password') }}
              </p>
              <p
                v-else-if="
                  updatePasswordForm.user.password &&
                  !checkPassword(updatePasswordForm.user.password) &&
                  showErrorPassword
                "
                class="error-text text-right"
              >
                {{ $t('strong-password-required') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('confirm-new-password') }}</label>
                </div>
                <input
                  type="password"
                  :placeholder="$t('confirm-password')"
                  v-model="updatePasswordForm.user.password_confirmation"
                  class="sf-input-text"
                />
              </div>
              <p
                v-if="
                  updatePasswordForm.user.password_confirmation &&
                  updatePasswordForm.user.password !=
                    updatePasswordForm.user.password_confirmation &&
                  showErrorPassword
                "
                class="error-text text-right"
              >
                {{ $t('repeat-the-password') }}
              </p>
              <p
                v-else-if="!updatePasswordForm.user.password_confirmation && showErrorPassword"
                class="error-text text-right"
              >
                {{ $t('repeat-the-password') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalEdit1 = false">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="saveUpdatePassword">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>
    <SfModal
      :title="$t('modal.title.2fa')"
      :width="640"
      closeOnOverlay
      :show.sync="isShownModal2Fa"
    >
      <div class="row exception-row">
        <div class="col-12 header-modal-exception note-2fa">
          <p v-html="`${$t('download-google-authenticator')}`" />
        </div>

        <div class="col-12 header-modal-exception">
          <img class="qrcode" :src="urlQrCode" />
        </div>

        <div class="col-12 header-modal-exception note-2fa">
          <p v-html="`${$t('enter-digit', { email: infoUser.email })}`" />
        </div>

        <div class="col-12 header-modal-exception">
          <vue-auth-code-input @inputChange="handleChangeCode" @inputComplete="enable2Fa" />
        </div>
      </div>
      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="cancelSetUp2Fa">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="enable2Fa">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>

    <SfDeleteModal
      :title="$t('modal.title.confirm-delete')"
      closeOnOverlay
      :show.sync="isShownModal2FaDisable"
    >
      <div class="content-delete">
        {{ $t('disable-2falogin-message') }}
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModal2FaDisable = false">
          {{ $t('cancel') }}
        </button>
        <button class="sf-primary" @click="disable2Fa">{{ $t('ok') }}</button>
      </div>
    </SfDeleteModal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import UsersService from '../../services/users.service'
import VueAuthCodeInput from 'vue-auth-code-input'
import { timeSince } from '@/helper/helper'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import SfModal from '@/components/SfModal.vue'
import { language } from '@/config'
import Select2 from 'v-select2-component'
export default {
  name: 'profile',
  components: {
    VueAuthCodeInput,
    SfModal,
    SfDeleteModal,
    Select2
  },

  data() {
    return {
      content: '',
      languageConfig: language(),
      isShownModal: false,
      inputError: false,
      checkboxState: false,
      isShownModalEdit: false,
      isShownModalEdit1: false,
      isShownModal2Fa: false,
      isShownModal2FaDisable: false,
      is_2fa: false,
      urlQrCode: '',
      code: '',
      editProfileForm: {
        user: {
          first_name: '',
          last_name: '',
          email: '',
          language: ''
        }
      },
      updatePasswordForm: {
        current_password: null,
        user: {
          password: null,
          password_confirmation: null
        }
      },
      showError: false,
      showErrorPassword: false,
      emailCanUsed: true,
      userID: '',
      currentLanguage: ''
    }
  },

  methods: {
    ...mapMutations('user', {
      setDataUserToStore: 'SET_CURRENT_USER',
      updateFromSocket: 'UPDATE_FROM_SOCKET'
    }),
    timeSince,
    showToast() {
      this.$store.commit('toast/NEW', { type: 'success', message: 'hello' })
    },
    submitModalHandler() {
      // some logic
      this.isShownModal = false
    },
    onBlur() {},
    onEnter() {},
    onEsc() {},
    onClickIcon() {},
    getInfoUser() {
      this.$store.dispatch('user/getCurrent')
    },
    saveUpdateProfile() {
      if (
        this.editProfileForm.user.first_name &&
        this.editProfileForm.user.last_name &&
        this.editProfileForm.user.email &&
        this.emailCanUsed &&
        this.checkEmail(this.editProfileForm.user.email)
      ) {
        UsersService.updateProfileUser(this.editProfileForm)
          .then((res) => {
            this.isShownModalEdit = false
            this.$toast.success('Profile successfully updated')
            this.$cookies.remove('language')
            this.$cookies.set('language', res.data.language || 'en')
            if (this.currentLanguage !== res.data.language) {
              this.$bus.$emit('changeLanguage', {
                language: res.data.language || 'en'
              })
              this.$i18n.locale = res.data.language
              this.currentLanguage = res.data.language
            }
            this.getInfoUser()
          })
          .catch((err) => {
            this.isShownModalEdit = false
            this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
          })
      } else {
        this.showError = true
      }
    },
    checkPassword(text) {
      // var strongPassword = new RegExp('(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$')
      const strongPassword = new RegExp('(?=.*[A-Z])(?=.*[0-9]).{8,}$')
      if (text && strongPassword.test(text)) {
        return true
      } else {
        return false
      }
    },
    checkEmail(email) {
      // eslint-disable-next-line
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    saveUpdatePassword() {
      if (
        this.updatePasswordForm.current_password &&
        this.updatePasswordForm.user.password &&
        this.checkPassword(this.updatePasswordForm.user.password) &&
        this.updatePasswordForm.user.password === this.updatePasswordForm.user.password_confirmation
      ) {
        UsersService.updateProfileUser(this.updatePasswordForm)
          .then((_) => {
            this.isShownModalEdit1 = false
            this.$toast.success('Password successfully updated')
          })
          .catch((err) => {
            this.isShownModalEdit1 = false
            this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
          })
      } else {
        this.showErrorPassword = true
      }
    },
    shownModalChangePassword() {
      this.isShownModalEdit1 = true
      this.showErrorPassword = false
      this.updatePasswordForm.current_password = null
      this.updatePasswordForm.user.password = null
      this.updatePasswordForm.user.password_confirmation = null
    },
    editProfile() {
      this.isShownModalEdit = true
      this.currentLanguage = this.infoUser.language
      this.editProfileForm.user.first_name = this.infoUser.first_name
      this.editProfileForm.user.last_name = this.infoUser.last_name
      this.editProfileForm.user.email = this.infoUser.email
      this.emailCanUsed = true
      this.showError = false
    },
    changeFirstName(e) {
      this.editProfileForm.user.first_name = e.target.value
    },
    changeLastName(e) {
      this.editProfileForm.user.last_name = e.target.value
    },
    changeEmail(e) {
      this.editProfileForm.user.email = e.target.value
    },
    changeCurrentPassword(e) {
      this.updatePasswordForm.current_password = e.target.value
    },
    changePassword(e) {
      this.updatePasswordForm.user.password = e.target.value
    },
    changePasswordConfirm(e) {
      this.updatePasswordForm.user.password_confirmation = e.target.value
    },
    checkEmailExist() {
      UsersService.checkEmailExist({
        user_id: this.infoUser.id,
        email: this.editProfileForm.user.email
      })
        .then((res) => {
          this.emailCanUsed = res.data.can_use
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong.')
        })
    },
    getUserID() {
      const userStr = this.$cookies.get('user')
      if (userStr) {
        this.userID = userStr.id
      }
    },
    shownModal2Fa() {
      UsersService.setup2Fa()
        .then((res) => {
          this.urlQrCode = res.data.url
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
        .finally(() => {
          this.isShownModal2Fa = true
        })
    },
    shownModal2FaDisable() {
      this.isShownModal2FaDisable = true
    },
    disable2Fa() {
      UsersService.disable2Fa()
        .then((res) => {
          this.is_2fa = false
          this.$toast.success('User was successfully disable 2FA')
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
        .finally(() => {
          this.isShownModal2FaDisable = false
        })
    },
    enable2Fa() {
      UsersService.enable2Fa({ code: this.code })
        .then((res) => {
          this.is_2fa = true
          this.$toast.success('User was successfully ensable 2FA')
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
        .finally(() => {
          this.isShownModal2Fa = false
        })
    },
    cancelSetUp2Fa() {
      UsersService.disable2Fa()
        .then((res) => {})
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong')
        })
        .finally(() => {
          this.isShownModal2Fa = false
        })
    },
    handleChangeCode(code, index) {
      this.code = code.toString().replace(/,/g, '')
    }
  },
  mounted() {
    this.getInfoUser()
    this.getUserID()
    this.$cable.subscribe({
      channel: 'AccountChannel',
      organization: this.$cookies.get('user').organization_id
    })
    this.$cable.subscribe({
      channel: 'OverviewChannel',
      organization: this.$cookies.get('user').organization_id
    })
  },
  computed: {
    ...mapState('user', {
      infoUser: 'infoUser'
    }),
    ...mapGetters('user', {
      userLogs: 'userLogs'
    })
  },
  channels: {
    AccountChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (data.action === 'update') {
          this.is_2fa = data.data.is_2fa
          this.setDataUserToStore(data.data)
        }
      },
      disconnected() {}
    },
    OverviewChannel: {
      connected() {},
      rejected() {},
      received(data) {
        const infoUser = this._.cloneDeep(this.infoUser)
        infoUser.organization.user_logs.unshift(data.data.user_log)
        infoUser.organization.user_logs.pop()
        this.updateFromSocket(infoUser)
      },
      disconnected() {}
    }
  },
  watch: {
    infoUser(value) {
      this.editProfileForm.user.first_name = value.first_name
      this.editProfileForm.user.last_name = value.last_name
      this.editProfileForm.user.email = value.email
      this.editProfileForm.user.language = value.language || 'en'
      this.is_2fa = value.is_2fa
    }
  },
  beforeDestroy() {
    this.$cable.unsubscribe('OverviewChannel')
    this.$cable.unsubscribe('AccountChannel')
  }
}
</script>

<style lang="scss" scoped>
.user-description {
  color: #9b9b9b;
  font: 14px 'Open Sans', sans-serif;
  padding-left: 30px;
}
.custom-label-account {
  margin-left: 15px;
}
.btn-edit-password {
  border-radius: 4px;
  background-color: #55a5f9;
  color: #ffffff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  margin: 5px 30px;
  font: 14px 'Open Sans', sans-serif;
  .custom-icon {
    width: 20px;
  }
}
.td-content {
  display: flex;
}
.pad-par {
  padding-left: 10px;
}
.user-description1 {
  color: #9b9b9b;
  font: 14px 'Open Sans', sans-serif;
  padding-left: 30px;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  font: 14px 'Open Sans', sans-serif;
  font-weight: 600;
  cursor: default;
}
.btn-edit-password {
  &:hover {
    color: #ffffff;
  }
}

.exception-row {
  margin: 0;
  margin-top: 20px;
  padding-left: 0;
  margin-left: 15px;
  .header-modal-exception {
    padding-left: 24px;
    &.note-2fa {
      padding-bottom: 15px;
      p {
        color: #002476;
        span {
          font-weight: 700;
        }
      }
    }
    .input-exception {
      height: 40px;
      border-color: #e8e8e8;
      border-radius: 4px;
      padding: 6px 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font: 14px 'Opens Sans', sans-serif;
      color: #212529;
    }
    .check-box {
      margin-top: 9px;
      &:nth-child(2) {
        margin-left: 25px;
      }
      span {
        cursor: pointer;
        width: 20px;
        height: 20px;
        border: 1px solid #126cdb;
        border-radius: 2px;
      }
      p {
        margin-left: 8px;
      }
    }
    .qrcode {
      margin-left: auto;
      margin-right: auto;
      display: block;
      width: 200px;
      height: 200px;
      margin-bottom: 10px;
    }
  }
  .name-exception {
    text-transform: capitalize;
    font: 14px 'Open Sans', sans-serif;
    color: #333333;
    font-weight: bold;
    margin: 10px 0 9px;
  }
  .custom-input {
    height: 34px !important;
    width: 100%;
    border: 0 !important;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  }
}
</style>
