export default {
  hardwareOption: (state) => {
    const listHardwareOptions = [
      {
        id: 0,
        text: 'Please select a model'
      }
    ]
    state.items.map((hardware) => {
      return listHardwareOptions.push({
        id: hardware.id,
        text: hardware.model_number
      })
    })
    return listHardwareOptions
  },
  hardwareOptionEdit: (state) => {
    const listHardwareOptions = []
    state.items.map((hardware) => {
      return listHardwareOptions.push({
        id: hardware.id,
        text: hardware.model_number
      })
    })
    return listHardwareOptions
  },
  isEmpty: (state) => state.items.length === 0
}
