<template>
  <div class="uikit uikit-card">
    <div class="container-fluid">
      <div class="row">
        <div class="col-3">
          <div class="sf-card">
            <div class="sf-card-body h-145"></div>
          </div>
        </div>

        <div class="col-3">
          <div class="sf-card">
            <div class="sf-card-body h-145"></div>
          </div>
        </div>

        <div class="col-3">
          <div class="sf-card">
            <div class="sf-card-body h-145"></div>
          </div>
        </div>

        <div class="col-3">
          <div class="sf-card">
            <div class="sf-card-body h-145"></div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <div class="sf-card">
            <div class="sf-card-body h-360"></div>
          </div>
        </div>

        <div class="col-6">
          <div class="sf-card">
            <div class="sf-card-body h-360"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {}
  },
  created() {},
  methods: {},
  computed: {}
}
</script>

<style lang="scss" scoped>
.h-145 {
  height: 145px;
}
.h-360 {
  height: 360px;
}
</style>
