<template>
  <div class="uikit uikit-field">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="material-name">
            <h2>Input field</h2>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-4">
          <div class="material-title">TEXT FIELD</div>
        </div>
        <div class="col-3">
          <input type="text" placeholder="placeholder text" class="sf-input-text" />
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-4">
          <div class="material-title">CHECKBOX</div>
        </div>
        <div class="col-6">
          <label class="sf-checkbox">
            <span class="check-title">Label</span>
            <input type="checkbox" checked="checked" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-4">
          <div class="material-title">RADIO</div>
        </div>
        <div class="col-6">
          <label class="sf-radio"
            >One
            <input type="radio" checked="checked" name="radio" />
            <span class="checkmark"></span>
          </label>
          <label class="sf-radio"
            >Two
            <input type="radio" name="radio" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-4">
          <div class="material-title">SELECT & SEARCH</div>
        </div>
        <div class="col-3">
          <div class="sf-select-group mb-3">
            <Select2
              class="sf-select2"
              v-model="selectValue"
              :options="[
                { id: '1', text: 'Option 1' },
                { id: '2', text: 'Option 2' },
                { id: '3', text: 'Option 3' }
              ]"
            />
            <span class="lb-right caret"></span>
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-4">
          <div class="material-title">LIST ITEM</div>
        </div>
        <div class="col-6">
          <button class="list-item">List item</button>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-4">
          <div class="material-title">LIST ITEM ACTIVE</div>
        </div>
        <div class="col-6">
          <button class="list-item active">List item</button>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-4">
          <div class="material-title">LIST ITEM WITH ICON</div>
        </div>
        <div class="col-6">
          <button class="list-item-icon">List item</button>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-4">
          <div class="material-title">LIST ITEM WITH ICON ACTIVE</div>
        </div>
        <div class="col-6">
          <button class="list-item-icon">List item</button>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-4">
          <div class="material-title">SEARCH</div>
        </div>
        <div class="col-3">
          <div class="sf-search-wrap">
            <input
              type="text"
              v-model="searchValue"
              placeholder="Search loop"
              class="sf-input-search"
            />
            <span
              @click="onSearchClick"
              v-bind:style="{ backgroundImage: `url(${getSearchIcon})` }"
              class="sf-input-search-icon"
            ></span>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-4">
          <div class="material-title">TEXT FIELD AND LABELS</div>
        </div>
        <div class="col-5">
          <div class="sf-form-group">
            <div class="sf-label-group">
              <label class="primary-label">Label</label>
            </div>
            <input type="text" placeholder="placeholder text" class="sf-input-text" />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-4">
          <div class="material-title">TEXT FIELD AND LABELS</div>
        </div>
        <div class="col-5">
          <div class="sf-form-group multi-row">
            <div class="sf-label-group">
              <label class="primary-label">Primary Label</label>
              <label class="secondary-label">Secondary label </label>
            </div>
            <input type="text" placeholder="placeholder text" class="sf-input-text" />
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-4">
          <div class="material-title">TEXT FIELD AND LABELS</div>
        </div>
        <div class="col-5">
          <div class="sf-form-group multi-row">
            <div class="sf-label-group">
              <label class="primary-label">Primary Label</label>
              <label class="secondary-label">Secondary label wrapping to next line </label>
            </div>
            <input type="text" placeholder="placeholder text" class="sf-input-text" />
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-4">
          <div class="material-title">TEXT FIELD</div>
        </div>
        <div class="col-3">
          <vue-tags-input
            v-model="tag"
            :class="'sf-tag'"
            :tags="tags"
            @tags-changed="(newTags) => (tags = newTags)"
          />
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-4">
          <div class="material-title">INPUT RANGE</div>
        </div>
        <div class="col-5">
          <div class="sf-form-group py-3">
            <div class="sf-label-group">
              <label class="primary-label">Label</label>
            </div>
            <div class="sf-range-slider-group pl-2">
              <vue-slider v-model="rangeSlider" class="sf-input-range-slider"></vue-slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Select2 from 'v-select2-component'
import searchIcon from '@/assets/images/sf-search-icon.svg'
import closeIcon from '@/assets/images/close-x.svg'
import VueTagsInput from '@johmun/vue-tags-input'
export default {
  components: {
    Select2,
    VueTagsInput
  },
  data() {
    return {
      searchIcon,
      searchValue: '',
      closeIcon,
      tag: '',
      tags: [],
      rangeSlider: 20
    }
  },
  created() {},
  methods: {
    onSearchClick() {
      if (this.searchValue) {
        this.searchValue = ''
      }
    }
  },
  computed: {
    getSearchIcon() {
      if (this.searchValue) {
        return closeIcon
      } else {
        return searchIcon
      }
    }
  }
}
</script>
