export default {
  isEmpty: (state) => state.items.length === 0,
  roleListNoPagingOptions: (state) => {
    const roleListOptions = [
      {
        id: 0,
        text: 'Select Role'
      }
    ]
    state.roleListByOrgNoPaging.map((role) => {
      roleListOptions.push({
        id: role.id,
        text: role.displayname
      })
    })
    return roleListOptions
  },
  roleListNoPaging: (state) => {
    const roleListOptions = [
      {
        id: 0,
        text: 'Select Role'
      }
    ]
    state.roleListNoPaging.map((role) => {
      roleListOptions.push({
        id: role.id,
        text: role.name
      })
    })
    return roleListOptions
  }
}
