export const fonts = [
  { id: 'Roboto', text: 'Roboto' },
  { id: 'Lato', text: 'Lato' },
  { id: 'Abel', text: 'Abel' },
  { id: 'Alata', text: 'Alata' },
  { id: 'AnticSlab', text: 'AnticSlab' },
  { id: 'Arimo', text: 'Arimo' },
  { id: 'Arvo', text: 'Arvo' },
  { id: 'Anton', text: 'Anton' },
  { id: 'BaiJamjuree', text: 'BaiJamjuree' },
  { id: 'BarlowCondensed', text: 'BarlowCondensed' },
  { id: 'Cairo', text: 'Cairo' },
  { id: 'Farro', text: 'Farro' },
  { id: 'KoHo', text: 'KoHo' },
  { id: 'Tinos', text: 'Tinos' },
  { id: 'ABeeZee', text: 'ABeeZee' },
  { id: 'AbrilFatface', text: 'AbrilFatface' },
  { id: 'Acme', text: 'Acme' },
  { id: 'AdventPro', text: 'AdventPro' },
  { id: 'AlegreyaSans', text: 'AlegreyaSans' },
  { id: 'AlfaSlabOne', text: 'AlfaSlabOne' },
  { id: 'AmaticSC', text: 'AmaticSC' },
  { id: 'Amiri', text: 'Amiri' },
  { id: 'Arapey', text: 'Arapey' },
  { id: 'AsapCondensed', text: 'AsapCondensed' },
  { id: 'Asap', text: 'Asap' },
  { id: 'Assistant', text: 'Assistant' },
  { id: 'BreeSerif', text: 'BreeSerif' },
  { id: 'Catamaran', text: 'Catamaran' },
  { id: 'Caveat', text: 'Caveat' },
  { id: 'ChakraPetch', text: 'ChakraPetch' },
  { id: 'Changa', text: 'Changa' },
  { id: 'ConcertOne', text: 'ConcertOne' },
  { id: 'CreteRound', text: 'CreteRound' },
  { id: 'CrimsonPro', text: 'CrimsonPro' },
  { id: 'Cuprum', text: 'Cuprum' },
  { id: 'DoHyeon', text: 'DoHyeon' },
  { id: 'Economica', text: 'Economica' },
  { id: 'Electrolize', text: 'Electrolize' },
  { id: 'Farro', text: 'Farro' },
  { id: 'Faustina', text: 'Faustina' },
  { id: 'FiraSans', text: 'FiraSans' },
  { id: 'FredokaOne', text: 'FredokaOne' },
  { id: 'GentiumBookBasic', text: 'GentiumBookBasic' },
  { id: 'GloriaHallelujah', text: 'GloriaHallelujah' },
  { id: 'GothicA1', text: 'GothicA1' },
  { id: 'HammersmithOne', text: 'HammersmithOne' },
  { id: 'HindVadodara', text: 'HindVadodara' },
  { id: 'IBMPlexMono', text: 'IBMPlexMono' },
  { id: 'IBMPlexSerif', text: 'IBMPlexSerif' },
  { id: 'IndieFlower', text: 'IndieFlower' },
  { id: 'Inter', text: 'Inter' },
  { id: 'IstokWeb', text: 'IstokWeb' },
  { id: 'Lato', text: 'Lato' },
  { id: 'Limelight', text: 'Limelight' },
  { id: 'LondrinaSolid', text: 'LondrinaSolid' },
  { id: 'Lora', text: 'Lora' },
  { id: 'Inter', text: 'Inter' },
  { id: 'Manrope', text: 'Manrope' },
  { id: 'Mate', text: 'Mate' },
  { id: 'MateSC', text: 'MateSC' },
  { id: 'Monoton', text: 'Monoton' },
  { id: 'MontserratAlternates', text: 'MontserratAlternates' },
  { id: 'Montserrat', text: 'Montserrat' },
  { id: 'MPLUSRounded1c', text: 'MPLUSRounded1c' },
  { id: 'MerriweatherSans', text: 'MerriweatherSans' },
  { id: 'Mukta', text: 'Mukta' },
  { id: 'NanumMyeongjo', text: 'NanumMyeongjo' },
  { id: 'Neuton', text: 'Neuton' },
  { id: 'NoticiaText', text: 'NoticiaText' },
  { id: 'NotoSans', text: 'NotoSans' },
  { id: 'NotoSerif', text: 'NotoSerif' },
  { id: 'Nunito', text: 'Nunito' },
  { id: 'OdibeeSans', text: 'OdibeeSans' },
  { id: 'Oswald', text: 'Oswald' },
  { id: 'Padauk', text: 'Padauk' },
  { id: 'Parisienne', text: 'Parisienne' },
  { id: 'PassionOne', text: 'PassionOne' },
  { id: 'PatrickHand', text: 'PatrickHand' },
  { id: 'PlayfairDisplay', text: 'PlayfairDisplay' },
  { id: 'PlayfairDisplaySC', text: 'PlayfairDisplaySC' },
  { id: 'Play', text: 'Play' },
  { id: 'PoiretOne', text: 'PoiretOne' },
  { id: 'Poppins', text: 'Poppins' },
  { id: 'Prata', text: 'Prata' },
  { id: 'PressStart2P', text: 'PressStart2P' },
  { id: 'PTSans', text: 'PTSans' },
  { id: 'PTSerif', text: 'PTSerif' },
  { id: 'QuattrocentoSans', text: 'QuattrocentoSans' },
  { id: 'Quicksand', text: 'Quicksand' },
  { id: 'Rajdhani', text: 'Rajdhani' },
  { id: 'Raleway', text: 'Raleway' },
  { id: 'Righteous', text: 'Righteous' },
  { id: 'Sacramento', text: 'Sacramento' },
  { id: 'SairaCondensed', text: 'SairaCondensed' },
  { id: 'Sanchez', text: 'Sanchez' },
  { id: 'Sarabun', text: 'Sarabun' },
  { id: 'BebasNeue', text: 'BebasNeue' },
  { id: 'BenchNine', text: 'BenchNine' },
  { id: 'Bitter', text: 'Bitter' },
  { id: 'Cabin', text: 'Cabin' },
  { id: 'Cairo', text: 'Cairo' },
  { id: 'Comfortaa', text: 'Comfortaa' },
  { id: 'Cormorant', text: 'Cormorant' },
  { id: 'CrimsonText', text: 'CrimsonText' },
  { id: 'DancingScript', text: 'DancingScript' },
  { id: 'DMSans', text: 'DMSans' },
  { id: 'Dosis', text: 'Dosis' },
  { id: 'EBGaramond', text: 'EBGaramond' },
  { id: 'ElMessiri', text: 'ElMessiri' },
  { id: 'Exo2', text: 'Exo2' },
  { id: 'FjallaOne', text: 'FjallaOne' },
  { id: 'Handlee', text: 'Handlee' },
  { id: 'Heebo', text: 'Heebo' },
  { id: 'Hind', text: 'Hind' },
  { id: 'HindMadurai', text: 'HindMadurai' },
  { id: 'IBMPlexSans', text: 'IBMPlexSans' },
  { id: 'Inconsolata', text: 'Inconsolata' },
  { id: 'JosefinSans', text: 'JosefinSans' },
  { id: 'Kanit', text: 'Kanit' },
  { id: 'Karma', text: 'Karma' },
  { id: 'KirangHaerang', text: 'KirangHaerang' },
  { id: 'Lexend', text: 'Lexend' },
  { id: 'LibreBaskerville', text: 'LibreBaskerville' },
  { id: 'Lobster', text: 'Lobster' },
  { id: 'LondrinaShadow', text: 'LondrinaShadow' },
  { id: 'Mitr', text: 'Mitr' },
  { id: 'MPLUS1p', text: 'MPLUS1p' },
  { id: 'Mulish', text: 'Mulish' },
  { id: 'NanumGothic', text: 'NanumGothic' },
  { id: 'Neucha', text: 'Neucha' },
  { id: 'Newsreader', text: 'Newsreader' },
  { id: 'Oxygen', text: 'Oxygen' },
  { id: 'Pacifico', text: 'Pacifico' },
  { id: 'PatuaOne', text: 'PatuaOne' },
  { id: 'Philosopher', text: 'Philosopher' },
  { id: 'Playball', text: 'Playball' },
  { id: 'PragatiNarrow', text: 'PragatiNarrow' },
  { id: 'Prompt', text: 'Prompt' },
  { id: 'Recursive', text: 'Recursive' },
  { id: 'Saira', text: 'Saira' },
  { id: 'SawarabiGothic', text: 'SawarabiGothic' },
  { id: 'SourceCodePro', text: 'SourceCodePro' },
  { id: 'SourceSerifPro', text: 'SourceSerifPro' },
  { id: 'SpaceMono', text: 'SpaceMono' },
  { id: 'Spartan', text: 'Spartan' },
  { id: 'Staatliches', text: 'Staatliches' },
  { id: 'Teko', text: 'Teko' },
  { id: 'Unna', text: 'Unna' },
  { id: 'VarelaRound', text: 'VarelaRound' },
  { id: 'Tangerine', text: 'Tangerine' },
  { id: 'ZillaSlab', text: 'ZillaSlab' }
]
