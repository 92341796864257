<template>
  <div class="container-fluid">
    <div class="card-header top-bar">
      <div class="header-left">
        <div class="admin-search-box">
          <SfInputSearch
            placeholder="Search"
            v-debounce:500ms="searchInputChange"
            class="sf-input-search"
          />
        </div>
      </div>
      <div class="header-right">
        <button class="sf-primary-add" @click="showModalCreate()">Add New Firmware</button>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 sf-table">
          <b-table
            :busy="loading"
            show-empty
            striped
            hover
            :items="items"
            :fields="fields"
            head-variant="none"
            table-variant="none"
            thead-class="custom-b-table"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty>
              <p class="d-flex justify-content-center">No data available in table</p>
            </template>

            <template v-slot:cell(current)="field">
              <div v-if="field.item.current" class="sf-table-status active"></div>
              <div v-else class="sf-table-status inactive"></div>
            </template>

            <template v-slot:head(name)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(name)="field">
              <p>
                <a :href="`${field.item.payload}`">{{ field.item.name }}</a>
              </p>
            </template>

            <template v-slot:head(version)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(version)="field">
              <p>{{ field.item.version }} ({{ field.item.version_code }})</p>
            </template>

            <template v-slot:head(payload_fingerprint)="field">
              <span>{{ field.label }}</span
              ><span class="custome-arrows"></span>
            </template>
            <template v-slot:cell(payload_fingerprint)="field">
              <p>{{ field.item.payload_fingerprint }}</p>
            </template>

            <template v-slot:cell(action)="field">
              <button
                @click="shownModalEdit(field.item.id)"
                class="button-edit-table"
                title="Edit"
              ></button>
              <button
                @click="shownModalDelete(field.item.id)"
                class="button-delete-table"
                title="Delete"
              ></button>
            </template>
          </b-table>

          <div v-if="!isEmpty" class="my-3">
            <b-pagination
              class="sf-b-pagination"
              :total-rows="pagination.limit * pagination.total"
              :per-page="pagination.limit"
              @change="changePage"
              :limit="6"
              v-model="currentPage"
            />
          </div>
        </div>
      </div>
    </div>
    <SfModal
      title="Create New Firmware"
      :icon="'fa fa-user'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalCreate"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">File</label>
                </div>
                <div class="w-100">
                  <input
                    type="file"
                    @change="processNewFile($event)"
                    :class="{
                      'input-error': submitted && $v.newFirmware.pi_firmware.payload.$invalid
                    }"
                  />
                </div>
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newFirmware.pi_firmware.payload.required"
              >
                Payload required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Name</label>
                </div>
                <input
                  v-model="newFirmware.pi_firmware.name"
                  placeholder="Name..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newFirmware.pi_firmware.name.required"
              >
                Name is required}
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Release Notes</label>
                </div>
                <textarea
                  v-model="newFirmware.pi_firmware.release_notes"
                  placeholder="Release Notes..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.newFirmware.pi_firmware.release_notes.required"
              >
                Release Notes is required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingFirmwareDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Active</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="newFirmware.pi_firmware.current"
                    :sync="true"
                    @change="onChangeToggleDetail"
                    :width="44"
                    :height="22"
                    :margin="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalCreate = false">Cancel</button>
        <button class="sf-primary" @click="saveFirmware()">Save</button>
      </div>
    </SfModal>
    <SfModal
      :title="'Edit Firmware'"
      :icon="'fa fa-user'"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingFirmwareDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Name</label>
                </div>
                <input
                  v-model="currentFirmware.pi_firmware.name"
                  placeholder="Name..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentFirmware.pi_firmware.name.required"
              >
                Name is required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Release Notes</label>
                </div>
                <textarea
                  v-model="currentFirmware.pi_firmware.release_notes"
                  placeholder="Release Notes..."
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentFirmware.pi_firmware.release_notes.required"
              >
                Release Notes is required
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingFirmwareDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">Active</label>
                </div>
                <div class="w-100">
                  <toggle-button
                    :value="currentFirmware.pi_firmware.current"
                    :sync="true"
                    @change="onChangeToggleDetailEdit"
                    :width="44"
                    :height="22"
                    :margin="2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalEdit = false">Cancel</button>
        <button class="sf-primary" @click="updateFirmware()">Save</button>
      </div>
    </SfModal>
    <SfDeleteModal :title="'Confirm Delete'" closeOnOverlay :show.sync="isShownModalDelete">
      <div class="content-delete">
        Deleting this post. Are you sure you want to delete this one?
      </div>
      <div class="footer-button">
        <button class="sf-secondary" @click="isShownModalDelete = false">Cancel</button>
        <button class="sf-primary" @click="removeFirmware()">OK</button>
      </div>
    </SfDeleteModal>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import SfModal from '@/components/SfModal.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import AdminFirmWareService from '@/services/admin-firmware.service'
import { required } from 'vuelidate/lib/validators'
import SfInputSearch from '@/components/SfInputSearch.vue'
export default {
  name: 'admin-firmwares',

  components: {
    SfModal,
    SfDeleteModal,
    SfInputSearch
  },
  data() {
    return {
      msg: 'Welcome to Index!!!',
      isShownModalCreate: false,
      isShownModalDelete: false,
      isShownModalEdit: false,
      submitted: false,
      currentPage: 1,
      fields: [
        {
          key: 'current',
          sortable: true,
          label: '',
          colType: 'current',
          class: 'sf-table-header-status'
        },
        {
          key: 'name',
          sortable: true,
          label: 'Name',
          colType: 'name'
        },
        {
          key: 'version',
          sortable: true,
          label: 'Version',
          colType: 'version'
        },
        {
          key: 'payload_fingerprint',
          sortable: true,
          label: 'Checksum',
          colType: 'payload_fingerprint'
        },
        {
          key: 'ACTION',
          colType: 'button',
          label: 'Actions'
        }
      ],
      newFirmware: {
        pi_firmware: {
          payload: [],
          name: '',
          release_notes: '',
          current: false
        }
      },
      currentFirmware: {
        pi_firmware: {
          name: '',
          release_notes: '',
          current: false
        }
      },
      defaultFirmware: {
        pi_firmware: {
          payload: [],
          name: '',
          release_notes: '',
          current: false
        }
      },
      dataSearch: '',
      loadingFirmwareDetail: false,
      lastest: '',
      optionToolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ color: [] }, { background: [] }] // dropdown with defaults from theme
      ]
    }
  },
  validations: {
    newFirmware: {
      pi_firmware: {
        payload: {
          required
        },
        name: {
          required
        },
        release_notes: {
          required
        }
      }
    },
    currentFirmware: {
      pi_firmware: {
        name: {
          required
        },
        release_notes: {
          required
        }
      }
    }
  },
  methods: {
    fetchData(activePage, search) {
      this.$store.dispatch('adminfirmware/getAllFirmwares', { page: activePage, limit: 10, search })
    },
    showModalCreate() {
      this.submitted = false
      this.setDataFirmware(this.newFirmware.pi_firmware, this.defaultFirmware.pi_firmware)
      this.isShownModalCreate = true
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage, this.dataSearch)
    },
    processNewFile(event) {
      this.newFirmware.pi_firmware.payload = event.target.files[0]
    },
    processUpdateFile(event) {
      this.currentFirmware.pi_firmware.payload = event.target.files[0]
    },
    saveFirmware() {
      this.submitted = true
      if (this.$v.newFirmware.$invalid) {
        return
      }
      let formdata = false
      if (window.FormData) {
        formdata = new FormData()
      }

      formdata.append('pi_firmware[payload]', this.newFirmware.pi_firmware.payload)
      formdata.append('pi_firmware[current]', this.newFirmware.pi_firmware.current)
      formdata.append('pi_firmware[name]', this.newFirmware.pi_firmware.name)
      formdata.append('pi_firmware[release_notes]', this.newFirmware.pi_firmware.release_notes)

      AdminFirmWareService.createFirmware(formdata)
        .then((response) => {
          this.$toast.success('Successfully created')
          this.isShownModalCreate = false
          this.fetchData(this.currentPage)
        })
        .catch((err) => {
          this.$toast.error(err.message)
          this.isShownModalCreate = false
        })
    },
    shownModalEdit(firmware_id) {
      this.setDataFirmware(this.currentFirmware.pi_firmware, this.defaultFirmware.pi_firmware)
      this.submitted = false
      this.isShownModalEdit = true
      this.currentFirewareID = firmware_id
      this.getItemByID()
    },
    shownModalDelete(firmware_id) {
      this.isShownModalDelete = true
      this.currentFirewareID = firmware_id
    },
    getItemByID() {
      this.loadingFirmwareDetail = true
      AdminFirmWareService.detailFirmware(this.currentFirewareID)
        .then((response) => {
          this.setDataFirmware(this.currentFirmware.pi_firmware, response.data)
          this.loadingFirmwareDetail = false
          this.currentFirmware.payload = []
        })
        .catch((err) => {
          this.isShownModalEdit = false
          this.$toast.error(err.message)
        })
    },
    setDataFirmware(pi_firmware, res) {
      pi_firmware.current = res.current
      pi_firmware.name = res.name
      pi_firmware.release_notes = res.release_notes
    },
    updateFirmware() {
      this.submitted = true
      if (this.$v.currentFirmware.$invalid) {
        return
      }
      let formdata = false
      if (window.FormData) {
        formdata = new FormData()
      }
      formdata.append('pi_firmware[current]', this.currentFirmware.pi_firmware.current)
      formdata.append('pi_firmware[name]', this.currentFirmware.pi_firmware.name)
      formdata.append('pi_firmware[release_notes]', this.currentFirmware.pi_firmware.release_notes)
      AdminFirmWareService.updateFirmware(this.currentFirewareID, formdata)
        .then((response) => {
          this.$toast.success('Firmware successfully updated')
          this.isShownModalEdit = false
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
          this.isShownModalEdit = false
        })
    },
    removeFirmware() {
      AdminFirmWareService.removeFirmware(this.currentFirewareID)
        .then((response) => {
          this.$toast.success('Post successfully deleted')
          if (this.items.length === 1 && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1
          }
          this.fetchData(this.currentPage, this.dataSearch)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalDelete = false
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    },
    onChangeToggleDetail(value) {
      this.newFirmware.pi_firmware.current = value.value
    },
    onChangeToggleDetailEdit(value) {
      this.currentFirmware.pi_firmware.current = value.value
    }
  },
  computed: {
    ...mapState('adminfirmware', {
      items: 'items',
      pagination: 'pagination',
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('adminfirmware', ['isEmpty'])
  },

  mounted() {
    this.fetchData(this.currentPage)
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, value)
    })
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
  }
}
</script>

<style lang="scss" scoped>
@import '../../Adminpage.scss';
.panel-body {
  border: 2px dashed #888888;
  cursor: pointer;
  padding: 35px;
}
</style>
