<template>
  <div class="template-name-com">
    <input
      v-model="templateName"
      @input="setTemplateName(templateName)"
      @change="setTemplateName(templateName)"
      class="input-template-name"
      id="tplName"
      type="text"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeOriginX: true,
      templateName: this.$store.getters['instagrameditor/getTemplateName']
    }
  },
  updated() {
    this.templateName = this.$store.getters['instagrameditor/getTemplateName']
  },
  computed: {
    instagramData() {
      return this.$store.getters['instagrameditor/getInstagramData']
    }
  },
  methods: {
    setTemplateName(name) {
      this.$store.dispatch('instagrameditor/setTemplateName', name)
    }
  }
}
</script>
<style scoped>
.template-name-com {
  margin-top: 15px;
}
.input-template-name {
  width: 227px;
  height: 25px;
  border-radius: 4px;
  border: solid 1px #e8e8e8;
  background-color: #ffffff;
  padding: 0 5px;
}
</style>
