<template>
  <SfModal
    :title="$t('modal.title.edit-instagram')"
    :width="768"
    :height="0"
    closeOnOverlay
    :show.sync="dataShow"
    @update:show="updateShow"
  >
    <div class="sf-modal-body">
      <div class="container">
        <div class="row mt-3">
          <div class="col">
            <FolderPath v-if="data.folder_path" :paths="data.folder_path" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <ul class="sf-tabs">
              <li>
                <a @click="changeTab('overview')" :class="{ active: activeTab === 'overview' }">{{
                  $t('overview')
                }}</a>
              </li>
              <li>
                <a @click="changeTab('linkto')" :class="{ active: activeTab === 'linkto' }">{{
                  $t('linked-to')
                }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-if="activeTab === 'overview'" class="container">
        <div class="row mt-3">
          <input type="hidden" name="type" id="type" value="gridview" />
          <input type="hidden" value="video" name="smart_asset" id="smart_asset_smart_asset_type" />
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('title') }}</label>
              </div>
              <input
                type="text"
                name="smart_asset"
                id="smart_asset_displayname"
                v-model="dataInput.name"
                class="sf-input-text"
                :placeholder="$t('placeholder.title')"
              />
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('tags') }}</label>
              </div>
              <vue-tags-input
                v-model="tag"
                class="sf-tag"
                :tags="dataInput.tags"
                @tags-changed="(newTags) => (dataInput.tags = newTags)"
                :placeholder="$t('placeholder.add-tags')"
              />
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group label-top">
                <label class="primary-label">{{ $t('orientation') }}</label>
              </div>
              <div class="row orientation-type">
                <div class="col-6">
                  <label class="sf-radio"
                    >{{ $t('landscape') }} ({{ $t('default') }})
                    <input
                      id="landscape-radio"
                      type="radio"
                      value="landscape"
                      v-model="dataInput.orientation"
                      class="sf-radio"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-6">
                  <label class="sf-radio"
                    >{{ $t('portrait') }}
                    <input
                      id="portrait-radio"
                      type="radio"
                      value="portrait"
                      v-model="dataInput.orientation"
                      class="sf-radio"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-12 mt-3">
                  <div class="row">
                    <div
                      class="col-6 landscape orientation-img"
                      :class="{ active: dataInput.orientation === 'landscape' }"
                    >
                      <img src="~@/assets/images/landscape-review.svg" />
                    </div>
                    <div
                      class="col-6 orientation-img portrait"
                      :class="{ active: dataInput.orientation === 'portrait' }"
                    >
                      <img src="~@/assets/images/portrait-review.svg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeTab === 'linkto'" class="container">
      <div class="row">
        <div class="col-6 mt-3">
          <div class="row">
            <div class="col-3">{{ $t('show') }}</div>
            <div class="col-9">
              <label class="sf-radio mb-1">
                {{ $t('loop') }}
                <input type="radio" value="loop" v-model="showBy" />
                <span class="checkmark"></span>
              </label>
              <label class="sf-radio">
                {{ $t('campaign') }}
                <input type="radio" value="campaign" v-model="showBy" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-6 mt-3">
          <SfInputSearch
            v-if="showBy === 'loop'"
            v-model="searchLoopValue"
            :placeholder="capitalizeFirstLetter([$t('search'), $t('loop')])"
            v-debounce:400ms="searchLoopChange"
            :height="'40px'"
          />
          <SfInputSearch
            v-if="showBy === 'campaign'"
            v-model="searchCampaignValue"
            :placeholder="capitalizeFirstLetter([$t('search'), $t('campaign')])"
            v-debounce:450ms="searchCampaignChange"
            :height="'40px'"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div v-if="showBy === 'loop'" class="col-12 sf-table">
          <b-table
            show-empty
            striped
            hover
            :items="loopItems"
            :fields="fields"
            head-variant="none"
            table-variant="none"
            thead-class="custom-b-table"
            :busy="loadingLoop"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>{{ $t('loading') }}...</strong>
              </div>
            </template>
            <template v-slot:empty="isLoopEmpty">
              <p class="d-flex justify-content-center">{{ $t('no-data-available-in-table') }}</p>
            </template>
            <template v-slot:head(name)="field">
              <span class="custome-arrows">{{ $t('name') }}</span>
            </template>
            <template v-slot:cell(name)="field">
              <p>
                <router-link :to="`/loop_assets/${field.item.id}/edit`">{{
                  field.item.name
                }}</router-link>
              </p>
            </template>
          </b-table>
          <div class="mt-3" v-if="!isLoopEmpty">
            <b-pagination
              class="sf-b-pagination"
              :total-rows="loopPagination.limit * loopPagination.total"
              :per-page="loopPagination.limit"
              @change="changeLoopPage"
              :limit="6"
              v-model="currentLoopPage"
            />
          </div>
        </div>

        <div v-if="showBy === 'campaign'" class="col-12 sf-table">
          <b-table
            show-empty
            striped
            hover
            :items="campaignItems"
            :fields="fields"
            head-variant="none"
            table-variant="none"
            thead-class="custom-b-table"
            :busy="loadingCampaign"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>{{ $t('loading') }}...</strong>
              </div>
            </template>
            <template v-slot:empty="isCampaignEmpty">
              <p class="d-flex justify-content-center">{{ $t('no-data-available-in-table') }}</p>
            </template>
            <template v-slot:head(name)="field">
              <span class="custome-arrows">{{ $t('name') }}</span>
            </template>
            <template v-slot:cell(name)="field">
              <p>
                <router-link :to="`/campaigns/${field.item.id}/edit`">{{
                  field.item.name
                }}</router-link>
              </p>
            </template>
          </b-table>
          <div class="mt-3" v-if="!isCampaignEmpty">
            <b-pagination
              class="sf-b-pagination"
              :total-rows="campaignPagination.limit * campaignPagination.total"
              :per-page="campaignPagination.limit"
              @change="changeCampaignPage"
              :limit="6"
              v-model="currentCampaignPage"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="sf-modal-footer mt-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="sf-modal-action">
              <button class="sf-secondary" type="button" @click="updateShow">
                {{ $t('cancel') }}
              </button>
              <button type="button" class="sf-primary" @click="onClickNext">
                {{ $t('next') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SfModal>
</template>

<script>
import FolderPath from './FolderPath.vue'
import SfModal from '@/components/SfModal'
import VueTagsInput from '@johmun/vue-tags-input'
import SmartAsset from '@/services/smart-assets.service'
import SfInputSearch from '@/components/SfInputSearch.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  components: {
    SfModal,
    VueTagsInput,
    SfInputSearch,
    FolderPath
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dataShow: this.show,
      tag: '',
      activeTab: 'overview',
      dataInput: {
        name: '',
        tags: [],
        orientation: 'landscape'
      },
      showBy: 'loop',
      searchLoopValue: '',
      searchCampaignValue: '',
      currentLoopPage: 1,
      currentCampaignPage: 1,
      fields: [
        {
          key: 'name',
          colType: 'name',
          label: 'Name',
          sortable: true
        }
      ]
    }
  },

  watch: {
    async show(value) {
      this.dataShow = value
      if (value) {
        this.dataInput = { ...this.dataInput, ...this.data }
      }
      this.dataInput.tags = this.dataInput.tags.map((item) => ({ text: item, ...item }))
      this.getLoopsByAssetID({ assetID: this.dataInput.id, page: 1, limit: 5, search: '' })
      this.getCampaignsByAssetID({ assetID: this.dataInput.id, page: 1, limit: 5, search: '' })
    }
  },
  methods: {
    ...mapActions('library', {
      getLoopsByAssetID: 'getLoopsByAssetID',
      getCampaignsByAssetID: 'getCampaignsByAssetID'
    }),
    changeLoopPage(page) {
      this.currentLoopPage = page
      this.getLoopsByAssetID({
        assetID: this.dataInput.id,
        page: this.currentLoopPage,
        limit: this.loopPagination.limit,
        search: this.searchLoopValue
      })
    },
    changeCampaignPage(page) {
      this.currentCampaignPage = page
      this.getCampaignsByAssetID({
        assetID: this.dataInput.id,
        page: this.currentCampaignPage,
        limit: this.campaignPagination.limit,
        search: this.searchCampaignValue
      })
    },
    searchLoopChange() {
      this.getLoopsByAssetID({
        assetID: this.dataInput.id,
        page: 1,
        limit: this.loopPagination.limit,
        search: this.searchLoopValue
      })
    },
    searchCampaignChange() {
      this.getCampaignsByAssetID({
        assetID: this.dataInput.id,
        page: 1,
        limit: this.campaignPagination.limit,
        search: this.searchCampaignValue
      })
    },
    updateShow() {
      this.$emit('update:show', false)
    },
    changeTab(val) {
      this.activeTab = val
    },
    async onClickNext() {
      const tags = this.dataInput.tags
      this.dataInput.displayname = this.dataInput.name
      const dataTemp = await SmartAsset.getSmartAssetsByID(this.data.id)
      localStorage.setItem(
        'instagram',
        JSON.stringify({
          smart_asset_id: this.dataInput.id,
          displayname: this.dataInput.name,
          tags: tags,
          orientation: this.dataInput.orientation,
          current_folder: {},
          organization_id: this.organizationId,
          smart_asset_type: 'instagram',
          orientation_changed: !(this.dataInput.orientation === dataTemp.data.orientation)
        })
      )

      localStorage.setItem(
        'instagramInfo',
        JSON.stringify({
          access_token: dataTemp.data.properties.ig_access_token,
          user: {
            id: dataTemp.data.properties.query
          }
        })
      )

      this.$emit('modal:next', { ...this.dataInput })
    }
  },
  computed: {
    ...mapState('library', {
      loadingLoop: 'loadingLoop',
      loadingCampaign: 'loadingCampaign',
      organizationId: 'organizationId'
    }),
    ...mapGetters('library', {
      isLoopEmpty: 'isLoopEmpty',
      isCampaignEmpty: 'isCampaignEmpty',
      loopItems: 'loopItems',
      campaignItems: 'campaignItems',
      loopPagination: 'loopPagination',
      campaignPagination: 'campaignPagination'
    })
  },
  created() {}
}
</script>

<style lang="scss" scoped>
@import './style-modal-smart-asset.scss';
</style>

<style lang="scss">
.form-control {
  height: auto !important;
  .vue-tags-input {
    margin: -0.375rem -0.75rem;
    border-radius: 0.25rem;
    * {
      border: transparent;
    }
    border: transparent;
    .ti-tag {
      background-color: #e0edf5;
      border-radius: 3px;
      color: #2f5d92;
      float: left;
      font-weight: 600;
      height: 28px;
      line-height: 28px;
      margin: 0 8px 6px 0;
      padding: 0 1px 0 8px;
      white-space: nowrap;
    }
  }
}
</style>
