var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vueTreeBase',{ref:"slVueTree",on:{"externaldrop":_vm.onExternalDropHandler,"keydown":_vm.onArrowDownHandler,"nodeclick":_vm.nodeClick,"select":_vm.onSelect,"drop":_vm.onDrop,"input":_vm.onChange,"toggle":_vm.openFolder},scopedSlots:_vm._u([{key:"title",fn:function({ node }){return [_c('span',{staticClass:"item-icon",class:{ 'none-toogle': node.isLeaf }},[(!node.isLeaf)?[(node.children && node.children.length && node.isExpanded)?_c('icon-folder-open'):_vm._e(),(!node.isExpanded || !node.children.length)?_c('icon-folder-close'):_vm._e()]:(node.isLeaf)?[(node.data.folder_type === _vm.typeFile.loops)?_c('icon-loop'):(node.data.file_type === _vm.typeFile.image)?_c('icon-image'):(node.data.file_type === _vm.typeFile.video)?_c('icon-video'):(node.data.file_type === _vm.typeFile.pdf)?_c('icon-pdf'):(node.data.file_type === _vm.typeFile.instagram)?_c('icon-social'):(node.data.file_type === _vm.typeFile.website)?_c('icon-website'):(node.data.file_type === _vm.typeFile.youtube)?_c('icon-youtube'):(node.data.file_type === _vm.typeFile.google_sheet)?_c('icon-google-sheet'):(node.data.file_type === _vm.typeFile.google_slide)?_c('icon-google-slide'):(node.data.file_type === _vm.typeFile.custom_asset)?_c('icon-custom-asset'):(node.data.file_type === _vm.typeFile.canva)?_c('icon-canva'):_vm._e()]:_vm._e()],2),(!node.data.isEdit)?_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(node.data.name)+" ")]):_vm._e(),_c('input',{ref:`item-${node.data.file_type}-${node.data.id}`,staticClass:"edit",style:({
        opacity: +!!node.data.isEdit,
        'pointer-events': !!node.data.isEdit ? 'initial' : 'none'
      }),attrs:{"type":"text"},domProps:{"value":node.data.name},on:{"blur":function($event){return _vm.blurEdit(node, $event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.blurEdit(node, $event)}}})]}},{key:"toggle",fn:function({ node }){return [(node.data && node.data.has_children && !node.isExpanded)?_c('iconCaret'):_vm._e(),(node.data && node.data.has_children && node.isExpanded)?_c('iconCaretBot'):_vm._e()]}},{key:"sidebar",fn:function({ node }){return (!_vm.isShowSidebar && !node.data.isEdit)?[(
        !node.data.shared_folder &&
        node.data.folder_type !== 'sub_organization' &&
        node.data.folder_type !== 'organizations'
      )?_c('div',{staticClass:"actions"},[_c('div',{staticClass:"btn-action btn-edit btn-edit-tree-item",on:{"click":function($event){return _vm.edit(node, $event)}}},[_c('img',{attrs:{"src":require("@/assets/images/loop-edit-icon.svg")}})]),_c('div',{staticClass:"btn-action btn-delete",on:{"click":function($event){return _vm.deleteItem(node, $event)}}},[_c('img',{attrs:{"src":require("@/assets/images/loop-delete-icon.svg")}})])]):_vm._e()]:undefined}}],null,true),model:{value:(_vm.nodes),callback:function ($$v) {_vm.nodes=$$v},expression:"nodes"}})
}
var staticRenderFns = []

export { render, staticRenderFns }