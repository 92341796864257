import UsersService from '@/services/users.service'
import { getDurationSecond } from '../../../helper/helper'
import AdminRoleService from '@/services/admin-role.service.js'
import Vue from 'vue'

export default {
  getCurrent({ state, commit }) {
    commit('SET_LOADING', true)
    return UsersService.getCurrent()
      .then((res) => {
        res.data.default_slide_duration = getDurationSecond(res.data.default_slide_duration)
        commit('SET_CURRENT_USER', res.data)
      })
      .catch((error) =>
        commit('toast/NEW', { type: 'error', message: error.message }, { root: true })
      )
      .finally(() => commit('SET_LOADING', false))
  },
  getAllUsers({ state, commit }, payload) {
    commit('SET_LOADING_USER', true)
    return UsersService.getAllUser(payload.page, payload.limit, payload.search)
      .then((res) => {
        res.data.items.map((item) => {
          if (!item.user_sub_type) {
            item.user_sub_type = 'orgadmin'
          }
        })
        commit('GET_ALL_USERS', res.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING_USER', false))
  },

  getInfoUserByID({ state, commit }, payload) {
    return UsersService.getInfoUserById(payload)
      .then((res) => {
        if (!res.data.user_sub_type) {
          res.data.user_sub_type = 'orgadmin'
        }
        commit('GET_INFO_USER_BY_ID', res.data)
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING', false))
  },

  getListUserSubType({ commit }) {
    return UsersService.getListUserSubType()
      .then((res) => {
        commit('GET_LIST_USER_SUB_TYPE', res.data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  },

  getListRoleByUser({ commit }) {
    return AdminRoleService.getRoleListByUser()
      .then((res) => {
        commit('GET_LIST_ROLE_BY_USER', res.data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  },

  getCustomRole({ commit }) {
    return AdminRoleService.getCustomRole()
      .then((res) => {
        commit('SET_CUSTOM_ROLE', res.data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  }
}
