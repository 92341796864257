<template>
  <div class="main-term">
    <section class="content_wrapper">
      <div class="content">
        <p class="btn-close" @click="cancelForgot">
          <img src="../../src/assets/images/close-x.svg" />
        </p>
        <div class="content-reset">
          <h4>{{ $t('reset-password') }}</h4>
          <p>{{ $t('enter-your-email-message') }}</p>
        </div>
        <div class="footer-content d-flex">
          <div class="email-address">
            <input class="sf-input-text" placeholder="Your email address" v-model="email" />
          </div>
          <button class="sf-primary ml-3" @click="sendPassWord">
            <div class="text" v-if="!isLoading">
              {{ $t('send-password') }}
            </div>
            <div v-else class="spinner-border" role="status">
              <span class="sr-only">{{ $t('loading') }}...</span>
            </div>
          </button>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import passwordService from '../services/password.service'

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      isLoading: false
    }
  },

  methods: {
    sendPassWord() {
      this.isLoading = true
      passwordService
        .sendEmail({ email: this.email })
        .then((response) => {
          this.$toast.success(response.message)
          this.email = ''
          this.isLoading = false
        })
        .catch((error) => {
          this.$toast.error(error.status === 404 ? 'User with same email not found' : error.message)
          this.isLoading = false
        })
    },
    cancelForgot() {
      this.$router.push('/login')
    }
  }
}
</script>
<style lang="scss" scoped>
.main-term {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../src/assets/images/app-background.jpg');
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;

  .content {
    width: 500px;
    height: 200px;
    background-color: #ffffff;
    margin-top: 20%;
    position: relative;

    .btn-close {
      position: absolute;
      right: 15px;
      top: 10px;
      font: 25px Roboto, sans-serif;
      z-index: 1000;
      color: #4a4a4a;
      cursor: pointer;
    }

    .content-reset {
      padding-top: 40px;
      padding-left: 20px;

      h4 {
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        color: #2c7fe1;
        font-weight: 400;
        margin: 15px 0 10px;
      }

      p {
        color: #626262;
        font: 14px 'Roboto', sans-serif;
      }
    }
  }
}
.footer-content {
  margin-top: 15px;
}
.email-address {
  display: flex;
  width: 250px;
  height: 40px;
  max-width: 270px;
  margin-left: 20px;
  img {
    margin-left: 5px;
  }
}

.input-address {
  border: none;
  margin-left: 10px;
}
.reset-password {
  border: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  width: 150px;
  margin: 0 10px;
  height: 40px;
  border-radius: 20px;
  background-color: #2c7fe1;
  box-shadow: 0 3px 5px 0 rgba(0, 89, 160, 0.18);
}
@media only screen and (max-width: 500px) {
  .content {
    width: 80% !important;
    margin: 10%;
    margin-left: 10%;
    height: 250px !important;
  }
  .footer-content {
    flex-direction: column;
  }
  .reset-password {
    margin-left: 20px;
    margin-top: 10px;
  }
}
</style>
