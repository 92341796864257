<template>
  <SfModal
    :title="$t('modal.title.edit-youtube')"
    :width="768"
    :height="0"
    closeOnOverlay
    :show.sync="dataShow"
    @update:show="updateShow"
  >
    <div class="sf-modal-body mb-3">
      <div class="container">
        <div class="row mt-3">
          <div class="col">
            <FolderPath v-if="data.folder_path" :paths="data.folder_path" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <ul class="sf-tabs">
              <li>
                <a @click="changeTab('overview')" :class="{ active: activeTab === 'overview' }">{{
                  $t('overview')
                }}</a>
              </li>
              <li>
                <a @click="changeTab('linkto')" :class="{ active: activeTab === 'linkto' }">{{
                  $t('linked-to')
                }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="activeTab === 'overview'" class="container">
        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('title') }}</label>
              </div>
              <input
                type="text"
                v-model="dataInput.displayname"
                class="sf-input-text"
                :placeholder="$t('placeholder.title')"
                :class="{ 'input-error-form': submitted && $v.dataInput.displayname.$invalid }"
              />
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.displayname.required"
            >
              Title required
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('url') }}</label>
              </div>
              <input
                type="text"
                name="property[url]"
                id="property_url"
                class="sf-input-text"
                :placeholder="$t('placeholder.url-of-youtube')"
                v-model="dataInput.properties.url"
                :class="{ 'input-error-form': submitted && $v.dataInput.properties.url.$invalid }"
              />
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.properties.url.url"
            >
              {{ $t('url-invalid-message') }}
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.properties.url.required"
            >
              {{ $t('url-invalid-null-message') }}
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.properties.url.maxLength"
            >
              {{ $t('url-invalid-long-message') }}
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.properties.url.validatorYoutubeUrl"
            >
              {{ $t('invalid-youtube-url') }}
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('seconds') }}</label>
              </div>
              <input
                type="number"
                name="property[seconds]"
                id="property_seconds"
                value="0"
                class="sf-input-text"
                :placeholder="$t('seconds')"
                v-model="dataInput.properties.seconds"
              />
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">{{ $t('tags') }}</label>
              </div>
              <vue-tags-input
                class="sf-tag"
                v-model="tag"
                :tags="dataInput.tags"
                @tags-changed="(newTags) => (dataInput.tags = newTags)"
                :placeholder="$t('placeholder.add-tags')"
              />
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group label-top">
                <label class="primary-label">{{ $t('orientation') }}</label>
              </div>
              <div class="row orientation-type">
                <div class="col-6">
                  <label class="sf-radio"
                    >{{ $t('landscape') }} ({{ $t('default') }})
                    <input
                      id="landscape-radio"
                      type="radio"
                      value="landscape"
                      v-model="dataInput.orientation"
                      class="sf-radio"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-6">
                  <label class="sf-radio"
                    >{{ $t('portrait') }}
                    <input
                      id="portrait-radio"
                      type="radio"
                      value="portrait"
                      v-model="dataInput.orientation"
                      class="sf-radio"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-12 mt-3">
                  <div class="row orientation-img-wrap">
                    <div
                      for="landscape-radio"
                      :class="{ active: dataInput.orientation === 'landscape' }"
                      class="col-6 landscape orientation-img"
                    >
                      <img src="~@/assets/images/landscape-review.svg" />
                    </div>
                    <div
                      for="portrait-radio"
                      :class="{ active: dataInput.orientation === 'portrait' }"
                      class="col-6 orientation-img portrait"
                    >
                      <img src="~@/assets/images/portrait-review.svg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="activeTab === 'linkto'" class="container">
        <div class="row">
          <div class="col-6 mt-3">
            <div class="row">
              <div class="col-3">{{ $t('show') }}</div>
              <div class="col-9">
                <label class="sf-radio mb-1">
                  {{ $t('loop') }}
                  <input type="radio" value="loop" v-model="showBy" />
                  <span class="checkmark"></span>
                </label>
                <label class="sf-radio">
                  {{ $t('campaign') }}
                  <input type="radio" value="campaign" v-model="showBy" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-6 mt-3">
            <SfInputSearch
              v-if="showBy === 'loop'"
              v-model="searchLoopValue"
              :placeholder="capitalizeFirstLetter([$t('search'), $t('loop')])"
              v-debounce:400ms="searchLoopChange"
              :height="'40px'"
            />
            <SfInputSearch
              v-if="showBy === 'campaign'"
              v-model="searchCampaignValue"
              :placeholder="capitalizeFirstLetter([$t('search'), $t('campaign')])"
              v-debounce:450ms="searchCampaignChange"
              :height="'40px'"
            />
          </div>
        </div>
        <div class="row mt-3">
          <div v-if="showBy === 'loop'" class="col-12 sf-table">
            <b-table
              show-empty
              striped
              hover
              :items="loopItems"
              :fields="fields"
              head-variant="none"
              table-variant="none"
              thead-class="custom-b-table"
              :busy="loadingLoop"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>{{ $t('loading') }}...</strong>
                </div>
              </template>
              <template v-slot:empty="isLoopEmpty">
                <p class="d-flex justify-content-center">{{ $t('no-data-available-in-table') }}</p>
              </template>
              <template v-slot:head(name)="field">
                <span class="custome-arrows">{{ $t('name') }}</span>
              </template>
              <template v-slot:cell(name)="field">
                <p>
                  <router-link :to="`/loop_assets/${field.item.id}/edit`">{{
                    field.item.name
                  }}</router-link>
                </p>
              </template>
            </b-table>
            <div class="mt-3" v-if="!isLoopEmpty">
              <b-pagination
                class="sf-b-pagination"
                :total-rows="loopPagination.limit * loopPagination.total"
                :per-page="loopPagination.limit"
                @change="changeLoopPage"
                :limit="6"
                v-model="currentLoopPage"
              />
            </div>
          </div>

          <div v-if="showBy === 'campaign'" class="col-12 sf-table">
            <b-table
              show-empty
              striped
              hover
              :items="campaignItems"
              :fields="fields"
              head-variant="none"
              table-variant="none"
              thead-class="custom-b-table"
              :busy="loadingCampaign"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>{{ $t('loading') }}...</strong>
                </div>
              </template>
              <template v-slot:empty="isCampaignEmpty">
                <p class="d-flex justify-content-center">{{ $t('no-data-available-in-table') }}</p>
              </template>
              <template v-slot:head(name)="field">
                <span class="custome-arrows">{{ field.label }}</span>
              </template>
              <template v-slot:cell(name)="field">
                <p>
                  <router-link :to="`/campaigns/${field.item.id}/edit`">{{
                    field.item.name
                  }}</router-link>
                </p>
              </template>
            </b-table>
            <div class="mt-3" v-if="!isCampaignEmpty">
              <b-pagination
                class="sf-b-pagination"
                :total-rows="campaignPagination.limit * campaignPagination.total"
                :per-page="campaignPagination.limit"
                @change="changeCampaignPage"
                :limit="6"
                v-model="currentCampaignPage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sf-modal-footer">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="sf-modal-action">
              <button class="sf-secondary" type="button" @click="updateShow">
                {{ $t('cancel') }}
              </button>
              <button type="button" class="sf-primary" @click="onClickSave">
                {{ $t('save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SfModal>
</template>

<script>
import FolderPath from './FolderPath.vue'
import SfModal from '@/components/SfModal'
import VueTagsInput from '@johmun/vue-tags-input'
import { required, url, maxLength } from 'vuelidate/lib/validators'
import SfInputSearch from '@/components/SfInputSearch.vue'
import { mapState, mapGetters, mapActions } from 'vuex'

const reg =
  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
const validatorYoutubeUrl = (url) => {
  if (url.match(reg) && url.match(reg).length > 0) {
    return true
  } else {
    return false
  }
}

export default {
  components: {
    SfModal,
    VueTagsInput,
    SfInputSearch,
    FolderPath
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dataShow: this.show,
      tag: '',
      activeTab: 'overview',
      dataDefault: {
        title: '',
        tags: [],
        orientation: 'landscape',
        properties: {
          url: '',
          youtube_id: '',
          seconds: 0
        }
      },
      dataInput: null,
      submitted: false,
      showBy: 'loop',
      searchLoopValue: '',
      searchCampaignValue: '',
      currentLoopPage: 1,
      currentCampaignPage: 1,
      fields: [
        {
          key: 'name',
          colType: 'name',
          label: 'Name',
          sortable: true
        }
      ]
    }
  },
  validations: {
    dataInput: {
      displayname: {
        required
      },
      properties: {
        url: {
          required,
          url,
          maxLength,
          validatorYoutubeUrl
        }
      }
    }
  },
  watch: {
    show(value) {
      this.dataShow = value
      this.dataInput = { ...this.dataInput, ...this.data }
      this.dataInput.tags = this.dataInput.tags.map((item) => ({ text: item.name, ...item }))
      this.submitted = false
      this.getLoopsByAssetID({ assetID: this.dataInput.id, page: 1, limit: 5, search: '' })
      this.getCampaignsByAssetID({ assetID: this.dataInput.id, page: 1, limit: 5, search: '' })
    }
  },
  methods: {
    ...mapActions('library', {
      getLoopsByAssetID: 'getLoopsByAssetID',
      getCampaignsByAssetID: 'getCampaignsByAssetID'
    }),
    changeLoopPage(page) {
      this.currentLoopPage = page
      this.getLoopsByAssetID({
        assetID: this.dataInput.id,
        page: this.currentLoopPage,
        limit: this.loopPagination.limit,
        search: this.searchLoopValue
      })
    },
    changeCampaignPage(page) {
      this.currentCampaignPage = page
      this.getCampaignsByAssetID({
        assetID: this.dataInput.id,
        page: this.currentCampaignPage,
        limit: this.campaignPagination.limit,
        search: this.searchCampaignValue
      })
    },
    searchLoopChange() {
      this.getLoopsByAssetID({
        assetID: this.dataInput.id,
        page: 1,
        limit: this.loopPagination.limit,
        search: this.searchLoopValue
      })
    },
    searchCampaignChange() {
      this.getCampaignsByAssetID({
        assetID: this.dataInput.id,
        page: 1,
        limit: this.campaignPagination.limit,
        search: this.searchCampaignValue
      })
    },
    updateShow() {
      this.$emit('update:show', false)
    },
    changeTab(val) {
      this.activeTab = val
    },
    onClickSave() {
      this.submitted = true
      if (this.$v.dataInput.$invalid || this.isSynTaxError) {
        return
      }
      this.dataInput.properties.youtube_id = this.dataInput.properties.url.match(reg)[1]
      this.$emit('modal:save', { ...this.dataInput })
    }
  },
  computed: {
    ...mapState('library', {
      loadingLoop: 'loadingLoop',
      loadingCampaign: 'loadingCampaign'
    }),
    ...mapGetters('library', {
      isLoopEmpty: 'isLoopEmpty',
      isCampaignEmpty: 'isCampaignEmpty',
      loopItems: 'loopItems',
      campaignItems: 'campaignItems',
      loopPagination: 'loopPagination',
      campaignPagination: 'campaignPagination'
    })
  },
  created() {
    this.dataInput = this.dataDefault
  }
}
</script>

<style lang="scss" scoped>
@import './style-modal-smart-asset';
</style>
<style lang="scss">
.form-control {
  height: auto !important;
  .vue-tags-input {
    margin: -0.375rem -0.75rem;
    border-radius: 0.25rem;
    * {
      border: transparent;
    }
    border: transparent;
    .ti-tag {
      background-color: #e0edf5;
      border-radius: 3px;
      color: #2f5d92;
      float: left;
      font-weight: 600;
      height: 28px;
      line-height: 28px;
      margin: 0 8px 6px 0;
      padding: 0 1px 0 8px;
      white-space: nowrap;
    }
  }
}
</style>
