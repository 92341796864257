import Http from './http.init'
import { ResponseWrapper, ErrorWrapper } from './util'
import Vue from 'vue'
import { EventBus } from '../event-bus.js'
import moment from 'moment-timezone'
let isRefreshUser = false
export default class BaseService {
  constructor() {
    if (!this.entity) {
      throw new Error('Child service class not provide entity')
    }
  }

  /**
   * ------------------------------
   * @HELPERS
   * ------------------------------
   */

  request(status = { auth: false }) {
    return new Http(status)
  }

  responseWrapper(...rest) {
    const response = new ResponseWrapper(...rest)
    this.checkInformationUser(response)
    return response
  }

  errorWrapper(...rest) {
    return new ErrorWrapper(...rest)
  }

  /**
   * ------------------------------
   * @API_CALLS_PUBLIC
   * ------------------------------
   */

  checkInformationUser(response) {
    if (!isRefreshUser) {
      const user = Vue.$cookies.get('user')
      if (user) {
        if (response.refreshUser && user.last_update_user !== response.refreshUser) {
          isRefreshUser = true
          return new Promise((resolve, reject) => {
            return this.request({ auth: true })
              .get('users/refresh_current_user')
              .then((response) => {
                const tokenExpired = Vue.$cookies.get('tokenExpired')
                const remember = Vue.$cookies.get('rememberMe') === 'true'
                if (tokenExpired && remember) {
                  Vue.$cookies.set(
                    'user',
                    response.data.data.user,
                    moment(tokenExpired).format('ddd, DD MMM YYYY HH:mm:ss ZZ')
                  )
                } else {
                  Vue.$cookies.set('user', response.data.data.user, 0)
                }
                EventBus.$emit('refresh_current_user', response.data.data)
                EventBus.$emit('refresh_current_user_header', response.data.data)
                isRefreshUser = false
              })
              .catch((_) => {})
          })
        }
      }
    }
  }

  getListPublic(parameters = {}) {
    const params = {
      ...parameters
    }
    return new Promise((resolve, reject) => {
      return this.request()
        .get(`${this.entity}`, { params })
        .then((response) => {
          const data = {
            content: response.data.data.results,
            total: response.data.data.total
          }
          resolve(this.responseWrapper(response, data))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getByIdPublic(id = window.required()) {
    return new Promise((resolve, reject) => {
      return this.request()
        .get(`${this.entity}/${id}`)
        .then((response) => resolve(this.responseWrapper(response, response.data.data)))
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  /**
   * ------------------------------
   * @API_CALLS_PRIVATE
   * ------------------------------
   */

  getById(id = window.required()) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/${id}`)
        .then((response) => resolve(this.responseWrapper(response, response.data.data)))
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  create(data = window.required()) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}`, data)
        .then((response) => resolve(this.responseWrapper(response, response.data.data)))
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  update(id = window.required(), data = window.required()) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .patch(`${this.entity}/${id}`, data)
        .then((response) => resolve(this.responseWrapper(response, response.data.data)))
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  remove(id = window.required()) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .delete(`${this.entity}/${id}`)
        .then((response) => resolve(this.responseWrapper(response, response.data)))
        .catch((error) => {
          reject(this.errorWrapper(error))
        })
    })
  }
}
