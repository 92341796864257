<template>
  <transition name="modal">
    <div class="ui-modal overlay component" v-if="show" @click="onClickOverlay" ref="overlay">
      <div class="modal-content" id="modalPreview">
        <div class="close" @click="close" />
        <div class="preview" id="preview"></div>
        <div class="fullScreen">
          <button @click="launchIntoFullscreen">
            <i class="fa fa-arrows-alt" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import LoopAssetsService from '@/services/loop-assets.service'
import instagramPreview from '@/assets/images/no-preview.png'
export default {
  name: 'UiCreateModal',

  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    width: Number,
    title: String,
    height: Number,
    data: Object
  },

  data() {
    return {
      dataScope: this.data,
      reviewSource: [],
      isFullScreen: false,
      instagramPreview,
      orientation: 'landscape'
    }
  },

  watch: {
    show(value) {
      this.dataScope = this.data
      this.getDataPreview(this.data.id)
    },
    reviewSource(value) {
      if (value.length) this.createSlider(value)
    }
  },

  methods: {
    close() {
      this.$emit('update:show', false)
    },
    onClickOverlay($event) {
      if ($event && $event.target === this.$refs.overlay) {
        this.close()
      }
    },
    getDataPreview(id) {
      LoopAssetsService.previewLoopAssetssByID(id)
        .then((res) => {
          this.reviewSource = res.data.slides
          this.orientation = res.data.loop_info.orientation
        })
        .catch((error) => {
          this.$toast.error(error.message ? error.message : 'Something went wrong')
        })
    },
    createSlider(slides) {
      let igTemplate = `<html><body style="overflow: hidden; width: 100%"><img style="width:100%" src="${instagramPreview}"/></body></html>`
      if (this.orientation === 'portrait') {
        igTemplate = `<html><body style="overflow: hidden;">
        <div style="
          width:320px;
          height: 640px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          margin: auto;
          border: 1px solid #ccc;
          ">
          <img style="width:100%" src="${instagramPreview}"/></div></body></html>`
      }
      let firstRun = true
      let currentSlideNum = 0
      const iframe1 = document.createElement('iframe')
      const iframe2 = document.createElement('iframe')
      iframe1.style.display = 'none'
      iframe2.style.display = 'none'
      iframe1.id = 'iframe1'
      iframe2.id = 'iframe2'
      iframe1.onload = () => {
        if (firstRun) {
          iframe1.style.display = 'block'
          firstRun = false
          const duration = slides[currentSlideNum].duration
          if (slides.length > 1) {
            setTimeout(switchFrame, duration)
          }
        }
      }
      if (slides[currentSlideNum].template_type === 'instagram') {
        iframe1.setAttribute('srcdoc', igTemplate)
      } else {
        LoopAssetsService.getPreviewSource(slides[currentSlideNum].preview_source)
          .then((res) => {
            iframe1.setAttribute('srcdoc', res.data)
            if (
              slides[currentSlideNum].template_type === 'video' ||
              slides[currentSlideNum].template_type === 'youtube'
            ) {
              document.getElementById('iframe1').onload = function () {
                document.getElementById('iframe1').contentWindow.playVid()
              }
            }
          })
          .catch((error) => {
            this.$toast.error(error.message ? error.message : 'Something went wrong')
          })
      }

      const preview = document.getElementById('preview')
      preview.appendChild(iframe1)
      preview.appendChild(iframe2)
      let nextSlideNum = slides[currentSlideNum + 1] == null ? 0 : currentSlideNum + 1
      if (slides[nextSlideNum].template_type === 'instagram') {
        iframe2.setAttribute('srcdoc', igTemplate)
      } else {
        LoopAssetsService.getPreviewSource(slides[nextSlideNum].preview_source)
          .then((res) => {
            iframe2.setAttribute('srcdoc', res.data)
          })
          .catch((error) => {
            this.$toast.error(error.message ? error.message : 'Something went wrong')
          })
      }

      function switchFrame() {
        currentSlideNum = nextSlideNum
        nextSlideNum = slides[currentSlideNum + 1] == null ? 0 : currentSlideNum + 1
        if (window.getComputedStyle(iframe1).display === 'block') {
          iframe1.style.display = 'none'
          iframe2.style.display = 'block'
          try {
            document.getElementById('iframe2').contentWindow.playVid()
          } catch (error) {}

          if (slides[nextSlideNum].template_type === 'instagram') {
            iframe1.setAttribute('srcdoc', igTemplate)
          } else {
            LoopAssetsService.getPreviewSource(slides[nextSlideNum].preview_source)
              .then((res) => {
                iframe1.setAttribute('srcdoc', res.data)
              })
              .catch((error) => {
                this.$toast.error(error.message ? error.message : 'Something went wrong')
              })
          }
        } else if (window.getComputedStyle(iframe2).display === 'block') {
          iframe1.style.display = 'block'
          iframe2.style.display = 'none'
          try {
            document.getElementById('iframe1').contentWindow.playVid()
          } catch (error) {}
          if (slides[nextSlideNum].template_type === 'instagram') {
            iframe2.setAttribute('srcdoc', igTemplate)
          } else {
            LoopAssetsService.getPreviewSource(slides[nextSlideNum].preview_source)
              .then((res) => {
                iframe2.setAttribute('srcdoc', res.data)
              })
              .catch((error) => {
                this.$toast.error(error.message ? error.message : 'Something went wrong')
              })
          }
        }
        const duration = slides[currentSlideNum].duration
        setTimeout(switchFrame, duration)
      }
    },
    launchIntoFullscreen() {
      if (this.isFullScreen) {
        this.isFullScreen = false
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        }
      } else {
        const element = document.getElementById('modalPreview')
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.mozRequestFullscreen) {
          element.mozRequestFullScreen()
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen()
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen()
        }
        this.isFullScreen = true
      }
    }
  },
  created() {},
  beforeDestroy() {
    this.reviewHTML = []
  }
}
</script>

<style lang="scss" scoped>
.ig-preview-note {
  width: 100%;
  max-width: 100%;
}
.ui-modal.overlay.component {
  width: 100vw;
  height: 100vh;
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-content {
    background-color: #fff;
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.5);
    border-radius: 0;
    border: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    min-width: 1267px;
    height: 100vh;
    overflow: hidden;
    border: 1px solid #888;
    .iPreview {
      min-height: 500px;
    }
    .close {
      width: 32px;
      height: 32px;
      background-image: url('~@/assets/images/lightbox-close.png');
      background-repeat: no-repeat;
      background-position: center;
      justify-self: flex-end;
      position: absolute;
      right: 0;
      opacity: 1;
      cursor: pointer;
    }
    .fullScreen {
      position: absolute;
      right: 0;
      bottom: 0;
      button {
        background-color: #4d94ff;
        border: 1px solid #1a75ff;
        color: white;
        padding: 5px;
        border-radius: 3px;
        display: inline-block;
        font-size: 16px;
        z-index: 9999999;
        position: absolute;
        right: 5px;
        bottom: 5px;
        width: 30px;
        height: 30px;
        line-height: 20px;
      }
    }
  }
}

/* modal transition */
.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}
.modal-enter,
.modal-leave-to {
  padding-top: 100px;
  opacity: 0;
}
</style>
