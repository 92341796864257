<template>
  <div class="container-fluid sf-pagination-page">
    <div class="row mt-5">
      <div class="col-12">
        <h2 class="page-title">Tabs</h2>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-3">TAB GROUP</div>
      <div class="col-9">
        <ul class="sf-tabs">
          <li><a class="active" tabindex="1">Active</a></li>
          <li><a tabindex="2">Default</a></li>
          <li><a tabindex="3">Default</a></li>
        </ul>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-3">TAB WITH ICON</div>
      <div class="col-9">
        <ul class="sf-tabs">
          <li>
            <a class="active" tabindex="1"><img :src="add" /><span>Add</span></a>
          </li>
          <li>
            <a tabindex="2"><img :src="playlistEditor" /><span>Customise</span></a>
          </li>
          <li>
            <a tabindex="3"><img :src="previewEye" /><span>Overview</span></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <h2 class="page-title">Pagination</h2>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-3">PAGINATION GROUP</div>
      <div class="col-9">
        <ul class="sf-pagination">
          <li class="page-item"><span class="previous-page"></span></li>
          <li class="page-item active"><a href="#1">1</a></li>
          <li class="page-item"><a href="#2">2</a></li>
          <li class="page-item"><a href="#3">3</a></li>
          <li class="page-item"><a href="#2">4</a></li>
          <li class="page-item"><a href="#3">5</a></li>
          <li class="page-item"><span class="next-page"></span></li>
        </ul>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-3">PAGINATION GROUP - OVERFLOW</div>
      <div class="col-9">
        <ul class="sf-pagination">
          <li class="page-item"><span class="previous-page"></span></li>
          <li class="page-item active"><a href="#1">1</a></li>
          <li class="page-item"><a href="#2">2</a></li>
          <li class="page-item"><a href="#3">3</a></li>
          <li class="page-item"><a href="#2">...</a></li>
          <li class="page-item"><a href="#3">100</a></li>
          <li class="page-item"><span class="next-page"></span></li>
        </ul>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <h2 class="page-title">Breadcrumbs</h2>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-3">BREADCRUMB GROUP</div>
      <div class="col-9">
        <div class="sf-breadcrumbs">
          <ul>
            <li><a class="sf-link active">Level 1</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-3">BREADCRUMB GROUP</div>
      <div class="col-9">
        <div class="sf-breadcrumbs">
          <ul>
            <li><a class="sf-link">Level 1</a></li>
            <li><a class="sf-link active">Level 2</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-3">BREADCRUMB GROUP</div>
      <div class="col-9">
        <div class="sf-breadcrumbs">
          <ul>
            <li><a class="sf-link">Level 1</a></li>
            <li><a class="sf-link">Level 2</a></li>
            <li><a class="sf-link active">Level 3</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import closeIcon from '@/assets/images/close-x.svg'
import playlistEditor from '@/assets/images/playlist-editor.svg'
import previewEye from '@/assets/images/preview-eye.svg'
import add from '@/assets/images/add.svg'

export default {
  name: 'account',
  components: {},
  data() {
    return {
      playlistEditor,
      closeIcon,
      previewEye,
      add
    }
  },
  created() {},
  methods: {},
  mounted() {}
}
</script>

<style lang="scss" scoped></style>
