<template>
  <div class="container-fluid pt-3 account-page">
    <div class="row org-top">
      <div class="col-10">
        <div v-if="loading" class="org-info-placeholders">
          <content-placeholders>
            <div class="col-6">
              <content-placeholders-text :lines="2" />
            </div>
          </content-placeholders>
        </div>
        <div v-else class="org-information">
          <div class="org-name">
            <h2>{{ infoUser.organization ? infoUser.organization.name : '' }}</h2>
            <button
              @click="showFormEditOrganizationName"
              class="button-edit-pen ml-2"
              v-show="isEditable()"
            ></button>
          </div>
          <h5 class="org-slug py-2">
            {{ infoUser.organization ? infoUser.organization.slug : '' }}
          </h5>
        </div>
      </div>
      <div class="col-2">
        <p class="child-org">
          {{ isParentOrg ? $t('parent-organization') : $t('child-organization') }}
        </p>
      </div>
    </div>
    <div class="sf-sub-menu">
      <div class="col-md-12 top-menu">
        <ul class="nav nav-tabs nav-tabs-custom">
          <li class="acount-menu-item">
            <router-link :to="{ path: `/users/${userID}/edit_profile` }">{{
              $t('profile')
            }}</router-link>
          </li>
          <li class="acount-menu-item">
            <router-link exact :to="{ path: `/users/${userID}/edit_organization` }">{{
              $t('organization')
            }}</router-link>
          </li>
          <li class="acount-menu-item">
            <router-link :to="{ path: `/users/${userID}/edit_setting` }">{{
              $t('settings')
            }}</router-link>
          </li>

          <li class="acount-menu-item">
            <router-link :to="{ path: `/users/${userID}/all_users` }">{{
              $t('users')
            }}</router-link>
          </li>
          <li v-if="isParentOrg" class="acount-menu-item">
            <router-link :to="{ path: `/users/${userID}/sub_organizations` }">{{
              $t('sub-organizations')
            }}</router-link>
          </li>
          <!--           <li class="acount-menu-item">
            <router-link :to="{path: `/users/${userID}/edit_billing`}">BILLING</router-link>
          </li> -->
        </ul>
      </div>
    </div>

    <!-- Modal Edit Name of Organization -->
    <SfModal
      :title="$t('organization-name')"
      :width="786"
      closeOnOverlay
      :show.sync="isShownModalEdit"
    >
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group sf-label-group-lg">
                  <label class="primary-label">{{ $t('organization-slug') }}</label>
                </div>
                <input
                  :placeholder="$t('organization-slug')"
                  v-model="updateOrganization.organization.slug"
                  class="sf-input-text"
                />
              </div>
              <p v-if="!updateOrganization.organization.slug && showError" class="error-text text">
                {{ $t('enter-an-organization-slug') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="sf-modal-footer mt-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalEdit = false">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="editOrganizationName">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>

    <router-view></router-view>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import SfModal from '@/components/SfModal.vue'
import OrganizationService from '../../services/organization.service'
import moment from 'moment-timezone'

export default {
  name: 'account',
  components: {
    SfModal
  },
  data() {
    return {
      idOrgan: 1,
      userID: 0,
      selected: '',
      isParentOrg: '',
      isShownModalEdit: false,
      isShownModalEditLocation: false,
      isShownModalEditContact: false,
      showError: false,
      updateOrganization: {
        organization: {
          name: '',
          email: '',
          phone: '',
          address: '',
          country: '',
          province_state: '',
          postal_zip: '',
          city: '',
          slug: ''
        }
      },
      stateFilter: [],
      listCountries: [],
      user: '',
      emailCanUsed: false
    }
  },
  created() {},
  methods: {
    ...mapMutations('user', {
      setDataUserToStore: 'SET_DATA_USER_TO_STORE'
    }),
    isEditable() {
      return this.infoUser.user_type === 'employee'
    },
    getInfoUser() {
      this.$store.dispatch('user/getCurrent')
    },
    showFormEditOrganizationName() {
      this.isShownModalEdit = true
      this.showError = false
      this.setDataOrganization(this.updateOrganization, this.infoUser.organization)
    },
    editOrganizationName() {
      if (this.updateOrganization.organization.name) {
        OrganizationService.editOrganizationAccount(this.updateOrganization)
          .then((res) => {
            this.$toast.success('Organization name successfully updated')
            // this.setDataUserToStore(res.data)
            this.getInfoUser()
            // this.setCookiesAndUpdateHeader(res.data)
          })
          .catch((err) => {
            this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
          })
          .finally(() => {
            this.isShownModalEdit = false
          })
      } else {
        this.showError = true
      }
    },

    setDataOrganization(newOrg, currentOrg) {
      newOrg.organization.name = currentOrg.name
      newOrg.organization.email = currentOrg.email
      newOrg.organization.phone = currentOrg.phone
      newOrg.organization.address = currentOrg.address
      newOrg.organization.province_state = currentOrg.province_state
      newOrg.organization.postal_zip = currentOrg.postal_zip
      newOrg.organization.city = currentOrg.city
      newOrg.organization.country = currentOrg.country
      newOrg.organization.slug = currentOrg.slug
    },
    setCookiesAndUpdateHeader(data) {
      const tokenExpired = this.$cookies.get('tokenExpired')
      const remember = this.$cookies.get('rememberMe') === 'true'
      if (tokenExpired && remember) {
        this.$cookies.set(
          'user',
          this.user,
          moment(tokenExpired).format('ddd, DD MMM YYYY HH:mm:ss ZZ')
        )
      } else {
        this.$cookies.set('user', this.user, 0)
      }
      this.$bus.$emit('update_joins_organization', {
        organization: { name: data.name }
      })
    },
    getUser() {
      const user = this.$cookies.get('user')
      if (user) {
        this.user = user
        this.userID = user.id
      }
    }
  },
  channels: {
    AccountChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (data.action === 'update') {
          this.getInfoUser()
        }
      },
      disconnected() {}
    }
  },
  mounted() {
    this.getUser()
    this.$cable.subscribe({
      channel: 'AccountChannel',
      organization: this.$cookies.get('user').organization_id
    })
    this.isParentOrg = this.$cookies.get('user').is_parent_organization
  },
  computed: {
    ...mapState('user', {
      infoUser: 'infoUser',
      loading: 'loading'
    })
  }
}
</script>

<style lang="scss" scoped>
.org-top {
  .org-information {
    .org-name {
      display: flex;
      align-items: center;
      h2 {
        color: #193880;
      }
    }
    .org-type,
    .org-slug {
      color: #6c7785;
    }
  }
  .child-org {
    text-align: right;
    color: #193880;
  }
}
.acount-menu-item {
  width: 20% !important;
}
</style>
