<template>
  <vueTreeBase
    v-model="nodes"
    ref="slVueTree"
    @externaldrop="onExternalDropHandler"
    @keydown="onArrowDownHandler"
    @nodeclick="nodeClick"
    @select="onSelect"
    @drop="onDrop"
    @input="onChange"
    @toggle="openFolder"
  >
    <template slot="title" slot-scope="{ node }">
      <span class="item-icon" :class="{ 'none-toogle': node.isLeaf }">
        <template v-if="!node.isLeaf">
          <icon-folder-open v-if="node.children && node.children.length && node.isExpanded" />
          <icon-folder-close v-if="!node.isExpanded || !node.children.length" />
        </template>
        <template v-else-if="node.isLeaf">
          <icon-loop v-if="node.data.folder_type === typeFile.loops" />
          <icon-image v-else-if="node.data.file_type === typeFile.image" />
          <icon-video v-else-if="node.data.file_type === typeFile.video" />
          <icon-pdf v-else-if="node.data.file_type === typeFile.pdf" />
          <icon-social v-else-if="node.data.file_type === typeFile.instagram" />
          <icon-website v-else-if="node.data.file_type === typeFile.website" />
          <icon-youtube v-else-if="node.data.file_type === typeFile.youtube" />
          <icon-google-sheet v-else-if="node.data.file_type === typeFile.google_sheet" />
          <icon-google-slide v-else-if="node.data.file_type === typeFile.google_slide" />
          <icon-custom-asset v-else-if="node.data.file_type === typeFile.custom_asset" />
          <icon-canva v-else-if="node.data.file_type === typeFile.canva" />
        </template>
      </span>
      <span class="title" v-if="!node.data.isEdit">
        {{ node.data.name }}
      </span>
      <input
        class="edit"
        :ref="`item-${node.data.file_type}-${node.data.id}`"
        @blur="blurEdit(node, $event)"
        @keyup.enter="blurEdit(node, $event)"
        :value="node.data.name"
        :style="{
          opacity: +!!node.data.isEdit,
          'pointer-events': !!node.data.isEdit ? 'initial' : 'none'
        }"
        type="text"
      />
    </template>

    <template slot="toggle" slot-scope="{ node }">
      <iconCaret v-if="node.data && node.data.has_children && !node.isExpanded" />
      <iconCaretBot v-if="node.data && node.data.has_children && node.isExpanded" />
    </template>
    <template
      v-if="!isShowSidebar && !node.data.isEdit"
      slot="sidebar"
      slot-scope="{ node }"
      :temp="node"
    >
      <div
        class="actions"
        v-if="
          !node.data.shared_folder &&
          node.data.folder_type !== 'sub_organization' &&
          node.data.folder_type !== 'organizations'
        "
      >
        <div class="btn-action btn-edit btn-edit-tree-item" @click="edit(node, $event)">
          <img src="~@/assets/images/loop-edit-icon.svg" />
        </div>
        <div class="btn-action btn-delete" @click="deleteItem(node, $event)">
          <img src="~@/assets/images/loop-delete-icon.svg" />
        </div>
      </div>
    </template>
  </vueTreeBase>
</template>

<script>
import vueTreeBase from '@/components/vueTreeBase'

import iconFolderOpen from '@/assets/images/folder-tree.svg?inline'
import iconFolderClose from '@/assets/images/close-folder.svg?inline'
import iconImage from '@/assets/images/image-listview-icon.svg?inline'
import iconVideo from '@/assets/images/video-listview-icon.svg?inline'
import iconPdf from '@/assets/images/pdf-listview-icon.svg?inline'
import iconYoutube from '@/assets/images/youtube-listview-icon.svg?inline'
import iconSocial from '@/assets/images/instagram-list-view-icon.svg?inline'
import iconWebsite from '@/assets/images/web-listview-icon.svg?inline'
import iconGoogleSheet from '@/assets/images/google-sheet-listview-icon.svg?inline'
import iconGoogleSlide from '@/assets/images/google-slide-listview-icon.svg?inline'
import iconCustomAsset from '@/assets/images/custom-asset-listview-icon.svg?inline'
import iconCaret from '@/assets/images/caret-down.svg?inline'
import iconCaretBot from '@/assets/images/caret-bot.svg?inline'
import iconLoop from '@/assets/images/loops-tree-folder.svg?inline'
import typeFile from '@/constant/typeFile'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import iconCanva from '@/assets/images/canva-listview-icon.svg?inline'

export default {
  name: 'VueTree',
  props: ['nodesTree', 'isShowSidebar'],
  data() {
    return {
      typeFile,
      nodes: this.nodesTree
    }
  },
  watch: {
    nodesTree(value) {
      this.nodes = value
    },
    nodes(value) {}
  },
  components: {
    vueTreeBase,
    iconFolderOpen,
    iconFolderClose,
    iconImage,
    iconVideo,
    iconPdf,
    iconSocial,
    iconWebsite,
    iconCaret,
    iconCaretBot,
    iconLoop,
    iconYoutube,
    iconGoogleSheet,
    iconGoogleSlide,
    iconCustomAsset,
    iconCanva
  },
  methods: {
    onSelect(node) {
      this.$emit('selected', node[0])
    },
    openFolder(node) {
      this.$emit('openFolder', node)
    },
    nodeClick(node) {
      if (node.data.file_type === 'folder') {
        this.$emit('selectedFolder', node)
      }
    },
    onExternalDropHandler(dropNode, cursorPosition, event, ...rg) {
      this.$emit('drop', { dropNode, cursorPosition })
    },
    onDrop(dropNode, cursorPosition) {
      this.$emit('drop', { dropNode, cursorPosition })
    },
    onChange(data) {
      this.$emit('dataChanged', data)
    },
    onArrowDownHandler(event) {
      event.preventDefault()
      const keyCode = event.code
      const slVueTree = this.$refs.slVueTree

      if (slVueTree.selectionSize === 1) {
        const selectedNode = slVueTree.getSelected()[0]
        let nodeToSelect

        if (keyCode === 'ArrowDown') {
          nodeToSelect = slVueTree.getNextNode(selectedNode.path, (node) => node.isVisible)
        } else if (keyCode === 'ArrowUp') {
          nodeToSelect = slVueTree.getPrevNode(selectedNode.path, (node) => node.isVisible)
        } else if (keyCode === 'Enter' || keyCode === 'Space') {
          if (selectedNode.isLeaf) return
          slVueTree.updateNode(selectedNode.path, {
            isExpanded: !selectedNode.isExpanded
          })
        }

        if (!nodeToSelect) return

        slVueTree.select(nodeToSelect.path)
      } else if (keyCode === 'ArrowDown') {
        slVueTree.select(slVueTree.getFirstNode().path)
      } else if (keyCode === 'ArrowUp') {
        slVueTree.select(slVueTree.getLastNode().path)
      }
    },
    edit(node, event) {
      window.slVueTree.updateNode(node.path, {
        data: {
          ...node.data,
          isEdit: true
        }
      })
      this.$refs[`item-${node.data.file_type}-${node.data.id}`].focus()
    },
    blurEdit(node, event) {
      const newNode = cloneDeep(node)
      set(newNode, 'data.name', event.target.value)
      window.slVueTree.updateNode(newNode.path, {
        data: {
          ...newNode.data,
          isEdit: false
        }
      })
      if (node.data.name !== event.target.value) {
        this.$emit('editNameItem', node, event.target.value)
      }
    },
    deleteItem(node, event) {
      this.$emit('deleteItem', node)
    }
  },
  mounted() {
    window.slVueTree = this.$refs.slVueTree
  }
}
</script>

<style lang="scss" scoped>
@-moz-document url-prefix() {
  .sl-vue-tree-node-item .title {
    overflow: unset !important;
    &:hover {
      overflow: unset !important;
    }
  }
}
.btn-action {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
  }
}
.sl-vue-tree-node-item {
  &:hover {
    .title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 70%;
    }
  }
  .title {
    width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13.5px;
    display: inline-block;
    z-index: 1;
  }
  .edit {
    width: 70%;
    height: 100%;
    z-index: 1;
  }
  .actions {
    position: absolute;
    right: 5px;
    top: 0;
    display: none;
    background: white;
    border: 1px #e8e8e8 solid;
    border-radius: 3px;
    z-index: 99;
    div {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      &:first-child {
        border-right: 1px #e8e8e8 solid;
      }
    }
  }
  &:hover {
    .actions {
      display: flex;
    }
  }
}
</style>
