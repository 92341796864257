<template>
  <div class="main-term">
    <section class="content_wrapper">
      <!-- Begin: Content -->
      <div class="modal-content">
        <div class="modal-header add">
          <h4 class="modal-title">Term &amp; Conditions</h4>
        </div>
        <div class="modal-body">
          <div class="modal-body-content">
            <div class="content-term">
              <p v-html="contentTerm"></p>
            </div>
          </div>
          <div class="term-info col-xs-12">
            <button
              :class="checked ? 'button-term-active' : 'button-term'"
              @click="checked = !checked"
            ></button>
            <p @click="checked = !checked">I agree to the terms of service</p>
          </div>
        </div>
        <div class="modal-footer">
          <div class="bnt-block">
            <button class="btn btn-custom" @click="cancelTerm">Cancel</button>
            <button class="btn btn-custom-save" :disabled="!checked" @click="acceptTerm">
              Accept
            </button>
          </div>
        </div>
      </div>
      <!-- End: Content -->
    </section>
  </div>
</template>
<script>
import TermLatestService from '../services/termLatest.service'
export default {
  data() {
    return {
      checked: false,
      contentTerm: '',
      from: null,
      previous: null
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from
    })
  },
  methods: {
    acceptTerm() {
      const path = this.$cookies.get('historyPath')
        ? this.$cookies.get('historyPath')
        : this.from.path
      TermLatestService.acceptTermLatesService().then(() => {
        this.$router.push(path)
        this.$cookies.remove('historyPath')
      })
    },
    cancelTerm() {
      this.$cookies.remove('accessToken')
      this.$cookies.remove('refreshToken')
      this.$cookies.remove('tokenExpired')
      this.$cookies.remove('rememberMe')
      this.$cookies.remove('user')
      this.$router.push('/login')
    }
  },
  mounted() {
    TermLatestService.getTermLatestService()
      .then((res) => {
        this.contentTerm = res.data.content
      })
      .catch((error) => {
        this.$toast.error(error.message ? error.message : 'Something went wrong')
      })
  }
}
</script>
<style lang="scss" scoped>
.main-term {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../src/assets/images/app-background.jpg');
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
}

.content_wrapper {
  width: 800px;
  margin-top: 10vh;
}

.modal-header {
  background-repeat: no-repeat;
  height: 60px;
  padding-left: 40px;
  color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  background-image: linear-gradient(to left, #70b1ff, #236cc4);

  .modal-title {
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.2px;
    color: #ffffff;
  }
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-body-content {
  background: #f6f7fb;
}

.content-term {
  padding: 30px;
  font-size: 12px;
  max-width: 100%;
  max-height: 300px;
  overflow: auto;
  align-items: center;
  white-space: pre-line;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 7px;
  }

  p {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-size: 12px;
    color: #666666;
    line-height: 1.5;
  }
}

.term-info {
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-top: 15px;
  line-height: 30px;
  font-size: 14px;
  font-weight: normal;
  color: #4a4a4a;

  p {
    padding-left: 10px;
    font: 14px 'Open Sans', sans-serif;
    cursor: pointer;
  }

  .button-term {
    background-image: url('../assets/images/term.svg');
    width: 25px;
    height: 25px;
    background-size: cover;
    background-color: transparent;
    border: 0;
  }

  .button-term-active {
    background-image: url('../assets/images/term-active.svg');
    width: 25px;
    height: 25px;
    background-size: cover;
    background-color: transparent;
    border: 0;
  }
}

.modal-footer {
  display: flex;
  justify-content: center;
  border: none;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  padding: 9px 12px;
  font-size: 13px;
  line-height: 1.49;
}

.btn-custom {
  width: 150px;
  height: 40px;
  background-color: transparent;
  border-radius: 20px;
  border: 1px solid #2c7fe1;
  color: #2c7fe1;
  font-weight: bold;
  box-shadow: 0 3px 5px 0 rgba(0, 89, 160, 0.18);
  margin-right: 10px;

  &:hover {
    color: #2c7fe1;
  }
}

.btn-custom-save {
  width: 150px;
  height: 40px;
  background-color: #2c7fe1;
  border-radius: 20px;
  font-weight: bold;
  border: 1px solid #2c7fe1;
  color: #fff;
  box-shadow: 0 3px 5px 0 rgba(0, 89, 160, 0.28);
  margin-left: 10px;

  &:hover {
    color: #fff;
  }
}
</style>
