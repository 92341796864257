import BaseService from './base.service'

class CountriesService extends BaseService {
  get entity() {
    return 'countries'
  }

  getAllCountries() {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getListTimezone() {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/time_zone`)
        .then((response) => {
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getAllStates() {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get('states')
        .then((response) => {
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }
}

export default new CountriesService()
