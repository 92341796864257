<template>
  <div class="lefttoolbar-com">
    <div
      @click="activeToolBar('overall')"
      class="ig-toolbar overall"
      v-bind:class="{ active: toolBar.overall }"
    >
      <div class="toolbar-icon">
        <img :src="toolBar.overall ? toolbarSettingsIconActive : toolbarSettingsIcon" />
      </div>
      <p class="toolbar-title">{{ $t('overall') }}</p>
    </div>

    <div
      @click="activeToolBar('edit')"
      class="ig-toolbar edit"
      v-bind:class="{ active: toolBar.edit }"
    >
      <div class="toolbar-icon">
        <img :src="toolBar.edit ? toolbarEditIconActive : toolbarEditIcon" />
      </div>
      <p class="toolbar-title">{{ $t('edit') }}</p>
    </div>

    <div
      @click="activeToolBar('widget')"
      class="ig-toolbar widget"
      v-bind:class="{ active: toolBar.widget }"
    >
      <div class="toolbar-icon">
        <img :src="toolBar.widget ? toolbarWidgetIconActive : toolbarWidgetIcon" />
      </div>
      <p class="toolbar-title">{{ $t('widgets') }}</p>
    </div>

    <div
      @click="activeToolBar('background')"
      class="ig-toolbar background"
      v-bind:class="{ active: toolBar.background }"
    >
      <div class="toolbar-icon">
        <img :src="toolBar.background ? toolbarBgIconActive : toolbarBgIcon" />
      </div>
      <p class="toolbar-title">{{ $t('background') }}</p>
    </div>
  </div>
</template>
<script>
import toolbarSettingsIcon from '@/assets/images/toolbar-settings-icon.svg'
import toolbarEditIcon from '@/assets/images/toolbar-edit-icon.svg'
import toolbarBgIcon from '@/assets/images/toolbar-bg-icon.svg'
import toolbarWidgetIcon from '@/assets/images/toolbar-widget-icon.svg'
import toolbarSettingsIconActive from '@/assets/images/toolbar-settings-icon-active.svg'
import toolbarEditIconActive from '@/assets/images/toolbar-edit-icon-active.svg'
import toolbarBgIconActive from '@/assets/images/toolbar-bg-icon-active.svg'
import toolbarWidgetIconActive from '@/assets/images/toolbar-widget-icon-active.svg'
import { mapState, mapGetters } from 'vuex'
export default {
  data: function () {
    return {
      toolbarSettingsIcon,
      toolbarEditIcon,
      toolbarBgIcon,
      toolbarWidgetIcon,
      toolbarSettingsIconActive,
      toolbarEditIconActive,
      toolbarBgIconActive,
      toolbarWidgetIconActive
    }
  },

  computed: {
    ...mapState('instagrameditor', {
      toolBar: 'toolBar'
    }),
    ...mapGetters('instagrameditor', ['getAuthentication'])
  },

  methods: {
    activeToolBar(value) {
      this.$store.dispatch('instagrameditor/activeToolBar', value)
    }
  }
}
</script>
<style scoped>
.lefttoolbar-com {
  margin-top: 15px;
}
.ig-toolbar {
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.ig-toolbar.active {
  color: #2c7fe1;
}
.toolbar-title {
  margin-top: 10px;
}
.active .toolbar-icon {
  background-color: #2c7fe1;
  border: 0;
}
.toolbar-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 0.5px solid #dcdcdc;
}
</style>
