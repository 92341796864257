<template>
  <SfModal
    :title="'Edit Template'"
    :width="768"
    :height="0"
    closeOnOverlay
    :show.sync="dataShow"
    @update:show="updateShow"
  >
    <div class="sf-modal-body">
      <div class="container">
        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">Title</label>
              </div>
              <input
                type="text"
                name="smart_asset"
                id="smart_asset_displayname"
                class="sf-input-text"
                placeholder="Title"
                v-model="dataInput.displayname"
                :class="{ 'input-error-form': submitted && $v.dataInput.displayname.$invalid }"
              />
            </div>
            <div
              class="invalid-error-text text-right"
              v-if="submitted && !$v.dataInput.displayname.required"
            >
              Template Title can't be blank
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">Tags</label>
              </div>
              <vue-tags-input
                class="sf-tag"
                v-model="tag"
                :tags="dataInput.tags"
                @tags-changed="(newTags) => (dataInput.tags = newTags)"
              />
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">Categories</label>
              </div>
              <multiselect
                :searchable="false"
                :allow-empty="false"
                deselectLabel=""
                selectLabel=""
                selectedLabel=""
                label="displayname"
                track-by="id"
                class="sf-multiselect"
                v-model="dataInput.category"
                :options="optionCategories"
              >
              </multiselect>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-9">
            <div class="sf-form-group">
              <div class="sf-label-group">
                <label class="primary-label">Description</label>
              </div>
              <input
                v-model="dataInput.templateDescription"
                placeholder="A quick idea of the template"
                class="sf-input-text"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sf-modal-footer mt-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="sf-modal-action">
              <button class="sf-secondary" type="button" @click="updateShow">Cancel</button>
              <button type="button" class="sf-primary" @click="onClickNext">Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SfModal>
</template>

<script>
import SfModal from '@/components/SfModal'
import Multiselect from 'vue-multiselect'
import VueTagsInput from '@johmun/vue-tags-input'
import AdminTemplateCategoryService from '@/services/admin-template-category.service'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    SfModal,
    VueTagsInput,
    Multiselect
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dataShow: this.show,
      tag: '',
      dataInput: {
        displayname: '',
        tags: [],
        orientation: 'landscape',
        smart_asset_type: 'custom_asset',
        category: '',
        templateDescription: ''
      },
      submitted: false,
      optionCategories: []
    }
  },
  validations: {
    dataInput: {
      displayname: {
        required
      }
    }
  },
  watch: {
    show(value) {
      this.dataShow = value
      if (value) {
        this.dataInput = { ...this.dataInput, ...this.data }
        this.dataInput.tags = this.dataInput.tags.map((item) => ({ text: item.name, ...item }))
        this.getTemplateCategories()
      }
    }
  },
  methods: {
    updateShow() {
      this.$emit('update:show', false)
    },
    getTemplateCategories() {
      AdminTemplateCategoryService.getAllTemplateCategoriesNoPaging()
        .then((response) => {
          this.optionCategories = response.data
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    async onClickNext() {
      this.submitted = true
      const tags = this.dataInput.tags
      localStorage.setItem(
        'instagram',
        JSON.stringify({
          smart_asset_id: this.dataInput.id,
          displayname: this.dataInput.displayname,
          tags: tags,
          orientation: this.dataInput.orientation,
          current_folder: {},
          smart_asset_type: 'instagram',
          orientation_changed: false
        })
      )

      localStorage.setItem(
        'instagramInfo',
        JSON.stringify({
          access_token: this.data.properties.ig_access_token,
          user: {
            id: this.data.properties.query
          }
        })
      )
      this.$emit('modal:next', { ...this.dataInput })
    },
    resetForm() {
      this.dataInput = {
        displayname: '',
        tags: [],
        orientation: 'landscape',
        smart_asset_type: 'custom_asset',
        category: '',
        templateDescription: ''
      }
    }
  },
  created() {
    this.$bus.$on('reset-submitted', (value) => {
      this.submitted = false
      this.resetForm()
    })
  },
  beforeDestroy() {
    this.resetForm()
  }
}
</script>

<style lang="scss">
.form-control {
  height: auto !important;
  .vue-tags-input {
    margin: -0.375rem -0.75rem;
    border-radius: 0.25rem;
    * {
      border: transparent;
    }
    border: transparent;
    .ti-tag {
      background-color: #e0edf5;
      border-radius: 3px;
      color: #2f5d92;
      float: left;
      font-weight: 600;
      height: 28px;
      line-height: 28px;
      margin: 0 8px 6px 0;
      padding: 0 1px 0 8px;
      white-space: nowrap;
    }
  }
}
</style>
