<template>
  <transition name="modaluppy">
    <div class="uppy" @click="onClickOverlay" :class="{ hidden: !dataShow }" ref="overlay">
      <div class="overlay-dashboard">
        <button id="closeUppy" class="close" @click="close"></button>
      </div>
      <div class="DashboardContainer"></div>
    </div>
  </transition>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { API_ASSET_URL } from '../../.env'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/src/style.scss'
import AwsS3 from '@uppy/aws-s3'
import SmartAssetsService from '@/services/smart-assets.service'
import { uuid } from 'vue-uuid'
import { getFileSHA1 } from '@/helper/mathHelper'
const Uppy = require('@uppy/core/lib')
const Dashboard = require('@uppy/dashboard/lib')
const Dropbox = require('@uppy/dropbox/lib')
const Url = require('@uppy/url/lib')
const Webcam = require('@uppy/webcam/lib')
const en = require('@uppy/locales/lib/en_US')
const fr = require('@uppy/locales/lib/fr_FR')
const es = require('@uppy/locales/lib/es_ES')

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    endpoint: {
      type: String,
      required: false,
      default() {
        return '/upload'
      }
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    },
    folderID: {
      type: Number
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      dataShow: false,
      uppy: {}
    }
  },

  computed: {
    ...mapState('library', {
      smartAssetDataModal: 'smartAssetDataModal',
      organizationId: 'organizationId'
    })
  },

  watch: {
    show(value) {
      this.dataShow = value
      if (value) {
        this.setLocale()
      }
    }
  },

  mounted() {
    const accessToken = this.$cookies.get('accessToken')
    this.uppy = Uppy({
      restrictions: {
        maxFileSize: 1073741824,
        maxNumberOfFiles: 20,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*', '.mp4', '.m4v', '.mkv']
      },
      locale: this.getLocale()
    })
      .use(Dashboard, {
        trigger: '.UppyModalOpenerBtn',
        inline: true,
        target: '.DashboardContainer',
        replaceTargetContent: true,
        showProgressDetails: true,
        note: 'Images and video only up to 1 GB',
        height: 470,
        browserBackButtonClose: true
      })
      .use(Webcam, {
        target: Dashboard,
        id: 'webcam',
        videoConstraints: {
          width: { ideal: 670 },
          height: { ideal: 370 },
          aspectRatio: { ideal: 4 / 3 }
        }
      })
      .use(Dropbox, { target: Dashboard, companionUrl: 'https://uppy.screenfluence.com' })
      .use(Url, { target: Dashboard, companionUrl: 'https://uppy.screenfluence.com' })
      .use(AwsS3, {
        async getUploadParameters(file) {
          // send a request to our server to get the S3 upload URL
          return fetch(`${API_ASSET_URL}/amazon/pre-signed/?content_type=${file.type}`, {
            method: 'GET',
            headers: {
              Authorization: accessToken,
              'Content-Type': 'application/json'
            }
          })
            .then((response) => response.json())
            .then(async (data) => {
              // send a request to save the file to S3
              return {
                method: 'POST',
                url: data.data.url,
                fields: {
                  ...data.data.url_fields
                }
              }
            })
        }
      })
    this.uppy.on('upload', (data) => {
      // uuid file name
      data.fileIDs.forEach((fileID) => {
        const file = this.uppy.getFile(fileID)
        const fileExtension = file.name.split('.').pop()
        const uuidFileName = `${uuid.v1()}.${fileExtension}`
        const newFile = new File([file.data], uuidFileName, { type: file.type })
        this.uppy.setFileMeta(fileID, { name: uuidFileName })
        this.uppy.setFileState(fileID, { data: newFile })
      })
      this.uppy.setMeta({
        current_folder: this.folderID || 0,
        organization_id: this.organizationId || ''
      })
    })
    this.uppy.on('upload-success', async (file, response) => {
      const validStatuses = [200, 201]
      if (validStatuses.includes(response.status)) {
        const sha1 = await getFileSHA1(file.data)
        const meta = file.meta
        const key = response.body.key
        // Check if the file is from Dropbox or URL and fetch the actual file data
        if (file.source === 'Dropbox' || file.source === 'Url') {
          file.data = await fetch(response.uploadURL)
            .then((response) => response.blob())
            .catch((err) => {
              this.$toast.error(err.message ? err.message : 'Failed to fetch file from Dropbox/URL')
            })
        }

        if (file.type.match(/image\/*/)) {
          const img = new Image()
          img.src = URL.createObjectURL(file.data)
          img.onload = async () => {
            // create thumbnail image
            const thumbnailWidth = 450
            const thumbnailBlob = await this.createThumbnail(
              img,
              'image/jpeg',
              thumbnailWidth,
              img.height * (thumbnailWidth / img.width)
            )

            // save to database
            await this.uploadThumbAndCreateAsset(
              file,
              thumbnailBlob,
              meta,
              {
                width: img.width,
                height: img.height
              },
              sha1,
              key
            )
          }
        } else if (file.type.match(/video\/*/)) {
          const video = document.createElement('video')
          video.src = URL.createObjectURL(file.data)

          // create thumbnail video
          video.onloadedmetadata = async () => {
            video.currentTime = 2
          }
          video.onseeked = async () => {
            const thumbnailBlob = await this.createThumbnail(video, 'image/jpeg', 450, 340)

            // save to database
            await this.uploadThumbAndCreateAsset(
              file,
              thumbnailBlob,
              meta,
              { duration: video.duration },
              sha1,
              key
            )
          }
        }
      }
    })
    this.uppy.on('upload-error', (file, error, response) => {
      const responseData = JSON.parse(response.body.responseText)
      this.$toast.open({
        message: responseData.message,
        type: 'error'
      })
    })
    this.uppy.on('complete', (result) => {
      this.$emit('update:isLoading', true)
      const dashboard = this.uppy.getPlugin('Dashboard')
      dashboard.closeModal()
      this.uppy.reset()
      const closeUppy = document.getElementById('closeUppy')
      closeUppy.click()
    })
  },

  methods: {
    ...mapMutations('library', {
      addNewItemTreeFolder: 'SET_NEW_ITEM_TREE_FOLDER',
      updateCountAssetStatistical: 'UPDATE_COUNT_ASSET_STATISTICAL'
    }),
    async createThumbnail(element, type, width, height) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = width
      canvas.height = height
      ctx.drawImage(element, 0, 0, width, height)
      return new Promise((resolve) => canvas.toBlob(resolve, type))
    },
    close() {
      this.$emit('update:show', false)
    },
    sanitize(fileName) {
      return fileName.replace(/[^a-zA-Z0-9.]/g, '-')
    },
    onClickOverlay($event) {
      if (this.closeOnOverlay && $event && $event.target === this.$refs.overlay) {
        this.close()
      }
    },
    async uploadThumbAndCreateAsset(file, thumbnailBlob, meta, assetMeta, sha1, key) {
      try {
        const thumb_key = await this.uploadThumbnail(thumbnailBlob, 'image/jpeg')
        if (file.source === 'Dropbox' || file.source === 'Url') {
          meta.name = key.split('/').pop()
        }
        const params = {
          current_folder: meta.current_folder,
          organization_id: meta.organization_id,
          relativePath: meta.relativePath,
          name: file.name,
          smart_asset_details: {
            sha1,
            key: key,
            thumb_key,
            asset_file_file_name: meta.name,
            asset_file_content_type: meta['Content-Type'],
            asset_file_file_size: file.size,
            asset_file_meta: assetMeta
          }
        }
        await this.createAsset(params)
      } catch (err) {
        this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        this.$emit('update:isLoading', false)
      }
    },
    getLocale() {
      if (this.$i18n.locale === 'es') return es
      if (this.$i18n.locale === 'fr') return fr
      return en
    },
    setLocale() {
      this.uppy.setOptions({ locale: this.getLocale() })
    },
    async createAsset(params) {
      SmartAssetsService.uploadMedia(params)
        .then((res) => {
          this.$emit('upload:success', res.data)
          this.$toast.open({
            message: res.message ? res.message : 'New asset created successfully.',
            type: 'success'
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
        .finally(() => {
          this.$emit('update:isLoading', false)
        })
    },
    async uploadThumbnail(thumbnailBlob, contentType) {
      try {
        const accessToken = this.$cookies.get('accessToken')
        const thumbnailPresignedServer = await fetch(
          `${API_ASSET_URL}/amazon/pre-signed-for-thumbnail`,
          {
            method: 'GET',
            headers: {
              Authorization: accessToken,
              'Content-Type': 'application/json'
            }
          }
        ).then((response) => response.json())

        const formData = new FormData()
        Object.keys(thumbnailPresignedServer.data.url_fields).forEach((key) => {
          formData.append(key, thumbnailPresignedServer.data.url_fields[key])
        })
        formData.append('file', thumbnailBlob)

        // Upload the thumbnail to S3
        const uploadResponse = await fetch(thumbnailPresignedServer.data.url, {
          method: 'POST',
          body: formData
        })
        if (!uploadResponse.ok) {
          throw new Error('Failed to upload thumbnail to S3')
        }
        const responseText = await uploadResponse.text()
        const parser = new DOMParser()
        const xmlDoc = parser.parseFromString(responseText, 'application/xml')
        const keyElement = xmlDoc.getElementsByTagName('Key')[0]

        if (keyElement) {
          return keyElement.textContent
        } else {
          throw new Error('Failed to upload thumbnail to S3 because of missing key')
        }
      } catch (err) {
        this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.uppy {
  width: 100vw;
  height: 100vh;
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.DashboardContainer {
  margin-top: 5vh;
}
.overlay-dashboard {
  position: absolute;
  width: 750px;
  height: 470px;
  margin-top: 5vh;
  .close {
    position: absolute;
    right: -35px;
    top: -8px;
    width: 32px;
    height: 32px;
    background-image: url('~@/assets/images/cancel-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    justify-self: flex-end;
    opacity: 1;
  }
}
.hidden {
  display: none !important;
}
/* modal transition */
.modaluppy {
  &-enter-active,
  &-leave-active {
    transition: all 0.3s ease;
  }
  &-enter,
  &-leave-to {
    padding-top: 100px;
    opacity: 0;
  }
}
::v-deep .uppy-DashboardContent-addMoreCaption {
  position: relative;
  top: -3px;
}
::v-deep .uppy-Url-importButton {
  position: relative;
  margin-left: 20px;
  padding: 12px 22px 13px 22px;
  top: 0px;
  margin-top: -2px;
}
::v-deep .uppy-Webcam-container {
  display: flex;
  padding-left: 25px;
}
::v-deep .uppy-Webcam-footer {
  display: flex;
  flex-direction: column;
}
::v-deep .uppy-Webcam-buttonContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  margin-left: 3px;
}
::v-deep .uppy-Webcam-button--picture svg {
  width: 50px;
}
::v-deep .uppy-Webcam-video {
  width: 100%;
}
::v-deep .uppy-Webcam-button--submit {
  margin-bottom: 10px;
  svg {
    width: 30px;
    height: 30px;
    path {
      fill: green;
    }
  }
}
::v-deep [title='Discard recorded file'] {
  svg {
    width: 25px;
    height: 25px;
    g {
      fill: red;
    }
  }
}
::v-deep .uppy-Dashboard-poweredByIcon {
  width: 11px;
}

::v-deep .uppy-Webcam-button {
  width: 60px;
  color: rgb(231, 34, 34);
}
::v-deep .uppy-c-textInput {
  min-width: 200px;
}
</style>
