<template>
  <transition name="modal">
    <div class="ui-modal overlay component" v-if="show" @click="onClickOverlay" ref="overlay">
      <div
        v-bind:style="{
          width: width + 'px',
          height: height ? height + 'px' : '100vh'
        }"
        class="modal-content"
      >
        <div class="header">
          <h3 class="title">{{ title }}</h3>
          <button class="close" @click="close" v-if="showCloseButton"></button>
        </div>
        <div class="sf-modal-body mb-3">
          <div class="container-fluid">
            <div class="row mt-3">
              <div class="col-3">
                <div class="row">
                  <div class="col-12">
                    <SfInputSearch
                      v-model="dataSearch"
                      placeholder="Search"
                      v-debounce:500ms="searchInputChange"
                      class="sf-input-search"
                      @onSearchInputChange="searchInputChange"
                    />
                  </div>
                </div>
                <!-- <div class="row mt-2">
                <div class="col-12">
                  <div class="sf-select-group">
                    <Select2
                      class="sf-select2"
                       />
                      <span class="lb-right caret"></span>
                  </div>
                </div>
              </div> -->
                <div class="row mt-2">
                  <div class="col-12">
                    <div class="sf-form-group">
                      <div class="sf-label-group sf-label-group">
                        <label class="primary-label">Sort by</label>
                      </div>
                      <div class="sf-select-group">
                        <Select2
                          class="sf-select2"
                          v-model="sortTemplate"
                          :options="[{ id: 'name', text: 'Name' }]"
                          @change="sortTemplateChange"
                        />
                        <span class="lb-right caret"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <button
                      class="sf-link w-100 mt-2"
                      :class="{ 'is-active': isViewAll }"
                      @click="filterTemplateByCatgoryId('')"
                    >
                      All
                    </button>
                    <button
                      class="sf-link w-100 mt-2"
                      :class="{ 'is-active': isViewMyTemplates }"
                      @click="viewAllMyTemplates"
                    >
                      My templates
                    </button>
                    <template v-if="templateCategories && templateCategories.length">
                      <button
                        v-for="(item, index) in templateCategories"
                        @click="filterTemplateByCatgoryId(item.id)"
                        :key="index"
                        class="sf-link w-100 mt-2"
                        :class="{ 'is-active': categoryId === item.id }"
                      >
                        {{ item.displayname }}
                      </button>
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-9 templates-list">
                <div v-if="loading" class="d-flex justify-content-center align-items-center h-100">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <template v-else>
                  <template v-for="(value, name, index) in templatesGroup">
                    <div :key="index">
                      <div class="row">
                        <div class="col-10">
                          <h2 v-if="name === '0'">My Templates</h2>
                          <h2 v-else>{{ categoryName(value) }}</h2>
                        </div>
                        <div class="col-2 text-right" v-if="isViewAll">
                          <button v-if="name === '0'" class="sf-link" @click="viewAllMyTemplates">
                            View all
                          </button>
                          <button v-else class="sf-link" @click="viewAllTemplatesByCategory(name)">
                            View all
                          </button>
                        </div>
                      </div>

                      <div class="mt-3 template-carousel" v-if="isViewAll">
                        <VueSlickCarousel
                          v-bind="settings"
                          ref="carousel"
                          class="carousel"
                          v-if="value"
                        >
                          <div v-for="(item, idx) in value" :key="idx">
                            <div
                              class="template-item mx-3"
                              :class="{ active: selectedTemplateId == item.id }"
                              @click="selectedTemplateId = item.id"
                            >
                              <div class="template-thumb sf-box-shadow">
                                <img :src="item.origin_link" />
                              </div>
                              <div
                                class="d-flex align-items-start justify-content-between mt-2 w-100"
                              >
                                <p :title="item.name" class="template-name text-truncate">
                                  {{ item.name }}
                                </p>
                                <button
                                  @click.stop="openEditTemplateModal(item)"
                                  class="button-edit-table"
                                  :title="$t('tooltip.edit')"
                                ></button>
                              </div>
                              <div class="template-desc">{{ item.description }}</div>
                            </div>
                          </div>
                        </VueSlickCarousel>
                      </div>
                      <div class="row mt-3" v-else>
                        <template v-if="value">
                          <div v-for="(item, idx) in value" :key="idx" class="col-4">
                            <div
                              class="template-item"
                              :class="{ active: selectedTemplateId == item.id }"
                              @click="selectedTemplateId = item.id"
                            >
                              <div class="template-thumb sf-box-shadow">
                                <img :src="item.origin_link" />
                              </div>
                              <div
                                class="d-flex align-items-start justify-content-between mt-2 w-100"
                              >
                                <p :title="item.name" class="template-name text-truncate">
                                  {{ item.name }}
                                </p>
                                <button
                                  @click.stop="openEditTemplateModal(item)"
                                  class="button-edit-table"
                                  :title="$t('tooltip.edit')"
                                ></button>
                              </div>
                              <div class="template-desc">{{ item.description }}</div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                  <div
                    v-if="isEmptyTemplates"
                    class="d-flex justify-content-center align-items-center h-100"
                  >
                    Templates are empty
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="sf-modal-footer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button class="sf-secondary" type="button" @click="close">Cancel</button>
                  <button type="button" @click="useTemplate()" class="sf-primary">
                    Use Template
                  </button>
                  <button class="sf-btn-delete" type="button" @click="deleteTemplate()">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SfModal
        :title="$t('modal.title.edit-template-name')"
        :width="786"
        closeOnOverlay
        :show.sync="showEditTemplateModal"
        @onClose="cancelEditTemplate"
      >
        <div class="sf-modal-body mb-3">
          <div class="container">
            <div class="row mt-3">
              <div class="col-9">
                <div class="sf-form-group">
                  <div class="sf-label-group sf-label-group-lg">
                    <label class="primary-label">Name</label>
                  </div>
                  <input
                    v-model.trim="template.name"
                    placeholder="Custom template"
                    class="sf-input-text"
                    :class="{
                      'input-error-form':
                        submitEditTemplateName &&
                        (!$v.template.name.required || !$v.template.name.minLength)
                    }"
                  />
                </div>
                <div
                  v-show="submitEditTemplateName && !$v.template.name.required"
                  class="invalid-error-text text-right"
                >
                  Name can't be blank
                </div>
                <div
                  v-show="submitEditTemplateName && !$v.template.name.minLength"
                  class="invalid-error-text text-right"
                >
                  {{ $t('name-minLength-2') }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sf-modal-footer">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="sf-modal-action">
                  <button class="sf-secondary" type="button" @click="cancelEditTemplate">
                    Cancel
                  </button>
                  <button type="button" class="sf-primary" @click="saveTemplateName">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SfModal>
    </div>
  </transition>
</template>

<script>
import SfInputSearch from '@/components/SfInputSearch.vue'
import SmartAssetsTemplateService from '@/services/smart-asset-template.service'
import AdminTemplateCategoryService from '@/services/admin-template-category.service'
import imgIcon from '@/assets/images/smart-assets/browser-page-layout.svg'
import Select2 from 'v-select2-component'
import { groupBy, isEmpty } from 'lodash'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { required, minLength } from 'vuelidate/lib/validators'
import SfModal from '@/components/SfModal.vue'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'TemplateModal',
  components: {
    SfInputSearch,
    Select2,
    VueSlickCarousel,
    SfModal
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    closeOnOverlay: {
      type: Boolean,
      default: false
    },
    width: Number,
    title: String,
    height: Number,
    orientation: String
  },
  data() {
    return {
      imgIcon,
      templatesGroup: null,
      templateCategories: null,
      selectedTemplateId: '',
      sortTemplate: 'name',
      dataSearch: '',
      categoryId: '',
      isViewAll: true,
      isViewMyTemplates: false,
      loading: false,
      settings: {
        dots: false,
        arrows: true,
        focusOnSelect: true,
        speed: 500,
        infinite: false,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      },
      showEditTemplateModal: false,
      template: {
        name: '',
        id: ''
      },
      submitEditTemplateName: false
    }
  },
  validations: {
    template: {
      name: {
        required,
        minLength: minLength(2)
      }
    }
  },
  mounted() {
    this.getTemplateCategories()
    // this.getAssetTemplates()
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.getAssetTemplates()
    })
    this.$bus.$on('closeModal', (value) => {
      this.close()
    })
  },

  watch: {
    show: function (value) {
      if (value) {
        this.getAssetTemplates()
      }
    }
  },
  computed: {
    isEmptyTemplates() {
      return isEmpty(this.templatesGroup)
    }
  },
  beforeDestroy() {
    this.$bus.$off('onSearchInputChange')
  },

  methods: {
    close() {
      this.$emit('update:show', false)
    },
    useTemplate() {
      if (this.selectedTemplateId) {
        this.$emit('update:selectedTemplate', this.selectedTemplateId)
      } else {
        this.$toast.error('Template selection required!')
      }
    },
    categoryName(array) {
      return array[0].category.displayname
    },
    deleteTemplate() {
      SmartAssetsTemplateService.deleteAssetTemplate(this.selectedTemplateId)
        .then((data) => {
          this.getAssetTemplates()
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Some things went wrong. Please try again')
        })
    },
    onClickOverlay($event) {
      if (this.closeOnOverlay && $event && $event.target === this.$refs.overlay) {
        this.close()
      }
    },
    groupTemplatesByCategory(data) {
      this.templatesGroup = {}
      const myTemplates = data.filter((item) => !item.category)
      let categoryTemplates = data.map((item) => {
        if (item.category) {
          item.categoryId = item.category.id
          return item
        }
      })
      if (this.categoryId === '') {
        categoryTemplates = categoryTemplates.filter((item) => item)
        if (this.isViewAll === true) {
          if (categoryTemplates.length > 0) {
            this.templatesGroup = groupBy(categoryTemplates, 'categoryId')
          }
        }
        if (myTemplates.length > 0) {
          this.templatesGroup['0'] = myTemplates
        }
      } else {
        categoryTemplates = categoryTemplates.filter((item) => item.categoryId === this.categoryId)
        if (categoryTemplates.length > 0) {
          this.templatesGroup = groupBy(categoryTemplates, 'categoryId')
        }
      }
    },
    getAssetTemplates() {
      this.loading = true
      SmartAssetsTemplateService.getAllSmartAssetTemplate({
        orientation: this.orientation,
        categoryId: this.categoryId,
        search: this.dataSearch
      })
        .then((response) => {
          this.groupTemplatesByCategory(response.data)
          this.sortTemplateChange('name')
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
        .finally(() => {
          this.loading = false
        })
    },
    searchInputChange(val) {
      this.dataSearch = val
      this.getAssetTemplates()
    },
    filterTemplateByCatgoryId(val) {
      this.categoryId = val
      this.isViewMyTemplates = false
      this.isViewAll = val === ''
      this.getAssetTemplates()
    },
    viewAllMyTemplates() {
      if (this.categoryId === '') {
        this.isViewAll = false
      }
      this.isViewMyTemplates = true
      this.getAssetTemplates()
    },
    viewAllTemplatesByCategory(val) {
      this.filterTemplateByCatgoryId(Number(val))
    },
    getTemplateCategories() {
      AdminTemplateCategoryService.getAllTemplateCategoriesNoPaging()
        .then((response) => {
          this.templateCategories = response.data
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
    },
    sortTemplateChange(val) {
      switch (val) {
        case 'name':
          for (const prop in this.templatesGroup) {
            this.templatesGroup[prop].sort((a, b) => {
              return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
            })
          }
          break

        default:
          break
      }
    },
    openEditTemplateModal(item) {
      this.showEditTemplateModal = true
      this.template = cloneDeep(item)
    },
    cancelEditTemplate() {
      this.showEditTemplateModal = false
      this.submitEditTemplateName = false
    },
    saveTemplateName() {
      this.submitEditTemplateName = true
      if (this.$v.template.name.$invalid) return
      this.showEditTemplateModal = false
      SmartAssetsTemplateService.editTemplateName({
        id: this.template.id,
        name: this.template.name
      })
        .then((response) => {
          this.getAssetTemplates()
          this.$toast.success('Template successfully updated')
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
        .finally(() => {
          this.showEditTemplateModal = false
          this.submitEditTemplateName = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-modal.overlay.component {
  width: 100vw;
  height: 100vh;
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .modal-content {
    max-width: 100vw;
    height: 100vh;
    background-color: #fff;
    box-shadow: 0 7px 20px rgba(0, 0, 0, 0.5);
    border-radius: 0;
    border: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    .header {
      background-image: linear-gradient(to left, #70b1ff, #236cc4);
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      .add-icon {
        background-image: url('../../assets/images/setting-icon.svg');
        width: 40px;
        height: 40%;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        left: 25px;
      }
      h3 {
        color: #ffffff;
      }
      .close {
        width: 32px;
        height: 32px;
        background-image: url('../../assets/images/cancel-icon.svg');
        background-repeat: no-repeat;
        background-position: center;
        justify-self: flex-end;
        position: absolute;
        right: 0;
        opacity: 1;
      }
    }
  }
}

/* modal transition */
.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}
.modal-enter,
.modal-leave-to {
  padding-top: 100px;
  opacity: 0;
}
.template-item {
  cursor: pointer;
  min-height: 195px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  flex-flow: column;
  border: 2px solid transparent;
  &.active {
    border: 2px solid #193880;
  }
  .template-thumb {
    border-radius: 8px;
    img {
      width: 100%;
      border-radius: 8px;
    }
  }
}
.templates-list {
  height: calc(100vh - 162px);
  overflow: auto;
}
.template-name {
  color: #193880;
  font-weight: 600;
  max-width: calc(100% - 24px);
  margin-right: 4px;
  white-space: break-spaces;
}
.template-desc {
  color: #6c7785;
  font-size: 12px;
  line-height: 17px;
  margin-top: 8px;
  width: 100%;
}
.is-active {
  color: #193880;
  margin-bottom: -1px;
  background-color: #e5f1ff;
  border: 1px solid #324b96 !important;
}
::v-deep.template-carousel {
  padding: 0 32px;
  .slick-prev,
  .slick-next {
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 100;
    left: -32px;
    width: 32px;
    height: 32px;
    background: #ffffff;
    opacity: 0.9;
    border: 1px solid #edf3fa;
    box-sizing: border-box;
    box-shadow: inset 0px 8px 16px rgba(237, 243, 250, 0.25),
      inset 0px -8px 16px rgba(237, 243, 250, 0.25);
    border-radius: 50%;
    &::before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      background-image: url('~@/assets/images/smart-assets/carousel-previous-icon.png');
      background-repeat: no-repeat;
      background-size: 16px;
    }
  }
  .slick-next {
    right: -32px;
    left: auto;
    &::before {
      background-image: url('~@/assets/images/smart-assets/carousel-next-icon.png');
    }
  }
}
.button-edit-table {
  min-width: 24px;
}
</style>
