export default {
  SET_ERROR(state, data) {
    state.error = data
  },

  SET_LOADING(state, data) {
    state.loading = data
  },

  GET_ALL_PLAYERS_ADMIN(state, data) {
    state.allPlayerAdmin = data.items
    state.pagination.total = data.total_pages
    state.total_records = data.total_records
  },
  GET_DETAIL_ADMIN_PLAYER(state, data) {
    state.itemsByID = data
  },
  SET_DATA_PLAYER(state, data) {
    state.itemsByID.name = data.name
    state.itemsByID.description = data.description
    state.itemsByID.orientation = data.orientation
    state.itemsByID.last_connection = data.last_connection
    state.itemsByID.rotate = data.rotate
    state.itemsByID.location = data.location
    state.itemsByID.country = data.country
    state.itemsByID.running_campaign = data.running_campaign
    state.itemsByID.province_state = data.province_state
    state.itemsByID.city = data.city
    state.itemsByID.lat = data.lat
    state.itemsByID.lng = data.lng
    state.itemsByID.time_zone_name = data.time_zone_name
    state.itemsByID.time_zone_value = data.time_zone_value
    state.itemsByID.campaign_code = data.campaign_code
    state.itemsByID.special = data.special
    state.itemsByID.activated = data.activated
    state.itemsByID.pin = data.pin
    state.itemsByID.reboot_hour = data.reboot_hour
    state.itemsByID.reboot_minute = data.reboot_minute
    state.itemsByID.reboot_frequency = data.reboot_frequency
  },
  GET_CAMPAIGN_BY_ORG(state, data) {
    state.listCampaignByOrg = data
  },
  GET_ALL_APPLICATION_VERSIONS(state, data) {
    state.application_installed = data
  }
}
