import state from './loopState'
import mutations from './loopMutations'
import getters from './loopGetters'
import actions from './loopActions'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
