import BaseService from './base.service'

class AdminOrganizationService extends BaseService {
  get entity() {
    return 'admin/organizations'
  }

  getAllAdminOrganizations(page, limit, search) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(
          search
            ? `${this.entity}?page=${page}&limit=${limit}&s=${search}`
            : `${this.entity}?page=${page}&limit=${limit}`
        )
        .then((response) => {
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getListPricing(page, limit) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get('organizations/list_pricing')
        .then((response) => {
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  getListOrgtype(page, limit) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get('admin/org_types')
        .then((response) => {
          resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => {
          const message = error.response.data
            ? error.response.data.error
            : error.response.statusText
          reject(this.errorWrapper(error, message))
        })
    })
  }

  createOrganization(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}`, data)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  deleteOrganization(id, data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .delete(`${this.entity}/${id}`, { data: data })
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  detailOrganization(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/${data}`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  updateOrganization(id, data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .patch(`${this.entity}/${id}`, data)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  joinOrganization(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/${id}/becomeOrg`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  checkValidData(type, data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/${type}`, data)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getAllOrganizationNoPaging() {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/all_organization`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getAllParentOrganizationNoPaging() {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/all_parent_organization`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getDataTableOfOrganization(data, type, page, limit, search) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(
          `${this.entity}/${data}/${type}?page=${page}&limit=${limit}${
            search ? '&s=' + search : ''
          }`
        )
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  checkEmailOrgExist(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .post(`${this.entity}/check_email`, data)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getDataToExport(data) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`admin/export_csvs/get_expire_url?type=${data.type}&organization_id=${data.id}`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }

  getAllInformation(id) {
    return new Promise((resolve, reject) => {
      return this.request({ auth: true })
        .get(`${this.entity}/${id}/all_information`)
        .then((response) => {
          return resolve(this.responseWrapper(response, response.data.data, response.data.message))
        })
        .catch((error) => reject(this.errorWrapper(error)))
    })
  }
}

export default new AdminOrganizationService()
