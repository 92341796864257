export default {
  SET_ITEMS(state, data) {
    state.items = data.items
    state.pagination.total = data.total_pages
    state.pagination.page = data.current_page
  },
  SET_PARENT_ITEMS(state, data) {
    state.parentOrg = data
  },
  SET_ERROR(state, data) {
    state.error = data
  },

  SET_LOADING(state, data) {
    if (data.type && data.type === 'campaigns') {
      state.loadingCampaign = data.data
    } else if (data.type && data.type === 'players') {
      state.loadingPlayer = data.data
    } else if (data.type && data.type === 'users') {
      state.loadingOfUser = data.data
    } else if (data.type && data.type === 'roles') {
      state.loadingRole = data.data
    } else {
      state.loading = data.data
    }
  },

  GET_LIST_PRICING(state, data) {
    state.pricingOptions = data
  },

  GET_LIST_ORG_TYPE(state, data) {
    state.orgTypeOptions = data
  },

  GET_LIST_COUNTRIES(state, data) {
    state.countryOptions = data
  },

  GET_LIST_STATE(state, data) {
    state.provinceStateOptions = data
  },

  UPDATE_LIST_STATE(state, data) {
    state.provinceStateOptions.push(data)
  },

  SET_ITEM_BY_ID(state, data) {
    state.itemsByID = data
  },

  SET_DATA_TABLE_PlAYER(state, data) {
    state.dataTablePlayer = data
  },

  SET_DATA_TABLE_CAMPAIGNS(state, data) {
    state.dataTableCampaign = data
  },

  SET_DATA_TABLE_USERS(state, data) {
    state.dataTableUser = data
  },

  SET_ITEMS_NAME(state, data) {
    state.itemsByID.name = data.name
    if (state.itemsByID.org_type) {
      const findOrgType = state.orgTypeOptions.find((orgType) => {
        return orgType.id === data.org_type_id
      })
      state.itemsByID.org_type.id = data.org_type_id
      state.itemsByID.org_type.name = findOrgType.text
    }
    state.itemsByID.pricing = data.pricing
    state.itemsByID.status = data.status
    state.itemsByID.industry = data.industry
  },

  SET_ITEMS_INFO_USER(state, data) {
    state.itemsByID.name = data.name
    state.itemsByID.email = data.email
    state.itemsByID.phone = data.phone
  },

  SET_ITEMS_LOCATION(state, data) {
    state.itemsByID.address = data.address
    state.itemsByID.city = data.city
    state.itemsByID.country = data.country
    state.itemsByID.postal_zip = data.postal_zip
    state.itemsByID.province_state = data.province_state
  },

  SET_ITEM_LINK(state, data) {
    state.itemsByID.freshdesk = data.freshdesk
    state.itemsByID.chargeover = data.chargeover
    state.itemsByID.xero = data.xero
  },
  SET_PERMISSION_LIST(state, data) {
    state.permissionList = data
  },
  SET_ROLE_LIST(state, data) {
    state.roleList = data
  },
  SET_ROLE_LIST_BY_ORG(state, data) {
    state.roleListByOrg = data
  },
  SET_ROLE_LIST_BY_ORG_NO_PAGING(state, data) {
    state.roleListByOrgNoPaging = data
  },
  SET_ROLE_LIST_NO_PAGING(state, data) {
    state.roleListNoPaging = data
  }
}
