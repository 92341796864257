<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <div class="sf-card">
          <div class="card-heading">
            <p>Customer</p>
          </div>
          <div class="card-body editable">
            <div class="row">
              <div class="col-12">
                <button class="button-edit-pen" @click="shownModalEdit()"></button>
                <div class="mt-3">
                  <p class="d-flex">
                    <span class="sf-text-strong mr-2">{{ $t('name') }}</span>
                    <content-placeholders
                      :class="'placeholder-text-detail'"
                      v-if="loadingEmployeeDetail"
                    >
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <span v-else>{{
                      displayUser.user.first_name + ' ' + displayUser.user.last_name
                    }}</span>
                  </p>
                </div>

                <div class="mt-3">
                  <p class="d-flex">
                    <span class="sf-text-strong mr-2">{{ $t('email') }}</span>
                    <content-placeholders
                      :class="'placeholder-text-detail'"
                      v-if="loadingEmployeeDetail"
                    >
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <span v-else>{{ displayUser.user.email }}</span>
                  </p>
                </div>

                <div class="mt-3">
                  <p class="d-flex">
                    <span class="sf-text-strong mr-2">{{ $t('organization') }}</span>
                    <content-placeholders
                      :class="'placeholder-text-detail'"
                      v-if="loadingEmployeeDetail"
                    >
                      <content-placeholders-text :lines="1" />
                    </content-placeholders>
                    <span v-else>{{ displayUser.user.organization.name }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SfModal :title="'Edit Customer'" :width="768" closeOnOverlay :show.sync="isShownModalEdit">
      <div class="sf-modal-body">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingEmployeeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('first-name') }}</label>
                </div>
                <input
                  v-model="currentUser.user.first_name"
                  :placeholder="$t('name')"
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.first_name.required"
              >
                {{ $t('first-name-required') }}
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.first_name.maxLength"
              >
                {{ 'text-250-required' }}
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingEmployeeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('last-name') }}</label>
                </div>
                <input
                  v-model="currentUser.user.last_name"
                  :placeholder="$t('last-name')"
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.last_name.required"
              >
                {{ $t('last-name-required') }}
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.last_name.maxLength"
              >
                {{ $t('text-250-required') }}
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingEmployeeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('email') }}</label>
                </div>
                <input
                  v-model="currentUser.user.email"
                  :placeholder="$t('email')"
                  class="sf-input-text"
                />
              </div>
              <div class="invalid-error" v-if="submitted && !$v.currentUser.user.email.required">
                {{ $t('email-address-required') }}
              </div>
              <div class="invalid-error" v-if="submitted && !$v.currentUser.user.email.maxLength">
                {{ $t('text-100-required') }}
              </div>
              <div class="invalid-error" v-if="submitted && !$v.currentUser.user.email.email">
                {{ $t('enter-a-valid-email-address') }}
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingEmployeeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('sub-type') }}</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    class="sf-select2"
                    v-model="currentUser.user.user_sub_type"
                    :options="userSupTypeOptions"
                    :settings="{ minimumResultsForSearch: -1 }"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingEmployeeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('organization') }}</label>
                </div>
                <div class="sf-select-group">
                  <Select2
                    v-model="currentUser.user.organization_id"
                    :options="organizationOptions"
                  />
                  <span class="lb-right caret"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingEmployeeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('password') }}</label>
                </div>
                <input
                  :type="'password'"
                  autocomplete="new-password"
                  v-model="currentUser.user.password"
                  :placeholder="$t('password')"
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.password.strongPassword"
              >
                {{ $t('strong-password-required') }}
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9">
              <div class="placeholder-modal" v-if="loadingEmployeeDetail">
                <content-placeholders>
                  <content-placeholders-text :lines="1" />
                </content-placeholders>
              </div>
              <div v-else class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('confirm-password') }}</label>
                </div>
                <input
                  autocomplete="new-password"
                  :type="'password'"
                  v-model="currentUser.user.passowrd_confirm"
                  :placeholder="$t('retype-your-password')"
                  class="sf-input-text"
                />
              </div>
              <div
                class="invalid-error"
                v-if="submitted && !$v.currentUser.user.passowrd_confirm.sameAsPassword"
              >
                {{ $t('passwords-must-be-identical') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-button my-3">
        <button class="sf-secondary" @click="isShownModalEdit = false">{{ $t('cancel') }}</button>
        <button class="sf-primary" @click="updateUser()">{{ $t('save') }}</button>
      </div>
    </SfModal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SfModal from '@/components/SfModal.vue'
import Select2 from 'v-select2-component'
import AdminUserService from '@/services/admin-user.service'
import { required, maxLength, email, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'admin-user-customer',

  components: {
    SfModal,
    Select2
  },

  data() {
    return {
      msg: 'Welcome to Index!!!',
      isShownModalEdit: false,
      inputError: false,
      checkboxState: false,
      submitted: false,
      currentUserID: this.$route.params.id,
      displayUser: {
        user: {
          first_name: '',
          last_name: '',
          email: '',
          user_sub_type: 0,
          user_type: 'customer',
          organization_id: 0,
          organization: {
            id: 0,
            name: ''
          }
        }
      },
      currentUser: {
        user: {
          first_name: '',
          last_name: '',
          email: '',
          user_sub_type: 0,
          user_type: 'customer',
          organization_id: 0,
          organization: {
            id: 0
          },
          password: '',
          passowrd_confirm: ''
        }
      },
      loadingEmployeeDetail: false
    }
  },

  validations: {
    currentUser: {
      user: {
        first_name: {
          required,
          maxLength: maxLength(250)
        },
        last_name: {
          required,
          maxLength: maxLength(250)
        },
        email: {
          required,
          maxLength: maxLength(100),
          email
        },
        password: {
          strongPassword(password) {
            return (
              (/[A-Z]/.test(password) && // checks for A-Z
                /[0-9]/.test(password) && // checks for 0-9
                password.length >= 8 &&
                password !== '') ||
              password === ''
            )
          }
        },
        passowrd_confirm: {
          sameAsPassword: sameAs('password')
        }
      }
    }
  },

  methods: {
    loadUserSubType() {
      this.$store.dispatch('adminuser/getUserSubType')
    },
    loadOrganization() {
      this.$store.dispatch('adminuser/getAllOrganizationNoPaging')
    },
    shownModalEdit() {
      this.submitted = false
      this.loadUserSubType()
      this.loadOrganization()
      this.isShownModalEdit = true
    },
    getItemByID() {
      this.loadingEmployeeDetail = true
      AdminUserService.detailUser(this.currentUserID)
        .then((response) => {
          this.setDataUser(this.currentUser.user, response.data)
          this.setDataUser(this.displayUser.user, response.data)
          this.loadingEmployeeDetail = false
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
    },
    updateUser() {
      this.submitted = true
      if (this.$v.currentUser.$invalid) {
        return
      }
      AdminUserService.updateUser(this.currentUserID, this.currentUser)
        .then((response) => {
          this.$toast.success('Successfully updated')
          this.setDataUser(this.displayUser.user, response.data)
        })
        .catch((err) => {
          this.$toast.error(err.message)
        })
      this.isShownModalEdit = false
    },
    setDataUser(user, new_user) {
      user.first_name = new_user.first_name
      user.last_name = new_user.last_name
      user.email = new_user.email
      user.user_sub_type = new_user.user_sub_type
      user.user_type = new_user.user_type
      user.organization_id = new_user.organization.id
      user.organization = new_user.organization
    }
  },

  computed: {
    ...mapState('adminuser', {
      userSupTypeOptions: 'userSupTypeOptions',
      organizationOptions: 'organizationOptions',
      error: 'error',
      loading: 'loading'
    }),
    ...mapGetters('adminuser', ['isEmpty'])
  },

  mounted() {
    this.getItemByID()
  }
}
</script>
<style lang="scss" scoped>
@import '../../Adminpage.scss';
</style>
