import AdminProgramService from '../../../../services/admin-programs.service'

export default {
  getAllAdminProgram({ commit }, payload) {
    AdminProgramService.getAllAdminProgram()
      .then((res) => {
        commit('SET_ERROR', '')
        commit('GET_ALL_ADMIN_PROGRAM', res.data)
      })
      .catch((error) => commit('SET_ERROR', error.message))
      .finally(() => commit('SET_LOADING', false))
  }
}
