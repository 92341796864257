<template>
  <div class="widget-component">
    <div class="row">
      <div class="col-12">
        <p class="action-title">{{ $t('widgets') }}</p>
        <p class="action-desc">Add or remove widgets from your instagram post.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div
          @click="activeWidget('titleIg')"
          v-bind:class="{ active: getVisitableWidget('titleIg') }"
          class="widget-item"
        >
          <div class="widget-item-img">
            <img :src="getVisitableWidget('titleIg') ? handleIconActive : handleIcon" />
          </div>
          <p>{{ $t('handle') }}</p>
        </div>
      </div>
      <div class="col-4">
        <div
          @click="activeWidget('textIg')"
          v-bind:class="{ active: getVisitableWidget('textIg') }"
          class="widget-item"
        >
          <div class="widget-item-img">
            <span>#</span>
          </div>
          <p>Caption</p>
        </div>
      </div>
      <!-- <div class="col-4">
      <div @click="activeWidget('tagsIg')" v-bind:class="{ active: getVisitableWidget('tagsIg')}" class="widget-item">
        <div class="widget-item-img">
          <span>@</span>
        </div>
        <p>Comment</p>
      </div>
    </div> -->
      <div class="col-4">
        <div
          @click="activeWidget('photo')"
          v-bind:class="{ active: getVisitableWidget('photo') }"
          class="widget-item"
        >
          <div class="widget-item-img">
            <img :src="getVisitableWidget('photo') ? photoIconActive : photoIcon" />
          </div>
          <p>Post Image</p>
        </div>
      </div>
      <div class="col-4">
        <div
          @click="activeWidget('profilePic')"
          v-bind:class="{ active: getVisitableWidget('profilePic') }"
          class="widget-item"
        >
          <div class="widget-item-img">
            <img :src="getVisitableWidget('profilePic') ? photoIconActive : photoIcon" />
          </div>
          <p>Display Image</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import photoIcon from '@/assets/images/toolbar-bg-icon.svg'
import handleIcon from '@/assets/images/toolbar-widget-icon.svg'
import photoIconActive from '@/assets/images/toolbar-bg-icon-active.svg'
import handleIconActive from '@/assets/images/toolbar-widget-icon-active.svg'
export default {
  data() {
    return {
      photoIcon,
      handleIcon,
      photoIconActive,
      handleIconActive,
      widgets: this.$store.getters['instagrameditor/getWidget']
    }
  },
  mounted() {},
  computed: {
    getActiveMenu() {
      return this.$store.getters['instagrameditor/getWidget']
    },
    getTongleValue() {
      const widget = this.$store.getters['instagrameditor/getWidget']
      for (const item in widget.activeMenu) {
        if (!!widget.activeMenu[item] === true) {
          return item
        }
      }
      return {}
    },
    getVisitableWidget() {
      return (value) => {
        const state = this.$store.getters['instagrameditor/getState']
        return state[value].visitable
      }
    }
  },
  methods: {
    activeWidget(value) {
      this.$store.dispatch('instagrameditor/activeWidgetElement', value)
    },
    tongleWidgetClick(e, name) {
      setTimeout(() => {}, 1000)
    }
  }
}
</script>

<style scoped>
.widget-component {
  position: relative;
  height: 520px;
}
.widget-toggle {
  position: absolute;
  bottom: 10px;
}
.widget-item {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  text-align: center;
  cursor: pointer;
}
.widget-item.active {
  color: #2c7fe1;
}
.active .widget-item-img {
  background-color: #2c7fe1;
  color: #ffffff;
  border: none;
}
.widget-item-img {
  border: 1px solid #ccc;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 10px;
}
.widget-item-img span {
  font-size: 22px;
}
</style>
