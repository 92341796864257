<template>
  <div class="sf-table">
    <b-table
      :fields="fields"
      :items="dataBugsnag.items"
      show-empty
      striped
      hover
      table-variant="none"
      :busy="loadingBugsnag"
      thead-class="custom-b-table"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:head(error)="field">
        <span class="custome-arrows">{{ field.label }}</span>
      </template>
      <template v-slot:cell(error)="field">
        <!-- <p>{{field.item.name}}</p> -->
        <p>
          <a target="#blank" :href="field.item.link">{{ field.item.name }}</a>
        </p>
      </template>

      <template v-slot:head(dateTime)="field">
        <span class="custome-arrows">{{ field.label }}</span>
      </template>
      <template v-slot:cell(dateTime)="field">
        <p>{{ field.item.time }}</p>
      </template>
    </b-table>
    <div class="my-2">
      <b-pagination
        class="sf-b-pagination"
        :total-rows="10 * dataBugsnag.total_pages"
        :per-page="10"
        @change="changePage"
        :limit="6"
        v-model="currentPage"
      />
    </div>
  </div>
</template>
<script>
import AdminPlayerService from '@/services/admin-player.service.js'
export default {
  name: 'Busnag',
  props: {
    currentPlayerID: {},
    show: {}
  },
  data() {
    return {
      fields: [
        {
          key: 'error',
          colType: 'error',
          label: 'Error Name',
          sortable: true
        },
        {
          key: 'dateTime',
          colType: 'dateTime',
          label: 'Date And Time',
          sortable: true
        }
      ],
      dataBugsnag: '',
      currentPage: 1,
      dataSearch: '',
      loadingBugsnag: true
    }
  },
  methods: {
    getListBugsnag(id, currentPage, search) {
      this.loadingBugsnag = true
      AdminPlayerService.getListBugsnag(id, currentPage, 10, search)
        .then((res) => {
          this.dataBugsnag = res.data
        })
        .catch((error) => {
          this.$toast.error(error.message ? error.message : 'Something went wrong')
        })
        .finally(() => {
          this.loadingBugsnag = false
        })
    },
    changePage(page) {
      this.currentPage = page
      this.getListBugsnag(this.currentPlayerID, this.currentPage, this.dataSearch)
    }
  },
  watch: {
    show(value) {
      if (value === 'bugs') {
      }
    }
  },
  mounted() {
    this.$bus.$off('onSearchInputChange')
    this.getListBugsnag(this.currentPlayerID, this.currentPage, this.dataSearch)
    this.$bus.$emit('showSearchBox', false)
    this.$bus.$on('onSearchInputChange', (value) => {
      this.dataSearch = value
      this.currentPage = 1
      this.getListBugsnag(this.currentPlayerID, this.currentPage, this.dataSearch)
    })
    this.$bus.$emit('send_active_button_to_header', this.dataSearch)
  }
}
</script>
<style scoped></style>
