<template>
  <div class="edit-photo-container">
    <div class="row">
      <!-- <div class="col-6 number-of-slides">
        <p>Number of Slides</p>
        <div class="number-input">
          <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()"></button>
          <input
            v-model="numberData.numberOfSlide"
            @input="setNumberOfSlide(numberData.numberOfSlide)"
            @change="setNumberOfSlide(numberData.numberOfSlide)"
            class="quantity" min="0" name="quantity" type="number">
          <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" class="plus"></button>
        </div>
      </div> -->
      <div class="col-12">
        <p class="action-title">{{ $t('edit-post-settings') }}</p>
        <p class="action-desc">
          {{ $t('post-count') }}
        </p>
      </div>
      <div class="col-12 mt-15">
        <p class="mb-10">Number of Slides</p>
        <input
          v-model="numberData.numberOfSlide"
          @input="setNumberOfSlide(numberData.numberOfSlide)"
          @change="setNumberOfSlide(numberData.numberOfSlide)"
          type="number"
          class="input-ig"
        />
      </div>

      <div class="col-12 mt-15">
        <p class="mb-10">{{ $t('duration-per-slide') }} ({{ $t('sec') }})</p>
        <input
          v-model="numberData.durationPerSlide"
          @input="setDurationPerSlide(numberData.durationPerSlide)"
          @change="setDurationPerSlide(numberData.durationPerSlide)"
          type="number"
          class="input-ig"
        />
      </div>

      <!-- <div class="col-12 mt-15">
      <p>Upload a mask</p>
      <div class="row edit-logo mt-10">
        <div class="col-4">
          <div class="bg-upload">
            <div class="upload-btn-wrapper">
              <div class="btn">+</div>
              <input
                type="file"
                name="myfile"
                @change="handleUploadImage($event)"
                accept="image/*"
                />
            </div>
          </div>
        </div>
        <div v-if="getMaskImage" class="col-6">
          <div class="show-upload-img">
            <img :src="getMaskImage">
            <a @click="handleRemoveImage" class="remove-img">X</a>
         </div>
        </div>
      </div>
    </div> -->
    </div>
    <div class="remove-widget-wrapper">
      <div class="remove-widget">
        <button @click="removeWidget" class="remove-widget-btn">{{ $t('remove-widget') }}</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    numberData: Object
  },
  computed: {
    getMaskImage() {
      const mask = this.$store.getters.getMask
      if (mask && mask.imageData) {
        return mask.imageData
      } else {
        return null
      }
    }
  },
  methods: {
    setNumberOfSlide(num) {
      this.$store.dispatch('instagrameditor/setNumberOfSlide', Number(num))
    },
    setDurationPerSlide(num) {
      this.$store.dispatch('instagrameditor/setDurationPerSlide', Number(num))
    },
    removeWidget() {
      this.$store.dispatch('instagrameditor/removeWidget', 'photo')
    },
    handleUploadImage(event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const imageData = e.target.result
          const payload = {
            imageData,
            file: input.files[0]
          }
          this.$store.dispatch('instagrameditor/setMaskImage', payload)
          this.$store.dispatch('instagrameditor/pushToHistory')
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    handleRemoveImage() {
      this.$store.dispatch('instagrameditor/removeMaskImage')
    }
  }
}
</script>
<style scoped></style>
